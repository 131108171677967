import HttpBaseService from "services/_common/http-base-service";
import { GetAllStockLocationRequest } from "./get-all-stock-location/get-all-stock-location.request";
import { GetAllStockLocationResponse } from "./get-all-stock-location/get-all-stock-location.response";
import { GetStockLocationRequest } from "./get-stock-location/get-stock-location.request";
import { GetStockLocationResponse } from "./get-stock-location/get-stock-location.response";
import { AddStockLocationRequest } from "./add-stock-location/add-stock-location.request";
import { AddStockLocationResponse } from "./add-stock-location/add-stock-location.response";
import { DeleteStockLocationRequest } from "./delete-stock-location/delete-stock-location.request";
import { SetDefaultLocationRequest } from "./set-default-location/set-default-location.request";
import { SetDefaultLocationResponse } from "./set-default-location/set-default-location.response";
import { UpdateStockLocationRequest } from "./update-stock-location/update-stock-location.request";
import { UpdateStockLocationResponse } from "./update-stock-location/update-stock-location.response";
import { GetAllBasicStockLocationRequest } from "./get-all-basic-stock-location/get-all-basic-stock-location.request";
import { GetAllBasicStockLocationResponse } from "./get-all-basic-stock-location/get-all-basic-stock-location.response";
import { GetProductVariantsAndStocksRequest } from "./get-product-variants-and-stocks/get-product-variants-and-stocks.request";
import { GetProductVariantsAndStocksResponse } from "./get-product-variants-and-stocks/get-product-variants-and-stocks.response";
import { GetProductOptionsRequest } from "./get-product-options/get-product-options.request";
import { GetProductOptionsResponse } from "./get-product-options/get-product-options.response";

class StockManagementService extends HttpBaseService {
	constructor() {
		super('stockmanagement');
	}

	async getAllStockLocation(request: GetAllStockLocationRequest): Promise<GetAllStockLocationResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async getAllBasicStockLocation(request: GetAllBasicStockLocationRequest): Promise<GetAllBasicStockLocationResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async getStockLocation(request: GetStockLocationRequest): Promise<GetStockLocationResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async getProductVariantsAndStock(request: GetProductVariantsAndStocksRequest): Promise<GetProductVariantsAndStocksResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async getProductOptions(request: GetProductOptionsRequest): Promise<GetProductOptionsResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async addStockLocation(request: AddStockLocationRequest): Promise<AddStockLocationResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async setDefaultLocation(request: SetDefaultLocationRequest): Promise<SetDefaultLocationResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async updateStockLocation(request: UpdateStockLocationRequest): Promise<UpdateStockLocationResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async deleteStockLocation(request: DeleteStockLocationRequest): Promise<boolean>{
		return await this.deleteData(request.getConvertUriExtension, "", request)
	}
}

export default new StockManagementService()