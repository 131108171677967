import HttpBaseService from 'services/_common/http-base-service';
import { GetTicketsRequest } from './get-tickets/get-tickets.request';
import { GetTicketsResponse } from './get-tickets/get-tickets.response';
import { GetTicketDetailRequest } from './get-ticket-detail/get-ticket-detail.request';
import { GetTicketDetailResponse } from './get-ticket-detail/get-ticket-detail.response';
import { CreateTicketRequest } from './create-ticket/create-ticket.request';
import { CreateTicketResponse } from './create-ticket/create-ticket.response';
import { CreateCommentToTicketRequest } from './create-comment-to-ticket/create-comment-to-ticket.request';
import { CreateCommentToTicketResponse } from './create-comment-to-ticket/create-comment-to-ticket.response';
import { CloseTicketRequest } from './close-ticket/close-ticket.request';
import { CloseTicketResponse } from './close-ticket/close-ticket.response';
import { TicketsReorderListForAdminRequest } from './tickets-reorder-list-for-admin/tickets-reorder-list-for-admin.request';
import { TicketsReorderListForAdminResponse } from './tickets-reorder-list-for-admin/tickets-reorder-list-for-admin.response';

class TicketsService extends HttpBaseService {
	constructor() {
		super('tickets');
	}

	async getTickets(request: GetTicketsRequest): Promise<GetTicketsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getTicketDetail(request: GetTicketDetailRequest): Promise<GetTicketDetailResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getReorderTicketsForAdmin(request: TicketsReorderListForAdminRequest): Promise<TicketsReorderListForAdminResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createTicket(request: CreateTicketRequest): Promise<CreateTicketResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}

	async createCommentToTicket(request: CreateCommentToTicketRequest): Promise<CreateCommentToTicketResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async closeTicket(request: CloseTicketRequest): Promise<CloseTicketResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new TicketsService();
