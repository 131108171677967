export class UpsertUserGroupRequest {
	userIds: string[];
	groupId: string;
	isActive: boolean;

	constructor(props: Partial<UpsertUserGroupRequest>) {
		this.userIds = props.userIds || [];
		this.groupId = props.groupId || "";
		this.isActive = props.isActive || true;
	}

	get getConvertUriExtension() {
		return 'upsert-user-group'
	}
}