import { ShippingRateDto } from "features/orders/derived-features/order-transports/dtos/shipping-rate.dto";
import { PaymentTypes } from "features/orders/dtos/payment-type.dto";

export class ReorderChargeRequest {
	reorderId: string;
	paymentMethodType: PaymentTypes;
	shippingRate: ShippingRateDto | null;

	constructor(props: Partial<ReorderChargeRequest>) {
		this.reorderId = props.reorderId || '';
		this.paymentMethodType = props.paymentMethodType || 2;
		this.shippingRate = props.shippingRate || null;
	}

	get getConvertUriExtension() {
		return 'charge';
	}
}