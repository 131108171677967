import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { CreateWizardContext, WizardContextType } from '../context/WizardContextProvider';
import { PrimeIcons } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { SurveyQuestionDto, SurveyQuestionOptionDto } from '../../../../../features/surveys/_common/survey-get/survey-get.response';
import { AnswerDto } from '../../../../../features/surveys/dtos/answer-dto';
import { InputText } from 'primereact/inputtext';
import OptionButton from '../../Survey/components/OptionButton';

type Props = {
	question?: SurveyQuestionDto;
};

function ProductsInterested({ question }: Props) {
	const context: WizardContextType = React.useContext(CreateWizardContext) as WizardContextType;

	const [itemStates, setItemStates] = useState<{ [key: string]: boolean }>({});
	const [selectedOptions, setSelectedOptions] = useState<SurveyQuestionOptionDto[]>([]);
	const [otherSelected, setOtherSelected] = useState(false);
	const [otherInput, setOtherInput] = useState<string>('');
	const [textAddableOption, setTextAddableOption] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	const subtitles = ['Mugs & bottles', 'Photo books, cards, calendars...', 'T-shirts, hoodies, sweatshirts...', 'Phone cases, tote bags...'];

	useEffect(() => {
		const initialStates = question?.surveyQuestionOptions.reduce(
			(acc: { [p: string]: boolean }, item) => {
				acc[item.id] = false;
				return acc;
			},
			{} as { [key: number]: boolean }
		);
		setItemStates(initialStates!);

		const filtered = question?.surveyQuestionOptions.filter((e) => e.textCanBeAdded);

		if (filtered!.length !== 0) setTextAddableOption(filtered![0].id);
	}, []);

	const onOptionClick = (option: SurveyQuestionOptionDto) => {
		setItemStates((prevState) => ({
			...prevState,
			[option.id]: !prevState[option.id]
		}));

		let newCurrent = [...selectedOptions];

		if (!itemStates[option.id]) {
			newCurrent.push(option);
		} else {
			newCurrent = newCurrent.filter((_nCurrent: SurveyQuestionOptionDto) => _nCurrent.id !== option.id);
		}
		setSelectedOptions(newCurrent);

		if (option.id === textAddableOption) setOtherSelected(!otherSelected);
	};

	const validate = () => {
		return Object.values(itemStates).some((isSelected) => isSelected) && (otherSelected ? otherInput !== '' : true);
	};

	const onSubmit = () => {
		if (!validate()) return setError('Please select at least one of the options');

		if (!context.sellingCustomProducts && !context.termsAndConditionsChecked) return setError('You must agree with the Terms of Service and Privacy Policy');

		if (!context.user) return;

		let newAnswers: AnswerDto[] = context.answers;

		selectedOptions.forEach((option, index) => {
			newAnswers.push({
				surveyQuestionOptionId: option.id,
				text: option.id === textAddableOption ? otherInput : null,
				userId: context.user?.userId!,
				questionId: question?.id!
			});
		});

		context.setAnswers(newAnswers);

		context.sellingCustomProducts ? context.setStep(4) : context.submitWizard();
	};

	return (
		<div className="flex flex-column justify-content-center align-items-center w-full">
			<div className="text-center mb-1 px-2">
				<h2 className="font-semibold">What products are you interested in?</h2>
				<p>We'll use this information to suggest products you'll love. But don't worry, you'll still have access to all the categories 🙂</p>

				<div className="h-1rem mb-2">{error !== '' && <div style={{color: "red"}} className="text-sm my-auto">{error}</div>}</div>
			</div>

			<div className="grid justify-content-center w-full">
				{question?.surveyQuestionOptions.map((option, index) => {
					return <OptionButton key={index} name={option.text} disabled={context.loading} subtext={subtitles[option.order - 1]} checked={itemStates[option.id] || false} onClick={() => onOptionClick(option)} />;
				})}
			</div>

			<div className={`${textAddableOption ? 'block' : 'hidden'} h-6rem w-full`}>
				{otherSelected ? (
					<div className="flex flex-column justify-content-center align-items-stretch w-full px-2">
						<p className="text-sm" style={{ color: '#555555' }}>
							You selected 'Other'. Please enter your answer below.
						</p>
						<InputText
							className={error !== '' ? 'border-round-lg border-red-500 transition-linear' : 'border-round-lg'}
							id="channel_other"
							onChange={(value) => {
								setError('');
								setOtherInput(value.target.value);
							}}
						/>
					</div>
				) : null}
			</div>

			{context.sellingCustomProducts && (
				<div className="flex flex-row justify-content-center my-1">
					<Button label="Back" onClick={() => context.back()} icon={PrimeIcons.ANGLE_LEFT} className="p-button-text mr-4 text-lg" style={{ color: '#101531' }} />

					<Button className="border-round-md border-none hover:bg-transparent text-lg px-4 py-2" style={{ backgroundColor: '#4541FF' }} label={!context.loading ? 'Next' : ''} onClick={() => onSubmit()} />
				</div>
			)}

			{!context.sellingCustomProducts && (
				<React.Fragment>
					<div className="flex flex-column align-items-start my-3 px-2">
						<div className="flex flex-row justify-content-center align-items-center my-2">
							<Checkbox inputId="terms_and_conditions" name="" value="" onChange={(e) => context.setTermsAndConditionsChecked(e.checked)} checked={context.termsAndConditionsChecked} />
							<label htmlFor="terms_and_conditions" className="ml-2 text-sm">
								I accept the{' '}
								<a className={'text-primary'} href="https://printram.com/contracts/terms-of-service" target="_blank" rel="noopener noreferrer">
									Terms of Service
								</a>{' '}
								and I have read the{' '}
								<a className={'text-primary'} href="https://printram.com/contracts/privacy-policy" target="_blank" rel="noopener noreferrer">
									Privacy Policy
								</a>
							</label>
						</div>

						<div className="flex flex-row justify-content-center align-items-center my-2">
							<Checkbox inputId="recieve_news" name="" value="" onChange={(e) => context.setReceiveNewsChecked(e.checked)} checked={context.receiveNewsChecked} />
							<label htmlFor="recieve_news" className="ml-2 text-sm">
								I would like to receive news, insights, and best practices from Printram
							</label>
						</div>
					</div>

					<div className="flex flex-row justify-content-center my-1">
						<Button label="Back" onClick={() => context.setStep(1)} icon={PrimeIcons.ANGLE_LEFT} className="p-button-text mr-4 text-lg" style={{ color: '#101531' }} />

						<Button className="border-round-md border-none hover:bg-transparent text-lg px-4 py-2" style={{ backgroundColor: '#4541FF' }} label={!context.loading ? 'Finish' : ''} onClick={() => onSubmit()}></Button>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

export default ProductsInterested;