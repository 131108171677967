import React, { useEffect, useState } from 'react';
import { GetTemplateCategoryRequest } from 'features/categories/derived-features/template-categories/get-template-category/get-template-category.request';
import templateCategoryService from 'features/categories/derived-features/template-categories/template-category.service';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import TreeNode from 'primereact/treenode';
import { TemplateCategoryDto } from 'features/categories/derived-features/template-categories/dtos/template-category.dto';
import mediaHelper from 'helpers/media.helper';
import DropdownButton from 'helpers/widgets/DropdownButton';
import CreateTemplateCategoryModal from './components/CreateTemplateCategoryModal';
import UpdateTemplateCategoryModal from './components/UpdateTemplateCategoryModal';
import categoryService from 'features/categories/_common/category.service';
import { SoftDeleteCategoryRequest } from 'features/categories/_common/soft-delete-category/soft-delete-category.request';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';

const TemplateCategories = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [createVisible, setCreateVisible] = useState<boolean>(false);
	const [updateVisible, setUpdateVisible] = useState<boolean>(false);
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);
	const [categories, setCategories] = useState<TemplateCategoryDto[]>([]);
	const [updateCategory, setUpdateCategory] = useState<TemplateCategoryDto | undefined>(undefined);

	const getAll = async () => {
		try {
			setLoading(true);

			const response = await templateCategoryService.getAll(new GetTemplateCategoryRequest());
			if (!response.isSuccess || !response.data) throw '';

			const treeData = response.data.map((_category) => {
				const tree = { key: _category.id, data: _category } as TreeNode;

				if (!!_category.subCategories) {
					tree.children = _category.subCategories.map((_subCategory) => ({ key: _subCategory.id, data: _subCategory } as TreeNode));
					delete tree.data.subCategories;
				}

				return tree;
			});

			setCategories(response.data);
			setTreeCategories(treeData);
		} catch (error) {
			setTreeCategories([]);
		} finally {
			setLoading(false);
			setUpdateCategory(undefined);
		}
	};

	const deleteCategory = async (id: string) => {
		try {
			const response = await categoryService.softDelete(new SoftDeleteCategoryRequest(id));
			if (!response) throw '';

			await getAll();
		} catch (error) {
		} finally {
		}
	};

	useEffect(() => {
		getAll();
	}, []);

	const descriptionBodyTemplate = (row: { data: TemplateCategoryDto }, options: ColumnBodyOptions) => {
		if (!row.data.description) return '';

		const count = 60;

		let description = row.data.description.substring(0, count);

		if (row.data.description.length > count) description += '...';

		return description;
	};

	const imageBodyTemplate = (row: { data: TemplateCategoryDto }, options: ColumnBodyOptions) => <img src={amazonS3Helper.showFile(row.data.media, { resize: '64x64' })} alt={row.data.name} loading="lazy" className="w-4rem h-4rem border-round-lg shadow-5" />;

	const bannerImageBodyTemplate = (row: { data: TemplateCategoryDto }, options: ColumnBodyOptions) => {
		if (!row.data.bannerMedia) return '';

		return <img src={amazonS3Helper.showFile(row.data.bannerMedia, { resize: '203x64' })} alt={row.data.name + 'Banner Image'} loading="lazy" className="h-4rem border-round-lg shadow-5" />;
	};

	const actionsBodyTemplate = (row: { data: TemplateCategoryDto }, options: ColumnBodyOptions) => {
		const menuItems = [
			<button
				onClick={(_) => {
					setUpdateVisible(true);
					setUpdateCategory(row.data);
				}}>
				Update
			</button>,

			<button onClick={(_) => deleteCategory(row.data.id)} className="text-pink-500 font-bold">
				Delete
			</button>
		];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	return (
		<div className="card">
			<div className="flex align-items-center justify-content-between mb-3">
				<h5 className="m-0">Template Categories</h5>

				<Button loading={loading} onClick={(_) => setCreateVisible(true)} label="New Category" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>

			<TreeTable value={treeCategories} loading={loading} lazy>
				<Column header="Name" field="name" expander />
				<Column header="Image" field="media" body={imageBodyTemplate} className="w-8rem" />
				<Column header="Description" field="description" body={descriptionBodyTemplate} />
				<Column header="Banner Image" field="bannerImageMedia" body={bannerImageBodyTemplate} />
				<Column header="Actions" body={actionsBodyTemplate} className="w-9rem" />
			</TreeTable>

			<CreateTemplateCategoryModal getAll={getAll} visible={createVisible} categories={categories} setVisible={setCreateVisible} />
			<UpdateTemplateCategoryModal getAll={getAll} updateCategory={updateCategory} visible={updateVisible} categories={categories} setVisible={setUpdateVisible} />
		</div>
	);
};

export default TemplateCategories;
