import React, { useEffect, useState } from 'react'
import styles from './EditLocation.module.scss'
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { ErrorMessage, Form, Formik } from 'formik';
import { RiCloseFill } from 'react-icons/ri';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';
import { FiMinus, FiPlus } from 'react-icons/fi';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { UpdateStockLocationRequest } from 'features/stockmanagement/_common/update-stock-location/update-stock-location.request';
import * as Yup from 'yup';
import countryService from 'features/countries/country.service';
import { GetStatesRequest } from 'features/countries/get-states/get-states.request';
import { GetCountriesRequest } from 'features/countries/get-countries/get-countries.request';
import stockmanagementService from 'features/stockmanagement/_common/stockmanagement.service';
import { GetStockLocationRequest } from 'features/stockmanagement/_common/get-stock-location/get-stock-location.request';
import { StockLocationDto } from 'features/stockmanagement/dtos/stock-location.dto';

type props = { setEditedLoc: React.Dispatch<React.SetStateAction<string | undefined>>; getLocations: Function; locationId: string };

export const EditLocation = ({ setEditedLoc, getLocations, locationId }: props) => {
	const [locationDetail, setLocationDetail] = useState<StockLocationDto>()
	const [genVisible, setGenVisible] = useState<boolean>(false);
	const [salesVisible, setSalesVisible] = useState<boolean>(false);
	const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
	const [countryOptions, setCountryOptions] = useState<PrDropdownType[]>();
	const [stateOptions, setStateOptions] = useState<PrDropdownType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const inputStyle = { backgroundColor: '#F9FAFB', borderColor: '#E5E7EB' };

	const initialValues = new UpdateStockLocationRequest({});

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required').min(2, 'Name must be at least 2 characters.').max(50, 'Name must be at most 50 characters.'),
		operationManager: Yup.string().required('Manager name is required').min(2, 'Manager name must be at least 2 characters.').max(50, 'Manager name must be at most 50 characters.'),
		countryId: Yup.number().moreThan(0, 'Country is required'),
		stateId: Yup.number().moreThan(0, 'State is required'),
		zipCode: Yup.string().required('Zip code is required'),
		city: Yup.string().required('City is required'),
		addressLine1: Yup.string().required('Address description is required')
	});

		const getStatesByCountry = async (countryId: number) => {
			try {
				setStateOptions([]);

				const response = await countryService.getStates(new GetStatesRequest(countryId));
				if (!response.isSuccess || !response.data) throw '';

				const options = response.data.map((_state) => ({ label: _state.name, value: _state.id }));

				if (!options) throw '';

				setStateOptions(options);
				//setStates(response.data);
			} catch (error) {
				//setStates([]);
			}
		};

		const getCountries = async () => {
			try {
				const response = await countryService.getCountries(new GetCountriesRequest());
				if (!response.isSuccess || !response.data) throw '';

				const options = response.data.map((_country) => ({ label: _country.name, value: _country.id }));

				if (!options) throw '';

				setCountryOptions(options);
				//setCountries(response.data);
			} catch (error) {
				//setCountries([]);
			} finally {}
		};

		const countryChangeHandler = async (event: PrDropdownType, name: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
			try {
				setFieldValue(name, event.value);

				await getStatesByCountry(+event.value);
			} catch (error) {}
		};

		const handleSubmit = async (values: UpdateStockLocationRequest) => {
			try {
				setLoading(true);

				const request: UpdateStockLocationRequest = structuredClone(values);

				const response = await stockmanagementService.updateStockLocation(new UpdateStockLocationRequest({ ...request }));

				if (!response.isSuccess) throw '';

				setEditedLoc(undefined);
				getLocations();
			} catch (error) {
			} finally {
				setLoading(false);
			}
	};

	const getLocationDetail = async () => {
		if (!locationId) return;

		try {
			setLoading(true)

			const request = new GetStockLocationRequest({ id: locationId })

			const response = await stockmanagementService.getStockLocation(request)

			if (!response.isSuccess || !response.data) throw ''

			setLocationDetail(response.data)
			initialValues.initUpdateDataFromAccountData(locationId, response.data)
			if(response.data.address.countryId) await getStatesByCountry(response.data.address.countryId);
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
			getLocationDetail()
			getCountries();
		}, []);

	return (
		<div className={styles.main}>
			<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
				{({ values, handleChange, setFieldValue }) => (
					<Form>
						<div className={styles.header}>
							<div onClick={() => setEditedLoc(undefined)} className={styles.closeIcon}>
								<RiCloseFill size={20} color="darkgray" />
							</div>

							<button type="submit" className={styles.addBtn}>
								Edit location
							</button>
						</div>

						<div className={styles.body}>
							<div className={styles.bodyInner}>
								<h1>Edit location</h1>

								<div className={styles.locAddMenus}>
									<div onClick={() => setGenVisible(!genVisible)} className={styles.locAddMenusHeader}>
										<span>
											General information<span className="text-red">*</span>
										</span>
										{genVisible ? <FiMinus /> : <FiPlus />}
									</div>

									{genVisible && (
										<div className={styles.locAddMenusBody}>
											<span>Specify the details about this location.</span>
											<div className={styles.input}>
												<div className={styles.inputLabel}>
													Location name<span className="text-red">*</span>
												</div>
												<PrTextInput name="name" value={values.name} onChange={handleChange} style={inputStyle} />
												<ErrorMessage name="name" component="small" className="text-red font-medium" />
											</div>

											<div className={styles.inputHalf}>
												<div className={styles.inputLabel}>Operation manager</div>
												<PrTextInput name="operationManager" value={values.operationManager} onChange={handleChange} style={inputStyle} />
												<ErrorMessage name="operationManager" component="small" className="text-red font-medium" />
											</div>
											<span className={styles.address}>Address</span>
											<div className={styles.inputHalf}>
												<div className={styles.inputLabel}>Company name</div>
												<PrTextInput name="companyName" onChange={handleChange} style={inputStyle} />
												<ErrorMessage name="companyName" component="small" className="text-red font-medium" />
											</div>
											<div className={styles.inputRows}>
												<div className={styles.inputRow}>
													<div className={styles.inputLabel}>Address line 1</div>
													<PrTextInput name="addressLine1" value={values.addressLine1} onChange={handleChange} style={inputStyle} />
													<ErrorMessage name="addressLine1" component="small" className="text-red font-medium" />
												</div>
												<div className={styles.inputRow}>
													<div className={styles.inputLabel}>Address line 2</div>
													<PrTextInput name="addressLine2" value={values.addressLine2} onChange={handleChange} style={inputStyle} />
												</div>
											</div>
											<div className={styles.inputRows}>
												<div className={styles.inputRow}>
													<div className={styles.inputLabel}>Postal code</div>
													<PrTextInput name="zipCode" value={values.zipCode} onChange={handleChange} style={inputStyle} />
													<ErrorMessage name="zipCode" component="small" className="text-red font-medium" />
												</div>
												<div className={styles.inputRow}>
													<div className={styles.inputLabel}>City</div>
													<PrTextInput name="city" value={values.city} onChange={handleChange} style={inputStyle} />
													<ErrorMessage name="city" component="small" className="text-red font-medium" />
												</div>
											</div>
											<div className={styles.inputRows}>
												<div className={styles.inputRow}>
													<div className={styles.inputLabel}>Country</div>
													<PrDropdown filter options={countryOptions} value={countryOptions?.find((_country) => _country.value === values.countryId)} onChange={(e) => countryChangeHandler(e, 'countryId', setFieldValue)} placeholder="Select a country" className="w-full surface-300" />
													<ErrorMessage name="countryId" component="small" className="text-red font-medium" />
												</div>
												<div className={styles.inputRow}>
													<div className={styles.inputLabel}>State</div>
													<PrDropdown filter key={stateOptions[0]?.value || 0} options={stateOptions} value={stateOptions?.find((_state) => _state.value === values.stateId)} onChange={(e) => setFieldValue('stateId', e.value)} placeholder="Choose a state" className="w-full surface-300" />
													<ErrorMessage name="stateId" component="small" className="text-red font-medium" />
												</div>
											</div>
										</div>
									)}
								</div>
								{/* <div className={styles.locAddMenus}>
									<div onClick={() => setSalesVisible(!salesVisible)} className={styles.locAddMenusHeader}>
										<span>Sales channels</span>
										{salesVisible ? <FiMinus /> : <FiPlus />}
									</div>

									{salesVisible && <div className={styles.locAddMenusBody}></div>}
								</div> */}

								<div className={styles.locAddMenus}>
									<div onClick={() => setSettingsVisible(!settingsVisible)} className={styles.locAddMenusHeader}>
										<span>Settings</span>
										{settingsVisible ? <FiMinus /> : <FiPlus />}
									</div>
									{settingsVisible && (
										<div className={styles.locAddMenusBody}>
											<span>Specify how this location's inventory can be used.</span>

											<div className={styles.settingsContainer}>
												<div className={styles.descriptions}>
													<span className={styles.settingsName}>Does this location offer pickups?</span>
													<span className={styles.settingsDesc}>E.g., a warehouse typically don't allow customers to show up, retail stores do.</span>
												</div>
												<div className={styles.setting}>
													<InputSwitch name="pickUp" checked={values.pickUp} onChange={handleChange} />
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};