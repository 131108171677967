export class SurveySoftDeleteRequest {
	private surveyId: string;

	constructor(surveyId: string) {
		this.surveyId = surveyId;
	}

	get getConvertUriExtension() {
		return `${this.surveyId}`;
	}
}
