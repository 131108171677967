import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';

export class GetOrdersForPrintCenterRequest {
	printCenterId: string;
	storeId: string | null;
	includeStores: boolean;
	contains: string | null;
	orderStatu: OrderStatuTypes | null;
	pagination: PaginationDto;

	constructor(props: Partial<GetOrdersForPrintCenterRequest>) {
		this.printCenterId = props.printCenterId || '';
		this.storeId = props.storeId || null;
		this.includeStores = props.includeStores || false;
		this.contains = props.contains || null;
		this.orderStatu = props.orderStatu || null;
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-orders-for-print-center`;
	}
}
