import { Color } from '../models/dtos/product/color';
import { DataResponse } from '../models/_commons/responses/data-response';
import { CreateColorRequest } from '../models/requests/colors/create-color-request';
import { UpdateColorRequest } from '../models/requests/colors/update-color-request';
import { ResponseBase } from '../models/_commons/responses/response-base';
import HttpBaseService from './_common/http-base-service';

class ColorService extends HttpBaseService {
    constructor() {
        super('colors');
    }

    async getAll(): Promise<DataResponse<Color[]>> {
        return await this.getData('get-colors');
    }

    async create(request: CreateColorRequest): Promise<ResponseBase>{
        return await this.postData('create-color', request);
    }

    async update(request: UpdateColorRequest): Promise<ResponseBase>{
        return await this.putData('update-color', request);
    }

    async delete(id: string): Promise<boolean>{
        return await this.deleteData('soft-delete-color', id);
    }
}

export default new ColorService();
