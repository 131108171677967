import { Category } from '../models/dtos/category/category';
import { CreateCategoryRequest } from '../models/requests/categories/create-category-request';
import { UpdateCategoryRequest } from '../models/requests/categories/update-category-request';
import { DataResponse } from '../models/_commons/responses/data-response';
import { ResponseBase } from '../models/_commons/responses/response-base';
import HttpBaseService from './_common/http-base-service';

class CategoryService extends HttpBaseService {
    constructor() {
        super('categories');
    }

    async getAll(): Promise<DataResponse<Category[]>> {
        return await this.getData('get-categories');
    }

    async create(request: CreateCategoryRequest): Promise<ResponseBase>{
        return await this.postData('create', request);
    }

    async createWithImageMedia(request: CreateCategoryRequest): Promise<ResponseBase>{
        return await this.postData('create-with-image-media', request, true);
    }

    async update(request: UpdateCategoryRequest): Promise<ResponseBase>{
        return await this.putData('update', request);
    }

    async delete(id: string): Promise<boolean>{
        return await this.deleteData('soft-delete', id);
    }
}

export default new CategoryService();
