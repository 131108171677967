import { MyAccountDto } from '../get-my-account/get-my-account.response';

export class UpdateAccountContactInfoPhoneNumberRequestDto {
	id: string | null;
	code: string;
	number: string;
	isDefault: boolean;

	constructor(props: Partial<UpdateAccountContactInfoPhoneNumberRequestDto>) {
		this.id = props.id || null;
		this.code = props.code || '';
		this.number = props.number || '';
		this.isDefault = props.isDefault || true;
	}
}

export class UpdateAccountContactInfoRequestDto {
	name: string;
	surname: string;
	email: string;
	phoneNumbers: UpdateAccountContactInfoPhoneNumberRequestDto[];

	constructor(props: Partial<UpdateAccountContactInfoRequestDto>) {
		this.name = props.name || '';
		this.surname = props.surname || '';
		this.email = props.email || '';
		this.phoneNumbers = props.phoneNumbers || [];
	}
}

export class UpdateAccountCorporateInfoRequestDto {
	businessName: string;
	feinNumber: string | null;

	constructor(props: Partial<UpdateAccountCorporateInfoRequestDto>) {
		this.businessName = props.businessName || '';
		this.feinNumber = props.feinNumber || null;
	}
}

export class UpdateAccountAddressRequestDto {
	id: string | null;
	countryId: number;
	stateId: number;
	zipCode: string;
	city: string;
	addressLine1: string;
	addressLine2: string | null;

	constructor(props: Partial<UpdateAccountAddressRequestDto>) {
		this.id = props.id || null;
		this.countryId = props.countryId || 0;
		this.stateId = props.stateId || 0;
		this.zipCode = props.zipCode || '';
		this.city = props.city || '';
		this.addressLine1 = props.addressLine1 || '';
		this.addressLine2 = props.addressLine2 || null;
	}
}

export class UpdateMyAccountRequest {
	userId: string;
	ssnNumber: string | null;
	isForeignNation: boolean;
	corporateInfo: UpdateAccountCorporateInfoRequestDto | null;
	contactInfo: UpdateAccountContactInfoRequestDto;
	addresses: UpdateAccountAddressRequestDto[];

	constructor(props: Partial<UpdateMyAccountRequest>) {
		this.userId = props.userId || '';
		this.ssnNumber = props.ssnNumber || null;
		this.isForeignNation = props.isForeignNation || false;
		this.corporateInfo = typeof props.corporateInfo === 'undefined' ? new UpdateAccountCorporateInfoRequestDto({}) : props.corporateInfo;
		this.contactInfo = props.contactInfo || new UpdateAccountContactInfoRequestDto({});
		this.addresses = props.addresses || [];
	}

	get getConvertUriExtension() {
		return `update-my-account`;
	}

	initUpdateDataFromAccountData(userId: string, data: MyAccountDto) {
		this.userId = userId;
		this.ssnNumber = data.ssnNumber;
		this.isForeignNation = true;

		//#region Corporate Info
		this.corporateInfo = new UpdateAccountCorporateInfoRequestDto({
			businessName: data?.corporateInfo?.businessName,
			feinNumber: data?.corporateInfo?.feinNumber
		});
		//#endregion

		//#region Contact Info
		this.contactInfo = new UpdateAccountContactInfoRequestDto({
			name: data?.contactInfo?.name,
			surname: data?.contactInfo?.surname,
			email: data?.contactInfo?.email
		});

		const phoneNumberRequestDto = new UpdateAccountContactInfoPhoneNumberRequestDto({});

		for (const phoneNumber of data.contactInfo.phoneNumbers || []) {
			if (!phoneNumber) break;

			if (!phoneNumber.isDefault) continue;

			phoneNumberRequestDto.id = phoneNumber.id;
			phoneNumberRequestDto.code = phoneNumber.code;
			phoneNumberRequestDto.number = phoneNumber.number;
			phoneNumberRequestDto.isDefault = phoneNumber.isDefault;

			break;
		}

		this.contactInfo.phoneNumbers.push(phoneNumberRequestDto);
		//#endregion

		//#region Addresses
		if (!data.addresses || data.addresses.length < 1) this.addresses.push(new UpdateAccountAddressRequestDto({}));
		else
			for (const address of data.addresses) {
				this.addresses.push(
					new UpdateAccountAddressRequestDto({
						id: address.id,
						countryId: address.countryId,
						stateId: address.stateId,
						city: address.city,
						zipCode: address.zipCode,
						addressLine1: address.addressLine1,
						addressLine2: address.addressLine2
					})
				);
			}
		//#endregion
	}
}
