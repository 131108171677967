
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { StatusOptions } from 'helpers/constants/user-status-options';
import DropdownInput from 'helpers/widgets/Forms/DropdownInput';
import TextInput from 'helpers/widgets/Forms/TextInput';
import TextMaskInput from 'helpers/widgets/Forms/TextMaskInput';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UpdatePrintCenterRequest } from 'features/printCenters/_common/update/update-print-center.request';
import { GetPrintCenterForAdminResponse } from 'features/printCenters/_common/get-print-center-for-admin/get-print-center-for-admin.response';
import { GetPrintCenterForAdminRequest } from 'features/printCenters/_common/get-print-center-for-admin/get-print-center-for-admin.request';
import printCenterService from 'features/printCenters/_common/print-center.service';
import { CreateOrUpdateCorporateModelDto } from 'features/printCenters/_common/update/dtos/create-or-update-corporate-model.dto';
import { UserUpdateModelDto } from 'features/printCenters/_common/update/dtos/user-update-model.dto';


type Props = {
	editedPrintCenter: string;
	setEditedPrintCenter: Function;
	getPrintCenters: Function;
};

const PrintCenterUpdate = ({ editedPrintCenter, setEditedPrintCenter, getPrintCenters }: Props) => {
	const formikRef = useRef<FormikProps<UpdatePrintCenterRequest>>(null);
	const [loading, setLoading] = useState(false);
	const [prefixOptions, setPrefixOptions] = useState<SelectItemOptionsType>([
		{ label: '+1', value: '1' },
		{ label: '+90', value: '90' }
	]);

	const [printCenter, setPrintCenter] = useState<GetPrintCenterForAdminResponse | null>();

	const initialValues = new UpdatePrintCenterRequest({ userId: printCenter?.userId || '', userStatuId: printCenter?.userStatuId || 0 }, { id: printCenter?.userId || '', name: printCenter?.personInfo?.name || '', surname: printCenter?.personInfo?.surname || '', email: printCenter?.email || '', phoneNumbers: printCenter?.phoneNumbers || [], isForeignNation: printCenter?.isForeignNation || false }, { userId: printCenter?.userId || '', businessName: printCenter?.corporateInfo?.businessName || '', feinNumber: printCenter?.corporateInfo?.feinNumber || '' });

	const validationSchema = Yup.object().shape({
		printCenterUpdateModel: Yup.object().shape({ userStatuId: Yup.number().required('User statu is required') }),
		userUpdateModel: Yup.object().shape({ email: Yup.string().required('Email is required'), name: Yup.string().required('Name is required'), surname: Yup.string().required('Surname is required') }),
		createOrUpdateCorporateModel: Yup.object().shape({ businessName: Yup.string().required('Business name is required') })
	});

	const getPrintCenterDetail = useCallback(async () => {
		try {
			setLoading(true);

			const request = new GetPrintCenterForAdminRequest();
			request.id = editedPrintCenter;

			const response = await printCenterService.getPrintCenterForAdmin(request);

			if (!response.isSuccess) throw '';

			setPrintCenter(response.data);
		} finally {
			setLoading(false);
		}
	}, []);

	const submitForm = async (values: UpdatePrintCenterRequest) => {
		try {
			setLoading(true);

			if (values.createOrUpdateCorporateModel?.feinNumber === '') values.createOrUpdateCorporateModel.feinNumber = null;
			if (values.userUpdateModel?.ssnNumber === '') values.userUpdateModel.ssnNumber = null;
			if (values.createOrUpdateCorporateModel?.feinNumber) values.createOrUpdateCorporateModel.feinNumber = values.createOrUpdateCorporateModel.feinNumber.replace(/-/g, '');
			if (values.userUpdateModel.ssnNumber) values.userUpdateModel.ssnNumber = values.userUpdateModel.ssnNumber.replace(/-/g, '');

			const createOrUpdateCorporateModel: CreateOrUpdateCorporateModelDto = { ...values.createOrUpdateCorporateModel, userId: values.createOrUpdateCorporateModel?.userId || '', businessName: values.createOrUpdateCorporateModel?.businessName || '', feinNumber: values.createOrUpdateCorporateModel?.feinNumber ? values.createOrUpdateCorporateModel?.feinNumber : null };

			const userUpdateModel: UserUpdateModelDto = { ...values.userUpdateModel, phoneNumbers: values.userUpdateModel.phoneNumbers.map((phone) => ({ id: phone?.id || null, isDefault: true, code: phone.code, number: phone.number })) };

			const request = new UpdatePrintCenterRequest(values.printCenterUpdateModel, userUpdateModel, createOrUpdateCorporateModel);

			const response = await printCenterService.updatePrintCenter(request);

			if (!response.isSuccess) throw '';

			setEditedPrintCenter('');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPrintCenterDetail();
	}, [getPrintCenterDetail]);

	useEffect(() => {
		if (!printCenter || !formikRef.current) return;

		formikRef.current?.setFieldValue('printCenterUpdateModel', { userId: printCenter.userId, userStatuId: printCenter.userStatuId });
		formikRef.current?.setFieldValue('userUpdateModel', { id: printCenter.userId, name: printCenter.personInfo?.name, surname: printCenter.personInfo?.surname, email: printCenter.email, phoneNumbers: printCenter.phoneNumbers, isForeignNation: printCenter.isForeignNation });
		formikRef.current?.setFieldValue('createOrUpdateCorporateModel', { userId: printCenter.userId, businessName: printCenter.corporateInfo?.businessName, feinNumber: printCenter.corporateInfo?.feinNumber });
	}, [printCenter]);

	return (
		<Dialog visible={editedPrintCenter ? true : false} onHide={() => setEditedPrintCenter('')} header="Edit Print Center">
			{!loading ? (
				<Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
					{({ values, handleChange }) => (
						<Form>
							<div className="grid">
								<div className="col-12 sm:col-6">
									<TextInput label="Business Name" name="createOrUpdateCorporateModel.businessName" value={values.createOrUpdateCorporateModel?.businessName} onChange={handleChange} placeholder="Enter business name" />
								</div>
								<div className="col-12 sm:col-6">
									<TextMaskInput label="FEIN Number" mask="99-9999999" name="createOrUpdateCorporateModel.feinNumber" value={values.createOrUpdateCorporateModel?.feinNumber || ''} onChange={handleChange} placeholder="Enter FEIN number" />
								</div>
								<div className="col-12 sm:col-6">
									<TextInput label="Print Center Name" name="userUpdateModel.name" value={values.userUpdateModel.name} onChange={handleChange} placeholder="Enter print center name" />
								</div>
								<div className="col-12 sm:col-6">
									<TextInput label="Surname" name="userUpdateModel.surname" value={values.userUpdateModel.surname} onChange={handleChange} placeholder="Enter print center surname" />
								</div>
								<div className="col-12 sm:col-6">
									<TextInput label="Email" name="userUpdateModel.email" value={values.userUpdateModel.email} onChange={handleChange} placeholder="Enter print center email" />
								</div>
								<div className="col-12 sm:col-6">
									<div className="flex gap-2">
										<div className="w-3">
											<DropdownInput label="Code" name="userUpdateModel.phoneNumbers[0].code" options={prefixOptions} value={values.userUpdateModel.phoneNumbers[0]?.code} onChange={handleChange} placeholder="Code" />
										</div>
										<div className="w-9">
											<TextMaskInput label="Phone" name="userUpdateModel.phoneNumbers[0].number" unmask={true} mask="(999) 999-9999" value={values.userUpdateModel.phoneNumbers[0]?.number} placeholder="Enter print center phone number" onChange={handleChange} />
										</div>
									</div>
								</div>
								<div className="col-12 sm:col-6">
									<TextMaskInput label="SSN Number" mask="999-99-9999" name="userUpdateModel.ssnNumber" value={values.userUpdateModel?.ssnNumber || ''} onChange={handleChange} placeholder="Enter ssn number" />
								</div>
								<div className="col-12 sm:col-6">
									<DropdownInput label="User Status" name="printCenterUpdateModel.userStatuId" options={StatusOptions} value={values.printCenterUpdateModel.userStatuId} onChange={handleChange} placeholder="Select a statu" />
								</div>
								<div className="col-12 mt-4">
									<div className="flex justify-content-between">
										<div>
											<Checkbox name="userUpdateModel.isForeignNation" checked={values.userUpdateModel.isForeignNation} onChange={handleChange} />
											<span className="ml-1">Foreign Nation</span>
										</div>

										<Button type="submit" label="Update Print Center" loading={loading} className="h-full px-6" />
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			) : (
				<div className="grid">
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
				</div>
			)}
		</Dialog>
	);
};

export default PrintCenterUpdate;
