import { Outlet } from 'react-router-dom';

const AccountLayout = () => {
	return (
		<div className="container relative" style={{ minHeight: '15rem' }}>
			<Outlet />
		</div>
	);
};

export default AccountLayout;
