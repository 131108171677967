import { Button, ButtonProps } from 'primereact/button';
import { cloneElement, useEffect, useRef, useState } from 'react';
import styles from './DropdownButton.module.scss';

enum Direction {
	Right = 'right',
	Left = 'left'
}

interface Props extends ButtonProps {
	direction?: `${Direction}`;
	menuItems?: JSX.Element[];
}

const DropdownButton = ({ menuItems, direction, ...props }: Props) => {
	const ulRef = useRef<HTMLUListElement>(null);
	const [open, setOpen] = useState<boolean>(false);

	const handleToggle = () => setOpen((current) => !current);

	const handleBlur = (e: any) => {
		if (e.nativeEvent.explicitOriginalTarget && e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
			return;
		}

		if (open) {
			setTimeout(() => {
				setOpen(false);
			}, 200);
		}
	};

	useEffect(() => {
		if (!open || !ulRef.current) return;

		ulRef.current.focus();
	}, [open]);

	return (
		<div className={`${styles.dropdown}${open ? ' ' + styles.open : ''}`}>
			<Button onClick={handleToggle} {...props} />
			{open && !!menuItems && menuItems.length > 0 ? (
				<ul ref={ulRef} tabIndex={0} onBlur={handleBlur} className={`${styles.menu} ${direction === 'right' ? styles.right : styles.left}`}>
					{menuItems.map((item, index) => (
						<li key={index} className={styles.menuItem + ' white-space-nowrap text-color'}>
							{cloneElement(item, {
								onClick: () => {
									item.props.onClick();
									setOpen(false);
								}
							})}
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export default DropdownButton;
