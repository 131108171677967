import React, { createContext, ReactNode, useState, useCallback } from 'react';
import { AnswerDto } from '../../../../../features/surveys/dtos/answer-dto';
import { useAppSelector } from '../../../../../redux/hooks';
import { User } from '../../../../../models/dtos/auth/users/user';
import { SurveyAnswerDtoRequest, SurveyCreateAnswerRequest } from '../../../../../features/surveys/_common/survey-create-answer/survey-create-answer.request';
import surveyService from '../../../../../features/surveys/_common/survey.service';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../../../../../models/dtos/auth/auth';
import userService from '../../../../../services/user-service';
import localStorageHelper from '../../../../../helpers/local-storage.helper';

export interface WizardStep {
	isCompleted: boolean;
	isCanNext: boolean;
	index: number;
	questionId: string;
	component: ReactNode;
}

export interface WizardContextType {
	user: User | undefined;
	auth: Auth | null;
	step: WizardStep | undefined;
	setStep: (index: number) => void;
	back: () => void;
	wizardSteps: WizardStep[];
	setWizardSteps: React.Dispatch<React.SetStateAction<WizardStep[]>>;
	answers: AnswerDto[];
	setAnswers: React.Dispatch<React.SetStateAction<AnswerDto[]>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	sellingCustomProducts: boolean;
	setSellingCustomProducts: React.Dispatch<React.SetStateAction<boolean>>;
	submitWizard: () => Promise<void>;
	receiveNewsChecked: boolean;
	setReceiveNewsChecked: React.Dispatch<React.SetStateAction<boolean>>;
	termsAndConditionsChecked: boolean;
	setTermsAndConditionsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateWizardContext = createContext<WizardContextType | undefined>(undefined);

interface Props {
	children: ReactNode;
}

const WizardContextProvider: React.FC<Props> = ({ children }) => {
	const navigate = useNavigate();
	const auth = useAppSelector((state) => state.auth.data);
	const user = useAppSelector((state) => state.auth.data?.user);

	const [step, setInternalStep] = useState<WizardStep | undefined>();
	const [answers, setAnswers] = useState<AnswerDto[]>([]);
	const [loading, setLoading] = useState(false);
	const [wizardSteps, setWizardSteps] = useState<WizardStep[]>([]);
	const [sellingCustomProducts, setSellingCustomProducts] = useState(false);
	const [receiveNewsChecked, setReceiveNewsChecked] = useState(false);
	const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);

	const setStep = (index: number) => {
		setInternalStep(wizardSteps[index]);
		setAnswers((prev) => prev.filter((item) => item.questionId !== wizardSteps[index].questionId));
	};

	const back = () => {
		if (step && step.index > 0) {
			setInternalStep(wizardSteps[step.index - 1]);
			setAnswers((prev) => prev.filter((item) => item.questionId !== wizardSteps[step.index - 1].questionId));
		}
	};

	const submitWizard = async () => {
		setLoading(true);
		try {
			const termsResponse = await userService.upsertTerms({
				userId: user!.userId,
				acceptTermOfService: termsAndConditionsChecked,
				readPrivacyPolicy: termsAndConditionsChecked,
				acceptReceiveNews: receiveNewsChecked
			});

			if (!termsResponse.isSuccess) throw "";

			const request: SurveyCreateAnswerRequest = new SurveyCreateAnswerRequest({
				answers: answers.map((answer) => ({
					userId: answer.userId,
					surveyQuestionOptionId: answer.surveyQuestionOptionId,
					text: answer.text
				}))
			});

			const response = await surveyService.createAnswer(request);

			if (!response.isSuccess) throw'';
		} catch (error) {
		} finally {
			localStorageHelper.remove('wizardAvaliable');
			navigate('/');
			setLoading(false);
		}
	};

	return (
		<CreateWizardContext.Provider
			value={{
				auth,
				user,
				step,
				setStep,
				back,
				wizardSteps,
				setWizardSteps,
				answers,
				setAnswers,
				loading,
				setLoading,
				sellingCustomProducts,
				setSellingCustomProducts,
				submitWizard,
				receiveNewsChecked,
				setReceiveNewsChecked,
				termsAndConditionsChecked,
				setTermsAndConditionsChecked
			}}>
			{children}
		</CreateWizardContext.Provider>
	);
};

export { WizardContextProvider, CreateWizardContext };
