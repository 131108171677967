import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ProductForImport } from 'features/products/_common/dtos/product-for-import.dto';
import TreeNode from 'primereact/treenode';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import { GetCategoriesRequest } from 'features/categories/_common/get-categories/get-categories.request';
import categoryService from 'features/categories/_common/category.service';
import { TreeSelect, TreeSelectChangeParams } from 'primereact/treeselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

type Props = {
	visible: boolean;
	categoryId: string;
	product?: ProductForImport;
	setVisible: (state: boolean) => void;
	setCategoryId: (state: string) => void;
	importProduct?: (product: ProductForImport) => void;
};

const CategorySelect = ({ visible, categoryId, product, setVisible, setCategoryId, importProduct }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);

	const generateTreeNode = (category: CategoryBaseDto): TreeNode => ({ key: category.id, label: category.name, data: category.media, selectable: category.canBeAddProduct });

	const mapCategoryToParent = (parentCategory: CategoryBaseDto, treeNode: TreeNode) => {
		if (parentCategory.subCategories === undefined) return;

		parentCategory.subCategories.forEach((_subCategory) => {
			if (treeNode.children === undefined) treeNode.children = [];

			const treeNodeForCurrentSubCategory = generateTreeNode(_subCategory);

			treeNode.children?.push(treeNodeForCurrentSubCategory);

			mapCategoryToParent(_subCategory, treeNodeForCurrentSubCategory);
		});
	};

	const mapCategoriesForTree = (categories: CategoryBaseDto[]) => {
		let treeNode: TreeNode[] = [];

		categories.forEach((_category) => {
			const treeNodeForCurrentCategory = generateTreeNode(_category);

			mapCategoryToParent(_category, treeNodeForCurrentCategory);

			treeNode.push(treeNodeForCurrentCategory);
		});

		return treeNode;
	};

	const getCategories = async () => {
		try {
			setLoading(true);
			const request = new GetCategoriesRequest();
			const response = await categoryService.getAll(request);
			if (!response.isSuccess || !response.data) throw '';

			const categories = mapCategoriesForTree(response.data);
			setTreeCategories(categories);
		} catch (error) {
			setTreeCategories([]);
		} finally {
			setLoading(false);
		}
	};

	const handleHide = () => setVisible(false);

	const handleChange = (event: TreeSelectChangeParams) => {
		if (!event.value || typeof event.value !== 'string') return;

		setCategoryId(event.value as string);
	};

	const handleSave = () => {
		if (!product) return;

		handleHide();
		importProduct?.(product);
	};

	useEffect(() => {
		getCategories();
	}, []);

	return (
		<Dialog header="Select Category for Import" visible={visible} onHide={handleHide} style={{ maxWidth: '40rem' }}>
			<div className="py-4 relative">
				<TreeSelect filter value={categoryId} options={treeCategories} selectionMode="single" metaKeySelection={false} onChange={handleChange} placeholder="Select Item" className="w-full" />

				<div className="text-right mt-4">
					<Button onClick={handleSave} label="Save" disabled={!categoryId} icon="pi pi-fw pi-save" className="px-4 m-0" />
				</div>

				{!!loading ? (
					<div className="custom-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}
			</div>
		</Dialog>
	);
};

export default CategorySelect;
