import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetUserProductVariantPricesRequest {
	userId: string = '';
	productIdentities: string[];
	productVariantIdentities: string[] = [];
	pagination = new PaginationDto();

	constructor(userId: string, productIdentities: string[], pagination: PaginationDto, productVariantIdentities?: string[]) {
		this.userId = userId;
		this.productIdentities = productIdentities;
		if (!!productVariantIdentities) this.productVariantIdentities = productVariantIdentities;
		if (!!pagination) this.pagination = pagination;
	}

	get getConvertUriExtension() {
		return `user/get-list`;
	}
}