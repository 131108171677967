export class LoginWithAutomaticLoginRequest {
	accessToken: string;

	constructor(accessToken: string) {
		this.accessToken = accessToken;
	}

	get getConvertUriExtension() {
		return `login-with-automatic-login`;
	}
}
