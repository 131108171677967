const toCamelCase = (obj: any): any => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(toCamelCase);
    }
    return Object.keys(obj).reduce((acc, key) => {
        const modifiedKey = key.replace(/(_\w)/g, k => k[1].toUpperCase());
        acc[modifiedKey] = toCamelCase(obj[key]);
        return acc;
    }, {} as { [key: string]: any });
}

const camelCaseHelper = {toCamelCase};

export default camelCaseHelper;
