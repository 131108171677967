import { ConfirmSideWithDesign } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { CalculatedDto } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.response';
import { CreateManuelOrderRequest } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { CreateOrder } from '../CreateOrder';
import { StepProducts } from '../steps/products/StepProducts';
import { StepReviewOrder } from '../steps/review-order/StepReviewOrder';
import { StepShipping } from '../steps/shipping/StepShipping';

export enum StepWithComponentEnum {
	Unknown = 0,
	Products = 1,
	Shipping = 2,
	ReviewOrder = 3
}

export interface StepWithComponent {
	label: string;
	value: StepWithComponentEnum;
	component: JSX.Element;
	isCompleted: boolean;
	isCanNext: boolean;
}

export const orderSteps: StepWithComponent[] = [
	{ label: 'Products', value: StepWithComponentEnum.Products, isCompleted: false, isCanNext: false, component: <StepProducts /> },
	{ label: 'Shipping', value: StepWithComponentEnum.Shipping, isCompleted: false, isCanNext: false, component: <StepShipping /> },
	{ label: 'Review order', value: StepWithComponentEnum.ReviewOrder, isCompleted: false, isCanNext: false, component: <StepReviewOrder /> }
];

export type CreateOrderContextType = {
	coreProductIdFromUrl: string | undefined;
	confirmSideWithDesigns: ConfirmSideWithDesign[];
	setConfirmSideWithDesigns: React.Dispatch<React.SetStateAction<ConfirmSideWithDesign[]>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	loadingText: string;
	setLoadingText: React.Dispatch<React.SetStateAction<string>>;
	request: CreateManuelOrderRequest;
	setRequest: React.Dispatch<React.SetStateAction<CreateManuelOrderRequest>>;
	orderStep: StepWithComponent;
	setOrderStep: React.Dispatch<React.SetStateAction<StepWithComponent>>;
	shippingRates: ShippingRateDto[] | [];
	setShippingRates: React.Dispatch<React.SetStateAction<ShippingRateDto[] | []>>;
	selectedPaymentCard: string | undefined;
	setSelectedPaymentCard: React.Dispatch<React.SetStateAction<string | undefined>>;
	selectedRate: ShippingRateDto | undefined;
	setSelectedRate: React.Dispatch<React.SetStateAction<ShippingRateDto | undefined>>;
	calculatedPrice: CalculatedDto | undefined;
	setCalculatedPrice: React.Dispatch<React.SetStateAction<CalculatedDto | undefined>>;
	isShowShippingForm: boolean;
	setIsShowShippingForm: React.Dispatch<React.SetStateAction<boolean>>;
	isNewShippingRatesCalculateRequired: boolean;
	setIsNewShippingRatesCalculateRequired: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateOrderContext = React.createContext<CreateOrderContextType | null>(null);

export const CreateOrderContextProvider = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const { coreProductId } = useParams();
	const [confirmSideWithDesigns, setConfirmSideWithDesigns] = useState<ConfirmSideWithDesign[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('');
	const [isShowShippingForm, setIsShowShippingForm] = useState<boolean>(true);
	const [request, setRequest] = useState<CreateManuelOrderRequest>(new CreateManuelOrderRequest({ userId: user?.userId }));
	const [orderStep, setOrderStep] = useState<StepWithComponent>(orderSteps[0]);
	const [shippingRates, setShippingRates] = useState<ShippingRateDto[] | []>([]);
	const [selectedPaymentCard, setSelectedPaymentCard] = useState<string | undefined>(undefined);
	const [selectedRate, setSelectedRate] = useState<ShippingRateDto | undefined>(undefined);
	const [calculatedPrice, setCalculatedPrice] = useState<CalculatedDto | undefined>(undefined);
	const [isNewShippingRatesCalculateRequired, setIsNewShippingRatesCalculateRequired] = useState<boolean>(false);

	return (
		<CreateOrderContext.Provider
			value={{
				coreProductIdFromUrl: coreProductId,
				loading,
				setLoading,
				loadingText,
				setLoadingText,
				request,
				setRequest,
				orderStep,
				setOrderStep,
				confirmSideWithDesigns,
				setConfirmSideWithDesigns,
				shippingRates,
				setShippingRates,
				selectedPaymentCard,
				setSelectedPaymentCard,
				selectedRate,
				setSelectedRate,
				calculatedPrice,
				setCalculatedPrice,
				isShowShippingForm,
				setIsShowShippingForm,
				isNewShippingRatesCalculateRequired,
				setIsNewShippingRatesCalculateRequired
			}}>
			<CreateOrder />
		</CreateOrderContext.Provider>
	);
};
