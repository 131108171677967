import minMaxPriceHandler from 'components/pages/products/components/MinMaxPriceHandler';
import { GetProductForDetailByIdResponse, ProductDto } from 'features/products/_common/get-product-for-detail-by-id/get-product-for-detail-by-id.response';
import auditHelper from 'helpers/audit.helper';
import currencyHelper from 'helpers/curreny.helper';
import { User } from 'models/dtos/auth/users/user';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styles from './VariantsAccordion.module.scss';
import { CoreProductDto, Variant } from 'features/products/_common/get-core-product/get-core-product.response';

interface VariationsData {
	sizeName: string;
	sizeId: string;
	minMaxPrice: string;
	discountedMinMaxPrice: string;
	planName: string;
	variations: Variant[];
}

type props = { product: CoreProductDto };

const VariantsAccordionV2 = ({ product }: props) => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const [variantsData, setVariantsData] = useState<VariationsData[]>([]);
	const [activeIndexes, setActiveIndexes] = useState<string[]>([]);

	const headerStockHandler = (variantData: VariationsData) => (variantData.variations.find((vari) => (vari.stock?.quantity || 0) > 0) ? 'In Stock' : 'Out of stock');

	const minMaxPriceCalculator = (variants: Variant[], isDiscountedPrice: boolean) => {
		var lowest = Number.POSITIVE_INFINITY;
		var highest = Number.NEGATIVE_INFINITY;
		var tmp;

		if (!isDiscountedPrice) {
			for (var i = variants.length - 1; i >= 0; i--) {
				tmp = variants[i].salePrice.formattedPricePerUnit;
				if (tmp < lowest) lowest = tmp;
				if (tmp > highest) highest = tmp;
			}
		} else {
			for (var i = variants.length - 1; i >= 0; i--) {
				tmp = variants[i].discountedSalePrice.price.formattedPricePerUnit;
				if (tmp < lowest) lowest = tmp;
				if (tmp > highest) highest = tmp;
			}
		}

		return lowest == highest ? currencyHelper.formatPrice(lowest) : currencyHelper.formatPrice(lowest) + ' - ' + currencyHelper.formatPrice(highest);
	};

	useEffect(() => {
		if (!product) return;

		const tempVariationData = product?.variants
			.map((vari) => {
				return {
					sizeName: vari.size.name,
					sizeId: vari.size.id,
					sizePriority: vari.size.priority,
					minMaxPrice: minMaxPriceCalculator(
						product.variants.filter((t) => t.size.id === vari.size.id),
						false
					),
					discountedMinMaxPrice: minMaxPriceCalculator(
						product.variants.filter((t) => t.size.id === vari.size.id),
						true
					),
					planName: vari.discountedSalePrice.planName
				};
			})
			.filter((data, index, self) => index === self.findIndex((t) => t.sizeId === data.sizeId));

		const formattedVariationData = tempVariationData
			.map((temp) => {
				return { ...temp, variations: product.variants.filter((vari) => vari.size.id === temp.sizeId) };
			})
			.sort((a, b) => a.sizePriority - b.sizePriority);

		setVariantsData(formattedVariationData);
	}, [product]);

	const isActiveIndex = (sizeId: string) => !![...activeIndexes].find((active) => active === sizeId);

	const toggleActiveIndex = (sizeId: string) => (isActiveIndex(sizeId) ? setActiveIndexes(activeIndexes.filter((active) => active !== sizeId)) : setActiveIndexes([...activeIndexes, sizeId]));

	return (
		<div className={styles.variantWrapper}>
			<div className={styles.variantHeader}>
				<span className={styles.variantHeaderTitle}>Size</span>
				<span className={styles.variantHeaderTitle}>Color</span>
				{product.variants.find((_variant) => _variant.stock !== null) ? <span className={styles.variantHeaderTitle}>Inventory</span> : null}
				<span className={styles.variantHeaderTitle}>Price</span>
				<span style={{ width: 28 }} />
			</div>

			{!!variantsData && variantsData.length > 0 ? (
				<React.Fragment>
					{variantsData.map((variantData: VariationsData, index: number) => (
						<div key={index} className={styles.variantBody}>
							<div className={styles.variantItemHeader}>
								<span className={styles.variantItemHeaderTitle}>{variantData.sizeName}</span>

								<span className={styles.variantItemHeaderTitle}>{variantData?.variations?.length || 0} Colors</span>

								{product.variants.find((_variant) => _variant.stock !== null) ? <span className={styles.variantItemHeaderTitle}>{headerStockHandler(variantData)}</span> : null}

								<span className={`${styles.variantItemHeaderTitle} ${styles.price}`}>
									<span className={`${styles.original} text-pink-500`}>{variantData.minMaxPrice}</span>

									{user?.accountType === 'Enterprise' || variantData.discountedMinMaxPrice === '$0.00' ? null : (
										<span className={styles.discounted}>
											{variantData.discountedMinMaxPrice} {variantData.planName} plan
										</span>
									)}
								</span>

								<span className={styles.variantItemHeaderArrow}>
									<span onClick={() => toggleActiveIndex(variantData.sizeId)} tabIndex={0} role="button" className={`pi pi-angle-down${isActiveIndex(variantData.sizeId) ? ` ${styles.opened}` : ''}`} />
								</span>
							</div>
							{!!variantData.variations && variantData.variations.length > 0 ? (
								<div className={`${styles.variantItem}${isActiveIndex(variantData.sizeId) ? ' ' + styles.activeItem : ''}`}>
									{variantData.variations.map((variant: Variant, index) => (
										<div key={index} className={styles.variantItemBody}>
											<span className={styles.variantItemHeaderTitle} />

											<span className={`${styles.variantItemHeaderTitle} ${styles.color}`}>
												<div className={styles.colorHex} style={{ '--bg-color-box': variant.color.hexCode } as CSSProperties} />
												<span className={styles.colorName}>{variant.color.name}</span>
											</span>

											{!!variant.stock ? <span className={styles.variantItemHeaderTitle}>{variant.stock.quantity > 0 ? 'In Stock' : 'Out of Stock'}</span> : null}

											<span className={`${styles.variantItemHeaderTitle} ${styles.price}`}>
												<span className={`${styles.original} text-pink-500`}>{currencyHelper.formatPrice(variant.salePrice.formattedPricePerUnit)}</span>

												{user?.accountType === 'Enterprise' || variant.discountedSalePrice.price.formattedPricePerUnit === 0 ? null : (
													<span className={styles.discounted}>
														{currencyHelper.formatPrice(variant.discountedSalePrice.price.formattedPricePerUnit)} {variant.discountedSalePrice.planName} plan
													</span>
												)}
											</span>

											<span style={{ width: 28 }} />
										</div>
									))}
								</div>
							) : null}
						</div>
					))}
				</React.Fragment>
			) : null}
		</div>
	);
};

export default VariantsAccordionV2;
