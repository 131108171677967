import { Dialog } from 'primereact/dialog';

type Props = {
	visible: boolean;
	setVisible: Function;
};
const PrivacyPolicy = ({ visible, setVisible }: Props) => {
	return (
		<Dialog visible={visible} header="Privacy Policy" modal onHide={() => setVisible(false)}>
			<span className="block">Printram, Inc.</span>
			<span className="block">3211 Stafford Rd </span>
			<p className="block">Missouri City, TX, USA</p>
			<span>Last Updated: Sep 27, 2023</span>

			<p>Thank you for choosing Printram (or formally known as Printram, Inc.). Before you start using our platform or visit our website, please take a moment to carefully review this Privacy Policy. It outlines how we safeguard your data and uphold your privacy.</p>

			<h4>A. Yours Responsibilities and Our </h4>
			<p className="text-500 text-lg">Summary: This policy is relevant to all individuals who use Printram, including registered users and visitors to our platform. When we handle the data of end-customers on behalf of Printram users, we function as a "data processor," and it is the responsibility of Printram merchants to ensure that their data is protected and managed in compliance with applicable laws.</p>

			<p className="font-bold">Who Does This Policy Apply To?</p>
			<p>This policy is applicable to you if you are a Printram customer, subscriber, or simply browsing our website.</p>
			<p>For your customers who make purchases of your products through Printram but are not registered users, we serve as the "data processor" on your behalf, meaning we only process their data to assist us in providing our services to you, as per your instructions or as mandated by law. It is your responsibility to ensure that their personal information is handled in accordance with relevant data protection regulations. This includes informing them about how service providers like us collect and utilize data on your behalf.</p>

			<p className="font-bold">Our Responsibilities:</p>
			<p>If you are a registered Printram customer or a visitor to our website, we function as the "data controller" for personal data. This means we determine the purposes and methods for processing your data.</p>

			<p className="font-bold">Your Responsibilities:</p>
			<p>Please take the time to read and understand this Privacy Policy. If you provide us with personal information about other individuals (e.g., if you are registering on someone else's behalf or transmitting data from your customers), or if others share your information with us, we will only use that information for the specific purposes for which it was provided to us. By submitting this information, you confirm that you have the legal authority to grant us permission to process it on your behalf in accordance with the terms outlined in this Privacy Policy.</p>

			<h4>B. When does Printram collect your data?</h4>
			<p className="text-500 text-lg">Summary: Printram gathers information during all your interactions with us, beginning with your initial visit to our website. Some data is collected automatically, while others require your explicit consent.</p>

			<p className="font-bold">We collect your data with your consent in the following situations:</p>
			<ul>
				<li>When you create an account with us.</li>
				<li>When you use our Product Designer tool to upload content to Printram.</li>
				<li>When you integrate Printram through various APIs, including our own and those of third-party storefronts and website builders.</li>
				<li>When you list products on third-party sales channels.</li>
				<li>When we correspond via email.</li>
				<li>When you reach out to our support and success teams (please note that we may also record call audio, and this will be disclosed during the call).</li>
				<li>When you engage with us on social media platforms.</li>
				<li>When you request a demonstration of Printram.</li>
				<li>When you participate in user research studies.</li>
				<li>When you opt-in to receive marketing messages.</li>
				<li>When you enter sweepstakes, contests, or register for promotional events.</li>
				<li>When you take part in our user referral program.</li>
			</ul>

			<p className="font-bold">We automatically collect your data when:</p>
			<ul>
				<li>You navigate through any section of our website.</li>
				<li>You utilize Printram to design and create products.</li>
				<li>You access and read our help section.</li>
				<li>You place orders for products through Printram.</li>
			</ul>

			<h4>C. Categories of data collected</h4>
			<p className="text-500 text-lg">Summary: Printram collects various types of data, each serving different purposes.</p>

			<p className="font-bold">Contact Information and Basic Details:</p>
			<p>This includes your name, address, phone number, and email address.</p>

			<p className="font-bold">Financial Information:</p>
			<p>We may collect details such as your PayPal account email and credit/debit card information.</p>

			<p className="font-bold">Data Related to Your Content and Products:</p>
			<p>This encompasses the products you've designed and any artwork you've uploaded.</p>

			<p className="font-bold">Contractual Data:</p>
			<p>Information regarding the sales you've conducted, customer data, store names, and order specifics.</p>

			<p className="font-bold">Identifying Information:</p>
			<p>Data that helps identify you, like your IP address, login details, browser type/version, time zone settings, browser plug-ins, geolocation information, operating system, and version.</p>

			<p className="font-bold">Usage Data:</p>
			<p>Information on how you use Printram, including your URL clickstreams (the path you take on our site), products/services viewed, page response times, download errors, duration of your visits to our pages, actions taken, and frequency of interactions.</p>

			<p className="font-bold">Sensitive Data: </p>
			<p>We want to assure you that we do not collect any "sensitive data" about you. This includes information related to your racial or ethnic origin, political opinions, religious/philosophical beliefs, trade union membership, genetic data, biometric data, health data, data concerning your sexual life or orientation, or any details related to offenses or alleged offenses.</p>

			<p className="font-bold">Children's Data: </p>
			<p>Printram is a business-to-business service designed and intended for use by individuals who are 18 years of age or older. We do not target or knowingly collect personal data from anyone under the age of 16. Our services are not intended for children.</p>

			<h4>D. How and why we use your data</h4>
			<p className="text-500 text-lg">Summary: In accordance with Data Protection Law, we are permitted to process your data only for specific reasons and when we have a legal basis to do so. Below, we outline the purposes for which we process your data and the legal justifications behind them.</p>

			<p className="font-bold">Ensuring the Functionality of Printram:</p>
			<p>This involves activities such as managing your requests, including product creation, integration with sales channels, transaction processing, order fulfillment, user authentication, storing your preferences, processing payments, and maintaining the technical infrastructure that supports our platform.</p>
			<ul>
				<p>
					<span className="font-bold">Legal basis for processing data: </span>
					<span>Contractual obligation, Legitimate interests</span>
				</p>
			</ul>

			<p className="font-bold">Enhancing the Printram Experience:</p>
			<p>We continually strive to improve Printram by conducting feature testing, engaging with feedback platforms and surveys, managing landing pages, analyzing user behavior and traffic patterns, optimizing our website's performance, and conducting data research, including profiling and the application of machine learning and similar techniques to your data. In some cases, we may enlist third-party assistance for these purposes.</p>
			<ul>
				<p>
					<span className="font-bold">Legal basis for processing data: </span>
					<span>Contractual obligation, Legitimate interests</span>
				</p>
			</ul>

			<p className="font-bold">Providing Customer Support:</p>
			<p>Our commitment to customer support involves notifying you of any updates or changes to our service. We also assist in resolving issues through live chat support, phone, or email, which includes addressing any technical glitches or bugs.</p>
			<ul>
				<p>
					<span className="font-bold">Legal basis for processing data: </span>
					<span>Contractual obligation</span>
				</p>
			</ul>

			<p className="font-bold">Marketing Activities (with Your Consent):</p>
			<p>With your explicit consent, we engage in marketing endeavors such as sending you emails and messages to inform you about new features, products, services, and relevant content.</p>
			<ul>
				<p>
					<span className="font-bold">Legal basis for processing data: </span>
					<span>Consent</span>
				</p>
			</ul>

			<h4>E. What these legal bases mean</h4>
			<span className="font-bold">Consent: </span>
			<span>Your information is processed for specific purposes to which you have granted explicit consent. You have the right to withdraw your consent at any time by contacting us at </span>
			<span className="font-bold underline">privacy@printram.com</span>
			<span> or by managing your data settings on your profile page.</span>

			<span className="font-bold">Contract: </span>
			<span>We process your data when it is necessary for the fulfillment of a contract you have with us, or when specific steps are required before entering into such a contract.</span>
			<p>Legitimate Interests: Processing your data is essential for our legitimate interests or the interests of a third party, provided that these interests do not override your own rights and interests. These legitimate interests encompass:</p>
			<ul>
				<p>Gaining insights from your interactions with our website or app.</p>
				<p>Ensuring the delivery, development, and enhancement of the Printram service.</p>
				<p>Enabling us to customize and improve our services and communications.</p>
				<p>Evaluating the effectiveness of our marketing campaigns.</p>
				<p>Strengthening data security.</p>
			</ul>
			<p>In each instance, these legitimate interests are valid only when they do not unduly infringe upon your rights and interests.</p>

			<h4>F. Your privacy rights and choices</h4>
			<p className="text-500 text-lg">Summary: Printram places you in control when it comes to your data and privacy. You can manage all your data preferences right from your Printram account page or by reaching out to us at privacy@printram.com. Here are the rights and choices you have:</p>

			<p className="font-bold">Your Rights</p>
			<ul>
				<p>
					<span className="font-bold">1. Access to Information: </span>
					<span>You have the right to access the information we hold about you. This includes details on what categories of data we process, the purposes behind data processing, the categories of third parties with whom we may share data, how long we retain data (or the criteria for such retention), and your other data-related rights. We'll provide this information within one month, unless it affects the rights and freedoms of others.</span>
				</p>
				<p>
					<span className="font-bold">2. Information Shared with Third Parties (California Residents): </span>
					<span>If you are a California resident, you can request information about the disclosure of your personal data to third parties for direct marketing purposes. Please email us at privacy@printram.com for such requests.</span>
				</p>
				<p>
					<span className="font-bold">3. Correction of Inaccurate Data: </span>
					<span> If you believe we have incorrect or inaccurate data, you can request corrections.</span>
				</p>
				<p>
					<span className="font-bold">4. Object to Profiling and Automated Decisions: </span>
					<span>You can object to us using your data for profiling or making automated decisions about you. We generally use data for these purposes to enhance your Printram experience.</span>
				</p>
				<p>
					<span className="font-bold">5. Data Portability: </span>
					<span>You have the right to receive a copy of your data in CSV or JSON format, making it easy to transfer to another service. If technically feasible, we can even assist in transferring data directly to the other service. This will not involve disclosing data about others.</span>
				</p>
				<p>
					<span className="font-bold">6. Right to Be Forgotten: </span>
					<span>You can request the deletion of your personal data if it is no longer needed for Printram purposes.</span>
				</p>
				<p>
					<span className="font-bold">7. Lodge a Complaint:</span>
					<span>If you have concerns about our data practices, please reach out to us first. If not resolved, EU residents can contact the relevant data authority. The U.S. does not have the same processes for such complaints, but you can contact us at privacy@printram.com, and we'll do our best to address your concerns</span>
				</p>
			</ul>

			<p className="font-bold">Your Choices</p>
			<ul>
				<p>
					<span className="font-bold">1. Providing Personal Data: </span>
					<span>You can choose not to provide us with personal data. However, please note that certain services, like transaction processing and order fulfillment, require personal data.</span>
				</p>
				<p>
					<span className="font-bold">2. Managing Cookies: </span>
					<span>You can turn off or block cookies in your browser settings. While you can still use the website and browse its pages, some services may not function effectively without cookies.</span>
				</p>
				<p>
					<span className="font-bold">3. Controlling Third-Party Data Collection: </span>
					<span>Various third parties offer opt-out options for data collection. You can manage these preferences using the provided links.</span>
				</p>
				<p>
					<span className="font-bold">4. Opting Out of Marketing: </span>
					<span> We will inform you if we plan to use your data for marketing purposes, including involvement of third parties. You can opt out by contacting us at customercare@printram.com or managing your data settings on your account page.</span>
				</p>
			</ul>

			<h4>G. How secure is the data we collect?</h4>
			<p className="text-500 text-lg">Summary: We take extensive measures to ensure the security of your data, but it's equally important for you to play a role in safeguarding your account information. If you suspect a data breach, take immediate action by changing your password and reaching out to our support team.</p>

			<p className="font-bold">Our Security Measures</p>
			<p>We have implemented physical, electronic, and managerial procedures to protect and maintain the integrity of the information we collect. For more details on our security efforts, please contact us at privacy@printram.com.</p>

			<p className="font-bold">Our Infrastructure</p>
			<p>Printify operates on servers and cloud systems that adhere to rigorous international standards. Access to our infrastructure is securely managed and limited to specific locations. Only designated employees have access, and additional security measures such as 2-factor authentication are in place.</p>
			<p>Data is stored in the United States on AWS/Azure servers and undergoes continuous backup. Our infrastructure's data centers and cloud services are fortified with physical access controls, intrusion and fire detection systems, and round-the-clock security personnel.</p>

			<p className="font-bold">Document Security</p>
			<p>All documents and data are version-controlled through Atlassian services, ensuring a permanent audit trail both at the database and storage levels.</p>

			<p className="font-bold">Monitoring</p>
			<p>We maintain 24/7, year-round monitoring of resources and infrastructure. Any alerts triggered by our monitoring system are promptly addressed. Printram utilizes third-party and in-house scans to test for vulnerabilities and protect our applications through Web Application Firewall (WAF) technology.</p>

			<p className="font-bold">Training and Audits</p>
			<p>We regularly conduct third-party audits to stay updated with the latest technologies and best practices for data protection. All Printram employees receive training in data security and are bound by strict confidentiality agreements.</p>

			<p className="font-bold">And please keep in mind:</p>
			<ul>
				<li>You provide personal data at your own risk, as no data transmission can be guaranteed 100% secure.</li>
				<li>The security of your username and password is your responsibility; please keep them confidential and secure.</li>
				<li>If you suspect a breach of your privacy, change your password immediately and contact us right away at customercare@printram.com or via Zendesk using the chat icon.</li>
			</ul>

			<h4>H. Where do we store the data?</h4>
			<p>The personal information we gather is handled at our offices located in San Francisco and Riga. Additionally, it may be processed at data processing facilities operated by third parties as mentioned below.</p>
			<p>When you provide us with your personal information, you are consenting to its transfer, storage, and processing by us. In the event that we transfer or store your information outside of the European Economic Area (EEA), we will take measures to ensure that your privacy rights remain safeguarded in accordance with the principles outlined in this Privacy Policy.</p>

			<h4>I. How long do we store your data?</h4>
			<p>We will retain and discontinue active use of any personally identifiable information related to you after a period of 6 months from your last interaction with Printram. Your personal data will be completely removed from our archives no later than 6 years from your last interaction with Printram or upon your request.</p>

			<h4>J. Third parties who process your data</h4>
			<p className="text-500 text-lg">Summary: Printram collaborates with various third-party partners worldwide to ensure the smooth operation of our platform. These partners include print providers who assist in offering and fulfilling products, as well as tools like Google Apps and Zendesk, which facilitate communication and service delivery to our users. This section outlines our collaborations, the purposes they serve, and where you can find more information regarding their privacy policies.</p>

			<p>To provide you with the best possible Printram experience, we often team up with third parties to deliver services that enhance our offerings. These collaborators include print partners who help us provide products and fulfill orders, services that support our internal and external communications, tools that keep our web platform and API running smoothly, and services that aid us in understanding platform usage for the development of new features, messages, and offers.</p>
			<p>In some instances, it becomes necessary for us to share your data with these partners to ensure the effective provision of these services. Rest assured, your data is only shared when absolutely required and in accordance with the protective measures and best practices outlined in this Privacy Policy.</p>
			<p>Below, you'll find details about our primary third-party service providers, the data shared or collected, and the reasons for such sharing.</p>

			<p className="font-bold">Infrastructure</p>

			<p className="font-bold">Microsoft Corporation Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>Data that identifies you</li>
				<li>Contractual data</li>
				<li>Data on how you use Printram</li>
			</ul>
			<p>Microsoft Azure is an ever-expanding set of cloud services that helps us build, manage, and deploy Printram.</p>

			<p className="font-bold">Analytics and Marketing</p>
			<p className="font-bold">Google Analytics Privacy policy</p>
			<ul>
				<li>How you use Printram</li>
				<li>Data that identifies you</li>
				<li>Cookies</li>
			</ul>
			<p>Google Analytics is a web analytics service: we use it to track your use of the service, and prepare reports on user activity.</p>

			<p className="font-bold">Google Adwords Privacy policy</p>
			<ul>
				<li>Data that identifies you</li>
				<li>Cookies</li>
			</ul>
			<p>Google Adwords is a marketing tool that allows us to reach potential users and grow the Printram audience.</p>

			<p className="font-bold">Facebook Inc. Privacy policy</p>
			<ul>
				<li>Data that identifies you</li>
				<li>Cookies</li>
			</ul>
			<p>Facebook business tools offer a number of services including analytics and advertising that allow us to track your use of the service, prepare reports on user activity and grow Printram’s audience.</p>

			<p className="font-bold">Mixpanel, Inc. Privacy policy</p>
			<ul>
				<li>Data on how you use Printram</li>
				<li>Data that identifies you</li>
				<li>Cookies</li>
			</ul>
			<p>This is a web analytics service: we use it to track your use of the service, and prepare reports on user activity.</p>

			<p className="font-bold">Hotjar Ltd. Privacy policy</p>
			<ul>
				<li>Data that identifies you</li>
				<li>How you use Printram</li>
				<li>Data from your content and products</li>
				<li>Cookies</li>
			</ul>
			<p>This is a web analytics service: we use it to track your use of the service, and prepare reports on user activity.</p>

			<p className="font-bold">Unbounce Marketing Solutions Inc. Privacy policy</p>
			<ul>
				<li>Data that identifies you</li>
				<li>How you use Printram</li>
				<li>Cookies</li>
			</ul>

			<p className="font-bold">Integrations (by your request)</p>

			<p className="font-bold">Shopify, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>Data that identifies you</li>
				<li>Contractual data</li>
				<li>Data from your content and products</li>
			</ul>
			<p>This integration allows you to sell your products on Shopify and allows us to automate order fulfillment to your end customers.</p>

			<p className="font-bold">Etsy, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>Data that identifies you</li>
				<li>Contractual data</li>
				<li>Data from your content and products</li>
			</ul>
			<p>This integration allows you to sell your products on Etsy and allows us to automate order fulfillment to your end customers.</p>

			<p className="font-bold">Bubblestorm Management (Pty) Ltd (WooCommerce.com) Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>Data that identifies you</li>
				<li>Contractual data</li>
				<li>Data from your content and products</li>
			</ul>
			<p>This integration allows you to sell your products on Woocommerce and allows us to automate order fulfillment to your end customers.</p>

			<p className="font-bold">Google, Inc. Privacy policy</p>
			<ul>
				<li>Data that identifies you</li>
				<li>How you use Printram</li>
				<li>Contact details</li>
				<li>Data from your content and products</li>
				<li>Cookies</li>
			</ul>
			<p>This enables users to connect Google services like Google Drive, where images may be stored.</p>

			<p className="font-bold">Communications and Research</p>

			<p className="font-bold">Sendgrid, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>How you use Printram</li>
				<li>Contractual data</li>
			</ul>
			<p>We use this service for sending, storing and tracking emails.</p>

			<p className="font-bold">Aftership, Ltd. Privacy policy</p>
			<ul>
				<li>Contractual data</li>
			</ul>
			<p>We use this service for delivering tracking information on your orders.</p>

			<p className="font-bold">The Rocket Science Group LLC d/b/a MailChimp Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>How you use Printram</li>
				<li>Data that identifies you</li>
			</ul>
			<p>We use this service for sending emails, managing mailing lists, and/or delivering newsletters about Product updates and changes.</p>

			<p className="font-bold">Typeform S.L. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>How you use Printram</li>
				<li>Data that identifies you</li>
				<li>Contractual data</li>
				<li>Data from your content and products</li>
			</ul>
			<p>We use this service to learn about user needs, to facilitate order issue submission and to understand and improve Printram services by capturing feedback from our users.</p>

			<p className="font-bold">Zendesk, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>How you use Printram</li>
				<li>Data from your content and products</li>
				<li>Contractual data</li>
				<li>Data that identifies you</li>
				<li>Cookies</li>
			</ul>
			<p>We use this service for customer communications, user interaction, helpdesk assistance, in app messaging and improving our platform and service.</p>

			<p className="font-bold">Google, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>How you use Printram</li>
				<li>Data from your content and products</li>
				<li>Contractual data</li>
				<li>Data that identifies you</li>
				<li>Cookies</li>
			</ul>
			<p>We use Google Suite for business for customer communications, user interaction, User Research, and improving our platform and service.</p>

			<p className="font-bold">Formagrid, Inc. (airtable) Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>How you use Printram</li>
				<li>Data from your content and products</li>
				<li>Contractual data</li>
				<li>Data that identifies you</li>
			</ul>
			<p>We use Airtable to store and analyze data for purposes of research and product development.</p>

			<p className="font-bold">Atlassian Pty Ltd Privacy policy</p>
			<ul>
				<li>How you use Printram</li>
				<li>Data from your content and products</li>
				<li>Contractual data</li>
				<li>Data that identifies you</li>
			</ul>
			<p>We use Atlassian products to store and analyze data for purposes of research and product development, to solve bugs and build out the Printram Platform.</p>

			<p className="font-bold">Payments</p>

			<p className="font-bold">Stripe, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>Financial information</li>
				<li>Cookies</li>
				<li>Contractual data</li>
			</ul>
			<p>This service processes payments for us.</p>

			<p className="font-bold">Paypal, Inc. Privacy policy</p>
			<ul>
				<li>Contact details</li>
				<li>Financial information</li>
				<li>Cookies</li>
				<li>Contractual data</li>
			</ul>
			<p>This service processes payments for us.</p>

			<h4>K. Cookies</h4>
			<p>We use cookies. Unless you adjust your browser settings to refuse cookies, we (and these third parties) will issue cookies when you interact withPrintram. These may be ‘session’ cookies, meaning they delete themselves when you leavePrintram, or ‘persistent’ cookies which do not delete themselves and help us recognise you when you return so we can provide a tailored service.</p>

			<p className="font-bold">How can I block cookies?</p>
			<p>You can block cookies by activating a setting on your browser allowing you to refuse the setting of cookies. You can also delete cookies through your browser settings. If you use your browser settings to disable, reject, or block cookies (including essential cookies), certain parts of our website will not function fully. In some cases, our website may not be accessible at all. Please note that where third parties use cookies we have no control over how those third parties use those cookies.</p>

			<p className="font-bold">Which specific cookies do we use?</p>
			<p className="font-bold">Google, Inc.</p>
			<p>Google Analytics uses cookies to see information on the activities of visitors to our website and users of our service, including page views, source and time spent on Printram. The information is depersonalized and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy.</p>
			<p>
				To opt out of being tracked by Google Analytics across all websites click{' '}
				<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" className="font-bold underline cursor-pointer">
					here.
				</a>
			</p>

			<p className="font-bold">Zendesk, Inc.</p>
			<p>Zendesk uses cookies to recognise particular people who have interacted with Zendesk through Printram previously. This may be visitors to our website, Printram users or counterparties.</p>
			<p>You may refuse the transmission of your Information by opting out (contact them directly for more information), however please note that you will delete the opt out cookie when you delete your cookies in your browser settings.</p>

			<p className="font-bold">Mixpanel, Inc.</p>
			<p>Mixpanel uses cookies to help analyze how visitors arrive at and use the service, and provides us with statistical reports on activity. The information generated by the cookies or other technologies about your use of the Services (including IP address) is transmitted to Mixpanel and potentially stored on servers in the United States. Mixpanel will use this information to compile reports for us.</p>
			<p>
				To opt out of being tracked by Mixpanel across all websites click{' '}
				<a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information" className="font-bold underline cursor-pointer">
					here.
				</a>
			</p>

			<p className="font-bold">Hotjar, Inc.</p>
			<p>Hotjar uses cookies to see information on the activities of visitors to our website and users of our service, including page views, source and time spent on Printram. The information is depersonalized and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy.</p>
			<p>
				To opt out of being tracked by Hotjar across all websites click{' '}
				<a href="https://www.hotjar.com/policies/do-not-track/" className="font-bold underline cursor-pointer">
					here.
				</a>
			</p>

			<p className="font-bold">Facebook, Inc.</p>
			<p>Facebook uses cookies if visitors have a Facebook account, use the Facebook Products, including their website and apps, or visit other websites and apps that use the Facebook Products (including the Like button or other Facebook Technologies). Cookies enable Facebook to offer the Facebook Products to visitors and to understand the information they receive about visitors, including information about their use of other websites and apps, whether or not they are registered or logged in.</p>
			<p>
				To opt out of being tracked by Facebook by following the instructions{' '}
				<a href="https://www.facebook.com/policies/cookies/" className="font-bold underline cursor-pointer">
					here.
				</a>
			</p>

			<h4>L. What if we change this Policy?</h4>
			<p>We reserve the right to make changes to this Privacy Policy at any time to reflect changes in the law, our data collection and use practices, the features of our Services, or advances in technology. Please check this page periodically for changes. If we make any material changes to this Privacy Policy, we will notify you via the email address provided for Registered Users and we will also post a notice on our home page and post the updated Privacy Policy here.</p>
			<p>The date the Privacy Policy was last revised is identified at the top of the page. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will mean you consent to and accept those changes. If you do not consent to such changes, you should stop using our Services.</p>
			<p>
				Contact us at any time at <span className="font-bold underline">customercare@printram.com</span> if you have more questions about this privacy policy, your rights, or our privacy practices.
			</p>

			{/* <h5>Welcome to Printram Privacy Policy!</h5>
			<p>Printram, Inc. (“we”, “our”, “us”, or “Printram”) respects the privacy of its users and is fully committed to protect their personal data and use it in accordance with data privacy laws. This Privacy Policy describes how we collect, use, and process any personal data that we collect from you—or you provide to us—in connection with your use of our website (www.Printram.co) or our mobile apps and our print-on-demand services (collectively, “Services”). By accessing or using our Services, you signify your understanding of the terms set out in this Privacy Policy.</p>
			<p>We do not knowingly collect, maintain, disclose, or sell the personal information about users under the age of sixteen (16). If you are under the age of 16, please do not use our Services. If you are under the age of 16 and have used our Services, please contact us at the email address below so that we may delete your personal information.</p>
			<p>If you use our Services only for your personal use, you are to be considered as the “User” and for the purpose of the General Data Protection Regulation (“GDPR”) and the UK General Data Protection Regulation (as defined by the UK Data Protection Act 2018 as amended by the Data Protection, Privacy and Electronic Communications (Amendments etc) (EU Exit) Regulations 2019), we are the data controller.</p>
			<p>If you use our Services to execute orders and deliver products to third parties, you are to be considered as the “Merchant” and with regard to your contact details and the other data listed in Section 1 below, we are the data controller. Where we act on a Merchant’s behalf to fulfil an order with regard to the Merchant’s customer, we are a data processor: please see our Data Processing Terms.</p>
			<p>Note that while our Services may contain links to other websites or services, we are not responsible for each respective website’s or service’s privacy practices and encourage you to be aware of this when you leave our Services and carefully read the privacy statements of each and every website and service you visit. This Privacy Policy does not apply to third-party websites and services. If you wish to contact Printram regarding your personal data or this Privacy Policy, please contact us at support@Printram.com</p>

			<h5>1. Information we collect</h5>
			<h5>1.1. Information collected about Users and Merchants and how we use it</h5>
			<p>Where you are a User and it is necessary to fulfil our contract with you for the purposes of providing, maintaining, or improving our products and Services (including, to the extent permitted by applicable law, any matters in our legitimate interests with respect to the Services), we will conﬁrm your identity, contact you, provide customer support (including via chat, in the comment section of our blog, or other platforms, where you may reach us), operate your account with us and invoice you. For the aforementioned purposes, we collect information that may contain the following personal data:</p>
			<p>Name;</p>
			<p>Company name;</p>
			<p>Shipping information;</p>
			<p>Email address and phone number;</p>
			<p>Payment and billing information (payment method details, first and last digits of your payment card);</p>
			<p>Order handling information</p>
			<p>If you have given your consent when registering your account, when subscribing to our newsletter or blog, or shared your email address or other personal data with us to receive any other information (for example, our list of sub-processors), we will process your email address to send you the informative and/or promotional materials, to which you have subscribed to, for example, newsletters, advertisements of our Services and other information about our Services that you have requested. At any point in time you can unsubscribe from receiving the above-mentioned information in our email footers and through your notiﬁcation settings on Printram. For Merchants, we will not use the contact details of your customers to directly market or advertise our Services to them.</p>
			<p>We obtain location information you provide in your profile or your IP address. We use and store information about your location to provide features and to improve and customise the Services, for example, for Printram’s internal analytics and performance monitoring; localisation, regional requirements, and policies for the Services; for local content, search results, and recommendations; for delivery and mapping services; and (using non-precise location information) marketing.</p>
			<p>When you call our customer support phone line, we may monitor or record the call to ensure the quality of our customer support. If you have a Printram account, we will retain the recording for as long as you have an account. If you do not have an account, we will delete the recording within 12 months or retain it, if it will be needed to resolve disputes between you and us.</p>
			<p>By using cookies and similar technology on our website, we may collect data such as information on your device, your preferences and information filled while visiting our website, your interaction with the website, and other information used for analytical, marketing, and targeting activities (including unique visits, returning visits, length of the session, actions carried out in the webpage). Learn more about how we use cookies on our website and how to opt out of interest-based advertising by clicking here.</p>
			<p>As it is in our legitimate interests to ensure our network security, give you access to and to improve our Services, we also collect the following technical usage data:</p>
			<p>How and when you access your account;</p>
			<p>Information about the device and browser you use;</p>
			<p>IP address and device data.</p>

			<h5>1.2. Information collected about our Merchant’s Customers</h5>
			<p>Where we act on a Merchant’s behalf to fulfil an order with regard to the Merchant’s customer (i.e. an end user of our Services), we are a data processor and we collect information relating to the Merchant’s customer, such as personal data relating to the end user of our Services, any personal data in the printing content (where applicable), personal data revealed during the use of any Printram services, including name, email address, phone number, shipping address, and other information about the Merchant's customers.</p>
			<p>If you are a customer of the Merchant (an end user of our Services), the Merchant is the data controller with regard to your personal data and should provide you the information on how your personal data is collected and processed when using our Services. Please read the Merchant’s privacy policy for further information. The Merchant is your contact for any questions you have about how it handles your personal data.</p> */}
		</Dialog>
	);
};

export default PrivacyPolicy;
