import { ErrorMessage } from 'formik';
import { Password, PasswordProps } from 'primereact/password';

interface Props extends PasswordProps {
    label: string;
    name: string;
    inputStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
}

const PasswordInput = ({ label, name, inputStyle, labelStyle, ...props }: Props) => {
    return (
        <>
            <div className="flex align-items-center justify-content-between px-1 mb-1">
                <label style={labelStyle}>{label}</label>
                <ErrorMessage name={name} component="small" className="text-red font-medium" />
            </div>
            <Password inputStyle={inputStyle} name={name} {...props} className="w-full" inputClassName="w-full"/>
        </>
    );
};

export default PasswordInput;
