import React, { createContext, useState } from 'react';
import { CustomDtfDeliveryAddress, CustomDtfFile } from '../../../../features/customDtf/create-custom-dtf-order/create-custom-dtf-order.request';
import { DeliveryTypes } from '../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import { ShippingRateDto } from '../../../../features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import { PaymentTypes } from '../../../../features/orders/dtos/payment-type.dto';
import { DtfProduct } from '../../../../features/customDtf/get-products/get-dtf-products.response';

export interface CustomDtfContextType {
	activeIndex: number;
	setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
	storeId: string;
	setStoreId: React.Dispatch<React.SetStateAction<string>>;
	variant: DtfProduct | undefined;
	setVariant: React.Dispatch<React.SetStateAction<DtfProduct | undefined>>;
	itemDetails: ItemDetail[];
	setItemDetails: React.Dispatch<React.SetStateAction<ItemDetail[]>>;
	items: CustomDtfFile[];
	setItems: React.Dispatch<React.SetStateAction<CustomDtfFile[]>>;
	paymentMethodType: PaymentTypes | undefined;
	setPaymentMethodType: React.Dispatch<React.SetStateAction<PaymentTypes | undefined>>;
	grandTotal: number;
	setGrandTotal: React.Dispatch<React.SetStateAction<number>>;
	shippingCost: number;
	setShippingCost: React.Dispatch<React.SetStateAction<number>>;
	calculatedPrice: number;
	setCalculatedPrice: React.Dispatch<React.SetStateAction<number>>;
	deliveryType: DeliveryTypes;
	setDeliveryType: React.Dispatch<React.SetStateAction<DeliveryTypes>>;
	deliveryAddress?: CustomDtfDeliveryAddress;
	setDeliveryAddress: React.Dispatch<React.SetStateAction<CustomDtfDeliveryAddress | undefined>>;
	shippingRates: ShippingRateDto[] | [];
	setShippingRates: React.Dispatch<React.SetStateAction<ShippingRateDto[] | []>>;
	selectedRate: ShippingRateDto | undefined;
	setSelectedRate: React.Dispatch<React.SetStateAction<ShippingRateDto | undefined>>;
	submitItems: (items: CustomDtfFile[]) => void;
	selectedPaymentCard: string | undefined;
	setSelectedPaymentCard: React.Dispatch<React.SetStateAction<string | undefined>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	fullName: string;
	setFullName: React.Dispatch<React.SetStateAction<string>>;
	phoneNumber: string;
	setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
	note: string | undefined;
	setNote: React.Dispatch<React.SetStateAction<string | undefined>>;
	attachmentFiles: File[];
	setAttachmentFiles: React.Dispatch<React.SetStateAction<File[]>>;
	filesUploaded: boolean;
	setFilesUploaded: React.Dispatch<React.SetStateAction<boolean>>;
	setStep: (index: number) => void;
	nextStep: () => void;
	prevStep: () => void;
}

const CreateCustomDtfContext = createContext<CustomDtfContextType | undefined>(undefined);

export type ItemDetail = {
	file: File;
	fileKey: string;
	quantity: number;
	length: number;
	height: number
	total: number;
	uploaded: boolean;
};

type Props = {
	children?: React.ReactNode;
};

const CustomDtfContextProvider: React.FC<Props> = ({ children }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [storeId, setStoreId] = useState<string>('');
	const [variant, setVariant] = useState<DtfProduct | undefined>(undefined);
	const [itemDetails, setItemDetails] = useState<ItemDetail[]>([]);
	const [items, setItems] = useState<CustomDtfFile[]>([]);
	const [paymentMethodType, setPaymentMethodType] = React.useState<PaymentTypes | undefined>();
	const [deliveryAddress, setDeliveryAddress] = useState<CustomDtfDeliveryAddress | undefined>();
	const [deliveryType, setDeliveryType] = useState<DeliveryTypes>(DeliveryTypes.Shipment);
	const [grandTotal, setGrandTotal] = useState<number>(0);
	const [shippingCost, setShippingCost] = useState<number>(0);
	const [calculatedPrice, setCalculatedPrice] = useState<number>(0);
	const [shippingRates, setShippingRates] = useState<ShippingRateDto[]>([]);
	const [selectedRate, setSelectedRate] = useState<ShippingRateDto | undefined>(undefined);
	const [selectedPaymentCard, setSelectedPaymentCard] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [fullName, setFullName] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [note, setNote] = useState<string | undefined>('');
	const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);
	const [filesUploaded, setFilesUploaded] = useState<boolean>(false);

	const nextStep = () => (activeIndex < 3 ? setActiveIndex(activeIndex + 1) : null);
	const prevStep = () => (activeIndex > 0 ? setActiveIndex(activeIndex - 1) : null);
	const setStep = (index: number) => setActiveIndex(index);
	const submitItems = (items: CustomDtfFile[]) => setItems(items);

	return (
		<CreateCustomDtfContext.Provider
			value={{
				activeIndex,
				setActiveIndex,
				storeId,
				setStoreId,
				variant,
				setVariant,
				itemDetails,
				setItemDetails,
				items,
				setItems,
				paymentMethodType,
				setPaymentMethodType,
				grandTotal,
				setGrandTotal,
				shippingCost,
				setShippingCost,
				calculatedPrice,
				setCalculatedPrice,
				deliveryType,
				setDeliveryType,
				deliveryAddress,
				setDeliveryAddress,
				shippingRates,
				setShippingRates,
				selectedRate,
				setSelectedRate,
				submitItems,
				selectedPaymentCard,
				setSelectedPaymentCard,
				loading,
				setLoading,
				fullName,
				setFullName,
				phoneNumber,
				setPhoneNumber,
				note,
				setNote,
				attachmentFiles,
				setAttachmentFiles,
				filesUploaded,
				setFilesUploaded,
				setStep,
				nextStep,
				prevStep
			}}>
			{children}
		</CreateCustomDtfContext.Provider>
	);
};

export { CustomDtfContextProvider, CreateCustomDtfContext };
