import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import assignedProductVariantToPrintCenterService from 'features/printCenters/derived-features/assigned-product-variant-to-print-centers/assigned-product-variant-to-print-center.service';
import { AssignedProductVariantDto } from 'features/printCenters/derived-features/assigned-product-variant-to-print-centers/get-assigned-product-variants/dtos/assigned-product-variant.dto'
import { GetAssignedProductVariantsRequest } from 'features/printCenters/derived-features/assigned-product-variant-to-print-centers/get-assigned-product-variants/get-assigned-product-variants.request';
import { Pagination } from 'models/_commons/responses/pagination';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import React, { useCallback, useEffect, useState } from 'react'
import { setForAdminAssignedProductListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import NewAssign from './components/NewAssign';

const AssignedProducts = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminAssignedProductListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [products, setProducts] = useState<AssignedProductVariantDto[]>()
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [assignVisible, setAssignVisible] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const getAssignedProducts = useCallback(async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			if (!!pagination) dispatch(setForAdminAssignedProductListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetAssignedProductVariantsRequest(null, pagination || fromStatePagination);

			const response = await assignedProductVariantToPrintCenterService.getAssignedProductVariants(request)

			if (!response.isSuccess) throw '';

			setProducts(response.data || []);
			setPaginationResponse(response.pagination);
		} finally { setLoading(false) }
	}, []);

	const variantBodyTemplate = (rowData: AssignedProductVariantDto) => rowData?.colorName + ' / ' + rowData?.sizeName

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.orderBy = 2;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		await getAssignedProducts(newPagination);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else {
			newPagination.itemCount = paginationResponse?.itemCountAtCurrentPage || 15;
			newPagination.orderBy = 2;
			newPagination.pageNumber = paginationResponse?.currentPage || 1;
			newPagination.first = 0;
		}

		getAssignedProducts(newPagination);
	}, []);

  return (
		<div className="card">
			<div className="flex justify-content-between align-items-center mb-3">
				<h5>Assigned Products</h5>
				<Button type="button" label="New Assign" onClick={() => setAssignVisible(true)} icon="pi pi-directions" loading={loading} />
			</div>

			<DataTable value={products} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} paginator lazy rowsPerPageOptions={[5, 10, 15, 20]} rows={fromStatePagination.itemCount || 5} dataKey="id" first={fromStatePagination.first} onPage={onPageChangeEvent} loading={loading} className="p-datatable-gridlines">
				<Column field="productName" header="Product" style={{ minWidth: '12rem' }} />
				<Column body={variantBodyTemplate} header="Variant" style={{ minWidth: '12rem' }} />
				<Column field="fullSku" header="SKU" style={{ minWidth: '12rem' }} />
				<Column field="printCenterFullName" header="Assigned Print Center" style={{ minWidth: '12rem' }} />
			</DataTable>

			<NewAssign visible={assignVisible} setVisible={setAssignVisible} getAssignedProducts={getAssignedProducts} />
		</div>
  );
}

export default AssignedProducts