import { ProductMockup } from '../../models/dtos/product/product-mockup';
import { CreateMockupRequest } from '../../models/requests/products/create-mockup-request';
import { UpdateMockupRequest } from '../../models/requests/products/update-mockup-request';
import { DataResponse } from '../../models/_commons/responses/data-response';
import { ResponseBase } from '../../models/_commons/responses/response-base';
import HttpBaseService from '../_common/http-base-service';

class ProductMockupService extends HttpBaseService {
    constructor() {
        super('product-mockups');
	}

	async get(productId: string): Promise<DataResponse<ProductMockup[]>> {
		return await this.getData('get-product-mockups-by-product?ProductId=' + productId);
	}

    async create(request: CreateMockupRequest): Promise<ResponseBase> {
        return await this.postData('create-mockup', request, true);
	}

	async update(request: UpdateMockupRequest): Promise<ResponseBase> {
		return await this.putData('update', request, true)
	}
}

export default new ProductMockupService();
