import categoryService from 'features/categories/_common/category.service';
import { GetCategoriesWithProductsRequest } from 'features/categories/_common/get-categories-with-products/get-categories-with-products.request';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import styles from './ProductCatalogLayout.module.scss';
import { Link } from 'react-router-dom';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { useAppSelector } from 'redux/hooks';
import { ProgressSpinner } from 'primereact/progressspinner';

const ProductCatalog = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const [loading, setLoading] = useState<boolean>(false);
	const [categories, setCategories] = useState<CategoryBaseDto[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<CategoryBaseDto | undefined>(undefined);

	const getCategories = async () => {
		try {
			setLoading(true);

			const response = await categoryService.getWithProducts(new GetCategoriesWithProductsRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCategories(response.data);
		} catch (error) {
			setCategories([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCategories();
	}, []);

	return (
		<main className="container relative" style={{ minHeight: '19.25rem' }}>
			<section className="container-header">
				<h1 className="container-header-title">Product Catalog</h1>

				{!!user && user.roles.isMerchant ? (
					<div className="container-header-tools">
						<Link to="/product/merchant/import">
							<PrButton type="secondary" text="Import Product" icon={<span className="pi pi-fw pi-download" />} />
						</Link>

						<Link to="/product/merchant/new">
							<PrButton type="secondary" text="New Product" icon={<span className="pi pi-fw pi-plus-circle" />} />
						</Link>
					</div>
				) : null}
			</section>

			<section className="flex align-items-start gap-3">
				<aside className={`container-body p-0 w-30rem ${styles.accordionSide}`}>
					<Accordion onTabOpen={(e) => setSelectedCategory(categories[e.index])} onTabClose={(e) => setSelectedCategory(undefined)}>
						{categories.map((_category) => (
							<AccordionTab key={_category.id} disabled={loading} headerTemplate={() => <strong>{_category.name}</strong>} className="catalog-accordion-tab">
								<div className={styles.accordionSubCategoryWrapper}>
									{_category.subCategories.length > 0
										? _category.subCategories.map((_subCategory) => (
												<Link key={_subCategory.id} to={`/product/catalog/${_subCategory.id}`} tabIndex={0} className={styles.accordionSubCategoryItem}>
													{_subCategory.name}
												</Link>
										  ))
										: null}
								</div>
							</AccordionTab>
						))}
					</Accordion>
				</aside>

				<section className="container p-0 w-full">
					<Outlet context={{ categories, selectedCategory }} />
				</section>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
	);
};

export default ProductCatalog;
