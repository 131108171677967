export enum ColorTypeEnum {
    White = '#f7f7f7',
    Black = '#212121'
}

const getContrast = (hexCode: string) => {
    if (!hexCode) return ColorTypeEnum.White;

    const hex = hexCode.replace('#', '');

    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);

    const yiq = (red * 299 + green * 587 + blue * 114) / 1000;

    return yiq >= 128 ? ColorTypeEnum.Black : ColorTypeEnum.White;
};

const colorHelper = { getContrast };

export default colorHelper;
