import currencyHelper from 'helpers/curreny.helper';
import { RadioButton } from 'primereact/radiobutton';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SellerReorderDetailContext, SellerReorderDetailContextType } from '../../../context/SellerReorderDetailContextProvider';
import style from './ShippingRates.module.scss';
import { reorderService } from 'features/reorders/_common/reorder.service';
import { ReorderCalculateRequest } from 'features/reorders/_common/calculate/re-order-calculate.request';


const ShippingRates = () => {
	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	return (
		<div>
			<div className={style.warningMessage}>If the cargo company calculates a different weight for the order, the difference will be charged.</div>

			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping method
			</h2>
			<div className={style.ratesWrapper}>
				{context.shippingRates.map((_rate, index) => (
					<div key={index} className="field-radiobutton">
						<RadioButton
							inputId={`rate${_rate.shippingRate.rateId || _rate.shippingRate.serviceName}`}
							value={_rate.shippingRate.rateId || _rate.shippingRate.serviceName}
							name="rate"
							onChange={async () => {
								try {
								context.setLoading(true);

								if (!context.reorder?.orderAddress) return;

								const rateRequest = new ReorderCalculateRequest({
									shippingRate: _rate?.shippingRate,
									reOrderId: context.reorderIdFromRoute?.reorderId
								});

								const response = await reorderService.calculateReorder(rateRequest);
								if (!response.data || !response.isSuccess) throw '';

								context.setCalculatedPrice(response.data);
								context.setSelectedRate(_rate);
							} catch (error) {
							} finally {
								context.setLoading(false);
							}}}
							checked={context.selectedRate?.shippingRate.rateId ? _rate.shippingRate.rateId === context.selectedRate?.shippingRate.rateId : _rate.shippingRate.serviceName === context.selectedRate?.shippingRate.serviceName}
						/>

						<label htmlFor={`rate${_rate.shippingRate.rateId || _rate.shippingRate.serviceName}`}>
							{_rate?.discountedAmount ? _rate?.discountedAmount?.pricePerUnit === 0 ? (
								<b className="text-green-600">Free</b>
							) : (
								<>
									<b>{currencyHelper.formatPrice(+_rate?.discountedAmount?.formattedPricePerUnit)}</b>{' '}
									<small className="font-semibold text-pink-600">
										<del>{currencyHelper.formatPrice(_rate.shippingRate?.amount)}</del>
									</small>
								</>
							) : <b>{currencyHelper.formatPrice(+_rate.shippingRate?.amount)}</b>}{' '}
							- {_rate.shippingRate?.serviceName}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default ShippingRates;
