import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useState } from 'react';
import { CreateProductDetailAndMaterialsRequest } from '../../../../../models/requests/products/create-product-detail-and-materials-request';
import productDetailAndMaterialsService from '../../../../../services/products/product-detail-and-materials-service';

const StepThree = ({ setStep }: any) => {
	const { productId } = useParams();

	const [loading, setLoading] = useState(false);

	const initialValues: CreateProductDetailAndMaterialsRequest = { createProductDetails: { productId: productId || '', details: [] }, createProductMaterials: { productId: productId || '', materialAndValues: [{ name: '', ratioPercentage: 0 }] } };

	const validationSchema = Yup.object().shape({
		createProductDetails: Yup.object().shape({
			details: Yup.array().of(Yup.string().required('Detail is Required').min(6, 'Detail must be at least 6 characters.').max(200, 'Detail must be max 200 characters.'))
		}),
		createProductMaterials: Yup.object().shape({
			materialAndValues: Yup.array().of(Yup.object().shape({ name: Yup.string().required('Material name is required').min(3, 'Materials must be at least 3 characters.'), ratioPercentage: Yup.number().required('Percantage is must be more than 0').moreThan(0, 'Percantage is must be more than 0') }))
		})
	});

	const onSubmit = (values: CreateProductDetailAndMaterialsRequest) => {
		if (!productId || productId.length < 0) return;

		values.createProductDetails.productId = productId;
		values.createProductMaterials.productId = productId;

		setLoading(true);
		productDetailAndMaterialsService
			.create(values)
			.then((response) => {
				if (!response.isSuccess) return;

				setStep((prev: number) => (prev += 1));
			})
			.finally(() => setLoading(false));
	};

	const getCountOfDetails = (index: number) => (index += 1);

	return (
		<div className="grid">
			<div className="col-8">
				<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
					{({ values, handleChange, setFieldValue }) => (
						<Form>
							<FieldArray name="createProductDetails.details">
								{({ remove, push }) => (
									<>
										<div className="flex align-items-center justify-content-between pl-1">
											<h6 className="line-height-1 m-0">Product Details</h6>
											<Button onClick={() => push('')} type="button" icon="pi pi-plus-circle" label="Add New Detail" className="p-button-outlined p-button-sm" />
										</div>

										{values.createProductDetails.details.length > 0 &&
											values.createProductDetails.details.map((detail, index) => (
												<div key={index} className="mt-3">
													<div className="w-full">
														<div className="mb-1 px-1 flex align-items-center justify-content-between">
															<label className="font-medium block">{getCountOfDetails(index)} Detail</label>
															<ErrorMessage name={`createProductDetails.details[${index}]`} component="small" className="text-red font-medium" />
														</div>
													</div>

													<div className="flex gap-3 align-items-end">
														<InputText name={`createProductDetails.details.${index}`} value={detail} onChange={handleChange} placeholder="Enter product detail" className="w-full" />
														{index !== 0 && <Button onClick={() => remove(index)} type="button" icon="pi pi-trash" className="p-button-outlined p-button-danger" />}
													</div>
												</div>
											))}
									</>
								)}
							</FieldArray>

							<hr className="mt-5 mb-3" />

							<FieldArray name="createProductMaterials.materialAndValues">
								{({ remove, push }) => (
									<>
										<div className="flex align-items-center justify-content-between pl-1">
											<h6 className="line-height-1 m-0">Product Materials</h6>
											<Button onClick={() => push('')} type="button" icon="pi pi-plus-circle" label="Add New Material" className="p-button-outlined p-button-sm" />
										</div>
										{values.createProductMaterials.materialAndValues.length > 0 &&
											values.createProductMaterials.materialAndValues.map((material, index) => (
												<div key={index} className="mt-3">
													<div className="w-full">
														<div className="mb-1 px-1 flex align-items-center justify-content-between">
															<label className="font-medium block">Material {getCountOfDetails(index)}</label>
															<ErrorMessage name={`createProductMaterials.materialAndValues.${index}.name`} component="small" className="text-red font-medium" />
															<ErrorMessage name={`createProductMaterials.materialAndValues.${index}.ratioPercentage`} component="small" className="text-red font-medium" />
														</div>
													</div>
													<div className="flex gap-3 align-items-end">
														<InputText name={`createProductMaterials.materialAndValues.${index}.name`} value={material.name} onChange={handleChange} placeholder="Enter material name" className="w-full" />
														<span className="p-input-icon-right">
															<i className="pi pi-percentage" />
															<InputText name={`createProductMaterials.materialAndValues.${index}.ratioPercentage`} value={material.ratioPercentage || 0} onChange={(e) => setFieldValue(`createProductMaterials.materialAndValues[${index}].ratioPercentage`, parseFloat(e.target.value) || 0)} placeholder="0" className="w-full" />
														</span>
														<Button onClick={() => remove(index)} type="button" icon="pi pi-trash" disabled={index === 0} className="p-button-outlined p-button-danger" />
													</div>
												</div>
											))}
									</>
								)}
							</FieldArray>

							<div className="mt-3 w-full text-center">
								<span className="text-500 font-small">The total percentage of products entered as a percentage must be %100</span>
							</div>

							<div className="mt-4">
								<Button type="submit" icon="pi pi-arrow-right" label="Confirm & Next" loading={loading} className="px-5" />
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default StepThree;
