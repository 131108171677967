import HttpBaseService from 'services/_common/http-base-service';
import { GetContactsRequest } from './get-contacts/get-contacts.request';
import { GetContactsResponse } from './get-contacts/get-contacts.response';

class ContactService extends HttpBaseService {
	constructor() {
		super('contacts');
	}

	async getContacts(request: GetContactsRequest): Promise<GetContactsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export const contactService = new ContactService();
