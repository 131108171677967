export class CreateImageRequest {
	imageFiles: File[] | File;

	constructor(imageFiles: File[] | File) {
		this.imageFiles = imageFiles;
	}

	get getConvertUriExtension() {
		return `create-image`;
	}
}