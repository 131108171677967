import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";
import { DtfListStatusType } from "features/dtfs/enums/dtf-list-status-type.enum";

export class GetAllDtfListsRequest {
	pagination: PaginationDto;
	dtfListStatusType: DtfListStatusType;

	get getConvertUriExtension() {
		return 'get-all-dtf-lists';
	}

	constructor(props: Partial<GetAllDtfListsRequest>) {
		this.pagination = props.pagination || new PaginationDto(1, 20);
		this.dtfListStatusType = props.dtfListStatusType || 2;
	}
}