import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React from 'react';
import { CreateOrderContext, CreateOrderContextType, orderSteps } from './context/CreateOrderContextPrvider';
import { Steps } from './steps/Steps';
import StartTutorialTourButton from 'helpers/widgets/Tutorial/StartTutorialTourButton';
import Cookies from 'js-cookie';

export const CreateOrder = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Create Manuel Order</h1>

				<div className="container-header-tools">
					{/* <GoBackButton /> */}

					{/* {context.orderStep.value !== 1 ? <StartTutorialTourButton onClickEvent={() => {
						Cookies.remove('product-table')
						Cookies.remove('ship-form-intro')
						Cookies.remove('ship-summary-intro');
						Cookies.remove('review-order-intro');
						context.setOrderStep({ ...orderSteps[0] });
					}} /> : null} */}

					{context.orderStep.value !== 1 ? <PrButton text="Go Back" icon={<span className="pi pi-fw pi-arrow-left" />} type="secondary" onClick={() => context.setOrderStep(orderSteps[context.orderStep.value - 2])} /> : null}
				</div>
			</div>

			<Steps />

			{context.orderStep.component}

			<PrLoading loading={context.loading} text={context.loadingText} />
		</div>
	);
};
