import { CreateManuelOrderRequest, DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import orderService from 'features/orders/_common/order.service';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React from 'react';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import styles from './StepReviewOrder.module.scss';

import { confirmDialog } from 'primereact/confirmdialog';
import { BiError } from 'react-icons/bi';
import { SellerReorderDetailContext, SellerReorderDetailContextType } from '../../context/SellerReorderDetailContextProvider';
import { Payments } from './components/Payments';
import { CostBreakdown } from '../shipping/breakdown/CostBreakdown';
import { ShippingInfo } from '../shipping/shipping-info/ShippingInfo';
import { ReOrderProductsTable } from '../products/components/ReOrderProductsTable';
import { ReorderChargeRequest } from 'features/reorders/_common/charge/charge.request';
import { reorderService } from 'features/reorders/_common/reorder.service';

export const StepReviewOrder = () => {
	const navigate = useNavigate();
	const context = React.useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	const showFailDialog = (error: any) => {
		confirmDialog({
			header: 'Re Order can not be created.',
			icon: <BiError color="red" size={50} />,
			message: error?.response?.data?.message || '',
			acceptLabel: 'Ok',
			acceptClassName: 'px-4 bg-blue-600',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: () => {}
		});
	};

	const chargeReorder = async () => {
		try {
			context.setLoading(true)

			const request = new ReorderChargeRequest(structuredClone(context.reorderRequest))

			const response = await reorderService.chargeReorder(request);

			if (!response.isSuccess) throw ''

			navigate('/product/seller/orders?status=3');
		} catch (error) {
			showFailDialog(error);
		} finally {
			context.setLoading(false);
		}
	}

	return (
		<div className="relative">
			<div className="container-body p-4">
				<ReOrderProductsTable />
			</div>

			{context.reorder?.deliveryType !== 2 ? <div className="container-body p-4 mt-5">
				<ShippingInfo />
			</div> : null}

			<div className="container-body p-4 mt-5">
				<Payments />
			</div>

			{context.calculatedPrice && (
				<div className="container-body p-4 mt-5">
					<CostBreakdown {...context.calculatedPrice} />

					<div className={styles.payButton}>
						<PrButton text="Pay securely now" onClick={chargeReorder} icon={<BsFillShieldLockFill />} btnType="button" />
						<div className={styles.payButtonDescription}>
							<div>By completing this order, </div>
							<div>I agree to Printram's Terms of Service.</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
