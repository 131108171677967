export class SearchDtfListByOrderCodeRequest {
    orderCode: string;

    constructor(orderCode: string) {
        this.orderCode = orderCode;
    }

    get getConvertUriExtension() {
        return `search-dtf-list-by-order-code?Code=${this.orderCode}`;
    }
}