import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GenerateProductVariants } from '../../../../../../models/requests/products/product-variants-generate-request';
import ColorCreate from '../../../../../_common/modals/ColorCreate';
import * as Yup from 'yup';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import productVariantsService from '../../../../../../services/products/product-variants-service';

type props = {
    visible: boolean;
    setVisible: Function;
    colorOptions: SelectItemOptionsType | undefined;
    sizeOptions: SelectItemOptionsType | undefined;
    loading: boolean;
    setLoading: Function;
    setVariations: Function;
    getColors: Function;
    prevValues: GenerateProductVariants | undefined;
    setPrevValues: Function;
}

const GenerateVariations = ({ visible, setVisible, colorOptions, sizeOptions, loading, setLoading, setVariations, getColors, prevValues, setPrevValues }: props) => {
    const { productId } = useParams();

    const [isColorVisible, setColorVisible] = useState<boolean>(false);
    
    const initialValues: GenerateProductVariants = prevValues ? prevValues : {
        productId: productId || '',
        productVariants: [
            { productVariantType: 1, values: [] },
            { productVariantType: 2, values: [] }
        ]
    };

    const validationSchema = Yup.object().shape({
        productVariants: Yup.array()
            .of(Yup.object().shape({ productVariantType: Yup.number(), values: Yup.array().of(Yup.string()).when('productVariantType', {is: 1,then: Yup.array().min(1,"Colors are required")}).when('productVariantType', {is: 2,then: Yup.array().min(1,"Sizes are required")}) }))
            .min(1, 'Variant is required')
    });

    const colorsItemTemplate = (colorOption: SelectItem) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="h-1rem w-1rem border-round border-500 border-1" style={{ backgroundColor: colorOption.title }} />
                <span>{colorOption.label}</span>
            </div>
        );
    };

    const sizesItemTemplate = (sizeOption: SelectItem) => {
        return <span>{sizeOption.label}</span>;
    };

    const onSubmit = (values: GenerateProductVariants) => { 
        setLoading(true);
        productVariantsService
            .generate(values)
            .then((response) => {
                if (!response.isSuccess) return;

                setVariations(response.data.variants);
                setPrevValues(values)
                setVisible(false)
            })
            .finally(() => setLoading(false));};

    return (
        <Dialog visible={visible} header="Create Variations" onHide={() => setVisible(false)}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ values, handleChange }) => (
                    <Form>
                        <FieldArray name="productVariants">
                            {() => (
                                <>
                                    {values.productVariants.map((variant, index) => (
                                        <div key={index}>
                                            <div className="mb-1 px-1 flex align-items-center justify-content-between col-12 md:col-9">
                                                <label className="font-medium block">{index === 0 ? 'Colors' : 'Sizes'}</label>
                                                <ErrorMessage name={`productVariants[${index}].values`} component="small" className="text-red font-medium" />
                                            </div>

                                            <div className="grid">
                                                <div className="col-12 md:col-9">
                                                    <MultiSelect
                                                        name={`productVariants[${index}].values`}
                                                        filter
                                                        options={index === 0 ? colorOptions : sizeOptions}
                                                        value={variant.values}
                                                        onChange={handleChange}
                                                        itemTemplate={index === 0 ? colorsItemTemplate : sizesItemTemplate}
                                                        maxSelectedLabels={7}
                                                        placeholder={index === 0 ? 'Select product colors' : 'Select product sizes'}
                                                        className="colors-select w-full"
                                                    />
                                                </div>
                                                {index === 0 && (
                                                    <div className="col-12 md:col-3 text-right ">
                                                        <Button onClick={() => setColorVisible(true)} type="button" className="w-full h-full d-inline-flex align-items-center justify-content-center gap-2">
                                                            <span className="pi pi-plus-circle" />
                                                            <span>New Color</span>
                                                        </Button>

                                                        {isColorVisible ? <ColorCreate isVisible={isColorVisible} setVisible={setColorVisible} getColors={getColors} /> : null}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        <div className="mt-4">
                            <Button type="submit" label="Save Variations" loading={loading} className="px-5" />
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default GenerateVariations;
