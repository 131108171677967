export class SearchProductForImportRequest {
	contains: string = '';

	constructor(contains: string) {
		this.contains = contains;
	}

	get getConvertUriExtension() {
		return `search-product-for-import?contains=${this.contains}`;
	}
}