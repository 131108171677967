import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class SurveyGetListRequest {
	pagination: PaginationDto;

	constructor(props: Partial<SurveyGetListRequest>) {
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `?Pagination.PageNumber=${this.pagination.pageNumber}&Pagination.ItemCount=${this.pagination.itemCount}&Pagination.OrderBy=${this.pagination.orderBy}`;
	}
}
