import { PlanDto } from 'features/plans/_common/dtos/plan.dto';
import { GetPlanByIdRequest } from 'features/plans/_common/get-by-id/get-by-id.request';
import plansService from 'features/plans/_common/plans.service';
import { PlanUpdateRequest } from 'features/plans/_common/update/update.request';
import { GetPlanFeatureTypesRequest } from 'features/plans/derived-features/plan-feature-types/get-plan-feature-types/get-plan-feature-types.request';
import planFeatureTypesService from 'features/plans/derived-features/plan-feature-types/plan-feature-types.service';
import { Form, Formik, FormikProps } from 'formik';
import DropdownInput from 'helpers/widgets/Forms/DropdownInput';
import TextInput from 'helpers/widgets/Forms/TextInput';
import TextareaInput from 'helpers/widgets/Forms/TextareaInput';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DropdownChangeParams } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useRef, useState } from 'react'

type props = {
	visible: boolean;
	setVisible: Function;
	planOptions: SelectItemOptionsType | undefined;
	editedPlanId: string;
	setEditedPlan: Function;
	getPlans: Function;
};

type PlanFeatureOptions = { id: string, planFeatureOptions: number; label: string; value: string; isCustomizable: boolean };

const PlanEdit = ({ visible, setVisible, planOptions, editedPlanId, setEditedPlan, getPlans }: props) => {
	const formikRef = useRef<FormikProps<PlanUpdateRequest>>(null);

	const [plan, setPlan] = useState<PlanDto>()
	const [planFeatureOptions, setPlanFeatureOptions] = useState<SelectItemOptionsType | undefined>();
	const [selectedFeatures, setSelectedFeatures] = useState<PlanFeatureOptions[]>([]);
	const [loading, setLoading] = useState(false);

	const initialValues: PlanUpdateRequest = new PlanUpdateRequest(plan?.id || '', plan?.basePlanName || '', plan?.basePlanId || '', plan?.planType || 0, plan?.name || '', plan?.description || '', plan?.priceAsMonthly.formattedPricePerUnit || 0, plan?.priceAsYearly.formattedPricePerUnit || 0, plan?.isBuyable || true, plan?.planFeatures.map(feature => ({ id:plan.id, planId: feature.planId, value: feature.value ,planFeatureTypeId: feature.planFeatureType.id})) || []);

	const onFeaturesChange = (e: DropdownChangeParams) => {
		if (!planFeatureOptions || selectedFeatures.find((item) => item.planFeatureOptions === e.value)) return;

		const pushParam = { id: plan?.planFeatures.find((feature) => feature.planFeatureType.id === e.value)?.id || '' ,planFeatureOptions: e.value, label: planFeatureOptions.find((feature) => feature.value === e.value)?.label, value: '', isCustomizable: planFeatureOptions.find((feature) => feature.value === e.value)?.isCustomizable || false };
		setSelectedFeatures([...selectedFeatures, pushParam]);
	};

	const deleteSelectedFeature = (planFeatureOptions: number) => setSelectedFeatures(selectedFeatures.filter((selecteds) => selecteds.planFeatureOptions !== planFeatureOptions));

	const onInputChange = (inputValue: string, planFeatureTypeId: number) => {
		setSelectedFeatures(
			selectedFeatures.map((item) => {
				if (item.planFeatureOptions === planFeatureTypeId) {
					return { ...item, value: inputValue.toString() };
				} else {
					return item;
				}
			})
		);
	};

	const submitForm = async(values: PlanUpdateRequest) => {
		values.planFeatures = selectedFeatures.map((feature) => ({ id: feature.id || null, planFeatureTypeId: feature.planFeatureOptions, planId: plan?.id || '', value: feature.value }));

		try {
			setLoading(true);

			const clonedValues = JSON.parse(JSON.stringify(values));

			const request = new PlanUpdateRequest(clonedValues.id, clonedValues.basePlanName, clonedValues.basePlanId || null, clonedValues.planType, clonedValues.name, clonedValues.description, Math.round(parseFloat(clonedValues.priceAsMonthly.toString()) * 100), Math.round(parseFloat(clonedValues.priceAsYearly.toString()) * 100), clonedValues.isBuyable, clonedValues.planFeatures);

			const response = await plansService.update(request);

			if (!response.isSuccess) throw '';

			getPlans();
			setVisible(false);
			setEditedPlan(undefined);
		} finally {
			setLoading(false);
		}
	}

	const getPlanById = async() => {
		return new Promise(async(resolve) => {
			try {
				const request = new GetPlanByIdRequest();
				request.planId = editedPlanId;

				const response = await plansService.getPlanById(request);

				if (!response.isSuccess) throw '';

				resolve(response.data as PlanDto);
			} finally {}
		})
	}

	const getPlanFeatureTypes = async () => {
		return new Promise(async(resolve) => {
			try {
				const request = new GetPlanFeatureTypesRequest();

				const response = await planFeatureTypesService.getAll(request);

				if (!response.isSuccess) throw '';

				const options = response.data?.map((feature) => ({ label: feature.description.replace('{0}', '___'), value: feature.id, isCustomizable: feature.isCustomizable } as SelectItem));

				if (!options) return;

				resolve(options);
			} finally {}
		})
	}

	useEffect(() => {
		if (!editedPlanId) return;

		setLoading(true)
		Promise.all([getPlanById(), getPlanFeatureTypes()]).then((values: any) => {
			setPlan(values[0])
			setPlanFeatureOptions(values[1])
		}).finally(() => setLoading(false))
	}, [editedPlanId])

	useEffect(() => {
		if (!plan) return;

		formikRef.current?.setFieldValue('id', plan.id)
		formikRef.current?.setFieldValue('basePlanName', plan.basePlanName);
		formikRef.current?.setFieldValue('basePlanId', plan.basePlanId);
		formikRef.current?.setFieldValue('planType', plan.planType);
		formikRef.current?.setFieldValue('name', plan.name);
		formikRef.current?.setFieldValue('description', plan.description);
		formikRef.current?.setFieldValue('priceAsMonthly', plan.priceAsMonthly.formattedPricePerUnit);
		formikRef.current?.setFieldValue('priceAsYearly', plan.priceAsYearly.formattedPricePerUnit);
		formikRef.current?.setFieldValue('isBuyable', plan.isBuyable);
		formikRef.current?.setFieldValue('planFeatures', plan.planFeatures.map((feature) => ({ id: feature.id, planId: feature.planId, value: feature.value, planFeatureTypeId: feature.planFeatureType.id })) || []);

		setSelectedFeatures(plan.planFeatures.map(feature => ({ id:feature.id || '' ,label: feature.planFeatureType.description.replace('{0}', '___'), value: feature.value, isCustomizable: feature.planFeatureType.isCustomizable, planFeatureOptions: feature.planFeatureType.id })))

	},[plan])

  return (
		<Dialog
			header="Edit Plan"
			visible={visible}
			onHide={() => {
				setVisible(false);
				setEditedPlan(undefined);
			}}
			className="plan-dialog">
			{!loading ? (
				<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={submitForm}>
					{({ values, handleChange }) => (
						<Form>
							<div className="grid">
								<div className="col-12 md:col-6">
									<TextInput name="name" label="Plan Name" value={values.name} onChange={handleChange} placeholder="Plan name" />
								</div>

								<div className="col-12 md:col-6">
									<DropdownInput name="basePlanId" label="Base Plan" value={values.basePlanId} options={planOptions} onChange={handleChange} placeholder="Select for add new features" />
								</div>

								<div className="col-12">
									<TextareaInput name="description" label="Plan Description" value={values.description} onChange={handleChange} placeholder="Plan description" />
								</div>

								<div className="col-12 md:col-6">
									<TextInput name="priceAsMonthly" label="Price as monthly" value={values.priceAsMonthly} onChange={handleChange} placeholder="0" keyfilter="num" />
								</div>

								<div className="col-12 md:col-6">
									<TextInput name="priceAsYearly" label="Price as yearly" value={values.priceAsYearly} onChange={handleChange} placeholder="0" keyfilter="num" />
								</div>

								<div className="col-12">
									<DropdownInput name="planFeatures" label="Features" options={planFeatureOptions} onChange={(e) => onFeaturesChange(e)} placeholder="Select for add new features" />
								</div>
							</div>
							<div className="my-4">
								{selectedFeatures.length > 0 &&
									selectedFeatures.map((feature: PlanFeatureOptions, index: number) => (
										<div key={index} className="flex flex-row justify-content-between align-items-center my-1 px-4" style={{ height: 40, backgroundColor: '#EFEFEF', borderRadius: 10 }}>
											<span>{feature.label || ''}</span>
											<div className="flex align-items-center justify-content-center">
												{feature.isCustomizable ? (
													<InputText value={feature.value} onChange={(e) => onInputChange(e.target.value, feature.planFeatureOptions)} style={{ width: 50, height: 30, borderRadius: 0, borderColor: 'lightgray' }} className="text-center" />
												) : (
													<div className="flex align-items-center justify-content-center" style={{ width: 50 }}>
														<i className="pi pi-check-circle" />
													</div>
												)}
												<div className="ml-4 cursor-pointer flex justify-content-center align-items-center border-1 border-round-2xl p-1 text-red" onClick={() => deleteSelectedFeature(feature.planFeatureOptions)}>
													<i className="pi pi-times text-xs" />
												</div>
											</div>
										</div>
									))}
							</div>

							<div className="col-12 flex justify-content-between align-items-end p-0">
								<div className="border-1 border-gray-500 border-round-sm py-3 px-3 flex justify-content-center align-items-center">
									<InputSwitch name="isBuyable" checked={values.isBuyable} onChange={handleChange} className="mr-3" />
									<span>Is Buyable?</span>
								</div>
								<Button type="submit" label="Update" loading={loading} className="h-full px-4 mr-0 md:px-8" />
							</div>
						</Form>
					)}
				</Formik>
			) : (
				<div className="grid">
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
				</div>
			)}
		</Dialog>
  );
}

export default PlanEdit