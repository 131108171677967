import React, { useEffect, useState } from 'react';
import { GetCategoriesRequest } from 'features/categories/_common/get-categories/get-categories.request';
import { SoftDeleteCategoryRequest } from 'features/categories/_common/soft-delete-category/soft-delete-category.request';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import categoryService from 'features/categories/_common/category.service';
import TreeNode from 'primereact/treenode';
import mediaHelper from 'helpers/media.helper';
import DropdownButton from 'helpers/widgets/DropdownButton';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import CreateCategoryModal from './components/CreateCategoryModal';
import UpdateCategoryModal from './components/UpdateCategoryModal';
import integrationsService from 'features/integrations/_common/integrations.service';
import { TaxonomyDto } from 'features/integrations/_common/get-etsy-taxonomies/dtos/taxonomy.dto';
import { GetEtsyTaxonomiesRequest } from 'features/integrations/_common/get-etsy-taxonomies/get-etsy-taxonomies.request';
import treeHelper from 'helpers/tree.helper';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';

const Categories = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [createVisible, setCreateVisible] = useState<boolean>(false);
	const [updateVisible, setUpdateVisible] = useState<boolean>(false);
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);
	const [updateCategory, setUpdateCategory] = useState<CategoryBaseDto | undefined>(undefined);
	const [taxonomies, setTaxonomies] = useState<TaxonomyDto[]>([]);

	const getTaxonomies = async () => {
		try {
			const response = await integrationsService.getEtsyTaxonomies(new GetEtsyTaxonomiesRequest());
			if (!response.isSuccess || !response.data) throw '';

			setTaxonomies(response.data || []);
		} catch (error) {
			setTaxonomies([]);
		}
	};

	const getAll = async () => {
		try {
			setLoading(true);

			const response = await categoryService.getAll(new GetCategoriesRequest());
			if (!response.isSuccess || !response.data) throw '';

			if (taxonomies.length < 1) await getTaxonomies();

			setTreeCategories(treeHelper.mapCategoriesForTree(response.data));
		} catch (error) {
			setTreeCategories([]);
		} finally {
			setLoading(false);
			setUpdateCategory(undefined);
		}
	};

	const deleteCategory = async (id: string) => {
		try {
			const response = await categoryService.softDelete(new SoftDeleteCategoryRequest(id));
			if (!response) throw '';

			await getAll();
		} catch (error) {
		} finally {
		}
	};

	useEffect(() => {
		getAll();
	}, []);

	const descriptionBodyTemplate = (row: { data: CategoryBaseDto }) => {
		if (!row.data.description) return '';

		const count = 60;

		let description = row.data.description.substring(0, count);

		if (row.data.description.length > count) description += '...';

		return description;
	};

	const imageBodyTemplate = (row: { data: CategoryBaseDto }) => <img src={amazonS3Helper.showFile(row.data.media, { resize: '64x64' })} alt={row.data.name} loading="lazy" className="w-4rem h-4rem border-round-lg shadow-5" style={{ objectFit: 'cover', objectPosition: 'top' }} />;

	const bannerImageBodyTemplate = (row: { data: CategoryBaseDto }) => {
		if (!row.data.bannerMedia) return '';

		return <img src={amazonS3Helper.showFile(row.data.bannerMedia, { resize: '203x64' })} alt={row.data.name + 'Banner Image'} loading="lazy" className="h-4rem border-round-lg shadow-5" />;
	};

	const actionsBodyTemplate = (row: { data: CategoryBaseDto }) => {
		const menuItems = [
			<button
				onClick={(_) => {
					setUpdateVisible(true);
					setUpdateCategory(row.data);
				}}>
				Update
			</button>,

			<button onClick={(_) => deleteCategory(row.data.id)} className="text-pink-500 font-bold">
				Delete
			</button>
		];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	return (
		<div className="card">
			<div className="flex align-items-center justify-content-between mb-3">
				<h5 className="m-0">Product Categories</h5>

				<Button loading={loading} onClick={(_) => setCreateVisible(true)} label="New Category" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>

			<TreeTable value={treeCategories} loading={loading} lazy>
				<Column header="Name" field="name" expander />
				<Column header="Image" field="media" body={imageBodyTemplate} className="w-8rem" />
				<Column header="Description" field="description" body={descriptionBodyTemplate} />
				<Column header="Banner Image" field="bannerImageMedia" body={bannerImageBodyTemplate} />
				<Column header="Actions" body={actionsBodyTemplate} className="w-9rem" />
			</TreeTable>

			{taxonomies.length > 0 ? (
				<React.Fragment>
					<CreateCategoryModal getAll={getAll} visible={createVisible} categories={treeCategories} setVisible={setCreateVisible} taxonomies={taxonomies} />
					<UpdateCategoryModal getAll={getAll} updateCategory={updateCategory} visible={updateVisible} categories={treeCategories} setVisible={setUpdateVisible} taxonomies={taxonomies} />
				</React.Fragment>
			) : null}
		</div>
	);
};

export default Categories;
