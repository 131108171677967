export class RemoveOrderFromDtfListRequest {
 	dtfListId: string
	orderId: string

	constructor(dtfListId: string, orderId: string) {
		this.dtfListId = dtfListId;
		this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return 'remove-order-from-dtf-list'
	}
}