import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

const defaultPagination = JSON.parse(JSON.stringify(new PaginationDto()));

const initialState = {
	forAdminProductPaginationInfo: { ...defaultPagination },
	forSellerProductPaginationInfo: { ...defaultPagination },
	forSellerOrderListPaginationInfo: { ...defaultPagination, itemCount: 20 } as PaginationDto,
	forSellerDtfOrderListPaginationInfo: { ...defaultPagination },
	forAdminDtfOrderListPaginationInfo: { ...defaultPagination },
	forMerchantProductPaginationInfo: { ...defaultPagination },
	forAdminOrderListPaginationInfo: { ...defaultPagination, itemCount: 20 } as PaginationDto,
	forAdminSellerListPaginationInfo: { ...defaultPagination },
	forAdminMerchantListPaginationInfo: { ...defaultPagination, itemCount: 20 } as PaginationDto,
	forAdminPrintCenterListPaginationInfo: { ...defaultPagination },
	forAdminAssignedProductListPaginationInfo: { ...defaultPagination },
	forAdminSellerStoreListPaginationInfo: { ...defaultPagination },
	forPrintramStockListPaginationInfo: { ...defaultPagination },
	forPrintCenterStockListPaginationInfo: { ...defaultPagination },
	forAdminContactsPaginationInfo: { ...defaultPagination, itemCount: 20 } as PaginationDto,
	forTemplateProductsPaginationInfo: { ...defaultPagination },
	forPrintCenterAssignedStorePaginationInfo: { ...defaultPagination },
	forSurveysPaginationInfo: { ...defaultPagination },
	forSellerBalanceHistoryPaginationInfo: { ...defaultPagination },
	forOperationStepsPaginationInfo: { ...defaultPagination },
	forAdminDtfListPaginationInfo: { ...defaultPagination },
	forProductCatalogPaginationInfo: { ...defaultPagination, itemCount: 6 } as PaginationDto,
	forSellerReorderPaginationInfo: { ...defaultPagination },
	forAdminReorderPaginationInfo: { ...defaultPagination }
};

const paginationSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		resetAll: (state) => {
			state = initialState;
			return state;
		},
		setForAdminProductPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminProductPaginationInfo = action.payload;

			return state;
		},
		setForSellerProductPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forSellerProductPaginationInfo = action.payload;

			return state;
		},
		setForSellerOrderListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forSellerOrderListPaginationInfo = action.payload;

			return state;
		},
		setSellerDtfOrderListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forSellerDtfOrderListPaginationInfo = action.payload;

			return state;
		},
		setAdminDtfOrderListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminDtfOrderListPaginationInfo = action.payload;

			return state;
		},
		setForMerchantProductPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forMerchantProductPaginationInfo = action.payload;

			return state;
		},
		setForAdminOrderListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminOrderListPaginationInfo = action.payload;

			return state;
		},
		setForAdminSellerListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminSellerListPaginationInfo = action.payload;

			return state;
		},
		setForAdminPrintCenterListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminPrintCenterListPaginationInfo = action.payload;

			return state;
		},
		setForAdminMerchantListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminMerchantListPaginationInfo = action.payload;

			return state;
		},
		setForAdminAssignedProductListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminAssignedProductListPaginationInfo = action.payload;

			return state;
		},
		setForAdminSellerStoreListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminSellerStoreListPaginationInfo = action.payload;

			return state;
		},
		setForPrintramStockListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forPrintramStockListPaginationInfo = action.payload;

			return state;
		},
		setForPrintCenterStockListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forPrintCenterStockListPaginationInfo = action.payload;

			return state;
		},
		setForAdminContactsPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminContactsPaginationInfo = action.payload;

			return state;
		},
		setForTemplateProductsPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forTemplateProductsPaginationInfo = action.payload;

			return state;
		},
		setForPrintCenterAssignedStorePaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forPrintCenterAssignedStorePaginationInfo = action.payload;

			return state;
		},
		setForSurveysPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forSurveysPaginationInfo = action.payload;

			return state;
		},
		setForSellerBalanceHistoryPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forSellerBalanceHistoryPaginationInfo = action.payload;

			return state;
		},
		setForOperationStepsPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forOperationStepsPaginationInfo = action.payload;

			return state;
		},
		setForAdminDtfListPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminDtfListPaginationInfo = action.payload;

			return state;
		},
		setForProductCatalogPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forProductCatalogPaginationInfo = action.payload;

			return state;
		},
		setForSellerReorderPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forSellerReorderPaginationInfo = action.payload;

			return state;
		},
		setForAdminReorderPaginationInfo: (state, action: PayloadAction<PaginationDto>) => {
			state.forAdminReorderPaginationInfo = action.payload;

			return state;
		}
	}
});

export const { resetAll, setForOperationStepsPaginationInfo, setForAdminProductPaginationInfo, setForProductCatalogPaginationInfo, setForSellerProductPaginationInfo, setForSellerOrderListPaginationInfo, setSellerDtfOrderListPaginationInfo, setAdminDtfOrderListPaginationInfo, setForMerchantProductPaginationInfo, setForAdminOrderListPaginationInfo, setForAdminSellerListPaginationInfo, setForAdminPrintCenterListPaginationInfo, setForAdminAssignedProductListPaginationInfo, setForAdminSellerStoreListPaginationInfo, setForAdminMerchantListPaginationInfo, setForPrintramStockListPaginationInfo, setForPrintCenterStockListPaginationInfo, setForAdminContactsPaginationInfo, setForTemplateProductsPaginationInfo, setForPrintCenterAssignedStorePaginationInfo, setForSurveysPaginationInfo, setForSellerBalanceHistoryPaginationInfo, setForAdminDtfListPaginationInfo, setForSellerReorderPaginationInfo, setForAdminReorderPaginationInfo } = paginationSlice.actions;

export default paginationSlice.reducer;
