import HttpBaseService from 'services/_common/http-base-service';
import { GetGroupsRequest } from './get-groups/get-groups.request';
import { GetGroupsResponse } from './get-groups/get-groups.response';
import { CreateGroupRequest } from './create-group/create-group.request';
import { CreateGroupResponse } from './create-group/create-group.response';
import { UpdateGroupRequest } from './update-group/update-group.request';
import { UpdateGroupResponse } from './update-group/update-group.response';
import { GetGroupUsersRequest } from './get-group-users/get-group-users.request';
import { GetGroupUsersResponse } from './get-group-users/get-group-users.response';
import { UpsertUserGroupRequest } from './upsert-user-group/upsert-user-group.request';
import { UpsertUserGroupResponse } from './upsert-user-group/upsert-user-group.response';
import { GetSellerListForGroupRequest } from './get-seller-list-for-group/get-seller-list-for-group.request';
import { GetSellerListForGroupResponse } from './get-seller-list-for-group/get-seller-list-for-group.response';
import { DeleteGroupRequest } from './delete-group/delete-group.request';

class GroupsService extends HttpBaseService {
	constructor() {
		super('groups');
	}

	async getGroups(request: GetGroupsRequest): Promise<GetGroupsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createGroup(request: CreateGroupRequest): Promise<CreateGroupResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async updateGroup(request: UpdateGroupRequest): Promise<UpdateGroupResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async getGroupUsers(request: GetGroupUsersRequest): Promise<GetGroupUsersResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getSellerListForGroup(request: GetSellerListForGroupRequest): Promise<GetSellerListForGroupResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async upsertUserGroup(request: UpsertUserGroupRequest): Promise<UpsertUserGroupResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async deleteGroup(request: DeleteGroupRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension)
	}
}

export default new GroupsService();
