import { DateTime, Interval } from 'luxon';

const formatDate = (date: string | Date) => {
    const luxonDate = DateTime.fromJSDate(new Date(date));

    return luxonDate.toLocaleString({ month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
};

const getMinutesDiffOfTwoDate = (pDate1: Date, pDate2 = new Date()) => {
    const date1 = DateTime.fromJSDate(pDate1);
    const date2 = DateTime.fromJSDate(pDate2);

    const diff = date1.diff(date2, 'minutes');

    return Math.round(diff.minutes);
};

const dateHelper = { getMinutesDiffOfTwoDate, formatDate };

export default dateHelper;
