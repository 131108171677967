import HttpBaseService from "services/_common/http-base-service";
import { GetUserProductVariantPricesRequest } from "./get-user-product-variant-prices/get-user-product-variant-prices.request";
import { GetUserProductVariantPricesResponse } from "./get-user-product-variant-prices/get-user-product-variant-prices.response";
import { CreateOrUpdateProductVariantPricesResponse } from "./create-or-update-product-variant-prices/create-or-update-product-variant-prices.response";
import { CreateOrUpdateProductVariantPricesRequest } from "./create-or-update-product-variant-prices/create-or-update-product-variant-prices.request";
import { DeleteUserProductVariantPricesRequest } from "./delete-user-product-variant-prices/delete-user-product-variant-prices.request";
import { DeleteUserProductVariantPricesResponse } from "./delete-user-product-variant-prices/delete-user-product-variant-prices.response";
import { GetGroupProductVariantPricesRequest } from "./get-group-product-variant-prices/get-group-product-variant-prices.request";
import { GetGroupProductVariantPricesResponse } from "./get-group-product-variant-prices/get-group-product-variant-prices.response";
import { DeleteGroupProductVariantPricesRequest } from "./delete-group-product-variant-prices/delete-group-product-variant-prices.request";
import { DeleteGroupProductVariantPricesResponse } from "./delete-group-product-variant-prices/delete-group-product-variant-prices.response";
import { CreateOrUpdateProductVariantPricesForGroupRequest } from "./create-or-update-product-variant-prices-for-group/create-or-update-product-variant-prices-for-group.request";
import { CreateOrUpdateProductVariantPricesForGroupResponse } from "./create-or-update-product-variant-prices-for-group/create-or-update-product-variant-prices-for-group.response";


class ProductVariantPricesService extends HttpBaseService {
	constructor() {
		super('product-variant-prices');
	}

	async getUserProductVariantPrices(request: GetUserProductVariantPricesRequest): Promise<GetUserProductVariantPricesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getGroupProductVariantPrices(request: GetGroupProductVariantPricesRequest): Promise<GetGroupProductVariantPricesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createOrUpdateUserProductVariantPrices(request: CreateOrUpdateProductVariantPricesRequest): Promise<CreateOrUpdateProductVariantPricesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createOrUpdateGroupProductVariantPrices(request: CreateOrUpdateProductVariantPricesForGroupRequest): Promise<CreateOrUpdateProductVariantPricesForGroupResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async deleteUserProductVariantPrice(request: DeleteUserProductVariantPricesRequest): Promise<DeleteUserProductVariantPricesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async deleteGroupProductVariantPrice(request: DeleteGroupProductVariantPricesRequest): Promise<DeleteGroupProductVariantPricesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new ProductVariantPricesService();
