import { CreateGroupRequest } from 'features/groups/_common/create-group/create-group.request';
import groupsService from 'features/groups/_common/groups.service';
import { Form, Formik } from 'formik';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import * as Yup from 'yup';

type props = {
	isVisible: boolean;
	setVisible: Function;
	getGroups: Function;
};

const CreateSellerGroup = ({ isVisible, setVisible, getGroups }: props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues: CreateGroupRequest = new CreateGroupRequest({ name: '', description: '', isActive: true });

	const validationScheme = Yup.object().shape({ name: Yup.string().required('Group name is Required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

	const submitForm = async (values: CreateGroupRequest) => {
		try {
			setLoading(true);

			const request = new CreateGroupRequest(values);

			const response = await groupsService.createGroup(request);

			if (!response.isSuccess) throw '';

			setVisible(false);
			getGroups();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			visible={isVisible}
			header="New Group"
			modal
			onHide={() => setVisible(false)}>
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div>
							<div>
								<PrTextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Group name" className="w-full" />
							</div>
							<div className="my-3">
								<PrTextInput label="Description" name="description" value={values.description} onChange={handleChange} placeholder="Description" className="w-full" />
							</div>
							<div className="flex justify-content-end">
								<PrButton btnType="submit" text="Create" loading={loading} className="py-3" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default CreateSellerGroup;
