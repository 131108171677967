import HttpBaseService from 'services/_common/http-base-service';
import { GetBalanceByUserIdRequest } from './get-balance-by-user-id/get-balance-by-user-id.request';
import { GetBalanceByUserIdResponse } from './get-balance-by-user-id/get-balance-by-user-id.response';
import { IncreaseWithChargeResponse } from './increase-with-charge/increate-with-charge.response';
import { IncreaseWithChargeRequest } from './increase-with-charge/increase-with-charge.request';
import { GetBalanceHistoryByUserRequest } from './get-balance-history-by-user/get-balance-history-by-user.request';
import { GetBalanceHistoryByUserResponse } from './get-balance-history-by-user/get-balance-history-by-user.response';
import { CreateWithdrawRequestRequest } from './create-withdraw-request/create-withdraw-request.request';
import { CreateWithdrawRequestResponse } from './create-withdraw-request/create-withdraw-request.response';
import { IncreaseBalanceRequest } from './increase-balance/increase-balance.request';
import { IncreaseBalanceResponse } from './increase-balance/increase-balance.response';
import { DecreaseBalanceRequest } from './decrease-balance/decrease-balance.request';
import { DecreaseBalanceResponse } from './decrease-balance/decrease-balance.response';
import { BalanceWithdrawRequest } from './balance-withdraw/balance-withdraw.request';
import { BalanceWithdrawResponse } from './balance-withdraw/balance-withdraw.response';
import { GetBalanceHistoryReportRequest } from './get-balance-history-report/get-balance-history-report.request';
import { GetBalanceHistoryReportResponse } from './get-balance-history-report/get-balance-history-report.response';

class BalanceService extends HttpBaseService {
	constructor() {
		super('balances');
	}

	async getBalanceByUserId(request: GetBalanceByUserIdRequest): Promise<GetBalanceByUserIdResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getBalanceHistoryByUser(request: GetBalanceHistoryByUserRequest): Promise<GetBalanceHistoryByUserResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getBalanceHistoryReport(request: GetBalanceHistoryReportRequest): Promise<GetBalanceHistoryReportResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async increaseWithCharge(request: IncreaseWithChargeRequest): Promise<IncreaseWithChargeResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createWithdrawRequest(request: CreateWithdrawRequestRequest): Promise<CreateWithdrawRequestResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	//#region Admin
	async increaseBalance(request: IncreaseBalanceRequest): Promise<IncreaseBalanceResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async decreaseBalance(request: DecreaseBalanceRequest): Promise<DecreaseBalanceResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async balanceWithdraw(request: BalanceWithdrawRequest): Promise<BalanceWithdrawResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
	//#endregion
}

export default new BalanceService();
