import { GetProductsRequest } from 'features/products/_common/boost/get-products/get-products.request';
import { ProductDto } from 'features/products/_common/boost/get-products/get-products.response';
import productService from 'features/products/_common/product.service';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import styles from './SelectPrintramProductDialogNew.module.scss';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import filterHelper from 'helpers/filter.helper';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import currencyHelper from 'helpers/curreny.helper';
import { FaRegCheckCircle } from 'react-icons/fa';
import { ProductFilterDto } from 'features/products/_common/boost/get-product-filters/get-product-filters.response';
import { GetProductFiltersRequest } from 'features/products/_common/boost/get-product-filters/get-product-filters.request';
import { TreeSelect } from 'primereact/treeselect';
import PrDropdown from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { TbFilterOff } from 'react-icons/tb';
import { ProductInformationsDto } from 'features/products/_common/boost/get-product-informations/get-product-informations.response';
import { GetProductInformationsRequest } from 'features/products/_common/boost/get-product-informations/get-product-informations.request';
import { Tooltip } from 'primereact/tooltip';
import { GetCoreProductDetailRequest } from 'features/products/_common/boost/get-core-product-detail/get-core-product-detail.request';
import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import { SyncEtsyProduct } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/sync-etsy-product.dto';

type Props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	handleProductClick?: (product: CoreProductSynchronizeDto) => void;
	productId?: string;
	syncProduct?: SyncEtsyProduct;
};

export const SelectPrintramProductDialogNew = (props: Props) => {
	const [loadings, setLoadings] = useState<number[]>([]);
	const [products, setProducts] = useState<ProductDto[]>([]);
	const [filteredProducts, setFilteredProducts] = useState<ProductDto[]>([]);
	const [productFilters, setProductFilters] = useState<ProductFilterDto | undefined>(undefined);
	const [productInformations, setProductInformations] = useState<ProductInformationsDto | undefined>(undefined);
	const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
	const [brandId, setBrandId] = useState<string | undefined>(undefined);
	const [modelId, setModelId] = useState<string | undefined>(undefined);
	const [selectedProduct, setSelectedProduct] = useState<ProductDto | undefined>(undefined);

	const clearAllStates = (closeDialog: boolean = false) => {
		setProducts([]);
		setFilteredProducts([]);
		setProductFilters(undefined);
		setProductInformations(undefined);
		setCategoryId(undefined);
		setBrandId(undefined);
		setModelId(undefined);
		setSelectedProduct(undefined);

		if (closeDialog) props.setVisible(false);
	};

	const getProducts = async () => {
		try {
			setLoadings((_loadings) => {
				const addedLoadings = [..._loadings];

				addedLoadings.push(_loadings.length);

				return addedLoadings;
			});

			const response = await productService.getProducts(new GetProductsRequest({ categoryId, brandId, modelId }));
			if (!response.isSuccess || !response.data) throw '';

			setProducts(response.data);
			setFilteredProducts(response.data);

			if (!!props.productId) {
				const findedProductId = response.data.find((_product: ProductDto) => _product.productId === props.productId)?.productId;
				if (!!findedProductId) getCoreProductDetail(findedProductId);
			}
		} catch (error) {
			setProducts([]);
		} finally {
			setLoadings((_loadings) => {
				const deletedLoadings = _loadings.length === 1 ? [] : _loadings.splice(0, 1);

				return deletedLoadings;
			});
		}
	};

	const getProductFilters = async () => {
		try {
			setLoadings((_loadings) => {
				const addedLoadings = [..._loadings];

				addedLoadings.push(_loadings.length);

				return addedLoadings;
			});

			const response = await productService.getProductFilters(new GetProductFiltersRequest({ categoryId, brandId }));
			if (!response.isSuccess || !response.data) throw '';

			setProductFilters(response.data);
		} catch (error) {
			setProductFilters(undefined);
		} finally {
			setLoadings((_loadings) => {
				const deletedLoadings = _loadings.length === 1 ? [] : _loadings.splice(0, 1);

				return deletedLoadings;
			});
		}
	};

	const getProductInformations = async (productId: string) => {
		try {
			setLoadings((_loadings) => {
				const addedLoadings = [..._loadings];

				addedLoadings.push(_loadings.length);

				return addedLoadings;
			});

			const response = await productService.getProductInformations(new GetProductInformationsRequest(productId));
			if (!response.isSuccess || !response.data) throw '';

			setProductInformations(response.data);
		} catch (error) {
			setProductInformations(undefined);
		} finally {
			setLoadings((_loadings) => {
				const deletedLoadings = _loadings.length === 1 ? [] : _loadings.splice(0, 1);

				return deletedLoadings;
			});
		}
	};

	const getCoreProductDetail = async (productId?: string) => {
		try {
			if (!productId || !selectedProduct?.productId || !productInformations?.id) throw '';

			setLoadings((_loadings) => {
				const addedLoadings = [..._loadings];

				addedLoadings.push(_loadings.length);

				return addedLoadings;
			});

			const response = await productService.getCoreProductDetail(new GetCoreProductDetailRequest(productId || selectedProduct?.productId || productInformations?.id));
			if (!response.isSuccess || !response.data) throw '';

			props.handleProductClick?.(response.data);
			clearAllStates(true);
		} catch (error) {
		} finally {
			setLoadings((_loadings) => {
				const deletedLoadings = _loadings.length === 1 ? [] : _loadings.splice(0, 1);

				return deletedLoadings;
			});
		}
	};

	useEffect(() => {
		if (props.visible) {
			getProducts();
		}
	}, [props.visible, categoryId, brandId, modelId]);

	useEffect(() => {
		if (props.visible) {
			getProductFilters();
		}
	}, [props.visible, categoryId, brandId]);

	return (
		<Dialog header="Select Printram Product" draggable={false} visible={props.visible} className={styles.dialog} onHide={() => clearAllStates(true)}>
			<div className={styles.wrapper}>
				<aside className={styles.info}>
					{props.syncProduct && (
						<div>
							<h5>
								<span>Etsy Order Details</span>
							</h5>

							<div className={styles.productInfo}>
								<div className="mt-3 mb-6">
									<p className="m-0">
										Name &#x2022; <span className="font-bold" dangerouslySetInnerHTML={{ __html: props.syncProduct.title }} />
									</p>

									{props.syncProduct.variations.map((_vari, index) => (
										<div key={index}>
											<p className="m-0">
												<span dangerouslySetInnerHTML={{ __html: _vari.key }} /> &#x2022; <span className="font-bold" dangerouslySetInnerHTML={{ __html: _vari.value }} />
											</p>
										</div>
									))}

									<p className="m-0">
										SKU &#x2022; <span className="font-bold" dangerouslySetInnerHTML={{ __html: props.syncProduct.sku }} />
									</p>
								</div>
							</div>
						</div>
					)}

					<h5>
						<span>{!!productInformations && 'Choosed '}Product Details</span>
					</h5>

					<div className={styles.productInfo}>
						{!!productInformations && !!selectedProduct ? (
							<React.Fragment>
								<p className="m-0">
									Name &#x2022; <span className="font-bold">{selectedProduct.productName}</span>
								</p>

								<p className="mt-2 mb-4">
									Model &#x2022;{' '}
									<span className="font-bold">
										{selectedProduct.brandName} {selectedProduct.modelName}
									</span>
								</p>

								<div style={{ background: 'var(--surface-400)', height: '2px' }} />

								<p className="m-0 mt-4 font-bold">Available Colors</p>

								<div className={styles.colorWrapper}>
									{productInformations.colors.map((_color, index) => (
										<div
											key={index}
											id={`color${_color.id}`}
											data-pr-tooltip={`${_color.name} ${_color.hexCode}`}
											data-pr-position="top"
											className={styles.colorItem}
											style={
												{
													'--color-item-bg-color': _color.hexCode,
													backgroundImage: `url("${_color.imageUrl}")`,
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													backgroundSize: 'cover'
												} as React.CSSProperties
											}>
											<Tooltip target={`#color${_color.id}`} />
										</div>
									))}
								</div>

								<div style={{ background: 'var(--surface-400)', height: '2px' }} />

								<p className="m-0 mt-4 font-bold">Available Sizes</p>

								<div className={styles.sizeWrapper}>
									{productInformations.sizes.map((_size, index) => (
										<div key={index} id={`size${_size.id}`} data-pr-tooltip={`${_size.name}`} data-pr-position="top" className={styles.sizeItem}>
											<Tooltip target={`#size${_size.id}`} />
											{_size.shortName}
										</div>
									))}
								</div>
							</React.Fragment>
						) : (
							<h5 className="m-0 py-4 px-2 flex align-items-center font-semibold text-color-secondary justify-content-center surface-ground pointer-events-none">Select product first</h5>
						)}
					</div>
				</aside>

				<section className={styles.container}>
					<SearchInput
						disabled={loadings.length > 0}
						onChange={(e) =>
							setFilteredProducts((_products) => {
								const newFilteredProducts = filterHelper.fromAllData(_products, e.target.value);

								return e.target.value.length > 2 && newFilteredProducts.length > 0 ? newFilteredProducts : [...products];
							})
						}
					/>

					<div className="flex gap-3 my-4">
						<TreeSelect
							value={categoryId}
							disabled={loadings.length > 0}
							draggable={false}
							options={productFilters?.categories.map((_category) => ({
								key: _category.parentCategoryId,
								label: _category.parentCategoryName,
								selectable: false,
								children: _category.subCategories.map((_sCategory) => ({ label: _sCategory.name, key: _sCategory.id }))
							}))}
							metaKeySelection={false}
							placeholder="Select Category"
							className="w-full"
							filter
							onChange={(event) => {
								const value = event.value as string | null;

								if (!value) return;

								setCategoryId(value);
							}}
						/>

						<PrDropdown
							value={productFilters?.brands.map((_brand) => ({ label: _brand.name, value: _brand.id })).filter((_brand) => _brand.value === brandId)[0]}
							options={productFilters?.brands.map((_brand) => ({ label: _brand.name, value: _brand.id }))}
							filter
							disabled={loadings.length > 0}
							placeholder="Select Brand"
							clearable
							className={styles.filterDropdown}
							onClear={() => setBrandId(undefined)}
							onChange={(event) => {
								const value = event.value as string;

								if (!value) return;

								setBrandId(event.value.toString());
							}}
						/>

						<PrDropdown
							value={productFilters?.models.map((_model) => ({ label: _model.name, value: _model.id })).filter((_model) => _model.value === modelId)[0]}
							options={productFilters?.models.map((_model) => ({ label: _model.name, value: _model.id }))}
							filter
							disabled={loadings.length > 0}
							placeholder="Select Model"
							clearable
							className={styles.filterDropdown}
							onClear={() => setModelId(undefined)}
							onChange={(event) => {
								const value = event.value as string;

								if (!value) return;

								setModelId(event.value.toString());
							}}
						/>

						<PrButton
							icon={<TbFilterOff size={18} />}
							onClick={() => {
								setCategoryId(undefined);
								setBrandId(undefined);
								setModelId(undefined);
							}}
							loading={loadings.length > 0}
							text="Clear"
							className={styles.clearFilterBtn}
						/>
					</div>

					<div className={styles.productWrapper}>
						{loadings.length > 0 && filteredProducts.length < 1 ? (
							<h5 className="m-0 py-4 px-2 flex align-items-center font-semibold text-color-secondary justify-content-center surface-ground pointer-events-none">Loading...</h5>
						) : (
							filteredProducts.map((_product, index) => (
								<div
									key={index}
									role="button"
									className={`${styles.productItem}${productInformations?.id === _product.productId ? ` ${styles.active}` : ''}${loadings.length > 0 ? ` ${styles.loading}` : ''}`}
									onDoubleClick={() => getCoreProductDetail(_product.productId)}
									onClick={async () => {
										if (selectedProduct?.productId === _product.productId) return;

										await getProductInformations(_product.productId);
										setSelectedProduct(_product);
									}}>
									<div className={styles.imgDivider}>
										<img src={amazonS3Helper.showFile(_product.media, { resize: '80x80' })} loading="lazy" alt={_product.productName} height={80} />
									</div>

									<div className={styles.productItemInfo}>
										<span>{_product.productName}</span>

										<span>
											{_product.brandName} {_product.modelName}
										</span>

										{/* Removed price info */}
										{/*<span>{currencyHelper.formatPrice(_product.price.formattedPricePerUnit)}</span>*/}
									</div>

									{_product.productId === productInformations?.id ? <FaRegCheckCircle size={30} className="ml-auto mr-6" /> : null}
								</div>
							))
						)}
					</div>
				</section>
			</div>

			<section className={styles.footer}>
				<PrButton btnType="button" disabled={!selectedProduct?.productId || !productInformations?.id} onClick={() => getCoreProductDetail(selectedProduct?.productId || productInformations?.id)} text="Confirm selection" />
			</section>
		</Dialog>
	);
};
