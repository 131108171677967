import React from 'react';
import style from '../../../../orders/create-order/steps/shipping/components/rates/ShippingRates.module.scss';
import { RadioButton } from 'primereact/radiobutton';
import currencyHelper from '../../../../../../helpers/curreny.helper';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../../context/CustomDtfContextProvider';
import {
	ShippingRateDto
} from '../../../../../../features/orders/derived-features/order-transports/dtos/shipping-rate.dto';

const CustomDtfShippingRates = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;

	const onSelectedShipRateChange = (rate: ShippingRateDto) => {
		context.setCalculatedPrice(context.grandTotal + rate.amount);
		context.setSelectedRate(rate);
	}

	return (
		<div>
			<div className={style.warningMessage}>If the cargo company calculates a different weight for the order, the difference will be charged.</div>

			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping method
			</h2>
			<div className={style.ratesWrapper}>
				{context.shippingRates.map((_rate, index) => (
					<div key={index} className="field-radiobutton">
						<RadioButton inputId={`rate${_rate.rateId || _rate.serviceName}`} value={_rate.rateId || _rate.serviceName} name="rate" onChange={() => onSelectedShipRateChange(_rate)} checked={context.selectedRate?.rateId ? _rate.rateId === context.selectedRate?.rateId : _rate.serviceName === context.selectedRate?.serviceName} />

						<label htmlFor={`rate${_rate.rateId || _rate.serviceName}`}>
							{currencyHelper.formatPrice(+_rate.amount)} - {_rate.serviceName}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomDtfShippingRates;
