import React, { useEffect } from 'react';
import { CreateWizardContext, WizardContextProvider, WizardContextType, WizardStep } from './context/WizardContextProvider';
import './Wizard.module.scss';
import surveyService from '../../../../features/surveys/_common/survey.service';
import AccountDetails from './steps/AccountDetails';
import ChannelsUsing from './steps/ChannelsUsing';
import ProductsInterested from './steps/ProductsInterested';
import CustomProducts from './steps/CustomProducts';
import SalesFigures from './steps/SalesFigures';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SurveyGetAvailableByUserRequest } from '../../../../features/surveys/_common/survey-get-available-by-user/survey-get-available-by-user.request';
import localStorageHelper from '../../../../helpers/local-storage.helper';
import { useNavigate } from 'react-router-dom';

function Wizard() {
	const context: WizardContextType = React.useContext(CreateWizardContext) as WizardContextType;
	const navigate = useNavigate();

	useEffect(() => {
		const handlePopState = (event: any) => {
			event.preventDefault();
			navigate(1);
		};

		window.history.pushState(null, '', window.location.href);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [navigate]);

	useEffect(() => {
		getWizard();
	}, []);

	useEffect(() => {
		if (context.wizardSteps.length > 0) {
			context.setStep(0);
		}
	}, [context.wizardSteps]);

	const getWizard = async () => {
		try {
			context.setLoading(true);

			const request = new SurveyGetAvailableByUserRequest(context.user?.userId!, 1);
			const response = await surveyService.getAvailableByUser(request);

			if (!response.isSuccess || !response.data) throw '';

			const wizardSurvey = response.data![0];

			if (!wizardSurvey) return;

			let w: WizardStep[] = [];

			for (let i = 0; i < 5; i++) {
				if (i === 0) {
					w.push({
						isCanNext: true,
						isCompleted: false,
						index: i,
						questionId: '',
						component: <AccountDetails />
					});
					w.push({
						isCanNext: true,
						isCompleted: false,
						index: i + 1,
						questionId: wizardSurvey.surveyQuestions[i].id,
						component: <CustomProducts question={wizardSurvey.surveyQuestions[i]} />
					});
				} else if (i === 1) {
					w.push({
						isCompleted: false,
						isCanNext: true,
						index: i + 1,
						questionId: wizardSurvey.surveyQuestions[i].id,
						component: <ChannelsUsing question={wizardSurvey.surveyQuestions[i]} />
					});
				} else if (i === 2) {
					w.push({
						isCompleted: false,
						isCanNext: true,
						index: i + 1,
						questionId: wizardSurvey.surveyQuestions[i].id,
						component: <ProductsInterested question={wizardSurvey.surveyQuestions[i]} />
					});
				} else if (i === 3) {
					w.push({
						isCompleted: false,
						isCanNext: true,
						index: i + 1,
						questionId: wizardSurvey.surveyQuestions[i].id,
						component: <SalesFigures question={wizardSurvey.surveyQuestions[i]} />
					});
				}
			}

			context.setWizardSteps(w);
		} catch (error) {
			localStorageHelper.remove('wizardAvaliable');
			navigate('/');
		} finally {
			context.setLoading(false);
		}
	};

	return (
		<div className="flex justify-content-center align-items-start w-screen h-screen pt-8">
			<div className="flex flex-column justify-content-center align-items-center sm:w-full md:w-full lg:w-8 xl:w-6" style={{ maxWidth: '612px' }}>
				<img className="mb-4" style={{ width: '163.15px', height: '60px' }} src="/logo/printram-main-logo.svg" alt="logo" />

				{context.loading ? (
					<ProgressSpinner
						className="absolute top-50 left-50"
						style={{
							width: '50px',
							height: '50px',
							transform: 'translate(-50%, -50%)'
						}}
						strokeWidth="8"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				) : context.step && context.wizardSteps.length !== 0 ? (
					context.step.component
				) : (
					<h1>No steps available</h1>
				)}
			</div>
		</div>
	);
}

function WizardWrapper() {
	return (
		<WizardContextProvider>
			<Wizard />
		</WizardContextProvider>
	);
}

export default WizardWrapper;