import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface';
import integrationsService from 'features/integrations/_common/integrations.service';
import { SearchOrdersRequest } from 'features/integrations/_common/search-orders/search-orders.request';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { GetOrderItemSummariesRequest } from 'features/orders/_common/get-order-item-summaries/get-order-item-summaries.request';
import orderService from 'features/orders/_common/order.service';
import { CreateOrderStatuHistoryRequest } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import dateHelper from 'helpers/dateHelper';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import OrderFilterTab from 'helpers/widgets/Order/OrderFilterTab/OrderFilterTab';
import PrintDropdown from 'helpers/widgets/Order/PrintDropdown/PrintDropdown';
import PrMultipleCalendar from 'helpers/widgets/Printram/Calendar/PrMultipleCalendar';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Pagination } from 'models/_commons/responses/pagination';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Link, useSearchParams } from 'react-router-dom';
import { setForAdminOrderListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './Orders.module.scss';
import CreateOrderStatuHistoryModal, { CreateOrderStatuHistoryModalData } from './modals/CreateOrderStatuHistoryModal';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { GetOrderReportsBasedOnItemsWithPaymentsRequest } from 'features/orders/_common/get-order-reports-based-on-items-with-payments/get-order-reports-based-on-items-with-payments.request';
import SendtoNewDtf from './components/SendtoNewDtf';
import DtfDropdown from 'helpers/widgets/Order/Dtf/DtfDropdown/DtfDropdown';
import SendtoExistingDtf from './components/SendtoExistingDtf';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import { GetOrdersForAdminNewRequest } from 'features/orders/_common/get-orders-for-admin-new/get-orders-for-admin-new.request';
import { OrderForListNewDto } from 'features/orders/dtos/order-for-list-new.dto';
import { GetOrdersNewStoreDto } from 'features/orders/_common/get-orders-for-admin-new/get-orders-for-admin-new.response';
import SendNewAutomatedDtf from "./components/SendNewAutomatedDtf";
import sendNewAutomatedDtf from "./components/SendNewAutomatedDtf";

const Orders = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminOrderListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();
	const searchStatus = searchParams.get('status');

	const [loading, setLoading] = useState<boolean>(false);
	const [statuLoading, setStatuLoading] = useState<boolean>(false);
	const [exportLoading, setExportLoading] = useState<boolean>(false);
	const [statuModalVisible, setStatuModalVisible] = useState<boolean>(false);
	const [statuModalData, setStatuModalData] = useState<CreateOrderStatuHistoryModalData | undefined>(undefined);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const [orders, setOrders] = useState<OrderForListNewDto[]>([]);
	const [selectedOrders, setSelectedOrders] = useState<OrderForListNewDto[]>([]);
	const [orderStores, setOrderStores] = useState<GetOrdersNewStoreDto[]>([]);
	const [selectedOrderStore, setSelectedOrderStore] = useState<Partial<GetOrdersNewStoreDto> | null>(null);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [filterOrderStatuType, setFilterOrderStatuType] = useState<OrderStatuTypes | null>(!!searchStatus ? Number(searchStatus) || null : null);
	const [lastRequest, setLastRequest] = useState<GetOrdersForAdminNewRequest>(new GetOrdersForAdminNewRequest({}));
	const [serviceLabelNames, setServiceLabelNames] = useState<string[]>([]);
	const [selectedServiceLabelName, setSelectedServiceLabelName] = useState<string | null>(null);
	const [exportOptions, setExportOptions] = useState<PrDropdownType[]>([{ label: 'Export Order Payments', value: 'payment' }]);
	const [sendNewDtfVisible, setSendNewDtfVisible] = useState<boolean>(false);
	const [sendNewAutomatedDtfVisible, setSendNewAutomatedDtfVisible] = useState<boolean>(false);
	const [sendExistingDtfVisible, setSendExistingDtfVisible] = useState<boolean>(false);
	const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryTypes | null>(null);

	const getAll = async (request: Partial<GetOrdersForAdminNewRequest>) => {
		request.orderStatus = filterOrderStatuType || null;
		request.contains = typeof request.contains !== 'undefined' ? request.contains : searchFilter || null;
		request.storeId = selectedOrderStore?.id || null;
		request.labelServiceName = selectedServiceLabelName || null;
		request.deliveryType = selectedDeliveryType || null;
		request.includeStores = !request.storeId ? true : false;
		request.includeLabelServiceNames = !request.labelServiceName ? true : false;

		const descStatus = [null, 1, 2, 6, 7, 8, 12];

		const clonedRequest = new GetOrdersForAdminNewRequest({ ...structuredClone(request) });

		clonedRequest.pagination.orderBy = descStatus.includes(filterOrderStatuType) ? PaginationOrderByEnum.Descending : PaginationOrderByEnum.Ascending;

		try {
			setLoading(true);

			const response = await orderService.getOrdersForAdminNew(clonedRequest);

			if (!response.isSuccess || !response.data?.orders) throw '';

			setOrders(response.data.orders);
			setPaginationResponse(response.data.pagination);

			if (!request.storeId && response.data.stores.length > 0) {
				setOrderStores(response.data.stores);
			}

			if (!request.labelServiceName && response.data.serviceLabelNames.length > 0) {
				setServiceLabelNames(response.data.serviceLabelNames);
			}

			const newPagination = JSON.parse(JSON.stringify(clonedRequest.pagination));

			dispatch(setForAdminOrderListPaginationInfo(newPagination));

			console.clear();
		} catch (error) {
			setOrders([]);
			setPaginationResponse(undefined);
		} finally {
			setSelectedOrders([]);
			setLoading(false);
			setLastRequest(clonedRequest);
		}
	};

	const syncOrders = async () => {
		try {
			setLoading(true);
			const response = await integrationsService.searchOrders(new SearchOrdersRequest());

			if (!response.isSuccess) throw '';
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const updateOrderStatus = async (request: Partial<CreateOrderStatuHistoryRequest>) => {
		try {
			setStatuLoading(true);

			const response = await orderStatuHistoriesService.create(new CreateOrderStatuHistoryRequest({ ...request }));

			if (!response.isSuccess) throw '';

			await getAll(lastRequest);
		} catch (error) {
		} finally {
			setStatuLoading(false);
		}
	};

	const exportProducts = async () => {
		try {
			if (!filterOrderStatuType) throw '';

			setExportLoading(true);

			const request = new GetOrderItemSummariesRequest(filterOrderStatuType);

			const response = await orderService.getOrderItemSummaries(request);

			if (!response.isSuccess) throw '';

			response.data?.archiveUrl && window.open(response.data?.archiveUrl, '_self');
		} catch (error) {
		} finally {
			setExportLoading(false);
		}
	};

	const exportPayments = async () => {
		try {
			setExportLoading(true);

			const request = new GetOrderReportsBasedOnItemsWithPaymentsRequest();

			const response = await orderService.getOrderReportsBasedOnItemsWithPayments(request);

			if (!response.isSuccess) throw '';

			response.data?.url && window.open(response.data.url, '_self');
		} catch (error) {
		} finally {
			setExportLoading(false);
		}
	};

	const setReadableName = (value: number) => {
		if (!value) return '';

		let newValue = stringHelper.parseAtUpperCaseAndJoin(OrderStatuTypes[value]);

		newValue = newValue.split('Order').join('');

		return newValue;
	};

	const handleReport = async (reportValue: string) => {
		if (reportValue === 'products') {
			exportProducts();
		} else if (reportValue === 'payments') {
			exportPayments();
		}
	};

	const exportOptionsHandler = () => {
		if (filterOrderStatuType && filterOrderStatuType !== OrderStatuTypes.PendingApproval) {
			const exports: PrDropdownType[] = [
				{ label: 'Export Order Payments', value: 'payments' },
				{ label: `Export ${stringHelper.parseAtUpperCaseAndJoin(OrderStatuTypes[filterOrderStatuType!])} Products`, value: 'products' }
			];
			setExportOptions(exports);
		} else {
			setExportOptions([{ label: 'Export Order Payments', value: 'payments' }]);
		}
	};

	useEffect(() => {
		getAll({});

		const newLastRequest = structuredClone(lastRequest);

		newLastRequest.dateFilter.minDate = null;
		newLastRequest.dateFilter.maxDate = null;

		setLastRequest(newLastRequest);
		exportOptionsHandler();
	}, [searchStatus, selectedOrderStore, selectedServiceLabelName, selectedDeliveryType]);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">{!filterOrderStatuType ? 'All' : setReadableName(filterOrderStatuType)} Orders</h1>

				<div className="container-header-tools">
					<PrDropdown key={exportOptions.length} options={exportOptions} onChange={(e) => handleReport(e.value.toString())} optionIcon={<SiMicrosoftexcel />} disabled={exportLoading} placeholder="Export Reports" className="z-5" />

					<PrButton type="secondary" text="Synchronize Orders" loading={loading} onClick={syncOrders} icon={<span className="pi pi-fw pi-sync" />} />

					<PrButton
						type="secondary"
						text="Refresh Orders"
						loading={loading}
						icon={<span className="pi pi-fw pi-refresh" />}
						onClick={async () => {
							setSearchFilter('');
							await getAll({ contains: null });
						}}
					/>
				</div>
			</div>

			<section className="container-body p-3 z-1">
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the Order page from the options below.</p>

				<OrderFilterTab filterOrderStatuType={filterOrderStatuType} setFilterOrderStatuType={setFilterOrderStatuType} page="admin" />

				<div className="flex mt-4">
					<div className="flex-1">
						<h5 className="mb-2">Store Filter</h5>

						<PrDropdown
							clearable
							filter
							placeholder="Select Seller Store"
							onClear={() => {
								setSelectedOrderStore(null);
							}}
							onChange={(e) => {
								setSelectedOrderStore({ id: e.value as string, name: e.label as string });
							}}
							value={selectedOrderStore ? { label: selectedOrderStore?.name as string, value: selectedOrderStore?.id as string } : undefined}
							options={orderStores.map((_store) => ({ label: _store.name, value: _store.id }))}
							className={styles.dropdownMinWidth}
						/>
					</div>

					<div className="flex-1">
						<h5 className="mb-2">Delivery Type Filter</h5>

						<PrDropdown
							clearable
							placeholder="Select Delivery Type"
							onClear={() => {
								setSelectedDeliveryType(null);
							}}
							onChange={(e) => {
								setSelectedDeliveryType(e.value as DeliveryTypes);
							}}
							value={selectedDeliveryType ? { label: DeliveryTypes[selectedDeliveryType], value: selectedDeliveryType } : undefined}
							options={enumToArrayHelper.convertArray(DeliveryTypes, true)}
							className={styles.dropdownMinWidth}
						/>
					</div>

					<div className="flex-1">
						<h5 className="mb-2">Service Label Filter</h5>

						<PrDropdown
							clearable
							filter
							placeholder="Select Service Label"
							onClear={() => {
								setSelectedServiceLabelName(null);
							}}
							onChange={(e) => {
								setSelectedServiceLabelName(e.value as string);
							}}
							value={selectedServiceLabelName ? { label: selectedServiceLabelName, value: selectedServiceLabelName } : undefined}
							options={serviceLabelNames.map((_serviceLabel) => ({ label: _serviceLabel, value: _serviceLabel }))}
							className={styles.dropdownMinWidth}
						/>
					</div>
				</div>

				<h5 className="mb-2">Date Filter</h5>
				<PrMultipleCalendar dates={lastRequest.dateFilter} onChange={(event) => getAll({ dateFilter: event })} />

				<div className="mt-4">
					<SearchInput onChange={(e) => setSearchFilter(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && getAll({})} filterBtn disabled={loading} placeholder="Search orders (order code, customer name, tracking number, etsy receipt no)" filterBtnOnClick={() => getAll({})} />
				</div>

				{filterOrderStatuType === OrderStatuTypes.PendingApproval ? <Message severity="info" text="Orders are updated every 2 hours. If you can't view your order, please try again later." className="w-full justify-content-start gap-3 font-bold px-4 mt-3" /> : null}
			</section>

			<section className="container-body p-0 z-0 relative">
				<div className={`px-4 sticky bg-white ${styles.ordersTableWrapper}`} style={{ top: '4rem', width: '100%', height: '100%', left: 0, zIndex: 999 }}>
					<div className={`${styles.ordersTableHeader}${selectedOrders.length > 0 ? ` ${styles.haveSelected}` : ''}`}>
						<Tooltip target="#selectedOrders" />

						<div className={styles.ordersTableHeaderSelectAll} style={{ visibility: orders.findIndex((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval) > -1 ? 'visible' : 'hidden' }}>
							<Checkbox inputId="selectAll" name="selectAll" checked={selectedOrders.length === orders.filter((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval).length && orders.filter((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval).length > 0} onChange={(event) => setSelectedOrders(event.checked ? orders.filter((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval) : [])} />

							<label htmlFor="selectAll" className="cursor-pointer ml-2 inline-flex flex-column">
								<span>Select All</span>

								<small id="selectedOrders" data-pr-tooltip={selectedOrders.map((_order) => _order.code).join('\n')} data-pr-position="top">
									<b>{selectedOrders.length}</b> Selected
								</small>
							</label>
						</div>

						<div className={styles.ordersTableHeaderTitles}>
							<div className={styles.ordersTableHeaderTitlesProduct}>
								<span>Store</span>
								{selectedOrders && selectedOrders.length > 0 ? <DtfDropdown orderIds={selectedOrders.map((_order) => _order.orderId)} setSendNewDtfVisible={setSendNewDtfVisible} setSendNewAutomatedDtfVisible={setSendNewAutomatedDtfVisible} setSendExistingDtfVisible={setSendExistingDtfVisible}  loading={loading} /> : null}
							</div>
							<span className={styles.ordersTableHeaderTitlesInventory}>Delivery</span>
							<span className={styles.ordersTableHeaderTitlesDeliveryOptions}>Customer</span>
							<span className={styles.ordersTableHeaderTitlesStatus}>
								<div className="flex justify-content-between align-items-center gap-2">
									<span>Status</span>

									<PrintDropdown loading={loading} setLoading={setLoading} orderIds={selectedOrders.map((_order) => _order.orderId)} />
								</div>
							</span>
						</div>
					</div>
				</div>

				<div className={styles.ordersTableBody}>
					{orders.length > 0 ? (
						orders.map((_order) => (
							<Link key={_order.orderId} to={`/order/${_order.orderId}`} className={`${styles.ordersTableBodyItemWrapper}${_order.isExternalOrder ? ` ${styles.external}` : ''}`} onClick={(e) => _order.isExternalOrder && e.preventDefault()}>
								<div className="px-4">
									<div className={styles.ordersTableBodyWrapper}>
										<div className={styles.ordersTableBodyItemCheck}>
											<Checkbox
												inputId={`selectItem${_order.orderId}`}
												name={`selectItem${_order.orderId}`}
												checked={!!selectedOrders.find((_sOrder) => _sOrder.orderId === _order.orderId)}
												style={{ visibility: _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval ? 'visible' : 'hidden' }}
												onChange={(event) =>
													setSelectedOrders((_current) => {
														let newCurrent = structuredClone(_current);

														if (event.checked) newCurrent.push(_order);
														else newCurrent = newCurrent.filter((_nCurrent: OrderForListNewDto) => _nCurrent.orderId !== _order.orderId);

														return newCurrent;
													})
												}
											/>

											<label htmlFor={`selectItem${_order.orderId}`} onClick={(e) => e.preventDefault()}>
												{!!_order.receiptId ? (
													<span
														className="cursor-pointer font-bold"
														onClick={() => {
															navigator.clipboard.writeText(_order.receiptId || '');
															toastHelper.success(`Copied '${_order.receiptId}' to clipboard`);
														}}>
														{_order.receiptId}
													</span>
												) : null}

												<span
													className="cursor-pointer"
													onClick={() => {
														navigator.clipboard.writeText(_order.code);
														toastHelper.success(`Copied '${_order.code}' to clipboard`);
													}}>
													{_order.code}
												</span>

												<span>{`${_order.orderItemCount > 0 ? _order.orderItemCount + ' products' : ''}`}</span>
											</label>
										</div>

										<div className={styles.ordersTableBodyItems}>
											<div className={styles.ordersTableBodyItemProduct}>
												<Tooltip target={`#adminStore${_order.code}`} />
												<h6
													onClick={(e) => {
														e.preventDefault();
														navigator.clipboard.writeText(_order.storeName);
														toastHelper.success(`Copied '${_order.storeName}' to clipboard`);
													}}
													id={`adminStore${_order.code}`}
													data-pr-tooltip={_order.storeName}
													data-pr-position="top">
													{_order.storeName}
												</h6>
												<span>
													{stringHelper.parseAtUpperCaseAndJoin(OrderStatuTypes[_order.currentOrderStatusType])} at &#x2022; {dateHelper.formatDate(_order.currentOrderStatusCreatedDate)}
												</span>
											</div>

											<div className={styles.ordersTableBodyItemInventory}>
												<Tooltip target={`#label${_order.orderId}`} />
												<span
													id={`label${_order.orderId}`}
													data-pr-tooltip={_order.labelServiceName}
													data-pr-position="top"
													className={`${styles.serviceLabelName}
													${_order.labelServiceName === 'Priority Mail Express' ? ` ${styles.express}` : ''}`}>
													{_order.labelServiceName}
												</span>

												<span className={styles.deliveryType}>{DeliveryTypes[_order.deliveryType || 0]}</span>
											</div>

											<div className={styles.ordersTableBodyItemDeliveryOptions}>
												<span>{_order.customerFullName || '-'}</span>
											</div>

											<div className={styles.ordersTableBodyItemStatus} onClick={(e) => e.preventDefault()}>
												<Dropdown
													options={_order.availableOrderStatus}
													optionValue="id"
													optionLabel="readableName"
													placeholder={OrderStatuTypes[_order.currentOrderStatusType]}
													onChange={(event) => {
														if (event.value === OrderStatuTypes.Canceled || event.value === OrderStatuTypes.UrgentOrder) {
															setStatuModalData({ orderId: _order.orderId, statu: event.value });
															setStatuModalVisible(true);
															return;
														}

														updateOrderStatus({ orderId: _order.orderId, orderStatuId: event.value });
													}}
													value={_order.currentOrderStatusType}
													className="w-full"
												/>
											</div>
										</div>
									</div>
								</div>
							</Link>
						))
					) : (
						<div className={`${styles.ordersTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any order yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && orders.length > 0 ? (
						<div className={`${styles.ordersTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={fromStatePagination.first}
								rows={fromStatePagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30, 40, 50, 100, 200, 500]}
								onPageChange={(event) => {
									if (fromStatePagination.pageNumber !== event.page + 1) setSelectedOrders([]);

									getAll({
										...lastRequest,
										pagination: {
											first: event.first,
											itemCount: event.rows,
											pageNumber: event.page + 1,
											orderBy: PaginationOrderByEnum.Descending
										}
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading || statuLoading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}

			<SendtoNewDtf visible={sendNewDtfVisible} setVisible={setSendNewDtfVisible} selectedOrders={selectedOrders} />
			<SendNewAutomatedDtf visible={sendNewAutomatedDtfVisible} setVisible={setSendNewAutomatedDtfVisible} selectedOrders={selectedOrders} />
			<SendtoExistingDtf visible={sendExistingDtfVisible} setVisible={setSendExistingDtfVisible} selectedOrders={selectedOrders} />
			<CreateOrderStatuHistoryModal data={statuModalData} visible={statuModalVisible} setVisible={setStatuModalVisible} callback={updateOrderStatus} />
		</div>
	);
};

export default Orders;
