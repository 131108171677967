export class AddRangeOrderstoDtfListRequest {
	dtfListId: string;
	orderIds: Array<string>;

	get getConvertUriExtension() {
		return 'add-range-orders-to-dtf-list';
	}

	constructor(props: Partial<AddRangeOrderstoDtfListRequest>) {
		this.dtfListId = props.dtfListId || '';
		this.orderIds = props.orderIds || [];
	}
}