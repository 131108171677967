import { Button } from 'primereact/button';
import DeleteConfirm from './DeleteConfirm';
import { useState } from 'react';
import userService from '../../../../../../services/user-service';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/hooks';
import { User } from '../../../../../../models/dtos/auth/users/user';
import { DeleteUserRequest } from '../../../../../../models/requests/users/delete-user-request';
import { logout } from '../../../../../../redux/features/auth/authSlice';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';

const DeleteUser = () => {
	const dispatch = useAppDispatch();
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [reason, setReason] = useState('');
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const deleteUser = () => {
		if (!userId) return;

		const deleteRequst: DeleteUserRequest = { userId, reason };
		userService.delete(deleteRequst).then((response) => (response ? dispatch(logout()) : {}));
	};
	return (
		<>
			<h5>My Personel Data</h5>

			<label>You have the right to access information we hold about you and the right to be “forgotten” by us.</label>

			<div className="mt-4">
				<div className="flex justify-content-end">
					<PrButton btnType="button" type="secondary" text="Delete My Data" onClick={() => setConfirmVisible(true)} className="px-8" />
				</div>
			</div>

			<DeleteConfirm isVisible={confirmVisible} setVisible={setConfirmVisible} deleteUser={deleteUser} reason={reason} setReason={setReason} />
		</>
	);
};

export default DeleteUser;
