export class UpdateStatuRequest {
	printCenterId: string = '';
	userStatuId: number = 0;

	get getConvertUriExtension() {
		return `update-statu`;
	}

	constructor(printCenterId: string, userStatuId: number) {
		this.printCenterId = printCenterId;
		this.userStatuId = userStatuId;
	}
}
