export class GetCreateReOrderDatasRequest {
	orderId: string;

	constructor(orderId: string) {
		this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return `get-create-reorder-datas/${this.orderId}`;
	}
}