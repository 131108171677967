import { InputNumber, InputNumberProps } from 'primereact/inputnumber';

interface PrPriceInputProps extends InputNumberProps {
	label: string;
	description: string;
	required: boolean;
	wrapperClassName: string;
}

const PrPriceInput = (props: Partial<PrPriceInputProps>) => {
	return (
		<div className={`pr-input-group w-full ${!!props.wrapperClassName ? props.wrapperClassName : ''}`}>
			<label htmlFor={props.id || ''} className="pr-input-label">
				<h5 className="pr-input-label-title">
					{props.label || ''} {typeof props.required !== 'undefined' ? !props.required ? <span className="pr-input-label-optional">(Optional)</span> : <span className="pr-input-label-required">*</span> : null}
				</h5>

				{props.description ? <p className="pr-input-label-description">{props.description}</p> : null}
			</label>

			<InputNumber {...props} className={`${!!props.className ? props.className : ''} pr-price-input-control`} mode="currency" currency="USD" locale="en-US" required={false} />
		</div>
	);
};

export default PrPriceInput;
