import PrButton from "../../../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton";
import {Integration} from "../../../../../../../models/dtos/store/integration";

type Props = {
    integration: Integration;
};

const IntegrationItem = ({integration}: Props) => {

    const highlights = [
        "Easy And Fast Setup",
        "Access To 96M+ Active Buyers",
        "Global Reach",
    ];

    return (
        <div className="shadow-1 bg-white w-full border-round-md">
            <div className="flex flex-column justify-content-center align-items-start w-full p-5">
                <div className="flex align-items-center justify-content-center gap-3">
                    <img
                        className="border-round-sm"
                        alt="marketplace-image"
                        src={integration.marketplaceImage}
                        height={30}
                        width={30}
                        loading="lazy"
                    />
                    <p className="text-lg font-medium">Etsy</p>
                </div>
                <p className="my-3 text-sm">
                    Etsy offers a fast and easy way to start selling, providing access to over
                    96 million active buyers worldwide. Its simple setup and low listing fees make it an ideal choice
                    for new merchants looking to reach a large, global audience.
                </p>
                <div className="mb-4">
                    <p className="mb-2 text-sm">Highlights:</p>
                    <ul className="list-none p-0 m-0">
                        {highlights.map((highlight, index) => (
                            <li key={index} className="flex align-items-center p-mb-2">
                                <i className="pi pi-check p-mr-2" style={{color: 'blue'}}></i>
                                <span style={{width: "1rem"}}></span>
                                <p className="text-sm">{highlight}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <PrButton
                    className="transition-linear border-round-md text-sm"
                    text="Connect to Etsy"
                    onClick={() => window.location.href = integration.connectionUrl}
                />
            </div>
        </div>
    );
};

export default IntegrationItem;
