import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetActiveEtsyAuthenticationsRequest {
	pagination: PaginationDto;
	contains?: string;

	constructor(pagination: PaginationDto, contains?: string) {
		this.pagination = pagination;
		if (contains) this.contains = contains;
	}

	get getConvertUriExtension() {
		return `get-active-etsy-authentications?${this.contains ? `contains=${this.contains}&` : ''}pagination.pageNumber=${this.pagination.pageNumber}&pagination.itemCount=${this.pagination.itemCount}&pagination.orderBy=${this.pagination.orderBy}`;
	}
}