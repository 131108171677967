import { Formik, Form } from 'formik';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import mediaHelper from '../../../../../../helpers/media.helper';
import { ProductVariationForUpdate } from '../../../../../../models/dtos/product/product-variation-for-update';
import { UpdateModel, UpdateProductVariantsRequest } from '../../../../../../models/requests/products/update-product-variants-request';
import productVariantsService from '../../../../../../services/products/product-variants-service';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { MeasurementType } from '../../../../../../models/dtos/dimesions/measurement-type';
import EditVariations from './modals/EditVariations';
import CreateEditVariations from '../../components/StepFour';
import { measurementHelper, measurementType } from 'helpers/measurement.helper';
import currencyHelper from 'helpers/curreny.helper';
import productVariantService from 'features/products/derived-features/product-variants/product-variant.service';
import { DeleteBulkVariantRequest } from 'features/products/derived-features/product-variants/delete-bulk-variant/delete-bulk-variant.request';

const StepThree = ({ setStep, step }: any) => {
	const { productId } = useParams();
	const [selectedVariations, setSelectedVariations] = useState<UpdateModel[]>([]);
	const [options, setOptions] = useState<ProductVariationForUpdate | null>();
	const [editVisible, setEditVisible] = useState<boolean>(false);
	const [variations, setVariations] = useState<UpdateModel[]>([]);
	const [editedVariations, setEditedVariations] = useState<UpdateModel[]>([]);
	const [measurementTypes, setMeasurementTypes] = useState<SelectItemOptionsType>();
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const initialValues: UpdateProductVariantsRequest = { updateModels: [] };

	const priceBodyTemplate = (rowData: UpdateModel) => currencyHelper.formatPrice(rowData.variantSpecificData.pricePerUnit / 100);

	const SKUBodyTemplate = (rowData: UpdateModel) => rowData.variantSpecificData.parentSKU + '-' + rowData.variantSpecificData.childSKU;

	const measurementBodyTemplate = (rowData: UpdateModel) =>
		rowData.variantComponents.dimensionComponents.map((dimension, index) => (
			<div key={index}>
				{dimension.measurementTypeName + ' : ' + dimension.value + ' ' + measurementHelper.getMeasurementUnit(dimension.measurementUnit, dimension.measurementTypeName as measurementType)}
				<br />
			</div>
		));

	const imageBodyTemplate = (rowData: UpdateModel) => {
		const files: File[] = rowData.variantSpecificData.imageFiles || [];

		return <>{files.length > 0 ? files.map((file: any, index: number) => <img key={index} loading="lazy" src={file.objectURL} style={{ width: 40, height: 40 }} />) : <img loading="lazy" src={mediaHelper.getImageOnMedia(options?.productVariant.variants.find((opt) => opt.productVariantId === rowData.productVariantId)?.media).url} style={{ width: 40, height: 40 }} />}</>;
	};

	const nameBodyTemplate = (rowData: UpdateModel) => rowData.variantComponents.colorComponent.colorName;
	const sizeBodyTemplate = (rowData: UpdateModel) => rowData.variantComponents.sizeName;

	const getProductVariations = () => {
		if (!productId) return;

		setLoading(true);
		productVariantsService
			.getAll(productId)
			.then((response) => {
				if (!response.isSuccess) return;

				setOptions(response.data);
				const updateModels: UpdateModel[] =
					response.data?.productVariant.variants.map((sel) => ({
						productVariantId: sel.productVariantId,
						isNewImage: false,
						isDisabled: sel.isDisabled,
						variantSpecificData: { pricePerUnit: sel.price.pricePerUnit, parentSKU: sel.parentSKU, childSKU: sel.childSKU, imageFiles: [] },
						variantComponents: {
							sizeName: sel.dimensions[0].size.name,
							colorComponent: { id: sel.color.id, colorName: sel.color.color.name },
							dimensionComponents: sel.dimensions.map((dim) => ({ id: dim.id, measurementTypeName: dim.measurementType.name, sizeName: dim.size.name, value: dim.value, measurementUnit: dim.measurementUnit }))
						}
					})) || [];

				setVariations(updateModels);

				const options = response.data?.measurementTypes.map((measurement: MeasurementType) => ({ label: measurement.name, value: measurement.name } as SelectItem));
				if (!options) return;

				setMeasurementTypes(options);
			})
			.finally(() => setLoading(false));
	};

	const onSubmit = () => {
		const req: UpdateProductVariantsRequest = { updateModels: variations.map((vari) => ({ ...vari, isNewImage: vari.variantSpecificData.imageFiles.length > 0 })) };

		setSubmitLoading(true);
		productVariantsService
			.update(req)
			.then((response) => {
				if (!response.isSuccess) return;

				getProductVariations();
			})
			.finally(() => setSubmitLoading(false));
	};

	const handleDeleteSelectedsVariants = async () => {
		try {
			setSubmitLoading(true);

			const identities = [...selectedVariations].map((_variant) => _variant.productVariantId);

			await productVariantService.deleteBulk(new DeleteBulkVariantRequest(identities));
			setSubmitLoading(false);
			setLoading(false);

			getProductVariations();
		} catch (error) {
		} finally {
			setSubmitLoading(false);
			setLoading(false);
		}
	};

	const handleDisableSelecteds = () => {
		const newVariantions = structuredClone(variations);

		for (const selectedVariant of selectedVariations) {
			for (const variant of newVariantions) {
				if (selectedVariant.productVariantId !== variant.productVariantId) continue;

				variant.isDisabled = !variant.isDisabled;
			}
		}

		setVariations(newVariantions);
		setSelectedVariations([]);
	};

	useEffect(() => {
		if (editedVariations?.length === 0) return;

		setEditVisible(true);
	}, [editedVariations]);

	useEffect(() => {
		getProductVariations();
	}, []);

	return (
		<div className="grid">
			<div className="col-12 lg:col-8">
				<CreateEditVariations callback={getProductVariations} />

				<hr />

				<div className="flex align-items-center justify-content-between mb-3">
					<h6 className="m-0">Update Product Variations</h6>

					<div className="flex gap-3">
						<Button type="button" icon="pi pi-check" label="Disable/Enable Selecteds" disabled={selectedVariations.length < 1} onClick={handleDisableSelecteds} className="p-button-outlined p-button-info px-4" />
						<Button type="button" icon="pi pi-cog" label="Edit Selecteds" disabled={selectedVariations.length < 1} onClick={() => setEditedVariations(selectedVariations)} className="p-button-outlined px-4" />
						<Button type="button" icon="pi pi-trash" label="Delete Selecteds" disabled={selectedVariations.length < 1} onClick={handleDeleteSelectedsVariants} className="p-button-outlined p-button-danger px-4" />
					</div>
				</div>

				<Formik initialValues={initialValues} onSubmit={onSubmit}>
					{() => (
						<Form>
							<DataTable value={variations} currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" paginator rowClassName={(e: UpdateModel) => ({ isDisable: e.isDisabled })} rows={10} rowsPerPageOptions={[5, 10, 15, 20]} selectionAriaLabel="productVariantId" selection={selectedVariations} selectionMode="checkbox" showGridlines onSelectionChange={(e) => setSelectedVariations(e.value)} dataKey="productVariantId" loading={loading || submitLoading} className="p-datatable-customers">
								<Column selectionMode="multiple" style={{ width: '1rem' }} />
								<Column field="variantComponents.colorComponent.colorName" header="Color" filter filterField="variantComponents.colorComponent.colorName" filterPlaceholder="Search by name" body={nameBodyTemplate} />
								<Column field="variantComponents.sizeName" header="Size" filter filterField="variantComponents.sizeName" filterPlaceholder="Search by name" body={sizeBodyTemplate} />
								<Column header="Price" body={priceBodyTemplate} />
								<Column header="SKU" filterPlaceholder="Search by SKU" body={SKUBodyTemplate} />
								<Column header="Measurements" body={measurementBodyTemplate} />
								<Column header="Image" body={imageBodyTemplate} style={{ width: '1rem' }} />
							</DataTable>

							<div className="col-12 sm:col-8 flex flex-wrap justify-content-end w-full p-0 mt-4">
								<Button type="submit" icon="pi pi-save" label="Save" loading={submitLoading} className="px-5" />
							</div>
						</Form>
					)}
				</Formik>
			</div>

			{editVisible ? <EditVariations visible={editVisible} setVisible={setEditVisible} measurementTypes={measurementTypes} editedVariations={editedVariations} setEditedVariations={setEditedVariations} setSelectedVariations={setSelectedVariations} variations={variations} setVariations={setVariations} /> : null}
		</div>
	);
};

export default StepThree;
