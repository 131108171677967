import React from 'react';

const BellaCanvasCareInstructions = () => {
	return (
		<React.Fragment>
			<div className="flex flex-wrap">
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/machine-wash-cold.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/do-not-bleach.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/do-not-tumble-dry.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/iron-steam-or-dry-low-heat.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/do-not-dry-clean.png" />
			</div>

			<p className="text-lg">Machine wash: cold (max 30C or 90F); Do not bleach; Do not tumble dry; Iron, steam or dry: low heat; Do not dry clean.</p>
		</React.Fragment>
	);
};

export default BellaCanvasCareInstructions;
