export enum SellerOptionProductPublishStatus {
    Unknown = 0,
    Draft = 1,
    Publish = 2
}

export interface SellerStoreOption {
    id: string;
    sellerId: string;
    acknowledgeTwoHours: boolean;
    sellerOptionProductPublishStatus: SellerOptionProductPublishStatus
}