
import balanceService from 'features/balances/_common/balance.service'
import { IncreaseWithChargeRequest } from 'features/balances/_common/increase-with-charge/increase-with-charge.request'
import { PaymentMethodDto } from 'features/stripe/_common/dtos/payment-method.dto'
import { GetOrCreateCustomerIdRequest } from 'features/stripe/_common/get-or-create-customer-id/get-or-create-customer-id.request'
import { GetPaymentMethodsRequest } from 'features/stripe/_common/get-payment-methods/get-payment-methods.request'
import { confirmDialog } from 'primereact/confirmdialog';
import stripeService from 'features/stripe/_common/stripe.service'
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton'
import { Dialog } from 'primereact/dialog'
import { Dropdown, DropdownProps } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { ProgressSpinner } from 'primereact/progressspinner'
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

type props = { isVisible: boolean, setVisible: Function, getUserBalance: Function, setActiveIndex: Function }
const AddBalance = ({ isVisible, setVisible, getUserBalance, setActiveIndex }: props) => {
	const userId = useAppSelector((state) => state.auth.data?.user.userId || '');

	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDto[]>([]);
	const [paymentOptions, setPaymentOptions] = useState<SelectItemOptionsType>();
	const [selectedMethod, setSelectedMethod] = useState<string>("");
	const [amount, setAmount] = useState<number>(25);
	const [loading, setLoading] = useState<boolean>(false);

	const getPaymentMethods = async() => {
		try {
			if (!userId) throw '';
			setLoading(true);

			const reqCusId = new GetOrCreateCustomerIdRequest(userId);
			const getOrCreateCustomerResponse = await stripeService.getOrCreateCustomerId(reqCusId);

			if (!getOrCreateCustomerResponse.isSuccess || !getOrCreateCustomerResponse.data) throw '';

			const reqPay = new GetPaymentMethodsRequest(userId);
			const getPaymentMethodsResponse = await stripeService.getPaymentMethods(reqPay);
			if (!getPaymentMethodsResponse.isSuccess || !getPaymentMethodsResponse.data) throw '';

			const creditCards = getPaymentMethodsResponse.data.data;

			if (creditCards.length === 0) {
				setVisible(false);
				showIsUserHavePaymentMethodNotExistsDialog();
				throw '';
			}

			setPaymentMethods(getPaymentMethodsResponse.data.data);
		} catch (error) {
			setPaymentMethods([])
		} finally {
			setLoading(false);
		}
	}

	const pay = async() => {
		try {
			if (!amount || !selectedMethod) throw '';

			setLoading(true)

			const request = new IncreaseWithChargeRequest(selectedMethod, Math.round(parseFloat(amount.toString()) * 100), userId);

			const response = await balanceService.increaseWithCharge(request)

			if (!response.isSuccess) throw '';

			getUserBalance();
			setSelectedMethod("");
			setAmount(25);
			setVisible(false);
		} catch(error) {}
		finally { setLoading(false) }
	}

	const buttonActiveHandler = () => {
		if (amount > 0 && selectedMethod) return false
		else return true
	}

	const showIsUserHavePaymentMethodNotExistsDialog = () => {
		confirmDialog({
			header: 'Payment Method Not Found',
			icon: 'pi pi-info-circle',
			message: 'You have not added any payment method, you cannot continue the transaction without adding a payment method.\nWould you like to add a payment method?',
			acceptLabel: 'Go Payment Methods Tab',
			rejectLabel: 'Stay This Tab',
			acceptClassName: 'px-4',
			rejectClassName: 'p-button-text px-4',
			accept: () => setActiveIndex(0)
		});
	};

	useEffect(() => {
		getPaymentMethods()
	}, [])

	useEffect(() => {
		if (paymentMethods.length === 0) return;

		setPaymentOptions(paymentMethods.map(pay => ({ value: pay.id, label: pay.card.brand.toLocaleUpperCase() + ' ' + pay.billing_details.name + ' ****' + pay.card.last4 + ' - ' + pay.card.exp_month + '/' + pay.card.exp_year } as SelectItem)))
		setSelectedMethod(paymentMethods[0].id)
	}, [paymentMethods])

  return (
		<Dialog header="Add Balance" visible={isVisible} onHide={() => setVisible(false)} style={{ maxWidth: '40rem' }}>
			<div className="relative" style={{ minHeight: '10rem' }}>
				<Dropdown options={paymentOptions} value={selectedMethod} onChange={(e) => setSelectedMethod(e.value)} className="w-full" />
				<div className="w-full text-right mt-1">
					<span onClick={() => setActiveIndex(0)} className="text-sm cursor-pointer">
						View Payment Methods
					</span>
				</div>
				<div className="p-inputgroup mt-4">
					<span className="p-inputgroup-addon cursor-pointer" onClick={(e) => setAmount(25)} style={{ backgroundColor: amount == 25 ? 'lightgray' : 'white' }}>
						$25
					</span>
					<span className="p-inputgroup-addon cursor-pointer" onClick={(e) => setAmount(50)} style={{ backgroundColor: amount == 50 ? 'lightgray' : 'white' }}>
						$50
					</span>
					<span className="p-inputgroup-addon cursor-pointer" onClick={(e) => setAmount(100)} style={{ backgroundColor: amount == 100 ? 'lightgray' : 'white' }}>
						$100
					</span>
					<InputNumber inputId="currency-us" value={amount} onChange={(event) => setAmount(event.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} />
					<PrButton text="Add Balance" icon={<span className="pi pi-fw pi-plus-circle" />} onClick={pay} disabled={buttonActiveHandler()} loading={loading} className="ml-2 h-3rem" />
				</div>

				<span className='text-sm text-center'>*Processing fee is paid by the user during the balance top up process.</span>

				{loading ? (
					<div className="pr-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}
			</div>
		</Dialog>
  );
}

export default AddBalance