import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dateHelper from 'helpers/dateHelper';
import DropdownButton from 'helpers/widgets/DropdownButton';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { DeleteMerchantProductRequest } from 'features/products/derived-features/merchant-products/delete-merchant-product/delete-merchant-product.request';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setForAdminProductPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { Pagination } from 'models/_commons/responses/pagination';
import { CoreProductForListDto } from 'features/products/_common/dtos/core-product-for-list.dto';
import { GetCoreProductsForListRequest } from 'features/products/_common/get-core-products-for-list/get-core-products-for-list.request';
import productService from 'features/products/_common/product.service';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ProductFilterTab from 'helpers/widgets/Order/OrderFilterTab/ProductFilterTab';
import { ProductStatu } from 'features/products/dtos/enums/product-statu.enum';
import stringHelper from 'helpers/string.helper';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import { ProgressSpinner } from 'primereact/progressspinner';
import { PiTShirtFill } from 'react-icons/pi';

const Products = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminProductPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();
	const searchStatus = searchParams.get('status');

	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<string | undefined>(undefined);
	const [products, setProducts] = useState<CoreProductForListDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [filterProductStatuType, setFilterProductStatuType] = useState<ProductStatu | null>(!!searchStatus ? Number(searchStatus) || 0 : 0);

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			const request = new GetCoreProductsForListRequest({ pagination, contains: !!filter && filter.length >= 3 ? filter : null, statu: filterProductStatuType === 0 ? null : filterProductStatuType });

			const response = await productService.getCoreProductsForList(request);

			if (!response.isSuccess || !response.data) throw '';

			const responseProducts = response.data;

			const mappedProducts = responseProducts?.map((product) => {
				product.createdDate = dateHelper.formatDate(product.createdDate);
				return product;
			});

			setPaginationResponse(response.pagination);
			setProducts(mappedProducts);
		} finally {
			setLoading(false);
		}
	};

	const deleteProduct = async (productId: string) => {
		try {
			const request = new DeleteMerchantProductRequest();
			request.productId = productId;

			const response = await merchantProductsService.delete(request);

			if (!response) throw '';

			getAll(fromStatePagination);
		} finally {
		}
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		const serializeData = JSON.stringify(newPagination);
		dispatch(setForAdminProductPaginationInfo(JSON.parse(serializeData)));
		getAll(newPagination);
	}, []);

	useEffect(() => {
		if (filterProductStatuType === 0) return;

		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = { ...fromStatePagination, pageNumber: 1, first: 0 };
		else {
			newPagination.first = 0;
			newPagination.pageNumber = 1;
			newPagination.itemCount = paginationResponse?.itemCountAtCurrentPage || 10;
		}

		const serializeData = JSON.stringify(newPagination);
		dispatch(setForAdminProductPaginationInfo(JSON.parse(serializeData)));
		getAll(newPagination);
	}, [filterProductStatuType]);

	const statusTemplate = (product: CoreProductForListDto) => ProductStatu[product.statu];

	const actionsTemplate = (product: CoreProductForListDto) => {
		const btnClassNames = 'flex align-items-center font-medium';

		const menuItems: JSX.Element[] = [
			<button disabled className={btnClassNames}>
				<span className="pi pi-fw pi-pencil h-full" /> <span className="ml-3 w-full h-full">Edit</span>
			</button>,
			<button onClick={() => navigate(product.id)} className={btnClassNames}>
				<span className="pi pi-fw pi-eye h-full" /> <span className="ml-3 w-full h-full white-space-nowrap">Show Detail</span>
			</button>,
			<button onClick={() => navigate(`sides/${product.id}`)} className={btnClassNames}>
				<PiTShirtFill size={20} /> <span className="ml-3 w-full h-full">Sides</span>
			</button>,
			<button onClick={() => deleteProduct(product.id)} className={`${btnClassNames} text-red`}>
				<span className="pi pi-fw pi-trash h-full" /> <span className="ml-3 w-full h-full">Delete</span>
			</button>
		];

		return (
			<div className="text-center">
				<DropdownButton menuItems={menuItems} icon="pi pi-fw pi-cog" className="bg-blue-600" />
			</div>
		);
	};

	const handleFilter = async () => {
		if (typeof filter === 'string' && filter.length === 0) {
			onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
			return;
		}

		if (!filter || filter.length < 3) return;

		onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
	};

	const onPageChangeEvent = (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;

			const serializeData = JSON.stringify(newPagination);
			dispatch(setForAdminProductPaginationInfo(JSON.parse(serializeData)));
		}

		getAll(newPagination);
	};

	const brandModelTemplate = (product: CoreProductForListDto) => `${product.brandName} / ${product.modelName}`;

	const setReadableName = (value: number) => {
		if (!value) return '';

		let newValue = stringHelper.parseAtUpperCaseAndJoin(ProductStatu[value]);

		newValue = newValue.split('Order').join('');

		return newValue;
	};

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">{!filterProductStatuType ? 'All' : setReadableName(filterProductStatuType)} Products</h1>
			</div>

			<div className="container-body p-3 z-1">
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the Product page from the options below.</p>
				<ProductFilterTab filterProductStatuType={filterProductStatuType} setFilterProductStatuType={setFilterProductStatuType} name="Products" />

				<div className="mt-4">
					<SearchInput onChange={(e) => setFilter(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && handleFilter()} filterBtn disabled={loading} placeholder="Search products" filterBtnOnClick={handleFilter} />
				</div>
			</div>

			<div className="container-body p-0">
				<DataTable value={products} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} first={fromStatePagination.first} className="p-datatable-gridlines">
					<Column field="productName" header="Product Name" />
					<Column header="Brand / Model" body={brandModelTemplate} />
					<Column field="categoryName" header="Category Name" />
					<Column field="merchantFullName" header="Merchant" />
					<Column field="createdDate" header="Created Date" dataType="date" />
					<Column field="productStatuId" header="Status" dataType="numeric" body={statusTemplate} style={{ width: '0' }} />
					<Column field="actions" header="Actions" body={actionsTemplate} style={{ width: '0' }} />
				</DataTable>
			</div>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Products;
