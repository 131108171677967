import HttpBaseService from 'services/_common/http-base-service';
import { AssignOrdersToPrintCentersResponse } from './assign-orders-to-print-centers/assign-orders-to-print-centers.response';
import { AssignOrdersToPrintCentersRequest } from './assign-orders-to-print-centers/assign-orders-to-print-centers.request';
import { DeleteAssignRequest } from './delete-assign/delete-assign.request';
import { DeleteAssignResponse } from './delete-assign/delete-assign.response';
import { GetAssignTypeByOrderRequest } from './get-assign-type-by-order/get-assign-type-by-order.request';
import { GetAssignTypeByOrderResponse } from './get-assign-type-by-order/get-assign-type-by-order.response';

class AssignedOrdersToPrintCenterService extends HttpBaseService {
	constructor() {
		super('assigned-orders-to-print-centers');
	}

	async assignOrdersToPrintCenters(request: AssignOrdersToPrintCentersRequest): Promise<AssignOrdersToPrintCentersResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getAssignTypeByOrder(request: GetAssignTypeByOrderRequest): Promise<GetAssignTypeByOrderResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async deleteAssign(request: DeleteAssignRequest): Promise<DeleteAssignResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new AssignedOrdersToPrintCenterService();