import styles from '../../../orders/create-order/steps/review-order/StepReviewOrder.module.scss';
import React from 'react';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../context/CustomDtfContextProvider';
import CustomDtfProductsTable from './Components/CustomDtfProductsTable/CustomDtfProductsTable';
import CustomDtfShippingInfo from '../ShipSummary/Components/CustomDtfShippingInfo';
import { CustomDtfPayments } from './Components/CustomDtPayments/CustomDtfPayments';
import { CustomDtfCostBreakdown } from '../ShipSummary/Components/CustomDtfCostBreakdown';
import { BsFillShieldLockFill } from 'react-icons/bs';
import PrButton from '../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { CreateCustomDtfOrderRequest } from '../../../../../features/customDtf/create-custom-dtf-order/create-custom-dtf-order.request';
import { useAppSelector } from '../../../../../redux/hooks';
import customDtfService from '../../../../../features/customDtf/custom-dtf.service';
import { confirmDialog } from 'primereact/confirmdialog';
import { BiError } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const CustomDtfCheckout = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;
	const user = useAppSelector((state) => state.auth.data?.user);

	const navigate = useNavigate();

	const showFailDialog = (error: any) => {
		confirmDialog({
			header: 'Order can not be created.',
			icon: <BiError color="red" size={50} />,
			message: error?.response?.data?.message || '',
			acceptLabel: 'Ok',
			acceptClassName: 'px-4 bg-blue-600',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: () => {}
		});
	};

	const completeOrder = async () => {
		try {
			context.setLoading(true);
			const request: CreateCustomDtfOrderRequest = new CreateCustomDtfOrderRequest({
				userId: user!.userId,
				storeId: context.storeId,
				productVariantId: context.variant?.productVariantId,
				deliveryType: context.deliveryType,
				paymentMethodType: context.paymentMethodType,
				files: context.items,
				deliveryAddress: context.deliveryAddress,
				shippingRate: context.selectedRate,
				note: context.note,
				attachmentFiles: context.attachmentFiles,
			});

			const response = await customDtfService.createCustomDtfOrder(request);

			if (!response.isSuccess) throw "";

			navigate('/custom-dtf/orders');
		} catch (error) {
			showFailDialog(error);
		} finally {
			context.setLoading(false);
		}
	};

	return (
		<div className="relative">
			<div className="container-body p-4">
				<CustomDtfProductsTable />
			</div>

			<div className="container-body p-4 mt-5">
				<CustomDtfShippingInfo />
			</div>

			<div className="container-body p-4 mt-5">
				<div className="tutorial-selector1">
					<CustomDtfPayments />
				</div>
			</div>

			{context.calculatedPrice && (
				<div className="container-body p-4 mt-5">
					<CustomDtfCostBreakdown />

					<div className={styles.payButton}>
						<PrButton text="Pay securely now" onClick={() => completeOrder()} icon={<BsFillShieldLockFill />} btnType="button" />
						<div className={styles.payButtonDescription}>
							<div>By completing this order, </div>
							<div>I agree to Printram's Terms of Service.</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CustomDtfCheckout;
