import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetPlansForListRequest {
	pagination: PaginationDto;

	constructor(pagination?: PaginationDto) {
		this.pagination = pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-plans-for-list`;
	}
}