export class ParseAddressRequest {
	address: string;

	constructor(props: Partial<ParseAddressRequest>) {
		this.address = props.address || ""
	}

	get getConvertUriExtension() {
		return `parse-address?address=${this.address}`;
	}
}