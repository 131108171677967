import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto"

export class GetProductVariantsAndStocksRequest {
	pagination: PaginationDto = new PaginationDto();
	productId: string;
	colorId: string | null;
	sizeId: string | null;
	stockLocationId: string | null;

	constructor(props: Partial<GetProductVariantsAndStocksRequest>) {
		this.pagination = props.pagination || new PaginationDto();
		this.productId = props.productId || '';
		this.colorId = props.colorId || null;
		this.sizeId = props.sizeId || null;
		this.stockLocationId = props.stockLocationId || null;
	}

	get getConvertUriExtension() {
		return 'get-product-variants-and-stocks';
	}
}