import { ErrorMessage, FieldArray, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { UpdateProductDetailAndMaterialsRequest } from '../../../../../../models/requests/products/update-product-detail-and-materials-request';
import * as Yup from 'yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import productDetailAndMaterialsService from '../../../../../../services/products/product-detail-and-materials-service';
import { useParams } from 'react-router-dom';
import { ProductDetailAndMaterials } from '../../../../../../models/dtos/product/product-detail-and-materials';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';

const StepTwo = ({ setStep, step }: any) => {
	const { productId } = useParams();
	const formikRef = useRef<FormikProps<UpdateProductDetailAndMaterialsRequest>>(null);
	const [product, setProduct] = useState<ProductDetailAndMaterials | null>();
	const [deletedDetails, setDeletedDetails] = useState<Array<string>>([]);
	const [deletedMaterials, setDeletedMaterials] = useState<Array<string>>([]);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const initialValues: UpdateProductDetailAndMaterialsRequest = { updateProductDetails: { productId: '', updateModels: [] }, updateProductMaterials: { productId: '', materialAndValues: [] } };
	const validationSchema = Yup.object().shape({
		updateProductMaterials: Yup.object().shape({
			materialAndValues: Yup.array().of(Yup.object().shape({ name: Yup.string().required('Material name is required').min(3, 'Materials must be at least 3 characters.'), ratioPercentage: Yup.number().required('Percantage is must be more than 0').moreThan(0, 'Percantage is must be more than 0') }))
		}),
		updateProductDetails: Yup.object().shape({ updateModels: Yup.array().of(Yup.object().shape({ detail: Yup.string().required('Detail is Required').min(6, 'Detail must be at least 6 characters.').max(200, 'Detail must be max 200 characters.') })) })
	});

	const getProductDetailAndMaterials = useCallback(() => {
		if (!productId) return;

		setLoading(true);
		productDetailAndMaterialsService
			.get(productId)
			.then((response) => {
				if (!response.isSuccess) return;

				setProduct(response.data);
			})
			.finally(() => setLoading(false));
	}, [productId]);

	const setFormikInitials = (product: ProductDetailAndMaterials) => {
		if (!formikRef?.current) return;

		let materials = { productId: product.productId, materialAndValues: product.productMaterials.map((mat) => ({ id: mat.id, name: mat.material.name, ratioPercentage: mat.ratioPercentage })) };

		formikRef.current?.setFieldValue('updateProductMaterials', materials);
		formikRef.current?.setFieldValue('updateProductDetails.updateModels', product.productDetails);
	};

	const onSubmit = (values: UpdateProductDetailAndMaterialsRequest) => {
		if (!productId) return;

		setSubmitLoading(true);
		const newDetails = values.updateProductDetails.updateModels.filter((detail) => !detail.id && { detail }).map((val) => val.detail).length > 0 ? values.updateProductDetails.updateModels.filter((detail) => !detail.id && { detail }).map((val) => val.detail) : null;
		const filterDetails = values.updateProductDetails.updateModels.filter((detail) => {
			if (detail.id) return { ...detail, productId: productId };
		});

		const newMaterials = values.updateProductMaterials.materialAndValues.filter((mat) => !mat.id).length > 0 ? values.updateProductMaterials.materialAndValues.filter((mat) => !mat.id) : null;
		const filterMaterials = values.updateProductMaterials.materialAndValues.filter((material) => {
			if (material.id) return material;
		});

		const detailCreateModel = newDetails ? { productId: productId, details: newDetails } : null;
		const detailDeleteIdentities = deletedDetails.length > 0 ? deletedDetails : null;
		const materialDeleteIdentities = deletedMaterials.length > 0 ? deletedMaterials : null;

		const req: UpdateProductDetailAndMaterialsRequest = { ...values, updateProductDetails: { productId: productId, updateModels: filterDetails, createModel: detailCreateModel, deleteIdentities: detailDeleteIdentities }, updateProductMaterials: { materialAndValues: filterMaterials, createMaterialAndValues: newMaterials, deleteIdentities: materialDeleteIdentities, productId: productId } };

		productDetailAndMaterialsService
			.update(req)
			.then((response) => {
				if (!response.isSuccess) return;

				getProductDetailAndMaterials();
				setDeletedDetails([]);
				setDeletedMaterials([]);
			})
			.finally(() => setSubmitLoading(false));
	};

	const getCountOfDetails = (index: number) => (index += 1);

	const materialDeleteHandler = (index: number, length: number) => {
		if (index !== 0) return false;
		if (length > 1) return false;
		return true;
	};

	useEffect(() => {
		getProductDetailAndMaterials();
	}, [getProductDetailAndMaterials]);

	useEffect(() => {
		if (!product) return;

		setFormikInitials(product);
	}, [product]);

	return (
		<div className="grid">
			{!loading ? (
				<div className="col-12 sm:col-8">
					<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
						{({ values, handleChange, setFieldValue }) => (
							<Form>
								<FieldArray name="updateProductDetails.updateModels">
									{({ remove, push }) => (
										<React.Fragment>
											<div className="flex align-items-center justify-content-between pl-1">
												<h6 className="line-height-1 m-0">Product Details</h6>
												<Button onClick={() => push('')} type="button" icon="pi pi-plus-circle" label="Add New Detail" className="p-button-outlined p-button-sm" />
											</div>

											{values.updateProductDetails.updateModels.length > 0 &&
												values.updateProductDetails.updateModels.map((detail, index) => (
													<div key={index} className="mt-3">
														<div className="w-full">
															<div className="mb-1 px-1 flex align-items-center justify-content-between">
																<label className="font-medium block">{getCountOfDetails(index)} Detail</label>
																<ErrorMessage name={`updateProductDetails.updateModels.${index}.detail`} component="small" className="text-red font-medium" />
															</div>
														</div>

														<div className="flex gap-3 align-items-end">
															<InputText name={`updateProductDetails.updateModels.${index}.detail`} value={detail.detail} onChange={handleChange} placeholder="Enter product detail" className="w-full" />
															{index !== 0 && (
																<Button
																	onClick={() => {
																		remove(index);
																		detail.id && setDeletedDetails([...deletedDetails, detail.id]);
																	}}
																	type="button"
																	icon="pi pi-trash"
																	className="p-button-outlined p-button-danger"
																/>
															)}
														</div>
													</div>
												))}
										</React.Fragment>
									)}
								</FieldArray>

								<hr className="mt-5 mb-3" />

								<FieldArray name="updateProductMaterials.materialAndValues">
									{({ remove, push }) => (
										<React.Fragment>
											<div className="flex align-items-center justify-content-between pl-1">
												<h6 className="line-height-1 m-0">Product Materials</h6>
												<Button onClick={() => push('')} type="button" icon="pi pi-plus-circle" label="Add New Material" className="p-button-outlined p-button-sm" />
											</div>
											{values.updateProductMaterials.materialAndValues.length > 0 &&
												values.updateProductMaterials.materialAndValues.map((material, index) => (
													<div key={index} className="mt-3">
														<div className="w-full">
															<div className="mb-1 px-1 flex align-items-center justify-content-between">
																<label className="font-medium block">Material {getCountOfDetails(index)}</label>
																<ErrorMessage name={`updateProductMaterials.materialAndValues.${index}.name`} component="small" className="text-red font-medium" />
																<ErrorMessage name={`updateProductMaterials.materialAndValues.${index}.ratioPercentage`} component="small" className="text-red font-medium" />
															</div>
														</div>
														<div className="flex gap-3 align-items-end">
															<InputText name={`updateProductMaterials.materialAndValues.${index}.name`} value={material.name} onChange={handleChange} placeholder="Enter material name" className="w-full" />
															<span className="p-input-icon-right">
																<i className="pi pi-percentage" />
																<InputText name={`updateProductMaterials.materialAndValues.${index}.ratioPercentage`} value={material.ratioPercentage || 0} onChange={(e) => setFieldValue(`updateProductMaterials.materialAndValues[${index}].ratioPercentage`, parseFloat(e.target.value) || 0)} placeholder="0" className="w-full" />
															</span>
															<Button
																onClick={() => {
																	remove(index);
																	material.id && setDeletedMaterials([...deletedMaterials, material.id]);
																}}
																type="button"
																icon="pi pi-trash"
																disabled={materialDeleteHandler(index, values.updateProductMaterials.materialAndValues.length)}
																className="p-button-outlined p-button-danger"
															/>
														</div>
													</div>
												))}
										</React.Fragment>
									)}
								</FieldArray>

								<div className="mt-3 w-full text-center">
									<span className="text-500 font-small">The total percentage of products entered as a percentage must be %100</span>
								</div>

								<div className="col-12 sm:col-8 flex flex-wrap justify-content-end w-full p-0 mt-4">
									<Button type="submit" icon="pi pi-save" label="Save" loading={submitLoading} className="px-5" />
								</div>
							</Form>
						)}
					</Formik>
				</div>
			) : (
				<div className="col-12 lg:col-8">
					<div className="grid mb-2">
						<div className="col-12">
							<Skeleton height="5rem" />
						</div>
					</div>

					<div className="grid mb-2">
						<div className="col-12">
							<Skeleton height="5rem" />
						</div>
					</div>

					<div className="grid mb-2">
						<div className="col-12">
							<Skeleton height="6rem" />
						</div>
					</div>

					<div className="grid">
						<div className="col-12">
							<Skeleton height="6rem" />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default StepTwo;
