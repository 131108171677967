import { AxiosError } from 'axios';
import { MessageType } from '../models/_commons/enums/message-type';
import { ResponseBase } from '../models/_commons/responses/response-base';
import { ValidationErrorResponse } from '../models/_commons/responses/validation-error-response';
import environment from './constants/environment';
import toastHelper from './toast.helper';

const handleMessage = (type: MessageType, message: string) => {
	switch (type) {
		case MessageType.Error:
			toastHelper.error(message);
			break;
		case MessageType.Info:
			toastHelper.info(message);
			break;
		case MessageType.Success:
			toastHelper.success(message);
			break;
		case MessageType.Warning:
			toastHelper.warning(message);
			break;
	}
};

const handleResponseError = (error: AxiosError) => {
	if (!!error.response?.data) {
		const response: ValidationErrorResponse = error.response.data;

		if (!!response.validationErrors) response.validationErrors.forEach((_error) => toastHelper.warning(`${_error.key} ${_error.value}`));
		else handleMessage(response.messageType!, response.message!);
	} else if (environment.mode !== 'production') {
		toastHelper.warning(error.message || error.toString());
	} else toastHelper.error('Internal Server Error');
};

const handleMessageHelper = { handleMessage, handleResponseError };

export default handleMessageHelper;
