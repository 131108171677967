import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { UpdateSellerWithUserRequest } from 'features/sellers/_common/update-seller-with-user/update-seller-with-user.request';
import sellerService from 'features/sellers/_common/seller.service';
import TextInput from 'helpers/widgets/Forms/TextInput';
import DropdownInput from 'helpers/widgets/Forms/DropdownInput';
import { StatusOptions } from 'helpers/constants/user-status-options';
import { Checkbox } from 'primereact/checkbox';
import { UserUpdateModelDto } from 'features/sellers/_common/update-seller-with-user/dtos/user-update-model.dto';
import { useEffect, useRef, useState } from 'react';
import TextMaskInput from 'helpers/widgets/Forms/TextMaskInput';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { CreateOrUpdateCorporateModelDto } from 'features/sellers/_common/update-seller-with-user/dtos/create-or-update-corporate-model.dto';
import { GetByIdRequest } from 'features/users/_common/get-by-id/get-by-id.request';
import userService from 'features/users/_common/user.service';
import { UserBaseDto } from 'features/_common/dtos/user/user.base.dto';
import { Skeleton } from 'primereact/skeleton';
import { useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getSellers: Function;
	editedSellerId: string;
	setEditedSeller: Function;
};

const SellerUpdate = ({ isVisible, setVisible, getSellers, editedSellerId, setEditedSeller }: Props) => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminSellerListPaginationInfo as PaginationDto);
	const formikRef = useRef<FormikProps<UpdateSellerWithUserRequest>>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const [seller, setSeller] = useState<UserBaseDto | null>();
	const [prefixOptions, setPrefixOptions] = useState<SelectItemOptionsType>([
		{ label: '+1', value: '1' },
		{ label: '+90', value: '90' }
	]);

	const initialValues = new UpdateSellerWithUserRequest({ id: seller?.userId || '', userStatuId: seller?.statu || 0 }, { id: seller?.userId || '', email: seller?.email || '', name: seller?.personInfo.name || '', surname: seller?.personInfo.surname || '', isForeignNation: seller?.isForeignNation, ssnNumber: seller?.ssnNumber || '', phoneNumbers: seller?.phoneNumbers?.map((phone) => ({ id: phone.id || null, isDefault: true, code: phone.code || '', number: phone.number || '' })) || [] }, { userId: seller?.userId || '', businessName: seller?.corporateInfo?.businessName || '', feinNumber: seller?.corporateInfo?.feinNumber || '' });

	const validationSchema = Yup.object().shape({
		sellerUpdateModel: Yup.object().shape({ id: Yup.string().required(), userStatuId: Yup.number().required('User statu is required') }),
		userUpdateModel: Yup.object().shape({ id: Yup.string().required(), email: Yup.string().required('Email is required'), name: Yup.string().required('Name is required'), surname: Yup.string().required('Surname is required') }),
		createOrUpdateCorporateModel: Yup.object().shape({ businessName: Yup.string().required('Business name is required') })
	});

	const getSellersWithPagination = () => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		getSellers(newPagination);
	};

	const getById = async () => {
		try {
			setLoading(true);

			const response = await userService.getById(new GetByIdRequest(editedSellerId));

			if (!response.isSuccess) throw '';

			setSeller(response.data);
		} finally {
			setLoading(false);
		}
	};

	const submitForm = async (values: UpdateSellerWithUserRequest) => {
		try {
			setSubmitLoading(true);

			if (values.createOrUpdateCorporateModel?.feinNumber === '') values.createOrUpdateCorporateModel.feinNumber = null;
			if (values.userUpdateModel?.ssnNumber === '') values.userUpdateModel.ssnNumber = null;
			if (values.createOrUpdateCorporateModel?.feinNumber) values.createOrUpdateCorporateModel.feinNumber = values.createOrUpdateCorporateModel.feinNumber.replace(/-/g, '');
			if (values.userUpdateModel.ssnNumber) values.userUpdateModel.ssnNumber = values.userUpdateModel.ssnNumber.replace(/-/g, '');

			const corporateCreteOrUpdateModel: CreateOrUpdateCorporateModelDto = { ...values.createOrUpdateCorporateModel, userId: values.createOrUpdateCorporateModel?.userId || '', businessName: values.createOrUpdateCorporateModel?.businessName || '', feinNumber: values.createOrUpdateCorporateModel?.feinNumber ? values.createOrUpdateCorporateModel?.feinNumber : null };

			const userUpdateModel: UserUpdateModelDto = { ...values.userUpdateModel, phoneNumbers: values.userUpdateModel.phoneNumbers.map((phone) => ({ id: phone?.id || null, isDefault: true, code: phone.code, number: phone.number })) };

			const request = new UpdateSellerWithUserRequest(values.sellerUpdateModel, userUpdateModel, corporateCreteOrUpdateModel);

			const response = await sellerService.updateSellerWithUser(request);

			if (!response.isSuccess) throw '';

			setEditedSeller(undefined);
			setVisible(false);
			getSellersWithPagination();
		} finally {
			setSubmitLoading(false);
		}
	};

	useEffect(() => {
		if (!seller) return;

		formikRef.current?.setFieldValue('sellerUpdateModel', { id: seller?.userId || '', userStatuId: seller?.statu || 0 });
		formikRef.current?.setFieldValue('userUpdateModel', { id: seller?.userId || '', email: seller?.email || '', name: seller?.personInfo.name || '', surname: seller?.personInfo.surname || '', isForeignNation: seller?.isForeignNation, ssnNumber: seller?.ssnNumber, phoneNumbers: seller?.phoneNumbers?.map((phone) => ({ id: phone.id || null, isDefault: true, code: phone.code || '', number: phone.number || '' })) || [] });
		formikRef.current?.setFieldValue('createOrUpdateCorporateModel', { userId: seller?.userId || '', businessName: seller?.corporateInfo?.businessName || '', feinNumber: seller?.corporateInfo?.feinNumber || '' });
	}, [seller]);

	useEffect(() => {
		if (!editedSellerId) return;

		getById();
	}, [editedSellerId]);

	return (
		<Dialog
			visible={isVisible}
			header="Update Seller"
			modal
			onHide={() => {
				setEditedSeller(undefined);
				setVisible(false);
			}}>
			{!loading ? (
				<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={submitForm} validationSchema={validationSchema}>
					{({ values, handleChange }) => (
						<Form>
							<div className="grid">
								<div className="col-12 sm:col-6">
									<TextInput label="Business Name" name="createOrUpdateCorporateModel.businessName" value={values.createOrUpdateCorporateModel?.businessName} onChange={handleChange} placeholder="Enter business name" />
								</div>
								<div className="col-12 sm:col-6">
									<TextMaskInput label="FEIN Number" mask="99-9999999" name="createOrUpdateCorporateModel.feinNumber" value={values.createOrUpdateCorporateModel?.feinNumber || ''} onChange={handleChange} placeholder="Enter FEIN number" />
								</div>
								<div className="col-12 sm:col-6">
									<TextInput label="Seller Name" name="userUpdateModel.name" value={values.userUpdateModel.name} onChange={handleChange} placeholder="Enter seller name" />
								</div>
								<div className="col-12 sm:col-6">
									<TextInput label="Surname" name="userUpdateModel.surname" value={values.userUpdateModel.surname} onChange={handleChange} placeholder="Enter seller surname" />
								</div>
								<div className="col-12 sm:col-6">
									<TextInput label="Email" name="userUpdateModel.email" value={values.userUpdateModel.email} onChange={handleChange} placeholder="Enter seller email" />
								</div>
								<div className="col-12 sm:col-6">
									<div className="flex gap-2">
										<div className="w-3">
											<DropdownInput label="Code" name="userUpdateModel.phoneNumbers[0].code" options={prefixOptions} value={values.userUpdateModel.phoneNumbers[0]?.code} onChange={handleChange} placeholder="Code" />
										</div>
										<div className="w-9">
											<TextMaskInput label="Phone" name="userUpdateModel.phoneNumbers[0].number" unmask={true} mask="(999) 999-9999" value={values.userUpdateModel.phoneNumbers[0]?.number} placeholder="Enterseller phone number" onChange={handleChange} />
										</div>
									</div>
								</div>
								<div className="col-12 sm:col-6">
									<TextMaskInput label="SSN Number" mask="999-99-9999" name="userUpdateModel.ssnNumber" value={values.userUpdateModel?.ssnNumber || ''} onChange={handleChange} placeholder="Enter ssn number" />
								</div>
								<div className="col-12 sm:col-6">
									<DropdownInput label="User Status" name="sellerUpdateModel.userStatuId" options={StatusOptions} value={values.sellerUpdateModel.userStatuId} onChange={handleChange} placeholder="Select a statu" />
								</div>
								<div className="col-12 mt-4">
									<div className="flex justify-content-between">
										<div>
											<Checkbox name="userUpdateModel.isForeignNation" checked={values.userUpdateModel.isForeignNation} onChange={handleChange} />
											<span className="ml-1">Foreign Nation</span>
										</div>

										<Button type="submit" label="Update Seller" loading={submitLoading} className="h-full px-6" />
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			) : (
				<div className="grid">
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
				</div>
			)}
		</Dialog>
	);
};

export default SellerUpdate;
