import React from 'react'
import styles from './Steps.module.scss'
import { CreateOrderContext, CreateOrderContextType, orderSteps } from '../context/CreateOrderContextPrvider';

export const Steps = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

  return (
		<div className={styles.coStepWrapper}>
			{orderSteps.map((_step, index) => (
				<div
					key={index}
					className={`${styles.coStepItem}${_step.value === context.orderStep.value ? ` ${styles.active}` : ''}${context.orderStep.value > _step.value ? ` ${styles.completed}` : ''}`}
					onClick={() => {
						if (!_step.isCompleted) return;

						context.setOrderStep(_step);
					}}>
					<span className={styles.coStepNumber}>{context.orderStep.value > _step.value ? <span className="pi pi-check"></span> : _step.value}</span>
					<span className={styles.coStepName}>{_step.label}</span>
				</div>
			))}

			<div className={styles.coStepDivider} />
		</div>
  );
}
