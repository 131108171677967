import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetAssignedStoresToPrintCenterRequest {
	printCenterId: string;
	contains: string | null;
	pagination: PaginationDto;

	constructor(props: Partial<GetAssignedStoresToPrintCenterRequest>) {
		this.printCenterId = props.printCenterId || '';
		this.contains = props.contains || null;
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-assigned-stores-to-print-center`;
	}
}
