import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import React, { useEffect, useState } from 'react';
import styles from './ProductUpdateArea.module.scss';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import { variantHelper } from 'helpers/variant.helper';
import { Size } from 'features/products/_common/get-core-product/get-core-product.response';
import { ProductPrintSide } from 'features/products/derived-features/product-print-sides/get-product-print-sides/get-product-print-sides.response';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { IoIosArrowBack } from 'react-icons/io';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { SidePrintInfo } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { FaCog } from 'react-icons/fa';
import { userPrintSideDimensionService } from 'features/users/derived_features/user-print-side-dimensions/user-print-side-dimension.service';
import { CreateOrUpdateUserPrintSideDimensionRequest } from 'features/users/derived_features/user-print-side-dimensions/create-or-update-user-print-side-dimension/create-or-update-user-print-side-dimension.request';
import { useAppSelector } from 'redux/hooks';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';

type Props = {
	product: CoreProductSynchronizeDto;
	setProduct: React.Dispatch<React.SetStateAction<CoreProductSynchronizeDto | null | undefined>>;
};

export const ProductUpdateArea = (props: Props) => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const [loading, setLoading] = useState<boolean>(false);
	const [isShowMessage, setIsShowMessage] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [selectedSize, setSelectedSize] = useState<Size | undefined>(undefined);
	const [selectedSide, setSelectedSide] = useState<ProductPrintSide | undefined>(undefined);
	const [sidesPrintInfo, setSidesPrintInfo] = useState<SidePrintInfo[]>([]);

	useEffect(() => {
		setSelectedSize(variantHelper.getSizes(props.product.variants)[0]);
		setSelectedSide(props.product.sides[0]);
	}, [props.product.id]);

	useEffect(() => {
		if (!selectedSize || !selectedSide) return;

		const dimension = selectedSide.printDimensions.find((_dimension) => _dimension.size.id === selectedSize.id);
		const userPrintOptions = selectedSide.userPrintDimensions.find((_dimension) => _dimension.size.id === selectedSize.id);

		if (!dimension) return;

		const printInfos: SidePrintInfo[] = [...sidesPrintInfo];

		const index = printInfos.findIndex((_info) => (_info.sizeId === userPrintOptions?.sizeId || _info.sizeId === dimension.size.id) && _info.sideId === selectedSide.id);

		if (index > -1) return;

		printInfos.push({
			sizeId: dimension.size.id,
			sideId: selectedSide.id,
			printHeight: userPrintOptions?.defaultPrintSizeHeight || dimension.defaultPrintSizeHeight,
			printWidth: userPrintOptions?.defaultPrintSizeWidth || dimension.defaultPrintSizeWidth,
			minPrintWidth: dimension.minPrintSizeWidth,
			minPrintHeight: dimension.minPrintSizeHeight,
			maxPrintWidth: dimension.maxPrintSizeWidth,
			maxPrintHeight: dimension.maxPrintSizeHeight
		});

		setSidesPrintInfo(printInfos);
	}, [selectedSize, selectedSide?.id]);

	useEffect(() => {
		if (!isShowMessage) return;

		const timer = setTimeout(() => {
			setIsShowMessage(false);
		}, 3500);

		return () => {
			clearTimeout(timer);
		};
	}, [isShowMessage]);

	return (
		<React.Fragment>
			<div className={styles.product}>
				<div className={styles.imgDivider}>
					<img src={amazonS3Helper.showFile(props.product.variants[0].medias[0], { resize: '100x100' })} alt={props.product.productName} />
				</div>

				<div className={styles.productItemInfo}>
					<span>{props.product.categoryName}</span>

					<span className="mt-1">
						{props.product.productName} - {props.product.model.brand.name} {props.product.model.name}
					</span>
				</div>

				<div className={styles.sizes}>
					<span>Select Size</span>

					<div className={styles.sizeItems}>
						{variantHelper.getSizes(props.product.variants).map((_size, index) => (
							<button
								key={index}
								type="button"
								className={_size.id === selectedSize?.id ? styles.active : ''}
								onClick={() => {
									setSelectedSize(_size);
								}}>
								{_size.shortName}
							</button>
						))}
					</div>
				</div>
			</div>

			<div className={styles.sideTabWrapper}>
				{props.product.sides.map((_side, index) => (
					<div key={index} role="button" tabIndex={0} onClick={() => setSelectedSide(_side)} className={`${styles.sideTabItem}${selectedSide?.id === _side.id ? ` ${styles.selected}` : ''}`}>
						{_side.name}
					</div>
				))}
			</div>

			<div className={styles.sizeInputContainer}>
				<span>{selectedSide?.name} Size (Standart)</span>

				<div className={styles.inputWrapper}>
					<div className={styles.inputWrapperItems}>
						<div className={styles.inputGroup}>
							<PrTextInput
								type="number"
								value={sidesPrintInfo.find((_info) => _info.sizeId === selectedSize?.id && _info.sideId === selectedSide?.id)?.printWidth}
								onChange={(e) =>
									setSidesPrintInfo((_current) => {
										const newPrintInfos = [..._current];

										for (const printInfo of newPrintInfos) {
											if (printInfo.sizeId !== selectedSize?.id || printInfo.sideId !== selectedSide?.id) continue;

											let width = !!e.target.value ? (Number(e.target.value) < printInfo.minPrintWidth ? printInfo.minPrintWidth : Number(e.target.value)) : printInfo.minPrintWidth;
											if (width > printInfo.maxPrintWidth) width = printInfo.maxPrintWidth;

											printInfo.printWidth = width;

											break;
										}

										return newPrintInfos;
									})
								}
							/>
							<span>in</span>
						</div>

						<span>X</span>

						<div className={styles.inputGroup}>
							<PrTextInput
								type="number"
								value={sidesPrintInfo.find((_info) => _info.sizeId === selectedSize?.id && _info.sideId === selectedSide?.id)?.printHeight}
								onChange={(e) =>
									setSidesPrintInfo((_current) => {
										const newPrintInfos = [..._current];

										for (const printInfo of newPrintInfos) {
											if (printInfo.sizeId !== selectedSize?.id || printInfo.sideId !== selectedSide?.id) continue;

											let height = !!e.target.value ? (Number(e.target.value) < printInfo.minPrintHeight ? printInfo.minPrintHeight : Number(e.target.value)) : printInfo.minPrintHeight;
											if (height > printInfo.maxPrintHeight) height = printInfo.maxPrintHeight;

											printInfo.printHeight = height;

											break;
										}

										return newPrintInfos;
									})
								}
							/>
							<span>in</span>
						</div>

						<PrButton
							text="Set Default"
							icon={<FaCog />}
							onClick={async () => {
								try {
									setLoading(true);

									const response = await userPrintSideDimensionService.createOrUpadte(
										new CreateOrUpdateUserPrintSideDimensionRequest({
											userId: user?.userId,
											sizeId: selectedSize?.id,
											productPrintSideId: selectedSide?.id,
											defaultPrintSizeWidth: sidesPrintInfo.find((_info) => _info.sizeId === selectedSize?.id && _info.sideId === selectedSide?.id)?.printWidth,
											defaultPrintSizeHeight: sidesPrintInfo.find((_info) => _info.sizeId === selectedSize?.id && _info.sideId === selectedSide?.id)?.printHeight
										})
									);
									if (!response.isSuccess) throw '';

									setIsSuccess(true);
								} catch (error) {
									setIsSuccess(false);
								} finally {
									setIsShowMessage(true);
									setLoading(false);
								}
							}}
							style={{ height: '2rem', marginLeft: '1rem' }}
						/>
					</div>
				</div>
			</div>

			{isShowMessage ? (
				<div className={`${styles.fianlMessage} ${isSuccess ? styles.success : styles.error}`}>
					<p>
						{props.product.productName} - {props.product.model.brand.name} {props.product.model.name} body print area {!isSuccess ? 'not' : null} updated.
					</p>
				</div>
			) : null}

			<PrButton
				text="Back"
				type="secondary"
				onClick={() => {
					props.setProduct(undefined);
				}}
				icon={<IoIosArrowBack />}
				style={{ marginTop: 'auto' }}
			/>

			<PrLoading loading={loading} />
		</React.Fragment>
	);
};
