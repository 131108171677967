import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetCoreProductForSynchronizeRequest {
	categoryId: string | null;
	brandId: string | null;
	modelId: string | null;
	colorId: string | null;
	includeCategories: boolean;
	includeUserPrintDimensions: boolean;
	includeBrands: boolean;
	includeModels: boolean;
	includeColors: boolean;
	pagination: PaginationDto;

	constructor(props: Partial<GetCoreProductForSynchronizeRequest>) {
		this.categoryId = props.categoryId || null;
		this.brandId = props.brandId || null;
		this.modelId = props.modelId || null;
		this.colorId = props.colorId || null;
		this.includeCategories = props.includeCategories || false;
		this.includeBrands = props.includeBrands || false;
		this.includeModels = props.includeModels || false;
		this.includeColors = props.includeColors || false;
		this.pagination = props.pagination || new PaginationDto();
		this.includeUserPrintDimensions = props.includeUserPrintDimensions || true;
	}

	get getConvertUriExtension() {
		return `core/for-synchronize`;
	}
}
