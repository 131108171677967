import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import Login from './components/auth/Login';
import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Access from './pages/Access';
import RequireAuth from './components/_common/RequireAuth';
import NotRequireAuth from './components/_common/NotRequireAuth';
import RegisterIndividual from './components/auth/RegisterIndividual';
import SendResetPasswordCode from './components/auth/ResetPassword/SendResetPasswordCode';
import ResetPasswordRequest from './components/auth/ResetPassword/ResetPassword';
import WizardWrapper from "./components/auth/Surveys/Wizard/Wizard";

const AppWrapper = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	switch (location.pathname) {
		case '/login':
			return (
				<Routes>
					<Route path="/login" element={<NotRequireAuth children={<Login />} />} />
				</Routes>
			);
		case '/register-individual':
			return (
				<Routes>
					<Route path="/register-individual" element={<NotRequireAuth children={<RegisterIndividual />} />} />
				</Routes>
			);
		case '/send-reset-password-code':
			return (
				<Routes>
					<Route path="/send-reset-password-code" element={<NotRequireAuth children={<SendResetPasswordCode />} />} />
				</Routes>
			);
		case '/reset-password':
			return (
				<Routes>
					<Route path="/reset-password" element={<NotRequireAuth children={<ResetPasswordRequest />} />} />
				</Routes>
			);
			case '/on-boarding':
			return (
				<Routes>
					<Route path="/on-boarding" element={<RequireAuth children={<WizardWrapper />} />} />
				</Routes>
			);
		case '/error':
			return (
				<Routes>
					<Route path="/error" element={<Error />} />
				</Routes>
			);
		case '/notfound':
			return (
				<Routes>
					<Route path="/notfound" element={<NotFound />} />
				</Routes>
			);
		case '/access':
			return (
				<Routes>
					<Route path="/access" element={<Access />} />
				</Routes>
			);
		default:
			return <RequireAuth children={<App />} />;
	}
};

export default AppWrapper;
