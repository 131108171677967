import HttpBaseService from '../../services/_common/http-base-service';
import {GetDtfProductResponse, GetDtfProductsRequest} from './get-products/get-dtf-products';
import { CreateCustomDtfOrderRequest } from './create-custom-dtf-order/create-custom-dtf-order.request';
import { CreateCustomDtfOrderResponse } from './create-custom-dtf-order/create-custom-dtf-order.response';
import { GetCustomDtfOrdersRequest } from './get-custom-dtf-orders/get-custom-dtf-orders.request';
import { GetCustomDtfOrdersResponse } from './get-custom-dtf-orders/get-custom-dtf-orders.response';
import { GetCustomDtfOrdersAdminResponse } from './get-custom-dtf-orders-admin/get-custom-dtf-orders-admin.response';
import { GetCustomDtfOrdersAdminRequest } from './get-custom-dtf-orders-admin/get-custom-dtf-orders-admin.request';
import {
	CreateCustomDtfOrderStatuHistoryRequest
} from './create-custom-dtf-order-statu-history/create-custom-dtf-order-statu-history.request';
import {
	CreateCustomDtfOrderStatuHistoryResponse
} from './create-custom-dtf-order-statu-history/create-custom-dtf-order-statu-history.response';
import { GetOrderDtfFilesRequest } from './get-order-dtf-files/get-order-dtf-files.request';
import { GetOrderDtfFilesResponse } from './get-order-dtf-files/get-order-dtf-files.response';

class CustomDtfService extends HttpBaseService{
	constructor() {
		super('custom-dtf');
	}

	async getProducts(request: GetDtfProductsRequest): Promise<GetDtfProductResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createCustomDtfOrder(request: CreateCustomDtfOrderRequest): Promise<CreateCustomDtfOrderResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}

	async getCustomDtfOrders(request: GetCustomDtfOrdersRequest): Promise<GetCustomDtfOrdersResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getCustomDtfOrdersAdmin(request: GetCustomDtfOrdersAdminRequest): Promise<GetCustomDtfOrdersAdminResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createOrderStatuHistory(request: CreateCustomDtfOrderStatuHistoryRequest): Promise<CreateCustomDtfOrderStatuHistoryResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getOrderDtfFiles(request: GetOrderDtfFilesRequest): Promise<GetOrderDtfFilesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new CustomDtfService();