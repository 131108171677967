import { Size } from 'features/products/_common/get-core-product/get-core-product.response';
import { CreateProductPrintSideRequest, PrintDimensions } from 'features/products/derived-features/product-print-sides/create-product-print-side/create-product-print-side.request';
import { FormikProps } from 'formik';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import React, { RefObject, useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { InputNumber } from 'primereact/inputnumber';

type props = {
	isVisible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	formikRef: RefObject<FormikProps<CreateProductPrintSideRequest>>;
	productSizes: Size[];
	formikPrintDimensions: PrintDimensions[];
};

type PrintDimensionForMultiple = {
	sizeId: string;
	defaultPrintSizeWidth?: number | string;
	defaultPrintSizeHeight?: number | string;
};

const ApplyMultipleDialog = ({ isVisible, setVisible, formikRef, productSizes, formikPrintDimensions }: props) => {
	const [sizeOptions, setSizeOptions] = useState<SelectItemOptionsType>();
	const [selectedSizes, setSelectedSizes] = useState<string[]>([]);

	const [printDimensions, setPrintDimensions] = useState<PrintDimensionForMultiple>({
		sizeId: '',
		defaultPrintSizeWidth: 0,
		defaultPrintSizeHeight: 0
	});

	const apply = () => {
		const appliedPrintDimensions = structuredClone(
			formikPrintDimensions.map((_size) => {
				if (selectedSizes.includes(_size.sizeId)) {
					return {
						sizeId: _size.sizeId,
						defaultPrintSizeWidth: printDimensions.defaultPrintSizeWidth,
						defaultPrintSizeHeight: printDimensions.defaultPrintSizeHeight
					};
				} else return _size;
			})
		);

		formikRef.current?.setFieldValue('printDimensions', appliedPrintDimensions);
		onClose();
	};

	const onClose = () => {
		setPrintDimensions({
			sizeId: '',
			defaultPrintSizeWidth: 0,
			defaultPrintSizeHeight: 0
		});
		setSelectedSizes([]);
		setVisible(false);
	};

	useEffect(() => {
		if (!productSizes) return;

		setSizeOptions(productSizes.map((_size) => ({ label: _size.shortName, value: _size.id })));
	}, [productSizes]);

	return (
		<Dialog header="Apply Multiple Sides" visible={isVisible} onHide={() => onClose()}>
			<MultiSelect options={sizeOptions} placeholder="Select sizes" value={selectedSizes} onChange={(e) => setSelectedSizes(e.value)} />
			<div className="flex flex-wrap gap-2 pt-3 border-300">
				<div className="flex-1 flex-column gap-1">
					<PrTextInput label="Width" required value={printDimensions.defaultPrintSizeWidth} onChange={(e) => setPrintDimensions({ ...printDimensions, defaultPrintSizeWidth: e.target.value })} placeholder="Printarea default width" />
				</div>

				<div className="flex-1 flex-column gap-1">
					<PrTextInput label="Height" required value={printDimensions.defaultPrintSizeHeight} onChange={(e) => setPrintDimensions({ ...printDimensions, defaultPrintSizeHeight: e.target.value })} placeholder="Printarea default width" />
				</div>
			</div>

			<PrButton text="Apply" onClick={apply} icon={<span className="pi pi-save" />} className="m-auto mt-4 mb-2" />
		</Dialog>
	);
};

export default ApplyMultipleDialog;
