import { CreateManuelOrderDeliveryAddressRequestDto } from "features/orders/_common/create-manuel-order/create-manuel-order.request";

export class GetShippingRatesRequest {
	variantAndQuantities: { [id: string]: number };
	shipTo: CreateManuelOrderDeliveryAddressRequestDto;

	constructor(props: Partial<GetShippingRatesRequest>) {
		this.variantAndQuantities = props.variantAndQuantities || {};
		this.shipTo = props.shipTo || new CreateManuelOrderDeliveryAddressRequestDto({});
	}

	get getConvertUriExtension() {
		return `get-rates-for-manuel-order`;
	}
}