const buildFormData = (formData: FormData, data: any, parentKey?: string | number) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            try {
                const index = Number(key);
                if (Number.isNaN(index)) throw '';
                buildFormData(formData, data[index], parentKey ? `${parentKey}[${index}].` : index);
            } catch (error) {
                const newKey = !!parentKey ? ((parentKey as string).endsWith('.') ? parentKey : parentKey + '.') + key : key;

                if (data[key] instanceof Array) {
                    const files = (data[key] as Array<any>).find((_data: any) => _data instanceof File || typeof _data !== 'object');

                    if (files !== undefined) {
                        (data[key] as []).forEach((_file: any) => (_file instanceof File ? formData.append(newKey, _file, _file.name) : formData.append(newKey, _file)));
                        return;
                    }
                }

                buildFormData(formData, data[key], newKey);
            }
        });
    } else {
        const value = data == null ? '' : data;

        formData.append((parentKey || '').toString(), value);
    }
};

const formDataHelper = { buildFormData };

export default formDataHelper;
