import dtfService from 'features/dtfs/_common/dtf.service';
import { UploadDtfImageRequest } from 'features/dtfs/_common/upload-dtf-image/upload-dtf-image.request';
import { acceptedFileExtensions } from 'helpers/constants/accepted-file-extensions';
import toastHelper from 'helpers/toast.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Dialog } from 'primereact/dialog'
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectParams } from 'primereact/fileupload';
import React, { useEffect, useState } from 'react'

type props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	dtfId: string;
	getDtfDetail: Function;
}

const UploadDtf = ({ visible, setVisible, dtfId, getDtfDetail }: props) => {
	const [files, setFiles] = useState<File[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const headerTemplateOfFileUpload = (options: FileUploadHeaderTemplateOptions) => (
		<div className={options.className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
			{options.chooseButton}
		</div>
	);

	const emptyTemplateOfFileUpload = () => (
		<div className="flex align-items-center flex-column py-3">
			<span className="font-semibold text-xl">Drag and Drop File Here</span>
			<span className="mt-2 font-semibold text-color-secondary">Supported file extensions; .zip</span>
		</div>
	);

	const handleFilesSelect = (event: FileUploadSelectParams) => {
		const fileList = [...event.files];

		setFiles(fileList);
	};

	const upload = async () => {
		if (!dtfId || !files) return;

		try {
			setLoading(true)

			const request = new UploadDtfImageRequest(files[0], dtfId)

			const response = await dtfService.uploadDtfImage(request)

			if (!response.isSuccess) throw ''

			getDtfDetail()
			setVisible(false)
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

  return (
		<Dialog
			header="Upload New Dtf File"
			visible={visible}
			className="max-w-30rem"
			onHide={() => {
				setVisible(false);
			}}>
			<div>
				<div>The existing dtf file will be replaced with the file you uploaded.</div>
				<FileUpload name="image" accept=".zip" chooseLabel="Add your zip file" onSelect={handleFilesSelect} onRemove={() => setFiles([])} headerTemplate={headerTemplateOfFileUpload} emptyTemplate={emptyTemplateOfFileUpload} className="mt-2" />
				<div className="flex flex-row justify-content-end mt-2">
					<PrButton text="Upload" onClick={upload} disabled={files.length === 0} loading={loading} />
				</div>
			</div>
		</Dialog>
  );
}

export default UploadDtf