import { SellerStore } from 'features/seller-stores/dtos/seller-store.dto';
import mediaHelper from 'helpers/media.helper';
import CustomGalleria from 'helpers/widgets/Products/CustomGalleria/CustomGalleria';
import { User } from 'models/dtos/auth/users/user';
import { Size } from 'models/dtos/dimesions/size';
import { ProductDesign } from 'models/dtos/product/printcart/product-design';
import { Product, VariantColor } from 'models/dtos/product/product';
import { DataResponse } from 'models/_commons/responses/data-response';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Skeleton } from 'primereact/skeleton';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPrintramStores } from 'redux/features/printram-store/printramStoreSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import productCopyBySellerService from 'services/products/derived-products/product-copy-by-seller-service';
import stripeService from 'services/stripe-service';
import minMaxPriceHandler from '../components/MinMaxPriceHandler';
import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import SizeGuideChart from 'helpers/widgets/Products/SizeGuideChart/SizeGuideChart';
import { ImageMediaBase } from 'features/medias/dtos/bases/image-media.base.dto';

const ProductDetail = () => {
	const user = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const stores = useAppSelector((state) => state.printramStore as any as DataResponse<SellerStore[]> | '');
	const dispatch = useAppDispatch();

	const { productId } = useParams();
	const navigate = useNavigate();

	const [product, setProduct] = useState<Product | undefined>(undefined);
	const [isUserHavePaymentMethod, setIsUserHavePaymentMethod] = useState<boolean>(false);
	const [originalProductId, setOriginalProductId] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [images, setImages] = useState<ImageMediaBase[]>([]);
	const [sizes, setSizes] = useState<Size[]>([]);
	const [colors, setColors] = useState<VariantColor[]>([]);

	const getProduct = useCallback(() => {
		if (!productId) return;

		setLoading(true);

		productCopyBySellerService
			.getCopiedProductById(productId)
			.then((response) => response.isSuccess && (setProduct(response.data?.copiedProduct), setOriginalProductId(response.data?.coreProduct.id)))
			.finally(() => {
				setLoading(false);
			});
	}, [productId]);

	const getStores = async () => await dispatch(getPrintramStores(user.userId));

	const imageHandleHelper = (design: ProductDesign): ImageMediaBase[] => {
		if (!design.previousDesigns || design.previousDesigns.length < 1) return [] as ImageMediaBase[];

		const lastDesign = design.previousDesigns[design.previousDesigns.length - 1];

		const lastDesignImages: Array<ImageMediaBase> = [];

		lastDesign.productDesignAndProduct?.designAndMedias.forEach((designAndMediasIdentities) => {
			designAndMediasIdentities.designedProductMedias.forEach((_productMedia) => lastDesignImages.push(mediaHelper.getImageOnMedia(_productMedia)));
		});

		lastDesign.productDesignAndVariants?.forEach((_productDesignAndVariant) => {
			const currentImageMedia = mediaHelper.getImageOnMedia(_productDesignAndVariant.media);

			if (lastDesignImages.some((_image) => _image.url === currentImageMedia.url) === false) lastDesignImages.push(currentImageMedia);
		});

		return lastDesignImages;
	};

	const buildImages = () => {
		if (!product) return;

		const images = imageHandleHelper(product.productDesign);

		if (!!product.productMockup) images.push(product.productMockup.designedMockupImage.imageMedia);

		setImages(images);
	};

	const buildColors = () => {
		if (!product) return;

		const uniqueColors: VariantColor[] = [];

		const variants = product.productDesign.previousDesigns[0].productDesignAndVariants?.map((_data) => _data.variant);
		const selectedColor = variants?.filter((variant) => variant !== null && !!variant.color).map((data) => data.color);

		if (!!selectedColor && selectedColor.length > 0) selectedColor.forEach((vColor) => (uniqueColors.findIndex((_vColor) => _vColor.color.id === vColor.color.id) === -1 ? uniqueColors.push(vColor) : null));

		const variantsOther = product.productDesign.previousDesigns[0].productDesignAndProduct?.productVariants;
		const selectedColorOther = variantsOther?.filter((variant) => variant !== null && !!variant.color).map((data) => data.color);

		if (!!selectedColorOther && selectedColorOther.length > 0) selectedColorOther.forEach((vColor) => (uniqueColors.findIndex((_vColor) => _vColor.color.id === vColor.color.id) === -1 ? uniqueColors.push(vColor) : null));

		setColors(uniqueColors);
	};

	const buildSizes = () => {
		if (!product) return;

		const selectedDimensions = product.variants.filter((variant) => variant !== null && !!variant.dimensions[0].size).map((data) => data.dimensions[0]);
		if (!selectedDimensions) return;

		const uniqueSizes: Size[] = [];

		selectedDimensions.forEach((dimension) => (uniqueSizes.findIndex((size) => size.id === dimension.size.id) === -1 ? uniqueSizes.push(dimension.size) : null));

		setSizes(uniqueSizes.sort((a, b) => a.priority - b.priority));
	};

	const getIsHavePaymentMethod = async () => {
		try {
			if (!user) throw '';

			const response = await stripeService.isUserHaveAnyPaymentMethod(user.userId);
			if (!response.isSuccess || !response.data) throw '';

			setIsUserHavePaymentMethod(response.data);
		} finally {
			console.clear();
		}
	};

	useEffect(() => {
		if (!productId) return;

		if (!!stores && stores.data === null) getStores();

		getIsHavePaymentMethod();
		getProduct();
	}, [productId, getProduct]);

	useEffect(() => {
		if (!product) return;

		buildImages();
		buildSizes();
		buildColors();
	}, [product]);

	const downloadSVG = () => {
		if (!product || !user) return;

		product.productDesign.previousDesigns[0].productDesignedImages.forEach((design) => {
			if (mediaHelper.getImageOnMedia(design.media).type !== 'SVG') return;

			fetch(mediaHelper.getImageOnMedia(design.media).url).then((response) =>
				response.blob().then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = `${product.name.toLocaleLowerCase().replaceAll(' ', '-')}-design.svg`;
					a.click();
				})
			);
		});
	};

	const isStoreExists = !!stores && stores.data !== null;

	const isConnectedStoreExists = isStoreExists && !!stores?.data?.find((store) => !!store.etsyStore);

	const showConnectedStoreNotExistsDialog = () => {
		confirmDialog({
			header: isStoreExists ? 'Connected Store Not Found' : 'Store Not Found',
			message: 'Your linked store was not found, You need to link a store to continue. Want to link a store?',
			icon: 'pi pi-info-circle',
			acceptLabel: 'Go Store Page',
			rejectLabel: 'Stay Page',
			acceptClassName: 'px-4',
			rejectClassName: 'p-button-text px-4',
			accept: () => navigate('/account/my-stores')
		});
	};

	const showIsUserHavePaymentMethodNotExistsDialog = () => {
		confirmDialog({
			header: 'Payment Method Not Found',
			message: 'You have not added any payment method, you cannot continue the transaction without adding a payment method.\nWould you like to add a payment method?',
			icon: 'pi pi-info-circle',
			acceptLabel: 'Go Payment Page',
			rejectLabel: 'Stay Page',
			acceptClassName: 'px-4',
			rejectClassName: 'p-button-text px-4',
			accept: () => navigate('/account/payments')
		});
	};

	const sendEtsy = () => {
		if (!productId) return;

		if (!isConnectedStoreExists) {
			showConnectedStoreNotExistsDialog();
			return;
		}

		if (!isUserHavePaymentMethod) {
			showIsUserHavePaymentMethodNotExistsDialog();
			return;
		}

		navigate('/product/seller/etsy-upload/' + productId);
	};

	const downloadPNG = () => {
		if (!product) return;

		product.productDesign.previousDesigns[0].productDesignedImages.forEach((design) => {
			if (mediaHelper.getImageOnMedia(design.media).type !== 'PNG') return;

			fetch(mediaHelper.getImageOnMedia(design.media).url).then((response) =>
				response.blob().then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = `${product.name.toLocaleLowerCase().replaceAll(' ', '-')}-design.png`;
					a.click();
				})
			);
		});
	};

	return (
		<div className="card">
			<h5>Product Detail</h5>

			{loading ? (
				<div className="grid">
					<div className="col-4">
						<Skeleton height="27rem" />

						<div className="grid mt-1">
							<div className="col-12">
								<Skeleton height="6rem" />
							</div>
						</div>
					</div>

					<div className="col-8">
						<Skeleton height="1.786rem" />
						<Skeleton height="1rem" width="50%" className="mt-3" />
						<Skeleton height="5rem" className="mt-4" />
					</div>
				</div>
			) : !!product ? (
				<div className="grid">
					<div className="col-12 md:col-6 lg:col-4">
						<CustomGalleria images={images} />
					</div>

					<div className="col">
						<div className="flex flex-column gap-2">
							<div>
								<div className="flex align-items-end justify-content-between mb-3">
									<h4 className="m-0">{product.name}</h4>

									<Link to={'/product/detail/' + originalProductId} className="p-button p-button-outlined px-3">
										<span className="pi pi-arrow-right"></span>
										<span className="ml-2">Go to Original Product</span>
									</Link>
								</div>

								<span className="text-lg">{product.brandName + ' ' + product.modelName}</span>
							</div>

							<ul className="flex flex-column gap-2">{!!product.details ? product.details.map((detail, index) => <li key={index}>{detail.detail}</li>) : null}</ul>

							<span dangerouslySetInnerHTML={{ __html: product.description }} />

							<hr className="my-2" />

							{!!product.materials && product.materials.length > 0 ? (
								<div>
									<h6 className="mb-2">Materials</h6>

									<div className="flex align-items-center flex-wrap gap-2">
										{product.materials.map((productMaterial) => (
											<div key={productMaterial.id} className="border-500 border-1 border-round px-3 py-1 shadow-2">
												<span>{productMaterial.ratioPercentage}%</span>
												<span> {productMaterial.material.name}</span>
											</div>
										))}
									</div>
								</div>
							) : null}

							<h6>Price: {minMaxPriceHandler(product.minPrice?.formattedPricePerUnit, product.maxPrice?.formattedPricePerUnit)}</h6>

							<div className="flex flex-wrap gap-5">
								<div>
									<h6 className="mb-2">Sizes</h6>
									<div className="flex flex-wrap align-items-center justify-content-start gap-2">
										{sizes.length > 0
											? sizes.map((size) => (
													<div key={size.id} className="px-1 h-2rem border-500 border-2 border-round flex align-items-center justify-content-center" style={{ minWidth: '2rem' }}>
														<span className="font-bold">{size.shortName}</span>
													</div>
											  ))
											: null}
									</div>
								</div>

								<div>
									<h6 className="mb-2">Colors</h6>
									<div className="flex flex-wrap align-items-center justify-content-start gap-2">
										{colors.length > 0
											? colors.map((color, index) => (
													<React.Fragment key={index}>
														<Tooltip target={`.color-${index}`} />

														<div className={`color-${index}`} data-pr-tooltip={color.color.name} data-pr-position="bottom">
															<div className="border-circle w-2rem h-2rem" style={{ backgroundColor: color.color.hexCode, boxShadow: '0 2px 4px 0000' }} />
														</div>
													</React.Fragment>
											  ))
											: null}
									</div>
								</div>
							</div>

							<div className="mt-4">
								<Button onClick={downloadSVG} icon="pi pi-download" label="Download SVG" className="px-4" />
								<Button onClick={downloadPNG} icon="pi pi-download" label="Download PNG" className="px-4 ml-3" />
								<Button onClick={sendEtsy} icon="pi pi-send" label="Go to Etsy details" className="px-4 ml-3" />
							</div>
						</div>
					</div>

					<hr className="w-full mx-3" style={{ marginBlock: '2rem' }} />

					<div className="w-full">
						<SizeGuideChart {...product.sizeChart} />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ProductDetail;
