export enum PaginationOrderByEnum {
	Ascending = 1,
	Descending = 2
}

export interface IPagination {
	pageNumber: number;
	itemCount: number | null;
	orderBy: PaginationOrderByEnum;
	first: number;
}
