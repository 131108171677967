import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

type Props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onSubmit: (name: string) => void;
};

const CreateStoreDialog = (props: Partial<Props>) => {
	const [name, setName] = useState<string>('');

	const submit = () => {
		props.setVisible?.(false);
		props.onSubmit?.(name);
		setName('');
	};

	return (
		<Dialog header="Create new printram store" visible={props.visible} onHide={() => props.setVisible?.(false)} style={{ maxWidth: '50rem' }}>
			<div className="flex gap-3 align-items-end">
				<PrTextInput
					label="Store Name"
					value={name}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							event.preventDefault();
							submit();
						}
					}}
					onChange={(e) => setName(e.target.value || '')}
					required
				/>

				<PrButton
					text="Create"
					icon={<span className="pi pi-fw pi-plus-circle" />}
					onClick={() => {
						submit();
					}}
				/>
			</div>
		</Dialog>
	);
};

export default CreateStoreDialog;
