import { Variant } from 'features/products/_common/get-core-product/get-core-product.response';
import { CopiedProductDto, PreviousDesign } from 'features/products/derived-features/product-copied-by-sellers/get-copied-product-detail/get-copied-product-detail.response';
import { ProductDesign } from '../models/dtos/product/printcart/product-design';
import mediaHelper from './media.helper';
import { ImageMediaBase } from 'features/medias/dtos/bases/image-media.base.dto';
import { MediaBase } from 'features/medias/dtos/bases/media.base.dto';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';

const imageHandle = (design: ProductDesign): ImageMediaBase[] => {
	if (!design.previousDesigns || design.previousDesigns.length < 1) return [];

	const lastDesign = design.previousDesigns[design.previousDesigns.length - 1];

	const lastDesignImages: ImageMediaBase[] = [];

	lastDesign.productDesignAndProduct?.designAndMedias?.forEach((designAndMediasIdentities) => {
		designAndMediasIdentities.designedProductMedias.forEach((_productMedia) => lastDesignImages.push(mediaHelper.getImageOnMedia(_productMedia)));
	});

	lastDesign.productDesignAndVariants?.forEach((_productDesignAndVariant) => {
		const currentImageMedia = mediaHelper.getImageOnMedia(_productDesignAndVariant.media);

		if (lastDesignImages.some((_image) => _image.url === currentImageMedia.url) === false) lastDesignImages.push(currentImageMedia);
	});

	return lastDesignImages;
};

const mediaHandleNew = (designs: PreviousDesign[]): MyProductMedia[] => {
	if (!designs) return [];

	const images: MyProductMedia[] = [];

	for (const design of designs) {
		if (design.uploadedDesignedMediasToProduct.length > 0) {
			for (const uploadedDesign of design.uploadedDesignedMediasToProduct) {
				const image = mediaHelper.getNewMediaImage(uploadedDesign.media);

				if (images.findIndex((_image) => mediaHelper.getNewMediaImage(_image).url === image.url) > -1) continue;

				images.push(uploadedDesign.media);
			}

			break;
		}

		for (const uploadedDesign of design?.uploadedDesignedMediasToVariant || []) {
			const image = mediaHelper.getNewMediaImage(uploadedDesign.media);

			if (images.findIndex((_image) => mediaHelper.getNewMediaImage(_image).url === image.url) > -1) continue;

			images.push(uploadedDesign.media);
		}
	}

	return images;
};

const buildMediasForCopiedProduct = (copiedProduct: CopiedProductDto): MyProductMedia[] => {
	if (!copiedProduct) return [];

	const images: MyProductMedia[] = [];

	if (copiedProduct.images?.length > 0)
		for (const pImage of copiedProduct.images) {
			const image = mediaHelper.getNewMediaImage(pImage);

			if (images.findIndex((_image) => mediaHelper.getNewMediaImage(_image).url === image.url) > -1) continue;

			images.push(pImage);
		}

	if (copiedProduct.mockups?.length > 0)
		for (const pImage of copiedProduct.mockups) {
			const image = mediaHelper.getNewMediaImage(pImage.media);

			if (images.findIndex((_image) => mediaHelper.getNewMediaImage(_image).url === image.url) > -1) continue;

			images.push(pImage.media);
		}

	images.push(...mediaHandleNew(copiedProduct.design.previousDesigns));

	return images;
};

const getImagesByVariants = (variants: Variant[]): MyProductMedia[] => {
	const images: MyProductMedia[] = [];

	for (const variant of variants) {
		if (!variant.medias || variant.medias.length < 1) continue;

		const media = variant.medias[variant.medias.length - 1];
		const image = mediaHelper.getNewMediaImage(media);

		if (images.findIndex((_image) => mediaHelper.getNewMediaImage(_image).url === image.url) > -1) continue;

		images.push(media);
	}

	return images;
};

const imageHelper = { buildMediasForCopiedProduct, getImagesByVariants };

export default imageHelper;
