import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { AssignedStoreDto } from 'features/printCenters/derived-features/assigned-store-to-print-centers/_dtos/assigned-store.dto';
import { GetAssignedStoresToPrintCenterRequest } from 'features/printCenters/derived-features/assigned-store-to-print-centers/get-assigned-stores-to-print-center/get-assigned-stores-to-print-center.request';
import assignedStoreToPrintCentersService from 'features/printCenters/derived-features/assigned-store-to-print-centers/assigned-store-to-print-centers.service';
import dateHelper from 'helpers/dateHelper';
import { Pagination } from 'models/_commons/responses/pagination';
import { setForPrintCenterAssignedStorePaginationInfo } from 'redux/features/pagination/paginationSlice';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const AssignedStore = () => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const fromStatePagination = useAppSelector((state) => state.pagination.forPrintCenterAssignedStorePaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<string | undefined>(undefined);
	const [stores, setStores] = useState<AssignedStoreDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			const request = new GetAssignedStoresToPrintCenterRequest({ printCenterId: user?.userId, contains: filter, pagination });
			const response = await assignedStoreToPrintCentersService.getAssignedStoresToPrintCenter(request);
			if (!response.isSuccess || !response.data) throw '';

			const mappedStores = response.data.map((_store) => {
				_store.assignDate = dateHelper.formatDate(_store.assignDate);
				return _store;
			});

			setPaginationResponse(response.pagination);
			setStores(mappedStores);
		} catch (error) {
			setStores([]);
		} finally {
			setLoading(false);
		}
	};

	const handleFilter = () => {
		if (typeof filter === 'string' && filter.length === 0) {
			onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
			return;
		}

		if (!filter || filter.length < 3) return;

		onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		const serializeData = JSON.stringify(newPagination);
		dispatch(setForPrintCenterAssignedStorePaginationInfo(JSON.parse(serializeData)));
		getAll(newPagination);
	}, []);

	const onPageChangeEvent = (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;

			const serializeData = JSON.stringify(newPagination);
			dispatch(setForPrintCenterAssignedStorePaginationInfo(JSON.parse(serializeData)));
		}

		getAll(newPagination);
	};

	return (
		<div className="card">
			<div className="flex flex-wrap align-items-center gap-3 mt-1 mb-3">
				<h5 className="m-0">Assigned Store List</h5>

				<div>
					<div className="p-inputgroup">
						<InputText placeholder="Search..." value={filter} onKeyDown={(event) => event.key === 'Enter' && handleFilter()} onChange={(event) => setFilter(event.target.value)} className="p-inputtext-sm" />
						<Button onClick={handleFilter} icon="pi pi-fw pi-search" className="p-button-sm" />
					</div>
				</div>
			</div>

			<DataTable value={stores} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading} className="p-datatable-gridlines">
				<Column header="Seller" field="sellerFullName" />
				<Column header="Store" field="storeName" />
				<Column header="Total Order" field="totalOrderCount" />
				<Column header="Assign Date" field="assignDate" />
			</DataTable>
		</div>
	);
};

export default AssignedStore;
