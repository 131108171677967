import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { contactService } from 'features/contacts/_common/contact.service';
import { ContactBaseDto } from 'features/contacts/_common/get-contacts/dtos/contact.base.dto';
import { GetContactsRequest } from 'features/contacts/_common/get-contacts/get-contacts.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { setForAdminContactsPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const Contacts = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminContactsPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [contacts, setContacts] = useState<ContactBaseDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const opRef = useRef<OverlayPanel[]>([]);

	const getContacts = async (pRequest: Partial<GetContactsRequest>) => {
		try {
			setLoading(true);

			const request = new GetContactsRequest(pRequest);

			const response = await contactService.getContacts(request);

			if (!response.isSuccess || !response.data) throw '';

			setContacts(response.data);
			setPaginationResponse(response.pagination);

			const newPagination = JSON.parse(JSON.stringify(request.pagination));

			dispatch(setForAdminContactsPaginationInfo(newPagination));
		} catch (error) {
			setContacts([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
		}
	};

	const getDataTotalIndex = (id: string) => fromStatePagination.first + contacts.findIndex((_contact) => _contact.id === id);

	useEffect(() => {
		getContacts({});
	}, []);

	return (
		<main className="container">
			<section className="container-header">
				<h1 className="container-header-title">Contacts</h1>

				<div className="container-header-tools">
					<PrButton
						type="secondary"
						text="Refresh Contacts"
						icon={<span className="pi pi-fw pi-refresh" />}
						loading={loading}
						onClick={async () => {
							await getContacts({});
						}}
					/>
				</div>
			</section>

			<section className="container-body relative p-0">
				<DataTable
					dataKey="id"
					value={contacts}
					currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					totalRecords={paginationResponse?.totalItemCount}
					showGridlines
					className="pr-table"
					lazy
					paginator
					rowsPerPageOptions={[5, 10, 20, 30]}
					rows={fromStatePagination.itemCount || 20}
					first={fromStatePagination.first}
					onPage={async (event) => {
						const pageNumber = !!event.page ? event.page + 1 : 1;

						await getContacts({ pagination: new PaginationDto(pageNumber, event.rows, undefined, event.first) });
					}}>
					<Column field="name" header="Name" />
					<Column field="phone" header="Phone" />
					<Column field="email" header="Email" />
					<Column field="subject" header="Subject" />
					<Column
						field="comment"
						header="Comment"
						body={(row: ContactBaseDto) => {
							const index = getDataTotalIndex(row.id);

							return (
								<div className="flex gap-2 align-items-center justify-content-between">
									<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '10rem' }}>{row.comment}</span>

									<PrButton type="icon" icon={<IoEyeOutline size={'1.25rem'} />} onClick={(e) => opRef.current[index].toggle(e)} />

									<OverlayPanel ref={(_element) => (!!_element ? (opRef.current[index] = _element) : null)} className="max w-20rem">
										{row.comment}
									</OverlayPanel>
								</div>
							);
						}}
					/>
				</DataTable>

				{loading ? (
					<div className="pr-loading-sticky">
						<div className="pr-spinner-wrapper">
							<ProgressSpinner className="p-progress-color" strokeWidth="4" />
						</div>
					</div>
				) : null}
			</section>
		</main>
	);
};

export default Contacts;
