import { Menu } from '@headlessui/react';
import { Skeleton } from 'primereact/skeleton';
import { BiDotsHorizontalRounded, BiLinkExternal, BiStore } from 'react-icons/bi';
import { SellerStore } from '../../../../../../../features/seller-stores/dtos/seller-store.dto';
import PrButton from '../../../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import "./StoreItem.module.scss";

type Props = {
    store: SellerStore;
    length: number;
    index: number;
    loading: boolean;
    connected: boolean;
    onUpdateStore: (store: SellerStore) => void;
    onConnectStore: (store: SellerStore) => void;
    onDisconnectStore: () => void;
};

const StoreItemSkeleton = () => (
    <div className="flex flex-row justify-content-between align-items-center bg-white w-full border-round-md p-4 shadow-1 my-2">
        <div className="flex flex-row justify-content-evenly align-items-center w-full sm:w-6">
            <Skeleton shape="circle" size="16px" />
            <Skeleton shape="circle" size="48px" className="mx-3" />
            <div className="flex flex-column justify-content-evenly align-items-start mr-auto">
                <Skeleton width="8rem" className="mb-1" />
                <Skeleton width="12rem" className="mt-1" />
            </div>
        </div>
        <Skeleton width="6rem" height="2rem" className="mx-3" />
    </div>
);

const StoreItem = ({
                       store,
                       length,
                       index,
                       loading,
                       connected,
                       onUpdateStore,
                       onConnectStore,
                       onDisconnectStore,
                   }: Props) => {
    const menuItems = [
        {
            text: "Update Store Name",
            disabled: false,
            onClick: () => onUpdateStore(store),
        },
        {
            text: "Disconnect",
            disabled: !connected,
            onClick: () => onDisconnectStore(),
        },
    ];

    const renderStoreDetails = () => (
        <div className="flex flex-row justify-content-between align-items-center bg-white w-full border-round-md p-4 shadow-1 my-2">
            <div className="flex flex-row justify-content-evenly align-items-center w-full">
                <div
                    style={{
                        backgroundColor: connected ? '#10B981' : '#F43F5E',
                        borderRadius: '100%',
                        minWidth: '16px',
                        minHeight: '16px'
                    }}
                />
                <div
                    className="flex justify-content-center align-items-center mx-3"
                    style={{
                        minWidth: '48px',
                        minHeight: '48px',
                        borderRadius: '100%',
                        border: '2px solid',
                        borderColor: '#4541FF'
                    }}>
                    <BiStore size={24} />
                </div>
                <div className="flex flex-column justify-content-evenly align-items-start xl:mr-0 mr-auto w-17rem text-overflow-ellipsis">
                    <p className="text-base font-medium m-0">{store?.name}</p>
                    {store?.etsyStore?.url && (
                        <a className="hidden sm:block" href={store.etsyStore.url} style={{ color: '#4541FF' }}>
                            {store.etsyStore.url.slice(8)}
                        </a>
                    )}
                </div>
                <div className="hidden xl:flex justify-content-center align-items-center border-solid border-gray-300 border-1 border-round-sm ml-3 py-2 px-4 mr-auto">
                    <span className="text-center" style={{ width: '5rem' }}>
                        {connected ? 'Connected' : 'Unconnected'}
                    </span>
                </div>
            </div>
            <div className="flex flex-row justify-content-center align-items-center">
                {!connected && (
                    <PrButton className="hidden lg:block transition-linear border-round-md text-sm" text={'Connect'} onClick={() => onConnectStore(store)} />
                )}
                <Menu as="div" className="pr-dropdown" style={{ zIndex: (length || 1) - (index || 0) }}>
                    <Menu.Button className="pr-btn flex p-button-text justify-content-between" style={{ border: 'none' }}>
                        <BiDotsHorizontalRounded size={24} style={{ color: '#101531' }} />
                    </Menu.Button>
                    <Menu.Items className="pr-dropdown-menu">
                        {menuItems.map((item, index) => (
                            <Menu.Item key={index} as="button" disabled={item.disabled} onClick={item.onClick}
                                       className="pr-btn pr-dropdown-menu-item text-sm"
                                       style={{ opacity: item.disabled ? ".5" : "1" }}>
                                {item.text}
                            </Menu.Item>
                        ))}
                        <Menu.Item as="button" disabled={store.etsyStore?.url != null} onClick={() => null}
                                   className="pr-btn pr-dropdown-menu-item text-sm block lg:hidden">
                            Go to Store{' '}
                            <span className="ml-auto">
                                <BiLinkExternal />
                            </span>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            </div>
        </div>
    );

    return loading ? <StoreItemSkeleton /> : renderStoreDetails();
};

export default StoreItem;
