
import balanceService from 'features/balances/_common/balance.service'
import { GetBalanceByUserIdRequest } from 'features/balances/_common/get-balance-by-user-id/get-balance-by-user-id.request'
import { Price } from 'features/products/dtos/prices/price.dto'
import { SellerForListDto } from 'features/sellers/_common/dtos/seller-for-list.dto'
import { Dialog } from 'primereact/dialog'
import styles from './SellerBalance.module.scss'
import React, { useEffect, useState } from 'react'
import currencyHelper from 'helpers/curreny.helper'
import { TabPanel, TabView } from 'primereact/tabview'
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { IncreaseBalanceRequest } from 'features/balances/_common/increase-balance/increase-balance.request'
import { DecreaseBalanceRequest } from 'features/balances/_common/decrease-balance/decrease-balance.request'
import { BalanceWithdrawRequest } from 'features/balances/_common/balance-withdraw/balance-withdraw.request'
import { ProgressSpinner } from 'primereact/progressspinner'
import Transactions from 'components/pages/account/components/payments/components/Transactions'
import { Calendar } from 'primereact/calendar'

type props = { isVisible: boolean, setVisible: Function, seller: SellerForListDto, setSeller: Function }
const SellerBalance = ({ isVisible, setVisible, seller, setSeller } : props) => {
	const [balance, setBalance] = useState<Price>();
	const [amount, setAmount] = useState(0);
	const [description, setDescription] = useState("");
	const [activeIndex, setActiveIndex] = useState(0);
	const [loading, setLoading] = useState<boolean>(false);

	const closeHandler = () => {
		setSeller(undefined);
		setBalance(undefined);
		setActiveIndex(0);
		setAmount(0);
		setLoading(false);
		setVisible(false);
	}

	const activeHandler = (index: number) => index === activeIndex ? 'primary' : 'secondary'

	const titleHandler = (index: number) => {
		if (index === 0) return 'Top Up Balance'
		else if (index === 1) return 'Decrease Balance'
		else if (index === 2) return 'Refund Balance'
		else if (index === 3) return 'Transactions'
	}

	const btnActiveHandler = () => {
		if (amount > 0 && description) return false
		else return true
	}

	const increaseBalance = async() => {
		try {
			if (!seller.id) throw '';

			setLoading(true);

			const request = new IncreaseBalanceRequest(seller.id, Math.round(parseFloat(amount.toString()) * 100), description)

			const response = await balanceService.increaseBalance(request)

			if (!response.isSuccess) throw '';

			closeHandler();
		}
		catch { }
		finally { setLoading(false) }
	}

	const decreaseBalance = async () => {
		try {
			if (!seller.id) throw '';

			setLoading(true);

			const request = new DecreaseBalanceRequest(seller.id, Math.round(parseFloat(amount.toString()) * 100), description);

			const response = await balanceService.decreaseBalance(request);

			if (!response.isSuccess) throw '';

			closeHandler();
		}
		catch { }
		finally { setLoading(false) }
	}

	const refundBalance = async () => {
		try {
			if (!seller.id) throw '';

			setLoading(true);

			const request = new BalanceWithdrawRequest(seller.id, Math.round(parseFloat(amount.toString()) * 100), description);

			const response = await balanceService.balanceWithdraw(request)

			if (!response.isSuccess) throw '';

			closeHandler();
		}
		catch { }
		finally { setLoading(false) }
	}

	const getUserBalance = async () => {
		try {
			if (!seller.id) throw '';

			setLoading(true);

			const request = new GetBalanceByUserIdRequest(seller.id);

			const response = await balanceService.getBalanceByUserId(request);

			if (!response.data?.price) throw '';

			setBalance(response?.data?.price);
		} catch { }
		finally { setLoading(false) }
	};

	useEffect(() => {
		getUserBalance();

		return () => {
			closeHandler();
		};
	},[])

  return (
		<Dialog visible={isVisible} header="Balance" onHide={closeHandler} style={{ maxWidth: '70rem' }}>
			<div className="relative" style={{ minHeight: '10rem' }}>
				<div className={styles.balanceCard}>
					<div className={styles.sellerInfos}>
						<div>
							<span className="font-bold">Seller Email: </span>
							<span>{seller.email}</span>
						</div>
						<div>
							<span className="font-bold">Seller Name: </span>
							<span>{seller.fullName}</span>
						</div>
					</div>
					<div className={styles.balanceInfo}>
						<span>Current Balance</span>
						<span className="font-bold text-lg">{currencyHelper.formatPrice(balance?.formattedPricePerUnit || 0)}</span>
					</div>
				</div>

				<div className={styles.buttons}>
					<PrButton text="Top Up" onClick={() => setActiveIndex(0)} type={activeHandler(0)} />
					<PrButton text="Decrease" onClick={() => setActiveIndex(1)} type={activeHandler(1)} />
					<PrButton text="Refund" onClick={() => setActiveIndex(2)} type={activeHandler(2)} />
					<PrButton text="Transactions" onClick={() => setActiveIndex(3)} type={activeHandler(3)} />
				</div>
				<hr />
				<div>
					<div className="flex flex-row align-items-center p-0">
						<span className={styles.title}>{titleHandler(activeIndex)}</span>
						{/* {activeIndex === 3 && (
							<div className="flex-1 text-right">
								<Calendar value={} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput />
							</div>
						)} */}
					</div>

					{activeIndex === 0 && (
						<div className={styles.tab}>
							<p>You can use this menu to add balance to the seller.</p>
							<div className={styles.inputs}>
								<div className={styles.amountInput}>
									<span>Amount:</span>
									<InputNumber inputId="currency-us" value={amount} onChange={(event) => setAmount(event.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} />
								</div>

								<div className={styles.descriptionInput}>
									<span>Description:</span>
									<InputText value={description} onChange={(event) => setDescription(event.target.value)} />
								</div>
							</div>
							<div className={styles.tabBtn}>
								<PrButton text="Top Up Balance" icon={<i className="pi pi-fw pi-plus-circle" />} disabled={btnActiveHandler()} onClick={increaseBalance} />
							</div>
						</div>
					)}
					{activeIndex === 1 && (
						<div className={styles.tab}>
							<span className={styles.tabDescription}>You can use this menu to reduce the seller's balance.</span>
							<span className={styles.tabWarn}>Attention: This menu does not refund the seller's account, it deletes the seller's balance.</span>
							<div className={styles.inputs}>
								<div className={styles.amountInput}>
									<span>Amount:</span>
									<InputNumber inputId="currency-us" value={amount} onChange={(event) => setAmount(event.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} />
								</div>

								<div className={styles.descriptionInput}>
									<span>Description:</span>
									<InputText value={description} onChange={(event) => setDescription(event.target.value)} />
								</div>
							</div>
							<div className={styles.tabBtn}>
								<PrButton text="Decrease Balance" icon={<i className="pi pi-fw pi-minus-circle" />} disabled={btnActiveHandler()} onClick={decreaseBalance} />
							</div>
						</div>
					)}
					{activeIndex === 2 && (
						<div className={styles.tab}>
							<span className={styles.tabDescription}>You can use this menu to send the seller's balance to the registered stripe account.</span>

							<div className={styles.inputs}>
								<div className={styles.amountInput}>
									<span>Amount:</span>
									<InputNumber inputId="currency-us" value={amount} onChange={(event) => setAmount(event.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} />
								</div>

								<div className={styles.descriptionInput}>
									<span>Description:</span>
									<InputText value={description} onChange={(event) => setDescription(event.target.value)} />
								</div>
							</div>

							<div className={styles.tabBtn}>
								<PrButton text="Refund Balance" icon={<i className="pi pi-fw pi-undo" />} disabled={btnActiveHandler()} onClick={refundBalance} />
							</div>
						</div>
					)}

					{activeIndex === 3 && (
						<div className={styles.tab}>
							<Transactions sellerId={seller.id} />
						</div>
					)}
				</div>

				{loading ? (
					<div className="pr-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}
			</div>
		</Dialog>
  );
}

export default SellerBalance