export class DeleteProductPrintSideRequest {
	sideId: string;

	constructor(sideId: string) {
		this.sideId = sideId;
	}

	get getConvertUriExtension() {
		return `${this.sideId}`;
	}
}