export class UploadDtfImageRequest {
	stream: File;
	dtfListId: string;

	constructor(stream: File, dtfListId: string) {
		this.stream = stream
		this.dtfListId = dtfListId
	}

	get getConvertUriExtension() {
		return 'upload-dtf-image';
	}
}