import React from 'react'
import PrintOptionsWidget from '../../../../../helpers/widgets/PrintOptions/PrintOptionsWidget';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import styles from './PrintOptions.module.scss';
import { Link, useNavigate } from 'react-router-dom';

const PrintOptions = () => {
	const navigate = useNavigate();

	return (
		<div className="relative">
			<div className="container-header">
				<h1 className="container-header-title">Print Options</h1>

				<div className={`container-header-tools ${styles.deviceControl}`}>
					<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />

					<Link to="/account/security">
						<PrButton icon={<span className="pi pi-arrow-right" />} text="Go Security" />
					</Link>
				</div>
			</div>

			<div className='container-body p-3'>
				<PrintOptionsWidget type="save" />
			</div>
		</div>
	);
}

export default PrintOptions;