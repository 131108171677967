import media from 'assets/musics/bicycle-bell.mp3';

const playAudio = () => {
	const audio = new Audio(media);

	audio.play().catch((err) => console.error('Error:', err));
};

const audioHelper = { playAudio };

export default audioHelper;
