import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export interface DateFilterDto {
	minDate: Date | string | null;
	maxDate: Date | string | null;
}

export class GetOperationProcessStepsRequest {
	contains: string | null;
	pagination: PaginationDto;
	dateFilter: DateFilterDto;

	constructor(props: Partial<GetOperationProcessStepsRequest>) {
		this.contains = props.contains || null;
		this.pagination = props.pagination || new PaginationDto(1, 20);
		this.dateFilter = props.dateFilter || { minDate: null, maxDate: null };
	}

	get getConvertUriExtension() {
		return `get-operation-process-steps`;
	}
}
