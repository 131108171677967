import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { UpdateModelRequest } from '../../../../../models/requests/models/update-model-request';
import { Model } from '../../../../../models/dtos/brand/model';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import modelService from '../../../../../services/model-service';

type Props = {
    isVisible: boolean;
    setVisible: Function;
    listModels: Function;
    brandsOptions: SelectItemOptionsType | undefined;
    editedModel: Model | undefined;
    setEditedModel: Function;
};

const ModelUpdate = ({ isVisible, setVisible, listModels, brandsOptions, editedModel, setEditedModel }: Props) => {
    const initialValues: UpdateModelRequest = { id: editedModel?.modelId || '', brandId: editedModel?.brandId || '', name: editedModel?.modelName || '' };

    const validationScheme = Yup.object().shape({ name: Yup.string().required('Model name is Required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.'), brandId: Yup.string().required('Brand is required') });

    const submitForm = (values: UpdateModelRequest) => {
        modelService.update(values).then((response) => {
            if (!response.isSuccess) return;

            setEditedModel(undefined);
            setVisible(false);
            listModels();
        });
    };
    
    return (
        <Dialog
            visible={isVisible}
            header="Update Model"
            modal
            onHide={() => {
                setVisible(false);
                setEditedModel(undefined);
            }}
        >
            <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
                {({ values, handleChange, setFieldValue }) => (
                    <Form>
                        <div className="grid">
                            <div className="col-12 mb-0 pb-0">
                                <ErrorMessage name="brandId" component="small" className="text-red font-medium w-full" />
                            </div>
                            <div className="col-12">
                                <Dropdown options={brandsOptions} value={values.brandId} onChange={(event) => setFieldValue('brandId', event.value)} placeholder="Select a Brand" className="w-full" />
                            </div>
                        </div>

                        <div className="grid mt-2">
                            <div className="col-12 mb-0 pb-0">
                                <ErrorMessage name="name" component="small" className="text-red font-medium w-full" />
                            </div>
                            <div className="col-8">
                                <InputText name="name" value={values.name} onChange={handleChange} placeholder="Enter model name" className="w-full" />
                            </div>
                            <div className="col-4">
                                <Button type="submit" label="Update" className="w-full h-full" />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ModelUpdate;
