export class GetPrintCenterOperatorByIdRequest {
	id: number;

	constructor(id: number) {
		this.id = id;
	}

	getConvertUriExtension () {
		return `get-by-id?id=${this.id}`;
	}
}