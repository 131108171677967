import { MeasurementType } from '../../models/dtos/dimesions/measurement-type';
import { GetMeasurementTypesRequest } from '../../models/requests/dimensions/measurement-types/get-measurement-types-request';
import { DataPaginationResponse } from '../../models/_commons/responses/data-pagination-response';
import HttpBaseService from '../_common/http-base-service';

class MeasurementTypeService extends HttpBaseService {
    constructor() {
        super('measurement-types');
    }

    async getAll(request: GetMeasurementTypesRequest): Promise<DataPaginationResponse<MeasurementType[]>> {
        return await this.postData('get-measurement-types', request);
    }
}

export default new MeasurementTypeService();
