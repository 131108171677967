import { ProductVariationForUpdate } from '../../models/dtos/product/product-variation-for-update';
import { CreateProductVariantsRequest } from '../../models/requests/products/create-product-variants-request';
import { GenerateProductVariants } from '../../models/requests/products/product-variants-generate-request';
import { UpdateProductVariantsRequest } from '../../models/requests/products/update-product-variants-request';
import { DataResponse } from '../../models/_commons/responses/data-response';
import HttpBaseService from '../_common/http-base-service';

class ProductVariantsService extends HttpBaseService {
	constructor() {
		super('product-variants');
	}

	async getAll(productId: string): Promise<DataResponse<ProductVariationForUpdate>> {
		return await this.getData('get-product-variants-for-update-by-product?ProductId=' + productId);
	}

	async generate(request: GenerateProductVariants) {
		return await this.postData('generate', request);
	}

	async create(request: CreateProductVariantsRequest) {
		return await this.postData('create', request, true);
	}

	async update(request: UpdateProductVariantsRequest) {
		return await this.putData('update', request, true);
	}
}

export default new ProductVariantsService();
