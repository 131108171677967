import {Dialog} from "primereact/dialog";
import styles from "../Dtfs.module.scss";
import {Link} from "react-router-dom";
import dateHelper from "../../../../helpers/dateHelper";
import React, {useState} from "react";
import {DtfDetails} from "../../../../features/dtfs/dtos/dtf-for-list.dto";
import dtfService from "../../../../features/dtfs/_common/dtf.service";
import {
    SearchDtfListByOrderCodeRequest
} from "../../../../features/dtfs/_common/search-dtf-list-by-order-code/search-dtf-list-by-order-code-request";
import {ProgressSpinner} from "primereact/progressspinner";

type Props = {
    query: string;
    visible: boolean;
    onHide: () => void;
}

const DtfSearchDialog = ({query, visible, onHide}: Props) => {
    const [dtfs, setDtfs] = useState<DtfDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getDtfs = async () => {
        try {
            setLoading(true);

            const request = new SearchDtfListByOrderCodeRequest(query);
            const response = await dtfService.searchDtfListByOrderCode(request);

            if (!response.isSuccess || !response.data) throw '';

            setDtfs(response.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const dispose = () => {
        setLoading(false);
        setDtfs([]);
    };

    return (
        <Dialog onShow={() => getDtfs()} visible={visible} header={`Results for ${query}`} onHide={() => {
            dispose();
            onHide();
        }}>
            <section>
                <div className={styles.dtfsTableWrapper}>
                    <div className={styles.dtfsTableHeader}>
                        <div className={styles.dtfsTableHeaderTitles}>
                            <span className={styles.dtfsTableHeaderTitlesName}>Name</span>
                            <span className={styles.dtfsTableHeaderTitlesStatus}>Status</span>
                            <span className={styles.dtfsTableHeaderTitlesDate}>
								Created Date
							</span>
                        </div>
                    </div>
                </div>
                <div className={styles.dtfsTableBody}>
                    {!loading ? dtfs.length > 0 ? (
                        dtfs.map((_dtf) => (
                            <Link to={_dtf.id} key={_dtf.id} className={`${styles.dtfsTableBodyItemWrapper}`}>
                                <div className="px-4">
                                    <div className={styles.dtfsTableBodyWrapper}>
                                        <div className={styles.dtfsTableBodyItems}>

                                            <div className={styles.dtfsTableBodyItemName}>
                                                    <span
                                                        className={styles.dtfsTableBodyItemNameTitle}>{_dtf.name}</span>
                                            </div>
                                            <div className={`${styles.dtfsTableBodyItemStatus}`}>
                                                <span>{_dtf.dtfListStatusName}</span>
                                            </div>
                                            <div className={styles.dtfsTableBodyItemDate}>
                                                <span>Created at &#x2022; {dateHelper.formatDate(_dtf.createdDate)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className={`${styles.dtfsTableBodyItemWrapper} ${styles.loadMore}`}>
                            <p>DTF including order with the code <span className="text-red-500">{query}</span> not found.</p>
                        </div>
                    ) : (<div className="flex justify-content-center align-items-center w-full h-4rem p-1"><ProgressSpinner
                        className="absolute top-50 left-50"
                        style={{width: '50px', height: '50px', transform: 'translate(-50%, -50%)'}} strokeWidth="8"
                        fill="var(--surface-ground)" animationDuration=".5s"/></div>)}
                </div>
            </section>
        </Dialog>
    );
};

export default DtfSearchDialog;