export class ReadyForShippingFromBarcodeRequest {
	barcode: string;
	onlyPrintModeActive: boolean;

	constructor(barcode: string, onlyPrintModeActive: boolean) {
		this.barcode = barcode;
		this.onlyPrintModeActive = onlyPrintModeActive;
	}

	get getConvertUriExtension() {
		return `ready-for-shipping/${this.barcode && this.barcode + '/'}${this.onlyPrintModeActive}`;
	}
}
