export class GetProductFiltersRequest {
	categoryId: string | undefined;
	brandId: string | undefined;

	constructor(props: Partial<GetProductFiltersRequest>) {
		this.categoryId = props.categoryId;
		this.brandId = props.brandId;
	}

	get getConvertUriExtension() {
		return `get-products-filters`;
	}
}
