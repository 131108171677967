import HttpBaseService from 'services/_common/http-base-service';
import { GetTemplateProductsForListRequest } from './get-template-products-for-list/get-template-products-for-list.request';
import { GetTemplateProductsForListResponse } from './get-template-products-for-list/get-template-products-for-list.response';
import { SoftDeleteTemplateProductRequest } from './soft-delete-template-products/soft-delete-template-products.request';

class TemplateProductService extends HttpBaseService {
	constructor() {
		super('template-products');
	}

	async getTemplateProducts(request: GetTemplateProductsForListRequest): Promise<GetTemplateProductsForListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async softDelete(request: SoftDeleteTemplateProductRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension);
	}
}

export default new TemplateProductService();
