import { AddRangeOrderstoDtfListRequest } from 'features/dtfs/_common/add-range-orders-to-dtf-list/add-range-orders-to-dtf-list.request';
import dtfService from 'features/dtfs/_common/dtf.service';
import { GetAllDtfListsRequest } from 'features/dtfs/_common/get-all-dtf-lists/get-all-dtf-lists.request';
import { DtfDetails } from 'features/dtfs/dtos/dtf-for-list.dto';
import { OrderForListNewDto } from 'features/orders/dtos/order-for-list-new.dto';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

type props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	selectedOrders: OrderForListNewDto[];
};

const SendtoExistingDtf = ({ visible, setVisible, selectedOrders }: props) => {
	const [dtfs, setDtfs] = useState<DtfDetails[]>([]);
	const [dtfOptions, setDtfOptions] = useState<PrDropdownType[]>([]);
	const [selectedDtf, setSelectedDtf] = useState<PrDropdownType>();
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const getDtfs = async () => {
		try {
			setLoading(true);

			const request = new GetAllDtfListsRequest({
				pagination: {
					pageNumber: 1,
					itemCount: null,
					orderBy: 1,
					first: 0
				},
				dtfListStatusType: 2
			});

			const response = await dtfService.getAllDtfLists(request);

			if (!response.isSuccess) throw '';

			const options = response?.data?.map((_dtf) => ({ label: _dtf.name, value: _dtf.id }));

			if (!options) throw '';

			setDtfs(response.data || []);
			setDtfOptions(options);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const send = async() => {
		try {
			setLoading(true)

			const request = new AddRangeOrderstoDtfListRequest({ dtfListId: selectedDtf?.value.toString(), orderIds: selectedOrders.map(_order => _order.orderId) });

			const response = await dtfService.addRangeOrderstoDtfList(request);

			if (!response.isSuccess) throw '';

			setSelectedDtf(undefined)
			setVisible(false)
			//	navigate('/dtfs');
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!visible) return;

		getDtfs()
	},[visible])

	return (
		<Dialog header="Add Orders to Existing DTF" visible={visible} onHide={() => setVisible(false)} className="max-w-30rem h-22rem">
			<div>
				<div className="h-13rem">
					<PrDropdown options={dtfOptions} value={selectedDtf} filter onChange={(e) => setSelectedDtf(e)} disabled={loading} placeholder="Select Dtf" />
					<div className='mt-4'>
						<b>{selectedOrders.length}</b> orders will be added to dtf.
					</div>
					<div className='mt-2'>Orders can only be added to DTFs that are in the preparation phase.</div>
				</div>

				<div className="flex justify-content-end">
					<PrButton text="Send" onClick={send} loading={loading} icon={<span className="pi pi-fw pi-send" />} />
				</div>
			</div>
		</Dialog>
	);
};

export default SendtoExistingDtf