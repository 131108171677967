export class GetExternalOrderByIdRequest {
	private orderId: string = '';

	constructor(orderId: string) {
		this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return `get-external-order-by-id?Id=${this.orderId}`;
	}
}
