export class CreateGroupRequest {
	name: string;
	description: string;
	isActive: boolean;

	constructor(props: Partial<CreateGroupRequest>) {
		this.name = props.name || '';
		this.description = props.description || '';
		this.isActive = props.isActive || true;
	}

	get getConvertUriExtension() {
		return 'create-group';
	}
}
