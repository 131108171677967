import { ConfirmSideWithDesign, SideWithDesignDialog, SideDesignDialog } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { CreateOrderContext, CreateOrderContextType, orderSteps } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import { CreateManuelOrderItemRequestDto, CreateManuelOrderItemSideRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import currencyHelper from 'helpers/curreny.helper';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { HiDuplicate, HiOutlineTrash } from 'react-icons/hi';
import { FiEdit3 } from 'react-icons/fi';
import { v4 as UUID } from 'uuid';
import styles from './ManuelOrderProductsTable.module.scss';
import htmlToImageHelper from 'helpers/html-to-image.helper';
import stringHelper from 'helpers/string.helper';
import { ProductPrintSide } from 'features/products/derived-features/product-print-sides/get-product-print-sides/get-product-print-sides.response';
import { SelectPrintramProductDialogNew } from 'components/_common/modals/select-printram-product-new/SelectPrintramProductDialogNew';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import Cookies from 'js-cookie';

export const ManuelOrderProductsTable = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;
	const [selectedProduct, setSelectedProduct] = useState<CoreProductSynchronizeDto | undefined>(undefined);
	const [selectedConfirmSideWithDesign, setSelectedConfirmSideWithDesign] = useState<ConfirmSideWithDesign | undefined>(undefined);
	const [isEditVisible, setIsEditVisible] = useState<boolean>(false);
	const [sideWithDesignDialogVisible, setSideWithDesignDialogVisible] = useState<boolean>(false);
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);

	const steps = [
		{
			element: '.tutorial-selector1',
			intro: 'If you want to edit the product, press "Edit" to make changes.'
		},
		{
			element: '.tutorial-selector2',
			intro: 'If you want to duplicate the prepared product, press "Duplicate" to create a copy.'
		},
		{
			element: '.tutorial-selector3',
			intro: 'If you need to order multiple items of the same design, size, and color, you can change the quantity from this section.'
		}
	];

	useEffect(() => {
		if (context.orderStep.value !== orderSteps[0].value || context.confirmSideWithDesigns.length === 0 || Cookies.get('product-table') === 'true') return;

		setTutorialStepsEnabled(true);
	}, [context.confirmSideWithDesigns.length]);

	return (
		<React.Fragment>
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'product-table', disableInteraction: false }} /> */}

			<table className={styles.table}>
				<thead>
					<tr>
						<th>Product</th>
						<th>Print File</th>
						<th>QTY</th>
						<th>Retail Each</th>
						<th>Total Price</th>

						<th className={styles.action}>
							<FaChevronDown size={20} />
						</th>
					</tr>
				</thead>

				<tbody>
					{context.confirmSideWithDesigns.map((_side, index) => (
						<tr key={index}>
							<td>
								<div className={styles.productInfo}>
									<div className={styles.productImgContainer}>
										<img src={amazonS3Helper.showFile(_side.variant.medias[0], { resize: '100x100' })} alt={_side.product.productName} width={80} />
									</div>

									<div className={styles.productInfoContainer}>
										<h6>{_side.product.productName}</h6>
										<span>
											Model: {_side.product.model.brand.name} {_side.product.model.name}
										</span>

										<span>Size: {_side.size.shortName}</span>

										<span className={styles.productInfoContainerColor}>
											<Tooltip target={`#color-${index}-${_side.color.id}`} />
											Color:
											<span
												id={`color-${index}-${_side.color.id}`}
												data-pr-tooltip={_side.color.hexCode}
												data-pr-position="top"
												style={{
													backgroundColor: _side.color.hexCode,
													backgroundImage: `url("${_side.color.imageUrl}")`,
													backgroundRepeat: 'no-repeat',
													backgroundSize: 'cover'
												}}
											/>
											{_side.color.name}
										</span>

										<span className="flex gap-3 align-items-center text-primary mt-2">
											{context.orderStep.value === orderSteps[0].value ? (
												<span
													role="button"
													className="text-primary cursor-pointer underline tutorial-selector1"
													tabIndex={0}
													onClick={() => {
														setSelectedConfirmSideWithDesign(structuredClone(_side));
														setIsEditVisible(true);
													}}>
													Edit
												</span>
											) : null}

											{context.orderStep.value === orderSteps[0].value ? (
												<span
													role="button"
													tabIndex={0}
													onClick={() => {
														const request = structuredClone(context.request);
														const sides = structuredClone(context.confirmSideWithDesigns);

														const item = structuredClone(request.items.find((_item: CreateManuelOrderItemRequestDto) => _item.uniqueId === _side.uniqueId));
														const side = structuredClone(sides.find((_item: ConfirmSideWithDesign) => _item.uniqueId === _side.uniqueId));

														if (!item || !side) return;

														const uuid = UUID();

														item.uniqueId = uuid;
														side.uniqueId = uuid;
														item.quantity = 1;

														request.items.push(item);
														sides.push(side);

														context.setRequest(request);
														context.setConfirmSideWithDesigns(sides);
													}}
													className="flex gap-1 align-items-center cursor-pointer underline tutorial-selector2">
													<HiDuplicate size={18} />
													Duplicate
												</span>
											) : null}
										</span>
									</div>
								</div>
							</td>

							<td>
								<div className={styles.printFileWrapper}>
									{_side.sides.map((_innerSide, innerSideIndex) => (
										<React.Fragment key={innerSideIndex}>
											<div className={styles.printFileItem}>
												<div className={styles.printFileItemImgContainer}>
													<img src={URL.createObjectURL(_innerSide.designFile!)} alt={_side.product.productName} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
												</div>

												<div className={styles.printFileItemInfo}>
													<span>{_innerSide.name} Side Print File</span>
												</div>
											</div>

											<div className={styles.printFileItem}>
												<div className={styles.printFileItemImgContainer}>
													<img src={_innerSide.mockupBase64} alt={_side.product.productName} />
												</div>

												<div className={styles.printFileItemInfo}>
													<span>{_innerSide.name} Side Mockup</span>
												</div>
											</div>
										</React.Fragment>
									))}
								</div>
							</td>

							<td>
								{context.orderStep.value === orderSteps[0].value ? (
									<PrTextInput
										type="number"
										value={context.request.items.find((_item) => _item.uniqueId === _side.uniqueId)?.quantity || 1}
										onChange={(e) => {
											const value = !e.target.value || +e.target.value < 1 ? 1 : +e.target.value;

											const request = structuredClone(context.request);

											for (const item of request.items) {
												if (item.uniqueId !== _side.uniqueId) continue;

												item.quantity = value;

												break;
											}

											context.setRequest(request);
										}}
										className="max-w-5rem tutorial-selector3"
									/>
								) : (
									<span style={{ lineHeight: '2.5rem', display: 'inline-block' }}>{context.request.items.find((_item) => _item.uniqueId === _side.uniqueId)?.quantity}</span>
								)}
							</td>

							<td>
								<PrPriceInput value={context?.calculatedPrice?.items.find((_item) => _item.uniqueIdentifier === _side.uniqueId)?.pricePerEach.formattedPricePerUnit || 0} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} />
							</td>

							<td>
								<span className="font-bold" style={{ lineHeight: '2.5rem', display: 'inline-block' }}>
									{currencyHelper.formatPrice(context?.calculatedPrice?.items.find((_item) => _item.uniqueIdentifier === _side.uniqueId)?.totalPrice.formattedPricePerUnit || 0)}
								</span>
							</td>

							<td className="text-center">
								{context.orderStep.value === orderSteps[0].value ? (
									<div
										tabIndex={0}
										role="button"
										onClick={() => {
											const request = structuredClone(context.request);
											const sides = structuredClone(context.confirmSideWithDesigns).filter((_contextSide: ConfirmSideWithDesign) => _contextSide.uniqueId !== _side.uniqueId);

											request.items = request.items.filter((_item: CreateManuelOrderItemRequestDto) => _item.uniqueId !== _side.uniqueId);

											context.setRequest(request);
											context.setConfirmSideWithDesigns(sides);
										}}
										className="flex flex-column text-400 align-items-center mt-2 cursor-pointer text-400 hover:text-700">
										<HiOutlineTrash />

										<span style={{ fontSize: '.5rem' }}>Delete</span>
									</div>
								) : null}

								{context.orderStep.value === orderSteps[2].value ? (
									<div tabIndex={0} role="button" onClick={() => context.setOrderStep(orderSteps[0])} className="flex flex-column text-400 align-items-center mt-2 cursor-pointer text-400 hover:text-700">
										<FiEdit3 />

										<span style={{ fontSize: '.5rem' }}>Edit</span>
									</div>
								) : null}
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<SelectPrintramProductDialogNew
				visible={isEditVisible}
				setVisible={setIsEditVisible}
				productId={!!selectedProduct ? undefined : context.coreProductIdFromUrl}
				handleProductClick={(_product) => {
					setSelectedProduct(_product);

					if (!sideWithDesignDialogVisible) setSideWithDesignDialogVisible(true);
				}}
			/>

			{!!selectedConfirmSideWithDesign ? (
				<SideWithDesignDialog
					visible={sideWithDesignDialogVisible}
					setVisible={setSideWithDesignDialogVisible}
					editProduct={selectedConfirmSideWithDesign}
					// isEditSide={{ color: selectedConfirmSideWithDesign.color, size: selectedConfirmSideWithDesign.size, uniqueId: selectedConfirmSideWithDesign.uniqueId }}
					product={isEditVisible ? undefined : selectedProduct}
					onChangeProduct={() => {
						setIsEditVisible(true);
					}}
					onConfirm={async (e) => {
						context.setLoading(true);

						context.setConfirmSideWithDesigns((_currentData) =>
							structuredClone(_currentData).map((_data: ConfirmSideWithDesign) => {
								if (_data.uniqueId === e.uniqueId) return e;

								return _data;
							})
						);

						context.setRequest((_request) => {
							const clonedRequest = structuredClone(_request);

							clonedRequest.items = clonedRequest.items.map((_item: CreateManuelOrderItemRequestDto) => {
								if (_item.uniqueId !== e.uniqueId) return _item;

								console.log(e.variant.id);

								_item.productVariantId = e.variant.id;
								_item.sides = e.sides.map(
									(_eventSide) =>
										new CreateManuelOrderItemSideRequestDto({
											productPrintSideId: _eventSide.id,
											designFile: _eventSide.designFile,
											templateMockupFile: htmlToImageHelper.dataUrlToFile(_eventSide.mockupBase64!, `${stringHelper.slugify(_eventSide.name)}-mockup.png`),
											printWidth: _eventSide.printInfo?.printWidth,
											printHeight: _eventSide.printInfo?.printHeight
										})
								);

								return _item;
							});

							//	console.clear();

							return clonedRequest;
						});

						setSelectedConfirmSideWithDesign(undefined);

						context.setLoading(false);
					}}
				/>
			) : null}
		</React.Fragment>
	);
};
