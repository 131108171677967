import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataView } from 'primereact/dataview';
import { Skeleton } from 'primereact/skeleton';
import styles from './ProductGridList.module.scss';
import { GetProductsForListRequest } from 'features/products/derived-features/merchant-products/get-products-for-list/get-products-for-list.request';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import { GetProductsForListResponse } from 'features/products/derived-features/merchant-products/get-products-for-list/get-products-for-list.response';
import mediaHelper from 'helpers/media.helper';
import currencyHelper from 'helpers/curreny.helper';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';

const ProductGridList = () => {
	const navigate = useNavigate();
	const [products, setProducts] = useState<GetProductsForListResponse[] | []>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const { categoryId } = useParams();

	const getProducts = useCallback(async () => {
		try {
			setLoading(true);

			const request = new GetProductsForListRequest({ categoryId });

			const response = await merchantProductsService.getProductsForList(request);

			if (!response.isSuccess) throw '';

			setProducts(response.data || []);
		} finally {
			setLoading(false);
		}
	}, [categoryId]);

	useEffect(() => {
		if (!categoryId) return;

		getProducts();
	}, [categoryId, getProducts]);

	const renderGridItems = (product: GetProductsForListResponse) => {
		return (
			<div className="col-12 md:col-4 xl:col-3 mb-3">
				<div className={`${styles.productGridItem} card`} style={{ '--stock-opacity': product.isInStock ? 1 : 0.3 } as CSSProperties}>
					<div className={styles.productGridItemTop}>
						{!!product.categories && product.categories.length > 0 ? (
							<div>
								<i className={`pi pi-tag ${styles.productCategoryIcon}`}></i>
								<span className={styles.productCategory}>{(product.categories as string[]).join(' | ')}</span>
							</div>
						) : null}
					</div>
					<div className={styles.productGridItemContent} onClick={() => navigate('/product/detail/' + product.id)}>
						<div className={styles.imgContainer}>
							<img src={product.media ? amazonS3Helper.showFile(product.media, { resize: '252x383' }) : 'https://via.placeholder.com/200'} alt={product.name} />
							<div className={`${styles.productDescription} ${product.isCustomizable ? styles.customizable : styles.notCustomizable}`}>{product.isCustomizable ? 'Customizable' : 'Not Customizable'}</div>
						</div>
						{!product.isInStock && (
							<div className={styles.outOfStock}>
								<span className={styles.outOfStockLabel}>Out of stock</span>
							</div>
						)}
						<div className={styles.productName}>{product.name}</div>

						<span className="mt-2 font-bold">
							{product.brand} {product.model}
						</span>
					</div>
					<div className={styles.productGridItemBottom}>
						<span className={styles.productPrice}>From {currencyHelper.formatPrice(product.minPrice.formattedPricePerUnit)}</span>

						<span className={styles.productDiscountPrice}>
							From {currencyHelper.formatPrice(product.minDiscountedPrice.price.formattedPricePerUnit)} {product.minDiscountedPrice.planName} plan
						</span>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="grid">
			<div className="col-12">
				<div className="card">
					<h5>Product List</h5>

					{!loading ? (
						<DataView value={products} layout={'grid'} itemTemplate={renderGridItems} paginator rows={12} />
					) : (
						<div className="grid">
							<div className="col-3">
								<Skeleton height="21rem" />
							</div>
							<div className="col-3">
								<Skeleton height="21rem" />
							</div>
							<div className="col-3">
								<Skeleton height="21rem" />
							</div>
							<div className="col-3">
								<Skeleton height="21rem" />
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductGridList;
