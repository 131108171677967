import { CreateModelDto } from "./dtos/create-model.dto";

export class AssignToPrintCentersRequest {
	createModels: CreateModelDto[];

	get getConvertUriExtension() {
		return `assign-to-print-centers`;
	}

	constructor(createModels: CreateModelDto[]) {
		this.createModels = createModels;
	}
}