export class GetShippoShipmentsRequest {
	private orderId: string;

	constructor(orderId: string) {
		this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return `get-shippo-shipments/${this.orderId}`;
	}
}
