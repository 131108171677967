import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { MdDone } from 'react-icons/md';

type Props = {
	completed: boolean;
	step: string;
	title: string;
	text: string;
	action: () => void | null;
	actionText: string;
	image: string;
	className?: string;
};

const PrizeItem = ({ completed, step, title, text, action, actionText, image, className }: Props) => {
	return (
		<div className={className} style={{backgroundColor: completed ? '#EBF0FF' : 'transparent'}}>
			<img src={image} alt={'connection illustration'} className="mx-auto mb-3" />
			<p className="m-0 text-sm">Step {step}</p>
			<h5 className="my-2 font-semibold">{title}</h5>
			<p className="m-0 text-sm line-height-2">{text}</p>
			<div className={"w-full mt-auto px-2"}>
				{!completed ? (
					<PrButton className="justify-content-center mt-auto w-full border-round-lg" text={actionText} onClick={() => action()}/>
				) : (
					<div className="flex flex-row justify-content-start align-items-center mt-auto">
						<div className="flex justify-content-center border-circle p-2" style={{ backgroundColor: '#4541FF' }}>
							<MdDone size="24px" color="white" />
						</div>
						<div className="ml-2 font-semibold">Completed</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PrizeItem;
