const fromAllData = <T>(objectArray: T[], filter: string) => [...objectArray].filter((data) => JSON.stringify(data).toLowerCase().indexOf(filter.toLowerCase()) !== -1);

const fromFilteredData = (objectArray: any[], filteredObject: any[], filter: string) => {
	if (!filter) return objectArray;

	const searchValues = [...filteredObject].filter((data) => JSON.stringify(data).toLowerCase().indexOf(filter.toLowerCase()) !== -1);

	const result = objectArray.filter((_object) => {
		return searchValues.some((_search) => {
			return _search.id === _object.id
		});
	});

	return result;
}

const onlyCharactersSpaceAndHypen = (value: string): string => value.replace(/[^A-Za-z\s-]*$/, '');

const filterHelper = { fromAllData, fromFilteredData, onlyCharactersSpaceAndHypen };

export default filterHelper;
