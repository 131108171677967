export class GetOrderForOperationRequest {
	private contains: string;

	constructor(contains: string) {
		this.contains = contains;
	}

	get getConvertUriExtension() {
		return `get-for-operation/${this.contains}`;
	}
}
