import { JwtKey } from '../models/_commons/dtos/parsed-jtw';

const parseJwt = (token: string) => {
    if (!token) return token;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );

    return JSON.parse(jsonPayload);
};

const getReadableRoles = (token: string) => {
    if (!token) return [] as string[];

    const decryptedToken = jwtParserHelper.parseJwt(token);

    return decryptedToken[JwtKey.ReadableRoles] as string[] | string;
};

const expireDate = (token: string) => {
    if (!token) return new Date();

    const decryptedToken = jwtParserHelper.parseJwt(token);

    const date = new Date((decryptedToken[JwtKey.ExpireDate] as number) * 1000);

    return date;
};

const jwtParserHelper = { parseJwt, getReadableRoles, expireDate };

export default jwtParserHelper;
