import orderService from 'features/orders/_common/order.service';
import { ReadyForShippingRequest } from 'features/orders/_common/ready-for-shipping/ready-for-shipping.request';
import { Form, Formik } from 'formik';
import audioHelper from 'helpers/audio.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';

const ReadBarcode = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const focusInput = () => {
		const input = document.querySelector('input');
		input?.focus();
	};

	const showErrorDialog = (error: any, barcode: string) => {
		confirmDialog({
			header: 'Status not updated!',
			message: error.response?.data?.message.toString() || '',
			icon: <IoWarningOutline size={25} />,
			rejectClassName: 'hidden',
			footer: (options) => {
				return (
					<div className="flex align-items-center justify-content-end gap-3">
						<span
							className="text-primary underline cursor-pointer font-bold"
							onClick={async () => {
								try {
									setLoading(true);
									options.reject();

									const response = await orderService.setReadyForShipping(new ReadyForShippingRequest(barcode, true));
									if (!response.isSuccess) throw '';
								} catch (error) {
									audioHelper.playAudio();
								} finally {
									setLoading(false);
								}
							}}>
							Get Ship Label
						</span>

						<PrButton text="Ok" btnType="button" onClick={() => options.accept()} />
					</div>
				);
			},
			className: 'max-w-30rem',
			accept: focusInput,
			reject: focusInput
		});
	};

	return (
		<Formik
			initialValues={{ barcode: '' }}
			onSubmit={async (values, formikHelpers) => {
				if (!values.barcode) return;

				const oldValue: { barcode: string } = JSON.parse(JSON.stringify(values));

				try {
					setLoading(true);

					const response = await orderService.setReadyForShipping(new ReadyForShippingRequest(values.barcode, false));
					if (!response.isSuccess) throw '';

					focusInput();
				} catch (error) {
					audioHelper.playAudio();
					showErrorDialog(error as any, oldValue.barcode);
				} finally {
					formikHelpers.resetForm();
					setLoading(false);
				}
			}}>
			{(form) => (
				<Form className="container-body static p-3" autoComplete="off">
					<PrTextInput label="Scan/Enter Barcode" readOnly={loading} name="barcode" minLength={2} autoFocus value={form.values.barcode} onChange={form.handleChange} required placeholder="Scan or Enter barcode to update order status" />

					<div className="flex justify-content-end mt-3">
						<PrButton btnType="submit" text="Update Status" />
					</div>

					{loading ? (
						<div className="pr-loading-sticky">
							<div className="pr-spinner-wrapper">
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						</div>
					) : null}
				</Form>
			)}
		</Formik>
	);
};

export default ReadBarcode;
