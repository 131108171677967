export class GetEtsyShippingProfilesByShopIdRequest {
	userId: string;
	shopId: number;

	constructor(props: Partial<GetEtsyShippingProfilesByShopIdRequest>) {
		this.userId = props.userId || '';
		this.shopId = props.shopId || 0;
	}

	get getConvertUriExtension() {
		return `get-etsy-shipping-profiles-by-shop-id?UserId=${this.userId}&ShopId=${this.shopId}`;
	}
}
