import { CalculateItems, CalculateOrderPricesWithVariantsAndSidesRequest } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import orderService from 'features/orders/_common/order.service';
import currencyHelper from 'helpers/curreny.helper';
import { RadioButton } from 'primereact/radiobutton';
import style from './ShippingRates.module.scss';
import React from 'react';
import { SyncOrderContext, SyncOrderContextType } from 'components/pages/orders/sync-order/context/SyncOrderContextProvider';
import { SyncEtsyProduct } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/sync-etsy-product.dto';

const ShippingRates = () => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	return (
		<div>
			<div className={style.warningMessage}>If the cargo company calculates a different weight for the order, the difference will be charged.</div>

			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping method
			</h2>
			<div className={style.ratesWrapper}>
				{context.shippingRates.map((_rate, index) => (
					<div key={index} className="field-radiobutton">
						<RadioButton
							inputId={`rate${_rate.rateId || _rate.serviceName}`}
							value={_rate.rateId || _rate.serviceName}
							name="rate"
							onChange={async () => {
								try {
									context.setLoading(true);

									if (!context.request.createModel.shipTo) return;

									const items: CalculateItems[] = context.request.createModel.products.map((_product: SyncEtsyProduct) => ({ uniqueIdentifier: _product.externalProductId, variantId: _product.productVariantId, sideIdentities: _product.sides.map((_side) => _side.productPrintSideId), quantity: _product.quantity }));

									const rateRequest = new CalculateOrderPricesWithVariantsAndSidesRequest({
										shippingRate: _rate,
										shipTo: structuredClone(context.request.createModel.shipTo),
										items: items.filter((_item) => _item.sideIdentities.length > 0)
									});

									if (!!rateRequest?.shipTo?.receiverPhoneNumber) rateRequest.shipTo.receiverPhoneNumber = rateRequest.shipTo.phonePrefix.value + rateRequest.shipTo.receiverPhoneNumber;
									if (rateRequest.shipTo?.receiverPhoneNumber === '') rateRequest.shipTo.receiverPhoneNumber = null;

									const response = await orderService.calculateOrderPricesWithVariantsAndSidesResponse(rateRequest);
									if (!response.data || !response.isSuccess) throw '';

									context.setSelectedRate(_rate);
									context.setCalculatedPrice(response.data);
								} catch (error) {
								} finally {
									context.setLoading(false);
								}
							}}
							checked={context.selectedRate?.rateId ? _rate.rateId === context.selectedRate?.rateId : _rate.serviceName === context.selectedRate?.serviceName}
						/>

						<label htmlFor={`rate${_rate.rateId || _rate.serviceName}`}>
							{currencyHelper.formatPrice(+_rate.amount)} - {_rate.serviceName}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default ShippingRates;
