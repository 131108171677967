export class UpdateSellerStoreRequest {
	id: string;
	sellerId: string;
	name: string;

	constructor(props: Partial<UpdateSellerStoreRequest>) {
		this.id = props.id || '';
		this.sellerId = props.sellerId || '';
		this.name = props.name || '';
	}

	get getConvertUriExtension() {
		return `update`;
	}
}
