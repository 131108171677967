export class GetProductInformationsRequest {
	id: string;

	constructor(id: string) {
		this.id = id;
	}

	get getConvertUriExtension() {
		return `get-products-information`;
	}
}
