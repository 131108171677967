import HttpBaseService from 'services/_common/http-base-service';
import { GetCountriesRequest } from './get-countries/get-countries.request';
import { GetCountriesResponse } from './get-countries/get-countries.response';
import { GetStatesRequest } from './get-states/get-states.request';
import { GetStatesResponse } from './get-states/get-states.response';
import { GetTimezonesRequest } from './get-timezones/get-timezones.request';
import { GetTimezonesResponse } from './get-timezones/get-timezones.response';

class CountryServive extends HttpBaseService {
	constructor() {
		super('countries');
	}

	async getCountries(request: GetCountriesRequest): Promise<GetCountriesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getStates(request: GetStatesRequest): Promise<GetStatesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getTimezones(request: GetTimezonesRequest): Promise<GetTimezonesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new CountryServive();
