import HttpBaseService from 'services/_common/http-base-service';
import { CompleteReorderByAdminRequest } from './complete-reorder-by-admin/complete-reorder-by-admin.request';
import { CompleteReorderByAdminResponse } from './complete-reorder-by-admin/complete-reorder-by-admin.response';
import { CreateReorderCompleteRequest } from './create-reorder-complete/create-reorder-complete.request';
import { CreateReorderCompleteResponse } from './create-reorder-complete/create-reorder-complete.response';
import { CreateReorderRequest } from './create-reorder/create-reorder.request';
import { CreateReorderResponse } from './create-reorder/create-reorder.response';
import { GetReorderDetailForSellerApproveRequest } from './get-reorder-detail-for-seller-approve/get-reorder-detail-for-seller-approve.request';
import { GetReorderDetailForSellerApproveResponse } from './get-reorder-detail-for-seller-approve/get-reorder-detail-for-seller-approve.response';
import { GetReorderDetailRequest } from './get-reorder-detail/get-reorder-detail.request';
import { GetReorderDetailResponse } from './get-reorder-detail/get-reorder-detail.response';
import { GetReorderListForSellerRequest } from './get-reorder-list-for-seller/get-reorder-list-for-seller.request';
import { GetReorderListForSellerResponse } from './get-reorder-list-for-seller/get-reorder-list-for-seller.response';
import { GetReorderProgressStatusRequest } from './get-reorder-progress-status/get-reorder-progress-status.request';
import { GetReorderProgressStatusResponse } from './get-reorder-progress-status/get-reorder-progress-status.response';
import { GetCreateReOrderDatasRequest } from './get-create-reorder-datas/get-create-reorder-datas.request';
import { GetCreateReOrderDatasResponse } from './get-create-reorder-datas/get-create-reorder-datas.response';
import { SaveReorderAddressRequest } from './save-address/save-address.request';
import { SaveReorderAddressResponse } from './save-address/save-address.response';
import { GetReorderListRequest } from './get-reorder-list/get-reorder-list.request';
import { GetReorderListResponse } from './get-reorder-list/get-reorder-list.response';
import { SaveAdminReviewsToReorderRequest } from './save-admin-reviews-to-reorder/save-admin-reviews-to-reorder.request';
import { SaveAdminReviewsToReorderResponse } from './save-admin-reviews-to-reorder/save-admin-reviews-to-reorder.response';
import { GetShippingRatesForReorderRequest } from './get-shipping-rates/get-shipping-rates.request';
import { GetShippingRatesForReorderResponse } from './get-shipping-rates/get-shipping-rates.response';
import { ReorderChargeRequest } from './charge/charge.request';
import { ReorderChargeResponse } from './charge/charge.response';
import { ReorderCalculateRequest } from './calculate/re-order-calculate.request';
import { ReorderCalculateResponse } from './calculate/re-order-calculate.response';

class ReorderService extends HttpBaseService {
	constructor() {
		super('reorders');
	}

	async getCreateReorderDatas(request: GetCreateReOrderDatasRequest): Promise<GetCreateReOrderDatasResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async saveReorderAddress(request: SaveReorderAddressRequest): Promise<SaveReorderAddressResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}

	async create(request: CreateReorderRequest): Promise<CreateReorderResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async complete(request: CreateReorderCompleteRequest, uploadAction: (state: ProgressEvent<EventTarget>) => void): Promise<CreateReorderCompleteResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}

	async completeByAdmin(request: CompleteReorderByAdminRequest): Promise<CompleteReorderByAdminResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async saveAdminReviewstoReorder(request: SaveAdminReviewsToReorderRequest): Promise<SaveAdminReviewsToReorderResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getForSeller(request: GetReorderListForSellerRequest): Promise<GetReorderListForSellerResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getProgressStatus(request: GetReorderProgressStatusRequest): Promise<GetReorderProgressStatusResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getReorderDetail(request: GetReorderDetailRequest): Promise<GetReorderDetailResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getReorderDetailForSellerApprove(request: GetReorderDetailForSellerApproveRequest): Promise<GetReorderDetailForSellerApproveResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getShippingRates(request: GetShippingRatesForReorderRequest): Promise<GetShippingRatesForReorderResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getReorderList(request: GetReorderListRequest): Promise<GetReorderListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async chargeReorder(request: ReorderChargeRequest): Promise<ReorderChargeResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async calculateReorder(request: ReorderCalculateRequest): Promise<ReorderCalculateResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}
}

export const reorderService = new ReorderService();
