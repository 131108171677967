import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { UserStatuTypes } from 'features/_common/dtos/user/user-statu.enum';

export class GetMerchantListRequest {
	contains: string | null;
	statu: UserStatuTypes | null;
	pagination: PaginationDto;

	constructor(props: Partial<GetMerchantListRequest>) {
		this.contains = props.contains || null;
		this.statu = props.statu || null;
		this.pagination = props.pagination || new PaginationDto(1, 20);
	}

	get getConvertUriExtension() {
		return `get-merchant-list`;
	}
}
