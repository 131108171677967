import { PaymentTypes } from "features/orders/dtos/payment-type.dto";

export class OrderStatuHistoryShipmentTypeRequestDto {
	shippoShipmentId: string;
	shippoRateId: string;

	constructor(props: Partial<OrderStatuHistoryShipmentTypeRequestDto>) {
		this.shippoShipmentId = props.shippoShipmentId || '';
		this.shippoRateId = props.shippoRateId || '';
	}
}

export class CreateOrderStatuHistoryRequest {
	orderId: string;
	orderStatuId: number;
	reason: string;
	shipmentType: OrderStatuHistoryShipmentTypeRequestDto | null;
	paymentMethodType: PaymentTypes;

	constructor(props: Partial<CreateOrderStatuHistoryRequest>) {
		this.orderId = props.orderId || '';
		this.orderStatuId = props.orderStatuId || 0;
		this.reason = props.reason || '';
		this.shipmentType = props.shipmentType || null;
		this.paymentMethodType = props.paymentMethodType || 2;
	}

	get getConvertUriExtension() {
		return `create-order-statu-history`;
	}
}
