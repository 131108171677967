import { OrderStatuTypes } from "features/orders/dtos/order-statu-types";

export class GetOrderItemSummariesRequest {
	orderStatu: OrderStatuTypes;

	constructor(orderStatu: OrderStatuTypes) {
		this.orderStatu = orderStatu;
	}

	get getConvertUriExtension() {
		return `get-order-item-summaries/${this.orderStatu}`;
	}
}