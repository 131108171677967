import React from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextType } from '../../context/UpgradeShippingContextProvider';
import currencyHelper from '../../../../../helpers/curreny.helper';

const CostBreakdown = () => {
	const context = React.useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;

	return (
		<div className="flex align-items-center justify-content-center">
			<div className="w-max">
				<h2 className="mb-3" style={{ fontSize: '1.5rem' }}>
					Order breakdown
				</h2>

				<div className="flex justify-content-between font-bold">
					<span>Shipping</span>
					<div>
						<span className=" text-sm line-through mr-1">{currencyHelper.formatPrice(context.selectedRate!.amount)}</span>
						<span style={{color: 'green'}} className='text-lg'>{currencyHelper.formatPrice(context.selectedRate!.calculatedAmount)}</span>
					</div>
				</div>

				<hr className="my-2" />

				<h2 className="flex justify-content-between m-0" style={{ fontSize: '1.5rem' }}>
					<span>Total</span>
					<span>{currencyHelper.formatPrice(context.selectedRate!.calculatedAmount)}</span>
				</h2>
			</div>
		</div>
	);
};

export default CostBreakdown;
