import { Size } from '../../models/dtos/dimesions/size';
import { GetSizesRequest } from '../../models/requests/dimensions/sizes/get-sizes-request';
import { CreateSizeRequest } from '../../models/requests/sizes/create-size-request';
import { DataPaginationResponse } from '../../models/_commons/responses/data-pagination-response';
import HttpBaseService from '../_common/http-base-service';

class SizeService extends HttpBaseService {
	constructor() {
		super('sizes');
	}

	async getSizes(request: GetSizesRequest): Promise<DataPaginationResponse<Size[]>> {
		return await this.postData('get-sizes', request);
	}

	async create(request: CreateSizeRequest) {
		return await this.postData('create', request);
	}

	async update(request: Size) {
		return await this.putData('update', request);
	}

	async delete(id: string): Promise<boolean> {
		return await this.deleteData('soft-delete', id);
	}
}

export default new SizeService();