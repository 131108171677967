import React, { useCallback, useEffect, useState } from 'react';
import { setForAdminSellerStoreListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { SellerStoreForListDto } from 'features/seller-stores/get-seller-store-list-for-admin/dtos/seller-store-for-list.dto';
import { GetSellerStoreListForAdminRequest } from 'features/seller-stores/get-seller-store-list-for-admin/get-seller-store-list-for-admin.request';
import sellerStoresService from 'features/seller-stores/seller-stores-service';
import dateHelper from 'helpers/dateHelper';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const SellerStores = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminSellerStoreListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [stores, setStores] = useState<SellerStoreForListDto[]>([]);
	const [filter, setFilter] = useState<string | undefined>(undefined);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getSellerStores = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			if (!!pagination) dispatch(setForAdminSellerStoreListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetSellerStoreListForAdminRequest(null, filter || null, pagination);

			const response = await sellerStoresService.getSellerStoreListForAdmin(request);

			if (!response.isSuccess) throw '';

			setStores(response.data || []);
			setPaginationResponse(response.pagination);
		} finally {
			setLoading(false);
		}
	};

	const createdDateTemplate = (store: SellerStoreForListDto) => dateHelper.formatDate(store.createdDate);

	const handleFilter = () => {
		if (typeof filter === 'string' && filter.length === 0) {
			onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
			return;
		}

		if (!filter || filter.length < 3) return;

		onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
	};

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		await getSellerStores(newPagination);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		getSellerStores(newPagination);
	}, []);

	return (
		<div className="card">
			<div className="flex flex-wrap align-items-center gap-3 mt-1 mb-3">
				<h5 className="m-0">Seller Store List</h5>

				<div>
					<div className="p-inputgroup">
						<InputText placeholder="Search..." value={filter} onKeyDown={(event) => event.key === 'Enter' && handleFilter()} onChange={(event) => setFilter(event.target.value)} className="p-inputtext-sm" />
						<Button onClick={handleFilter} icon="pi pi-fw pi-search" className="p-button-sm" />
					</div>
				</div>
			</div>

			<DataTable value={stores} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading} className="p-datatable-gridlines">
				<Column field="storeName" header="Store Name" />
				<Column field="sellerFullName" header="Seller" />
				<Column field="totalOrderQuantity" header="Orders" />
				<Column field="pushedProductCount" header="Pushed Products" />
				<Column field="integratedPlatforms" header="Integrated Platforms" />
				<Column field="createdDate" header="Created Date" body={createdDateTemplate} />
			</DataTable>
		</div>
	);
};

export default SellerStores;
