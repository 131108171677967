import HttpBaseService from 'services/_common/http-base-service';
import { CreateOrUpdateMissingWeightDimensionsRequest } from './create-or-update-missing-weight-dimensions/create-or-update-missing-weight-dimensions.request';
import { CreateOrUpdateMissingWeightDimensionsResponse } from './create-or-update-missing-weight-dimensions/create-or-update-missing-weight-dimensions.response';

class ProductVariantDimensionsService extends HttpBaseService {
	constructor() {
		super('product-variant-dimensions');
	}

	async createOrUpdateMissingWeightDimensions(request: CreateOrUpdateMissingWeightDimensionsRequest): Promise<CreateOrUpdateMissingWeightDimensionsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new ProductVariantDimensionsService();
