import React, { useState } from 'react';
import { SurveyDto } from 'features/surveys/_common/survey-get/survey-get.response';
import { Dialog } from 'primereact/dialog';
import { SurveyAnswerDtoRequest, SurveyCreateAnswerRequest } from 'features/surveys/_common/survey-create-answer/survey-create-answer.request';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import toastHelper from 'helpers/toast.helper';
import surveyService from 'features/surveys/_common/survey.service';
import { ProgressSpinner } from 'primereact/progressspinner';

type Props = {
	userId: string;
	survey: SurveyDto;
	callback: () => void;
};

interface QuestionAnswer {
	questionId: string;
	answer: SurveyAnswerDtoRequest;
}

const AvailableSurveyItemDialog = (props: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [answers, setAnswers] = useState<QuestionAnswer[]>([]);

	const handleAnswer = (questionId: string, surveyQuestionOptionId: string, text?: string) => {
		const currentAnswers = [...answers];

		if (currentAnswers.find((_current) => _current.questionId === questionId)) {
			currentAnswers.forEach((_answer) => {
				if (_answer.questionId === questionId) {
					_answer.answer.surveyQuestionOptionId = surveyQuestionOptionId;
					_answer.answer.text = text || null;
				}
			});
		} else currentAnswers.push({ questionId, answer: new SurveyAnswerDtoRequest({ userId: props.userId, surveyQuestionOptionId, text }) });

		setAnswers(currentAnswers);
	};

	const isChecked = (questionId: string, optionId: string) => {
		const findedAnswer = answers.find((_answer) => _answer.questionId === questionId);

		if (!findedAnswer) return false;

		return findedAnswer.answer.surveyQuestionOptionId === optionId;
	};

	const handleOptionAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, questionId: string, optionId: string) => {
		const currentAnswers = [...answers];

		if (!currentAnswers.find((_answer) => _answer.questionId === questionId)) {
			handleAnswer(questionId, optionId, event.target.value);
			return;
		}

		currentAnswers.forEach((_answer) => {
			if (_answer.answer.surveyQuestionOptionId === optionId) {
				_answer.answer.text = event.target.value;

				return;
			}
		});

		setAnswers(currentAnswers);
	};

	const handleSubmitForm = async () => {
		try {
			setLoading(true);

			if (props.survey.surveyQuestions.length > answers.length) {
				toastHelper.warning('You should answer all question');
				throw '';
			}

			const reqAnswers = answers.map((_answer) => _answer.answer);

			reqAnswers.forEach((_answer) => {
				_answer.text = !!_answer.text && _answer.text.length > 0 ? _answer.text : null;
			});

			const response = await surveyService.createAnswer(new SurveyCreateAnswerRequest({ answers: answers.map((_answer) => _answer.answer) }));
			if (!response.isSuccess) throw '';

			props.callback();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		// <Dialog visible={props.visible} onHide={() => props.setVisible(false)} header={`Answer ${props.survey.name}`}>
		<div className="grid relative" onSubmit={handleSubmitForm}>
			{props.survey.surveyQuestions.map((_question) => (
				<div key={_question.id} className="col-12">
					<h6>{_question.question}</h6>

					<div className="grid">
						{_question.surveyQuestionOptions.map((_option) => (
							<React.Fragment key={_option.id}>
								<div className="col-12">
									<div className="field-radiobutton m-0">
										<RadioButton inputId={`option-${_option.id}`} name={`question-${_question.id}`} value={_option} onChange={(e) => handleAnswer(_question.id, _option.id)} checked={isChecked(_question.id, _option.id)} />

										<label htmlFor={`option-${_option.id}`} className="cursor-pointer">
											{_option.text}
										</label>
									</div>
								</div>

								{_option.textCanBeAdded ? (
									<div className="col-12 m-0">
										<InputText value={answers.find((_answer) => _answer.answer.surveyQuestionOptionId === _option.id)?.answer.text || ''} onChange={(e) => handleOptionAnswerChange(e, _question.id, _option.id)} placeholder="Enter answer" className="w-full" />
									</div>
								) : null}
							</React.Fragment>
						))}
					</div>
				</div>
			))}

			<div className="col-12 text-right mt-3">
				<Button onClick={handleSubmitForm} disabled={loading} type="submit" label="Save my answers" className="px-4" />
			</div>

			{loading ? (
				<div className="custom-loading">
					<ProgressSpinner className="p-progress-color" strokeWidth="4" />
				</div>
			) : null}
		</div>
		// </Dialog>
	);
};

export default AvailableSurveyItemDialog;
