import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetSellerStoresBySellerIdRequest } from '../../../features/seller-stores/get-seller-stores-by-seller-id/get-seller-stores-by-seller-id.request';
import sellerStoresService from '../../../features/seller-stores/seller-stores-service';
import { DataResponse } from '../../../models/_commons/responses/data-response';
import { SellerStore } from 'features/seller-stores/dtos/seller-store.dto';

const initialState: DataResponse<SellerStore[]> | '' = {
	data: null
};


export const getPrintramStores = createAsyncThunk('seller-stores/get-seller-stores-by-seller', async (sellerId: string) => {
	const request: GetSellerStoresBySellerIdRequest = new GetSellerStoresBySellerIdRequest();
	request.sellerId = sellerId

	return await sellerStoresService.getAllBySellerId(request)
})

export const printramStoreSlice = createSlice({
	name: 'printram-store',
	initialState,
	reducers: { resetStore: () => initialState },
	extraReducers: (builder) => {
		builder
			.addCase(getPrintramStores.fulfilled, (state, action: PayloadAction<DataResponse<any[]>>) => {
				state = action.payload;
				return state;
			})
			.addCase(getPrintramStores.rejected, (state, action: PayloadAction<any>) => {
				state = action.payload;
				return state;
			});
	}
});

export const { resetStore } = printramStoreSlice.actions;
export default printramStoreSlice.reducer;
