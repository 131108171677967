import { Button } from 'primereact/button';
import React from 'react';
import { CreateWizardContext, WizardContextType } from '../context/WizardContextProvider';
import { SurveyQuestionDto, SurveyQuestionOptionDto } from '../../../../../features/surveys/_common/survey-get/survey-get.response';
import { AnswerDto } from '../../../../../features/surveys/dtos/answer-dto';
import { PrimeIcons } from 'primereact/api';
import OptionButton from '../../Survey/components/OptionButton';

type Props = {
	question?: SurveyQuestionDto;
};

function CustomProducts({ question }: Props) {
	const context: WizardContextType = React.useContext(CreateWizardContext) as WizardContextType;

	const onSubmit = (option: SurveyQuestionOptionDto, questionId: string) => {
		if (!context.user) return;

		let newAnswers: AnswerDto[] = context.answers;

		const answer: AnswerDto = {
			surveyQuestionOptionId: option.id,
			text: null,
			userId: context.user?.userId,
			questionId: questionId
		};

		newAnswers.push(answer);

		context.setAnswers(newAnswers);

		if (option.order === 1) {
			context.setSellingCustomProducts(false);
			context.setStep(3);
		} else if (option.order === 2) {
			context.setSellingCustomProducts(true);
			context.setStep(2);
		} else {
			return;
		}
	};

	return (
		<div className="flex flex-column justify-content-center align-items-center">
			<div className="text-center">
				<h2 className="font-semibold">{question!.question}</h2>
				<p>We will use this information to customize your experience.</p>

				<div className="h-1rem mb-2">{/* {error != "" && <div className="text-sm my-auto">{error}</div>} */}</div>
			</div>
			<div className="grid w-full justify-content-center">
				{question?.surveyQuestionOptions.map((option, index: number) => {
					return <OptionButton key={index} name={option.text} disabled={context.loading} onClick={() => onSubmit(option, question.id)} />;
				})}
			</div>
			<div className="flex flex-row justify-content-center my-3">
				<Button label="Back" onClick={() => context.back()} icon={PrimeIcons.ANGLE_LEFT} className="p-button-text mr-4 text-lg" style={{ color: '#101531' }} />
			</div>
		</div>
	);
}

export default CustomProducts;