export interface DateFilterDto {
	minDate: Date | string | null;
	maxDate: Date | string | null;
}

export class ExportOperationProcessStepsRequest {
	contains: string | null;
	dateFilter: DateFilterDto;

	constructor(props: Partial<ExportOperationProcessStepsRequest>) {
		this.contains = props.contains || null;
		this.dateFilter = props.dateFilter || { minDate: null, maxDate: null };
	}

	get getConvertUriExtension() {
		return `export-operation-process-steps`;
	}
}