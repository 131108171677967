export class GetBalanceHistoryReportRequest {
	userId: string = '';

	constructor(userId: string) {
		this.userId = userId
	}

	get getConvertUriExtension() {
		return `${this.userId}/history/report`;
	}
}