import { Dialog } from 'primereact/dialog';
import React from 'react';

import styles from './PrintPreviewModal.module.scss';

enum DialogType {
	Receipts = 'Receipts',
	Details = 'Details',
	Barcode = 'Barcode',
	ReceiptWithShipLabel = 'Receipt With Ship Label',
}

export type DialogTypeString = `${DialogType}`;

type Props = {
	visible: boolean;
	setVisible: (state: boolean) => void;
	pdfUrl: string;
	dialogType: DialogTypeString;
};

const PrintPreviewModal = ({ visible, setVisible, pdfUrl, dialogType }: Props) => {
	return (
		<Dialog header={dialogType} visible={visible} onHide={() => setVisible(false)} className={styles.previewDialog}>
			<object data={pdfUrl} type="application/pdf" className={styles.pdf}>
				<div>No online PDF viewer installed</div>
			</object>
		</Dialog>
	);
};

export default PrintPreviewModal;
