import { reorderService } from 'features/reorders/_common/reorder.service';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { useContext, useEffect } from 'react';
import { SellerReorderDetailContext, SellerReorderDetailContextType, reorderSteps } from './context/SellerReorderDetailContextProvider';
import { Steps } from './steps/Steps';
import { GetReorderDetailRequest } from 'features/reorders/_common/get-reorder-detail/get-reorder-detail.request';
import { ReorderDetail } from 'features/reorders/_common/get-reorder-detail/get-reorder-detail.response';
import { ReorderCalculateRequest } from 'features/reorders/_common/calculate/re-order-calculate.request';

export const SellerReOrderDetail = () => {
	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	const calculateTotal = async (reorder: ReorderDetail) => {
		try {
			if (!reorder) throw '';

			const response = await reorderService.calculateReorder(
				new ReorderCalculateRequest({
					shippingRate: null,
					reOrderId: context.reorderIdFromRoute?.reorderId
				})
			);

			if (!response.isSuccess || !response.data) throw '';

			context.setCalculatedPrice(response.data);
		} catch (error) {
			context.setCalculatedPrice(undefined);
		}
	};

	const getDetail = async () => {
		try {
			context.setLoading(true);
			if (!context.reorderIdFromRoute) throw '';

			const request = new GetReorderDetailRequest(context.reorderIdFromRoute.reorderId);

			const response = await reorderService.getReorderDetail(request);
			if (!response.data || !response.isSuccess) throw '';

			await calculateTotal(response.data);

			context.setReorder(response.data);
		} catch (error) {
			context.setReorder(undefined);
		} finally {
			context.setLoading(false);
		}
	};

	useEffect(() => {
		if (!!context.reorderIdFromRoute?.reorderId && !context.reorder) {
			console.log(context.reorderIdFromRoute);
			getDetail();
		}
	}, [context.reorderIdFromRoute?.reorderId]);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Reorder Detail</h1>

				<div className="container-header-tools">
					{/* <GoBackButton /> */}

					{context.reorderStep.value !== 1 ? <PrButton text="Go Back" icon={<span className="pi pi-fw pi-arrow-left" />} type="secondary" onClick={() => context.setReorderStep(reorderSteps[context.reorderStep.value - 2])} /> : null}
				</div>
			</div>

			<Steps />

			{context.reorderStep.component}

			<PrLoading loading={context.loading} />
		</div>
	);
};
