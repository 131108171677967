import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { StatusOptions } from 'helpers/constants/user-status-options';
import DropdownButton from 'helpers/widgets/DropdownButton';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { GetPrintCenterListForAdminResponse } from 'features/printCenters/_common/get-print-center-list-for-admin/get-print-center-list-for-admin.response';
import { setForAdminPrintCenterListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { GetPrintCenterListForAdminRequest } from 'features/printCenters/_common/get-print-center-list-for-admin/get-print-center-list-for-admin.request';
import printCenterService from 'features/printCenters/_common/print-center.service';
import { UpdateStatuRequest } from 'features/printCenters/_common/update-statu/update-statu.request';
import { PrintCenterDto } from 'features/printCenters/_common/dtos/print-center.dto';
import PrintCenterUpdate from './components/PrintCenterUpdate';
import PrintCenterCreate from './components/PrintCenterCreate';

const PrintCenters = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminPrintCenterListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [createVisible, setCreateVisible] = useState<boolean>(false);
	const [printCenters, setPrintCenters] = useState<GetPrintCenterListForAdminResponse>([]);
	const [selectedPrintCenters, setSelectedPrintCenters] = useState<GetPrintCenterListForAdminResponse>([]);
	const [editedPrintCenter, setEditedPrintCenter] = useState('');
	const [receiverUserIdentities, setReceiverUserIdentities] = useState<Array<string> | null>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			if (!!pagination) dispatch(setForAdminPrintCenterListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetPrintCenterListForAdminRequest(pagination);

			const response = await printCenterService.getPrintCenterListForAdmin(request);

			if (!response.isSuccess) throw '';

			setPrintCenters(response.data || []);
			setPaginationResponse(response.pagination);
		} finally {
			setLoading(false);
		}
	};

	const updateStatu = async (req: UpdateStatuRequest) => {
		try {
			const request = new UpdateStatuRequest(req.printCenterId, req.userStatuId);

			const response = await printCenterService.updateStatu(request);

			if (!response) throw '';

			await getAll();
		} finally {
		}
	};

	const handlePrintCenterStatuOnChange = async (event: DropdownChangeParams, printCenterId: string) => {
		const request = new UpdateStatuRequest(printCenterId, event.value);

		await updateStatu(request);
	};

	const statusTemplate = (printCenter: PrintCenterDto) => <Dropdown options={StatusOptions} value={printCenter.statu} onChange={(event) => handlePrintCenterStatuOnChange(event, printCenter.id)} className="w-full" />;

	const actionsTemplate = (printCenter: PrintCenterDto) => {
		const menuItems = [<button onClick={() => setEditedPrintCenter(printCenter.id)}>Edit</button>];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.orderBy = 2;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		await getAll(newPagination);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		getAll(newPagination);
	}, []);

	useEffect(() => {
		if (!selectedPrintCenters) return;

		setReceiverUserIdentities(selectedPrintCenters.map((printCenter) => printCenter.id));
	}, [selectedPrintCenters]);

	return (
		<div className="card">
			<div className="flex justify-content-between align-items-center mb-3">
				<h5>Providers</h5>

				<Button type="button" onClick={() => setCreateVisible(true)} loading={loading} label="New Print Center" icon="pi pi-plus-circle" />
			</div>

			<DataTable value={printCenters} selectionMode="checkbox" currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading} selection={selectedPrintCenters} onSelectionChange={(e) => setSelectedPrintCenters(e.value)} className="p-datatable-gridlines">
				<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<Column field="id" header="Print Center Id" />
				<Column field="businessName" header="Corporate Name" />
				<Column field="fullName" header="Personal Name" />
				<Column field="email" header="Email" />
				<Column field="userStatuId" header="Status" dataType="numeric" body={statusTemplate} />
				<Column field="actions" header="Actions" body={actionsTemplate} />
			</DataTable>

			<PrintCenterCreate visible={createVisible} setVisible={setCreateVisible} getMerchants={getAll} />
			{editedPrintCenter && <PrintCenterUpdate editedPrintCenter={editedPrintCenter} setEditedPrintCenter={setEditedPrintCenter} getPrintCenters={getAll} />}
		</div>
	);
};

export default PrintCenters;
