import { ShippingProfile } from '../../models/dtos/integrations/etsy/shipping-profile';
import { ShopSection } from '../../models/dtos/integrations/etsy/shop-section';
import { EtsyStore } from '../../models/dtos/store/etsy-store';
import { Integration } from '../../models/dtos/store/integration';
import { CreateEtsyStoreWithEtsyShopId } from '../../models/requests/integrations/create-etsy-store-with-etsy-shopid-request';
import { PushEtsyListing } from '../../models/requests/integrations/push-etsy-listing';
import { SaveEtsySellerPrivateNoteRequest } from '../../models/requests/integrations/save-etsy-seller-private-note-request';
import { SetEtsyAuthToken } from '../../models/requests/integrations/set-etsy-auth-token-request';
import { DataResponse } from '../../models/_commons/responses/data-response';
import { ResponseBase } from '../../models/_commons/responses/response-base';
import HttpBaseService from '../_common/http-base-service';
import {GetActiveIntegrationsRequest} from "../../models/requests/integrations/get-active-integrations-request";

class IntegrationsService extends HttpBaseService {
	constructor() {
		super('integrations');
	}

	async getEtsyStoresByUser(userId: string): Promise<DataResponse<EtsyStore>> {
		return await this.getData('get-etsy-stores-by-user-from-etsy?UserId=' + userId);
	}

	async getEtsyInformationsForPush(userId: string, productId: string) {
		return await this.getData('get-etsy-informations-for-push?UserId=' + userId + '&CopiedProductId=' + productId);
	}

	async getEtsyShopSectionsByShopId(userId: string, shopId: number): Promise<DataResponse<ShopSection[]>> {
		return await this.getData('get-etsy-shop-sections-by-shop-id?UserId=' + userId + '&ShopId=' + shopId);
	}

	async getEtsyShippingProfilesByShopId(userId: string, shopId: number): Promise<DataResponse<ShippingProfile[]>> {
		return await this.getData('get-etsy-shipping-profiles-by-shop-id?UserId=' + userId + '&ShopId=' + shopId);
	}

	async getActiveIntegrations(request: GetActiveIntegrationsRequest): Promise<DataResponse<Integration[]>> {
		return await this.postData('get-active-integrations', request);
	}

	async setEtstAuthToken(request: SetEtsyAuthToken) {
		return await this.postData('set-etsy-auth-token', request);
	}

	async createEtsyStoreWithEtsyShopId(request: CreateEtsyStoreWithEtsyShopId) {
		return await this.postData('create-etsy-store-with-etsy-shop-id', request);
	}

	async pushListingByProductId(request: PushEtsyListing) {
		return await this.postData('push-listing-by-product-id', request);
	}

	async saveEtsyOrderSellerPrivateNote(request: SaveEtsySellerPrivateNoteRequest): Promise<ResponseBase> {
		return await this.postData('save-etsy-seller-private-note', request);
	}
}

export default new IntegrationsService();
