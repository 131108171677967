import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginSocialGoogle, objectType } from 'reactjs-social-login';
import environment from '../../helpers/constants/environment';
import localStorageHelper from '../../helpers/local-storage.helper';
import toastHelper from '../../helpers/toast.helper';
import { RegisterOrLoginWithGoogleRequest } from '../../models/requests/google-login-request';
import { googleLogin } from '../../redux/features/auth/authSlice';
import { useAppDispatch } from '../../redux/hooks';
import styles from './Login.module.scss';
import React from "react";
import surveyService from '../../features/surveys/_common/survey.service';
import { SurveyGetAvailableByUserRequest } from '../../features/surveys/_common/survey-get-available-by-user/survey-get-available-by-user.request';


type Props = { setDisable: Function };

const LoginWithGoogle = ({ setDisable }: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);

	const resolveHandler = async (accessToken?: string) => {
		localStorageHelper.remove('auth');

		setDisable(true);
		if (!accessToken) return;

		const request: RegisterOrLoginWithGoogleRequest = { accessToken };
		setLoading(true);
		dispatch(googleLogin(request)).then(async (data: any | undefined | null) => {
			if (data.error) return;
			await getAvailableSurveys(data.payload.data.user.userId);
		}).finally(() => setLoading(false));
	};

	const handleCheckAutoGoogleLogin = () => {
		if (!location.search || !location.search.includes('googleLoginAccessToken')) return;

		const accessToken = location.search.replace('?googleLoginAccessToken=', '');

		if (!accessToken || accessToken === 'null' || accessToken.length < 1) return;

		resolveHandler(accessToken);
	};

	const getAvailableSurveys = async (userId: string) => {
		try {
			if (!userId) return;
			const response = await surveyService.getAvailableByUser(new SurveyGetAvailableByUserRequest(userId, 1));

			if (!response.isSuccess) throw '';

			if (!!response.data![0]) localStorageHelper.setStringfyForData('wizardAvaliable', true);
			navigate('/on-boarding');
		} catch (error) {
		} finally {
		}
	};

	const rejectHandler = (message: string | objectType) => {
		if (typeof message === 'string') toastHelper.error(message);
		else toastHelper.error(JSON.stringify(message));

		setLoading(false);
		setDisable(false);
	};

	useEffect(() => {
		handleCheckAutoGoogleLogin();
	}, [location.search]);

	return (
		<LoginSocialGoogle
			client_id={environment.googleAppId || ''}
			isOnlyGetToken
			scope="openid profile email"
			onLoginStart={() => setDisable(true)}
			onResolve={(resolveParams) => {
				resolveHandler(resolveParams?.data?.['access_token']);
			}}
			onReject={rejectHandler}>
			<button className={styles.socialLoginBtn}>
				{loading ? (
					<ProgressSpinner strokeWidth="4" animationDuration=".5s" style={{ width: '1.3rem', height: '1.3rem' }} />
				) : (
					<React.Fragment>
						<p className="m-0 font-semibold">Google</p>
						<img style={{width: "1rem"}} alt="google" src="/svgs/google_icon.svg"/>
					</React.Fragment>
				)}
			</button>
		</LoginSocialGoogle>
	);
};

export default LoginWithGoogle;
