export class DownloadAllDtfFilesRequest {
    dtfListIds: string[];

    constructor(dtfListIds: string[]) {
        this.dtfListIds = dtfListIds;
    }

    get getConvertUriExtension() {
        return 'download-all-dtf-files';
    }
}