import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import DropdownButton from '../../../helpers/widgets/DropdownButton';
import printCenterOperatorService from '../../../features/printCenterOperators/_common/print-center-operator.service';
import { SoftDeletePrintCenterOperatorRequest } from '../../../features/printCenterOperators/_common/soft-delete-print-center-operator/soft-delete-print-center-operator.request';
import { OperatorDTO } from '../../../features/printCenterOperators/_common/dtos/operator_dto';
import { GetAllPrintCenterOperatorsRequest } from '../../../features/printCenterOperators/_common/get-all-print-center-operators/get-all-print-center-operators.request';
import CreateOperatorDialog from './dialogs/CreateOperatorDialog';
import { CreatePrintCenterOperatorRequest } from '../../../features/printCenterOperators/_common/create-print-center-operator/create-print-center-operator.request';
import EditOperatorDialog from './dialogs/EditOperatorDialog';
import { UpdatePrintCenterOperatorRequest } from '../../../features/printCenterOperators/_common/update_print_center_operator/update_print_center_operator.request';
import toastHelper from '../../../helpers/toast.helper';

const PrintCenterOperators = () => {
	const [loading, setLoading] = useState(false);
	const [operators, setOperators] = useState<OperatorDTO[]>([]);
	const [createOperatorVisible, setCreateOperatorVisible] = React.useState(false);
	const [editOperatorVisible, setEditOperatorVisible] = React.useState<number | undefined>(undefined);

	useEffect(() => {
		getOperators();
	}, []);

	const getOperators = async () => {
		try {
			setLoading(true);

			const response = await printCenterOperatorService.getAllPrintCenterOperators(new GetAllPrintCenterOperatorsRequest());

			if (!response.isSuccess) throw '';

			setOperators(response.data!);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const createOperator = async (name: string) => {
		try {
			setLoading(true);

			const response = await printCenterOperatorService.createPrintCenterOperator(new CreatePrintCenterOperatorRequest(name));

			if (!response.isSuccess) throw '';

			await getOperators();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const updateOperator = async (operator: OperatorDTO) => {
		try {
			setLoading(true);

			const response = await printCenterOperatorService.updatePrintCenterOperator(new UpdatePrintCenterOperatorRequest(operator));

			if (!response.isSuccess) throw '';

			await getOperators();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const deleteOperator = async (id: number) => {
		try {
			setLoading(true);

			const response = await printCenterOperatorService.softDeletePrintCenterOperator(new SoftDeletePrintCenterOperatorRequest(id));

			if (!response.isSuccess) throw '';

			await getOperators();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const copyToClipboard = (text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((err) => {});
	};

	const nameTemplate = (rowData: OperatorDTO) => {
		return (
			<div className='cursor-pointer'
				onClick={() => {
					copyToClipboard(rowData.name);
					toastHelper.success(`Copied '${rowData.name}' to clipboard`);
				}}>
				{rowData.name}
			</div>
		);
	};

	const codeTemplate = (rowData: OperatorDTO) => {
		return (
			<div className='cursor-pointer'
				onClick={() => {
					copyToClipboard(rowData.code);
					toastHelper.success(`Copied '${rowData.code}' to clipboard`);
				}}>
				{rowData.code}
			</div>
		);
	};

	const actionsTemplate = (rowData: OperatorDTO) => {
		const menuItems = [<button onClick={() => setEditOperatorVisible(rowData.id)}>Edit</button>, <button onClick={() => deleteOperator(rowData.id)}>Delete</button>];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	return (
		<div className="card">
			<div className="flex justify-content-between mb-3">
				<h5>Operators</h5>
				<Button onClick={() => setCreateOperatorVisible(true)} label="New Operator" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>

			<DataTable value={operators as any} paginator rows={10} dataKey="id" loading={loading} className="p-datatable-gridlines">
				<Column field="name" header="Operator Name" body={nameTemplate} style={{ minWidth: '12rem' }} />
				<Column field="code" header="Operator Code" body={codeTemplate} style={{ minWidth: '12rem' }} />
				<Column field="actions" header="Actions" body={actionsTemplate} />
			</DataTable>

			<CreateOperatorDialog visible={createOperatorVisible} onHide={() => setCreateOperatorVisible(false)} onSubmit={(name: string) => createOperator(name)} />
			<EditOperatorDialog visible={!!editOperatorVisible} onHide={() => setEditOperatorVisible(undefined)} onSubmit={(operator: OperatorDTO) => updateOperator(operator)} operatorId={editOperatorVisible} />
		</div>
	);
};

export default PrintCenterOperators;
