import React, { useState } from 'react';
import styles from './SearchInput.module.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';

type Props = {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
	disabled: boolean;
	filterBtn: boolean;
	filterBtnOnClick: React.MouseEventHandler<HTMLButtonElement>;
	placeholder: string;
	value: string;
};

const SearchInput = (props: Partial<Props>) => {
	const [focus, setFocus] = useState<boolean>(false);
	const [btnWidth, setBtnWidth] = useState<number>(0);

	return (
		<React.Fragment>
			{props.filterBtn ? (
				<div className={styles.searchContainer}>
					{btnWidth > 0 ? (
						<div onBlur={(_) => setFocus(false)} className={`${styles.searchWrapper}${focus ? ` ${styles.focusSearch}` : ''}${!!props.filterBtn ? ` ${styles.btnExists}` : ''}`} style={{ '--btn-width': `${btnWidth}px` } as React.CSSProperties}>
							<AiOutlineSearch size={20} />

							<input type="text" value={props.value} onKeyDown={props.onKeyDown} onFocus={(_) => setFocus(true)} onChange={props.onChange} disabled={props.disabled} placeholder={props.placeholder || 'Search'} className={styles.searchInput} />
						</div>
					) : null}

					<PrButton childRef={(_ref) => setBtnWidth(_ref?.getBoundingClientRect().width || 0)} type="secondary" text="Apply" onClick={props.filterBtnOnClick} disabled={props.disabled} className={styles.searchBtn} />
				</div>
			) : (
				<div onBlur={(_) => setFocus(false)} className={`${styles.searchWrapper}${focus ? ` ${styles.focusSearch}` : ''}${!!props.filterBtn ? ` ${styles.btnExists}` : ''}`}>
					<AiOutlineSearch size={20} />

					<input type="text" onFocus={(_) => setFocus(true)} value={props.value} onChange={props.onChange} disabled={props.disabled} placeholder={props.placeholder || 'Search'} className={styles.searchInput} />
				</div>
			)}
		</React.Fragment>
	);
};

export default SearchInput;
