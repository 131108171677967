import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto'
import { EtsyAuthenticationDto } from 'features/integrations/_common/get-active-etsy-authentications/dtos/etsy-authentication.dto'
import { GetActiveEtsyAuthenticationsRequest } from 'features/integrations/_common/get-active-etsy-authentications/get-active-etsy-authentications.request'
import integrationsService from 'features/integrations/_common/integrations.service'
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput'
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton'
import React, { useEffect, useState } from 'react'
import styles from './EtsyConnections.module.scss'
import dateHelper from 'helpers/dateHelper'
import { Pagination } from 'models/_commons/responses/pagination'
import { Paginator } from 'primereact/paginator'
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface'
import { ProgressSpinner } from 'primereact/progressspinner'
import { DeleteAuthenticationRequest } from 'features/integrations/_common/delete-authentication/delete-authentication.request'

const EtsyConnections = () => {
	const [connections, setConnections] = useState<EtsyAuthenticationDto[]>([])
	const [pagination, setPagination] = useState<PaginationDto>({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [search, setSearch] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false)

	const getConnections = async() => {
		try {
			setLoading(true)

			const request = new GetActiveEtsyAuthenticationsRequest(pagination, search)

			const response = await integrationsService.getActiveEtsyAuthentications(request)

			if (!response.isSuccess) throw ''

			setConnections(response.data || [])
			setPaginationResponse(response.pagination);
		} catch (error) {
			setConnections([])
		} finally {
			setLoading(false)
		}
	}

	const deleteConnection = async (connection: EtsyAuthenticationDto) => {
		try {
			setLoading(true)

			const request = new DeleteAuthenticationRequest(connection.userId)

			const response = await integrationsService.deleteAuthentication(request)

			if (!response) throw ''

			setSearch("")
			getConnections()
		} catch (error) {
			setLoading(false)
		} finally {}
	}

	const clearPagination = () => setPagination({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });

	useEffect(() => {
		if (!pagination) return;

		getConnections();
	}, [pagination]);

  return (
		<main className="container relative">
			<section className="container-header">
				<h1 className="container-header-title">Etsy Connections</h1>

				<div className="container-header-tools">
					<PrButton type="secondary" text="Refresh Connections" disabled={loading} icon={<span className="pi pi-fw pi-refresh" />} onClick={() => getConnections()} />
				</div>
			</section>

			<section className="container-body p-3">
				<div className="mb-3">
					You can search among Etsy connections by <span className="font-bold">seller name, email, printram store name</span> or <span className="font-bold">etsy shop name.</span>
				</div>
				<SearchInput onChange={(e) => setSearch(e.target.value)} value={search} onKeyDown={(event) => event.key === 'Enter' && clearPagination()} filterBtn disabled={loading} placeholder="Search connections" filterBtnOnClick={() => clearPagination()} />
			</section>

			<section className="container-body p-0">
				<div className={styles.connectionsTableWrapper}>
					<div className={styles.connectionsTableHeader}>
						<div className={styles.connectionsTableHeaderTitles}>
							<span className={styles.connectionsTableHeaderTitlesSeller}>Seller</span>
							<span className={styles.connectionsTableHeaderTitlesStore}>Store</span>
							<span className={styles.connectionsTableHeaderTitlesStatus}>Status</span>
							<span className={styles.connectionsTableHeaderTitlesDate}>Last Updated Date</span>
							<span className={styles.connectionsTableHeaderTitlesActions} />
						</div>
					</div>
				</div>

				<div className={styles.connectionsTableBody}>
					{connections.length > 0 ? (
						connections.map((_connection) => (
							<div key={_connection.userId} className={styles.connectionsTableBodyItemWrapper}>
								<div className="px-4">
									<div className={styles.connectionsTableBodyWrapper}>
										<div className={styles.connectionsTableBodyItems}>
											<div className={styles.connectionsTableBodyItemSeller}>
												<span>{_connection.fullName}</span>
												<span className="font-medium">{_connection.email}</span>
											</div>
											<div className={styles.connectionsTableBodyItemStore}>
												<span>{_connection.printramStoreName}</span>
												<span className="font-medium">{_connection.etsyShopName}</span>
											</div>
											<div className={`${styles.connectionsTableBodyItemStatus} ${_connection.isActive && styles.isActiveStatus}`}>
												<span>{_connection.isActive ? 'Active' : 'Not Active'}</span>
											</div>
											<div className={styles.connectionsTableBodyItemDate}>
												<span>Updated at &#x2022; {dateHelper.formatDate(_connection.lastUpdatedDate)}</span>
											</div>
											<div className={styles.connectionsTableBodyItemActions}>
												<PrButton text="Delete" onClick={() => deleteConnection(_connection)} type="secondary" icon={<span className='pi pi-trash' />} />
											</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div className={`${styles.connectionsTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any connection yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && connections.length > 0 ? (
						<div className={`${styles.connectionsTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={pagination.first}
								rows={pagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30]}
								onPageChange={(event) => {
									setPagination({
										first: event.first,
										itemCount: event.rows,
										pageNumber: event.page + 1,
										orderBy: PaginationOrderByEnum.Descending
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
  );
}

export default EtsyConnections