import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import PrTextInput from '../../../../../helpers/widgets/Printram/Forms/Input/PrTextInput';
import stringHelper from '../../../../../helpers/string.helper';
import PrDropdown, { PrDropdownType } from '../../../../../helpers/widgets/Printram/Dropdown/PrDropdown';
import { DeliveryTypes, prefixOptions } from '../../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import AutoCompleteInput from '../../../../../helpers/widgets/Printram/Forms/Input/AutoCompleteInput';
import PrButton from '../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import React, { useEffect, useState } from 'react';
import { CountryBaseDto } from '../../../../../features/countries/get-countries/get-countries.response';
import countryService from '../../../../../features/countries/country.service';
import { GetCountriesRequest } from '../../../../../features/countries/get-countries/get-countries.request';
import { ValidateAddressRequest, ValidationAddressDto } from '../../../../../features/orders/derived-features/order-transports/validate-address/validate-address-request';
import { orderTransportService } from '../../../../../features/orders/derived-features/order-transports/order-transport.service';
import camelCaseHelper from '../../../../../helpers/camel-case.helper';
import FormatAddressDialog from '../../../orders/create-order/steps/shipping/components/ship-form/dialogs/FormatAddressDialog';
import { ValidateAddressDTO, ValidateAddressResponseDataDTO } from '../../../../../features/orders/derived-features/order-transports/validate-address/validate-address-response';
import { CustomDtfDeliveryAddress } from '../../../../../features/customDtf/create-custom-dtf-order/create-custom-dtf-order.request';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../context/CustomDtfContextProvider';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getPrintramStores } from '../../../../../redux/features/printram-store/printramStoreSlice';
import * as Yup from 'yup';
import SelectButtonForm from '../../../../../helpers/widgets/Printram/Forms/Buttons/SelectButton';
import styles from '../../../orders/create-order/steps/shipping/components/ship-form/ShipForm.module.scss';
import { FiPlus } from 'react-icons/fi';
import PrTextareaInput from "../../../../../helpers/widgets/Printram/Forms/Input/PrTextareaInput";
import AddressAutofill from '../../../../../helpers/widgets/Order/Address/AddressAutofill';
import StatesAndCountries from '../../../../../assets/jsons/states-and-countries/states-and-countries.json';
import { State, StateAndCountry } from '../../../../../assets/jsons/states-and-countries/states.type';

const CustomDtfShipment = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;
	const stores = useAppSelector((_state) => _state.printramStore.data || []);
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.auth.data?.user);

	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [validateAddressResponse, setValidateAddressResponse] = useState<ValidateAddressResponseDataDTO | undefined>(undefined);
	const [formatAddressVisible, setFormatAddressVisible] = useState<boolean>(false);
	const [selectedCountryIso, setSelectedCountryIso] = useState<string | undefined>();
	const [stateOptions, setStateOptions] = useState<PrDropdownType[] | undefined>();

	useEffect(() => {
		if (countries.length < 1) getCountries();
		dispatch(getPrintramStores(user!.userId)).then((data) => context.setStoreId((data.payload as any).data[0].storeId));
		context.setSelectedRate(undefined);
	}, []);

	useEffect(() => {
		const statesAndCountries = structuredClone(StatesAndCountries);

		setStateOptions(statesAndCountries.find((_states: StateAndCountry) => _states.country === selectedCountryIso)?.states.map((_statesOption: State) => ({ label: _statesOption.name, value: _statesOption.abbreviation })));
	}, [selectedCountryIso]);

	const getCountries = async () => {
		try {
			const response = await countryService.getCountries(new GetCountriesRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCountries(response.data);
		} catch (error) {
			setCountries([]);
		}
	};

	const validateAddress = async (values: FormikValues) => {
		try {
			context.setLoading(true);
			const validationAddress: ValidationAddressDto = structuredClone(values);

			let deliveryAddress: CustomDtfDeliveryAddress = structuredClone(values);
			deliveryAddress.receiverPhoneNumber = deliveryAddress.receiverPhoneNumber ? values.phonePrefix.value + deliveryAddress.receiverPhoneNumber : null;
			context.setDeliveryAddress(deliveryAddress);

			const request: ValidateAddressRequest = new ValidateAddressRequest({
				shipTo: validationAddress
			});
			const validateResponse = await orderTransportService.validateAddress(request);

			if (!validateResponse.isSuccess) throw '';

			const data = camelCaseHelper.toCamelCase(validateResponse.data);

			if (!data.isValidationExist) {
				onSubmitForm(data.response.originalAddress!, deliveryAddress);
				return;
			}

			setValidateAddressResponse(data.response!);
			setFormatAddressVisible(true);
			return;
		} catch (error) {
			console.log(error);
		} finally {
			context.setLoading(false);
		}
	};

	const onSubmitForm = (address: ValidateAddressDTO, deliveryAddress: CustomDtfDeliveryAddress) => {
		try {
			const _deliveryAddress = new CustomDtfDeliveryAddress({
				receiverPhoneNumber: deliveryAddress.receiverPhoneNumber,
				receiverFullName: deliveryAddress.receiverFullName,
				receiverEmail: deliveryAddress.receiverEmail,
				firstLine: address.addressLine1,
				secondLine: deliveryAddress.secondLine,
				city: address.cityLocality,
				state: address.stateProvince,
				zip: address.postalCode,
				countryIso: address.countryCode,
				formattedAddress: address.completeAddress ? address.completeAddress : context.deliveryAddress?.formattedAddress,
				vatNumber: deliveryAddress.vatNumber
			});

			context.setDeliveryAddress(_deliveryAddress);
			context.nextStep();
			return;
		} catch (e) {
			console.log(e);
		}
	};

	const initialValues = {
		firstLine: '',
		secondLine: '',
		city: '',
		state: '',
		zip: '',
		countryIso: '',
		receiverFullName: '',
		receiverEmail: '',
		formattedAddress: '',
		vatNumber: '',
		receiverPhoneNumber: '',
		phonePrefix: { label: '+1', value: '+1' },
		attachmentFiles: [],
		note: '',
	};

	const onSubmit = async (values: FormikValues) => {
		if (context.deliveryType === DeliveryTypes.PickUp) {
			const deliveryAddress = new CustomDtfDeliveryAddress({ receiverFullName: values.receiverFullName, receiverEmail: values.receiverEmail });
			context.setDeliveryAddress(deliveryAddress);
			context.setCalculatedPrice(context.grandTotal);
			context.setNote(values.note);
			context.setAttachmentFiles(values.attachmentFiles);
			context.setStep(3);
			return;
		}
		await validateAddress(values);
	};

	return (
		<div className={'relative'}>
			<Formik
				initialValues={context.deliveryAddress ? { ...context.deliveryAddress, attachmentFiles: [], note: '' } : initialValues}
				onSubmit={onSubmit}
				validationSchema={Yup.object().shape({
					receiverFullName: Yup.string()
						.required('Full Name is required')
						.test('is-full-name', 'Enter your Full Name', (value) => {
							if (!value) return false;

							return value.trim().split(' ').length > 1;
						}),
					receiverEmail: Yup.string().email('Email address must be valid.').required('Email is required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
					formattedAddress: context.deliveryType === DeliveryTypes.Shipment ? Yup.string() : Yup.string(),
					countryIso: context.deliveryType === DeliveryTypes.Shipment ? Yup.string().required('Select a country') : Yup.string(),
					state: context.deliveryType === DeliveryTypes.Shipment ? Yup.string().required('State is required') : Yup.string(),
					firstLine: context.deliveryType === DeliveryTypes.Shipment ? Yup.string().required('Address Line 1 is required') : Yup.string(),
					city: context.deliveryType === DeliveryTypes.Shipment ? Yup.string().required('City is required') : Yup.string(),
					zip: context.deliveryType === DeliveryTypes.Shipment ? Yup.string().required('Zip Code is required') : Yup.string()
				})}>
				{(form) => (
					<Form>
						<div className={`${context.deliveryType === DeliveryTypes.Shipment ? 'container-body' : ''} p-0 flex flex-row justify-content-center align-items-center`}>
							<div className={`${context.deliveryType === DeliveryTypes.Shipment ? 'flex-1' : 'container-body w-6'} p-5`}>
								<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
									Recipient
								</h2>

								<div>
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										Printram Store *
									</label>
									<PrDropdown
										options={stores.map((_store) => ({
											label: _store.name,
											value: _store.storeId
										}))}
										value={stores
											.map((_store) => ({
												label: _store.name,
												value: _store.storeId
											}))
											.find((_store) => _store.value === context.storeId)}
										placeholder="Select store"
										className="w-full"
										onChange={(event) => {
											context.setStoreId(event.value as string);
										}}
									/>
									<ErrorMessage name="storeId" component="small" className="text-red font-medium" />
								</div>

								<div className="mt-4 tutorial-selector2">
									<SelectButtonForm
										label="Delivery Type"
										options={[
											{
												label: DeliveryTypes[DeliveryTypes.Shipment],
												value: DeliveryTypes.Shipment
											},
											{ label: DeliveryTypes[DeliveryTypes.PickUp], value: DeliveryTypes.PickUp }
										]}
										value={context.deliveryType}
										onChange={(event) => !!event.value && context.setDeliveryType(event.value)}
									/>
								</div>

								<div className="mt-4">
									<PrTextInput
										type="text"
										label="Full Name *"
										style={{ height: '2.375rem' }}
										value={form.values?.receiverFullName || ''}
										onChange={(e) => {
											const formattedName = stringHelper.formatName(e.target.value);

											form.setFieldValue('receiverFullName', formattedName);
										}}
									/>

									<ErrorMessage name="receiverFullName" component="small" className="text-red font-medium" />
								</div>

								<div className="mt-4">
									<PrTextInput
										type="email"
										label="Email *"
										value={form.values?.receiverEmail || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('receiverEmail', e.target.value || null);
										}}
									/>

									<ErrorMessage name="receiverEmail" component="small" className="text-red font-medium" />
								</div>

								<label className="mt-4 block" style={{ fontSize: '19px', fontWeight: '600' }}>
									Phone <span className="pr-input-label-optional">(Optional)</span>
								</label>

								<div className="flex gap-2 align-items-end mt-1">
									<div>
										<PrDropdown
											options={prefixOptions}
											value={form.values?.phonePrefix || undefined}
											className="w-7rem"
											onChange={(e) => {
												form.setFieldValue('phonePrefix', e);
											}}
										/>
									</div>

									<PrTextInput
										name="phone"
										type="tel"
										value={form.values?.receiverPhoneNumber || ''}
										className="w-full"
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											if (e.target.value.length > 10) return;

											if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

											form.setFieldValue('receiverPhoneNumber', e.target.value);
										}}
									/>
								</div>

								<div className="mt-4">
									<PrTextInput
										label="VAT number"
										required={false}
										value={form.values?.vatNumber || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('vatNumber', e.target.value || '');
										}}
									/>
								</div>

								<div className="mt-4">
									<PrTextareaInput
										label="Note"
										required={false}
										value={form.values?.note || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('note', e.target.value || '');
										}}
									/>
								</div>

								{context.deliveryType === DeliveryTypes.PickUp ? (
									<React.Fragment>
										<div className="mt-4">
											<h5 style={{ fontSize: '19px', fontWeight: '600' }}>
												Ship Labels <span className="pr-input-label-optional">(Optional)</span>
											</h5>

											<label htmlFor="printFile" className={styles.printFileWrapper}>
												<input
													type="file"
													value={''}
													onChange={(e) => {
														if (!e.target.files) return;

														const files = e.target.files as any as File[];

														const attachmentFiles = !!form.values.attachmentFiles ? [...form.values.attachmentFiles] : [];

														for (const file of files) {
															if (!file.name.endsWith('.pdf')) continue;
															if (attachmentFiles.findIndex((_attachmentFile) => (_attachmentFile as File).name === file.name) > -1) continue;

															// @ts-ignore
															attachmentFiles.push(file as File);
														}

														form.setFieldValue('attachmentFiles', attachmentFiles);
													}}
													multiple
													accept=".pdf"
													name="printFile"
													id="printFile"
													className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
												/>

												<div role="button" tabIndex={0} className={styles.printFileBtn}>
													<FiPlus size="1.808rem" />
													<span>Ship Label File</span>
												</div>

												<div className={styles.printFileDivider} />

												<div className={styles.printFileInfo}>
													<span className={styles.printTitle}>Drag and Drop "Ship Labels" Here</span>
													<span className={styles.printAccepts}>You can upload: .pdf, Files</span>
												</div>
											</label>
										</div>

										{!!form.values.attachmentFiles ? (
											<div className="mt-4 flex flex-column gap-3">
												{form.values.attachmentFiles.map((_file, fileIndex) => (
													<div
														key={fileIndex}
														className={`flex justify-content-between h-3rem border-1 cursor-pointer border-round-lg select-none surface-100 border-600'`}
														onClick={() => {
															return;
														}}>
														<span className="h-full flex align-items-center px-3">{(_file as File).name}</span>

														<span
															role="button"
															tabIndex={0}
															className="cursor-pointer px-3 border-left-1 h-full flex align-items-center border-400"
															onClick={(e) => {
																e.stopPropagation();

																//	setSelectedAttachmentFile(undefined);

																const attachmentFiles = form.values.attachmentFiles?.filter((_attachmentFile) => (_attachmentFile as File).name !== (_file as File).name);

																form.setFieldValue('attachmentFiles', !!attachmentFiles && attachmentFiles.length > 0 ? attachmentFiles : null);
															}}>
															<i className="pi pi-times"></i>
														</span>
													</div>
												))}
											</div>
										) : null}
									</React.Fragment>
								) : null}
							</div>

							{context.deliveryType === DeliveryTypes.Shipment && (
								<div className="flex-1 border-left-1 border-400 p-5">
									<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
										Shipping address
									</h2>

									{countries.length > 0 ? (
										<div className="mt-4">
											{countries.length > 0 ? (
												<AddressAutofill
													onAutofillConfirm={(e) => {
														if (!e) return;
														form.setFieldValue('formattedAddress', e.formatted_address || '');
														form.setFieldValue('state', e.state_province || '');
														form.setFieldValue('city', e.city_locality || '');
														form.setFieldValue('zip', e.postal_code || '');
														form.setFieldValue('firstLine', e.address_line_1 || '');
														form.setFieldValue('secondLine', e.address_line_2 || '');

														for (const country of countries) {
															if (country.iso3 !== e.country_code && country.iso2 !== e.country_code && country.name !== e.country_code) continue;

															form.setFieldValue('countryIso', country.iso2);
															setSelectedCountryIso(country.iso2);
															break;
														}
													}}
												/>
											) : null}

											<ErrorMessage name="formattedAddress" component="small" className="text-red font-medium" />
										</div>
									) : null}

									<div className="mt-4 flex gap-4">
										<div className="w-full">
											<label className="block mb-1"
												   style={{ fontSize: '19px', fontWeight: '600' }}>
												Country *
											</label>

											<div>
												<PrDropdown
													value={countries
														.map((_country) => ({
															label: _country.name,
															value: _country.iso2
														}))
														.find((_country) => _country.value === form.values?.countryIso)}
													options={countries.map((_country) => ({
														label: _country.name,
														value: _country.iso2
													}))}
													className="w-full"
													onChange={(e) => {
														form.setFieldValue('address.countryIso', e.value);
													}}
												/>

												<ErrorMessage name="address.countryIso" component="small"
															  className="text-red font-medium" />
											</div>
										</div>

										<div className="w-full">
											<label className="block mb-1"
												   style={{ fontSize: '19px', fontWeight: '600' }}>
												State *
											</label>

											{stateOptions ? (
												<PrDropdown
													value={stateOptions.find((_states) => _states.value === form.values.state)}
													options={stateOptions}
													className="w-full"
													onChange={(e) => {
														form.setFieldValue('deliveryAddress.state', e.value);
													}}
												/>
											) : (
												<PrTextInput
													value={form.values.state || ''}
													className="w-full"
													style={{ height: '2.375rem' }}
													onChange={(e) => {
														form.setFieldValue('deliveryAddress.state', e.target.value || '');
													}}
												/>
											)}

											<ErrorMessage name="deliveryAddress.state" component="small"
														  className="text-red font-medium" />
										</div>
									</div>

									<div className="mt-4">
										<PrTextInput
											label="Address Line 1 *"
											value={form.values?.firstLine || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('address.firstLine', e.target.value || '');
											}}
										/>

										<ErrorMessage name="address.firstLine" component="small"
													  className="text-red font-medium" />
									</div>

									<div className="mt-4">
										<PrTextInput
											label="Address Line 2"
											required={false}
											value={form.values?.secondLine || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('address.secondLine', e.target.value || null);
											}}
										/>
									</div>

									<div className="mt-4 flex gap-4">
										<div className="w-full">
											<PrTextInput
												label="City *"
												value={form.values?.city || ''}
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													form.setFieldValue('address.city', e.target.value || '');
												}}
											/>

											<ErrorMessage name="address.city" component="small" className="text-red font-medium" />
										</div>

										<div className="w-full">
											<PrTextInput
												label="Zip Code *"
												value={form.values?.zip || ''}
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													form.setFieldValue('address.zip', e.target.value || '');
												}}
											/>

											<ErrorMessage name="address.zip" component="small" className="text-red font-medium" />
										</div>
									</div>
								</div>
							)}
						</div>

						<div className="mt-3 container-body bg-white flex flex-column p-3 h-6rem flex align-items-center justify-content-center">
							<PrButton className={`${context.deliveryType === DeliveryTypes.PickUp ? 'px-4' : ''}`} btnType="submit" text={context.deliveryType === DeliveryTypes.Shipment ? 'Calculate Shipping' : 'Next'} />
							<div className={`text-lg mt-2 text-primary cursor-pointer`} role="button" onClick={() => context.prevStep()}>
								Back
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<FormatAddressDialog
				visible={formatAddressVisible}
				validateAddressResponse={validateAddressResponse}
				onSubmit={(address) => onSubmitForm(address, context.deliveryAddress!)}
				onHide={() => {
					setFormatAddressVisible(false);
				}}
			/>
		</div>
	);
};

export default CustomDtfShipment;
