import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Dialog } from 'primereact/dialog';
import styles from './NewCardForm.module.scss'
import { useState } from 'react'
import CardSuccess from './CardSuccess';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';

const NewCardForm = ({ setupIntent, isVisible, setVisible, getAll, createSetupIntent }: any) => {
	const stripe = useStripe();
	const elements = useElements();
	const [name, setName] = useState('');
	const [stripeErrMsg, setStripeErrMsg] = useState<string | undefined>('');
	const [saveLoading, setSaveLoading] = useState(false);
	const [cardSuccessVisible, setCardSuccessVisible] = useState(false);

    const cardStyle = {
		showIcon: true,
		style: {
			base: {
				color: '#32325d',
				fontFamily: 'Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
			},
			invalid: {
				fontFamily: 'Arial, sans-serif',
				color: '#fa755a',
				iconColor: '#fa755a'
			}
		}
	};

	const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

		if (cardElement) {
			setSaveLoading(true)
            await stripe
                .confirmCardSetup(setupIntent.client_secret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: name
                        }
                    }
                })
                .then(function (result) {
                    if (result.error) {
                        setStripeErrMsg(result.error.message);
                    } else {
						createSetupIntent();
						setCardSuccessVisible(true);
                    }
                }).finally(() => setSaveLoading(false));
        }
	};

	const onDiscard = () => {
		setVisible(false);
		setStripeErrMsg('');
	}

	return (
		<Dialog
			visible={isVisible}
			header="Add New Card"
			modal
			onHide={() => {
				setVisible(false);
				setStripeErrMsg('');
			}}
			className={styles.dialog}>
			<form onSubmit={handleSubmit} className={styles.main}>
				<div className={styles.name}>
					<label htmlFor="">Name On Card</label>
					<input onChange={(e) => setName(e.target.value)} className={styles.nameInput} />
				</div>

				<div className={styles.card}>
					<label htmlFor="">Card Number</label>
					<i className="text-red">*</i>
					<div className={styles.cardNumber}>
						<CardNumberElement options={cardStyle} className={styles.cardInput} />
					</div>
				</div>

				<div className={styles.expCvc}>
					<div className={styles.exp}>
						<label htmlFor="">Expriation Date</label>
						<i className="text-red">*</i>
						<CardExpiryElement className={styles.expInput} />
					</div>

					<div className={styles.cvc}>
						<label htmlFor="">CVC</label>
						<i className="text-red">*</i>
						<CardCvcElement className={styles.cvcInput} />
					</div>
				</div>

				{stripeErrMsg && (
					<div className="mt-3">
						<span className="text-red">{stripeErrMsg}</span>
					</div>
				)}

				<div className={styles.buttons}>
					<PrButton text="Discard" loading={saveLoading} onClick={() => onDiscard()} type='secondary' />
					<PrButton text="Submit" loading={saveLoading} disabled={!stripe} btnType="submit" />
				</div>

				<CardSuccess isVisible={cardSuccessVisible} setVisible={setCardSuccessVisible} setCardFormVisible={setVisible} getAll={getAll} />
			</form>
		</Dialog>
	);
};

export default NewCardForm