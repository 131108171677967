export class GetSubscriptionsRequest {
	userId: string;

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `get-subscriptions?userId=${this.userId}`;
	}
}