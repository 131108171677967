import React from 'react';
import { OrderTransportHistoryTypes } from '../../../models/dtos/order/order-transport-history-types';
import { OrderTransport } from '../../../models/dtos/order/order-transport';
import dateHelper from '../../dateHelper';
import styles from './ShippingInfo.module.scss';
import currencyHelper from '../../curreny.helper';
import { useAppSelector } from '../../../redux/hooks';
import { Roles } from '../../../models/dtos/auth/users/roles';

const ShippingInfo = ({ id, orderId, price, priceForSeller, audit, serviceName, carrierName, estimatedDeliveryDateToCarrier, estimatedDeliveryDateToCustomer, isDelivered, labelUrl, trackLink, trackNumber, histories }: OrderTransport) => {
	const userRoles = useAppSelector((state) => state.auth.data?.user.roles || ({} as Roles));

	if (!id) return <h5>Shipping Info Not Found</h5>;

	return (
		<div className={styles.trackInfoWrapper}>
			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Carrier Name</div>
				<div className={styles.trackInfoValue}>{carrierName}</div>
			</div>

			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Service Name</div>
				<div className={styles.trackInfoValue}>{serviceName}</div>
			</div>

			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Track Number</div>
				<div className={styles.trackInfoValue}>
					<span>{trackNumber}</span>
					<a href={trackLink} target="_blank" title="Track your order" className="ml-3 text-primary hiddenOnPrint">
						<span className="pi pi-compass" />
					</a>
				</div>
			</div>

			<div className={`${styles.trackInfoRow} hiddenOnPrint`}>
				<div className={styles.trackInfoLabel}>Label</div>
				<div className={styles.trackInfoValue}>
					<a href={labelUrl} target="_blank" className="text-primary">
						Open label
					</a>
				</div>
			</div>

			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Estimated Delivery Date To Carrier</div>
				<div className={styles.trackInfoValue}>{dateHelper.formatDate(estimatedDeliveryDateToCarrier)}</div>
			</div>

			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Estimated Delivery Date To Customer</div>
				<div className={styles.trackInfoValue}>{dateHelper.formatDate(estimatedDeliveryDateToCustomer)}</div>
			</div>

			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Current Status</div>
				<div className={styles.trackInfoValue}>{OrderTransportHistoryTypes[histories?.at(-1)?.type || 0].split('_').join(' ')}</div>
			</div>

			<div className={styles.trackInfoRow}>
				<div className={styles.trackInfoLabel}>Current Status Date</div>
				<div className={styles.trackInfoValue}>{dateHelper.formatDate(histories?.at(-1)?.statusDate || '')}</div>
			</div>

			{histories?.at(-1)?.currentLocation?.length! > 2 ? (
				<div className={styles.trackInfoRow}>
					<div className={styles.trackInfoLabel}>Current Location</div>
					<div className={styles.trackInfoValue}>{histories?.at(-1)?.currentLocation}</div>
				</div>
			) : null}
		</div>
	);
};

export default ShippingInfo;
