import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GenerateProductVariants } from '../../../../../models/requests/products/product-variants-generate-request';
import colorService from '../../../../../services/color-service';
import { ProductSizes } from '../../../../../helpers/constants/product-sizes';
import productVariantsService from '../../../../../services/products/product-variants-service';
import measurementTypeService from '../../../../../services/dimensions/measurement-type-service';
import { ProductVariation } from '../../../../../models/dtos/product/product-variation';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GenerateVariations from './modals/GenerateVariations';
import { MeasurementType } from '../../../../../models/dtos/dimesions/measurement-type';
import EditVariations from './modals/EditVariations';
import { cloneDeep } from 'lodash';
import { CreateProductVariantsRequest } from '../../../../../models/requests/products/create-product-variants-request';
import sizeService from '../../../../../services/dimensions/size-service';
import { Size } from '../../../../../models/dtos/dimesions/size';
import { measurementHelper, measurementType } from 'helpers/measurement.helper';
import toastHelper from 'helpers/toast.helper';
import stockmanagementService from 'features/stockmanagement/_common/stockmanagement.service';
import { GetAllBasicStockLocationRequest } from 'features/stockmanagement/_common/get-all-basic-stock-location/get-all-basic-stock-location.request';
import { BasicStockLocationDto } from 'features/stockmanagement/dtos/basic-stock-location.dto';
import PrDropdown from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { MultiSelect } from 'primereact/multiselect';

const StepFour = ({ setStep, callback }: any) => {
	const { productId } = useParams();

	const [colorOptions, setColorOptions] = useState<SelectItemOptionsType>();
	const [sizeOptions, setSizeOptions] = useState<SelectItemOptionsType>(ProductSizes);
	const [locationOptions, setLocationOptions] = useState<SelectItemOptionsType>();
	const [selectedLocations, setSelectedLocations] = useState<Array<string>>()
	const [isVisible, setVisible] = useState<boolean>(false);
	const [editVisible, setEditVisible] = useState<boolean>(false);
	const [measurementTypes, setMeasurementTypes] = useState<SelectItemOptionsType>();
	const [variations, setVariations] = useState<ProductVariation[]>([]);
	const [selectedVariations, setSelectedVariations] = useState<ProductVariation[]>([]);
	const [editedVariations, setEditedVariations] = useState<ProductVariation[]>([]);
	const [prevValues, setPrevValues] = useState<GenerateProductVariants>();
	const [loading, setLoading] = useState(false);

	const initialValues: CreateProductVariantsRequest = {
		productId: productId || '',
		variants: [],
		stockLocationIds: []
	};

	const onSubmit = async () => {
		try {
			const request: CreateProductVariantsRequest = {
				productId: productId || '',
				variants: variations,
				stockLocationIds: selectedLocations || []
			};

			let priceWarning: string = '';
			let skuWarning: string = '';

			variations.find((variant) => {
				if (!variant.variantSpecificData.pricePerUnit || variant.variantSpecificData.pricePerUnit === 0) priceWarning = `${variant.displayText} Price is required`;
				if (!variant.variantSpecificData.parentSKU || !variant.variantSpecificData.childSKU) skuWarning = `${variant.displayText} SKU is required`;
			});

			if (!!priceWarning && priceWarning.length > 0) {
				toastHelper.warning(priceWarning);
				return;
			}

			if (!!skuWarning && skuWarning.length > 0) {
				toastHelper.warning(skuWarning);
				return;
			}

			setLoading(true);

			const response = await productVariantsService.create(request);
			if (!response.isSuccess) throw '';

			setStep?.((prev: number) => (prev += 1));
			setVariations([]);
			callback?.();
		} finally {
			setLoading(false);
		}
	};

	const formatCurrency = (value: any) => value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

	const priceBodyTemplate = (rowData: ProductVariation) => formatCurrency(rowData.variantSpecificData.pricePerUnit / 100);

	const SKUBodyTemplate = (rowData: ProductVariation) => rowData.variantSpecificData.parentSKU + '-' + rowData.variantSpecificData.childSKU;

	const imageBodyTemplate = (rowData: ProductVariation) => {
		const files: File[] = rowData.variantSpecificData.imageFiles || [];

		return (
			<>
				{files.map((file: any, index: number) => {
					return <img key={index} alt="" src={file.objectURL} style={{ width: 40, height: 40 }} />;
				})}
			</>
		);
	};

	const nameBodyTemplate = (rowData: ProductVariation) => (
		<span onClick={() => setEditedVariations(cloneDeep([rowData]))} className="cursor-pointer">
			{rowData.displayText}
		</span>
	);

	const measurementBodyTemplate = (rowData: ProductVariation) =>
		rowData.variantComponents.productVariantDimensionComponents.map((item, index) => (
			<React.Fragment key={index}>
				{item.measurementTypeName + ' : ' + item.value + ' ' + measurementHelper.getMeasurementUnit(item.measurementUnit, item.measurementTypeName as measurementType)}
				<br />
			</React.Fragment>
		));

	const getColors = () => {
		return new Promise((resolve) => {
			colorService.getAll().then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((color) => ({ label: color.name, value: color.name, title: color.hexCode } as SelectItem));
				if (!options) return;

				setColorOptions(options);
				resolve(options);
			});
		});
	};

	const getMeasurementTypes = () => {
		return new Promise((resolve) => {
			measurementTypeService.getAll({ pagination: { orderBy: 1, pageNumber: 1 } }).then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((measurement: MeasurementType) => ({ label: measurement.name, value: measurement.name } as SelectItem));
				if (!options) return;

				resolve(options);
			});
		});
	};

	const getSizes = () => {
		return new Promise((resolve) => {
			sizeService.getSizes({ pagination: { orderBy: 1, pageNumber: 1 } }).then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((size: Size) => ({ label: size.shortName, value: size.name } as SelectItem));
				if (!options) return;

				resolve(options);
			});
		});
	};

	const getStockLocations = () => {
		return new Promise(async (resolve) => {
			const request = new GetAllBasicStockLocationRequest()
			await stockmanagementService.getAllBasicStockLocation(request).then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((location: BasicStockLocationDto) => ({ label: location.name, value: location.stockLocationId } as SelectItem));
				if (!options) return;

				resolve(options);
			});
		});
	}

	useEffect(() => {
		if (editedVariations.length === 0) return;

		setEditVisible(true);
	}, [editedVariations]);

	useEffect(() => {
		if (!productId) return;

		setLoading(true);
		Promise.all([getColors(), getMeasurementTypes(), getSizes(), getStockLocations()])
			.then((values: any) => {
				setColorOptions(values[0]);
				setMeasurementTypes(values[1]);
				setSizeOptions(values[2]);
				setLocationOptions(values[3]);
			})
			.finally(() => setLoading(false));
	}, [productId]);

	return (
		<div className="grid">
			<div className={`col-12${!!setStep ? ' lg:col-8' : ''}`}>
				<div className="flex align-items-center justify-content-between mb-3">
					<Button type="button" icon={prevValues ? 'pi pi-refresh' : 'pi pi-plus'} label={prevValues ? 'Change Variations' : 'Create Variations'} onClick={() => setVisible(true)} loading={loading} className="p-button-outlined px-4" />

					{!!variations && variations.length > 0 ? <Button type="button" icon="pi pi-cog" label="Edit Selecteds" onClick={() => setEditedVariations(cloneDeep(selectedVariations))} disabled={!selectedVariations || selectedVariations.length < 1} className="p-button-outlined px-4" /> : null}
				</div>

				<Formik initialValues={initialValues} onSubmit={onSubmit}>
					{() => (
						<Form>
							{variations?.length > 0 && (
								<DataTable value={variations} selectionAriaLabel="displayText" selection={selectedVariations} selectionMode="checkbox" showGridlines onSelectionChange={(e) => setSelectedVariations(e.value)} dataKey="displayText" loading={loading} className="p-datatable-customers">
									<Column selectionMode="multiple" headerStyle={{ width: '1rem' }}></Column>
									<Column field="displayText" header="Variant Name" filterPlaceholder="Search by name" body={nameBodyTemplate} />
									<Column field="variantSpecificData.pricePerUnit" header="Price" filterPlaceholder="Search by price" body={priceBodyTemplate} />
									<Column header="SKU" filterPlaceholder="Search by SKU" body={SKUBodyTemplate} />
									<Column field="variantComponents" header="Measurements" filterPlaceholder="Search by name" body={measurementBodyTemplate} />
									<Column field="variantSpecificData.imageFiles" header="Images" body={imageBodyTemplate} />
								</DataTable>
							)}

							{!callback ? <div className="flex align-items-center justify-content-between mt-5">
								<span className="font-bold mr-3">Select Stock Locations</span>
								<MultiSelect value={selectedLocations} onChange={(e) => setSelectedLocations(e.value)} options={locationOptions} optionLabel="label" placeholder="Select Stock Locations" className="w-full md:w-30rem" />
							</div>:null}

							{!!variations && variations.length > 0 ? (
								<div className="mt-4 text-right">
									<Button type="submit" icon={`pi ${!!setStep ? 'pi-arrow-right' : 'pi-save'}`} label={`${!!setStep ? 'Confirm & Next' : 'Save'}`} loading={loading} className="px-5" />
								</div>
							) : null}
						</Form>
					)}
				</Formik>
			</div>

			{isVisible && <GenerateVariations visible={isVisible} setVisible={setVisible} colorOptions={colorOptions} sizeOptions={sizeOptions} loading={loading} setLoading={setLoading} setVariations={setVariations} getColors={getColors} prevValues={prevValues} setPrevValues={setPrevValues} />}

			{editVisible && <EditVariations visible={editVisible} setVisible={setEditVisible} measurementTypes={measurementTypes} editedVariations={editedVariations} setEditedVariations={setEditedVariations} setSelectedVariations={setSelectedVariations} variations={variations} setVariations={setVariations} />}
		</div>
	);
};

export default StepFour;
