import {Button} from "primereact/button";
import React from "react";

type OptionButtonProps = {
    name: string;
    subtext?: string | null;
    checked?: boolean;
    disabled: boolean;
    onClick: () => void;
};

const OptionButton = ({name, subtext, checked, disabled, onClick}: OptionButtonProps) => {

    return (
        <div className="col-6">
            <Button
                className={
                    checked
                        ? "w-full border-round-xl border-2 border-black-alpha-50 transition-linear"
                        : "w-full border-round-xl bg-white hover:bg-blue-50 border-2 transition-ease-out"
                }
                style={{color: "#101531", padding: "12px", backgroundColor: checked ? "#EBF0FF" : "", borderColor: checked ? "#101531" : "#D1D3D4"}}
                disabled={disabled}
                onClick={() => onClick()}
            >
                {subtext ? (<div className="flex flex-column justify-content-center align-items-start text-start">
                    <p className="mb-1">{name}</p>
                    <p className={`${subtext ? "block" : "hidden"} font-light text-small text-gray-500 h-3rem md:h-full text-left`}>{subtext}</p>
                </div>) : name}
            </Button>
        </div>
    );
};

export default OptionButton;