import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { ProductStatu } from 'features/products/dtos/enums/product-statu.enum';

export class GetMyProductsRequest {
	merchantId: string;
	contains: string | null;
	statu: ProductStatu | null;
	pagination: PaginationDto;

	constructor(props: Partial<GetMyProductsRequest>) {
		this.merchantId = props.merchantId || '';
		this.contains = props.contains || null;
		this.statu = props.statu || null;
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-my-products`;
	}
}
