import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { UpdateBrandRequest } from '../../../../../models/requests/brands/update-brand-request';
import { Brand } from '../../../../../models/dtos/brand/brand';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import brandService from '../../../../../services/brand-service';
import TextInput from '../../../../../helpers/widgets/Forms/TextInput';
import { StatusOptions } from '../../../../../helpers/constants/user-status-options';
import DropdownInput from '../../../../../helpers/widgets/Forms/DropdownInput';

type Props = {
    isVisible: boolean;
    setVisible: Function;
    getBrands: Function;
    editedBrand: Brand | undefined;
    setEditedBrand: Function;
};

const BrandUpdate = ({ isVisible, setVisible, getBrands, editedBrand, setEditedBrand }: Props) => {
    const initialValues: UpdateBrandRequest = { id: editedBrand?.id || '', name: editedBrand?.name || '', brandStatuId: editedBrand?.statu || 0 };

    const validationScheme = Yup.object().shape({ name: Yup.string().required('Brand name is Required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

    const submitForm = (values: UpdateBrandRequest) => {
        brandService.update(values).then((response) => {
            if (!response.isSuccess) return;

            setEditedBrand(undefined);
            setVisible(false);
            getBrands();
        });
    };

    return (
        <Dialog
            visible={isVisible}
            header="Update Brand"
            modal
            onHide={() => {
                setEditedBrand(undefined);
                setVisible(false);
            }}
        >
            <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
                {({ values, handleChange }) => (
                    <Form>
                        <div className="grid">
                            <div className="col-12">
                                <TextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Enter brand name" className="w-full" />
                            </div>
                            <div className="col-8">
                                <DropdownInput label="Status" name="brandStatuId" options={StatusOptions} value={values.brandStatuId} onChange={handleChange} placeholder="Select a statu" />
                            </div>
                            <div className="col-4 flex align-items-end">
                                <Button type="submit" label="Update" className="w-full py-3" />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default BrandUpdate;
