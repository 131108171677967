import { DeliveryTypes, prefixOptions } from '../../orders/_common/create-manuel-order/create-manuel-order.request';
import { PrDropdownType } from '../../../helpers/widgets/Printram/Dropdown/PrDropdown';
import { ShippingRateDto } from '../../orders/derived-features/order-transports/dtos/shipping-rate.dto';

export class CreateCustomDtfOrderRequest {
	userId: string;
	storeId: string;
	productVariantId: string;
	deliveryType: number;
	paymentMethodType: number;
	files: CustomDtfFile[];
	deliveryAddress?: CustomDtfDeliveryAddress;
	shippingRate?: ShippingRateDto;
	attachmentFiles: File[];
	note: string;

	constructor(props: Partial<CreateCustomDtfOrderRequest>) {
		this.userId = props.userId || '';
		this.storeId = props.storeId || '';
		this.productVariantId = props.productVariantId || '';
		this.deliveryType = props.deliveryType || DeliveryTypes.Shipment;
		this.paymentMethodType = props.paymentMethodType || 1;
		this.files = props.files || [];
		this.deliveryAddress = props.deliveryAddress || undefined;
		this.shippingRate = props.shippingRate || undefined;
		this.attachmentFiles = props.attachmentFiles || [];
		this.note = props.note || '';
	}

	get getConvertUriExtension() {
		return 'create-custom-dtf-order';
	}
}

export class CustomDtfFile {
	dtfFileName: string;
	dtfFilePath: string;
	height: number;
	width: number;
	quantity: number;

	constructor(dtfFileName: string, dtfFilePath: string, height: number, width: number, quantity: number) {
		this.dtfFileName = dtfFileName;
		this.dtfFilePath = dtfFilePath;
		this.height = height;
		this.width = width;
		this.quantity = quantity;
	}
}

export class CustomDtfDeliveryAddress {
	receiverFullName: string;
	receiverPhoneNumber: string | null;
	receiverEmail?: string;
	firstLine: string;
	secondLine?: string;
	city: string;
	state: string;
	zip: string;
	countryIso: string;
	formattedAddress: string;
	vatNumber?: string;
	phonePrefix: PrDropdownType;

	constructor(props: Partial<CustomDtfDeliveryAddress>) {
		this.receiverFullName = props.receiverFullName || '';
		this.receiverPhoneNumber = props.receiverPhoneNumber || null;
		this.receiverEmail = props.receiverEmail || '';
		this.firstLine = props.firstLine || '';
		this.secondLine = props.secondLine || '';
		this.city = props.city || '';
		this.state = props.state || '';
		this.zip = props.zip || '';
		this.countryIso = props.countryIso || '';
		this.formattedAddress = props.formattedAddress || '';
		this.vatNumber = props.vatNumber || '';
		this.phonePrefix = props.phonePrefix || prefixOptions[0];
	}
}