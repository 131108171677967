import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const CustomDtf = () => {
	const location = useLocation();
	const basePathName = '/custom-dtf';

	return (
		<main className="">
			<section className="container">
				<h3 className={'mb-4'}>{location.pathname === basePathName ? 'New Order' : 'Orders'}</h3>

				<div className="border-bottom-1 border-gray-300">
					<Link className={`text-lg font-semibold px-3 ${location.pathname === basePathName || location.pathname === basePathName + '/' ? 'text-indigo-600 border-bottom-1 border-primary' : 'text-gray-800'}`} to={basePathName}>
						Create Order
					</Link>
					<Link className={`text-lg font-semibold px-3 ${location.pathname === basePathName + '/orders' || location.pathname === basePathName + '/orders/' ? 'text-indigo-600 border-bottom-1 border-primary' : 'text-gray-800'}`} to={`${basePathName}/orders`}>
						Orders
					</Link>
				</div>
			</section>

			<Outlet />
		</main>
	);
};

export default CustomDtf;
