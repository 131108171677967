import { useState, useEffect } from 'react';
import brandService from '../../../../services/brand-service';
import { Brand } from '../../../../models/dtos/brand/brand';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import BrandCreate from './components/BrandCreate';
import BrandUpdate from './components/BrandUpdate';
import { Skeleton } from 'primereact/skeleton';
import { StatusOptions } from '../../../../helpers/constants/user-status-options';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { UpdateBrandStatuRequest } from '../../../../models/requests/brands/update-brand-statu-request';
import imageHelper from 'helpers/image.helper';
import mediaHelper from 'helpers/media.helper';

const Brands = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [visibleCreateBrand, setVisibleCreateBrand] = useState(false);
    const [visibleUpdateBrand, setVisibleUpdateBrand] = useState(false);
    const [editedBrand, setEditedBrand] = useState<Brand | undefined>(undefined);

    const getAll = () => {
        setLoading(true);

        brandService
            .getBrandsForAdmin({ pageNumber: 1, orderBy: 1 })
            .then((response) => response.isSuccess && setBrands(response.data || []))
            .finally(() => setLoading(false));
    };

    const deleteBrand = (id: string) => brandService.delete(id).then((response) => response && getAll());

    const updateStatu = (request: UpdateBrandStatuRequest) => brandService.updateStatu(request).then((response) => response && getAll());

    const handleEditBtnCliked = (brand: Brand) => {
        setEditedBrand(brand);
        setVisibleUpdateBrand(true);
    };

    useEffect(() => {
        getAll();
    }, []);

    const handleBrandStatuOnChange = (event: DropdownChangeParams, brandId: string) => {
        const request: UpdateBrandStatuRequest = { brandId, brandStatuId: event.value };

        updateStatu(request);
    };

    const statusTemplate = (brand: Brand) => <Dropdown options={StatusOptions} value={brand.statu} onChange={(event) => handleBrandStatuOnChange(event, brand.id)} className="w-full" />;

    const actionsTemplate = (brand: Brand) => (
        <div className="flex gap-2 justify-content">
            <Button onClick={() => handleEditBtnCliked(brand)} label="Edit" icon="pi pi-fw pi-file-edit" className="p-button-info" />
            <Button onClick={() => deleteBrand(brand.id)} label="Delete" icon="pi pi-fw pi-trash" className="p-button-danger" />
        </div>
    );

    const imageTemplate = (brand: Brand) => <img src={mediaHelper.getImageOnMedia(brand?.medias[0]).url} alt={brand.name} width="50" height="50" style={{ borderRadius: 25 }} />;

    return (
        <div className="card">
            <div className="flex justify-content-between mb-3">
                <h5>Brands</h5>
                <Button onClick={() => setVisibleCreateBrand(true)} label="New Brand" icon="pi pi-plus-circle" className="p-button-outlined" />
            </div>
            <DataTable value={brands} paginator rows={10} dataKey="id" loading={loading} className="p-datatable-gridlines">
                <Column field="id" header="Brand Id" filter filterPlaceholder="Search by id" style={{ minWidth: '12rem' }} />
                <Column header="Brand Image" body={(event) => imageTemplate(event)} style={{ minWidth: '6rem' }} />
                <Column field="name" header="Brand Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                <Column field="brandStatuId" header="Status" dataType="numeric" filter body={statusTemplate} style={{ width: '0' }} />
                <Column field="actions" header="Actions" body={actionsTemplate} />
            </DataTable>

            <BrandCreate isVisible={visibleCreateBrand} setVisible={setVisibleCreateBrand} getBrands={getAll} />
            <BrandUpdate isVisible={visibleUpdateBrand} setVisible={setVisibleUpdateBrand} getBrands={getAll} editedBrand={editedBrand} setEditedBrand={setEditedBrand} />
        </div>
    );
};

export default Brands;
