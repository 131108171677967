import { ProductForImport } from 'features/products/_common/dtos/product-for-import.dto';
import productService from 'features/products/_common/product.service';
import { SearchProductForImportRequest } from 'features/products/_common/search-product-for-import/search-product-for-import.request';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import ProductsTable from './components/ProductsTable';

const ProductImport = () => {
	const [contains, setContains] = useState<string>("")
	const [products, setProducts] = useState<ProductForImport[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const searchProduct = async() => {
		try {
			if (contains.length < 3) throw ''

			setLoading(true)

			const request = new SearchProductForImportRequest(contains)

			const response = await productService.searchProductForImport(request)

			if (!response.isSuccess) throw ''

			setProducts(response.data || [])
		} finally { setLoading(false) }
	}

  return (
		<div>
			<div className="card">
				<div className="col-12">
					<div className="p-inputgroup">
						<InputText value={contains} onChange={(e) => setContains(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && (event.preventDefault(), searchProduct())} placeholder="Gildan 2000 etc." className="w-full" />
						<Button type="button" label="Search Product" onClick={() => searchProduct()} className="w-14rem h-3rem" />
					</div>
				</div>
				<div className="col-12 alig border-1 border-yellow-500  border-round-md mt-3 p-2">
					<span className="pi pi-fw pi-info-circle mr-2 text-lg text-yellow-500" />
					<span>Products that are incompatible with printram products may not be imported.</span>
				</div>
			</div>
			<div className="card">
				<ProductsTable products={products} loading={loading} />
			</div>
		</div>
  );
}

export default ProductImport