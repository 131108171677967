import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetCategoryTransportInformationByCategoryRequest {
	categoryId: string = '';
	pagination: PaginationDto = new PaginationDto();

	get getConvertUriExtension() {
		return `get-by-category`;
	}

	constructor(categoryId: string, pagination: PaginationDto) {
		this.categoryId = categoryId
		this.pagination = pagination
	};
}