import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import stringHelper from 'helpers/string.helper';
import styles from './OrderFilterTab.module.scss';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

type Props = {
	filterOrderStatuType: OrderStatuTypes | null;
	setFilterOrderStatuType: (state: OrderStatuTypes | null) => void;
	page: 'admin' | 'seller' | 'printCenter';
	isTutorialStart?: boolean;
};

const OrderFilterTab = ({ filterOrderStatuType, setFilterOrderStatuType, page, isTutorialStart }: Props) => {
	const [_, setSearchParams] = useSearchParams();
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);

	const statuColors = [
		{ orderStatu: null, color: '#363062' }, // all
		{ orderStatu: OrderStatuTypes.PendingApproval, color: '#c279ce' }, // Pending Approval
		{ orderStatu: OrderStatuTypes.OrderApproved, color: '#3f51b5' }, // acknowledge
		{ orderStatu: OrderStatuTypes.UrgentOrder, color: '#6C5F5B' }, // delay order
		{ orderStatu: OrderStatuTypes.PrintedOrder, color: '#75bef8' }, // printed order
		{ orderStatu: OrderStatuTypes.DeliveredToCarrier, color: '#4169e1' }, // Delivered To USPS
		{ orderStatu: OrderStatuTypes.OnTheWay, color: '#fdd87d' }, // on the way
		{ orderStatu: OrderStatuTypes.Delivered, color: '#90cd93' }, // delivered
		{ orderStatu: OrderStatuTypes.Canceled, color: '#ff8980' }, // canceled
		{ orderStatu: OrderStatuTypes.Preparing, color: '#ad8957' } // preparing
	];

	const steps = [
		{
			element: '.tutorial-selector2',
			title: 'Pending Approval',
			intro: 'When you integrate with a marketplace, orders will appear in the Pending Approval section every 2 hours. This section displays orders that have been synced from your marketplace integration and are awaiting your approval.'
		},
		{
			element: '.tutorial-selector3',
			title: 'Order Approved',
			intro: 'The Order Approved section shows all the orders that have been successfully approved. These orders are ready to move on to the next stage of processing.'
		},
		{
			element: '.tutorial-selector13',
			title: 'Preparing',
			intro: 'The Preparing section indicates that the product is currently being printed and prepared. This stage ensures that the product is being handled with care and is on its way to completion.'
		},
		{
			element: '.tutorial-selector12',
			title: 'Delivered to Carrier',
			intro: 'The Delivered to Carrier section signifies that the product has been handed over to the shipping carrier. The order is now in transit.'
		}
	];

	const handleClickStatuBtns = async (statu: OrderStatuTypes | null = null) => {
		setFilterOrderStatuType(statu);
		setSearchParams(`status=${statu}`);
	};

	useEffect(() => {
		if (!isTutorialStart) return;

		setTutorialStepsEnabled(true)
	},[isTutorialStart])

	return (
		<React.Fragment>
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'orders-intro-2', disableInteraction: false, buttonClass: 'text-sm text-color-secondary bg-red', tooltipClass: "p-10rem" }} /> */}

			{page === 'admin' || page === 'seller' ? (
				<React.Fragment>
					<h5 className="m-0">Order Status</h5>
					<p className="text-sm text-color-secondary">You can filter your orders by order status like acknowledge, printed, canceled etc.</p>
				</React.Fragment>
			) : (
				<h6 className="mb-1">Filter by Order Status</h6>
			)}

			<div className={`flex flex-wrap gap-3${page === 'admin' || page === 'seller' ? '' : ' mb-3'}`}>
				<button onClick={() => handleClickStatuBtns(null)} className={`${styles.btnStatus} shadow-1${filterOrderStatuType === null ? ' ' + styles.active : ''}`} style={{ '--border-color-hex': statuColors.find((statu) => statu.orderStatu === null)?.color } as CSSProperties} aria-label="All">
					<i className={`pi pi-circle${filterOrderStatuType === null ? '-fill' : ''} mr-2`} style={{ color: statuColors.find((statu) => statu.orderStatu === null)?.color }} />
					All
				</button>

				{enumToArrayHelper.convertArray(OrderStatuTypes, true).map((data) => (
					<button onClick={() => handleClickStatuBtns(+data.value)} key={data.value} className={`${styles.btnStatus} shadow-1${!!filterOrderStatuType && filterOrderStatuType === data.value ? ' ' + styles.active : ''} tutorial-selector${data.value}`} style={{ '--border-color-hex': statuColors.find((statu) => statu.orderStatu === data.value)?.color } as CSSProperties} aria-label={data.label}>
						<i className={`pi pi-circle${!!filterOrderStatuType && filterOrderStatuType === data.value ? '-fill' : ''} mr-2`} style={{ color: statuColors.find((statu) => statu.orderStatu === data.value)?.color }} />
						{stringHelper.parseAtUpperCaseAndJoin(data.label)}
					</button>
				))}
			</div>
		</React.Fragment>
	);
};

export default OrderFilterTab;
