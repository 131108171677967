import orderService from 'features/orders/_common/order.service';
import { ReadyForShippingRequest } from 'features/orders/_common/ready-for-shipping/ready-for-shipping.request';
import { Form, Formik } from 'formik';
import audioHelper from 'helpers/audio.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react'
import { IoWarningOutline } from 'react-icons/io5';

const GetShipLabel = () => {
 const [loading, setLoading] = useState<boolean>(false);

 const focusInput = () => {
		const input = document.querySelector('input');
		input?.focus();
 };

 const showErrorDialog = (error: any) => {
		confirmDialog({
			header: 'Ship label can not get!',
			message: error.response?.data?.message?.toString() || '',
			icon: <IoWarningOutline size={25} />,
			acceptLabel: 'Ok',
			acceptClassName: 'px-4',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: focusInput,
			reject: focusInput
		});
 };

 return (
		<Formik
		 initialValues={{ barcode: '' }}
		 onSubmit={async (values, formikHelpers) => {
			 if (!values.barcode) return;
				try {
					setLoading(true);

					const response = await orderService.setReadyForShipping(new ReadyForShippingRequest(values.barcode, true));
					if (!response.isSuccess) throw '';
				} catch (error) {
					audioHelper.playAudio();
					showErrorDialog(error as any);
				} finally {
					formikHelpers.resetForm();
					focusInput();
					setTimeout(() => {
						setLoading(false);
					}, 1000);
				}
			}}>
			{(form) => (
				<Form className="container-body static p-3" autoComplete="off">
					<PrTextInput label="Scan/Enter Barcode" readOnly={loading} name="barcode" minLength={2} autoFocus value={form.values.barcode} onChange={form.handleChange} required placeholder="Scan or Enter barcode to get ship label" />

					<div className="flex justify-content-end mt-3">
						<PrButton btnType="submit" text="Get Ship Label" />
					</div>

					{loading ? (
						<div className="pr-loading-sticky">
							<div className="pr-spinner-wrapper">
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						</div>
					) : null}
				</Form>
			)}
		</Formik>
 );
}

export default GetShipLabel