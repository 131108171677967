import { PaymentTypes } from '../../orders/dtos/payment-type.dto';

export class CreateCustomDtfOrderStatuHistoryRequest {
	customDtfOrderId: string;
	customDtfOrderStatuId: number;
	reason: string;
	paymentMethodType: PaymentTypes;

	constructor(props: Partial<CreateCustomDtfOrderStatuHistoryRequest>) {
		this.customDtfOrderId = props.customDtfOrderId || '';
		this.customDtfOrderStatuId = props.customDtfOrderStatuId || 0;
		this.reason = props.reason || '';
		this.paymentMethodType = props.paymentMethodType || PaymentTypes.Balance;
	}

	get getConvertUriExtension() {
		return `create-statu-history`;
	}
}
