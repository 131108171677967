import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import integrationsServiceOld from 'services/integrations/integrations-service';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ResponseBase } from 'models/_commons/responses/response-base';
import { getPrintramStores } from 'redux/features/printram-store/printramStoreSlice';
import StoreItem from './components/store-item/StoreItem';
import sellerStoresService from 'features/seller-stores/seller-stores-service';
import { DeleteSellerStoreRequest } from 'features/seller-stores/delete-seller-store/delete-seller-store.request';
import { ProgressSpinner } from 'primereact/progressspinner';
import CreateStoreDialog from './components/dialogs/CreateStoreDialog';
import { CreateSellerStoreRequest } from 'features/seller-stores/create-seller-store/create-seller-store.request';
import UpdateStoreDialog from './components/dialogs/UpdateStoreDialog';
import { SellerStore } from 'features/seller-stores/dtos/seller-store.dto';
import { UpdateSellerStoreRequest } from 'features/seller-stores/update-seller-store/update-seller-store.request';
import { EtsyStore } from 'models/dtos/store/etsy-store';
import ConnectStoreDialog from './components/dialogs/ConnectStoreDialog';
import integrationsService from 'features/integrations/_common/integrations.service';
import { DeleteAuthenticationRequest } from 'features/integrations/_common/delete-authentication/delete-authentication.request';

const MyStores = () => {
    const user = useAppSelector((state) => state.auth.data?.user);
    const stores = useAppSelector((state) => state.printramStore.data || []);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code') || '';
    const state = searchParams.get('state') || '';

    const [loading, setLoading] = useState<boolean>(true);
    const [storeLoading, setStoreLoading] = useState<boolean>(false);
    const [connectionVisible, setConnectionVisible] = useState<boolean>(false);
    const [etsyStore, setEtsyStore] = useState<EtsyStore | undefined>(undefined);
    const [createStoreVisible, setCreateStoreVisible] = useState<boolean>(false);
    const [selectedStore, setSelectedStore] = useState<SellerStore | undefined>(undefined);
    const [updateStoreVisible, setUpdateStoreVisible] = useState<boolean>(false);

    useEffect(() => {
        getStores();
    }, []);

    useEffect(() => {
        if (code) {
            setEtsyAuthToken();
        }
    }, [code]);

    const getStores = async () => {
        try {
            setLoading(true);
            if (!user) throw new Error('User not found');
            await dispatch(getPrintramStores(user.userId));
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const setEtsyAuthToken = async () => {
        try {
            setLoading(true);
            if (!user) throw new Error('User not found');
            const response: ResponseBase = await integrationsServiceOld.setEtstAuthToken({ code, userId: user.userId, storeId: state });
            if (!response.isSuccess) throw new Error('Failed to set Etsy Auth Token');
        } catch (error) {
        } finally {
            searchParams.delete('code');
            searchParams.delete('state');
            setSearchParams(searchParams);
            setLoading(false);
            navigate("/product/catalog");
        }
    };

    const onConnectStore = (store: SellerStore) => {
        navigate(`/account/integrations/?storeId=${store.storeId}`);
    };

    const onDisconnectStore = async () => {
        try {
            setStoreLoading(true);
            if (!user) throw new Error('User not found');
            const request = new DeleteAuthenticationRequest(user.userId);
            const response = await integrationsService.deleteAuthentication(request);
            if (!response) throw new Error('Failed to disconnect store');
            await getStores();
        } catch (error) {
        } finally {
            setStoreLoading(false);
        }
    };

    const submitCreateStore = async (name: string) => {
        try {
            setLoading(true);
            const response = await sellerStoresService.create(new CreateSellerStoreRequest({ name, sellerId: user?.userId }));
            if (!response.isSuccess) throw new Error('Failed to create store');
            await getStores();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const submitUpdateStore = async (id: string, name: string) => {
        try {
            setStoreLoading(true);
            const response = await sellerStoresService.update(new UpdateSellerStoreRequest({ id, name, sellerId: user?.userId }));
            if (!response.isSuccess) throw new Error('Failed to update store');
            await getStores();
        } catch (error) {
        } finally {
            setStoreLoading(false);
        }
    };

    const submitConnectStore = async (connectData: { etsyStore: EtsyStore; sellerStore: SellerStore }) => {
        try {
            setLoading(true);
            if (!user) throw new Error('User not found');
            const response = await integrationsServiceOld.createEtsyStoreWithEtsyShopId({
                sellerId: user.userId,
                storeId: connectData.sellerStore.storeId,
                etsyShopId: connectData.etsyStore.shop_id,
            });
            if (!response.isSuccess) throw new Error('Failed to connect store');
            await getStores();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const Loading = () => (
        <div>
            <ProgressSpinner className="absolute top-50 left-50" style={{ width: '50px', height: '50px', transform: 'translate(-50%, -50%)' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
    );

    return loading ? (
        <Loading />
    ) : (
        <div className="relative text-sm">
            <div className="flex flex-row justify-content-between align-items-center">
                <h4 className="font-400 my-3">My Stores</h4>
                <PrButton className="transition-linear border-round-md text-sm" text="Add New Store" onClick={() => setCreateStoreVisible(true)} />
            </div>
            {stores.map((store, index) => (
                <StoreItem
                    key={store.storeId}
                    store={store}
                    length={stores.length}
                    index={index}
                    loading={storeLoading}
                    connected={store.isConnectedAnyMarketPlace}
                    onUpdateStore={(store: SellerStore) => {
                        setSelectedStore(store);
                        setUpdateStoreVisible(true);
                    }}
                    onConnectStore={(store: SellerStore) => onConnectStore(store)}
                    onDisconnectStore={() => onDisconnectStore()}
                />
            ))}
            <CreateStoreDialog
                visible={createStoreVisible}
                setVisible={setCreateStoreVisible}
                onSubmit={(name) => submitCreateStore(name)}
            />
            <UpdateStoreDialog
                visible={updateStoreVisible}
                store={selectedStore}
                setStore={setSelectedStore}
                setVisible={setUpdateStoreVisible}
                onSubmit={(id, name) => submitUpdateStore(id, name)}
            />
            <ConnectStoreDialog
                visible={connectionVisible}
                store={selectedStore}
                etsyStore={etsyStore}
                setStore={setSelectedStore}
                setEtsyStore={setEtsyStore}
                setVisible={setConnectionVisible}
                onSubmit={(connectData) => submitConnectStore(connectData)}
            />
        </div>
    );
};

export default MyStores;
