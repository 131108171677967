import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetContactsRequest {
	pagination: PaginationDto;

	constructor(props: Partial<GetContactsRequest>) {
		this.pagination = props.pagination || new PaginationDto(1, 20);
	}

	get getConvertUriExtension() {
		return `get-contacts`;
	}
}
