import React, { useContext, useEffect } from 'react';
import { ShippingInfo } from '../shipping-info/ShippingInfo';
import ShippingRates from '../ship-rates/ShippingRates';
import { SellerReorderDetailContext, SellerReorderDetailContextType, reorderSteps } from '../../../context/SellerReorderDetailContextProvider';
import { reorderService } from 'features/reorders/_common/reorder.service';
import { CostBreakdown } from '../breakdown/CostBreakdown';
import { GetShippingRatesForReorderRequest } from 'features/reorders/_common/get-shipping-rates/get-shipping-rates.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { StepWithComponentEnum } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import { ReorderCalculateRequest } from 'features/reorders/_common/calculate/re-order-calculate.request';

export const ShipSummary = () => {
	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	const getRates = async () => {
		try {
			context.setLoading(true);

			if (!context.reorderIdFromRoute?.newOrderId) throw '';

			const response = await reorderService.getShippingRates(new GetShippingRatesForReorderRequest(context.reorderIdFromRoute.newOrderId));

			if (!response.isSuccess || !response.data) throw '';

			const minRate = Math.min(...response.data.shippingRates.map((_rate) => (_rate.discountedAmount ? _rate?.discountedAmount?.formattedPricePerUnit : _rate.shippingRate.amount)));

			const selectedRate = response.data.shippingRates.find((_rate) => _rate?.discountedAmount?.formattedPricePerUnit === minRate || _rate.shippingRate.amount === minRate);

			const calcReq = new ReorderCalculateRequest({ reOrderId: context.reorderIdFromRoute?.reorderId, shippingRate: selectedRate?.shippingRate });

			const calcResponse = await reorderService.calculateReorder(calcReq);

			if (!calcResponse.isSuccess || !calcResponse.data) throw '';

			context.setShippingRates(response.data.shippingRates);

			context.setSelectedRate(selectedRate);

			context.setCalculatedPrice(calcResponse.data);
		} catch (error) {
		} finally {
			context.setLoading(false);
		}
	};

	useEffect(() => {
		if (!context.isNewShippingRatesCalculateRequired || context.reorder?.deliveryType !== 1) return;

		getRates();
	}, [context.reorderIdFromRoute?.reorderId, context.reorderIdFromRoute?.newOrderId, context.isNewShippingRatesCalculateRequired]);

	return (
		<React.Fragment>
			{context.reorder?.deliveryType !== 2 ? (
				<div className="container-body p-5">
					<ShippingInfo />
				</div>
			) : null}

			{context.reorder?.deliveryType !== 2 ? (
				<div className="container-body p-5">
					<ShippingRates />
				</div>
			) : null}

			{!!context.calculatedPrice ? (
				<div className="container-body text-center p-5">
					<CostBreakdown {...context.calculatedPrice} />

					<div className="flex justify-content-center mt-4">
						<PrButton
							text="Continue to review"
							onClick={() => {
								for (const step of reorderSteps) {
									if (step.value === StepWithComponentEnum.Shipping) {
										step.isCanNext = true;
										step.isCompleted = true;

										continue;
									}

									if (step.value === StepWithComponentEnum.ReviewOrder) {
										context.setReorderStep(step);

										continue;
									}
								}

								context.setReorderRequest((current) => {
									const newCurrent = structuredClone(current);

									newCurrent.shippingRate = context.selectedRate?.shippingRate || null;

									return newCurrent;
								});
							}}
						/>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};
