export enum UserRoleEnum {
	Unknown = 0,
	Admin = 1,
	Provider = 2,
	Seller = 3,
	Merchant = 4,
	PrintCenter = 5
}

export class SurveyQuestionOptionDtoRequest {
	order: number;
	text: string;
	textCanBeAdded: boolean;

	constructor(props: Partial<SurveyQuestionOptionDtoRequest>) {
		this.order = props.order || 0;
		this.text = props.text || '';
		this.textCanBeAdded = props.textCanBeAdded || false;
	}
}

export class SurveyQuestionDtoRequest {
	order: number;
	question: string;
	options: SurveyQuestionOptionDtoRequest[];

	constructor(props: Partial<SurveyQuestionDtoRequest>) {
		this.order = props.order || 0;
		this.question = props.question || '';
		this.options = props.options || [];
	}
}

export class SurveyCreateRequest {
	name: string;
	isActive: boolean;
	roles: UserRoleEnum[];
	questions: SurveyQuestionDtoRequest[];
	isSaveAnswersToCrm: boolean;

	constructor(props: Partial<SurveyCreateRequest>) {
		this.name = props.name || '';
		this.isActive = props.isActive || true;
		this.roles = props.roles || [3];
		this.questions = props.questions || [];
		this.isSaveAnswersToCrm = props.isSaveAnswersToCrm || false;
	}

	get getConvertUriExtension() {
		return ``;
	}
}
