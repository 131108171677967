import { AxiosRequestConfig } from 'axios';
import { confirmDialog } from 'primereact/confirmdialog';
import { ResponseBase } from '../../models/_commons/responses/response-base';
import axiosInstance from '../../interceptors/axios-interceptor';
import formDataHelper from '../../helpers/form-data.helper';

class HttpBaseService {
	private controller = '';

	constructor(controller: string) {
		this.controller = controller + '/';
	}

	protected async getData(method: string, data: any = null) {
		const newUrl = this.controller + method;

		let params = new URLSearchParams();

		if (!!data) for (let property in data) if (!!data[property]) params.append(property, data[property]);

		const response = await axiosInstance.get(newUrl, { params });

		return response.data;
	}

	protected async postData(method: string, request?: any, sendAsFormData: boolean = false, uploadAction?: (state: ProgressEvent) => void) {
		const newUrl = this.controller + method;

		if (!sendAsFormData) {
			const response = await axiosInstance.post(newUrl, request);

			return response.data;
		}

		const formData = new FormData();

		formDataHelper.buildFormData(formData, request);

		const response = await axiosInstance.post(newUrl, formData, {
			onUploadProgress: uploadAction
		});

		return response.data;
	}

	protected async putData(method: string, request: any, sendAsFormData: boolean = false) {
		const newUrl = this.controller + method;

		if (!sendAsFormData) {
			const response = await axiosInstance.put(newUrl, request);

			return response.data;
		}

		const formData = new FormData();

		formDataHelper.buildFormData(formData, request);

		const response = await axiosInstance.put(newUrl, formData, { headers: { encrypt: 'multipart/form-data' } });

		return response.data;
	}

	protected async patchData(method: string, request: any, sendAsFormData: boolean = false) {
		const newUrl = this.controller + method;

		if (!sendAsFormData) {
			const response = await axiosInstance.patch(newUrl, request);

			return response.data;
		}

		const response = await axiosInstance.patch(newUrl, request, { headers: { encrypt: 'multipart/form-data' } });

		return response.data;
	}

	private async deleteConfirmed(method: string, id?: string, request?: any): Promise<ResponseBase> {
		const newUrl = this.controller + method;

		const config: AxiosRequestConfig = {};

		config.data = request ? request : { id };

		const response = await axiosInstance.delete(newUrl, config);

		return response.data;
	}

	protected async deleteData(method: string, id?: string, request?: any, dataType?: string): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			confirmDialog({
				header: 'Delete Confirmation',
				message: dataType === 'store' ? 'Your orders connected to this store will also be irrevocably deleted. Are you sure?' : dataType === 'product' ? 'Your orders connected to this product will also be irrevocably deleted. Are you sure?' : 'Do you want to delete this record?',
				icon: 'pi pi-info-circle',
				acceptClassName: 'p-button-danger',
				className: 'delete-confirm-dialog',
				closable: false,
				accept: async () => {
					try {
						const response = await this.deleteConfirmed(method, id, request);
						resolve(response.isSuccess || false);
					} catch (error) {
						resolve(false);
					}
				},
				reject: () => resolve(false)
			});
		});
	}
}

export default HttpBaseService;
