import { CreateCategoryTransportInformationCommandDto } from "./dtos/create-category-transport-information-command.dto";

export class CreateCategoryTransportInformationsRequest {
	categoryId: string = '';
	createCategoryTransportInformationCommands: CreateCategoryTransportInformationCommandDto[] = [];

	get getConvertUriExtension() {
		return `create-category-transport-informations`;
	}

	constructor(categoryId: string, createCategoryTransportInformationCommands: CreateCategoryTransportInformationCommandDto[]) {
		this.categoryId = categoryId;
		this.createCategoryTransportInformationCommands = createCategoryTransportInformationCommands;
	}
}