import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { SelectItem, SelectItemOptionsType } from "primereact/selectitem";
import { Skeleton } from "primereact/skeleton";
import TreeNode from "primereact/treenode";
import { TreeSelect } from "primereact/treeselect";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InformationsTable from "./components/InformationsTable";
import { CategoryBaseDto } from "features/categories/dtos/bases/category.base.dto";
import { CreateCategoryTransportInformationsRequest } from "features/categories/derived-features/category-transport-informations/create-category-transport-informations/create-category-transport-informations.request";
import sizeService from "services/dimensions/size-service";
import { Size } from "models/dtos/dimesions/size";
import countryService from "services/country-service";
import { CreateCategoryTransportInformationCommandDto } from "features/categories/derived-features/category-transport-informations/create-category-transport-informations/dtos/create-category-transport-information-command.dto";
import categoryTransportInformationsService from "features/categories/derived-features/category-transport-informations/category-transport-informations-service";
import { GetCategoriesRequest } from "features/categories/_common/get-categories/get-categories.request";
import categoryService from "features/categories/_common/category.service";


const CreateShippingInformation = () => {
	const navigate = useNavigate();

	const [categories, setCategories] = useState<CategoryBaseDto[] | null>(null)
	const [sizes, setSizes] = useState<SelectItemOptionsType>();
	const [countries, setCountries] = useState<SelectItemOptionsType>();
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<any>()
	const [selectedSizes, setSelectedSizes] = useState<Array<any>>([])
	const [selectedCountries, setSelectedCountries] = useState<Array<any>>([]);
	const [firstItemPrice, setFirstItemPrice] = useState<string>("");
	const [additionalItemPrice, setAdditionalItemPrice] = useState<string>("")
	const [minBusinessDay, setMinBusinessDay] = useState<string>("");
	const [maxBusinessDay, setMaxBusinessDay] = useState<string>("");
	const [transportInformations, setTransfromInformations] = useState<any>({categoryId:selectedCategory?.id || '', createCategoryTransportInformationCommands: []})
	const [loading, setLoading] = useState(false)

	const getCategories = useCallback(() => {
		return new Promise(async(resolve) => {
			try {
				const request = new GetCategoriesRequest()

				const response = await categoryService.getAll(request)

				if (!response.isSuccess) throw ''

				resolve(response.data)
			} finally {}
		})
	}, []);

	const getSizes = () => {
		return new Promise((resolve) => {
			sizeService.getSizes({ pagination: { orderBy: 1, pageNumber: 1 } }).then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((size: Size) => ({ label: size.shortName, value: { name: size.shortName, id: size.id } } as SelectItem));
				if (!options) return;

				resolve(options);
			});
		});
	};

	const getCountries = () => {
		return new Promise((resolve) => {
			countryService.getCountries().then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((country) => ({ label: country.name, value: { id: country.id, name: country.name } } as SelectItem));
				if (!options) return;

				resolve(options);
			});
		});
	};

	   const generateTreeNode = (category: CategoryBaseDto): TreeNode => {
			const treeNodeForCurrentSubCategory: TreeNode = { key: category.id, label: category.name, data: category };
			return treeNodeForCurrentSubCategory;
		};

		const mapCategoryToParent = useCallback((parentCategory: CategoryBaseDto, treeNode: TreeNode) => {
			if (parentCategory.subCategories === undefined) return;

			parentCategory?.subCategories?.forEach((_subCategory) => {
				if (treeNode.children === undefined) treeNode.children = [];

				const treeNodeForCurrentSubCategory = generateTreeNode(_subCategory);

				treeNode.children?.push(treeNodeForCurrentSubCategory);

				mapCategoryToParent(_subCategory, treeNodeForCurrentSubCategory);
			});
		}, []);

		const mapCategoriesForTree = useCallback(
			(categories: CategoryBaseDto[]) => {
				let treeNode: TreeNode[] = [];

				categories.forEach((_category) => {
					const treeNodeForCurrentCategory = generateTreeNode(_category);

					mapCategoryToParent(_category, treeNodeForCurrentCategory);

					treeNode.push(treeNodeForCurrentCategory);
				});

				setTreeCategories(treeNode);
			},
			[mapCategoryToParent]
		);

	const isAddEnabled = () => {
		if (!selectedCategory || !selectedCountries || !selectedSizes || !firstItemPrice || !additionalItemPrice || !minBusinessDay || !maxBusinessDay) return true;
		return false;
	}

	const getAll = () => {
		setLoading(true);

		Promise.all([getCategories(), getSizes(), getCountries()])
			.then((values: any) => {
				setCategories(values[0]);
				setSizes(values[1]);
				setCountries(values[2]);
			})
			.finally(() => setLoading(false));
	}

	const onCategoryChange = (e: any) => setSelectedCategory({id:e.target.value, name: e.originalEvent.target.innerText})

	const addTransfortInformations = () => {
		if (!selectedCategory) return;

		let addReq: CreateCategoryTransportInformationCommandDto[] = selectedSizes.map((size) => ({
			sizeId: size.id,
			countryIdentities: selectedCountries.map((country) => country.id),
			firstItemPrice: Math.round(parseFloat(firstItemPrice) * 100),
			additionalItemPrice: Math.round(parseFloat(additionalItemPrice) * 100),
			minBusinessDay: parseInt(minBusinessDay),
			maxBusinessDay: parseInt(maxBusinessDay),
			sizeName: size.name,
			countries: selectedCountries.map((country) => country.name)
		}));

		setTransfromInformations({ categoryId: selectedCategory.id, createCategoryTransportInformationCommands: [...transportInformations?.createCategoryTransportInformationCommands, ...addReq ]});
	}

	const onSave = async() => {
		try {
			const request = new CreateCategoryTransportInformationsRequest(transportInformations.categoryId, transportInformations.createCategoryTransportInformationCommands)

			const response = await categoryTransportInformationsService.create(request)

			if (!response.isSuccess) throw '';

			navigate('/shipping-informations')
		} finally {}
	};

	useEffect(() => {
		if (!selectedCategory) return;

		setTransfromInformations({ categoryId: selectedCategory.id, createCategoryTransportInformationCommands: transportInformations.createCategoryTransportInformationCommands})
	}, [selectedCategory])

	 useEffect(() => {
			if (!categories || categories.length < 1) return;

			mapCategoriesForTree(categories);
		}, [categories, mapCategoriesForTree]);

	useEffect(() => {
		getAll()
	}, [])

  return (
		<div className="card">
			{!loading ? (
				<div>
					<h5>New Shipping Information</h5>
					<div className="my-3">
						<span>Category</span>
						<TreeSelect filter value={selectedCategory?.id || ''} options={treeCategories} onChange={onCategoryChange} placeholder="Select Parent Category" className="w-full" />
					</div>
					<div className="flex gap-4">
						<div className="flex-1">
							<span>Sizes</span>
							<MultiSelect filter options={sizes} value={selectedSizes} maxSelectedLabels={3} onChange={(e) => setSelectedSizes(e.value)} placeholder="Select sizes" className="w-full" />
						</div>
						<div className="flex-1">
							<span>Countries</span>
							<MultiSelect filter options={countries} value={selectedCountries} maxSelectedLabels={3} onChange={(e) => setSelectedCountries(e.value)} placeholder="Select countries" className="w-full" />
						</div>
					</div>

					<div className="flex gap-4 my-4">
						<div className="flex-1">
							<span>First Item Price</span>
							<InputText value={firstItemPrice} onChange={(e) => setFirstItemPrice(e.target.value)} keyfilter="num" placeholder="0" className="w-full" />
						</div>
						<div className="flex-1">
							<span>Additional Item Price</span>
							<InputText value={additionalItemPrice} onChange={(e) => setAdditionalItemPrice(e.target.value)} keyfilter="num" placeholder="0" className="w-full" />
						</div>
					</div>

					<div className="flex gap-4 my-4">
						<div className="flex-1">
							<span>Min Business Day</span>
							<InputText value={minBusinessDay} onChange={(e) => setMinBusinessDay(e.target.value)} keyfilter="num" placeholder="0" className="w-full" />
						</div>
						<div className="flex-1">
							<span>Max Business Day</span>
							<InputText value={maxBusinessDay} onChange={(e) => setMaxBusinessDay(e.target.value)} keyfilter="num" placeholder="0" className="w-full" />
						</div>
					</div>

					<div className="w-full text-right">
						<Button type="button" label="Add Shipping Information" disabled={isAddEnabled()} icon="pi pi-plus" onClick={() => addTransfortInformations()} />
					</div>

					{transportInformations.createCategoryTransportInformationCommands.length > 0 && (
						<div>
						  <InformationsTable transportInformations={transportInformations} setTransfromInformations={setTransfromInformations} categoryName={selectedCategory?.name || ''} />
							<div className="w-full text-right">
								<Button type="button" label="Save Transport Informations" onClick={() => onSave()} icon="pi pi-save" />
							</div>
						</div>
					)}
				</div>
			) : (
				<div className="grid">
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
				</div>
			)}
		</div>
  );
}

export default CreateShippingInformation