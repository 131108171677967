import React from 'react';

interface Props extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onInput' | 'ref'> {
	label: string;
	description: string;
	required: boolean;
	wrapperClassName: string;
}

const PrTextInput = (props: Partial<Props>) => {
	return (
		<div className={`pr-input-group w-full ${!!props.wrapperClassName ? props.wrapperClassName : ''}`}>
			{!!props.label ? (
				<label htmlFor={props.id || ''} className="pr-input-label">
					<h5 className="pr-input-label-title">
						{props.label || ''} {typeof props.required !== 'undefined' ? !props.required ? <span className="pr-input-label-optional">(Optional)</span> : <span className="pr-input-label-required">*</span> : null}
					</h5>

					{props.description ? <p className="pr-input-label-description">{props.description}</p> : null}
				</label>
			) : null}

			<input {...props} className={`${!!props.className ? props.className : ''} pr-input-control`} required={false} />
		</div>
	);
};

export default PrTextInput;
