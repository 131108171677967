import { DateFilterDto } from "features/_common/dtos/dateFilter/date-filter.dto";
import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetBalanceHistoryByUserRequest {
	userId: string = '';
	pagination: PaginationDto = new PaginationDto();
	filter: DateFilterDto;

	constructor(userId: string, pagination: PaginationDto, filter: DateFilterDto) {
		this.filter = filter;
		this.userId = userId;
		if (!!pagination) this.pagination = pagination;
	}

	get getConvertUriExtension() {
		return `${this.userId}/history`;
	}
}