import HttpBaseService from "services/_common/http-base-service";
import { GetAllDtfListsRequest } from "./get-all-dtf-lists/get-all-dtf-lists.request";
import { GetAllDtfListsResponse } from "./get-all-dtf-lists/get-all-dtf-lists.response";
import { CreateDtfListWithOrdersRequest } from "./create-dtf-list-with-orders/create-dtf-list-with-orders.request";
import { CreateDtfListWithOrdersResponse } from "./create-dtf-list-with-orders/create-dtf-list-with-orders.response";
import { AddRangeOrderstoDtfListRequest } from "./add-range-orders-to-dtf-list/add-range-orders-to-dtf-list.request";
import { AddRangeOrderstoDtfListResponse } from "./add-range-orders-to-dtf-list/add-range-orders-to-dtf-list.response";
import { GetDtfListRequest } from "./get-dtf-list/get-dtf-list.request";
import { GetDtfListResponse } from "./get-dtf-list/get-dtf-list.response";
import { CreateDtfListStatusHistoryRequest } from "./create-dtf-list-status-history/create-dtf-list-status-history.request";
import { CreateDtfListStatusHistoryResponse } from "./create-dtf-list-status-history/create-dtf-list-status-history.response";
import { RemoveOrderFromDtfListRequest } from "./remove-order-from-dtf-list/remove-order-from-dtf-list.request";
import { UploadDtfImageRequest } from "./upload-dtf-image/upload-dtf-image.request";
import { UploadDtfImageResponse } from "./upload-dtf-image/upload-dtf-image.response";
import { DownloadDtfImageRequest } from "./download-dtf-image/download-dtf-image.request";
import { DownloadDtfImageResponse } from "./download-dtf-image/download-dtf-image.response";
import { GenerateOrderReceiptDtfRequest } from "./generate-order-receipt-dtf/generate-order-receipt-dtf.request";
import { GenerateOrderReceiptDtfResponse } from "./generate-order-receipt-dtf/generate-order-receipt-dtf.response";
import { SendDtfListToApiRequest } from "./send-dtf-list-to-api/send-dtf-list-to-api.request";
import { SendDtfListToApiResponse } from "./send-dtf-list-to-api/send-dtf-list-to-api.response";
import { ApproveDtfListRequest } from "./approve-dtf-list/approve-dtf-list.request";
import { ApproveDtfListResponse } from "./approve-dtf-list/approve-dtf-list.response";
import { CancelDtfListRequest } from "./cancel-dtf-list/cancel-dtf-list.request";
import { CancelDtfListResponse } from "./cancel-dtf-list/cancel-dtf-list.response";
import { ReadyToPrintDtfListRequest } from "./ready-to-print-dtf-list/ready-to-print-dtf-list.request";
import { ReadyToPrintDtfListResponse } from "./ready-to-print-dtf-list/ready-to-print-dtf-list.response";
import {
	CreateAutomatedDtfListWithOrdersRequest
} from "./create-automated-dtf-list-with-order/create-automated-dtf-list-with-order-request";
import {
	CreateAutomatedDtfListWithOrdersResponse
} from "./create-automated-dtf-list-with-order/create-automated-dtf-list-with-order-response";
import {SearchDtfListByOrderCodeRequest} from "./search-dtf-list-by-order-code/search-dtf-list-by-order-code-request";
import {DownloadAllDtfFilesRequest} from "./download-all-dtf-files/download-all-dtf-files-request";
import {DownloadAllDtfFilesResponse} from "./download-all-dtf-files/download-all-dtf-files-response";

class DtfService extends HttpBaseService {
	constructor() {
		super('dtfs');
	}

	async getAllDtfLists(request: GetAllDtfListsRequest): Promise<GetAllDtfListsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
	async searchDtfListByOrderCode(request: SearchDtfListByOrderCodeRequest): Promise<GetAllDtfListsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createDtfListWithOrders(request: CreateDtfListWithOrdersRequest): Promise<CreateDtfListWithOrdersResponse> {
		return await this.postData(request.getConvertUriExtension, request);

	}	async createAutomatedDtfListWithOrders(request: CreateAutomatedDtfListWithOrdersRequest): Promise<CreateAutomatedDtfListWithOrdersResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async addRangeOrderstoDtfList(request: AddRangeOrderstoDtfListRequest): Promise<AddRangeOrderstoDtfListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getDtfDetail(request: GetDtfListRequest): Promise<GetDtfListResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createDtfListStatusHistory(request: CreateDtfListStatusHistoryRequest): Promise<CreateDtfListStatusHistoryResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async removeOrderFromDtfList(request: RemoveOrderFromDtfListRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension, '', request);
	}

	async uploadDtfImage(request: UploadDtfImageRequest): Promise<UploadDtfImageResponse> {
		return await this.postData(request.getConvertUriExtension, request, true)
	}

	async downloadDtfImage(request: DownloadDtfImageRequest): Promise<DownloadDtfImageResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async generateOrderReceiptDtf(request: GenerateOrderReceiptDtfRequest): Promise<GenerateOrderReceiptDtfResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async downloadAllDtfFiles(request: DownloadAllDtfFilesRequest): Promise<DownloadAllDtfFilesResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async sendDtfListToApi(request: SendDtfListToApiRequest): Promise<SendDtfListToApiResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async approveDtfList(request: ApproveDtfListRequest): Promise<ApproveDtfListResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async cancelDtfList(request: CancelDtfListRequest): Promise<CancelDtfListResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async readyToPrintDtfList(request: ReadyToPrintDtfListRequest): Promise<ReadyToPrintDtfListResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}
}

export default new DtfService();