export class GetShippingRatesForUpgradeRequest {
	orderId: string;

	constructor(orderId: string) {
		this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return `get-shipping-rates-for-upgrage/${this.orderId}`;
	}
}
