import { ErrorMessage } from 'formik';
import { InputText, InputTextProps } from 'primereact/inputtext';

interface Props extends InputTextProps {
    label: string;
	name: string;
	optional?: boolean;
	labelStyle?: React.CSSProperties;
}

const TextInput = ({ label, name, optional, labelStyle, ...props }: Props) => {
    return (
        <>
            <div className="flex align-items-center justify-content-between px-1 mb-1">
				<label style={labelStyle}>{label} { !!optional ? <small>(Optional)</small> : '' }</label>
                <ErrorMessage name={name} component="small" className="text-red font-medium" />
            </div>
            <InputText name={name} {...props} className="w-full" />
        </>
    );
};

export default TextInput;
