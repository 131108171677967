import HttpBaseService from './_common/http-base-service';
import { RegisterIndividualRequest } from '../models/requests/register-individual-request';
import { ResponseBase } from '../models/_commons/responses/response-base';
import { PaginationRequest } from '../models/_commons/requests/pagination-request';
import { RegisterOrLoginWithGoogleRequest } from '../models/requests/google-login-request';
import localStorageHelper from '../helpers/local-storage.helper';
import { DataResponse } from '../models/_commons/responses/data-response';
import { Auth } from '../models/dtos/auth/auth';
import { UpdateSellerRequest } from '../models/requests/sellers/update-seller-request';
import { UpdateSellerStatuRequest } from '../models/requests/sellers/update-seller-statu-request';

class SellerService extends HttpBaseService {
    constructor() {
        super('sellers');
    }

    async register(request: RegisterIndividualRequest) {
        return await this.postData('create-seller', request);
/*        try {
            const response = (await this.postData('create-seller', request)) as ResponseBase;
            return response.isSuccess;
        } catch (error) {
            return false;
        }*/
    }

    async registerOrLoginWithGoogle(request: RegisterOrLoginWithGoogleRequest) {
        const response = (await this.postData('create-or-login-seller-with-google', request)) as DataResponse<Auth>;
        if (response.isSuccess) localStorageHelper.setStringfyForData('auth', response.data);
        return response;
    }

    async getSellersForAdmin (request: PaginationRequest) {
        return await this.postData('get-sellers-for-admin', { pagination: request });
    }

    async update(request: UpdateSellerRequest) {
        return await this.putData('update-seller-with-user',request)
    }

    async updateStatu(request: UpdateSellerStatuRequest) {
        return await this.patchData('update-statu',request)
    }

    async delete(sellerId: string): Promise<boolean>{
        return await this.deleteData('soft-delete',sellerId)
    }
}

export default new SellerService();
