import { IsUserHaveAnAccountRequest } from 'features/stripe/_common/is-user-have-an-account/is-user-have-an-account.request';
import stripeService from 'features/stripe/_common/stripe.service';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styles from './Withdrawals.module.scss';
import { CreateStripeAccountRequest } from 'features/stripe/_common/create-account/create-account.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { GetBalanceByUserIdRequest } from 'features/balances/_common/get-balance-by-user-id/get-balance-by-user-id.request';
import balanceService from 'features/balances/_common/balance.service';
import { Price } from 'features/products/dtos/prices/price.dto';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import currencyHelper from 'helpers/curreny.helper';
import { CreateWithdrawRequestRequest } from 'features/balances/_common/create-withdraw-request/create-withdraw-request.request';
import { confirmDialog } from 'primereact/confirmdialog';

const Withdrawals = () => {
	const userId = useAppSelector((state) => state.auth.data?.user.userId || '');
	const [isUserHaveAnAccount, setIsUserHaveAnAccount] = useState<boolean>();
	const [balance, setBalance] = useState<Price>();
	const [amount, setAmount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const inputStyle = { backgroundColor: 'white', backgroundImage: 'none', borderWidth: 1, borderColor: 'gray', borderRadius: '.5rem', height: '2.4rem' };

	const buttonActiveHandler = () => {
		if (amount > 0 && isUserHaveAnAccount) return false
		else return true
	}

	const getUserBalance = () => {
		return new Promise(async (resolve) => {
			try {
				if (!userId) throw '';

				const request = new GetBalanceByUserIdRequest(userId);

				const response = await balanceService.getBalanceByUserId(request);

				if (!response.data?.price) throw '';

				resolve(response?.data?.price);
			} finally {}
		});
	};

	const createStripeAccount = async () => {
		try {
			if (!userId) throw '';

			setLoading(true);

			const request = new CreateStripeAccountRequest(userId);

			const response = await stripeService.createAccount(request);

			if (!response.isSuccess) throw '';

			window.open(response.data?.url, '_self');
		} catch {
		} finally {
			setLoading(false);
		}
	};

	const isUserHaveStripeAccount = async () => {
		return new Promise(async (resolve) => {
			try {
				if (!userId) throw '';

				const request = new IsUserHaveAnAccountRequest(userId);

				const response = await stripeService.isUserHaveAnAccount(request);

				if (!response.isSuccess) {
					resolve(false);
					throw ''
				}

				resolve(true);
			} catch(error: any) { if(error.response.data.isSuccess == false) resolve(false) } finally {}
		});
	};

	const showWithdrawRequestReceived = () => {
		confirmDialog({
			header: 'Withdraw Request Received',
			icon: 'pi pi-info-circle',
			message: 'We are received your request. Information about the transaction will be made through the ticket.',
			acceptLabel: 'Ok',
			acceptClassName: 'px-4 bg-blue-600',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: () => {}
		});
	};

	const createWithdrawRequest = async () => {
		try {
			if (!userId) throw '';

			setLoading(true)

			const request = new CreateWithdrawRequestRequest(userId, Math.round(parseFloat(amount.toString()) * 100));

			const response = await balanceService.createWithdrawRequest(request);

			if (!response.isSuccess) throw ''

			setAmount(0)
			showWithdrawRequestReceived();
		} catch { }
		finally { setLoading(false) }
	}

	useEffect(() => {
		if (!userId) return;

		setLoading(true);
		Promise.all([isUserHaveStripeAccount(), getUserBalance()])
			.then((values: any) => {
				setIsUserHaveAnAccount(values[0]);
				setBalance(values[1]);
			})
			.catch()
			.finally(() => setLoading(false));
	}, []);

	return (
		<React.Fragment>
			<div className="container-body p-3">
				<h4 className="m-0">Withdraw Balance</h4>
				<p className="text-color-secondary">Initiate a withdrawal from your available balance. Your request will be assessed within the following 48 business hours, and you will be notified via email once it has been processed.</p>

				{!isUserHaveAnAccount && (
					<div className={styles.stripeCard}>
						<p>You need to have a Stripe account to withdraw money from your balance.</p>
						<PrButton text="Create Stripe Account" onClick={createStripeAccount} type="secondary" />
					</div>
				)}

				<div className={styles.balanceCard}>
					<div className={styles.current}>
						<span>Current balance</span>
						<span className={styles.price}>{currencyHelper.formatPrice(balance?.formattedPricePerUnit || 0)}</span>
					</div>
					<div className={styles.withdraw}>
						<span>Withdraw amount:</span>
						<InputNumber inputId="currency-us" value={amount} onChange={(event) => setAmount(event.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} inputStyle={inputStyle} className="w-full" />
					</div>
					<div className={styles.button}>
						<PrButton text="Confirm withdrawal" onClick={createWithdrawRequest} disabled={buttonActiveHandler()} loading={loading} />
					</div>
				</div>
				<div className="text-sm m-0 text-right">*Processing fee is paid by the user during the balance withdrawal process.</div>

				{loading ? (
					<div className="pr-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default Withdrawals;
