export class CloseTicketRequest {
	ticketId: string;

	constructor(ticketId: string) {
		this.ticketId = ticketId;
	}

	get getConvertUriExtension() {
		return 'close'
	}
}