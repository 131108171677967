import { SellerStore } from 'features/seller-stores/dtos/seller-store.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';

type Props = {
	visible: boolean;
	store: SellerStore;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setStore: React.Dispatch<React.SetStateAction<SellerStore | undefined>>;
	onSubmit: (storeId: string, name: string) => void;
};

const UpdateStoreDialog = (props: Partial<Props>) => {
	const [name, setName] = useState<string>('');

	const submit = () => {
		props.setVisible?.(false);
		props.onSubmit?.(props.store?.storeId || '', name);
		props.setStore?.(undefined);
		setName('');
	};

	useEffect(() => {
		if (!props.store) return;

		setName(props.store.name);
	}, [props.store]);

	return (
		<Dialog
			header={`Update '${props.store?.name}' printram store`}
			visible={props.visible}
			style={{ maxWidth: '50rem' }}
			onHide={() => {
				props.setVisible?.(false);
				props.setStore?.(undefined);
			}}>
			<div className="flex gap-3 align-items-end">
				<PrTextInput
					label="Store Name"
					value={name}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							event.preventDefault();
							submit();
						}
					}}
					onChange={(e) => setName(e.target.value || '')}
					required
				/>

				<PrButton
					text="Update"
					icon={<span className="pi pi-fw pi-refresh" />}
					onClick={() => {
						submit();
					}}
				/>
			</div>
		</Dialog>
	);
};

export default UpdateStoreDialog;
