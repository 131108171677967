import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetBoostYourPotentialFormRequests {
	pagination: PaginationDto;
	contains?: string;

	constructor(pagination: PaginationDto, contains?: string) {
		this.pagination = pagination;
		if (contains) this.contains = contains;
	}

	get getConvertUriExtension() {
		return `get-boost-your-business-potential-with-printram-forms?${this.contains ? `contains=${this.contains}&` : ''}pagination.pageNumber=${this.pagination.pageNumber}&pagination.itemCount=${this.pagination.itemCount}&pagination.orderBy=${this.pagination.orderBy}`;
	}
}
