import { CreateCommentToTicketRequest } from 'features/tickets/_common/create-comment-to-ticket/create-comment-to-ticket.request';
import ticketsService from 'features/tickets/_common/tickets.service';
import { Form, Formik } from 'formik';
import toastHelper from 'helpers/toast.helper';
import ClassicEditorWithImages from 'helpers/widgets/Editor/ClassicEditorWithImages';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';

type props = { ticketId: string; getTicketDetail: Function };

const CreateComment = ({ ticketId, getTicketDetail }: props) => {
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const initialValues: CreateCommentToTicketRequest = { ticketId: ticketId || '', comment: '' } as any;

	const createComment = async(values: CreateCommentToTicketRequest) => {
		try {
			if (!comment || comment.length < 1) {
				toastHelper.warning('Comment is required');
				throw ''
			}

			setLoading(true);

			const request = new CreateCommentToTicketRequest(comment, ticketId);

			const response = await ticketsService.createCommentToTicket(request)

			if (!response.isSuccess) throw ''

			setComment("")
			getTicketDetail()
		} catch (error) {

		} finally {
			setLoading(false)
		}
	};

	return (
		<div className="relative">
			<Formik initialValues={initialValues} onSubmit={createComment}>
				{({}) => (
					<Form>
						<div className="flex w-full">
							<ClassicEditorWithImages comment={comment || ''} onChange={setComment} setLoading={setLoading}  />
						</div>

						{/* <TextareaInput name="comment" label="Comment" rows={5} onChange={handleChange} /> */}
						<div className="flex justify-content-end align-items-center mt-3">
							<PrButton text="Reply" btnType="submit" icon={<span className="pi pi-fw pi-send" />} loading={loading} />
						</div>
					</Form>
				)}
			</Formik>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default CreateComment;
