import dtfService from 'features/dtfs/_common/dtf.service';
import {GetDtfListRequest} from 'features/dtfs/_common/get-dtf-list/get-dtf-list.request';
import {DtfListDto, DtfOrderSide} from 'features/dtfs/dtos/dtf-for-list.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import {BsArrowRight} from 'react-icons/bs';
import styles from './DtfDetail.module.scss'
import dateHelper from 'helpers/dateHelper';
import stringHelper from 'helpers/string.helper';
import {confirmDialog} from 'primereact/confirmdialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {CiCircleRemove, CiWarning} from 'react-icons/ci';
import {
    RemoveOrderFromDtfListRequest
} from 'features/dtfs/_common/remove-order-from-dtf-list/remove-order-from-dtf-list.request';
import UploadDtf from './components/UploadDtf';
import {PrDropdownType} from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import DtfDownloadDropdown from 'helpers/widgets/Order/Dtf/DtfDownloadDropdown/DtfDownloadDropdown';
import DtfStatuUpdateDropdown from 'helpers/widgets/Order/Dtf/DtfStatuUpdateDropdown/DtfStatuUpdateDropdown';
import {DownloadDtfImageRequest} from 'features/dtfs/_common/download-dtf-image/download-dtf-image.request';
import {
    GenerateOrderReceiptDtfRequest
} from 'features/dtfs/_common/generate-order-receipt-dtf/generate-order-receipt-dtf.request';
import PrintPreviewModal, {DialogTypeString} from 'helpers/widgets/Order/PrintPreview/PrintPreviewModal';
import {SendDtfListToApiRequest} from 'features/dtfs/_common/send-dtf-list-to-api/send-dtf-list-to-api.request';
import {ApproveDtfListRequest} from 'features/dtfs/_common/approve-dtf-list/approve-dtf-list.request';
import {CancelDtfListRequest} from 'features/dtfs/_common/cancel-dtf-list/cancel-dtf-list.request';
import {
    ReadyToPrintDtfListRequest
} from 'features/dtfs/_common/ready-to-print-dtf-list/ready-to-print-dtf-list.request';
import {Inplace, InplaceContent, InplaceDisplay} from "primereact/inplace";

type DtfDesignsDto = {
    orderId: string;
    orderCode: string;
    orderDesigns: DtfOrderSide[];
};

const DtfDetail = () => {
    const {dtfId} = useParams();
    const navigate = useNavigate();
    const statusContainerRef = useRef<HTMLDivElement>(null);

    const [dtf, setDtf] = useState<DtfListDto>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(0);
    const [scrollWidth, setScrollWidth] = useState<number>(0);
    const [dtfDesigns, setDtfDesigns] = useState<DtfDesignsDto[]>([]);
    const [uploadVisible, setUploadVisible] = useState<boolean>(false);
    const [pdfVisible, setPdfVisible] = useState<boolean>(false)
    const [pdfUrl, setPdfUrl] = useState<string>('');

    const downloadOptions: PrDropdownType[] = [
        {label: 'DTF File', value: 0},
        {label: 'Order Receipt PDF', value: 1}
    ];

    const getDtfDetail = async () => {
        if (!dtfId) return;

        try {
            setLoading(true)

            const request = new GetDtfListRequest(dtfId)

            const response = await dtfService.getDtfDetail(request)

            if (!response.isSuccess || !response.data) throw ''

            setDtf(response.data)

            if (!response.data.orderDetails) return;

            const orderDesigns: DtfDesignsDto[] = response.data.orderDetails.flatMap((_orderDetail) => ({
                orderId: _orderDetail.orderId,
                orderCode: _orderDetail.code,
                orderDesigns: _orderDetail.items.flatMap((_item) => _item.sides)
            }))
            setDtfDesigns(orderDesigns);

        } finally {
            setLoading(false)
        }
    }

    const showCancelDtf = () => {
        confirmDialog({
            header: 'Cancel DTF List',
            icon: 'pi pi-info-circle',
            message: 'Are you sure you want to cancel the dtf list?',
            acceptLabel: 'Confirm',
            rejectLabel: 'No',
            acceptClassName: 'px-4',
            rejectClassName: 'p-button-text px-4',
            accept: () => cancelDtf()
        });
    };

    const buttonsHandlerWithStatus = () => {
        if (dtf?.dtfList.dtfListStatusType === 2) {
            //Preparing
            return ["send"]
        } else if (dtf?.dtfList.dtfListStatusType === 3) {
            //Processing
            return []
        } else if (dtf?.dtfList.dtfListStatusType === 4) {
            //Testing
            return ["download", "upload"]
        } else if (dtf?.dtfList.dtfListStatusType === 5 || dtf?.dtfList.dtfListStatusType === 6) {
            //Completed
            return ['download'];
        } else return [];
    }

    const cancelDtf = async () => {
        if (!dtfId) return;

        try {
            const request = new CancelDtfListRequest([dtfId]);

            const response = await dtfService.cancelDtfList(request)

            if (!response.isSuccess) throw ''

            getDtfDetail()
        } catch (error) {
            setLoading(false);
        } finally {
        }
    }

    const removeOrderFromDtf = async (orderId: string) => {
        if (!dtfId || !orderId) return;
        try {
            const request = new RemoveOrderFromDtfListRequest(dtfId, orderId)

            const response = await dtfService.removeOrderFromDtfList(request);

            if (!response) throw '';

            getDtfDetail();
        } catch (error) {
            setLoading(false);
        } finally {

        }
    }

    const downloadDTF = async () => {
        if (!dtfId) return;

        try {
            setLoading(true)

            const request = new DownloadDtfImageRequest(dtfId)
            const response = await dtfService.downloadDtfImage(request)

            if (!response.isSuccess || !response.data) throw ''

            window.open(response.data, '_blank');
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const dtfReady = async () => {
        if (!dtfId) return;

        try {
            const request = new ReadyToPrintDtfListRequest([dtfId])

            const response = await dtfService.readyToPrintDtfList(request)

            if (!response.isSuccess) throw ''

            getDtfDetail();
        } catch (error) {
            setLoading(false);
        } finally {
        }
    }

    const downloadOrderReceipt = async () => {
        if (!dtfId) return;

        try {
            setLoading(true)

            const request = new GenerateOrderReceiptDtfRequest(dtfId, true)

            const response = await dtfService.generateOrderReceiptDtf(request)

            if (!response.isSuccess || !response.data) throw '';

            setPdfVisible(true);
            setPdfUrl(response?.data?.cdnUrl);
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const completeDTF = async () => {
        if (!dtfId) return;

        try {
            setLoading(true)

            const request = new ApproveDtfListRequest([dtfId])

            const response = await dtfService.approveDtfList(request)

            if (!response.isSuccess) throw ''

            getDtfDetail()
        } catch (error) {
            setLoading(false);
        } finally {

        }
    }

    const sendtoDTF = async () => {
        if (!dtfId) return;

        try {
            setLoading(true)

            const request = new SendDtfListToApiRequest([dtfId])

            const response = await dtfService.sendDtfListToApi(request)

            if (!response.isSuccess) throw ''

            getDtfDetail();
        } catch (error) {
            setLoading(false)
        } finally {

        }
    }

    useEffect(() => {
        if (!dtfId) return;

        getDtfDetail();
    }, [dtfId])

    return (
        <main className="container relative">
            <section className="container-header">
                <div className={styles.headerTitles}>
                    <h1>Dtf Detail</h1>
                </div>

                <div className="container-header-tools">
                    {buttonsHandlerWithStatus().includes('send') ?
                        <PrButton text="Send to DTF" onClick={() => sendtoDTF()} type="secondary"
                                  icon={<span className="pi pi-fw pi-send"/>}/> : null}

                    {buttonsHandlerWithStatus().includes('download') ?
                        <DtfDownloadDropdown dtfId={dtfId || ''} downloadDTF={downloadDTF}
                                             downloadPDF={downloadOrderReceipt}/> : null}
                    {buttonsHandlerWithStatus().includes('upload') ?
                        <PrButton text="Update Dtf" onClick={() => setUploadVisible(true)} type="secondary"
                                  icon={<span className="pi pi-fw pi-upload"/>}/> : null}
                    {dtf?.dtfList.dtfListStatusType !== 7 &&
                        <DtfStatuUpdateDropdown disabled={loading} dtfId={dtfId || ''}
                                                showCancel={() => showCancelDtf()}
                                                completeDTF={() => completeDTF()} dtfReady={() => dtfReady()}
                                                status={dtf?.dtfList.dtfListStatusType || 0}/>}
                </div>
            </section>

            {dtf ? (
                <section className="container-body p-3 z-1">
                    <div className={styles.dtfHeader}>
                        <div>
                            <h4 className="m-0">Dtf List Information</h4>
                            <p className="text-sm text-600">The dtf list information details are provided below.</p>
                        </div>
                    </div>
                    <div className={styles.dtf}>
                        <div className={styles.dtfCard}>
                            <h6>Dtf List Name</h6>
                            <div>{dtf?.dtfList.name}</div>
                        </div>

                        <div className={styles.dtfCard}>
                            <h6>Dtf Size</h6>
                            <div>
                                {dtf?.dtfList.height} x {dtf?.dtfList.width}
                            </div>
                        </div>

                        <div className={styles.dtfCard}>
                            <h6>Created Date</h6>
                            <div>{dateHelper.formatDate(dtf.dtfList.createdDate)}</div>
                        </div>

                        <div className={styles.dtfCard}>
                            <h6>Status</h6>
                            <div className={styles.dtfsStatus}>
                                <span>{dtf.dtfList.dtfListStatusName}</span>
                            </div>
                        </div>
                    </div>
                </section>
            ) : null}

            <section className="container-body p-3 z-3">
                <h4 className="m-0">Status History</h4>
                <p className="text-sm text-600">The dtf list status history details are provided below.</p>

                {!!dtf ? (
                    <div
                        ref={(_ref) => {
                            if (!_ref) return;

                            setScrollWidth(_ref.scrollWidth);

                            return ((statusContainerRef.current as any) = _ref);
                        }}
                        className={`${styles.detailWrapper} ${styles.scrollable}`}
                        onMouseUp={() => setIsDragging(false)}
                        onMouseDown={(event) => {
                            if (!statusContainerRef.current) return;

                            setIsDragging(true);

                            setStartX(event.pageX - statusContainerRef.current.offsetLeft);
                            setScrollLeft(statusContainerRef.current.scrollLeft);
                        }}
                        onMouseLeave={() => setIsDragging(false)}
                        onMouseMove={(event) => {
                            if (!isDragging || !statusContainerRef.current) return;

                            const x = event.pageX - statusContainerRef.current.offsetLeft;
                            const walk = x - startX;
                            statusContainerRef.current.scrollLeft = scrollLeft - walk;
                        }}>
                        {dtf?.dtfList.dtfListStatusHistories.map((_history, _index) => (
                            <div key={_index} className="flex align-items-center justify-content-center gap-1 w-full">
                                <div className={`${styles.detailItem} w-full h-full`}>
                                    <div className={styles.detailItemHeader}>
                                        <h6>{stringHelper.parseAtUpperCaseAndJoin(_history.name)}</h6>
                                    </div>

                                    <p className="flex flex-column">
                                        <span className="mt-auto">{dateHelper.formatDate(_history.createdDate)}</span>
                                    </p>
                                </div>

                                {dtf.dtfList.dtfListStatusHistories.length - 1 !== _index ? <BsArrowRight/> : null}
                            </div>
                        ))}
                    </div>
                ) : null}
            </section>

            {!!dtfDesigns && dtfDesigns.length > 0 ? (
                <section className="container-body p-3 z-3">
                    <h4 className="m-0">Order Designs</h4>
                    <p className="text-sm text-600">The desings to send dtf list details are provided below.</p>
                    {dtf?.dtfList.dtfListStatusType === 2 ? (
                        <div className={styles.warn}>
                            <CiWarning size={20}/>
                            If you remove a design from the dtf list all designs in that order will be removed.
                        </div>
                    ) : null}
                    <div className={styles.orderDtfDesigns}>
                        {dtfDesigns.map((_order) => (
                            <Inplace>
                                <InplaceDisplay>
                                    <div className={`${styles.orderDtfDesignsCardTitle} hover:bg-gray-200 transition-ease-in transition-duration-200`}>
                                        <span>{_order.orderCode}</span>
                                        {dtf?.dtfList.dtfListStatusType === 2 ?
                                            <CiCircleRemove onClick={() => removeOrderFromDtf(_order.orderId)}
                                                            color="red"
                                                            className="ml-2 cursor-pointer"/> : null}
                                    </div>
                                </InplaceDisplay>
                                <InplaceContent>
                                    <div key={_order.orderId} className={styles.orderDtfDesignsCard}>
                                        <div className={styles.orderDtfDesignsCardTitle}>
                                            <span>{_order.orderCode}</span>
                                            {dtf?.dtfList.dtfListStatusType === 2 ?
                                                <CiCircleRemove onClick={() => removeOrderFromDtf(_order.orderId)}
                                                                color="red"
                                                                className="ml-2 cursor-pointer"/> : null}
                                        </div>
                                        <div className={styles.orderDtfDesignsCardDesigns}>
                                            {_order.orderDesigns.map((_design) => (
                                                <img src={_design.design.imageWithStorage.url} alt="" height={180} loading="lazy"/>
                                            ))}
                                        </div>
                                    </div>
                                </InplaceContent>
                            </Inplace>
                        ))}
                    </div>
                </section>
            ) : null}

            <UploadDtf visible={uploadVisible} setVisible={setUploadVisible} dtfId={dtfId || ''}
                       getDtfDetail={getDtfDetail}/>
            <PrintPreviewModal dialogType={'Receipts'} pdfUrl={pdfUrl} visible={pdfVisible} setVisible={setPdfVisible}/>

            {loading ? (
                <div className="pr-loading-sticky">
                    <div className="pr-spinner-wrapper">
                        <ProgressSpinner className="p-progress-color" strokeWidth="4"/>
                    </div>
                </div>
            ) : null}
        </main>
    );
}

export default DtfDetail