import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { CreateMerchantRequest } from 'features/merchants/_common/create-merchant/create-merchant.request';
import { MerchantForListDto } from 'features/merchants/_common/dtos/merchant-for-list.dto';
import { GetMerchantListRequest } from 'features/merchants/_common/get-merchant-list/get-merchant-list.request';
import { merchantService } from 'features/merchants/_common/merchant.service';
import { UpdateMerchantWithUserRequest } from 'features/merchants/_common/update-merchant-with-user/update-merchant-with-user.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { setForAdminMerchantListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import MerchantCreate from './components/MerchantCreate';
import MerchantUpdate from './components/MerchantUpdate';

const Merchants = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminMerchantListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [createVisible, setCreateVisible] = useState<boolean>(false);
	const [updateVisible, setUpdateVisible] = useState<boolean>(false);
	const [editedMerchantId, setEditedMerchantId] = useState<string | undefined>(undefined);
	const [secondLoading, setSecondLoading] = useState<boolean>(false);
	const [merchants, setMerchants] = useState<MerchantForListDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getMerchants = async (pRequest: Partial<GetMerchantListRequest>) => {
		try {
			setLoading(true);

			const request = new GetMerchantListRequest(pRequest);

			const response = await merchantService.getMerchantList(request);
			if (!response.isSuccess || !response.data) throw '';

			setMerchants(response.data);
			setPaginationResponse(response.pagination);

			const newPagination = JSON.parse(JSON.stringify(request.pagination));

			dispatch(setForAdminMerchantListPaginationInfo(newPagination));
		} catch (error) {
			setMerchants([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getMerchants({});
	}, []);

	return (
		<main className="container">
			<section className="container-header">
				<h1 className="container-header-title">Merchants</h1>

				<div className="container-header-tools">
					<PrButton
						type="secondary"
						text="Refresh Merchants"
						icon={<span className="pi pi-fw pi-refresh" />}
						loading={loading || secondLoading}
						onClick={async () => {
							await getMerchants({});
						}}
					/>

					<PrButton type="secondary" text="Create Merchants" icon={<span className="pi pi-fw pi-plus-circle" />} loading={loading || secondLoading} onClick={() => setCreateVisible(true)} />
				</div>
			</section>

			<section className="container-body relative p-0">
				<DataTable
					dataKey="id"
					value={merchants}
					currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					totalRecords={paginationResponse?.totalItemCount}
					showGridlines
					className="pr-table"
					lazy
					paginator
					rowsPerPageOptions={[5, 10, 20, 30]}
					rows={fromStatePagination.itemCount || 20}
					first={fromStatePagination.first}
					onPage={async (event) => {
						const pageNumber = !!event.page ? event.page + 1 : 1;

						await getMerchants({ pagination: new PaginationDto(pageNumber, event.rows, undefined, event.first) });
					}}>
					<Column header="Full Name" field="fullName" />
					<Column header="Email" field="email" />
					<Column header="Corporate Name" field="corporateName" />
					<Column
						header="Actions"
						style={{ width: '1rem' }}
						align="center"
						body={(row: MerchantForListDto) => (
							<PrButton
								size="small"
								loading={secondLoading}
								text="Edit"
								icon={<FaEdit />}
								onClick={() => {
									setUpdateVisible(true);
									setEditedMerchantId(row.id);
								}}
							/>
						)}
					/>
				</DataTable>

				{loading || secondLoading ? (
					<div className="pr-loading-sticky">
						<div className="pr-spinner-wrapper">
							<ProgressSpinner className="p-progress-color" strokeWidth="4" />
						</div>
					</div>
				) : null}
			</section>

			<MerchantCreate
				visible={createVisible}
				setVisible={setCreateVisible}
				onSubmit={async (values, formikHelpers) => {
					try {
						setSecondLoading(true);

						const request = new CreateMerchantRequest(structuredClone(values));
						request.passwordConfirm = request.password;

						const response = await merchantService.create(request);
						if (!response.isSuccess) throw '';

						formikHelpers.resetForm();

						await getMerchants({});
					} catch (error) {
					} finally {
						setSecondLoading(false);
					}
				}}
			/>

			<MerchantUpdate
				merchantId={editedMerchantId}
				visible={updateVisible}
				setVisible={setUpdateVisible}
				onSubmit={async (values, formikHelpers) => {
					try {
						setSecondLoading(true);

						const request = new UpdateMerchantWithUserRequest(structuredClone(values));

						request.userUpdateModel.phoneNumbers = request.userUpdateModel.phoneNumbers.map((_phone) => ({ ..._phone, isDefault: true }));

						if (request.createOrUpdateCorporateModel?.feinNumber === '') request.createOrUpdateCorporateModel.feinNumber = null;
						if (request.userUpdateModel?.ssnNumber === '') request.userUpdateModel.ssnNumber = null;

						const response = await merchantService.update(request);
						if (!response.isSuccess) throw '';

						setEditedMerchantId(undefined);

						formikHelpers.resetForm();

						await getMerchants({});
					} catch (error) {
						setUpdateVisible(true);
					} finally {
						setSecondLoading(false);
					}
				}}
			/>
		</main>
	);
};

export default Merchants;
