import assignedOrdersToPrintCenterService from 'features/printCenters/derived-features/assigned-orders-to-print-centers/assigned-orders-to-print-center.service';
import { DeleteAssignRequest } from 'features/printCenters/derived-features/assigned-orders-to-print-centers/delete-assign/delete-assign.request';
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'

type props = {
	assignVisible: boolean;
	setAssignVisible: Function;
	cancelOrderId: string;
	setCancelOrderId: Function;
	getOrders: Function;
}
const CancelAssign = ({ assignVisible, setAssignVisible, cancelOrderId, setCancelOrderId, getOrders }: props) => {
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)

	const onCancel = async() => {
		try {
			setSubmitLoading(true);

			const request = new DeleteAssignRequest(cancelOrderId, null);

			const response = await assignedOrdersToPrintCenterService.deleteAssign(request);

			if (!response.isSuccess) throw '';

			getOrders();
			onHide();
		}finally { setSubmitLoading(false) }
	}

	const onHide = () => {
		setCancelOrderId(undefined)
		setAssignVisible(false)
	}

  return (
		<Dialog header="Cancel Assign" visible={assignVisible} onHide={() => onHide()} className="max-w-max">
			<span>Are you sure to cancel assign?</span>
			<div className="flex justify-content-between gap-6 mt-5">
				<Button label="Go Back" icon="pi pi-arrow-left" loading={submitLoading} onClick={() => onHide()} className="p-button-outlined" />
				<Button label="Cancel Assign" icon="pi pi-trash" loading={submitLoading} onClick={() => onCancel()} className="p-button-outlined p-button-danger" />
			</div>
		</Dialog>
  );
}

export default CancelAssign