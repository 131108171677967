import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { GetPrintCenterListForAdminRequest } from 'features/printCenters/_common/get-print-center-list-for-admin/get-print-center-list-for-admin.request';
import printCenterService from 'features/printCenters/_common/print-center.service';
import { SelectItem } from 'primereact/selectitem';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { GetPrintCenterStockDto } from 'features/stocks/_common/get-print-center-stocks/dtos/get-print-center-stocks.dto';
import { Pagination } from 'models/_commons/responses/pagination';
import { setForPrintCenterStockListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { GetPrintCenterStockRequest } from 'features/stocks/_common/get-print-center-stocks/get-print-center-stocks.request';
import stockService from 'features/stocks/_common/stock.service';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import currencyHelper from 'helpers/curreny.helper';

const PrintCenterStockForAdmin = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forPrintCenterStockListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [printCenterOptions, setPrintCenterOptions] = useState<SelectItem[]>([]);
	const [printCenterId, setPrintCenterId] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [stocks, setStocks] = useState<GetPrintCenterStockDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getPrintCenters = async () => {
		try {
			const request = new GetPrintCenterListForAdminRequest();
			const response = await printCenterService.getPrintCenterListForAdmin(request);
			if (!response.isSuccess || !response.data) throw '';

			const options = response.data.map((_printCenter) => ({ label: _printCenter.fullName, value: _printCenter.id } as SelectItem));
			setPrintCenterOptions(options);
		} catch (error) {
			setPrintCenterOptions([]);
		}
	};

	const getStocks = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			if (!printCenterId) throw '';

			if (!!pagination) dispatch(setForPrintCenterStockListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetPrintCenterStockRequest(printCenterId, pagination || fromStatePagination);
			const response = await stockService.getPrintCenterStocks(request);
			if (!response.isSuccess || !response.data) throw '';

			setStocks(response.data);
			setPaginationResponse(response.pagination);
		} catch (error) {
			setStocks([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
		}
	};

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.orderBy = 2;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		await getStocks(newPagination);
	};

	const priceBodyTemplate = (rowData: GetPrintCenterStockDto) => <span>{currencyHelper.formatPrice(rowData.price.formattedPricePerUnit)}</span>

	useEffect(() => {
		getPrintCenters();
	}, []);

	useEffect(() => {
		if (!printCenterId) return;

		let newPagination = new PaginationDto();

		newPagination.itemCount = 10;

		getStocks(newPagination);
	}, [printCenterId]);

	return (
		<div className="card">
			<div className="flex align-items-center justify-content-between mb-3">
				<h5 className="m-0">Print Center Stocks</h5>

				<Dropdown options={printCenterOptions} value={printCenterId} filter filterBy="label" onChange={(e) => setPrintCenterId(e.value)} placeholder="Select a Print Center" style={{ width: '250px' }} />
			</div>

			<DataTable value={stocks} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} paginator lazy rowsPerPageOptions={[5, 10, 15, 20]} rows={fromStatePagination.itemCount || 5} dataKey="productVariantId" first={fromStatePagination.first} onPage={onPageChangeEvent} loading={loading} className="p-datatable-gridlines">
				<Column field="productName" header="Product" />
				<Column field="brandName" header="Brand" />
				<Column field="modelName" header="Model" />
				<Column field="colorName" header="Color" />
				<Column field="sizeName" header="Size" />
				<Column field="fullSku" header="SKU" />
				<Column body={priceBodyTemplate} header="Price" />
				<Column field="quantity" header="Quantity" />
			</DataTable>
		</div>
	);
};

export default PrintCenterStockForAdmin;
