import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetMyProductsRequest {
	sellerId: string;
	pagination: PaginationDto;

	constructor(props: Partial<GetMyProductsRequest>) {
		this.sellerId = props.sellerId || '';
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-my-products`;
	}
}
