import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { SurveyListDto } from 'features/surveys/_common/survey-get-list/survey-get-list.response';
import { Column } from 'primereact/column';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SurveyGetListRequest } from 'features/surveys/_common/survey-get-list/survey-get-list.request';
import surveyService from 'features/surveys/_common/survey.service';
import { Pagination } from 'models/_commons/responses/pagination';
import { setForSurveysPaginationInfo } from 'redux/features/pagination/paginationSlice';
import dateHelper from 'helpers/dateHelper';
import { Message } from 'primereact/message';
import DropdownButton from 'helpers/widgets/DropdownButton';
import { SurveySoftDeleteRequest } from 'features/surveys/_common/survey-soft-delete/survey-soft-delete.request';

const SurveyList = () => {
	const navigate = useNavigate();
	const fromStatePagination = useAppSelector((state) => state.pagination.forSurveysPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [surveys, setSurveys] = useState<SurveyListDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			const response = await surveyService.getList(new SurveyGetListRequest({ pagination: pagination }));
			if (!response.isSuccess || !response.data) throw '';

			setSurveys(response.data);
			setPaginationResponse(response.pagination);
		} catch (error) {
			setSurveys([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
		}
	};

	const softDeleteSurvey = async (surveyId: string) => {
		try {
			setDeleteLoading(true);
			const response = await surveyService.softDelete(new SurveySoftDeleteRequest(surveyId));
			if (!response) throw '';

			await getAll(fromStatePagination);
		} catch (error) {
		} finally {
			setDeleteLoading(false);
		}
	};

	const onPageChangeEvent = (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;

			const serializeData = JSON.stringify(newPagination);
			dispatch(setForSurveysPaginationInfo(JSON.parse(serializeData)));
		}

		getAll(newPagination);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		const serializeData = JSON.stringify(newPagination);
		dispatch(setForSurveysPaginationInfo(JSON.parse(serializeData)));
		getAll(newPagination);
	}, []);

	const statusBodyTemplate = (survey: SurveyListDto) => <Message severity={`${survey.isActive ? 'success' : 'warn'}`} text={`${survey.isActive ? 'Active' : 'Deactive'}`} className="py-2" />;
	const createdDateBodyTemplate = (survey: SurveyListDto) => dateHelper.formatDate(survey.createdDate);
	const actionsBodyTemplate = (survey: SurveyListDto) => {
		const menuItems = [
			<button onClick={() => navigate(`sellers-answers/${survey.name}/${survey.id}`)}>Users' Answers</button>,
			<button onClick={() => navigate(`update/${survey.id}`)}>Update</button>,
			<button onClick={() => softDeleteSurvey(survey.id)} className="text-pink-500 font-bold">
				Delete
			</button>
		];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	return (
		<div className="card">
			<div className="flex align-items-center justify-content-between mb-3">
				<h5 className="m-0">Surveys</h5>

				<div className="flex gap-3">
					<Link to="create">
						<Button icon="pi pi-plus-circle" label="Create New" className="px-4" />
					</Link>
				</div>
			</div>

			<DataTable value={surveys} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 10} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading || deleteLoading}>
				<Column header="Name" field="name" />
				<Column header="Question Quantity" field="questionQuantity" />
				<Column header="Total Answer Quantity" field="totalAnswerQuantity" />
				<Column header="Status" body={statusBodyTemplate} />
				<Column header="Created Date" body={createdDateBodyTemplate} />
				<Column header="Actions" body={actionsBodyTemplate} />
			</DataTable>
		</div>
	);
};

export default SurveyList;
