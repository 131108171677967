import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import editorService from 'features/editors/_common/editor.service';
import { CreateImageRequest } from 'features/editors/_common/create-image/create-image.request';

type Props = {
	comment: string;
	onChange: Function;
	setLoading: Function;
};

const ClassicEditorWithImages = ({ comment, onChange, setLoading }: Props) => {
	const uploadAdapter = (loader: any) => ({
		upload: () =>
			new Promise((resolve, reject) => {
				loader.file.then(async (file: File) => {
					try {
						setLoading(true);

						const request = new CreateImageRequest(file);

						const response = await editorService.createImage(request);

						if (!response.isSuccess) throw '';

						resolve({ default: response.data?.[0] });
					} catch(responseError: any) {
						reject(responseError);
					} finally {
						setLoading(false);
					}
				});
			})
	});

	function uploadPlugin(editor: any) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => uploadAdapter(loader);
	}

	return <CKEditor data={comment} config={{ extraPlugins: [uploadPlugin] }} editor={ClassicEditor} onChange={(_, editor) => onChange(editor.getData() as string)} />;
};

export default ClassicEditorWithImages;
