export class CreateDtfListWithOrdersRequest {
	name: string;
	orderIds: Array<string>;

	get getConvertUriExtension() {
		return 'create-dtf-list-with-orders';
	}

	constructor(props: Partial<CreateDtfListWithOrdersRequest>) {
		this.name = props.name || '';
		this.orderIds = props.orderIds || [];
	}
}