import { ProductVariantAndPrice } from "../create-or-update-product-variant-prices/create-or-update-product-variant-prices.request";

export class CreateOrUpdateProductVariantPricesForGroupRequest {
	groupId: string = '';
	productVariantAndPrices: ProductVariantAndPrice[] = [];

	constructor(groupId: string, productVariantAndPrices: ProductVariantAndPrice[]) {
		this.groupId = groupId;
		this.productVariantAndPrices = productVariantAndPrices;
	}

	get getConvertUriExtension() {
		return `group/create-or-update`;
	}
}
