import HttpBaseService from "services/_common/http-base-service";
import { GetInTouchFormsRequest } from "./_common/get-in-touch-forms/get-in-touch-forms.request";
import { GetInTouchFormsResponse } from "./_common/get-in-touch-forms/get-in-touch-forms.response";
import { GetBoostYourPotentialFormRequests } from "./_common/get-boost-your-potential-forms/get-boost-your-potential-forms.request";
import { GetBoostYourPotentialFormResponse } from "./_common/get-boost-your-potential-forms/get-boost-your-potential-forms.response";

class FormsService extends HttpBaseService {
	constructor() {
		super('forms')
	}

	async getGetInTouchFormRequests(request: GetInTouchFormsRequest): Promise<GetInTouchFormsResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async getBoostYourPotentialFormRequests(request: GetBoostYourPotentialFormRequests): Promise<GetBoostYourPotentialFormResponse> {
		return await this.getData(request.getConvertUriExtension)
	}
}

export default new FormsService();