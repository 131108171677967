import { Formik, Form, FormikHelpers } from 'formik';
import { useAppSelector } from '../../../../../redux/hooks';
import * as Yup from 'yup';
import { User } from '../../../../../models/dtos/auth/users/user';
import DeleteUser from './components/DeleteUser';
import PasswordInput from '../../../../../helpers/widgets/Forms/PasswordInput';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Security.module.scss';
import { ChangePasswordRequest } from 'features/users/_common/change-password/change-password.request';
import { useState } from 'react';
import userService from 'features/users/_common/user.service';
import { ProgressSpinner } from 'primereact/progressspinner';

const Security = () => {
	const user = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false)

    const initialValues = new ChangePasswordRequest( user.userId, '', '', '');

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Password is required.').min(5,"Must be at least 5 characters."),
        password: Yup.string().required('New password is required.').min(5,"Must be at least 5 characters."),
        passwordConfirm: Yup.string()
            .required('Password Confirm is Required')
            .oneOf([Yup.ref('password'), null], 'Password must be match')
    });

	const submitForm = async (values: ChangePasswordRequest, formikHelpers: FormikHelpers<ChangePasswordRequest>) => {
		try {
			setLoading(true);

			const request = new ChangePasswordRequest(user.userId, values.currentPassword, values.password, values.passwordConfirm);

			const response = await userService.changePassword(request);

			if (!response.isSuccess) throw '';

			formikHelpers.resetForm();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const inputStyle = { backgroundColor: 'white', borderWidth: 1, borderColor: '#bdbdbd', borderRadius: '0.6rem', background: 'none', height: 40, alignItems: 'center' };

    return (
		<div className="relative">
			<div className="container-header">
				<h1 className="container-header-title">Security</h1>

				<div className={`container-header-tools ${styles.deviceControl}`}>
					<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />

					<Link to="/account/settings">
						<PrButton icon={<span className="pi pi-arrow-right" />} text="Go Settings" />
					</Link>
				</div>
			</div>

			<div className="container-body p-3">
				<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationSchema}>
					{({ handleChange, values }) => (
						<Form>
							{!user.isSocialAccount && (
								<>
									<h5>Login Details</h5>

									<div className="mb-4">
										<PasswordInput type="password" label="Current Password" name="currentPassword" id="password" value={values.currentPassword} onChange={handleChange} toggleMask feedback={false} placeholder="Enter current password" inputStyle={inputStyle} />
									</div>

									<div className="mb-4">
										<PasswordInput type="password" label="New Password" name="password" id="password" value={values.password} onChange={handleChange} toggleMask feedback={false} placeholder="Enter new password" inputStyle={inputStyle} />
									</div>

									<div className="mb-4">
										<PasswordInput type="password" label="Confirm New Password" name="passwordConfirm" id="password" value={values.passwordConfirm} onChange={handleChange} toggleMask feedback={false} placeholder="Confirm new password" inputStyle={inputStyle} />
									</div>

									<div className="mt-6">
										<div className="flex justify-content-end">
											<PrButton btnType="submit" loading={loading} text="Update" className="px-6" />
										</div>
									</div>
								</>
							)}
						</Form>
					)}
				</Formik>
			</div>

			<div className="container-body p-3">
				<DeleteUser />
			</div>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Security;
