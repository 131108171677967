import React, { useEffect, useState } from 'react';
import { GetPrintramStockDto } from 'features/stocks/_common/get-printram-stocks/dtos/get-printram-stock.dto';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setForPrintramStockListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { GetPrintramStockRequest } from 'features/stocks/_common/get-printram-stocks/get-printram-stock.request';
import stockService from 'features/stocks/_common/stock.service';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import currencyHelper from 'helpers/curreny.helper';

const PrintramStock = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forPrintramStockListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [stocks, setStocks] = useState<GetPrintramStockDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			if (!!pagination) dispatch(setForPrintramStockListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetPrintramStockRequest(pagination || fromStatePagination);
			const response = await stockService.getPrintramStocks(request);
			if (!response.isSuccess || !response.data) throw '';

			setStocks(response.data);
			setPaginationResponse(response.pagination);
		} catch (error) {
			setStocks([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
		}
	};

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.orderBy = 2;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		await getAll(newPagination);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		getAll(newPagination);
	}, []);

	const priceBody = (stock: GetPrintramStockDto) => <span>{currencyHelper.formatPrice(stock.price.formattedPricePerUnit)}</span>;

	return (
		<div className="card">
			<h5>Printram Stock</h5>

			<DataTable value={stocks} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} paginator lazy rowsPerPageOptions={[5, 10, 15, 20]} rows={fromStatePagination.itemCount || 5} dataKey="productVariantId" first={fromStatePagination.first} onPage={onPageChangeEvent} loading={loading} className="p-datatable-gridlines">
				<Column field="productName" header="Product" />
				<Column field="brandName" header="Brand" />
				<Column field="modelName" header="Model" />
				<Column field="colorName" header="Color" />
				<Column field="sizeName" header="Size" />
				<Column field="fullSku" header="SKU" />
				<Column header="Price" body={priceBody} />
				<Column field="quantity" header="Quantity" />
			</DataTable>
		</div>
	);
};

export default PrintramStock;
