export class SendDtfListToApiRequest {
	dtfListIds: string[];

	constructor(dtfListIds: string[]) {
		this.dtfListIds = dtfListIds;
	}

	get getConvertUriExtension() {
		return `send-dtf-list-to-api`;
	}
}