import { Switch } from '@headlessui/react';
import React, { useState } from 'react';
import styles from './PrSwitchToggle.module.scss';

type Props = {
	checked: boolean;
	onChange: (state: boolean) => void;
};

export const PrSwitchToggle = (props: Props) => {
	return (
		<Switch checked={props.checked} onChange={props.onChange} as="button" type="button" className={`${styles.switch}${props.checked ? ` ${styles.active}` : ''}`}>
			<span />
		</Switch>
	);
};
