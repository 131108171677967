import { PdfRequestBase } from '../_bases/request.base';

export class GenerateOrderItemBarcodePdfsRequest implements PdfRequestBase {
	orderIdentities: string[] = [];
	isSaveToCdn: boolean = true;

	constructor(orderIdentities?: string[]) {
		if (!!orderIdentities) this.orderIdentities = orderIdentities;
	}

	get getConvertUriExtension() {
		return `generate-order-item-barcode-pdfs`;
	}
}
