import { ConfirmSideWithDesign } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { CreateEtsySynchronizeProductsWithSidesRequest } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/create-etsy-synchronize-products-with-sides.request';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EtsyProductForSync, StepProducts } from '../steps/products/StepProducts';
import { StepReviewOrder } from '../steps/review-order/StepReviewOrder';
import { StepShipping } from '../steps/shipping/StepShipping';
import { useAppSelector } from 'redux/hooks';
import { ShippoShipmentDto, ShippoShipmentRateDto } from 'features/orders/derived-features/order-transports/get-shippo-shipments/get-shippo-shipments.response';
import { CalculatedDto } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.response';
import { SyncOrder } from '../SyncOrder';
import { GetOrdersStoreDto } from 'features/orders/_common/get-orders-for-admin/get-orders-for-admin.response';
import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';

export interface StepWithComponent {
	label: string;
	value: number;
	component: JSX.Element;
	isCompleted: boolean;
	isCanNext: boolean;
}

export const orderSteps: StepWithComponent[] = [
	{ label: 'Products', value: 1, isCompleted: false, isCanNext: false, component: <StepProducts /> },
	{ label: 'Shipping', value: 2, isCompleted: false, isCanNext: false, component: <StepShipping /> },
	{ label: 'Review order', value: 3, isCompleted: false, isCanNext: false, component: <StepReviewOrder /> }
];

export type SyncOrderContextType = {
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	loadingText: string;
	setLoadingText: React.Dispatch<React.SetStateAction<string>>;
	request: CreateEtsySynchronizeProductsWithSidesRequest;
	setRequest: React.Dispatch<React.SetStateAction<CreateEtsySynchronizeProductsWithSidesRequest>>;
	orderStep: StepWithComponent;
	setOrderStep: React.Dispatch<React.SetStateAction<StepWithComponent>>;
	shippingRates: ShippingRateDto[] | [];
	setShippingRates: React.Dispatch<React.SetStateAction<ShippingRateDto[] | []>>;
	selectedPaymentCard: string | undefined;
	setSelectedPaymentCard: React.Dispatch<React.SetStateAction<string | undefined>>;
	selectedRate: ShippingRateDto | undefined;
	setSelectedRate: React.Dispatch<React.SetStateAction<ShippingRateDto | undefined>>;
	calculatedPrice: CalculatedDto | undefined;
	setCalculatedPrice: React.Dispatch<React.SetStateAction<CalculatedDto | undefined>>;
	isShowShippingForm: boolean;
	setIsShowShippingForm: React.Dispatch<React.SetStateAction<boolean>>;
	externalProducts: EtsyProductForSync[];
	setExternalProducts: React.Dispatch<React.SetStateAction<EtsyProductForSync[]>>;
	selectedStore: GetOrdersStoreDto | undefined;
	setSelectedStore: React.Dispatch<React.SetStateAction<GetOrdersStoreDto | undefined>>;
	isNewShippingRatesCalculateRequired: boolean;
	setIsNewShippingRatesCalculateRequired: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SyncOrderContext = React.createContext<SyncOrderContextType | null>(null);

export const SyncOrderContextProvider = ({ children }: React.PropsWithChildren) => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('');
	const [isShowShippingForm, setIsShowShippingForm] = useState<boolean>(true);
	const [request, setRequest] = useState<CreateEtsySynchronizeProductsWithSidesRequest>(new CreateEtsySynchronizeProductsWithSidesRequest({}));
	const [orderStep, setOrderStep] = useState<StepWithComponent>(orderSteps[0]);
	const [shippingRates, setShippingRates] = useState<ShippingRateDto[] | []>([]);
	const [selectedPaymentCard, setSelectedPaymentCard] = useState<string | undefined>(undefined);
	const [selectedRate, setSelectedRate] = useState<ShippingRateDto | undefined>(undefined);
	const [calculatedPrice, setCalculatedPrice] = useState<CalculatedDto | undefined>(undefined);
	const [externalProducts, setExternalProducts] = useState<EtsyProductForSync[]>([]);
	const [selectedStore, setSelectedStore] = useState<GetOrdersStoreDto | undefined>();
	const [isNewShippingRatesCalculateRequired, setIsNewShippingRatesCalculateRequired] = useState<boolean>(false);

	return (
		<SyncOrderContext.Provider
			value={{
				loading,
				setLoading,
				loadingText,
				setLoadingText,
				request,
				setRequest,
				orderStep,
				setOrderStep,
				shippingRates,
				setShippingRates,
				selectedPaymentCard,
				setSelectedPaymentCard,
				selectedRate,
				setSelectedRate,
				calculatedPrice,
				setCalculatedPrice,
				isShowShippingForm,
				setIsShowShippingForm,
				externalProducts,
				setExternalProducts,
				selectedStore,
				setSelectedStore,
				isNewShippingRatesCalculateRequired,
				setIsNewShippingRatesCalculateRequired
			}}>
			<SyncOrder />
		</SyncOrderContext.Provider>
	);
};
