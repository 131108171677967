import { Dialog } from 'primereact/dialog';

type Props = {
	visible: boolean;
	setVisible: Function;
};

const TermOfService = ({ visible, setVisible }: Props) => {
	return (
		<Dialog visible={visible} header="Term of Service" modal onHide={() => setVisible(false)}>
			<span className="block">Printram, Inc.</span>
			<span className="block">8922 359 FM</span>
			<p>Richmond, TX, USA</p>
			<p>Last Updated: Sep 27, 2023</p>
			<p>Thank you for choosing Printram (or formally known as Printram, Inc.). Prior to utilizing or entering our platform, please thoroughly review these Terms of Use (referred to as the "Terms"). This document constitutes a legally binding agreement between Printram and you, the User (as defined below), regarding the utilization of the applications, software, products, and services offered by Printram (collectively referred to as the "Service"). Consequently, we have endeavored to ensure its clarity. Alongside the comprehensive legal provisions, we have included brief, non-binding descriptions for each clause. These descriptions are not intended to supplant or distort the full text.</p>

			<h4>A. Definitions</h4>
			<p className="text-500 text-lg">Summary: These definitions are used throughout this Terms of Service, and it's crucial for you to comprehend their meaning when encountered in our terms.</p>
			<p>Any terms not explicitly defined in this section shall carry the definitions as provided elsewhere in these Terms of Service.</p>

			<ul>
				<li>"Agreement" collectively refers to all the terms, conditions, and notices contained in this document (referred to as the "Terms of Service") and all other operational guidelines, policies, including the Printram Privacy Policy and Printram Intellectual Property Policy, and procedures that we may periodically publish on our Website. This includes but is not limited to information on shipping, payments, and returns.</li>
				<li>"Content" pertains to the materials presented or displayed on the Website, encompassing text, data, articles, images, photographs, graphics, software, applications, designs, features, and other items available on the Website or accessible through the Service.</li>
				<li>"Customers" denotes individuals or entities who purchase Your Products through a Sales Channel.</li>
				<li>"Orders" signify Your Products once they have been acquired, either by you or your customers.</li>
				<li>"Produced Orders" describe Orders that have been processed, completed, and shipped.</li>
				<li>"Products" encompasses all the items offered by various Providers on our Website, including but not limited to clothing, accessories, home decor, kitchenware, footwear, and stationary.</li>
				<li>"Providers" signifies the organizations and companies with which Printram collaborates within the scope of its Service to make Products available to you.</li>
				<li>"Sales Channels" or "Stores" refer to other websites and platforms where you sell Your Products, such as Shopify, Woocommerce, Ebay, or Etsy.</li>
				<li>"Us," "We," and "Printram" refer to Printram, Inc., along with our affiliated legal entities, subsidiaries (including, but not limited to, Printram Development SIA), directors, contractors, licensors, officers, agents, and employees. Depending on various factors like the Print Provider's location, your location, and the transaction's region, Printram reserves the right to provide Services through different Printram legal entities.</li>
				<li>"User," "You," and "Your" pertain to the individual, company, or organization accessing or utilizing the Website or Service, or directing the use of the account in its capacity.</li>
				<li>"User Products" denote Products created by Users by incorporating Content through the Service. "Your Mockups" are visual representations of Users' Products generated using the Service.</li>
				<li>"User-Generated Content" encompasses Content, whether written or otherwise, that is generated or uploaded by our Users.</li>
				<li>"Website" refers to Printram's official website, located at Printram.com, and all the content, services, and products made available by Printram through the Website. This includes Printram-owned subdomains like app.Printram.com, Printramapp.com, and all subdomains of Printramapp.com, such as app.Printramapp.com. It also encompasses the use of current and future Printram plugins, such as those found in the Shopify App store, Woocommerce, Etsy, and more. Occasionally, Printram plugin pages and Printram-owned websites may present additional or varying terms of service. If such additional terms conflict with these Terms of Service, the terms provided in the additional terms concerning the conflicting matter shall take precedence</li>
				<li>"Your Content" pertains to Content that you generate or have ownership of.</li>
			</ul>

			<h4>B. General</h4>
			<ul>
				<p>1. When you make use of our Services and Content, you are implicitly accepting:</p>
				<ul>
					<li>Our Terms of Service</li>
					<li>Our Privacy Policy</li>
					<li>Our Intellectual Property Policy</li>
				</ul>
				<p className="mt-4">2. Unless supplemented by additional terms, conditions, policies, guidelines, or standards, the Agreement represents the complete understanding between Printram and you regarding the subject matter covered herein. It overrides any prior verbal or written agreements or understandings between Printram and you concerning the access to and use of the Service.</p>
				<p>3. If any portion of these Terms of Service is deemed invalid or unenforceable, that specific provision will be removed and will not affect the validity and enforceability of the remaining provisions.</p>
			</ul>
			<p>Have inquiries regarding the Terms of Service? Please reach out to us.</p>

			<h4>C. When Do These Terms Apply?</h4>
			<p className="text-500 text-lg">Summary: When you use Printify, you are automatically agreeing to all the terms outlined below.</p>
			<p>Using the Service implies that you recognize and consent to abide by these Terms of Service. If you do not consent to these Terms of Service, you should refrain from accessing the Service. If you are already registered, you have the option to deactivate your account</p>

			<h4>D. Can These Terms Be Modified?</h4>
			<p className="text-500 text-lg">Summary: We can make changes to these Terms of Service, and if they are significant, we will inform you.</p>
			<p>Printram retains the right to amend the Terms of Service periodically, and the latest version will always be accessible on our Website. If a revision, at our sole discretion, is considered substantial, we will notify you. The "Last Updated" date at the beginning of the Terms of Service indicates the date of the most recent alteration. Your continued use of the Service after the effective date of any modifications to the Terms of Service will indicate your acceptance of these changes. If you do not agree with the updated Terms of Service, you should discontinue using the Service and deactivate your account.</p>

			<h4>E. What Is the Scope of Printram’s Services?</h4>
			<ul>
				<p>1. Printram functions as an online platform that enables you to create and connect your store, allowing you to sell customized products through partnerships with various Print on Demand Providers worldwide. Our role is to handle the technical aspects while you focus on creating and selling.</p>
				<p>2. Printram serves as an online marketplace for print on demand dropshipping, collaborating with multiple Providers to offer customizable Products that you can sell to Customers. We also maintain integrations with various Sales Channels to streamline the publication of Your Products on your Stores. When Customers purchase Your Products through a Sales Channel, we coordinate with Providers for printing and delivery.</p>
				<p>3. Printram is solely a platform and does not engage in selling products or printing. We act as a facilitator for your business.</p>
				<p>4. When using our Service to sell Your Products, your relationship with Printram remains that of a consumer, not an employee, agent, joint venturer, or partner. You operate independently for your own benefit.</p>
				<p>5. Printram does not assume responsibility for your choice of Print Provider or the final product quality. Users are expected to research and select suitable Providers for their Products.</p>
				<p>6. We may provide links to external resources or third-party services, but we do not endorse or control these services. Users should exercise their own judgment and review the terms and policies of Third Party Sites.</p>
				<p>7. Printram cannot guarantee 100% uninterrupted access to our Service due to the nature of the Internet. We reserve the right to restrict access for maintenance and improvements to ensure the proper functioning of the Printram Service. Changes and enhancements may also be introduced over time.</p>
			</ul>

			<h4>F. Who Can Access Printram and Use the Service?</h4>
			<p className="text-500 text-lg">Summary: To use our service, you must be at least 18 years old, and if you sign up on behalf of someone else or an entity, you confirm that you have the legal authority to do so. Some features of our platform may have separate terms and conditions in addition to those outlined here.</p>
			<ul>
				<p>1. You need to be at least 18 years old and have the legal capacity to use the Service or create a Printram account. By using our Service, you affirm that you are 18 years of age or older and have the legal authority to enter into a contract.</p>
				<p>2. If you create an account for a company, organization, or another entity, "You" encompasses both you and that entity. You also confirm that you have the authority to grant permissions and licenses as described in these Terms of Service on behalf of the entity, and you agree to these Terms of Service on the entity's behalf.</p>
				<p>3. Some sections and features of the Service may have their own separate policies, standards, or guidelines. They may also require you to accept additional terms and conditions. If there is a conflict between these Terms of Service and specific terms and conditions related to a particular area or feature of Printram Service, the more specific terms and conditions will prevail, unless otherwise specified.</p>
				<p>4. To utilize our Service, you are required to register an account. Instructions on how to do this can be found in the following section.</p>
				<p>5. You acknowledge and accept that you are responsible for paying any applicable taxes resulting from your use of the Service.</p>
			</ul>

			<h4>G. What Are the Rules for Creating and Maintaining an Account?</h4>
			<p className="text-500 text-lg">Summary: We have specific requirements that you must adhere to when creating an account with us.</p>
			<ul>
				<p>1. To establish a Printram Account, you may need to provide certain personal details, such as your full legal name, current address, phone number, email address, and payment information. We will handle and utilize this information in accordance with Printram's Privacy Policy. You commit to supplying accurate and complete information to Printram and agree to promptly update it when changes occur. Additionally:</p>
				<ul>
					<li>You must not create an account or access the Service if you are under 18 years of age.</li>
					<li>You may only have one account at any given time and must not create an account using false identity or information. It should be in your name or your entity's name, with proper authorization.</li>
					<li>If you have been previously removed by the Company, you cannot have an account or use the Service.</li>
					<li>We reserve the right to reject your registration or terminate an existing registration at our sole discretion.</li>
					<li>The email address you provide will be our primary means of communication.</li>
					<li>Any harassment or derogatory behavior towards Printram employees may lead to immediate account termination.</li>
				</ul>
				<p>2. You are responsible for safeguarding your account credentials and must not disclose them to third parties. If you suspect your credentials have been compromised, lost, or stolen, or if there is any unauthorized use of your Printram Account, you must promptly notify Printram. You are solely responsible for all activities carried out through your Printram account, and Printram will not be liable for any losses resulting from unauthorized use of your username or password.</p>
				<p>3. Printram disclaims all liability arising from the revocation, cancellation, or suspension of your account for any reason. Your account and registration will terminate immediately if you breach any of the terms stated here. You can terminate your account at any time from your Account Settings. However, please ensure that you have disconnected your store from sales channels, unsubscribed from the business plan, and removed your registered credit card. Account deletion is irreversible, and you won't be able to access the data afterward. Nevertheless, you will remain responsible for any pending orders and charges.</p>
			</ul>

			<h4>H. What Are Your Rights to Content on Printram?</h4>
			<p className="text-500 text-lg">Summary: All the content you upload belongs to you. We have the discretion to remove content that doesn't meet our standards or if we receive notifications that it isn't yours.</p>
			<ul>
				<p>1. By uploading content to Printram, you grant us permission to use it for purposes like marketing and educating other Printram users.</p>
				<p>2. You are granted a limited, non-exclusive license to use the Service, subject to these Terms of Service. Printram retains all other rights to the Service.</p>
				<p>3. You can upload and post Content to our Website, and anything you place on the Products remains your own. You are responsible for your Content and must have proper authorization to post it.</p>
				<p>4. By using the Printram Service, you provide us with license to use Your Content for the operation, development, and promotion of the Service.</p>
				<p>5. You agree not to misuse the Service, follow all laws and regulations, and not engage in prohibited activities.</p>
				<p>6. You are responsible for your account and must not share access or use the Service for illegal purposes.</p>
				<p>7. You must monitor and prevent minors under 13 from using the Service with your account.</p>
				<p>8. You must not use the Service to resell blank Products without customization.</p>
				<p>9. We may remove Your Content if it violates our terms or is reported for infringing on others' intellectual property rights.</p>
				<p>10. Printram may view, monitor, and record activity on the Service without notice, as allowed by law, although we are not obligated to do so. Information obtained may be shared with law enforcement for criminal investigations involving Service use.</p>
				<p>11. Content that exploits children, harasses, bullies, promotes violence, supports illegal activities, infringes on intellectual property, reveals personal or confidential information, promotes self-harm, or supports terrorist organizations is strictly prohibited and may result in account termination.</p>
			</ul>

			<h4>I. How do Payments Work on Printram?</h4>
			<p className="text-500 text-lg">Summary: Printram will only impose charges if you opt for a Printram Premium Plan. Products will only be manufactured and sent after receiving payment through credit/debit or from an existing Printram balance. Users can modify their payment settings, including frequency. It's important to note that "sales credits" cannot be withdrawn.</p>
			<ul>
				<p>1. Printram does not charge any fees or costs for the platform itself unless you subscribe to the Printram Premium Plan.</p>
				<p>2. To order product samples or fulfill customer orders, payment must be made in advance. This payment includes the product cost, shipping, and applicable taxes, as detailed in the Printram Catalog and Shipping Rates page. Orders are processed only upon receipt of payment.</p>
				<p>3. Printram cannot access funds in your Sales Channel accounts. To process payments within Printram, you need to have funds in your Printram balance or link an accepted payment method to your Printram account. By default, Printram deducts funds from your Printram balance first and, if necessary, charges the remaining amount using your connected payment method.</p>
				<p>4. Printram charges users when orders are sent for production. Users can adjust their settings to determine the frequency of order processing and whether charges occur per order or for multiple orders collectively. These settings are configurable after creating an account and connecting a Sales Channel.</p>
				<p>5. Printram may issue coupons, which are virtual credits usable for manual and sample orders. Coupons can be applied to a single order and cannot be combined or converted into cash.</p>
				<p>6. In case of order issues, contact Printram before disputing the claim with your credit card company. If a dispute is filed, Printram cannot issue refunds until the dispute is resolved or canceled. Refunds offered by Printram after investigation are provided as Printram credits.</p>
				<p>7. Withdrawn funds from a Printram balance are returned to the original payment source, either a credit/debit card or PayPal account. The method of withdrawal depends on the initial payment source.</p>
				<p>8. Users are responsible for paying any required sales tax on their transactions, as tax regulations vary based on the product source and delivery location. Consultation with a tax accountant is recommended.</p>
				<p>9. EU regulations mandate VAT collection on orders produced and shipped within the EU. EU-registered businesses may seek a VAT Exemption with a valid VAT number by contacting Printram Merchant Support.</p>
				<p>10. Printram may process payments and issue invoices through various Printram legal entities based on factors like the Print Provider's location, your location, and the transaction region.</p>
			</ul>

			<h4>J. How Does Printram Handle Order Related Issues?</h4>
			<p className="text-500 text-lg">Summary: If you encounter any problems with your order, please reach out to Printram Merchant Support within 30 days of receiving the product. Provide a thorough description of the issue and include relevant photos or videos as evidence. After our investigation, we will determine if you qualify for a free reprint or refund. It's important to note that certain issues may not meet the criteria for reprinting, as described below.</p>
			<ul>
				<p>1. Printram does not maintain inventory or handle printing directly. Quality and outcome of Produced Orders primarily depend on the chosen Provider. Users are responsible for researching and adhering to print quality requirements.</p>
				<p>2. Conflict resolution is managed by Printram's Merchant Support team on behalf of Providers. Users must contact Printram Merchant Support within 30 days of product delivery and refrain from contacting Print Providers directly.</p>
				<p>3. Custom-made products are non-refundable, and once an Order is placed, changes to order details are typically not possible. Merchants should relay accurate customer information.</p>
				<p>4. Requests for specific Order changes, such as address or delivery method changes, will be evaluated by Merchant Support.</p>
				<p>5. Full or partial refunds for canceled Orders depend on the Order's fulfillment stage.</p>
				<p>6. Orders with artwork issues are subject to a quality control process. Proceeding with such orders or non-response within 7 days may result in ineligibility for replacements or refunds.</p>
				<p>7. Production times are estimated and not guaranteed. Lost Orders during shipment may be investigated for replacements.</p>
				<p>8. Faulty products may be replaced or refunded in Printram credit after investigation by Merchant Support.</p>
				<p>9. Refunds are not provided for Orders in transit unless deemed lost by Merchant Support.</p>
				<p>10. Accurate delivery addresses are the merchant's responsibility. Invalid or incorrect addresses may require reshipment or cancellation, with associated costs borne by the merchant.</p>
				<p>11. Non-delivered Orders should be reported within specific timeframes to be eligible for a reprint or refund.</p>
				<p>12. Tracking indicating delivery, but claims of non-receipt, will be individually investigated.</p>
				<p>13. Shipping delays are beyond Printram's control, and refunds/reprints for such cases are typically not offered unless the package is lost in-transit.</p>
				<p>14. Refund or reprint requests due to issues like artwork, design guidelines, product limitations, or other ineligible reasons will be handled on a case-by-case basis.</p>
			</ul>

			<h4>K. Can You Use Printram’s Image and Name?</h4>
			<p className="text-500 text-lg">Summary: We place a strong emphasis on safeguarding intellectual property rights and we request that you demonstrate the same level of respect for ours.</p>
			<p>Printram, along with Printram.com, Printramapp.com, and various Printram visual elements such as graphics, logos, designs, page headers, button icons, scripts, and service names, are protected trademarks and trade dress owned by Printram. These may not be utilized in connection with any product or service without Printram's prior written consent.</p>
			<p>The brand and press kit images and icons are available for use by partners and third-party websites, provided that appropriate links to the Printram Website are included. Printram retains the right to request the removal of these images and icons from your usage at any time.</p>

			<h4>L. Limitation of Liability</h4>
			<p className="text-500 text-lg">Summary: We want to emphasize that we're not accountable for any losses you might face while using our service. If you happen to violate the law or breach this agreement and face legal consequences, it's essential to understand the following section carefully. It outlines the extent of our responsibilities to you.</p>
			<p className="font-bold">Limitation of Liability: </p>
			<p>Printram, its officers, directors, agents, affiliates, employees, advertisers, and providers shall not be held liable for any indirect, incidental, consequential, or punitive damages, including but not limited to loss of use, profits, or data. This includes scenarios arising from contract, negligence, or any other legal basis, and the total liability in such cases shall not exceed the fees paid by you to Printram or $100.00 (whichever is greater). Please note that certain jurisdictions may not permit the exclusion or limitation of liability, so these restrictions may not apply to you.</p>

			<p className="font-bold">Indemnification: </p>
			<p>You agree to indemnify and hold Printram, its parent company, subsidiaries, affiliates, partners, and providers harmless from any claims, including reasonable attorney's fees, made by third parties due to your violation of these Terms of Service or any legal violations or infringements of third-party rights.</p>
			<p className="font-bold">Warranty Disclaimer: </p>
			<p>Printram operates the Service from various locations and does not guarantee its suitability or availability in all locations. The Service and content are provided "as is" and "as available" without any warranties, whether express or implied, including but not limited to warranties of title, non-infringement, merchantability, and fitness for a particular purpose. Printram, its directors, employees, agents, representatives, suppliers, partners, and providers do not warrant that the Service will be secure or available at all times, that defects or errors will be corrected, that content or software will be free of viruses, or that the Service will meet your requirements. Your use of the Service is at your own risk, and certain states/countries may not allow limitations on implied warranties, so some of these limitations may not apply to you.</p>

			<h4>M. What if I Have Legal Issues with Printram?</h4>
			<p className="text-500 text-lg">Summary: In case you encounter any legal disputes with Printram, you commit to initiating a dialogue with us as the initial step. If, after attempting to resolve the matter together, we are unable to reach a resolution, we will then engage in formal arbitration procedures. If the dispute is not suitable for arbitration, any ensuing legal matters will be addressed in the State of Delaware.</p>

			<span className="font-bold">Dispute Resolution:</span>
			<span> If there's a disagreement related to these Terms of Service, your initial step is to reach out to Printram Merchant Support to attempt to resolve the matter. In cases where direct discussions fail to provide a resolution, both parties agree to explore mediation as a first step, which will be administered by the American Arbitration Association under its Commercial Mediation Procedures. If mediation doesn't resolve the dispute, any remaining controversies or claims linked to the Terms of Service will be settled through arbitration, administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules. The arbitrator's award may be enforced in any court with jurisdiction.</span>
			<p className="mt-3">If, for any reason, a particular claim, dispute, or controversy related to Printram or our Service isn't subject to arbitration as determined by applicable laws or other factors, both You and Printram consent to resolve that specific claim or dispute in line with Clause N.3 of these Terms of Service.</p>
			<p>These Terms of Service are governed by the laws of the State of Delaware, without consideration for conflicts of law principles. You consent to the personal jurisdiction of the United States District Court for the District of Delaware for any actions not covered by the Arbitration process outlined in Clause N.1.</p>

			<h4>N. Printram Premium Plan</h4>
			<p className="text-500 text-lg">Summary: Printram provides two subscription plans, one on a monthly basis and the other on a yearly basis, which offer discounts of up to 20% on all products. These subscription plans are set to automatically renew, and uninstalling the Printram app or disconnecting your store will not cancel them. To avoid renewal charges, you must proactively cancel your subscription before the renewal date.</p>

			<p className="font-bold">Recurring Subscription: </p>
			<p>When you subscribe to the Printram Premium Plan, you'll enjoy discounts of up to 20% on all Printram products. This subscription will continue on a month-to-month or year-to-year basis until you decide to cancel or terminate it. To avoid being billed for the next subscription period, make sure to cancel your subscription before it automatically renews each month or year.</p>
			<p>Before subscribing to the Premium plan, you'll have the opportunity to preview and compare prices. Once subscribed, you'll only see prices with the Premium plan discount applied throughout the catalog.</p>

			<p className="font-bold">Billing: </p>
			<p>Upon starting your subscription, you give us authorization to charge your payment card for the full subscription fee. Please note that the amount charged may vary due to reasons such as promotional offers or changes in the subscription plan. You also authorize us to charge your payment card for these amounts.</p>
			<p>Printram reserves the right to adjust pricing for our service or any of its components at any time, as determined by our sole discretion. Any price changes to your service will become effective after we notify you via email.</p>
			<p>You'll be charged the subscription fee immediately, and these charges will automatically renew every month for monthly subscriptions and every year for yearly subscriptions unless you decide to cancel the subscription.</p>
			<p>We will automatically charge your payment card on the calendar day corresponding to the start of your subscription. If your payment card is declined, we may adjust the timing of the charge. For instance, if your subscription began on a date not found in a given month (e.g., August 31), we will charge your payment card on an appropriate day (e.g., September 30).</p>
			<p>If you switch from a monthly subscription (that is not cancelled) to a yearly subscription, your monthly subscription will end immediately, and the yearly subscription will commence from the switch date. The yearly subscription fee will be adjusted to account for the unused days within your monthly subscription period. For example, if you switch from monthly to yearly halfway through a month, you will be billed $285.38, which includes a deduction for the unused days in that month.</p>
			<p>If you switch from a monthly subscription (that is cancelled but still active) to a yearly subscription, the same rules apply, but you will be charged the full yearly subscription amount of $299.88.</p>

			<p className="font-bold">Cancellation: </p>
			<p>Please note that subscription fee charges are non-refundable, and there are no refunds or credits for partially used subscription periods. After cancellation, you'll retain access to the discounts until the end of your billing cycle.</p>
			<p>To cancel your subscription, access the Payment section by clicking on the settings icon in the upper right-hand corner of your account page. After cancellation, your subscription will remain active until its expiration date but will not auto-renew.</p>
			<p>Keep in mind that you must unsubscribe before the next billing cycle to avoid charges for the following month or year. Printram subscription fee charges cannot be refunded.</p>
			<p>It's important to note that we can only charge your payment cards for the Premium plan subscription and cannot utilize your Printram balance for this purpose.</p>
			<p>Please be aware that Printram Premium doesn't automatically cancel when you disconnect your Printram store or uninstall the Printram app. You must manually cancel it from the Payment section to halt all subscription charges.</p>

			<h4>O. Printram FX Rate</h4>
			<p className="text-500 text-lg">Summary: You have the option to choose a currency other than USD for billing purposes when placing your orders. When you opt for a non-USD currency, Printram will employ the Printram FX Rate to convert the USD amount to your selected currency. It's important to note that if you choose a non-USD currency, any refunds for orders will also be processed and returned to you in the same selected non-USD currency.</p>
			<p>You have the option to choose a currency other than USD for billing when placing orders on Printram. When you opt for a non-USD currency, Printram will utilize its own foreign exchange rates, known as the Printram FX Rate, to convert the total payment, including product costs, shipping, and other applicable expenses, from USD to your chosen currency.</p>
			<p>It's important to note that the Printram FX Rate may differ from the foreign exchange rates available online or in financial databases, as those rates are typically inter-bank rates not accessible to end customers. To ensure efficiency, Printram always strives to apply the most favorable Printram FX Rate based on foreign exchange rate fluctuations.</p>
			<p>Please be aware that Printram reserves the right, at its sole discretion, to modify the Printram FX Rate at any time.</p>
			<p>If you select a non-USD currency for billing, any refunds for orders will be processed and returned to your credit or debit card in the same selected non-USD currency. The calculation of the refund, including any associated fees, will be based on the same Printram FX Rate used for the original payment of your order.</p>
			<p>Please note that choosing a non-USD currency may come with certain limitations. For example, the use of coupons (flat discounts for orders) and Printram's USD Balance will be disabled, but your subscription charges will still be in USD. When you choose a non-USD currency, we will inform you of any applicable limitations.</p>
			<p>You have the flexibility to switch back to using USD as the billing currency for your orders at any time.</p>

			{/* <h5 className="m-0">Overview</h5>
			<p>This website is operated by Wadaaa: Worldwide Multi-Seller Marketplace. Throughout the site, the terms “we”, “us” and “our” refer to Wadaaa: Worldwide Multi-Seller Marketplace. Wadaaa: Worldwide Multi-Seller Marketplace offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
			<p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
			<p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
			<p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
			<p>Our store is hosted on Wadaaa Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</p>
			<h5 className="m-0">Section 1 - Online store terms</h5>
			<p>
				<span className="block">By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</span>
				<span className="block">You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</span>
				<span className="block">You must not transmit any worms or viruses or any code of a destructive nature.</span>
				<span className="block">A breach or violation of any of the Terms will result in an immediate termination of your Services.</span>
			</p>
			<h5 className="m-0">Section 2 - General Conditions</h5>
			<p>
				<span className="block">We reserve the right to refuse service to anyone for any reason at any time.</span>
				<span className="block">You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</span>
				<span className="block">You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</span>
				<span className="block">The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</span>
			</p>
			<h5 className="m-0">Section 3 - Accuracy, completeness and timeliness of information</h5>
			<p>
				<span className="block">We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</span>
				<span className="block">This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</span>
			</p>
			<h5 className="m-0">Section 4 - Modifications to the service and prices</h5>
			<p>
				<span className="block">Prices for our products are subject to change without notice.</span>
				<span className="block">We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</span>
				<span className="block">We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</span>
			</p>
			<h5 className="m-0">Section 5 - Products or services (if applicable)</h5>
			<p>
				<span className="block">Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</span>
				<span className="block">We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.</span>
				<span className="block">We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.</span>
				<span className="block">We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</span>
			</p>
			<h5 className="m-0">Section 6 - Accuracy, of billing and account information</h5>
			<p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</p>
			<p>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
			<p>For more detail, please review our Returns Policy.</p>
			<h5 className="m-0">Section 7 - Optional tools</h5>
			<p>
				<span className="block">We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.</span>
				<span className="block">You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</span>
				<span className="block">Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</span>
				<span className="block">We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</span>
			</p>
			<h5 className="m-0">Section 8 - Third-party links</h5>
			<p>
				<span className="block">Certain content, products and services available via our Service may include materials from third-parties.</span>
				<span className="block">Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</span>
				<span className="block">We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</span>
			</p>
			<h5 className="m-0">Section 9 - User comments, feedback and other submissions</h5>
			<p>
				<span className="block">If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</span>
				<span className="block">We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.</span>
				<span className="block">You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.</span>
			</p>
			<h5 className="m-0">Section 10 - Personal information</h5>
			<p>Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.</p>
			<h5 className="m-0">Section 11 - Errors, inaccuracies and omissions</h5>
			<p>
				<span className="block">Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</span>
				<span className="block">We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</span>
			</p>
			<h5 className="m-0">Section 12 - Prohibited uses</h5>
			<p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
			<h5 className="m-0">Section 13 - Disclaimer of warranties; Limitation of liability</h5>
			<p>
				<span className="block">We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</span>
				<span className="block">We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</span>
				<span className="block">You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</span>
				<span className="block">You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</span>
				<span className="block">In no case shall Wadaaa: Worldwide Multi-Seller Marketplace, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</span>
			</p>
			<h5 className="m-0">Section 14 - Indemnification</h5>
			<p>You agree to indemnify, defend and hold harmless Wadaaa: Worldwide Multi-Seller Marketplace and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
			<h5 className="m-0">Section 15 - Severability</h5>
			<p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
			<h5 className="m-0">Section 16 - Termination</h5>
			<p>
				<span className="block">The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</span>
				<span className="block">These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</span>
				<span className="block">If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</span>
			</p>
			<h5 className="m-0">Section 17 - Entire agreement</h5>
			<p>
				<span className="block">The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</span>
				<span className="block">These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</span>
				<span className="block">Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</span>
			</p>
			<h5 className="m-0">Section 18 - Governing law</h5>
			<p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of United States.</p>
			<h5 className="m-0">Section 19 - Changes to terms of service</h5>
			<p>
				<span className="block">You can review the most current version of the Terms of Service at any time at this page.</span>
				<span className="block">We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</span>
			</p>
			<h5 className="m-0">Section 20 - Contact information</h5>
			<p>
				Questions about the Terms of Service should be sent to us at <a href="mailto:info@printram.co">info@printram.co</a>.
			</p> */}
		</Dialog>
	);
};

export default TermOfService;
