import HttpBaseService from '../../services/_common/http-base-service';
import { GetDashboardInformationsResponse } from './get-dashboard-informations/get-dashboard-informations.response';


class InformationsService extends HttpBaseService {
    constructor() {
        super('informations');
    }

    async getDashboardInformations(): Promise<GetDashboardInformationsResponse> {
        return await this.getData('get-dashboard-informations');
    }
}

export default new InformationsService();