import { GetGroupUsersRequest } from 'features/groups/_common/get-group-users/get-group-users.request';
import { GetSellerListForGroupRequest } from 'features/groups/_common/get-seller-list-for-group/get-seller-list-for-group.request';
import groupsService from 'features/groups/_common/groups.service';
import { UpsertUserGroupRequest } from 'features/groups/_common/upsert-user-group/upsert-user-group.request';
import { GroupForListDto } from 'features/groups/dtos/group-for-list.dto';
import { UserForGroup } from 'features/groups/dtos/group-users.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Dialog } from 'primereact/dialog';
import { PickList, PickListChangeParams } from 'primereact/picklist';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';

type props = {
	isVisible: boolean;
	setVisible: Function;
	group: GroupForListDto;
	setGroup: Function;
	getGroups: Function;
};

const UpdateSellerGroupUsers = ({ isVisible, setVisible, group, setGroup, getGroups }: props) => {
	const [sellers, setSellers] = useState<UserForGroup[]>([]);
	const [groupSellers, setGroupSellers] = useState<UserForGroup[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const itemTemplate = (item: UserForGroup) => {
		return (
			<div className="flex flex-row">
				<span className="text-sm font-bold">
					{item.name} {item.surname} -{' '}
				</span>
				{item.storeNames.length > 0 && <span className="text-sm font-bold">{item.storeNames[0]} - </span>}
				<span className="text-sm">{item.email}</span>
			</div>
		);
	};

	const getSellers = async () => {
		return new Promise(async (resolve) => {
			try {
				const request = new GetSellerListForGroupRequest({});

				const response = await groupsService.getSellerListForGroup(request);

				if (!response.isSuccess) {
					resolve([]);
					throw '';
				}

				resolve(response.data);
			} catch (error: any) {
				if (error.response.data.isSuccess == false) resolve([]);
			} finally {
			}
		});
	};

	const getGroupSellers = async () => {
		return new Promise(async (resolve) => {
			try {
				if (!group.id) throw '';

				const request = new GetGroupUsersRequest(group.id);

				const response = await groupsService.getGroupUsers(request);

				if (!response.isSuccess) {
					resolve([]);
					throw '';
				}

				resolve(response.data?.users);
			} catch (error: any) {
				if (error.response.data.isSuccess == false) resolve([]);
			} finally {
			}
		});
	};

	const onChange = (event: PickListChangeParams) => {
		setSellers(event.source);
		setGroupSellers(event.target);
	};

	const saveUsers = async () => {
		try {
			setLoading(true);

			let sellerIds = groupSellers.flatMap((e) => e.userId);

			const request = new UpsertUserGroupRequest({ groupId: group.id, userIds: sellerIds, isActive: true });

			const response = await groupsService.upsertUserGroup(request);

			if (!response.isSuccess) throw '';

			setGroup(undefined);
			setVisible(false);
			getGroups();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!group.id) return;

		setLoading(true);
		Promise.all([getSellers(), getGroupSellers()])
			.then((values: any) => {
				let groupSellerIds = values[1] ? values[1].flatMap((e: UserForGroup) => e.userId) : [];
				setSellers(values[0].filter((val0: UserForGroup) => !groupSellerIds.includes(val0.userId)));
				setGroupSellers(values[1] || []);
			})
			.catch()
			.finally(() => setLoading(false));
	}, [group]);

	return (
		<Dialog
			visible={isVisible}
			header="Update Group Users"
			modal
			onHide={() => {
				setGroup(undefined);
				setVisible(false);
			}}>
			<div>
				<PickList source={sellers} target={groupSellers} onChange={onChange} itemTemplate={itemTemplate} filterBy="name,surname,email" sourceHeader="Available Sellers" targetHeader="Selected (In Group)" sourceStyle={{ height: '30rem' }} targetStyle={{ height: '30rem' }} />

				<div className='flex justify-content-end mt-4'>
					<PrButton text="Save Users" onClick={saveUsers} icon={<span className='pi pi-fw pi-save' />} />
				</div>
			</div>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</Dialog>
	);
};

export default UpdateSellerGroupUsers;
