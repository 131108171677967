import currencyHelper from '../../../../../../helpers/curreny.helper';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../../context/CustomDtfContextProvider';
import React from 'react';

export const CustomDtfCostBreakdown = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;

	return (
		<div className="flex align-items-center justify-content-center">
			<div className="w-max">
				<h2 className="mb-3" style={{ fontSize: '1.5rem' }}>
					Order breakdown
				</h2>

				<div className="flex justify-content-between mb-2 font-bold">
					<span>Fulfillment</span>
					<span>{currencyHelper.formatPrice(context.grandTotal || 0)}</span>
				</div>

				<div className="flex justify-content-between font-bold">
					<span>Shipping</span>
					<span>{currencyHelper.formatPrice(context.selectedRate?.amount || 0)}</span>
				</div>

				<hr className="my-2" />

				<h2 className="flex justify-content-between m-0" style={{ fontSize: '1.5rem' }}>
					<span>Total</span>
					<span>{currencyHelper.formatPrice(context.calculatedPrice)}</span>
				</h2>
			</div>
		</div>
	);
};
