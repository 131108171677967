import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import currencyHelper from 'helpers/curreny.helper';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import { Tooltip } from 'primereact/tooltip';
import React, { useContext } from 'react';
import { SellerReorderDetailContext, SellerReorderDetailContextType, reorderSteps } from '../../context/SellerReorderDetailContextProvider';
import styles from './StepProducts.module.scss';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { StepWithComponentEnum } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import { FaChevronRight } from 'react-icons/fa';
import { ReOrderProductsTable } from './components/ReOrderProductsTable';

export const StepProducts = () => {
	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	return !!context.reorder ? (
		<React.Fragment>
			<section className="container-body p-3">
				<h4 className="m-0">Details</h4>
				<p className="text-sm text-600">The order details are provided below.</p>

				<div className={styles.detailWrapper}>
					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Customer Name</h6>
						</div>

						<p>{context.reorder.customerFulLName}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Customer Note</h6>
						</div>

						<p>{context.reorder.customerNote || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Gift Note</h6>
						</div>

						<p>{context.reorder.giftNote || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Seller Note</h6>
						</div>

						<p>{context.reorder.sellerNote || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Delivery Type / Order Type</h6>
						</div>

						<span>{DeliveryTypes[context.reorder.deliveryType || 0]} / </span>
						<span>Reorder</span>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Platform</h6>
						</div>

						<p>Printram</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Shipping Address</h6>
						</div>

						<address>{context.reorder?.orderAddress?.formattedAddress || '-'}</address>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Approval Note</h6>
						</div>

						<p>{context.reorder.approvalNote || '-'}</p>
					</div>
				</div>
			</section>

			<section className="container-body p-3">
				<ReOrderProductsTable />
			</section>

			<div className="container-body p-3 sticky bottom-0 left-0 right-0" style={{ height: '5.5rem' }}>
				<div className="flex align-items-center justify-content-end h-full gap-3">
					{!!context.calculatedPrice ? (
						context.calculatedPrice.discountedFulFillment ? (
							<div>
								<small className="font-semibold text-pink-600 line-through">{currencyHelper.formatPrice(context.calculatedPrice?.fulfillment?.formattedPricePerUnit || 1)}</small>
								<h6 className="m-0 text-right">{currencyHelper.formatPrice(context.calculatedPrice.discountedFulFillment.formattedPricePerUnit)}</h6>
							</div>
						) : (
							<div>
								<h6 className="m-0 text-right">{currencyHelper.formatPrice(context.calculatedPrice?.fulfillment?.formattedPricePerUnit || 1)}</h6>
							</div>
						)
					) : null}

					<PrButton
						text="Continue"
						onClick={() => {
							for (const step of reorderSteps) {
								if (step.value === StepWithComponentEnum.Products) {
									step.isCompleted = true;
									continue;
								}

								if (step.value === StepWithComponentEnum.Shipping) {
									context.setIsShowShippingForm(false);
									context.setIsNewShippingRatesCalculateRequired(true);
									context.setReorderStep(step);
									continue;
								}
							}
						}}
						icon={<FaChevronRight />}
					/>
				</div>
			</div>
		</React.Fragment>
	) : null;
};
