export class SurveyGetAvailableByUserRequest {
	userId: string;
	type: number | null;

	constructor(userId: string, type?: number) {
		this.userId = userId;
		this.type = type || null
	}

	get getConvertUriExtension() {
		return `available-surveys-by-user?UserId=${this.userId}&type=${this.type}`;
	}
}
