export class GetCoreProductRequest {
	private productId: string;

	constructor(productId: string) {
		this.productId = productId;
	}

	get getConvertUriExtension() {
		return `core/detail/${this.productId}`;
	}
}
