import 'App.scss';
import dateHelper from 'helpers/dateHelper';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const RequireAuth = ({ children }: any) => {
	const location = useLocation();
	const token = useAppSelector((state) => state.auth.data?.accessToken.token);
	const refreshTokenExpiryDate = useAppSelector((state) => state.auth.data?.refreshToken.expiryDate);
	const navigateUrl = `/login${!!location.search && !location.search.includes('status=') ? location.search : ''}`;

	const isRefreshTokenExpired = dateHelper.getMinutesDiffOfTwoDate(new Date(refreshTokenExpiryDate || new Date().setMinutes(-2))) < 1;

	if (!token || isRefreshTokenExpired) return <Navigate to={navigateUrl} />;

	return children;
};

export default RequireAuth;
