import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";
import { Pagination } from "models/_commons/responses/pagination";
import { TicketFilterTypes } from "../enums/ticket-filter-types.enum";

export class GetTicketsRequest {
	pagination: PaginationDto;
	contains?: string;
	filter: TicketFilterTypes = 0;
	isReorder?: boolean | null;

	constructor(pagination: PaginationDto, contains?: string, filter: TicketFilterTypes = 0, isReorder?: boolean | null) {
		this.pagination = pagination;
		this.filter = filter;
		if (contains) this.contains = contains;
		if (isReorder !== null) this.isReorder = isReorder;
	}

	get getConvertUriExtension() {
		return `?${this.contains ? `contains=${this.contains}&` : ''}${this.filter ? `filter=${this.filter}&` : ''}pagination.pageNumber=${this.pagination.pageNumber}&pagination.itemCount=${this.pagination.itemCount}&pagination.orderBy=${this.pagination.orderBy}${(this.isReorder !== null && this.isReorder !== undefined) ? `&isReorder=${this.isReorder}`: ''}`;
	}
}