import balanceService from 'features/balances/_common/balance.service';
import { GetBalanceByUserIdRequest } from 'features/balances/_common/get-balance-by-user-id/get-balance-by-user-id.request';
import { Price } from 'features/products/dtos/prices/price.dto';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styles from './Balance.module.scss';
import currencyHelper from 'helpers/curreny.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import AddBalance from './AddBalance';

type props = { setActiveIndex: Function }
const Balance = ({ setActiveIndex } : props) => {
	const userId = useAppSelector((state) => state.auth.data?.user.userId || '');

	const [balance, setBalance] = useState<Price>();
	const [addBalanceVisible, setAddBalanceVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const balanceDescriptions = [
		{ icon: 'pi pi-check-circle', description: 'Easily manages expenses related to orders and production without any fuss.' },
		{ icon: 'pi pi-check-circle', description: 'Faster order fulfillment without the requirement for credit card transactions.' },
		{ icon: 'pi pi-check-circle', description: 'Avoid additional charges from transactions and currency conversions.' }
	];

	const getUserBalance = async() => {
		try {
			if (!userId) throw '';

			setLoading(true)

			const request = new GetBalanceByUserIdRequest(userId)

			const response = await balanceService.getBalanceByUserId(request)

			if (!response.data?.price) throw '';

			setBalance(response?.data?.price)
		} finally { setLoading(false) }
	}

	useEffect(() => {
		getUserBalance();
	},[])

	return (
		<React.Fragment>
			<div className="container-body p-3">
				<h4 className="m-0">Printram Balance</h4>
				<p className="text-color-secondary">Making your shopping experience even smoother is just a few clicks away! Loading your account balance is now easier and faster than ever. By adding balance to your account, you can complete your purchases quickly and hassle-free while keeping up with exclusive deals.</p>

				<div className={styles.balanceMain}>
					<div className={styles.balanceCard}>
						<div className={styles.balanceCardDescriptions}>
							{balanceDescriptions.map((bal, index) => (
								<div key={index} className={styles.description}>
									<i className={bal.icon} />
									<span>{bal.description}</span>
								</div>
							))}
						</div>
						<div className={styles.balanceCardPrice}>
							<span>Current balance</span>
							<span className={styles.price}>{currencyHelper.formatPrice(balance?.formattedPricePerUnit || 0)}</span>
							<div className={styles.btn}>
								<PrButton text="Add Balance" icon={<span className="pi pi-fw pi-plus-circle" />} onClick={() => setAddBalanceVisible(true)} />
							</div>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="pr-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}

				{addBalanceVisible && <AddBalance isVisible={addBalanceVisible} setVisible={setAddBalanceVisible} getUserBalance={getUserBalance} setActiveIndex={setActiveIndex} />}
			</div>
		</React.Fragment>
	);
}

export default Balance