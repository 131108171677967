import { useNavigate, useParams } from 'react-router-dom';
import orderService from '../../../features/orders/_common/order.service';
import { GetOrderDetailNewRequest } from '../../../features/orders/_common/get-order-detail-new/get-order-detail-new.request';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextProvider, UpgradeShippingContextType } from './context/UpgradeShippingContextProvider';

const UpgradeShipping = () => {
	const context = React.useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;
	const navigate = useNavigate();
	const { orderId } = useParams();

	useEffect(() => {
		const handleBackButton = (event: PopStateEvent) => {
			event.preventDefault();
			// Custom logic on back button press
			if (context.step.index === 0) {
				navigate(-1); // Go back to the previous page
			} else {
				context.prevStep();
				window.history.pushState(null, document.title, window.location.href);
			}
		};

		// Push a new state so that the back button does not immediately close the app
		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', handleBackButton);

		return () => {
			window.removeEventListener('popstate', handleBackButton);
		};
	}, [navigate]);

	useEffect(() => {
		getOrderDetail();
	}, []);

	const getOrderDetail = async () => {
		try {
			context.setLoading(true);
			if (!orderId) throw '';

			const response = await orderService.getOrderDetailNew(new GetOrderDetailNewRequest(orderId));
			if (!response.isSuccess) throw '';

			context.setOrder(response.data);
			context.setStoreId(response.data?.store.storeId!);

			const deliveryAddress = structuredClone(response.data?.orderAddress)
			context.setDeliveryAddress(deliveryAddress);
			context.setLoading(false);
		} catch (error) {
			context.setOrder(null);
			if (error instanceof AxiosError && error.response?.status === 500) navigate(-1);
		} finally {
			context.setLoading(false);
		}
	};

	return (
		<main className="container relative">
			<h3>Upgrade Shipping #{context.order?.code}</h3>
			<p>You can use this screen to upgrade shipping</p>

			{context!.step.component}
		</main>
	);
};

const UpgradeShippingWrapper = () => {
	return (
		<UpgradeShippingContextProvider>
			<UpgradeShipping />
		</UpgradeShippingContextProvider>
	);
};

export default UpgradeShippingWrapper;
