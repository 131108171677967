import { ErrorMessage } from 'formik';
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';

interface Props extends InputTextareaProps {
	label: string;
	name: string;
	optional?: boolean;
}

const TextareaInput = ({ label, name, optional, ...props }: Props) => {
	return (
		<>
			<div className="flex align-items-center justify-content-between px-1 mb-1">
				<label>
					{label} {!!optional ? <small>(Optional)</small> : ''}
				</label>
				<ErrorMessage name={name} component="small" className="text-red font-medium" />
			</div>
			<InputTextarea name={name} {...props} className="w-full" />
		</>
	);
};

export default TextareaInput;
