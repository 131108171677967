import { toast } from 'react-toastify';

const toastOptions = { autoClose: 8000 }
const info = (message?: string) => toast.info(message || "Info" , toastOptions);
const success = (message?: string) => toast.success(message || "Success" , toastOptions);
const warning = (message?: string) => toast.warn(message || "Warning", toastOptions);
const error = (message?: string) => toast.error(message || "Error", toastOptions);
const defaultToast = (message?: string) => toast(message || "Default", toastOptions);

const toastHelper = { info, success, warning, error, defaultToast };

export default toastHelper;
