import { Calendar, CalendarValueType } from 'primereact/calendar';
import { useEffect, useRef, useState } from 'react';
import PrButton from '../Forms/Buttons/PrButton';
import { GetOrdersDateFilterRequestDto } from 'features/orders/_common/get-orders-for-admin/get-orders-for-admin.request';

type DateTypes = 'today' | 'yesterday' | 'last7days' | 'lastweek' | 'last2weeks' | 'thismonth' | 'lastmonth';

type Props = {
	dates: GetOrdersDateFilterRequestDto;
	onChange: (dates: GetOrdersDateFilterRequestDto) => void;
};

const PrMultipleCalendar = (props: Partial<Props>) => {
	const calendarRef = useRef<Calendar>(null);
	const [dates, setDates] = useState<CalendarValueType>(props.dates?.minDate ? [props.dates.minDate, props.dates.maxDate!] : undefined);
	const [dateType, setDateType] = useState<DateTypes | undefined>(undefined);

	useEffect(() => {
		setDates(props.dates?.minDate ? [props.dates.minDate, props.dates.maxDate!] : undefined);
	}, [props.dates]);

	return (
		<Calendar
			id="prMultiCalendar"
			ref={calendarRef}
			value={dates}
			placeholder={`Select Date`}
			selectionMode="range"
			showIcon
			showOnFocus
			readOnlyInput
			selectOtherMonths
			numberOfMonths={2}
			onChange={(e) => {
				setDates(e.value);
				setDateType(undefined);
			}}
			footerTemplate={() => (
				<div className="mt-3 mb-1 border-top-1 border-400">
					<div className="flex w-full mt-3 gap-2">
						<PrButton
							type={!!dates && dateType === 'today' ? 'primary' : 'secondary'}
							text="Today"
							onClick={() => {
								setDateType('today');
								setDates([new Date(), new Date()]);
							}}
						/>

						<PrButton
							type={!!dates && dateType === 'yesterday' ? 'primary' : 'secondary'}
							text="Yesterday"
							onClick={() => {
								let now = new Date();

								now.setDate(now.getDate() - 1);

								setDateType('yesterday');
								setDates([now, now]);
							}}
						/>

						<PrButton
							type={!!dates && dateType === 'last7days' ? 'primary' : 'secondary'}
							text="Last 7 days"
							onClick={() => {
								let last7days = new Date();
								let yesterday = new Date();

								last7days.setDate(last7days.getDate() - 7);
								yesterday.setDate(yesterday.getDate() - 1);

								setDateType('last7days');
								setDates([last7days, yesterday]);
							}}
						/>

						<PrButton
							type={!!dates && dateType === 'lastweek' ? 'primary' : 'secondary'}
							text="Last week"
							onClick={() => {
								const now = new Date();
								const first = now.getDate() - now.getDay();
								const last = first + 6;

								setDateType('lastweek');
								setDates([new Date(now.setDate(first - 7)), new Date(now.setDate(last - 7))]);
							}}
						/>

						<PrButton
							type={!!dates && dateType === 'last2weeks' ? 'primary' : 'secondary'}
							text="Last 2 weeks"
							onClick={() => {
								const now = new Date();
								const first = now.getDate() - now.getDay();
								const last = first + 6;

								setDateType('last2weeks');
								setDates([new Date(now.setDate(first - 14)), new Date(now.setDate(last - 7))]);
							}}
						/>
						<PrButton
							type={!!dates && dateType === 'thismonth' ? 'primary' : 'secondary'}
							text="This month"
							onClick={() => {
								const now = new Date();

								setDateType('thismonth');
								setDates([new Date(now.getFullYear(), now.getMonth(), 1), new Date(now.getFullYear(), now.getMonth() + 1, 0)]);
							}}
						/>
						<PrButton
							type={!!dates && dateType === 'lastmonth' ? 'primary' : 'secondary'}
							text="Last month"
							onClick={() => {
								const now = new Date();

								setDateType('lastmonth');
								setDates([new Date(now.getFullYear(), now.getMonth() - 1, 1), new Date(now.getFullYear(), now.getMonth(), 0)]);
							}}
						/>
					</div>

					<div className="flex w-full mt-3 gap-2 mt-2">
						<PrButton
							type="success"
							text="Apply"
							onClick={() => {
								const newRequest = new GetOrdersDateFilterRequestDto({});

								if (!!dates) {
									if ((dates as Date[])[1] === null) {
										newRequest.minDate = new Date((dates as Date[])[0]);
										newRequest.minDate.setUTCHours(0, 0, 0, 0);

										newRequest.maxDate = new Date((dates as Date[])[0]);
										newRequest.maxDate.setUTCHours(23, 59, 59, 999);

										setDates([new Date((dates as Date[])[0]), new Date((dates as Date[])[0])]);
									} else {
										newRequest.minDate = new Date((dates as Date[])[0]);
										newRequest.minDate.setUTCHours(0, 0, 0, 0);

										newRequest.maxDate = new Date((dates as Date[])[1]);
										newRequest.maxDate.setUTCHours(23, 59, 59, 999);
									}
								}

								if (!dateType && !!newRequest.minDate && !!newRequest.maxDate) {
									newRequest.minDate.setDate(newRequest.minDate.getDate() + 1);
									newRequest.maxDate.setDate(newRequest.maxDate.getDate() + 1);
								}

								props.onChange?.(newRequest);

								calendarRef.current?.hide();
							}}
						/>

						<PrButton
							type="danger"
							text="Clear"
							onClick={() => {
								setDates(undefined);
							}}
						/>
					</div>
				</div>
			)}
		/>
	);
};

export default PrMultipleCalendar;
