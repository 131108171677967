export class GetMySettingsRequest {
	userId: string;

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `get-my-settings?UserId=${this.userId}`;
	}
}