import React, { useState } from 'react';
import GoBackButton from 'helpers/widgets/Buttons/GoBackButton';
import { SurveyCreateRequest, SurveyQuestionDtoRequest, SurveyQuestionOptionDtoRequest } from 'features/surveys/_common/survey-create/survey-create.request';
import { FieldArray, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import TextInput from 'helpers/widgets/Forms/TextInput';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import surveyService from 'features/surveys/_common/survey.service';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

const SurveyCreate = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('Loading');

	const initialValues = new SurveyCreateRequest({});

	const handleQuestionDragEnd = (event: DropResult, questions: SurveyQuestionDtoRequest[], setQuestions: (questions: SurveyQuestionDtoRequest[]) => void) => {
		if (!event.destination) return;

		const updatedQuestions = [...questions];
		const [reorderedItem] = updatedQuestions.splice(event.source.index, 1);
		updatedQuestions.splice(event.destination.index, 0, reorderedItem);

		setQuestions(updatedQuestions);
	};

	const handleQuestionOptionsDragEnd = (event: DropResult, questionOptions: SurveyQuestionOptionDtoRequest[], setQuestions: (questionOptions: SurveyQuestionOptionDtoRequest[]) => void) => {
		if (!event.destination) return;

		const updatedQuestionOptions = [...questionOptions];
		const [reorderedItem] = updatedQuestionOptions.splice(event.source.index, 1);
		updatedQuestionOptions.splice(event.destination.index, 0, reorderedItem);

		setQuestions(updatedQuestionOptions);
	};

	const submitForm = async (values: SurveyCreateRequest) => {
		try {
			setLoadingText('Survey is being created');
			setLoading(true);

			values.questions.forEach((_question, _questionIndex) => {
				_question.order = _questionIndex;

				_question.options.forEach((_option, _optionIndex) => {
					_option.order = _optionIndex;
				});
			});

			const response = await surveyService.create(values);
			if (!response.isSuccess) throw '';

			navigate(-1);
		} catch (error) {
		} finally {
			setLoadingText('Survey is created');
			setLoading(false);
		}
	};

	return (
		<div className="grid justify-content-center">
			<div className="col-12 xl:col-8 relative">
				<div className="card">
					<div className="flex align-items-center justify-content-between mb-3">
						<h5 className="m-0">Create new survey</h5>

						<div className="flex gap-3">
							<GoBackButton />
						</div>
					</div>

					<hr />

					<Formik initialValues={initialValues} onSubmit={submitForm}>
						{({ values, handleChange, setFieldValue }) => (
							<Form>
								<div className="grid">
									<div className="col-12">
										<TextInput label="Name" name="name" value={values.name} placeholder="Enter survey name" onChange={handleChange} />
									</div>

									<div className="col-12 flex flex-row">
										<div className="field-checkbox select-none m-0 mr-5">
											<Checkbox inputId={`isActive`} name={`isActive`} checked={values.isActive} onChange={(event) => setFieldValue(`isActive`, event.checked)} />

											<label htmlFor={`isActive`} className="cursor-pointer">
												Is active
											</label>
										</div>
										<div className="field-checkbox select-none m-0">
											<Checkbox inputId={`isActive`} name={`isActive`} checked={values.isSaveAnswersToCrm} onChange={(event) => setFieldValue(`isSaveAnswersToCrm`, event.checked)} />

											<label htmlFor={`isActive`} className="cursor-pointer">
												Save to CRM
											</label>
										</div>
									</div>
								</div>

								<FieldArray name="questions">
									{(_questionField) => (
										<div className="my-4">
											<div className="grid">
												<div className="col-12 lg:col-10 align-self-center">
													<h5 className="m-0">Questions</h5>
												</div>

												<div className="col-12 lg:col-2">
													<Button type="button" onClick={(_) => _questionField.push(new SurveyQuestionDtoRequest({}))} icon="pi pi-fw pi-plus-circle" label="Add Question" className="p-button-outlined font-bold w-full" />
												</div>
											</div>

											<DragDropContext onDragEnd={(e) => handleQuestionDragEnd(e, values.questions, (questions) => setFieldValue('questions', questions))}>
												<Droppable droppableId="questionDroppable">
													{(questionProvided) => (
														<div ref={questionProvided.innerRef}>
															{!!values.questions && values.questions.length > 0 ? (
																values.questions.map((question, index) => (
																	<Draggable key={`question-${index}`} draggableId={`questionDraggable-${index}`} index={index}>
																		{(questionDraggableProvided) => (
																			<div ref={questionDraggableProvided.innerRef} {...questionDraggableProvided.draggableProps} className="odd">
																				<FieldArray name={`questions[${index}].options`}>
																					{(_optionField) => (
																						<div className="grid mt-1">
																							<div className="col-12 lg:col-8">
																								<TextInput label={`${index + 1}. Question`} placeholder="Enter question" name={`questions[${index}].question`} value={question.question} onChange={handleChange} />
																							</div>

																							<div className="col-12 lg:col-2 align-self-end">
																								<Button type="button" onClick={(_) => _optionField.push(new SurveyQuestionOptionDtoRequest({}))} icon="pi pi-fw pi-plus-circle" label="Add Option" className="p-button-info p-button-outlined font-bold w-full h-3rem" />
																							</div>

																							<div className="col-12 lg:col-1 align-self-end">
																								<Button type="button" onClick={(_) => _questionField.remove(index)} icon="pi pi-fw pi-trash" className="p-button-danger p-button-outlined font-bold w-full h-3rem" />
																							</div>

																							<div {...questionDraggableProvided.dragHandleProps} className="col-12 lg:col-1 align-self-end">
																								<span role="button" className="p-button p-button-info p-button-outlined flex align-items-center justify-content-center w-full h-3rem">
																									<span className="pi pi-arrows-alt py-1"></span>
																								</span>
																							</div>

																							<div className="col-12">
																								<DragDropContext onDragEnd={(e) => handleQuestionOptionsDragEnd(e, values.questions[index].options, (questionOptions) => setFieldValue(`questions[${index}].options`, questionOptions))}>
																									<Droppable droppableId={`question${index}Droppable`}>
																										{(optionProvided) => (
																											<div ref={optionProvided.innerRef} {...optionProvided.droppableProps}>
																												{!!question.options && question.options.length > 0
																													? question.options.map((option, optionIndex) => (
																															<Draggable key={`option-${index}-${optionIndex}`} draggableId={`option-${index}-${optionIndex}`} index={optionIndex}>
																																{(optionDraggableProvided) => (
																																	<div ref={optionDraggableProvided.innerRef} {...optionDraggableProvided.draggableProps}>
																																		<div className={`grid mt-1`}>
																																			<div className="col-12 lg:col-10">
																																				<TextInput label={`${optionIndex + 1}. Option`} placeholder="Enter option" name={`questions[${index}].options[${optionIndex}].text`} value={option.text} onChange={handleChange} />
																																			</div>

																																			<div className="col-12 lg:col-1 align-self-end">
																																				<Button type="button" onClick={(_) => _optionField.remove(optionIndex)} icon="pi pi-fw pi-trash" className="p-button-danger p-button-outlined font-bold w-full h-3rem" />
																																			</div>

																																			<div {...optionDraggableProvided.dragHandleProps} className="col-12 lg:col-1 align-self-end">
																																				<span role="button" className="p-button p-button-info p-button-outlined flex align-items-center justify-content-center w-full h-3rem">
																																					<span className="pi pi-arrows-alt py-1"></span>
																																				</span>
																																			</div>

																																			<div className="col-12">
																																				<div className="field-checkbox select-none m-0">
																																					<Checkbox inputId={`questions[${index}].options[${optionIndex}].textCanBeAdded`} name={`questions[${index}].options[${optionIndex}].textCanBeAdded`} checked={option.textCanBeAdded} onChange={(event) => setFieldValue(`questions[${index}].options[${optionIndex}].textCanBeAdded`, event.checked)} />
																																					<label htmlFor={`questions[${index}].options[${optionIndex}].textCanBeAdded`} className="cursor-pointer">
																																						Text can be added
																																					</label>
																																				</div>
																																			</div>
																																		</div>
																																	</div>
																																)}
																															</Draggable>
																														))
																													: null}
																												{optionProvided.placeholder}
																											</div>
																										)}
																									</Droppable>
																								</DragDropContext>
																							</div>
																						</div>
																					)}
																				</FieldArray>
																			</div>
																		)}
																	</Draggable>
																))
															) : (
																<div className="surface-200 border-round text-color-secondary font-bold text-center py-5">
																	<span>No Question Found</span>
																</div>
															)}
															{questionProvided.placeholder}
														</div>
													)}
												</Droppable>
											</DragDropContext>
										</div>
									)}
								</FieldArray>

								<div className="grid">
									<div className="col-12 text-right">
										<Button type="submit" disabled={!values.name || values.name.length < 1 || values.questions.length < 1 || !!values.questions.find((_quest) => _quest.options.length < 1 || _quest.question.length < 1)} icon="pi pi-fw pi-plus-circle" label="Create Survey" className="px-4" />
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>

				{loading ? (
					<div className="custom-loading">
						<div className="flex flex-column text-0">
							<h2>{loadingText}</h2>
							<ProgressSpinner className="p-progress-color" strokeWidth="4" />
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default SurveyCreate;
