import { useContext } from 'react';
import { SellerReorderDetailContext, SellerReorderDetailContextType } from '../../context/SellerReorderDetailContextProvider';
import { ShipForm } from './ship-form/ShipForm';
import { ShipSummary } from './ship-summary/ShipSummary';

export const StepShipping = () => {
	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	return !context.isShowShippingForm && !!context.calculatedPrice ? <ShipSummary /> : <ShipForm />;
};
