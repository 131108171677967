
import groupsService from 'features/groups/_common/groups.service';
import { UpdateGroupRequest } from 'features/groups/_common/update-group/update-group.request';
import { GroupForListDto } from 'features/groups/dtos/group-for-list.dto';
import { Form, Formik } from 'formik';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import * as Yup from 'yup';

type props = {
	isVisible: boolean;
	setVisible: Function;
	group: GroupForListDto;
	setGroup: Function;
	getGroups: Function;
}

const UpdateSellerGroup = ({ isVisible, setVisible, group, setGroup, getGroups }: props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues: UpdateGroupRequest = new UpdateGroupRequest({ id: group.id || '', name: group.name || '', description: group.description || '', isActive: true });

	const validationScheme = Yup.object().shape({ name: Yup.string().required('Group name is Required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

	const submitForm = async(values: UpdateGroupRequest) => {
		try {
			setLoading(true);

			const request = new UpdateGroupRequest(values)

			const response = await groupsService.updateGroup(request)

			if (!response.isSuccess) throw ''

			setGroup(undefined);
			setVisible(false);
			getGroups();
		} catch (error) {

		} finally {
			setLoading(false)
		}
	};

  return (
		<Dialog
			visible={isVisible}
			header="Update Group"
			modal
			onHide={() => {
				setGroup(undefined);
				setVisible(false);
			}}>
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div>
							<div>
								<PrTextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Group name" className="w-full" />
							</div>
							<div className='my-3'>
								<PrTextInput label="Description" name="description" value={values.description} onChange={handleChange} placeholder="Description" className="w-full" />
							</div>
							<div className="flex justify-content-end">
								<PrButton btnType="submit" text="Update" loading={loading} className="py-3" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
  );
}

export default UpdateSellerGroup