import { ShippingRateDto } from '../dtos/shipping-rate.dto';
import { PaymentMethodDto } from '../../../../stripe/_common/dtos/payment-method.dto';
import { PaymentTypes } from '../../../dtos/payment-type.dto';

export class UpdateTransportDeliveryAddress {
	receiverFullName: string;
	receiverPhoneNumber?: string | null;
	receiverEmail: string;
	firstLine: string;
	secondLine?: string;
	city: string;
	state: string;
	zip: string;
	countryIso: string;
	formattedAddress?: string;
	vatNumber?: string;

	constructor(props: Partial<UpdateTransportDeliveryAddress>) {
		this.receiverFullName = props.receiverFullName || '';
		this.receiverPhoneNumber = props.receiverPhoneNumber;
		this.receiverEmail = props.receiverEmail || '';
		this.firstLine = props.firstLine || '';
		this.secondLine = props.secondLine || "";
		this.city = props.city || "";
		this.state = props.state || "";
		this.zip = props.zip || "";
		this.countryIso = props.countryIso || "";
		this.formattedAddress = props.formattedAddress;
		this.vatNumber = props.vatNumber || "";
	}
}

export class ShippingRateWithDiscountAmountDto {
	shippingRate: ShippingRateDto;
	/*discountedAmount: number;*/

	constructor (props: ShippingRateWithDiscountAmountDto) {
		this.shippingRate = props.shippingRate;
		/*this.discountedAmount = props.discountedAmount;*/
	}
}

export class UpdateTransportRequest {
	orderId: string;
	shipTo: UpdateTransportDeliveryAddress;
	shippingRate: ShippingRateWithDiscountAmountDto;
	paymentMethodType: PaymentTypes;

	constructor(props: Partial<UpdateTransportRequest>) {
		this.orderId = props.orderId!;
		this.shipTo = props.shipTo!;
		this.shippingRate = props.shippingRate!;
		this.paymentMethodType = props.paymentMethodType!;
	}

	get getConvertUriExtension() {
		return `update-transport`;
	}
}
