import { MediaBase } from 'features/medias/dtos/bases/media.base.dto';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';
import { useRef, useState } from 'react';
import styles from './GalleriaV2.module.scss';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';

type Props = {
	medias: MyProductMedia[];
};

const GalleriaV2 = (props: Props) => {
	const sliderDivRef = useRef<HTMLDivElement>(null);

	const [selectedImage, setSelectedImage] = useState<MyProductMedia>(props.medias[0]);

	const handleUpClick = () => {
		if (!sliderDivRef.current) return;

		sliderDivRef.current.scrollTop -= sliderDivRef.current.getClientRects()[0].height / 1.5;
	};

	const handleDownClick = () => {
		if (!sliderDivRef.current) return;

		sliderDivRef.current.scrollTop += sliderDivRef.current.getClientRects()[0].height / 1.5;
	};

	return (
		<div className="grid">
			<div className="col-4 lg:col-2 text-center">
				<div className="mb-3 select-none">
					<button onClick={handleUpClick} className={styles.toggleBtn}>
						<span className="pi pi-angle-up"></span>
					</button>
				</div>

				<div ref={sliderDivRef} className="flex flex-column gap-3 max-h-25rem overflow-y-auto w-full" style={{ scrollSnapType: 'y mandatory' }}>
					{!!props.medias && props.medias.length > 0 ? props.medias.map((_media, index) => <img onClick={() => setSelectedImage(_media)} loading="lazy" key={index} src={amazonS3Helper.showFile(_media, { resize: '76x76' })} className={`${styles.thumbnailImg}${amazonS3Helper.getImageWithContainer(selectedImage).url === amazonS3Helper.getImageWithContainer(_media).url ? ` ${styles.selectedImg}` : ''}`} />) : null}
				</div>

				<div className="mt-3 select-none">
					<button onClick={handleDownClick} className={styles.toggleBtn}>
						<span className="pi pi-angle-down"></span>
					</button>
				</div>
			</div>

			<div className="col-8 lg:col-10">
				<img src={amazonS3Helper.showFile(selectedImage, { resize: '444x444' })} style={{ backgroundImage: `url("${amazonS3Helper.showFile(selectedImage, { resize: '100x100', quality: 25 })}")`, minWidth: '100%', minHeight: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} loading="lazy" className="w-full" />
			</div>
		</div>
	);
};

export default GalleriaV2;
