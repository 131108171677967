export class CreateWithdrawRequestRequest {
	userId: string = '';
	amount: number = 0;

	constructor(userId: string, amount: number) {
		this.userId = userId;
		this.amount = amount;
	}

	get getConvertUriExtension() {
		return `${this.userId}/withdraw-request`;
	}
}