import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';

type Props = {
	number?: boolean;
	className?: string;
	label: string;
	value: string | number;
	disabled?: boolean;
	onChange?: (value: number) => void;
};

const ItemRowField = ({ className, label, value, disabled, number, onChange }: Props) => {
	const [numValue, setNumValue] = React.useState(value);

	useEffect(() => {
		setNumValue(value);
	}, [value]);

	const parseNumber = (val: string | number): number => {
		const parsed = typeof val === 'string' ? parseFloat(val) : val;
		return isNaN(parsed) ? 0 : parsed;
	};

	const onButtonClick = (value: number) => {
		if (!onChange) return;

		onChange(value);
		setNumValue(value);
	};

	return (
		<div className={`${className} flex flex-column justify-content-start align-items-start w-full`}>
			<label className={'text-sm'}>{label}</label>
			<div className={'relative w-full'}>
				{number && (
					<span style={{ top: '50%', transform: 'translateY(-50%)' }} className={'absolute right-0 flex flex-column mr-2'}>
						<div className={'pi pi-angle-up cursor-pointer'} role="button" onClick={() => onButtonClick(parseNumber(numValue) + 1)} />
						<div className={'pi pi-angle-down cursor-pointer'} role="button" onClick={() => numValue > 1 ? onButtonClick(parseNumber(numValue) - 1) : null} />
					</span>
				)}
				<InputText
					disabled={disabled}
					value={numValue}
					keyfilter="int"
					className={'w-full bg-transparent border-round-md border-solid border-gray-200'}
					onChange={(e) => {
						if (!onChange || (number ? parseNumber(e.target.value) < 1 : false)) return;

						onChange(parseNumber(e.target.value));
						setNumValue(e.target.value);
					}}
				/>
			</div>
		</div>
	);
};

export default ItemRowField;
