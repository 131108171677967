import HttpBaseService from 'services/_common/http-base-service';
import { GetCategoriesRequest } from './get-categories/get-categories.request';
import { GetCategoriesResponse } from './get-categories/get-categories.response';
import { CreateCategoryRequest } from './create-category/create-category.request';
import { CreateCategoryResponse } from './create-category/create-category.response';
import { GetCategoriesWithProductsRequest } from './get-categories-with-products/get-categories-with-products.request';
import { GetCategoriesWithProductsResponse } from './get-categories-with-products/get-categories-with-products.response';
import { GetTemplateCategoriesRequest } from './get-template-categories/get-template-categories.request';
import { GetTemplateCategoriesResponse } from './get-template-categories/get-template-categories.response';
import { UpdateCategoryRequest } from './update-category/update-category.request';
import { UpdateCategoryResponse } from './update-category/update-category.response';
import { SoftDeleteCategoryRequest } from './soft-delete-category/soft-delete-category.request';

class CategoryService extends HttpBaseService {
	constructor() {
		super('categories');
	}

	async create(request: CreateCategoryRequest): Promise<CreateCategoryResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}

	async update(request: UpdateCategoryRequest): Promise<UpdateCategoryResponse> {
		return await this.putData(request.getConvertUriExtension, request, true);
	}

	async softDelete(request: SoftDeleteCategoryRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension);
	}

	async getAll(request: GetCategoriesRequest): Promise<GetCategoriesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getTemplateCategories(request: GetTemplateCategoriesRequest): Promise<GetTemplateCategoriesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getWithProducts(request: GetCategoriesWithProductsRequest): Promise<GetCategoriesWithProductsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new CategoryService();
