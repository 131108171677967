import { SurveyQuestionDtoRequest, SurveyQuestionOptionDtoRequest, UserRoleEnum } from '../survey-create/survey-create.request';
import { SurveyDto } from '../survey-get/survey-get.response';

export class SurveyUpdateQuestionOptionDtoRequest extends SurveyQuestionOptionDtoRequest {
	id: string | null;

	constructor(props: Partial<SurveyUpdateQuestionOptionDtoRequest>) {
		super({ ...props });

		this.id = props.id || null;
	}
}

export class SurveyUpdateQuestionDtoRequest extends SurveyQuestionDtoRequest {
	id: string | null;
	options: SurveyUpdateQuestionOptionDtoRequest[];

	constructor(props: Partial<SurveyUpdateQuestionDtoRequest>) {
		super({ ...props });

		this.options = props.options || [];
		this.id = props.id || null;
	}
}

export class SurveyUpdateRoleRequest {
	id: string | null;
	role: UserRoleEnum;

	constructor(props: Partial<SurveyUpdateRoleRequest>) {
		this.id = props.id || null;
		this.role = props.role || UserRoleEnum.Seller;
	}
}

export class SurveyUpdateRequest {
	id: string;
	name: string;
	isActive: boolean;
	roles: SurveyUpdateRoleRequest[];
	questions: SurveyUpdateQuestionDtoRequest[];

	constructor(props: Partial<SurveyUpdateRequest>) {
		this.id = props.id || '';
		this.name = props.name || '';
		this.isActive = props.isActive || true;
		this.roles = props.roles || [];
		this.questions = props.questions || [];
	}

	get getConvertUriExtension() {
		return ``;
	}

	initRequestBySurvey(survey: SurveyDto) {
		this.id = survey.id;
		this.name = survey.name;
		this.isActive = survey.isActive;
		this.roles = survey.surveyUserRoles.map((_role) => ({ id: _role.id, role: _role.role }));
		this.questions = survey.surveyQuestions.map(
			(_question) =>
				new SurveyUpdateQuestionDtoRequest({
					id: _question.id,
					order: _question.order,
					question: _question.question,
					options: _question.surveyQuestionOptions.map((_option) => ({
						id: _option.id,
						order: _option.order,
						text: _option.text,
						textCanBeAdded: _option.textCanBeAdded
					}))
				})
		);
	}
}
