import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { TemplateProductDto } from 'features/products/derived-features/template-products/get-template-products-for-list/dtos/template-product.dto';
import { GetTemplateProductsForListRequest } from 'features/products/derived-features/template-products/get-template-products-for-list/get-template-products-for-list.request';
import { SoftDeleteTemplateProductRequest } from 'features/products/derived-features/template-products/soft-delete-template-products/soft-delete-template-products.request';
import templateProductService from 'features/products/derived-features/template-products/template-product.service';
import { Pagination } from 'models/_commons/responses/pagination';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { setForTemplateProductsPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { Column } from 'primereact/column';
import mediaHelper from 'helpers/media.helper';
import { Button } from 'primereact/button';
import dateHelper from 'helpers/dateHelper';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';

const TemplateProducts = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forTemplateProductsPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [products, setProducts] = useState<TemplateProductDto[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			const request = new GetTemplateProductsForListRequest({ pagination: pagination });

			const response = await templateProductService.getTemplateProducts(request);
			if (!response.isSuccess || !response.data) throw '';

			setPaginationResponse(response.pagination);
			setProducts(response.data);
		} catch (error) {
			setPaginationResponse(undefined);
			setProducts([]);
		} finally {
			setLoading(false);
		}
	};

	const deleteProduct = async (id: string) => {
		try {
			setLoading(true);

			const response = await templateProductService.softDelete(new SoftDeleteTemplateProductRequest(id));
			if (!response) throw '';

			setLoading(false);
			await getAll(fromStatePagination);
		} catch (error) {
			setLoading(false);
		}
	};

	const onPageChangeEvent = (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;

			const serializeData = JSON.stringify(newPagination);
			dispatch(setForTemplateProductsPaginationInfo(JSON.parse(serializeData)));
		}

		getAll(newPagination);
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		const serializeData = JSON.stringify(newPagination);
		dispatch(setForTemplateProductsPaginationInfo(JSON.parse(serializeData)));
		getAll(newPagination);
	}, []);

	const productDesignBodyTemplate = (product: TemplateProductDto) => <img src={amazonS3Helper.showFile(product.productDesignedMedia, { resize: '64x64' })} alt={product.name} loading="lazy" className="w-4rem h-4rem border-round-lg shadow-5" />;
	const designImageBodyTemplate = (product: TemplateProductDto) => <img src={amazonS3Helper.showFile(product.designMedia, { resize: '64x64' })} alt={product.name} loading="lazy" className="w-4rem h-4rem border-round-lg shadow-5" />;
	const createdDateBodyTemplate = (product: TemplateProductDto) => dateHelper.formatDate(product.createdDate);
	const actionsBodyTemplate = (product: TemplateProductDto) => <Button onClick={(_) => deleteProduct(product.id)} label="Delete" icon="pi pi-trash" className="p-button-danger" />;

	return (
		<div className="card">
			<h5>Template Products</h5>

			<DataTable value={products} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading} className="p-datatable-gridlines">
				<Column field="name" header="Name / Code" />
				<Column field="media" header="Image" body={productDesignBodyTemplate} />
				<Column field="media" header="Design" body={designImageBodyTemplate} />
				<Column field="categories" header="Category" />
				<Column field="createdDate" header="Created Date" body={createdDateBodyTemplate} />
				<Column header="Actions" body={actionsBodyTemplate} style={{ width: '0' }} />
			</DataTable>
		</div>
	);
};

export default TemplateProducts;
