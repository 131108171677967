import React from 'react';
import { SyncOrderContext, SyncOrderContextType, orderSteps } from 'components/pages/orders/sync-order/context/SyncOrderContextProvider';
import { UsaFlagIcon } from '../icon/UsaFlagIcon';
import currencyHelper from 'helpers/curreny.helper';

const ShippingInfo = () => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	return (
		<div>
			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping
			</h2>

			<div className="flex gap-4">
				<div className="w-3">
					<h4 className="mb-4" style={{ fontSize: '1rem' }}>
						Shipping From
					</h4>

					<p>
						<UsaFlagIcon style={{ width: '1.5625rem', height: '0.8125rem' }} />
						<span className="ml-3">United States</span>
					</p>
				</div>

				<div className="w-3">
					<h4 className="mb-4" style={{ fontSize: '1rem' }}>
						Shipping to
						<span
							role="button"
							tabIndex={0}
							className="text-primary underline cursor-pointer ml-2 font-normal"
							onClick={() => {
								context.setIsShowShippingForm(true);
								context.setOrderStep(orderSteps[1]);
							}}>
							Edit
						</span>
					</h4>

					<p className="mb-0">{context.request.createModel.shipTo?.receiverFullName}</p>

					<p className="mb-0">{context.request.createModel.shipTo?.firstLine}</p>

					{!!context.request.createModel.shipTo?.secondLine ? <p className="mb-0">{context.request.createModel.shipTo?.secondLine}</p> : null}

					<p className="mb-0">
						{context.request.createModel.shipTo?.city}, {context.request.createModel.shipTo?.state} {context.request.createModel.shipTo?.zip} {context.request.createModel.shipTo?.countryIso}
					</p>

					{!!context.request.createModel.shipTo?.receiverPhoneNumber ? (
						<p className="mb-0">
							{context.request.createModel.shipTo?.phonePrefix.label}
							{context.request.createModel.shipTo?.receiverPhoneNumber}
						</p>
					) : null}
				</div>

				<div className="w-6">
					{!!context.selectedRate && orderSteps[1].isCompleted && context.orderStep.value !== orderSteps[1].value ? (
						<React.Fragment>
							<h4 className="mb-4" style={{ fontSize: '1rem' }}>
								Shipping method
								<span
									role="button"
									tabIndex={0}
									className="text-primary underline cursor-pointer ml-2 font-normal"
									onClick={() => {
										context.setIsShowShippingForm(false);
										context.setOrderStep(orderSteps[1]);
									}}>
									Edit
								</span>
							</h4>

							<p>
								{currencyHelper.formatPrice(+context.selectedRate.amount)} - {context.selectedRate.serviceName}
							</p>
						</React.Fragment>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ShippingInfo;
