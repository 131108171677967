import HttpBaseService from "services/_common/http-base-service";
import { GetPlanFeatureTypesRequest } from "./get-plan-feature-types/get-plan-feature-types.request";
import { GetPlanFeatureTypesResponse } from "./get-plan-feature-types/get-plan-feature-types.response";

class PlanFeatureTypesService extends HttpBaseService {
	constructor() {
		super('plan-feature-types');
	}

	async getAll(request: GetPlanFeatureTypesRequest): Promise<GetPlanFeatureTypesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new PlanFeatureTypesService();
