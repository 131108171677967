import React, { useEffect, useState } from 'react';
import styles from './SellerMyPrices.module.scss';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { GetVariantSummariesRequest } from 'features/products/derived-features/product-variants/get-variant-summaries/get-variant-summaries.request';
import productVariantService from 'features/products/derived-features/product-variants/product-variant.service';
import { VariantSummary } from 'features/products/derived-features/product-variants/get-variant-summaries/dtos/variant-summary.dto';
import { Pagination } from 'models/_commons/responses/pagination';
import { UserVariantPrice } from 'features/products/derived-features/product-variant-prices/get-user-product-variant-prices/dtos/user-variant-price.dto';
import { useAppSelector } from 'redux/hooks';
import { User } from 'models/dtos/auth/users/user';
import { GetUserProductVariantPricesRequest } from 'features/products/derived-features/product-variant-prices/get-user-product-variant-prices/get-user-product-variant-prices.request';
import productVariantPricesService from 'features/products/derived-features/product-variant-prices/product-variant-prices.service';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { useNavigate } from 'react-router-dom';
import { SelectPrintramProductDialog } from 'components/_common/modals/select-printram-product/SelectPrintramProductDialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import currencyHelper from 'helpers/curreny.helper';
import { Paginator } from 'primereact/paginator';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import { SelectPrintramProductDialogNew } from 'components/_common/modals/select-printram-product-new/SelectPrintramProductDialogNew';

const SellerMyPrices = () => {
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const navigate = useNavigate();

	const [selectedProduct, setSelectedProduct] = useState<PrDropdownType>();
	const [variants, setVariants] = useState<VariantSummary[]>([]);
	const [viewVariants, setViewVariants] = useState<VariantSummary[]>([]);
	const [copiedVariants, setCopiedVariants] = useState<VariantSummary[]>([]);
	const [userPrices, setUserPrices] = useState<UserVariantPrice[]>([]);
	const [sizes, setSizes] = useState<PrDropdownType[]>([]);
	const [selectedSize, setSelectedSize] = useState<PrDropdownType>();
	const [colors, setColors] = useState<PrDropdownType[]>([]);
	const [selectedColor, setSelectedColor] = useState<PrDropdownType>();
	const [filters, setFilters] = useState([
		{ name: 'size', value: '' },
		{ name: 'color', value: '' }
	]);
	const [paginator, setPaginator] = useState<PaginationDto>({ pageNumber: 1, itemCount: 40, orderBy: 2, first: 0 });
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [pricesLoading, setPricesLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [visible, setVisible] = useState<boolean>(false);

	const getVariants = async (pagination: PaginationDto) => {
		try {
			if (!selectedProduct) throw '';

			setLoading(true);

			const request = new GetVariantSummariesRequest([selectedProduct.value.toString()], pagination);

			const response = await productVariantService.getVariantSummaries(request);

			if (!response.isSuccess) throw '';

			const formattedVariants = response.data?.variants?.data?.map((_vari: VariantSummary) => ({ ..._vari, size: _vari.size.name }));
			setVariants(formattedVariants || ([] as any));
			setCopiedVariants(formattedVariants || ([] as any));

			const first = paginator.first;
			const count = paginator.itemCount || 0;
			const firstCount = first + count;
			if (formattedVariants) setViewVariants(formattedVariants.filter((_vari, index) => index >= first && index < firstCount) as any);

			const sizeOptions = response.data?.sizes.map((size) => ({ label: size, value: size }));
			setSizes([{ label: 'All', value: '' }, ...(sizeOptions || [])]);

			const colorOptions = response.data?.colors.map((color) => ({ label: color, value: color }));
			setColors([{ label: 'All', value: '' }, ...(colorOptions || [])]);

			setPaginationResponse(response.data?.variants.pagination);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const getUserPrices = async (pagination: PaginationDto) => {
		try {
			if (!selectedProduct || !userId) throw '';

			setPricesLoading(true);

			const request = new GetUserProductVariantPricesRequest(userId, [selectedProduct.value.toString()], pagination);

			const response = await productVariantPricesService.getUserProductVariantPrices(request);

			if (!response.isSuccess) throw '';

			setUserPrices(response.data || []);
		} catch (error) {
		} finally {
			setPricesLoading(false);
		}
	};

	const sellerPriceHandler = (rowData: VariantSummary) => {
		const sellerPrice = userPrices.find((_variant) => _variant.productVariantId === rowData.id)?.price.formattedPricePerUnit;
		const printramPrice = viewVariants.find((_variant) => _variant.id === rowData.id)?.printramPrice.formattedPricePerUnit;

		return <span className={sellerPrice ? styles.sellerPrice : ''}>{currencyHelper.formatPrice(sellerPrice || printramPrice || 0)}</span>;
	};

	const handleSizeChange = (event: PrDropdownType) => {
		setSelectedSize(event);

		setFilters(
			filters.map((filter) => {
				if (filter.name === 'size') return { ...filter, value: event.value.toString() };
				else return filter;
			})
		);
	};

	const handleColorChange = (event: PrDropdownType) => {
		setSelectedColor(event);

		setFilters(
			filters.map((filter) => {
				if (filter.name === 'color') return { ...filter, value: event.value.toString() };
				else return filter;
			})
		);
	};

	useEffect(() => {
		if (!paginator || !variants) return;

		const first = paginator.first;
		const count = paginator.itemCount || 0;
		const firstCount = first + count;

		setViewVariants(variants.filter((_vari, index) => index >= first && index < firstCount));
	}, [paginator]);

	useEffect(() => {
		if (!copiedVariants) return;

		const oldVariants = copiedVariants;

		const sizeName = filters.find((filter) => filter.name === 'size')?.value || undefined;
		const colorName = filters.find((filter) => filter.name === 'color')?.value || undefined;

		const query = { size: sizeName || undefined, color: colorName || undefined } as any;

		const trimQuery = Object.entries(query)
			.filter(([key, value]) => value !== undefined)
			.reduce((obj: any, [key, value]) => {
				obj[key] = value;
				return obj;
			}, {});

		const newVariants = oldVariants?.filter((item: any) => {
			for (let key in trimQuery) {
				if (item[key] === undefined || item[key] != trimQuery[key]) return false;
			}
			return true;
		});

		setVariants(newVariants);

		const first = paginator.first;
		const count = paginator.itemCount || 0;
		const firstCount = first + count;

		setViewVariants(newVariants.filter((_vari, index) => index >= first && index < firstCount));
	}, [filters]);

	useEffect(() => {
		if (!selectedProduct) return;

		setSelectedColor(undefined);
		setSelectedSize(undefined);
		setSizes([]);
		setColors([]);
		getVariants({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
		getUserPrices({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
	}, [selectedProduct]);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Seller Prices</h1>

				<div className="container-header-tools">
					<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />
				</div>
			</div>

			<div className="container-body p-3 z-1">
				<div className="w-full">
					<PrButton text={selectedProduct?.label.toString() || 'Select Product'} type="secondary" onClick={() => setVisible(true)} />
				</div>

				<hr />
				<div className={styles.filters}>
					{sizes.length > 0 && (
						<div className={styles.filter}>
							<span>Select Size</span>
							<PrDropdown options={sizes} value={selectedSize} onChange={handleSizeChange} filter disabled={loading} placeholder="Select Size" />
						</div>
					)}

					{colors.length > 0 && (
						<div className={styles.filter}>
							<span>Select Color</span>
							<PrDropdown options={colors} value={selectedColor} onChange={handleColorChange} filter disabled={loading} placeholder="Select Color" />
						</div>
					)}
				</div>

				<div className={styles.info}>You are viewing special prices defined in your account. Product prices are shown without including the plan features you have. If you have an active plan, you can view it in the product catalogue.</div>
			</div>

			{selectedProduct && (
				<div className="container-body p-0">
					<div className={styles.tableWrapper}>
						<div className="px-4">
							<div className={styles.tableHeader}>
								<div className={styles.tableHeaderTitles}>
									<span className={styles.tableHeaderTitlesName}>Variant Name</span>
									<span className={styles.tableHeaderTitlesPrintramPrice}>Printram Price</span>
									<div className={styles.tableHeaderTitlesSellerPrice}>Your Price</div>
								</div>
							</div>
						</div>
					</div>

					<div className={styles.tableBody}>
						{viewVariants.length > 0 ? (
							viewVariants.map((_vari, index) => (
								<div className={`${styles.tableBodyItemWrapper} ${userPrices.find((_upd) => _upd.productVariantId === _vari.id) && styles.selected}`} key={index}>
									<div className="px-4">
										<div className={styles.tableBodyWrapper}>
											<div className={styles.tableBodyItems}>
												<div className={styles.tableBodyItemName}>
													<span>{_vari.variant}</span>
												</div>
												<div className={styles.tableBodyItemPrintramPrice}>
													<span>{currencyHelper.formatPrice(_vari.printramPrice.formattedPricePerUnit)}</span>
												</div>
												<div className={styles.tableBodyItemSellerPrice}>
													<span>{sellerPriceHandler(_vari)}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<div className={`${styles.tableBodyItemWrapper} ${styles.loadMore}`}>
								<p>You dont have any product prices yet</p>
							</div>
						)}

						{!!paginationResponse && paginationResponse.totalItemCount > 0 && variants.length > 0 ? (
							<div className={`${styles.tableBodyItemWrapper} ${styles.loadMore}`}>
								<Paginator
									template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
									currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
									first={paginator?.first || 0}
									rows={paginator?.itemCount || 40}
									totalRecords={variants.length}
									rowsPerPageOptions={[20, 40, 60]}
									onPageChange={(event) => {
										setPaginator({ first: event.first, pageNumber: event.page, itemCount: event.rows, orderBy: 2 });
									}}
								/>
							</div>
						) : null}
					</div>
				</div>
			)}

			<SelectPrintramProductDialogNew
				visible={visible}
				setVisible={setVisible}
				handleProductClick={(_product) => {
					setSelectedProduct({ label: _product.productName, value: _product.id });
				}}
			/>

			{/* <SelectPrintramProductDialog
				visible={visible}
				setVisible={setVisible}
				onlySelectProduct
				handleProductClick={(product) => {
					if (!product) return;

					setSelectedProduct({ label: product.productName, value: product.id });
				}}
			/> */}

			<PrLoading loading={loading} />
		</div>
	);
};

export default SellerMyPrices;
