import countryService from 'features/countries/country.service';
import { GetCountriesRequest } from 'features/countries/get-countries/get-countries.request';
import { CountryBaseDto } from 'features/countries/get-countries/get-countries.response';
import { GetStatesRequest } from 'features/countries/get-states/get-states.request';
import { StateDto } from 'features/countries/get-states/get-states.response';
import { GetMyAccountRequest } from 'features/users/derived_features/user-profiles/get-my-account/get-my-account.request';
import { MyAccountDto } from 'features/users/derived_features/user-profiles/get-my-account/get-my-account.response';
import { UpdateMyAccountRequest } from 'features/users/derived_features/user-profiles/update-my-account/update-my-account.request';
import userProfileService from 'features/users/derived_features/user-profiles/user-profile.service';
import { ErrorMessage, FieldArray, Form, Formik, FormikContextType, useFormikContext } from 'formik';
import TextMaskInput from 'helpers/widgets/Forms/TextMaskInput';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrPrefixDropdownInput from 'helpers/widgets/Printram/Forms/Input/PrPrefixDropdownInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setReNewAuth } from 'redux/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Yup from 'yup';
import styles from './MyAccount.module.scss';

type props = { setVisible?: React.Dispatch<React.SetStateAction<boolean>> };

const MyAccount = ({ setVisible }: props) => {
	const auth = useAppSelector((state) => state.auth.data);
	const user = useAppSelector((state) => state.auth.data?.user);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [_, setAccountData] = useState<MyAccountDto | undefined>(undefined);
	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [countryOptions, setCountryOptions] = useState<PrDropdownType[]>();
	const [states, setStates] = useState<StateDto[]>([]);
	const [stateOptions, setStateOptions] = useState<PrDropdownType[]>([]);

	const inputStyle = { backgroundColor: 'white', borderWidth: 1, borderColor: '#bdbdbd', borderRadius: '0.6rem', background: 'none', height: 40, alignItems: 'center' };

	const initialValues = new UpdateMyAccountRequest({});

	const validationSchema = Yup.object().shape({
		contactInfo: Yup.object().shape({
			name: Yup.string().required('Name is required').min(2, 'Name must be at least 2 characters.').max(50, 'Name must be at most 50 characters.'),
			surname: Yup.string().required('Surname is required').min(2, 'Surname must be at least 2 characters.').max(50, 'Surname must be at most 50 characters.'),
			email: Yup.string().required('Email is required').email('Please enter a valid e-mail').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be at most 75 characters.')
		}),
		addresses: Yup.array().of(
			Yup.object().shape({
				countryId: Yup.number().moreThan(0, 'Country is required'),
				stateId: Yup.number().moreThan(0, 'State is required'),
				zipCode: Yup.string().required('Zip code is required'),
				city: Yup.string().required('City is required'),
				addressLine1: Yup.string().required('Address description is required')
			})
		)
	});

	const getStatesByCountry = async (countryId: number) => {
		try {
			setStateOptions([]);

			const response = await countryService.getStates(new GetStatesRequest(countryId));
			if (!response.isSuccess || !response.data) throw '';

			const options = response.data.map((_state) => ({ label: _state.name, value: _state.id }));

			if (!options) throw '';

			setStateOptions(options);
			setStates(response.data);
		} catch (error) {
			setStates([]);
		}
	};

	const getCountries = async () => {
		try {
			const response = await countryService.getCountries(new GetCountriesRequest());
			if (!response.isSuccess || !response.data) throw '';

			const options = response.data.filter((a) => a.name !== 'Turkiye' && a.name !== 'United States' && a.name !== 'Canada').map((_country) => ({ label: _country.name, value: _country.id }));

			options.unshift(...response.data.filter((a) => a.name === 'Turkiye' || a.name === 'United States' || a.name === 'Canada').reverse().map((_country) => ({ label: _country.name, value: _country.id })));

			if (!options) throw '';

			setCountryOptions(options);
			setCountries(response.data);
		} catch (error) {
			setCountries([]);
		}
	};

	const getAccountData = async () => {
		try {
			setLoading(true);
			if (!user || !user.userId) throw '';

			const response = await userProfileService.getMyAccount(new GetMyAccountRequest(user.userId));
			if (!response.isSuccess || !response.data) throw '';

			setAccountData(response.data);
			initialValues.initUpdateDataFromAccountData(user.userId, response.data);
			if (response.data.addresses.length > 0 && !!response.data.addresses[0].countryId) await getStatesByCountry(response.data.addresses[0].countryId);
		} catch (error) {
			setAccountData(undefined);
		} finally {
			setLoading(false);
		}
	};

	const countryChangeHandler = async (event: PrDropdownType, name: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
		try {
			setFieldValue(name, event.value);

			await getStatesByCountry(+event.value);
		} catch (error) {}
	};

	const handleSubmit = async (values: UpdateMyAccountRequest) => {
		try {
			setLoading(true);
			const request: UpdateMyAccountRequest = structuredClone(values);

			request.isForeignNation = true;
			request.contactInfo.phoneNumbers[0].isDefault = true;

			if (!request.corporateInfo?.businessName || request.corporateInfo.businessName.length < 3) request.corporateInfo = null;

			const response = await userProfileService.updateMyAccount(new UpdateMyAccountRequest({ ...request }));

			if (!response.isSuccess) throw '';

			dispatch(setReNewAuth({ ...auth, user: { ...auth?.user, phoneNumbers: request.contactInfo.phoneNumbers as any } as any } as any));

			if (user?.roles.isSeller) {
				!!setVisible ? setVisible(false) : navigate('/account/my-stores');
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const ScrollToError = () => {
		const { submitCount, isValid, errors } = useFormikContext() as FormikContextType<any>;

		useEffect(() => {
			if (isValid) return;

			const fieldErrorNames = !!errors?.corporateInfo ? 'corporateInfo.businessName' : !!errors?.contactInfo ? 'contactInfo.name' : 'addresses.countryId';
			if (fieldErrorNames.length <= 0) return;

			const element = document.querySelector(`input[name='${fieldErrorNames}']`);

			if (!element) return;

			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}, [submitCount]);

		return null;
	};

	useEffect(() => {
		getAccountData();
		getCountries();
	}, []);

	return (
		<div className="relative">
			<div className="container-header">
				<h1 className="container-header-title">My Account</h1>

				{!setVisible && (
					<div className={`container-header-tools ${styles.deviceControl}`}>
						<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />

						<Link to="/account/my-stores">
							<PrButton icon={<span className="pi pi-arrow-right" />} text="Go My Stores" />
						</Link>
					</div>
				)}
			</div>

			<section>
				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
					{({ values, handleChange, setFieldValue }) => (
						<Form>
							<div className="container-body p-3">
								<h4 className="m-0">Business name</h4>
								<p className="m-0">This information will appear on your invoices</p>

								<div className="mt-2">
									<PrTextInput label="Name of business" name="corporateInfo.businessName" value={values.corporateInfo?.businessName} required={false} onChange={handleChange} />
									<ErrorMessage name="corporateInfo.businessName" component="small" className="text-red font-medium" />
								</div>
							</div>
							<div className="container-body p-3 mt-3">
								<h4 className="m-0">Contact details</h4>
								<p className="m-0">This information will appear on your invoices</p>

								<div className={styles.contactDetailsInputs}>
									<div className={styles.nameSurname}>
										<div className={styles.contactDetailsInputsName}>
											<PrTextInput label="Name" name="contactInfo.name" value={values.contactInfo.name} required onChange={handleChange} />
											<ErrorMessage name="contactInfo.name" component="small" className="text-red font-medium" />
										</div>

										<div className={styles.contactDetailsInputsSurname}>
											<PrTextInput label="Surname" name="contactInfo.surname" value={values.contactInfo.surname} required onChange={handleChange} />
											<ErrorMessage name="contactInfo.surname" component="small" className="text-red font-medium" />
										</div>
									</div>

									<div className={styles.contactDetailsInputsEmail}>
										<PrTextInput label="Email" name="contactInfo.email" value={values.contactInfo.email} required onChange={handleChange} />
										<ErrorMessage name="contactInfo.email" component="small" className="text-red font-medium" />
									</div>

									<div className={styles.prefixPhone}>
										<div className={styles.contactDetailsInputsPrefix}>
											<div className="flex flex-row font-bold">
												<span className="pr-input-label-title">Code</span>
												<span className="pr-input-label-title text-red"> * </span>
											</div>
											<PrPrefixDropdownInput required name="contactInfo.phoneNumbers[0].code" value={values.contactInfo.phoneNumbers?.[0]?.code} onChange={handleChange} style={inputStyle} />
											{/* <DropdownInput label="Code *" name="contactInfo.phoneNumbers[0].code" options={prefixOptions} value={values.contactInfo.phoneNumbers?.[0]?.code} onChange={handleChange} placeholder="Select" /> */}
										</div>

										<div className={styles.contactDetailsInputsPhone}>
											<div className="flex flex-row font-bold">
												<span className="pr-input-label-title">Phone</span>
												<span className="pr-input-label-title text-red"> * </span>
											</div>
											<PrTextInput
												name="contactInfo.phoneNumbers[0].number"
												type="tel"
												placeholder="Please enter your phone number without country prefix"
												value={values.contactInfo.phoneNumbers?.[0]?.number}
												className="mt-1"
												onChange={(e) => {
													if (e.target.value.length > 14) return;

													if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

													handleChange(e);
												}}
												style={inputStyle}
											/>
										</div>
									</div>
								</div>
							</div>
							<FieldArray name="addresses">
								{({ remove, push }) => (
									<div className="container-body p-3 mt-3">
										<h4 className="m-0">Address</h4>
										<p className="m-0">This information will appear on your invoices</p>

										{!!values.addresses && values.addresses.length > 0
											? values.addresses.map((_address, _addressIndex) => (
													<div key={_addressIndex}>
														{/* {_addressIndex === 1 ? (
															<div className="mt-3 flex flex-row">
																<h5 className="flex-1">Address for product sample shipping</h5>

																<div className="text-right">
																	<i onClick={() => remove(_addressIndex)} tabIndex={0} className="pi pi-times cursor-pointer"></i>
																</div>
															</div>
														) : null} */}

														{_addressIndex === 0 ? (
															<div className={styles.addressInputs}>
																<div className={styles.countryState}>
																	<div className={styles.addressInputsCountry}>
																		<div className="flex flex-row font-bold mb-1">
																			<span className="pr-input-label-title">Country</span>
																			<span className="pr-input-label-title text-red"> * </span>
																		</div>
																		<PrDropdown filter options={countryOptions} value={countryOptions?.find((_country) => _country.value === _address.countryId)} onChange={(e) => countryChangeHandler(e, `addresses[${_addressIndex}].countryId`, setFieldValue)} placeholder="Choose a country" className="w-full" />
																	</div>

																	<div className={styles.addressInputsState}>
																		<div className="flex flex-row font-bold mb-1">
																			<span className="pr-input-label-title">State</span>
																			<span className="pr-input-label-title text-red"> * </span>
																		</div>
																		<PrDropdown filter key={stateOptions[0]?.value || 0} options={stateOptions} value={stateOptions?.find((_state) => _state.value === _address.stateId)} onChange={(e) => setFieldValue(`addresses[${_addressIndex}].stateId`, e.value)} placeholder="Choose a state" className="w-full" />
																	</div>
																</div>

																<div className={styles.cityZipcode}>
																	<div className={styles.addressInputsCity}>
																		<PrTextInput label="City" placeholder="City" name={`addresses[${_addressIndex}].city`} value={_address.city} required onChange={handleChange} />
																	</div>

																	<div className={styles.addressInputsZipcode}>
																		<PrTextInput label="Zip Code" placeholder="ZIP Code" name={`addresses[${_addressIndex}].zipCode`} value={_address.zipCode} required onChange={handleChange} />
																	</div>
																</div>

																<div className={styles.addressInputsAddress1}>
																	<PrTextInput label="Address 1" placeholder="Address 1" name={`addresses[${_addressIndex}].addressLine1`} value={_address.addressLine1} required onChange={handleChange} />
																</div>

																<div className={styles.addressInputsAddress2}>
																	<PrTextInput label="Address 2" placeholder="Address 2 (Optional)" name={`addresses[${_addressIndex}].addressLine2`} value={_address.addressLine2 || undefined} onChange={handleChange} />
																</div>

																{/* {values.addresses.length === 1 && (
																	<div>
																		<h6 onClick={() => push(new UpdateAccountAddressRequestDto({}))} tabIndex={0} className="cursor-pointer m-0">
																			+ Use a different address for receiving product samples
																		</h6>
																	</div>
																)} */}
															</div>
														) : null}
													</div>
											  ))
											: null}
									</div>
								)}
							</FieldArray>

							<div className={`container-body p-3 ${styles.buttons}`}>
								{setVisible ? (
									<div className="flex align-items-center mr-3">
										<span>Please fill in all required(*) fields to continue.</span>
									</div>
								) : null}
								<div>
									<PrButton btnType="submit" text="Submit" className="px-4" />
								</div>
							</div>

							<ScrollToError />
						</Form>
					)}
				</Formik>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default MyAccount;
