import { Size } from 'features/products/_common/get-core-product/get-core-product.response';

export class PrintArea {
	width: number;
	height: number;
	xCoordinate: number;
	yCoordinate: number;

	constructor(props: Partial<PrintArea>) {
		this.width = props.width || 0;
		this.height = props.height || 0;
		this.xCoordinate = props.xCoordinate || 0;
		this.yCoordinate = props.yCoordinate || 0;
	}
}

export class PrintDimensions {
	sizeId: string;
	minPrintSizeWidth?: number;
	maxPrintSizeWidth?: number;
	minPrintSizeHeight?: number;
	maxPrintSizeHeight?: number;
	defaultPrintSizeWidth?: number;
	defaultPrintSizeHeight?: number;

	constructor(props: Partial<PrintDimensions>) {
		this.sizeId = props.sizeId || '';
		this.minPrintSizeWidth = props.minPrintSizeWidth;
		this.maxPrintSizeWidth = props.maxPrintSizeWidth;
		this.minPrintSizeHeight = props.minPrintSizeHeight;
		this.maxPrintSizeHeight = props.maxPrintSizeHeight;
		this.defaultPrintSizeWidth = props.defaultPrintSizeWidth;
		this.defaultPrintSizeHeight = props.defaultPrintSizeHeight;
	}
}

export enum SideTypeEnum {
	Unknown = 0,
	Front = 1,
	Back = 2,
	Other = 3
}

export class CreateProductPrintSideRequest {
	id?: string;
	productId: string;
	name: string;
	amount?: number;
	maxWidth?: number;
	maxHeight?: number;
	mediaWidth?: number | null;
	mediaHeight?: number | null;
	order: number;
	templateMockupImage?: File;
	area: PrintArea | null;
	printDimensions: PrintDimensions[];
	type: SideTypeEnum;
	isActive: boolean;

	constructor(props: Partial<CreateProductPrintSideRequest>) {
		if (props.id) this.id = props.id;
		this.productId = props.productId || '';
		this.amount = props.amount;
		this.name = props.name || '';
		this.maxWidth = props.maxWidth;
		this.maxHeight = props.maxHeight;
		this.mediaWidth = props.mediaWidth;
		this.mediaHeight = props.mediaHeight;
		this.order = props.order || 0;
		this.templateMockupImage = props.templateMockupImage;
		this.area = props.area || null;
		this.printDimensions = props.printDimensions || [];
		this.type = props.type || SideTypeEnum.Other;
		this.isActive = props.isActive || false;
	}

	get getConvertUriExtension() {
		return '';
	}

	initSizes(sizes: Size[]) {
		for (const size of sizes) this.printDimensions.push(new PrintDimensions({ sizeId: size.id }));
	}
}
