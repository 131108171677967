import { CreateSetupIntentRequest } from '../models/requests/stripe/create-setup-intent-request';
import { GetSessionUrlRequest } from '../models/requests/stripe/get-session-url-request';
import { DataResponse } from '../models/_commons/responses/data-response';
import HttpBaseService from './_common/http-base-service';

class StripeService extends HttpBaseService {
	constructor() {
		super('stripe');
	}

	async getCustomerId(userId: string): Promise<DataResponse<string>> {
		return await this.getData('get-or-create-customer-id?userId=' + userId);
	}

	async getSessionUrl(request: GetSessionUrlRequest) {
		return await this.postData('get-session-url', request);
	}

	async getPaymentMethods(userId: string): Promise<DataResponse<any>> {
		return await this.getData('get-payment-methods?userId=' + userId);
	}

	async createSetupIntent(request: CreateSetupIntentRequest) {
		return await this.postData('create-setup-intent', request);
	}

	async deletePaymentMethod(paymentMethodId: string) {
		return await this.deleteData('delete-payment-method', paymentMethodId);
	}

	async isUserHaveAnyPaymentMethod(userId: string): Promise<DataResponse<boolean>> {
		return await this.getData('is-have-any-payment-method?UserId=' + userId);
	}
}

export default new StripeService();
