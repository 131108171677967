import { measurementHelper, measurementType } from 'helpers/measurement.helper';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectParams, ItemTemplateOptions } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { Tag } from 'primereact/tag';
import { useEffect, useState } from 'react';
import formDataHelper from '../../../../../../../helpers/form-data.helper';
import { DimensionComponent, UpdateModel } from '../../../../../../../models/requests/products/update-product-variants-request';

type props = {
	visible: boolean;
	setVisible: Function;
	measurementTypes: SelectItemOptionsType | undefined;
	editedVariations: UpdateModel[];
	setEditedVariations: Function;
	setSelectedVariations: Function;
	variations: UpdateModel[];
	setVariations: Function;
};

const EditVariations = ({ visible, setVisible, measurementTypes, editedVariations, setEditedVariations, setSelectedVariations, variations, setVariations }: props) => {
	const [selectedMeasurements, setSelectedMeasurements] = useState<DimensionComponent[]>(editedVariations[0].variantComponents.dimensionComponents);
    const dimensions = [800, 1200, 1600];

    const onMeasurementChange = (e: DropdownChangeParams) => {
        if (selectedMeasurements.find((item) => item.measurementTypeName === e.value)) return;

        const pushParam: DimensionComponent = { measurementTypeName: e.value, sizeName: editedVariations[0].variantComponents.dimensionComponents[0].sizeName, value: "0", measurementUnit: 1 };
        setSelectedMeasurements([...selectedMeasurements, pushParam]);
    };

	const onInputChange = (inputValue: string, measurementTypeName: string) => {
        setSelectedMeasurements(
            selectedMeasurements.map((item) => {
                if (item.measurementTypeName === measurementTypeName) {
                    return { ...item, value: inputValue };
                } else {
                    return item;
                }
            })
        );
    };

    const removeMeasurement = (measurementTypeName: string) => {
        setSelectedMeasurements(selectedMeasurements.filter((item) => item.measurementTypeName !== measurementTypeName));
    };

	const onPriceChange = (inputValue: number) => editedVariations.forEach((element: UpdateModel) => (element.variantSpecificData.pricePerUnit = parseFloat((inputValue * 100).toFixed(0))));

	const onParentSKUChange = (inputValue: string) => editedVariations.forEach((element: UpdateModel) => (element.variantSpecificData.parentSKU = inputValue));

	const onChildSKUChange = (inputValue: string) => editedVariations.forEach((element: UpdateModel) => (element.variantSpecificData.childSKU = inputValue));

    const handleSelect = (event: FileUploadSelectParams) => {
        const files: any = [];

        for (let index = 0; index < event.files.length; index++) files.push(event.files[index]);

        const formData = new FormData();

        formDataHelper.buildFormData(formData, files);

        editedVariations.forEach((element: UpdateModel) => (element.variantSpecificData.imageFiles = files));
	};

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center justify-content-between">
                    {options.chooseButton}

                    <small className="text-600">
                        The dimension of the images should be <b>800x800 / 1200x1200 / 1600x1600</b> px.
                    </small>
                </div>
            </div>
        );
    };

    const onTemplateRemove = (callback: Function, deletedFile?: File) => {
        if (deletedFile) {
            setEditedVariations(editedVariations.map((variation: UpdateModel) => ({ ...variation, variantSpecificData: { ...variation.variantSpecificData, imageFiles: variation.variantSpecificData.imageFiles.filter((img: File) => img.name !== deletedFile.name) } })));
        }
        callback();
    };

    const itemTemplate = (pFile: any, props: ItemTemplateOptions) => {
        const file: File = pFile;
        const fileReader = new FileReader();

        if (file.type === 'image/svg+xml') {
            onTemplateRemove(props.onRemove);
            return;
        }

        fileReader.onload = () => {
            const img = new Image();

            img.onload = () => {
                const dim = dimensions.find((dimension) => dimension === img.width);
                if (img.width !== img.height || !dim) {
                    onTemplateRemove(props.onRemove);
                    return;
                }
            };

            img.src = fileReader.result as string;
        };

        fileReader.readAsDataURL(file);

        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={pFile.objectURL} height={75} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(props.onRemove, file)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        const imgFiles = editedVariations.find((element: UpdateModel) => element.variantSpecificData?.imageFiles?.length > 0)?.variantSpecificData?.imageFiles || [];
        return <div className="p-2">{imgFiles?.length > 0 && imgFiles.map((image: any, index: number) => <img key={index} src={image.objectURL} alt="" height={50} />)}</div>;
    };

	const onSave = () => {
        setVariations(variations.map((item: UpdateModel) => (item = editedVariations.find((element: UpdateModel) => element.productVariantId === item.productVariantId) || item)));
        setSelectedVariations([]);
        setVisible(false);
    };

    useEffect(() => {
        if (!selectedMeasurements || editedVariations.length === 0) return;

        editedVariations?.forEach(
            (element: UpdateModel) =>
			(element.variantComponents.dimensionComponents = selectedMeasurements)
		);
    }, [selectedMeasurements, editedVariations]);

    return (
		<Dialog
			visible={visible}
			header="Edit Variations"
			onHide={() => {
				setEditedVariations([]);
				setVisible(false);
			}}>
			<div className="flex flex-column gap-1">
				{editedVariations.length == 1 && <span className="font-medium my-2">Variant you edited: </span>}
				<Dropdown options={measurementTypes} onChange={(e: DropdownChangeParams) => onMeasurementChange(e)} placeholder="Add measurement type" />
				<div className="flex flex-column align-items-end gap-2">
					{selectedMeasurements.length > 0 &&
						selectedMeasurements.map((item, index) => (
							<div key={index} className="w-full flex flex-column">
								<span>{item.measurementTypeName} ({measurementHelper.getMeasurementUnit(item.measurementUnit, item.measurementTypeName as measurementType)})</span>
								<span className="p-input-icon-right cursor-pointer">
									<i className="pi pi-times" onClick={(e) => removeMeasurement(item.measurementTypeName)} />
									<InputText placeholder="0" value={item.value || ''} onChange={(e: any) => onInputChange(e.target.value, item.measurementTypeName)} keyfilter="num" className="w-full" />
								</span>
							</div>
						))}
				</div>
				<hr />

				<span>Parent SKU</span>
				<InputText placeholder="Enter parent SKU (6303 etc.)" defaultValue={editedVariations[0].variantSpecificData.parentSKU || ''} onChange={(e: any) => onParentSKUChange(e.target.value)} />

				<span>Child SKU</span>
				<InputText placeholder="Enter child SKU (R, G, B etc.)" defaultValue={editedVariations[0].variantSpecificData.childSKU || ''} onChange={(e: any) => onChildSKUChange(e.target.value)} />

				<hr />

				<span>Price</span>
				<InputText placeholder="0" defaultValue={editedVariations[0].variantSpecificData.pricePerUnit / 100 || ''} onChange={(e: any) => onPriceChange(e.target.value.replace(',', '.'))} keyfilter="num" />

				<FileUpload name="image" accept="image/*" chooseLabel="Select variant images" emptyTemplate={emptyTemplate} headerTemplate={headerTemplate} itemTemplate={itemTemplate} onSelect={handleSelect} onBeforeDrop={() => false} className="my-4" />

				<Button label="Save Changes" onClick={() => onSave()} />
			</div>
		</Dialog>
	);
};

export default EditVariations;
