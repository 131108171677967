import { AxiosError } from 'axios';
import { CoreProductForListDto } from 'features/products/_common/dtos/core-product-for-list.dto';
import { CoreProductForStockDto } from 'features/products/_common/dtos/core-product-for-stock.dto';
import { GetApprovedCoreProductsForListRequest } from 'features/products/_common/get-approved-core-products-for-list/get-approved-core-products-for-list.request';
import { GetCoreProductsForStockPrintCenterRequest } from 'features/products/_common/get-core-products-for-stock-print-center/get-core-products-for-stock-print-center.request';
import productService from 'features/products/_common/product.service';
import { Price } from 'features/products/dtos/prices/price.dto';
import { EntryDto } from 'features/stocks/_common/dtos/entry.dto';
import { OutDto } from 'features/stocks/_common/dtos/out.dto';
import { PriceDto } from 'features/stocks/_common/dtos/price.dto';
import { CreateForPrintCenterRequest } from 'features/stocks/_common/product-variant-stocks/print-center/create/create-for-print-center.request';
import stockService from 'features/stocks/_common/stock.service';
import StockManagementTable from 'helpers/widgets/Stock/StockManagementTable';
import { DataResponse } from 'models/_commons/responses/data-response';
import { User } from 'models/dtos/auth/users/user';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

export interface ProfitError {
	maxCanBeEnteredPrice: Price;
	productVariantId: string;
}

const StockManagement = () => {
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const [loading, setLoading] = useState(false);
	const [productLoading, setProductLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [productOptions, setProductOptions] = useState<SelectItemOptionsType>();
	const [selectedProduct, setSelectedProduct] = useState<CoreProductForListDto>();
	const [product, setProduct] = useState<CoreProductForStockDto | null>();
	const [entries, setEntries] = useState<EntryDto[]>([]);
	const [outs, setOuts] = useState<OutDto[]>([]);
	const [prices, setPrices] = useState<PriceDto[]>([]);
	const [copiedProduct, setCopiedProduct] = useState<CoreProductForStockDto | null>();
	const [productSizes, setProductSizes] = useState<{ name: string; value: string }[]>([]);
	const [selectedSize, setSelectedSize] = useState<string | undefined>(undefined);
	const [precentagePrice, setPrecentagePrice] = useState<number | undefined>(undefined);
	const [profitErrors, setProfitErrors] = useState<ProfitError[]>([]);

	const getProducts = useCallback(async () => {
		try {
			setProductLoading(true);

			const request = new GetApprovedCoreProductsForListRequest();
			request.pagination = { pageNumber: 1, itemCount: null, orderBy: 2, first: 0 };

			const response = await productService.getApprovedCoreProductsForList(request);

			if (!response.isSuccess) throw '';

			const options = response.data && response?.data.map((prod) => ({ label: prod.productName + ' - ' + prod.brandName + ' - ' + prod.modelName, value: prod } as SelectItem));

			if (!options) return;

			setProductOptions(options);
		} finally {
			setProductLoading(false);
		}
	}, []);

	const getProductVariants = useCallback(async () => {
		try {
			if (!selectedProduct?.id) throw '';

			setLoading(true);

			const request = new GetCoreProductsForStockPrintCenterRequest(selectedProduct?.id, userId);

			const response = await productService.getCoreProductsForPrintCenterStock(request);

			if (!response.isSuccess) throw '';

			setProduct(response.data);

			const sizes = [...(response?.data?.sizeNames || [])]?.map((_size) => ({ name: _size, value: _size })) || [];

			setProductSizes([{ name: 'All', value: '' }, ...sizes]);
			setPrices(response.data?.variants.map((variant) => ({ productVariantId: variant.productVariantId, price: { formattedPricePerUnit: variant.price.formattedPricePerUnit.toFixed(2) as any, pricePerUnit: variant.price.pricePerUnit } })) || []);
		} catch {
			setProduct(null);
			setPrices([]);
			setProductSizes([]);
		} finally {
			setSelectedSize(undefined);
			setPrecentagePrice(undefined);
			setLoading(false);
		}
	}, [selectedProduct]);

	const onSave = async () => {
		try {
			setSaveLoading(true);

			let tempReq = { entries: entries.filter((entry) => entry.quantity !== 0), outs: outs.filter((out) => out.quantity !== 0) };

			const request = new CreateForPrintCenterRequest(userId, tempReq.entries, tempReq.outs, prices);

			const response = await stockService.createForPrintCenter(request);

			if (!response.isSuccess) throw '';

			getProductVariants();
			setEntries([]);
			setOuts([]);
			setProfitErrors([]);
		} catch (error: unknown | AxiosError) {
			if (!error) return;

			const errorResponse = error as AxiosError<DataResponse<{ profitError: { printramProfitErrors: ProfitError[] } }>>;
			const profitErrors = errorResponse.response?.data.data?.profitError.printramProfitErrors;
			setProfitErrors(profitErrors || []);
		} finally {
			setSelectedSize(undefined);
			setPrecentagePrice(undefined);
			setCopiedProduct(undefined);
			setSaveLoading(false);
		}
	};

	const onProductChange = (event: DropdownChangeParams) => {
		setSelectedProduct(event.value);
		setCopiedProduct(undefined);
	};

	const handleSizeChange = (event: DropdownChangeParams) => {
		if (!product) return;

		setSelectedSize(event.value);

		if (!copiedProduct) setCopiedProduct({ ...product });

		if ((!event.value || event.value === 'null' || event.value.length < 1) && !copiedProduct) return;

		if ((!event.value || event.value === 'null' || event.value.length < 1) && !!copiedProduct) {
			setProduct(copiedProduct);
			setCopiedProduct(undefined);
			return;
		}

		let newVariants = { ...(copiedProduct || product) }.variants.filter((_variant) => _variant.sizeName.toLowerCase().trim() === event.value.toLowerCase().trim());

		const newProduct = { ...(copiedProduct || product) };

		newProduct.variants = newVariants;

		setPrices(newProduct.variants.map((variant) => ({ productVariantId: variant.productVariantId, price: variant.price })) || []);

		setProduct(newProduct);
	};

	const onPercentageChangeClick = () => {
		if (!product) return;

		const newPrices =
			product.variants.map((variant) => {
				const _price = { productVariantId: variant.productVariantId, price: variant.price };

				if (!precentagePrice) return _price;

				const formattedPricePerUnit = (precentagePrice / 100) * variant.price.formattedPricePerUnit + variant.price.formattedPricePerUnit;
				const pricePerUnit = formattedPricePerUnit * 100;

				_price.price.formattedPricePerUnit = Number(formattedPricePerUnit.toFixed(2));
				_price.price.pricePerUnit = Math.ceil(Number(pricePerUnit.toFixed(2)));

				return _price;
			}) || [];

		setPrices(newPrices);
	};

	useEffect(() => {
		if (!selectedProduct) return;

		getProductVariants();
	}, [selectedProduct, getProductVariants]);

	useEffect(() => {
		getProducts();
	}, [getProducts]);

	return (
		<div className="card">
			<div className="flex justify-content-between mb-3">
				<h5>Stock Management</h5>
				<Dropdown value={selectedProduct} options={productOptions} disabled={productLoading} onChange={onProductChange} filter filterBy="label" placeholder="Select a Product" className="w-20rem" />
			</div>

			{!loading && productSizes.length > 0 ? (
				<React.Fragment>
					<hr />
					<div className="grid">
						<div className="col-2">
							<label htmlFor="sizes" className="block font-medium mb-1">
								Select Size
							</label>

							<Dropdown options={productSizes} inputId="sizes" value={selectedSize} onChange={handleSizeChange} optionValue="value" optionLabel="name" placeholder="Please select a size" className="w-full" />
						</div>

						{/* <div className="col-3">
							<label htmlFor="sizes" className="block font-medium mb-1">
								Price <small>(% percentage)</small>
							</label>

							<InputText type="number" disabled={!selectedSize} value={precentagePrice} onChange={(event) => setPrecentagePrice(+event.target.value)} placeholder="Update the price of the size as a percentage." className="w-full" />
						</div>

						<div className="col-2 align-self-end">
							<Button label="Apply" disabled={!precentagePrice} onClick={onPercentageChangeClick} icon="pi pi-save" className="mt-auto px-5" />
						</div> */}
					</div>
					<hr />
				</React.Fragment>
			) : null}

			<StockManagementTable product={product} profitErrors={profitErrors} onSave={onSave} saveLoading={saveLoading} loading={loading || saveLoading} entries={entries} setEntries={setEntries} outs={outs} setOuts={setOuts} prices={prices} setPrices={setPrices} />
		</div>
	);
};

export default StockManagement;
