import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrintOption } from '../../../models/dtos/auth/users/print-option';
import { UserPrintOptionCreateOrUpdateRequest } from '../../../models/requests/users/userPrintOptions/user-print-option-create-or-update-request';
import { DataResponse } from '../../../models/_commons/responses/data-response';
import userPrintOptionService from '../../../services/user-print-option-service';

const initialState: PrintOption | null = null;

export const getUserPrintOptionByUserId = createAsyncThunk('user-print-options/get-by-user', async (userId: string) => await userPrintOptionService.getByUserId(userId));
export const createOrUpdateUserPrintOption = createAsyncThunk('user-print-options/create-or-update', async (request: UserPrintOptionCreateOrUpdateRequest) => await userPrintOptionService.createOrUpdateOption(request));

export const userPrintOptionSlice = createSlice({
	name: 'user-print-option',
	initialState,
	reducers: { resetPrintOption: () => initialState },
	extraReducers: (builder) => {
		builder
			.addCase(getUserPrintOptionByUserId.fulfilled, (state: any, action: PayloadAction<DataResponse<PrintOption>>) => {
				if (!action.payload.data) return;

				state = action.payload.data as PrintOption;

				return state;
			})
			.addCase(createOrUpdateUserPrintOption.fulfilled, (state: any, action: PayloadAction<DataResponse<PrintOption>>) => {
				if (!action.payload.data) return;

				state = action.payload.data as PrintOption;

				return state;
			});
	}
});

export const { resetPrintOption } = userPrintOptionSlice.actions;
export default userPrintOptionSlice.reducer;
