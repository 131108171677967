export class GetSizesByProductRequest {
	private productId: string;

	constructor(productId: string) {
		this.productId = productId;
	}

	get getConvertUriExtension() {
		return `get-sizes-by-product/${this.productId}`;
	}
}
