import * as UUID from 'uuid';

export class ProductAttachmentRequestDto {
	uniqueId: string;
	header: string;
	file: File | null;

	constructor(props: Partial<ProductAttachmentRequestDto>) {
		this.uniqueId = UUID.v4();
		this.header = props.header || '';
		this.file = props.file || null;
	}
}

export class ProductAttachmentCreateRequest {
	productId: string;
	attachments: ProductAttachmentRequestDto[];

	constructor(props: Partial<ProductAttachmentCreateRequest>) {
		this.productId = props.productId || '';
		this.attachments = props.attachments || [];
	}

	get getConvertUriExtension() {
		return ``;
	}
}
