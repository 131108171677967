import HttpBaseService from 'services/_common/http-base-service';
import { GetSellersForAdminRequest } from './get-sellers-for-admin/get-sellers-for-admin.request';
import { GetSellersForAdminResponse } from './get-sellers-for-admin/get-sellers-for-admin.response';
import { UpdateSellerWithUserRequest } from './update-seller-with-user/update-seller-with-user.request';
import { UpdateSellerWithUserResponse } from './update-seller-with-user/update-seller-with-user.response';
import { UpdateStatuRequest } from './update-statu/update-statu.request';
import { UpdateStatuResponse } from './update-statu/update-statu.response';
import { SoftDeleteSellerRequest } from './soft-delete/soft-delete.request';
import { DataPaginationResponse } from 'models/_commons/responses/data-pagination-response';
import { GetAccountCompletePercentageRequest } from './get-account-complete-percentage/get-account-complete-percentage.request';
import { GetAccountCompletePercentageResponse } from './get-account-complete-percentage/get-account-complete-percentage.response';
import { GetSellerListRequest } from './get-seller-list/get-seller-list.request';
import { GetSellerListResponse } from './get-seller-list/get-seller-list.response';

class SellerService extends HttpBaseService {
	constructor() {
		super('sellers');
	}

	async getSellersForAdmin(request: GetSellersForAdminRequest): Promise<DataPaginationResponse<GetSellersForAdminResponse[]>> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getSellerList(request: GetSellerListRequest): Promise<GetSellerListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async updateSellerWithUser(request: UpdateSellerWithUserRequest): Promise<UpdateSellerWithUserResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async updateStatu(request: UpdateStatuRequest): Promise<UpdateStatuResponse> {
		return await this.patchData(request.getConvertUriExtension, request);
	}

	async delete(request: SoftDeleteSellerRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension, request.id);
	}

	async getAccountCompletePercentage(request: GetAccountCompletePercentageRequest): Promise<GetAccountCompletePercentageResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new SellerService();
