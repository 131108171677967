import { ProductVariantAndWeightsDto } from "../dtos/product-variant-and-weights.dto";

export class CreateOrUpdateMissingWeightDimensionsRequest {
	productVariantAndWeights: ProductVariantAndWeightsDto[] = [];

	get getConvertUriExtension() {
		return `create-or-update-missing-weight-dimensions`;
	}

	constructor(productVariantAndWeights: ProductVariantAndWeightsDto[]) {
		this.productVariantAndWeights = productVariantAndWeights;
	}
}