import { CreateManuelOrderDeliveryAddressRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import { PaymentTypes } from 'features/orders/dtos/payment-type.dto';
import { SyncEtsyProduct } from './sync-etsy-product.dto';


export class SyncOrderDeliveryAddressRequestDto extends CreateManuelOrderDeliveryAddressRequestDto {
	id: string;
	orderId: string;

	constructor(props: Partial<SyncOrderDeliveryAddressRequestDto>) {
		super({ ...props });

		this.id = props.id || '';
		this.orderId = props.orderId || '';
	}
}

export class ShipmentType {
	shippoShipmentId: string;
	shippoRateId: string;

	constructor(props: Partial<ShipmentType>) {
		this.shippoShipmentId = props.shippoShipmentId || '';
		this.shippoRateId = props.shippoRateId || '';
	}
}

export class CreateModelRequestDto {
	sellerId: string;
	orderId: string;
	products: SyncEtsyProduct[];
	sellerNote: string | null;
	customerNote: string | null;
	giftMessage: string | null;
	shipTo: SyncOrderDeliveryAddressRequestDto | null;
	paymentMethodType: PaymentTypes;
	shippingRate: ShippingRateDto | null;

	constructor(props: Partial<CreateModelRequestDto>) {
		this.sellerId = props.sellerId || '';
		this.orderId = props.orderId || '';
		this.products = props.products || [];
		this.sellerNote = props.sellerNote || null;
		this.customerNote = props.customerNote || null;
		this.giftMessage = props.giftMessage || null;
		this.shipTo = props.shipTo || null;
		this.paymentMethodType = props.paymentMethodType || 2;
		this.shippingRate = props.shippingRate || null;
	}
}
