import { AreaSelector, IArea } from '@bmunozg/react-image-area';
import { Size } from 'features/products/_common/get-core-product/get-core-product.response';
import { CreateProductPrintSideRequest, PrintArea } from 'features/products/derived-features/product-print-sides/create-product-print-side/create-product-print-side.request';
import { ProductPrintSide } from 'features/products/derived-features/product-print-sides/get-product-print-sides/get-product-print-sides.response';
import { productPrintSideService } from 'features/products/derived-features/product-print-sides/product-print-side.service';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import mediaHelper from 'helpers/media.helper';
import PrDropdown from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { printSideTypeOptions } from '../AddSides';
import { Checkbox } from 'primereact/checkbox';
import toastHelper from 'helpers/toast.helper';

type props = {
	isVisible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	side: ProductPrintSide | undefined;
	setSide: React.Dispatch<React.SetStateAction<ProductPrintSide | undefined>>;
	getAll: Function;
	productSides: ProductPrintSide[];
	productSizes: Size[];
};
const EditSide = ({ isVisible, setVisible, side, setSide, getAll, productSides, productSizes }: props) => {
	const formikRef = useRef<FormikProps<CreateProductPrintSideRequest>>(null);
	const [multiplier, setMultiplier] = useState<number>(5);

	const initialValues = new CreateProductPrintSideRequest({
		id: side?.id,
		productId: side?.productId,
		amount: side?.printPrice?.formattedPricePerUnit,
		name: side?.name,
		maxWidth: side?.maxWidth,
		maxHeight: side?.maxHeight,
		mediaWidth: side?.medias[0].mediaWidth,
		mediaHeight: side?.medias[0].mediaHeight,
		order: side?.order || productSides.length,
		type: side?.type,
		printDimensions: side?.printDimensions.map((_dimension) => ({
			sizeId: _dimension.size.id,
			minPrintSizeWidth: 2,
			minPrintSizeHeight: 2,
			maxPrintSizeWidth: _dimension.defaultPrintSizeWidth,
			maxPrintSizeHeight: _dimension.defaultPrintSizeHeight,
			defaultPrintSizeWidth: _dimension.defaultPrintSizeWidth,
			defaultPrintSizeHeight: _dimension.defaultPrintSizeHeight,
			name: _dimension.size.name
		})),
		isActive: side?.isActive
	});

	const [createLoading, setCreateLoading] = useState<boolean>(false);
	const imageRef = useRef<HTMLImageElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [areas, setAreas] = useState<IArea[]>([]);

	return (
		<Dialog
			header="Edit Side"
			visible={isVisible}
			onHide={() => {
				setAreas([]);
				setVisible(false);
				setSide(undefined);
				setMultiplier(5);
			}}>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Name is required'),
					amount: Yup.number().required('Price is required').min(1, 'Price must be greater than or equal to 1')
				})}
				onSubmit={async (values, formikHelpers) => {
					try {
						setCreateLoading(true);

						const clonedRequest = new CreateProductPrintSideRequest({
							...structuredClone(values),
							mediaWidth: imageRef.current ? +imageRef.current.getBoundingClientRect().width.toFixed(2) : null,
							mediaHeight: imageRef.current ? +imageRef.current.getBoundingClientRect().height.toFixed(2) : null,
							order: values.order || productSides.length,
							printDimensions: values.printDimensions.map((_dimension) => ({
								sizeId: _dimension.sizeId,
								minPrintSizeWidth: 2,
								minPrintSizeHeight: 2,
								maxPrintSizeWidth: _dimension.defaultPrintSizeWidth,
								maxPrintSizeHeight: _dimension.defaultPrintSizeHeight,
								defaultPrintSizeWidth: _dimension.defaultPrintSizeWidth,
								defaultPrintSizeHeight: _dimension.defaultPrintSizeHeight
							}))
						});

						clonedRequest.amount = Math.round((clonedRequest.amount || 0) * 100);
						if (values.templateMockupImage)
							clonedRequest.area = new PrintArea({
								width: +areas[0].width.toFixed(2),
								height: +areas[0].height.toFixed(2),
								xCoordinate: +areas[0].x.toFixed(2),
								yCoordinate: +areas[0].y.toFixed(2)
							});

						const response = await productPrintSideService.createOrUpdate(clonedRequest);
						if (!response.isSuccess) throw '';

						formikHelpers.resetForm();

						setAreas([]);
						setVisible(false);
						setSide(undefined);
						setMultiplier(5);

						await getAll();
					} catch (error) {
					} finally {
						setCreateLoading(false);
					}
				}}>
				{(form) => (
					<Form className="container-body p-3">
						<div className="flex flex-row justify-content-between mb-4">
							<div>
								<h4 className="m-0">Add New Side</h4>
								<p className="text-sm text-600">To add new side to the product, please fill out the form below.</p>
							</div>
						</div>

						<div className="flex gap-3 mb-3">
							<div className="flex-1 flex flex-column gap-1">
								<PrTextInput label="Name" value={form.values.name} onChange={form.handleChange} name="name" required placeholder="Enter side name" />
								<ErrorMessage name="name" component="small" className="text-red" />
							</div>

							<div className="flex-1 flex flex-column gap-1">
								<PrPriceInput label="Price" name="amount" required value={form.values.amount} onChange={(event) => form.setFieldValue('amount', event.value)} placeholder="Enter side price" />
								<ErrorMessage name="amount" component="small" className="text-red" />
							</div>

							<div className="flex-1 flex flex-column gap-1">
								<label className="block font-bold">Type *</label>
								<PrDropdown options={printSideTypeOptions} value={printSideTypeOptions.find((_option) => form.values.type === _option.value)} onChange={(event) => form.setFieldValue('type', event.value)} className="w-full mt-auto" />
							</div>
						</div>

						<div className="flex flex-column gap-3 mb-4">
							{form.values.printDimensions.map((_dimension, index) => (
								<div key={index} className="flex flex-wrap gap-2 pt-3 border-top-1 border-300">
									<div className="flex-1 flex gap-1 align-items-center justify-content-center uppercase font-bold">
										<span>{productSizes.find((_size) => _size.id === _dimension.sizeId)?.name}</span>
									</div>

									<div className="flex-1 flex-column gap-1">
										<PrTextInput label="Width" name={`printDimensions[${index}].defaultPrintSizeWidth`} required value={_dimension.defaultPrintSizeWidth} onChange={form.handleChange} placeholder="Printarea default width" />
										<ErrorMessage name={`printDimensions[${index}].defaultPrintSizeWidth`} component="small" className="text-red" />
									</div>

									<div className="flex-1 flex-column gap-1">
										<PrTextInput label="Height" name={`printDimensions[${index}].defaultPrintSizeHeight`} required value={_dimension.defaultPrintSizeHeight} onChange={form.handleChange} placeholder="Printarea default width" />
										<ErrorMessage name={`printDimensions[${index}].defaultPrintSizeHeight`} component="small" className="text-red" />
									</div>
								</div>
							))}
						</div>

						<div className="flex flex-column gap-3 py-4 border-top-1 border-300">
							<div>
								<input
									ref={fileInputRef}
									accept=".png, .webp"
									value={''}
									type="file"
									className="hidden"
									onChange={(e) => {
										if (!e.target.files || e.target.files.length < 1) return;

										const dimensions = form.values.printDimensions[0];

										if (!dimensions || !dimensions.defaultPrintSizeWidth || !dimensions.defaultPrintSizeHeight) {
											toastHelper.warning('Add Width / Height first');
											return;
										}

										setAreas([{ x: 0, y: 0, width: dimensions.defaultPrintSizeWidth * multiplier, height: dimensions.defaultPrintSizeHeight * multiplier, unit: 'px' }]);

										form.setFieldValue('templateMockupImage', e.target.files?.[0]);
									}}
								/>

								<PrButton btnType="button" type="secondary" onClick={() => fileInputRef.current?.click()} text="Browse Print Mockup File" icon={<span className="pi pi-fw pi-plus-circle" />} />
							</div>

							{!form.values.templateMockupImage && side?.medias[0].media && <img src={mediaHelper.getImage(side?.medias[0].media)} style={{ maxHeight: '400px', maxWidth: '400px' }} />}

							{!!form.values.templateMockupImage ? (
								<div className="flex gap-4">
									<AreaSelector
										areas={areas}
										onChange={(_areas) => {
											const currentArea = areas[0];
											const eventArea = _areas[0];

											if (currentArea.width === eventArea.width && currentArea.height === eventArea.height) setAreas(_areas);
										}}
										maxAreas={1}
										globalAreaStyle={{ backgroundColor: 'white', opacity: 0.5, border: '1.5px dashed' }}>
										<img ref={imageRef} src={URL.createObjectURL(form.values.templateMockupImage)} alt="Selected Mockup Side Image" className="block" style={{ maxHeight: '400px', maxWidth: '400px' }} />
									</AreaSelector>

									<div>
										<PrTextInput
											label="Multiplier"
											name="multiplier"
											required
											type="number"
											placeholder="Enter Multiplier"
											value={multiplier}
											onChange={(e) => {
												const value = +e.target.value;
												const dimensions = form.values.printDimensions[0];

												if (!dimensions || !dimensions.defaultPrintSizeWidth || !dimensions.defaultPrintSizeHeight) {
													toastHelper.warning('Add Width / Height first');
													return;
												}

												setMultiplier(value);

												setAreas((_areas) => {
													const clonedAreas = [..._areas];

													for (const clonedArea of clonedAreas) {
														clonedArea.width = dimensions.defaultPrintSizeWidth! * value;
														clonedArea.height = dimensions.defaultPrintSizeHeight! * value;
													}

													return clonedAreas;
												});
											}}
										/>

										<p className="mt-4 mb-2">
											Area Width = <b>{areas[0].width}px</b>
										</p>
										<p className="m-0">
											Area Height = <b>{areas[0].height}px</b>
										</p>
									</div>
								</div>
							) : null}
						</div>

						<hr />
						<div className="flex justify-content-between align-items-center gap-5">
							<div>
								<Checkbox name="isActive" inputId="isActiveEdit" checked={form.values.isActive} onChange={form.handleChange} />
								<label htmlFor="isActiveEdit" className="ml-2 font-bold">
									Is Side Active ?
								</label>
							</div>
							<div className="max-w-30rem">
								<PrTextInput label="Order No" name="order" required value={form.values.order} onChange={form.handleChange} placeholder="Order no" />
							</div>

							<PrButton btnType="submit" text="Save" disabled={createLoading} />
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default EditSide;
