import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { UpdateColorRequest } from '../../../../../models/requests/colors/update-color-request';
import { Color } from '../../../../../models/dtos/product/color';
import { ErrorMessage, Form, Formik } from 'formik';
import { ColorPicker } from 'primereact/colorpicker';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import colorService from '../../../../../services/color-service';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getColors: Function;
	editedColor: Color | undefined;
	setEditedColor: Function;
};

const ColorUpdate = ({ isVisible, setVisible, getColors, editedColor, setEditedColor }: Props) => {
	const [selectedColor, setSelectedColor] = useState(editedColor?.hexCode || '');

	const initialValues: UpdateColorRequest = {
		id: editedColor?.id || '',
		name: editedColor?.name || '',
		hexCode: editedColor?.hexCode || '',
		imageUrl: editedColor?.imageUrl || ''
	};

	const validationScheme = Yup.object().shape({
		name: Yup.string().required('Color name is Required').min(2, 'Name must be at least 2 characters.').max(100, 'Name must be a maximum of 100 characters.')
	});

	const submitForm = (values: UpdateColorRequest) => {
		if (selectedColor === '') return;

		selectedColor.charAt(0) !== '#' ? (values.hexCode = '#' + selectedColor) : (values.hexCode = selectedColor);
		colorService.update(values).then((response) => {
			if (!response.isSuccess) return;

			setEditedColor(undefined);
			setVisible(false);
			getColors();
		});
	};

	useEffect(() => {
		if (!editedColor?.hexCode) return;

		setSelectedColor(editedColor.hexCode);
	}, [editedColor]);

	return (
		<Dialog
			visible={isVisible}
			header="Update Color"
			modal
			onHide={() => {
				setVisible(false);
				setEditedColor(undefined);
			}}>
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div className="grid justify-content-center">
							<div className="col-12 lg:col-5">
								<ColorPicker value={selectedColor} onChange={(e: any) => setSelectedColor(e.value.toString())} inline className="flex align-items-center justify-content-center" />
							</div>

							<div className="col-12 lg:col-7 d-flex gap-4">
								<div className="col-12 mb-0 pb-0">
									<ErrorMessage name="hexCode" component="small" className="text-red font-medium" />
								</div>
								<div className="col-12">
									<InputText value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} placeholder="Enter hex code or select a color" className="w-full" />
								</div>

								<div className="col-12 mb-0 pb-0">
									<ErrorMessage name="name" component="small" className="text-red font-medium" />
								</div>
								<div className="col-12">
									<InputText name="name" value={values.name} onChange={handleChange} placeholder="Enter color name" className="w-full" />
								</div>

								<div className="col-12 mb-0 pb-0">
									<ErrorMessage name="imageUrl" component="small" className="text-red font-medium" />
								</div>
								<div className="col-12">
									<InputText name="imageUrl" value={values.imageUrl} onChange={handleChange} placeholder="Enter color image url" className="w-full" />
								</div>

								<div className="col-12">
									<Button type="submit" label="Update" className="w-full h-full" />
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ColorUpdate;
