import { CreateOrUpdateCorporateModelDto } from "./dtos/create-or-update-corporate-model.dto";
import { PrintCenterUpdateModelDto } from "./dtos/print-center-update-model.dto";
import { UserUpdateModelDto } from "./dtos/user-update-model.dto";


export class UpdatePrintCenterRequest {
	printCenterUpdateModel: PrintCenterUpdateModelDto = { userId: '', userStatuId: 0 };
	userUpdateModel: UserUpdateModelDto = { id: '', email: '', name: '', surname: '', phoneNumbers: [], isForeignNation: false };
	createOrUpdateCorporateModel: CreateOrUpdateCorporateModelDto = { userId: '', businessName: '', feinNumber: null };

	get getConvertUriExtension() {
		return `update`;
	}

	constructor(printCenterUpdateModel: PrintCenterUpdateModelDto, userUpdateModel: UserUpdateModelDto, createOrUpdateCorporateModel?: CreateOrUpdateCorporateModelDto) {
		this.printCenterUpdateModel = printCenterUpdateModel;
		this.userUpdateModel = userUpdateModel;
		if (createOrUpdateCorporateModel) this.createOrUpdateCorporateModel = createOrUpdateCorporateModel;
	}
}
