import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import React, { useEffect , useContext , useRef , useState } from 'react';
import { RTLContext } from '../App';
import { Tooltip } from 'primereact/tooltip';
import help from '../assets/icons/message-question.svg'
import documentForward from '../assets/icons/document-forward.svg'
import arrow from '../assets/icons/arrow-down.svg'

const AppInlineHelpMenu = (props: any) => {
    const menuKey = props.menuKey || 'inline-menu';
    const isRTL = useContext(RTLContext);
    const menuRef = useRef<HTMLUListElement>(null);
    const [menuStyle, setMenuStyle] = useState<React.CSSProperties>({});
    const [menuPositionChecked, setMenuPositionChecked] = useState(false);

    const inlineMenuClassName = classNames(
        'layout-inline-menu',
        {
            'layout-inline-menu-active': props.inlineMenuActive[menuKey]
        },
        props.className
    );

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const helpMenu = [
        {
            label: 'Free Mockups & Designs',
            command: () => {
                const aElement = document.createElement('a');
                aElement.href = 'https://drive.google.com/drive/folders/1UtDhe69NbsVuXyMZJAfZB2_ARVl80cnL';
                aElement.target = '_blank';
                aElement.click();
                aElement.remove();
            }
        },
        {
            label: 'FAQ - Printram (TR)',
            command: () => {
                const aElement = document.createElement('a');
                aElement.href = 'https://printram.blob.core.windows.net/important/FAQ-PRINTRAM.pdf';
                aElement.target = '_blank';
                aElement.click();
                aElement.remove();
            }
        },
        {
            label: 'FAQ - Etsy (TR)',
            command: () => {
                const aElement = document.createElement('a');
                aElement.href = 'https://printram.blob.core.windows.net/important/FAQ-ETSY.pdf';
                aElement.target = '_blank';
                aElement.click();
                aElement.remove();
            }
        },
        {
            label: 'How to open a store on ETSY? (TR)',
            command: () => {
                const aElement = document.createElement('a');
                aElement.href = 'https://printram.blob.core.windows.net/important/EtsyGuide.pdf';
                aElement.target = '_blank';
                aElement.click();
                aElement.remove();
            }
        }
    ];

    const adjustMenuPosition = () => {
        const menuElement = menuRef.current;
        if (menuElement) {
            const rect = menuElement.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.bottom > windowHeight) {
                const offset = rect.bottom - windowHeight;
                setMenuStyle({ bottom: `${offset / 2}px` });
            } else {
                setMenuStyle({ top: 'auto' });
            }
        }
    };

    useEffect(() => {
        if (props.inlineMenuActive[menuKey] && !menuPositionChecked) {
            adjustMenuPosition();
            setMenuPositionChecked(true);
        }
    }, [props.inlineMenuActive[menuKey], menuPositionChecked]);

    useEffect(() => {
        window.addEventListener('resize', adjustMenuPosition);

        return () => {
            window.removeEventListener('resize', adjustMenuPosition);
        };
    }, []);

    return (
        <div className={inlineMenuClassName} style={props.style}>
            {isSlim() && <Tooltip target=".avatarTooltip" />}

            <button className={classNames('layout-inline-menu-action p-link flex flex-row align-items-center', {'p-3 lg:p-0.5 py-3': props.horizontal, 'p-3': !props.horizontal})} onClick={(e) => props.onInlineMenuClick(e, menuKey)} style={{marginBottom: "8px"}}>
                <img src={help} alt=""/>
                <span className="layout-menuitem-text">Help</span>

                <img src={arrow} alt="" className={classNames('layout-inline-menu-icon pi pi-angle-down', { 'ml-auto': !isRTL,'mr-auto': isRTL })}/>
            </button>

            <CSSTransition classNames="slide-horizontal" timeout={{enter: 300, exit: 200}}
                           in={props.inlineMenuActive[menuKey]} unmountOnExit>
                <React.Fragment>
                    <ul ref={menuRef} className="layout-inline-menu-action-panel" style={menuStyle}>
                        {helpMenu.map((item, index) => (
                            <li key={index} onClick={item.command} className="layout-inline-menu-action-item tooltip" data-pr-tooltip={item.label}>
                                <button className="flex flex-row align-items-center p-link">
                                    <img src={documentForward} alt="" style={{marginRight: "10px"}}/>
                                    <span className="">{item.label}</span>
                                </button>
                            </li>
                        ))}
                    </ul>

                    {isSlim() && <Tooltip target=".tooltip" />}
                </React.Fragment>
            </CSSTransition>
        </div>
    );
};

export default AppInlineHelpMenu;