import { CreateManuelOrderRequest, DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import orderService from 'features/orders/_common/order.service';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React, { useEffect, useState } from 'react';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { CreateOrderContext, CreateOrderContextType } from '../../context/CreateOrderContextPrvider';
import { ManuelOrderProductsTable } from '../products/components/products-table/ManuelOrderProductsTable';
import { CostBreakdown } from '../shipping/components/breakdown/CostBreakdown';
import ShippingInfo from '../shipping/components/shipping-info/ShippingInfo';
import styles from './StepReviewOrder.module.scss';
import { Payments } from './components/payments/Payments';
import { confirmDialog } from 'primereact/confirmdialog';
import { BiError } from 'react-icons/bi';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

export const StepReviewOrder = () => {
	const navigate = useNavigate();
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);
	const steps = [
		{
			element: '.tutorial-selector1',
			intro: 'You can make a payment using your balance or a credit card.'
		}
	];

	const showFailDialog = (error: any) => {
		confirmDialog({
			header: 'Order can not be created.',
			icon: <BiError color="red" size={50} />,
			message: error?.response?.data?.message || '',
			acceptLabel: 'Ok',
			acceptClassName: 'px-4 bg-blue-600',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: () => {}
		});
	};

	const createOrder = async () => {
		try {
			context.setLoading(true);

			const request = new CreateManuelOrderRequest(structuredClone(context.request));

			if (request.deliveryType !== DeliveryTypes.PickUp) {
				if (!!request.deliveryAddress?.receiverPhoneNumber) request.deliveryAddress.receiverPhoneNumber = request.deliveryAddress?.phonePrefix.value + request.deliveryAddress?.receiverPhoneNumber;
				if (request.deliveryAddress?.receiverPhoneNumber === '') request.deliveryAddress.receiverPhoneNumber = null;
				if (!!request.attachmentFiles) request.attachmentFiles = null;
			} else request.deliveryAddress = null;

			const response = await orderService.createManuelOrder(request, (event) => {
				const percentage = Math.round((event.loaded * 100) / event.total);
				if (percentage !== 100) context.setLoadingText((_) => `File Processing: ${percentage}%`);
				else context.setLoadingText('');
			});
			if (!response.isSuccess) throw '';
			navigate('/product/seller/orders?status=3');
		} catch (error) {
			showFailDialog(error);
		} finally {
			context.setLoading(false);
		}
	};

	useEffect(() => {
		setTutorialStepsEnabled(true);
	}, []);

	return (
		<div className="relative">
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'review-order-intro', disableInteraction: false }} /> */}

			<div className="container-body p-4">
				<ManuelOrderProductsTable />
			</div>

			<div className="container-body p-4 mt-5">
				<ShippingInfo />
			</div>

			<div className="container-body p-4 mt-5">
				<div className="tutorial-selector1">
					<Payments />
				</div>
			</div>

			{context.calculatedPrice && (
				<div className="container-body p-4 mt-5">
					<CostBreakdown {...context.calculatedPrice} />

					<div className={styles.payButton}>
						<PrButton text="Pay securely now" onClick={createOrder} icon={<BsFillShieldLockFill />} btnType="button" />
						<div className={styles.payButtonDescription}>
							<div>By completing this order, </div>
							<div>I agree to Printram's Terms of Service.</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
