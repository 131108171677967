import HttpBaseService from 'services/_common/http-base-service';
import { ProductImagesCreateRequest } from './product-images-create/product-images-create.request';
import { ProductImagesCreateResponse } from './product-images-create/product-images-create.response';

class ProductImageService extends HttpBaseService {
	constructor() {
		super('product-images');
	}

	create(request: ProductImagesCreateRequest): Promise<ProductImagesCreateResponse> {
		return this.postData(request.getConvertUriExtension, request, true);
	}
}

export default new ProductImageService();
