import { ModelDto } from "../dtos/model.dto";

export class CreateRequest {
	merchantId: string;
	model: ModelDto;
	categoryIdentities: Array<string> | null;
	name: string;
	description: string;

	get getConvertUriExtension() {
		return `create`;
	}

	constructor(merchantId: string, model: ModelDto, categoryIdentities: Array<string> | null, name: string, description: string) {
		this.merchantId = merchantId;
		this.model = model;
		this.categoryIdentities = categoryIdentities;
		this.name = name;
		this.description = description;
	}
}