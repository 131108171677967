export class CreateOrUpdateUserPrintSideDimensionRequest {
	userId: string;
	sizeId: string;
	productPrintSideId: string;
	defaultPrintSizeWidth: number;
	defaultPrintSizeHeight: number;

	constructor(props: Partial<CreateOrUpdateUserPrintSideDimensionRequest>) {
		this.userId = props.userId || '';
		this.sizeId = props.sizeId || '';
		this.productPrintSideId = props.productPrintSideId || '';
		this.defaultPrintSizeWidth = props.defaultPrintSizeWidth || 0;
		this.defaultPrintSizeHeight = props.defaultPrintSizeHeight || 0;
	}

	get getConvertUriExtension() {
		return ``;
	}
}
