import { Product } from '../../models/dtos/product/product';
import { UpdateProductStatuRequest } from '../../models/requests/products/update-product-statu-request';
import { DataResponse } from '../../models/_commons/responses/data-response';
import { ResponseBase } from '../../models/_commons/responses/response-base';
import HttpBaseService from '../_common/http-base-service';

class ProductService extends HttpBaseService {
    constructor() {
        super('products');
    }

    async getById(productId: string): Promise<DataResponse<Product>> {
        return await this.getData(`get-by-id?ProductId=${productId}`);
    }

    async updateStatu(request: UpdateProductStatuRequest): Promise<ResponseBase> {
        return await this.patchData('update-statu',request)
    }
}

export default new ProductService();
