import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { GetCoreProductsForListRequest } from 'features/products/_common/get-core-products-for-list/get-core-products-for-list.request';
import productService from 'features/products/_common/product.service';
import { GetProductVariantsAndStocksRequest } from 'features/stockmanagement/_common/get-product-variants-and-stocks/get-product-variants-and-stocks.request';
import stockmanagementService from 'features/stockmanagement/_common/stockmanagement.service';
import { ProductVariantAndStockDto } from 'features/stockmanagement/dtos/product-variant-and-stock.dto';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { Pagination } from 'models/_commons/responses/pagination';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import React, { useEffect, useState } from 'react'
import styles from './StockManagement.module.scss'
import { Paginator } from 'primereact/paginator';
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface';
import { InputText } from 'primereact/inputtext';
import { EntryDto } from 'features/stocks/_common/dtos/entry.dto';
import { OutDto } from 'features/stocks/_common/dtos/out.dto';
import { GetProductOptionsRequest } from 'features/stockmanagement/_common/get-product-options/get-product-options.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { CreateForAdminRequest } from 'features/stocks/_common/product-variant-stocks/printram/create/create-for-admin.request';
import stockService from 'features/stocks/_common/stock.service';

const StockManagement = () => {
	const [loading, setLoading] = useState(false);
	const [productLoading, setProductLoading] = useState(false);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [productStocks, setProductStocks] = useState<ProductVariantAndStockDto[]>([])
	const [productOptions, setProductOptions] = useState<PrDropdownType[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<PrDropdownType>();
	const [sizeOptions, setSizeOptions] = useState<PrDropdownType[]>([]);
	const [colorOptions, setColorOptions] = useState<PrDropdownType[]>([]);
	const [locationOptions, setLocationOptions] = useState<PrDropdownType[]>([]);
	const [selectedSize, setSelectedSize] = useState<PrDropdownType>();
	const [selectedColor, setSelectedColor] = useState<PrDropdownType>();
	const [selectedLocation, setSelectedLocation] = useState<PrDropdownType>();
	const [entries, setEntries] = useState<EntryDto[]>([]);
	const [outs, setOuts] = useState<OutDto[]>([]);
	const [pagination, setPagination] = useState<PaginationDto>({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getProducts = async () => {
		try {
			setProductLoading(true);

			const request = new GetCoreProductsForListRequest({});
			request.pagination = { pageNumber: 1, itemCount: null, orderBy: 2, first: 0 };

			const response = await productService.getCoreProductsForList(request);

			if (!response.isSuccess) throw '';

			const options = response.data && response?.data.map((prod) => ({ label: prod.productName + ' - ' + prod.brandName + ' - ' + prod.modelName, value: prod.id }));

			if (!options) return;

			setProductOptions(options);
		} finally {
			setProductLoading(false);
		}
	};

	const getStocks = async (paginationReq: PaginationDto, useFilter: boolean) => {
		if (!selectedProduct) return;
		try {
			if (!paginationReq) throw '';

			setLoading(true)

			const request = new GetProductVariantsAndStocksRequest({
				pagination: paginationReq,
				productId: selectedProduct?.value.toString(),
				sizeId: useFilter ? selectedSize?.value.toString() : null,
				colorId: useFilter ? selectedColor?.value.toString() : null,
				stockLocationId: useFilter ? selectedLocation?.value.toString() : null
			})

			const response = await stockmanagementService.getProductVariantsAndStock(request)

			if (!response.isSuccess) throw ''

			setProductStocks(response.data || [])
			setPaginationResponse(response.pagination)
		} catch (error) {
			setProductStocks([])
		} finally {
			setLoading(false)
		}
	}

	const getProductOptions = async () => {
		if (!selectedProduct) return;

		try {
			setOptionsLoading(true);

			const request = new GetProductOptionsRequest(selectedProduct.value.toString())

			const response = await stockmanagementService.getProductOptions(request)

			if (!response.isSuccess || !response.data) throw ''

			const optionsSize = response.data.productAndSizes.map(_size => ({ label: _size.sizeName, value: _size.sizeId })) as PrDropdownType[]
			const optionsColor = response.data.productAndColors.map((_color) => ({ label: _color.colorName, value: _color.colorId })) as PrDropdownType[];
			const optionsLocation = response.data.stockInformations.map((_loc) => ({ label: _loc.stockLocationName, value: _loc.stockLocationId })) as PrDropdownType[];

			setSizeOptions([{ label: 'All', value: '' }, ...optionsSize]);
			setColorOptions([{ label: 'All', value: '' }, ...optionsColor]);
			setLocationOptions([{ label: 'All', value: '' }, ...optionsLocation]);

		} catch (error) {
			setSizeOptions([]);
			setColorOptions([]);
			setLocationOptions([]);
		} finally {
			setOptionsLoading(false)
		}
	}

	const onSave = async () => {
		try {
			setSaveLoading(true);

			let tempReq = { entries: entries.filter((entry) => entry.quantity !== 0), outs: outs.filter((out) => out.quantity !== 0) };

			const request = new CreateForAdminRequest(tempReq.entries, tempReq.outs, []);

			const response = await stockService.createForAdmin(request);

			if (!response.isSuccess) throw '';

			getStocks(pagination, true);
			setEntries([]);
			setOuts([]);
		} finally {
			setSaveLoading(false);
		}
	};

	const variantNameHandler = (product: ProductVariantAndStockDto) => <span>{`${product.productName} / ${product.colorName} - ${product.sizeName}`}</span>

	const stockBodyTemplate = (rowData: ProductVariantAndStockDto) => {
		const stock = rowData?.stockQuantity || 0;
		const entryQuantity = entries.find((entry) => entry.stockId === rowData.stockId)?.quantity || 0;
		const outQuantity = outs.find((out) => out.stockId === rowData.stockId)?.quantity || 0;
		const calculatedStock = stock + entryQuantity - outQuantity;

		return <span className="flex align-items-center justify-content-center surface-50 h-3rem w-full border-round-lg text-400">{calculatedStock}</span>;
	};

	const onStockDecrease = (stockId: string, variantId: string, quantity: number) => {
		if (!variantId) return;

		if (outs.length > 0) {
			const index = outs.findIndex((stock) => stock.stockId === stockId);

			if (index === -1) {
				setOuts([...outs, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
				return;
			} else {
				setOuts(outs.map((stock, ind) => (ind === index ? { stockId: stockId, productVariantId: variantId, quantity: quantity } : stock)));
				return;
			}
		}

		setOuts([...outs, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
	};

	const decreaseBodyTemplate = (rowData: ProductVariantAndStockDto) => (
		<div className={`p-inputgroup w-7rem`}>
			<span className="p-inputgroup-addon">
				<i className="pi pi-minus" />
			</span>
			<InputText value={outs.find((out) => out.stockId === rowData.stockId)?.quantity || 0} keyfilter="int" onChange={(e) => onStockDecrease(rowData.stockId, rowData.productVariantId, parseInt(e.target.value) || 0)} />
		</div>
	);

	const onStockIncrease = (stockId: string, variantId: string, quantity: number) => {
		if (!variantId) return;

		if (entries.length > 0) {
			const index = entries.findIndex((stock) => stock.stockId === stockId);

			if (index === -1) {
				setEntries([...entries, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
				return;
			} else {
				setEntries(entries.map((stock, ind) => (ind === index ? { stockId: stockId, productVariantId: variantId, quantity: quantity } : stock)));
				return;
			}
		}

		setEntries([...entries, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
	};

	const clearFilters = () => {
		setSelectedColor(undefined)
		setSelectedSize(undefined);
		setSelectedLocation(undefined)
		getStocks(pagination, false);
	}

	const increaseBodyTemplate = (rowData: ProductVariantAndStockDto) => (
		<div className={`p-inputgroup w-7rem`}>
			<span className="p-inputgroup-addon">
				<i className="pi pi-plus" />
			</span>
			<InputText value={entries.find((entry) => entry.stockId === rowData.stockId)?.quantity || 0} keyfilter="int" onChange={(e) => onStockIncrease(rowData.stockId, rowData.productVariantId, parseInt(e.target.value) || 0)} />
		</div>
	);

	useEffect(() => {
		if (!selectedProduct || !pagination) return;

		setSelectedColor(undefined)
		setSelectedSize(undefined)
		setSelectedLocation(undefined)
		getStocks(pagination, false)
		getProductOptions()
	}, [selectedProduct])

	useEffect(() => {
		if (!pagination) return;

		getStocks(pagination, true);
	}, [pagination]);

	useEffect(() => {
		getProducts()
	},[])

  return (
		<main className="container relative">
			<section className="container-header">
				<h1 className="container-header-title">Stock Management</h1>
			</section>

			<section className={styles.stockMainBody}>
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the stock page from the options below.</p>

				<PrDropdown options={productOptions} value={selectedProduct} onChange={(e) => setSelectedProduct(e)} disabled={productLoading} filter placeholder="Select a product" />
			</section>

			<section className="container-body">
				<div className={styles.actions}>
					<div className={styles.filters}>
						<PrDropdown options={colorOptions} value={selectedColor} onChange={(e) => setSelectedColor(e)} disabled={optionsLoading} placeholder="Select a color" />
						<PrDropdown options={sizeOptions} value={selectedSize} onChange={(e) => setSelectedSize(e)} disabled={optionsLoading} placeholder="Select a size" />
						<PrDropdown options={locationOptions} value={selectedLocation} onChange={(e) => setSelectedLocation(e)} disabled={optionsLoading} placeholder="Select a location" />
						<PrButton text="Apply Filters" onClick={() => getStocks(pagination, true)} loading={optionsLoading} />
						<PrButton onClick={clearFilters} loading={optionsLoading} type="secondary" icon={<span className="pi pi-filter-slash" />} />
					</div>
					{entries.length > 0 || outs.length > 0 ? <PrButton text="Save Stocks" onClick={onSave} loading={saveLoading} /> : null}
				</div>

				<div className={styles.stocksTableWrapper}>
					<div className={styles.stocksTableHeader}>
						<div className={styles.stocksTableHeaderTitles}>
							<span className={styles.stocksTableHeaderTitlesVariant}>Product Variant</span>
							<span className={styles.stocksTableHeaderTitlesLocation}>Location</span>
							<span className={styles.stocksTableHeaderTitlesDecrease} />
							<span className={styles.stocksTableHeaderTitlesStock}>Stock</span>
							<span className={styles.stocksTableHeaderTitlesIncrease} />
						</div>
					</div>
				</div>

				<div className={styles.stocksTableBody}>
					{productStocks.length > 0 ? (
						productStocks.map((_product, index) => (
							<div key={index} className={styles.stocksTableBodyItemWrapper}>
								<div className="px-4">
									<div className={styles.stocksTableBodyWrapper}>
										<div className={styles.stocksTableBodyItems}>
											<div className={styles.stocksTableBodyItemVariant}>{variantNameHandler(_product)}</div>
											<div className={styles.stocksTableBodyItemLocation}>
												<span>{_product.stockLocationName}</span>
											</div>
											<div className={styles.stocksTableBodyItemDecrease}>{decreaseBodyTemplate(_product)}</div>
											<div className={styles.stocksTableBodyItemStock}>{stockBodyTemplate(_product)}</div>
											<div className={styles.stocksTableBodyItemIncrease}>{increaseBodyTemplate(_product)}</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div className={`${styles.stocksTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any product stock yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && productStocks.length > 0 ? (
						<div className={`${styles.productStocksTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={pagination.first}
								rows={pagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30]}
								onPageChange={(event) => {
									setPagination({
										first: event.first,
										itemCount: event.rows,
										pageNumber: event.page + 1,
										orderBy: PaginationOrderByEnum.Descending
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
  );
}

export default StockManagement