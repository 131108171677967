import { useState } from 'react';
import StepFour from './components/StepFour';
import StepOne from './components/StepOne';
import StepThree from './components/StepThree';
import StepTwo from './components/StepTwo';
import { SplitButton } from 'primereact/splitbutton';
import StepFive from './components/StepFive';
import StepSix from './components/StepSix';

const ProductEdit = () => {
	const [step, setStep] = useState<number>(0);

	const prevItems = [
		{
			label: 'Step 1',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(0);
			},
			disabled: step <= 0
		},
		{
			label: 'Step 2',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(1);
			},
			disabled: step <= 1
		},
		{
			label: 'Step 3',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(2);
			},
			disabled: step <= 2
		},
		{
			label: 'Step 4',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(3);
			},
			disabled: step <= 3
		},
		{
			label: 'Step 5',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(4);
			},
			disabled: step <= 4
		},
		{
			label: 'Step 6',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(5);
			},
			disabled: step <= 5
		}
	];

	const nextItems = [
		{
			label: 'Step 1',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(0);
			},
			disabled: step >= 0
		},
		{
			label: 'Step 2',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(1);
			},
			disabled: step >= 1
		},
		{
			label: 'Step 3',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(2);
			},
			disabled: step >= 2
		},
		{
			label: 'Step 4',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(3);
			},
			disabled: step >= 3
		},
		{
			label: 'Step 5',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(4);
			},
			disabled: step >= 4
		},
		{
			label: 'Step 6',
			icon: 'pi pi-circle',
			command: (_: any) => {
				setStep(5);
			},
			disabled: step >= 5
		}
	];

	return (
		<div className="card">
			<div className="grid mb-3">
				<div className="col-12 lg:col-8">
					<div className="grid">
						<div className="col-12 lg:col-6 align-self-center">
							<h5 className="m-0">Edit Product, Step {step + 1}</h5>
						</div>

						<div className="col-12 lg:col-6 text-right gap-2">
							<SplitButton model={prevItems} onClick={(_) => setStep((current) => --current)} disabled={step === 0} icon="pi pi-arrow-left" label="Prev" className="p-button-outlined mr-3 w-full lg:w-auto" />
							<SplitButton model={nextItems} onClick={(_) => setStep((current) => ++current)} disabled={step === 5} icon="pi pi-arrow-right" label="Next" className="p-button-outlined w-full lg:w-auto mt-3 lg:mt-0" />
						</div>
					</div>
				</div>
			</div>

			{step === 0 && <StepOne setStep={setStep} step={step} />}
			{step === 1 && <StepFive setStep={setStep} />}
			{step === 2 && <StepTwo setStep={setStep} step={step} />}
			{step === 3 && <StepThree setStep={setStep} step={step} />}
			{step === 4 && <StepSix setStep={setStep} />}
			{step === 5 && <StepFour setStep={setStep} step={step} />}
		</div>
	);
};

export default ProductEdit;
