import { CreateDtfListWithOrdersRequest } from 'features/dtfs/_common/create-dtf-list-with-orders/create-dtf-list-with-orders.request';
import dtfService from 'features/dtfs/_common/dtf.service';
import { OrderForListNewDto } from 'features/orders/dtos/order-for-list-new.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

type props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	selectedOrders: OrderForListNewDto[];
};
const SendtoNewDtf = ({ visible, setVisible, selectedOrders }: props) => {
	const [dtfName, setDtfName] = useState<string>("")
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const send = async() => {
		try {
			if (selectedOrders.length === 0) throw '';

			setLoading(true)

			const selectedOrderIds = selectedOrders.map((_order) => _order.orderId);

			const request = new CreateDtfListWithOrdersRequest({ orderIds: selectedOrderIds, name: dtfName });

			const response = await dtfService.createDtfListWithOrders(request)

			if(!response.isSuccess) throw ''

			setVisible(false);
			//	navigate('/dtfs')
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}
  return (
		<Dialog header="Send Orders to DTF" visible={visible} onHide={() => setVisible(false)} className="max-w-30rem">
			<div>
				<span>
					<b>{selectedOrders.length}</b> orders will be send to dtf.
				</span>

				<PrTextInput placeholder="Dtf Name" value={dtfName} onChange={(e) => setDtfName(e.target.value)} className="my-2" />

				<div className='flex justify-content-end mt-2'>
					<PrButton text="Send" onClick={send} icon={<span className="pi pi-fw pi-send" />} loading={loading} />
				</div>
			</div>
		</Dialog>
  );
}

export default SendtoNewDtf