import countryService from 'features/countries/country.service';
import { GetTimezonesRequest } from 'features/countries/get-timezones/get-timezones.request';
import { NotificationSetting } from 'features/users/derived_features/user-profiles/get-my-settings/dtos/notification';
import { UserSettings } from 'features/users/derived_features/user-profiles/get-my-settings/dtos/settings';
import { Timezone } from 'features/users/derived_features/user-profiles/get-my-settings/dtos/timezone';
import { GetMySettingsRequest } from 'features/users/derived_features/user-profiles/get-my-settings/get-my-settings.request';
import { UpdateMySettingsRequest } from 'features/users/derived_features/user-profiles/update-my-settings/update-my-settings.request';
import userProfileService from 'features/users/derived_features/user-profiles/user-profile.service';
import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps } from 'formik';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { User } from 'models/dtos/auth/users/user';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { SellerOptionProductPublishStatus } from '../../../../../models/dtos/account/seller-store-option';

type SelectButtonType = {
	name: string;
	value: string | number;
};

const Settings = () => {
	const { userId, roles } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const formikRef = useRef<FormikProps<UpdateMySettingsRequest>>(null);

	const [mySettings, setMySettings] = useState<UserSettings | null>();
	const [timezoneOptions, setTimezoneOptions] = useState<PrDropdownType[] | undefined>([]);
	const [timezones, setTimezones] = useState<Timezone[]>([])
	const optionProductPublishStatuses: SelectButtonType[] = [
		{ name: SellerOptionProductPublishStatus[SellerOptionProductPublishStatus.Draft], value: SellerOptionProductPublishStatus.Draft },
		{ name: SellerOptionProductPublishStatus[SellerOptionProductPublishStatus.Publish], value: SellerOptionProductPublishStatus.Publish }
	];
	const [loading, setLoading] = useState<boolean>(false)

	const initialValues: UpdateMySettingsRequest = new UpdateMySettingsRequest(
		userId,
		mySettings?.timezone || ({ id: 0, zoneName: '', tzName: '', gmtOffsetName: '' } as Timezone),
		mySettings?.notificationSettings || ([{ notificationSettingType: 1, description: '', value: false }] as NotificationSetting[]),
		roles.isSeller && !!mySettings?.sellerStoreOption ? mySettings.sellerStoreOption : null
	);

	const submitForm = async(values: UpdateMySettingsRequest) => {
		try {
			setLoading(true)

			const request = new UpdateMySettingsRequest(userId, values.timezone, values.notificationSettings, values.sellerStoreOption)

			const response = await userProfileService.updateMySettings(request)

			if (!response.isSuccess) throw ''

			getMySettings()
		} catch (error) {setLoading(false)} finally {}
	}

	const getMySettings = useCallback(async() => {
		try {
			const request = new GetMySettingsRequest(userId)

			const response = await userProfileService.getMySettings(request)

			if (!response.isSuccess) throw ''

			setMySettings(response.data);
			formikRef.current?.setFieldValue('timezone', response.data?.timezone);
		} catch (error) {

		} finally {
			setLoading(false)
		}

	}, [userId]);

	const getTimezones = useCallback(async() => {
		try {
			if (!userId) throw ''

			setLoading(true)

			const request = new GetTimezonesRequest()

			const response = await countryService.getTimezones(request)

			if (!response.isSuccess) throw ''

			const options = response.data?.map((timezone) => ({ label: timezone.zoneName + ' ' + timezone.gmtOffsetName, value: timezone.id } as PrDropdownType));
			setTimezoneOptions(options);
			setTimezones(response.data || [])
			getMySettings();
		} catch (error) {

		} finally {

		}
	}, [userId, getMySettings]);

	useEffect(() => {
		getTimezones();
	}, [getTimezones]);

	return (
		<div className="relative">
			<div className="container-header">
				<h1 className="container-header-title">Settings</h1>
			</div>

			<div className="container-body p-3">
				{!!mySettings ? (
					<>
						<h5>Timezone</h5>
						<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={submitForm}>
							{({ values, setFieldValue }) => (
								<Form>
									<PrDropdown
										options={timezoneOptions}
										value={{ label: values.timezone.zoneName, value: values.timezone.id }}
										onChange={(event) =>
											setFieldValue(
												'timezone',
												timezones.find((_zone) => _zone.id === event.value)
											)
										}
										placeholder="Select timezone"
										className="w-full"
									/>

									<h5>Notifications</h5>

									<FieldArray
										name="notificationSettings"
										render={(arrayHelpers: FieldArrayRenderProps) => (
											<>
												{values.notificationSettings.map((notification, index) => (
													<div key={index}>
														<div className="field-checkbox">
															<Checkbox name={`values.notificationSettings[${index}]`} value={values.notificationSettings[index].value} checked={values.notificationSettings[index].value} onChange={(event) => arrayHelpers.replace(index, { ...values.notificationSettings[index], value: event.checked })} />
															<label>{values.notificationSettings[index].description}</label>
														</div>
													</div>
												))}
											</>
										)}
									/>

									{roles.isSeller ? (
										<>
											<h5>Store Options</h5>
											<div className="field-checkbox select-none">
												<Checkbox inputId="acknowledgeTwoHours" name="values.sellerStoreOption.acknowledgeTwoHours" value={values.sellerStoreOption?.acknowledgeTwoHours} checked={values.sellerStoreOption?.acknowledgeTwoHours} onChange={(event) => setFieldValue('sellerStoreOption.acknowledgeTwoHours', event.checked)} />
												<label htmlFor="acknowledgeTwoHours">
													<div>Acknowledge Two Hours</div>
													<div className='text-sm text-gray-700 mt-1'>If this option is selected, orders will be automatically sent to print 2 hours after they are received.</div>
												</label>
											</div>
											<div className="">
												<h6>Product Publish Status</h6>
												<SelectButton value={values.sellerStoreOption?.sellerOptionProductPublishStatus} options={optionProductPublishStatuses} onChange={(event) => setFieldValue('sellerStoreOption.sellerOptionProductPublishStatus', event.value)} optionLabel="name" optionValue="value" />
											</div>
										</>
									) : null}

									<div className="flex justify-content-end">
										<PrButton btnType="submit" text="Update" className="px-8" />
									</div>
								</Form>
							)}
						</Formik>
					</>
				) : (
					<></>
				)}
			</div>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Settings;
