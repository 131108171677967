import { DataResponse } from 'models/_commons/responses/data-response';
import HttpBaseService from 'services/_common/http-base-service';
import { GetProductForDetailByIdRequest } from './get-product-for-detail-by-id/get-product-for-detail-by-id.request';
import { GetProductForDetailByIdResponse } from './get-product-for-detail-by-id/get-product-for-detail-by-id.response';
import { GetCoreProductsForListRequest } from './get-core-products-for-list/get-core-products-for-list.request';
import { GetCoreProductsForListResponse } from './get-core-products-for-list/get-core-products-for-list.response';
import { GetApprovedCoreProductsForListRequest } from './get-approved-core-products-for-list/get-approved-core-products-for-list.request';
import { GetApprovedCoreProductsForListResponse } from './get-approved-core-products-for-list/get-approved-core-products-for-list.response';
import { SearchProductForImportRequest } from './search-product-for-import/search-product-for-import.request';
import { SearchProductForImportResponse } from './search-product-for-import/search-product-for-import.response';
import { ImportProductRequest } from './import-product/import-product.request';
import { ImportProductResponse } from './import-product/import-product.response';
import { GetCoreProductsForStockPrintCenterRequest } from './get-core-products-for-stock-print-center/get-core-products-for-stock-print-center.request';
import { GetCoreProductsForStockPrintCenterResponse } from './get-core-products-for-stock-print-center/get-core-products-for-stock-print-center.response';
import { GetCoreProductsForStockPrintramRequest } from './get-core-products-for-stock-printram/get-core-products-for-stock-print-center.request';
import { GetCoreProductsForStockPrintramResponse } from './get-core-products-for-stock-printram/get-core-products-for-stock-print-center.response';
import { GetCoreProductRequest } from './get-core-product/get-core-product.request';
import { GetCoreProductResponse } from './get-core-product/get-core-product.response';
import { GetCoreProductForSynchronizeRequest } from './get-core-product-for-synchronize/get-core-product-for-synchronize.request';
import { GetCoreProductForSynchronizeRsponse } from './get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import { GetCoreProductListSummariesRequest } from './get-core-product-list-summaries/get-core-product-list-summaries.request';
import { GetCoreProductListSummariesResponse } from './get-core-product-list-summaries/get-core-product-list-summaries.response';
import { GetProductsRequest } from './boost/get-products/get-products.request';
import { GetProductsResponse } from './boost/get-products/get-products.response';
import { GetProductFiltersRequest } from './boost/get-product-filters/get-product-filters.request';
import { GetProductFiltersResponse } from './boost/get-product-filters/get-product-filters.response';
import { GetProductInformationsRequest } from './boost/get-product-informations/get-product-informations.request';
import { GetProductInformationsResponse } from './boost/get-product-informations/get-product-informations.response';
import { GetCoreProductDetailRequest } from './boost/get-core-product-detail/get-core-product-detail.request';
import { GetCoreProductDetailResponse } from './boost/get-core-product-detail/get-core-product-detail.response';

class ProductService extends HttpBaseService {
	constructor() {
		super('products');
	}

	async getById(request: GetProductForDetailByIdRequest): Promise<GetProductForDetailByIdResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getCoreProduct(request: GetCoreProductRequest): Promise<GetCoreProductResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getCoreProductForSync(request: GetCoreProductForSynchronizeRequest): Promise<GetCoreProductForSynchronizeRsponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getCoreProductsForList(request: GetCoreProductsForListRequest): Promise<GetCoreProductsForListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getCoreProductsForPrintCenterStock(request: GetCoreProductsForStockPrintCenterRequest): Promise<GetCoreProductsForStockPrintCenterResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getCoreProductsForPrintramStock(request: GetCoreProductsForStockPrintramRequest): Promise<GetCoreProductsForStockPrintramResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getCoreProductListSummaries(request: GetCoreProductListSummariesRequest): Promise<GetCoreProductListSummariesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getApprovedCoreProductsForList(request: GetApprovedCoreProductsForListRequest): Promise<GetApprovedCoreProductsForListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async searchProductForImport(request: SearchProductForImportRequest): Promise<SearchProductForImportResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async importProduct(request: ImportProductRequest): Promise<ImportProductResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getProducts(request: GetProductsRequest): Promise<GetProductsResponse> {
		return await this.getData(request.getConvertUriExtension, request);
	}

	async getProductFilters(request: GetProductFiltersRequest): Promise<GetProductFiltersResponse> {
		return await this.getData(request.getConvertUriExtension, request);
	}

	async getProductInformations(request: GetProductInformationsRequest): Promise<GetProductInformationsResponse> {
		return await this.getData(request.getConvertUriExtension, request);
	}

	async getCoreProductDetail(request: GetCoreProductDetailRequest): Promise<GetCoreProductDetailResponse> {
		return await this.getData(request.getConvertUriExtension, request);
	}
}

export default new ProductService();
