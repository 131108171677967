import { useEffect, useState } from 'react';
import AccountPlanCard from './components/AccountPlanCard';
import PrintramPro from './components/PrintramPro';
import styles from './Plans.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAppSelector } from 'redux/hooks';
import { User } from 'models/dtos/auth/users/user';
import stripeService from 'features/stripe/_common/stripe.service';
import { GetOrCreateCustomerIdRequest } from 'features/stripe/_common/get-or-create-customer-id/get-or-create-customer-id.request';
import userSubscriptionService from 'features/users/derived_features/user-subscriptions/user-subscription.service';
import { GetSubscriptionsRequest } from 'features/users/derived_features/user-subscriptions/get-subscriptions/get-subscriptions.request';
import { UserSubscriptionDto } from 'features/users/derived_features/user-subscriptions/dtos/UserSubscription.dto';
import plansService from 'features/plans/_common/plans.service';
import { PlanDto } from 'features/plans/_common/dtos/plan.dto';
import { GetPlansRequest } from 'features/plans/_common/get-plans/get-plans.request';
import PrBtnGroup from 'helpers/widgets/Printram/Forms/Buttons/BtnGroup/PrBtnGroup';

type PlanMode = 'month' | 'year';

const Plans = () => {
	const { userId, roles } = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const navigate = useNavigate();

	const [isFormVisible, setFormVisible] = useState(false);
	const [stripeCustomerId, setStripeCustomerId] = useState('');
	const [userSubscription, setUsersubscription] = useState<UserSubscriptionDto>();
	const [planOptions, setPlanOptions] = useState<PlanDto[] | []>([])
	const [activeMode, setActiveMode] = useState<PlanMode>('month');
	const [loading, setLoading] = useState(false)


	const fetchAll = async () => {
		if (!userId) return;

		try {
			setLoading(true);

			const customerRequest = new GetOrCreateCustomerIdRequest(userId)

			const getOrCreateCustomerResponse = await stripeService.getOrCreateCustomerId(customerRequest);

			if (!getOrCreateCustomerResponse.isSuccess || !getOrCreateCustomerResponse.data) throw '';

			setStripeCustomerId(getOrCreateCustomerResponse.data);

			const subsRequest = new GetSubscriptionsRequest(userId);

			const getSubscriptionsResponse = await userSubscriptionService.getSubscriptions(subsRequest);

			if (!getSubscriptionsResponse.isSuccess || !getSubscriptionsResponse.data) throw '';

			setUsersubscription(getSubscriptionsResponse.data);

			const plansRequest = new GetPlansRequest()

			const getPlansResponse = await plansService.getPlans(plansRequest);

			if (!getPlansResponse.isSuccess || !getPlansResponse.data) throw '';

			setPlanOptions(getPlansResponse.data);
		}
		catch (error: AxiosError | any) {
			// if (error instanceof AxiosError) {
			// 	if (!error.response?.config.url) return;

			// 	if (error.response.config.url.includes('get-or-create-customer-id')) navigate('/account/details')
			// }
		}
		finally { setLoading(false); }
	}

	useEffect(() => {
		fetchAll();
	}, [])

	return (
		<div className="relative">
			<div className="container-header">
				<h1 className="container-header-title">Plans</h1>

				<div className={`container-header-tools ${styles.deviceControl}`}>
					<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />

					<Link to="/account/payments">
						<PrButton icon={<span className="pi pi-arrow-right" />} text="Go Payments" />
					</Link>
				</div>
			</div>

			<section className="container-body p-3">
				<div className={styles.offer}>
					<span>
						We offer you a great <span className={styles.offerPrice}>price</span> plan to build your business
					</span>
				</div>

				<div className={`${styles.saveContainer} ${styles.desktop}`}>
					<PrBtnGroup
						firstText="Monthly"
						firstOnClik={() => {
							setActiveMode('month');
						}}
						secondText="Yearly"
						secondOnClik={() => {
							setActiveMode('year');
						}}
						isActive={activeMode === 'month' ? 'first' : 'second'}
					/>

					<strong>SAVE UP TO 30%</strong>

					<span className={styles.arrow}>
						<svg xmlns="http://www.w3.org/2000/svg" width="62" height="25" viewBox="0 0 62 25" fill="none">
							<path d="M0 14.5L2.07864 19.8863L5.70402 15.393L0 14.5ZM61.037 0.811352C58.4643 7.12607 50.4557 15.7799 39.9021 20.5339C29.3824 25.2727 16.3955 26.1115 3.79948 16.9239L3.21018 17.7318C16.153 27.1724 29.5344 26.301 40.3129 21.4457C51.0575 16.6056 59.2771 7.78133 61.963 1.18865L61.037 0.811352Z" fill="#0B2110" />
						</svg>
					</span>
				</div>

				{userSubscription && (
					<div className={styles.currentPlan}>
						<span className={styles.currentPlanTitle}>
							Your current plan is <span className={styles.currentPlanName}>{userSubscription.accountType || ""}</span>
						</span>
					</div>
				)}

				<div className={styles.main}>
					<div className={styles.cards}>
						{planOptions.map((plan, index) => (
							<AccountPlanCard key={index} {...plan} index={index} activeMode={activeMode} features={plan.planFeatures} userSubscription={userSubscription} setFormVisible={setFormVisible} />
						))}
					</div>
				</div>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}

			<PrintramPro isVisible={isFormVisible} setVisible={setFormVisible} stripeCustomerId={stripeCustomerId} />
		</div>
	);
};

export default Plans;
