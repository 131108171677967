import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { User } from '../../../../../../models/dtos/auth/users/user';
import { GetSessionUrlRequest } from '../../../../../../models/requests/stripe/get-session-url-request';
import { useAppSelector } from '../../../../../../redux/hooks';
import stripeService from '../../../../../../services/stripe-service';
import styles from './PrintramPro.module.scss';
import environment from 'helpers/constants/environment';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';

type props = { isVisible: boolean; setVisible: Function; stripeCustomerId: string; };

const PrintramPro = ({ isVisible, setVisible, stripeCustomerId }: props) => {
    const [selectedPaymentPeriod, setSelectedPaymentPeriod] = useState(0);
    const user = useAppSelector((state) => state.auth.data?.user || ({} as User));
    const paymentPeriods = [
        {
            name: 'Monthly',
            price: '$24.99 / Month',
            price_id: environment.stripePremiumMonthlyPriceId || '',
            description: 'Billed every month',
            value: 1
        },
        {
            name: 'Annualy',
            price: '$249.99 / Year',
            price_id: environment.stripePremiumYearlyPriceId || '',
            description: 'Billed every year',
            value: 2
        }
    ];

    const clickHandler = () => {
		if (selectedPaymentPeriod === 0) return;

        const request:GetSessionUrlRequest = {customerId: stripeCustomerId, userId: user.userId, priceId: paymentPeriods[selectedPaymentPeriod-1].price_id}

        stripeService.getSessionUrl(request).then(response => response.isSuccess && window.open(response.data,'_self'))
    };

    return (
        <Dialog visible={isVisible} header="Printram Premium" onHide={() => setVisible(false)} className={styles.printramProMain}>
            {paymentPeriods.map((period,index) => (
                <PeriodCard key={index} period={period} selectedPaymentPeriod={selectedPaymentPeriod} setSelectedPaymentPeriod={setSelectedPaymentPeriod} />
            ))}
            <PrButton btnType="button" text="Upgrade Now" onClick={() => clickHandler()} className="mx-auto px-5" />
        </Dialog>
    );
};

const PeriodCard = ({ period, selectedPaymentPeriod, setSelectedPaymentPeriod }: any) => {
    return (
        <div onClick={() => setSelectedPaymentPeriod(period.value)} className={styles.periodCardMain}>
            <div className={styles.select}>
                {period.value === selectedPaymentPeriod ? <i className="pi pi-fw pi-check-circle" /> : <i className="pi pi-fw pi-circle" />}
                <span className={styles.name}>{period.name}</span>
            </div>
            <div className={styles.priceContainer}>
                <span className={styles.price}>{period.price}</span>
                <span className={styles.description}>{period.description}</span>
            </div>
        </div>
    );
};

export default PrintramPro;
