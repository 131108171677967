import React from 'react';
import { UsaFlagIcon } from '../../../../orders/create-order/steps/shipping/components/icon/UsaFlagIcon';
import { DeliveryTypes } from '../../../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../../context/CustomDtfContextProvider';
import currencyHelper from '../../../../../../helpers/curreny.helper';

const CustomDtfShippingInfo = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;

	return (
		<div>
			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping
			</h2>

			<div className="flex gap-4">
				<div className="w-3">
					<h4 className="mb-4" style={{ fontSize: '1rem' }}>
						Shipping From
					</h4>

					<p>
						<UsaFlagIcon style={{ width: '1.5625rem', height: '0.8125rem' }} />
						<span className="ml-3">United States</span>
					</p>
				</div>

				<div className="w-3">
					<h4 className="mb-4" style={{ fontSize: '1rem' }}>
						Shipping to
						<span role="button" tabIndex={0} className="text-primary underline cursor-pointer ml-2 font-normal" onClick={() => context.setStep(1)}>
							Edit
						</span>
					</h4>

					<p className="mb-0">
						{context.deliveryAddress!.receiverFullName}{' '}
						{context.deliveryType === DeliveryTypes.PickUp ? (
							<>
								- <b>Pick Up</b>
							</>
						) : null}
					</p>

					{context.deliveryType === DeliveryTypes.Shipment && !!context.deliveryAddress ? (
						<>
							<p className="mb-0">{context.deliveryAddress?.firstLine}</p>

							{!!context.deliveryAddress?.secondLine ? <p className="mb-0">{context.deliveryAddress?.secondLine}</p> : null}

							<p className="mb-0">
								{context.deliveryAddress?.city}, {context.deliveryAddress?.state} {context.deliveryAddress?.zip} {context.deliveryAddress?.countryIso}
							</p>

							{!!context.deliveryAddress.receiverPhoneNumber ? (
								<p className="mb-0">
									{/*{context.deliveryAddress?.receiverPhoneNumber}*/}
									{context.deliveryAddress?.receiverPhoneNumber}
								</p>
							) : null}
						</>
					) : null}
				</div>

				{!!context.selectedRate && context.activeIndex === 3 && <div className="w-6">
					<React.Fragment>
						<h4 className="mb-4" style={{ fontSize: '1rem' }}>
							Shipping method
							<span
								role="button"
								tabIndex={0}
								className="text-primary underline cursor-pointer ml-2 font-normal"
								onClick={() => {
									context.prevStep();
								}}>
									Edit
								</span>
						</h4>

						<p>
							{currencyHelper.formatPrice(context.selectedRate!.amount)} - {context.selectedRate!.serviceName}
						</p>
					</React.Fragment>
				</div>}
			</div>
		</div>
	);
};

export default CustomDtfShippingInfo;
