import { useState, useEffect, useCallback } from 'react';
import modelService from '../../../../services/model-service';
import brandService from '../../../../services/brand-service';
import { Model } from '../../../../models/dtos/brand/model';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ModelCreate from './components/ModelCreate';
import { SelectItemOptionsType, SelectItem } from 'primereact/selectitem';
import ModelUpdate from './components/ModelUpdate';

const Models = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [models, setModels] = useState<Model[]>([]);
    const [visibleCreateModel, setVisibleCreateModel] = useState(false);
    const [visibleUpdateModel, setVisibleUpdateModel] = useState(false);
    const [editedModel, setEditedModel] = useState<Model | undefined>();
    const [brandsOptions, setBrandsOptions] = useState<SelectItemOptionsType | undefined>();

    const getModels = () => {
        return new Promise((resolve) => {
            modelService.getAll({ pageNumber: 1, orderBy: 1 }).then((response) => response.isSuccess && resolve(response.data || []));
        });
    };

    const getBrands = () => {
        return new Promise((resolve) => {
            brandService.getAll({ pageNumber: 1, orderBy: 1 }).then((response) => {
                if (!response.isSuccess) return;

                const options = response.data?.map((brand) => ({ label: brand.name, value: brand.id } as SelectItem));
                if (!options) return;

                resolve(options);
            });
        });
    };

    const listModels = useCallback(() => {
        setLoading(true);

        Promise.all([getModels(), getBrands()])
            .then((values: any) => {
                setModels(values[0]);
                setBrandsOptions(values[1]);
            })
            .finally(() => setLoading(false));
    }, []);

    const deleteModel = (id: string) => modelService.delete(id).then((response) => response && listModels());

    const handleEditBtnCliked = (model: Model) => {
        setEditedModel(model);
        setVisibleUpdateModel(true);
    };

    useEffect(() => {
        listModels();
    }, []);

    const actionsTemplate = (model: Model) => (
        <div className="flex gap-2 justify-content">
            <Button onClick={() => handleEditBtnCliked(model)} label="Edit" icon="pi pi-fw pi-file-edit" className="p-button-info" />
            <Button onClick={() => deleteModel(model.modelId)} label="Delete" icon="pi pi-fw pi-trash" className="p-button-danger" />
        </div>
    );

    return (
        <div className="card">
            <h5>Models</h5>

            <DataTable value={models} paginator rows={10} loading={loading} dataKey="id" className="p-datatable-gridlines">
                <Column field="modelId" header="Model Id" filter filterPlaceholder="Search by id" style={{ minWidth: '12rem' }} />
                <Column field="modelName" header="Model Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                <Column field="brandName" header="Brand Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                <Column field="actions" header="Actions" body={actionsTemplate} />
            </DataTable>

            <ModelCreate isVisible={visibleCreateModel} setVisible={setVisibleCreateModel} listModels={listModels} brandsOptions={brandsOptions} />
            <ModelUpdate isVisible={visibleUpdateModel} setVisible={setVisibleUpdateModel} listModels={listModels} brandsOptions={brandsOptions} editedModel={editedModel} setEditedModel={setEditedModel} />
        </div>
    );
};

export default Models;
