import { IPagination, PaginationOrderByEnum } from './pagination.interface';

export class PaginationDto implements IPagination {
	pageNumber: number = 1;
	itemCount: number | null = null;
	orderBy: PaginationOrderByEnum = PaginationOrderByEnum.Descending;
	first: number = 0;

	constructor(pageNumber?: number, itemCount?: number, orderBy?: number, first?: number) {
		this.pageNumber = pageNumber || 1;
		this.itemCount = itemCount || null;
		this.orderBy = orderBy || PaginationOrderByEnum.Descending;
		this.first = first || 0;
	}
}
