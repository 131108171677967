export class PlanDeleteRequest {
	id: string = '';

	get getConvertUriExtension() {
		return `soft-delete`;
	}

	constructor(id: string) {
		this.id = id
	}
}