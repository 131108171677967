import { CreateManuelOrderItemRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';

export class CreateReorderItemItemDto {
	oldItemId: string;
	reasonTypes: number[];
	item: CreateManuelOrderItemRequestDto | null;

	constructor(props: Partial<CreateReorderItemItemDto>) {
		this.oldItemId = props.oldItemId || '';
		this.reasonTypes = props.reasonTypes || [];
		this.item = props.item || null;
	}
}

export class CreateReorderItemRequest {
	reorderId: string;
	items: CreateReorderItemItemDto[];

	constructor(props: Partial<CreateReorderItemRequest>) {
		this.reorderId = props.reorderId || '';
		this.items = props.items || [];
	}

	get getConvertUriExtension() {
		return `add-items-to-reorder`;
	}
}
