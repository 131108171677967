import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import { TaxonomyDto } from 'features/integrations/_common/get-etsy-taxonomies/dtos/taxonomy.dto';
import TreeNode from 'primereact/treenode';

const generateTreeNodeWithSelectable = (data: TaxonomyDto | CategoryBaseDto, setSelectable: boolean | undefined = undefined): TreeNode => ({ key: data.id, label: data.name, data, selectable: setSelectable });

const mapTaxonomyToParent = (parentCategory: TaxonomyDto, treeNode: TreeNode, setSelectable: boolean | undefined = undefined) => {
	if (!parentCategory.children || parentCategory.children.length < 1) return;

	parentCategory.children.forEach((_subCategory) => {
		if (treeNode.children === undefined) treeNode.children = [];

		const treeNodeForCurrentSubCategory = generateTreeNodeWithSelectable(_subCategory);

		treeNode.children?.push(treeNodeForCurrentSubCategory);

		mapTaxonomyToParent(_subCategory, treeNodeForCurrentSubCategory);
	});
};

const mapCategoryToParentWithSelectable = (parentCategory: CategoryBaseDto, treeNode: TreeNode, setSelectable: boolean | undefined = undefined) => {
	if (!parentCategory.subCategories || parentCategory.subCategories.length < 1) return;

	parentCategory.subCategories.forEach((_subCategory) => {
		if (treeNode.children === undefined) treeNode.children = [];

		const treeNodeForCurrentSubCategory = generateTreeNodeWithSelectable(_subCategory, typeof setSelectable !== undefined ? _subCategory.canBeAddProduct : undefined);

		treeNode.children?.push(treeNodeForCurrentSubCategory);

		mapCategoryToParentWithSelectable(_subCategory, treeNodeForCurrentSubCategory, typeof setSelectable !== undefined ? _subCategory.canBeAddProduct : undefined);
	});
};

const mapEtsyTaxonomiesForTree = (categories: TaxonomyDto[]) => {
	let treeNode: TreeNode[] = [];

	categories.forEach((_category) => {
		const treeNodeForCurrentCategory = generateTreeNodeWithSelectable(_category);

		mapTaxonomyToParent(_category, treeNodeForCurrentCategory);

		treeNode.push(treeNodeForCurrentCategory);
	});

	return treeNode;
};

const mapCategoriesForTreeWithSelectable = (categories: CategoryBaseDto[], setSelectable: boolean | undefined = undefined) => {
	let treeNode: TreeNode[] = [];

	categories.forEach((_category) => {
		const treeNodeForCurrentCategory = generateTreeNodeWithSelectable(_category, typeof setSelectable !== undefined ? _category.canBeAddProduct : undefined);

		mapCategoryToParentWithSelectable(_category, treeNodeForCurrentCategory);

		treeNode.push(treeNodeForCurrentCategory);
	});

	return treeNode;
};

const generateTreeNode = (data: TaxonomyDto | CategoryBaseDto): TreeNode => ({ key: data.id, label: data.name, data });

const mapCategoryToParent = (parentCategory: CategoryBaseDto, treeNode: TreeNode) => {
	if (!parentCategory.subCategories || parentCategory.subCategories.length < 1) return;

	parentCategory.subCategories.forEach((_subCategory) => {
		if (treeNode.children === undefined) treeNode.children = [];

		const treeNodeForCurrentSubCategory = generateTreeNode(_subCategory);

		treeNode.children?.push(treeNodeForCurrentSubCategory);

		mapCategoryToParent(_subCategory, treeNodeForCurrentSubCategory);
	});
};

const mapCategoriesForTree = (categories: CategoryBaseDto[], setSelectable: boolean | undefined = undefined) => {
	let treeNode: TreeNode[] = [];

	categories.forEach((_category) => {
		const treeNodeForCurrentCategory = generateTreeNode(_category);

		mapCategoryToParent(_category, treeNodeForCurrentCategory);

		treeNode.push(treeNodeForCurrentCategory);
	});

	return treeNode;
};

const treeHelper = { mapEtsyTaxonomiesForTree, mapCategoriesForTree, mapCategoriesForTreeWithSelectable };

export default treeHelper;
