import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ColorUpdate from './components/ColorUpdate';
import { Color } from 'features/products/_common/get-core-product/get-core-product.response';
import colorService from 'services/color-service';
import ColorCreate from 'components/_common/modals/ColorCreate';

const Colors = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [colors, setColors] = useState<Color[]>([]);
	const [visibleCreateColor, setVisibleCreateColor] = useState(false);
	const [visibleUpdateColor, setVisibleUpdateColor] = useState(false);
	const [editedColor, setEditedColor] = useState<Color | undefined>(undefined);

	const getAll = () => {
		setLoading(true);

		colorService
			.getAll()
			.then((response) => response.isSuccess && setColors(response.data || []))
			.finally(() => setLoading(false));
	};

	const deleteColor = (id: string) => colorService.delete(id).then((response) => response && getAll());

	const handleEditBtnCliked = (color: Color) => {
		setEditedColor(color);
		setVisibleUpdateColor(true);
	};

	useEffect(() => {
		getAll();
	}, []);

	const actionsTemplate = (color: Color) => (
		<div className="flex gap-3 justify-content-end mx-0">
			<Button onClick={() => handleEditBtnCliked(color)} label="Edit" icon="pi pi-pencil" className="px-4" />
			<Button onClick={() => deleteColor(color.id)} label="Delete" icon="pi pi-trash" className="p-button-danger px-4" />
		</div>
	);

	const hexActionsTemplate = (color: Color) => {
		return (
			<div className="flex gap-2 align-items-center">
				<div className="color-picker" style={{ backgroundColor: color.hexCode }} />
				{color.hexCode}
			</div>
		);
	};

	return (
		<div className="card">
			<div className="flex justify-content-between mb-3">
				<h5>Colors</h5>
				<Button onClick={() => setVisibleCreateColor(true)} label="New Color" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>

			<DataTable value={colors} dataKey="id" paginator rows={10} loading={loading} className="p-datatable-gridlines">
				<Column field="id" header="Id" filter filterPlaceholder="Search by Id" />
				<Column field="hexCode" header="Hex Code" body={hexActionsTemplate} filter filterPlaceholder="Search by Hex Code" />
				<Column
					field="imageUrl"
					header="Image"
					body={(color: Color) => (
						<div
							style={{
								backgroundImage: `url('${color.imageUrl}')`,
								backgroundColor: color.hexCode,
								width: '2rem',
								height: '2rem',
								borderRadius: '.25rem',
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat'
							}}
						/>
					)}
				/>
				<Column field="name" header="Name" filter filterPlaceholder="Search by Name" />
				<Column field="actions" header="Actions" body={actionsTemplate} style={{ width: '0' }} />
			</DataTable>

			<ColorCreate isVisible={visibleCreateColor} setVisible={setVisibleCreateColor} getColors={getAll} />
			<ColorUpdate isVisible={visibleUpdateColor} setVisible={setVisibleUpdateColor} getColors={getAll} editedColor={editedColor} setEditedColor={setEditedColor} />
		</div>
	);
};

export default Colors;
