import DropdownInput from 'helpers/widgets/Forms/DropdownInput';
import prefixCountryNumbers from 'assets/jsons/prefix-country-numbers.json';
import { DropdownChangeParams } from 'primereact/dropdown';

const prefixesSet = new Set(prefixCountryNumbers.filter((_prefix) => _prefix.active).map((_prefix) => _prefix.prefix));
const prefixes = [...(prefixesSet as any)].map((_prefix: string) => ({ label: _prefix, value: _prefix.replaceAll('+', '') }));

type Props = {
	value: string;
	onChange: (event: DropdownChangeParams) => void;
	label: string;
	name: string;
	isOptional: boolean;
	required: boolean;
	className: string;
	style: React.CSSProperties;
};

const PrPrefixDropdownInput = (props: Partial<Props>) => {
	return <DropdownInput filter label={props.label || ''} name={props.name || 'prefix'} isOptional={props.isOptional} required={props.required} options={prefixes} style={props.style} value={props.value || prefixes[0]} onChange={props.onChange} className={props.className} />;
};

export default PrPrefixDropdownInput;
