import { NotificationSetting } from "../get-my-settings/dtos/notification";
import { SellerStoreOption } from "../get-my-settings/dtos/seller-store-option";
import { Timezone } from "../get-my-settings/dtos/timezone";

export class UpdateMySettingsRequest {
	userId: string;
	timezone: Timezone;
	notificationSettings: NotificationSetting[];
	sellerStoreOption: SellerStoreOption | null;

	constructor(userId: string, timezone: Timezone, notificationSettings: NotificationSetting[], sellerStoreOption: SellerStoreOption | null) {
		this.userId = userId;
		this.timezone = timezone;
		this.notificationSettings = notificationSettings;
		this.sellerStoreOption = sellerStoreOption;
	}

	get getConvertUriExtension() {
		return `update-my-settings`;
	}
}