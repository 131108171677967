import { CreateOrderContext, CreateOrderContextType, StepWithComponentEnum, orderSteps } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React, { useEffect, useState } from 'react';
import { CostBreakdown } from '../breakdown/CostBreakdown';
import ShippingRates from '../rates/ShippingRates';
import ShippingInfo from '../shipping-info/ShippingInfo';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

export const ShipSummary = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);
	const steps = [
		{
			element: '.tutorial-selector1',
			intro: 'You can choose your preferred shipping option based on the calculated shipping fees.',
		}
	];

	useEffect(() => {
		setTutorialStepsEnabled(true);
	},[])

	return (
		<React.Fragment>
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'ship-summary-intro', disableInteraction: false }} /> */}

			<div className="container-body p-5">
				<ShippingInfo />
			</div>

			<div className="container-body p-5">
				<div className='tutorial-selector1'>
					<ShippingRates />
				</div>
			</div>

			{!!context.calculatedPrice ? (
				<div className="container-body text-center p-5">
					<CostBreakdown {...context.calculatedPrice} />

					<div className="flex justify-content-center mt-4">
						<PrButton
							text="Continue to review"
							onClick={() => {
								for (const step of orderSteps) {
									if (step.value === StepWithComponentEnum.Shipping) {
										step.isCanNext = true;
										step.isCompleted = true;

										continue;
									}

									if (step.value === StepWithComponentEnum.ReviewOrder) {
										context.setOrderStep(step);

										continue;
									}
								}

								context.setRequest((current) => {
									const newCurrent = structuredClone(current);

									newCurrent.shippingRate = context.selectedRate || null;

									return newCurrent;
								});
							}}
						/>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};
