import React from 'react';
import { SyncOrderContext, SyncOrderContextType, orderSteps } from 'components/pages/orders/sync-order/context/SyncOrderContextProvider';
import { CostBreakdown } from '../breakdown/CostBreakdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import ShippingRates from '../rates/ShippingRates';
import ShippingInfo from '../shippingInfo/ShippingInfo';

export const ShipSummary = () => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	return (
		<React.Fragment>
			<div className="container-body p-5">
				<ShippingInfo />
			</div>

			<div className="container-body p-5">
				<ShippingRates />
			</div>

			{!!context.calculatedPrice ? (
				<div className="container-body text-center p-5">
					<CostBreakdown {...context.calculatedPrice} />

					<div className="flex justify-content-center mt-4">
						<PrButton
							text="Continue to review"
							onClick={() => {
								orderSteps[1].isCanNext = true;
								orderSteps[1].isCompleted = true;

								context.setOrderStep({ ...orderSteps[2] });

								context.setRequest((current) => {
									const newCurrent = structuredClone(current);

									newCurrent.createModel.shippingRate = context.selectedRate || null;

									return newCurrent;
								});
							}}
						/>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};
