import { UpdateMerchantWithUserRequest } from 'features/merchants/_common/update-merchant-with-user/update-merchant-with-user.request';
import { GetByIdRequest } from 'features/users/_common/get-by-id/get-by-id.request';
import userService from 'features/users/_common/user.service';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { StatusOptions } from 'helpers/constants/user-status-options';
import DropdownInput from 'helpers/widgets/Forms/DropdownInput';
import TextInput from 'helpers/widgets/Forms/TextInput';
import TextMaskInput from 'helpers/widgets/Forms/TextMaskInput';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

type Props = {
	visible: boolean;
	setVisible: (state: boolean) => void;
	onSubmit: (values: UpdateMerchantWithUserRequest, formikHelpers: FormikHelpers<UpdateMerchantWithUserRequest>) => void;
	merchantId: string;
};

const MerchantUpdate = (props: Partial<Props>) => {
	const formikRef = useRef<FormikProps<UpdateMerchantWithUserRequest>>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [prefixOptions, setPrefixOptions] = useState<SelectItemOptionsType>([
		{ label: '+1', value: '1' },
		{ label: '+90', value: '90' }
	]);

	const initialValues = new UpdateMerchantWithUserRequest({});

	const getById = async () => {
		try {
			setLoading(true);

			if (!props.merchantId) throw '';

			const response = await userService.getById(new GetByIdRequest(props.merchantId));

			if (!response.isSuccess || !response.data) throw '';

			const merchant = response.data;

			initialValues.initData(merchant);

			formikRef.current?.setFieldValue('merchantUpdateModel', initialValues.merchantUpdateModel);
			formikRef.current?.setFieldValue('createOrUpdateCorporateModel', initialValues.createOrUpdateCorporateModel);
			formikRef.current?.setFieldValue('userUpdateModel', initialValues.userUpdateModel);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!props.visible) return;

		getById();
	}, [props.merchantId, props.visible]);

	return (
		<Dialog header="Update Merchant" modal visible={props.visible} onHide={() => props.setVisible?.(false)}>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				enableReinitialize
				onSubmit={(values, formikHelpers) => {
					props.setVisible?.(false);
					props.onSubmit?.(values, formikHelpers);
				}}
				validationSchema={Yup.object().shape({
					merchantUpdateModel: Yup.object().shape({ userId: Yup.string().required(), userStatuId: Yup.number().moreThan(0, 'User statu is required') }),
					userUpdateModel: Yup.object().shape({ id: Yup.string().required(), email: Yup.string().required('Email is required'), name: Yup.string().required('Name is required'), surname: Yup.string().required('Surname is required') }),
					createOrUpdateCorporateModel: Yup.object().shape({ businessName: Yup.string().required('Business name is required') })
				})}>
				{({ values, handleChange }) => (
					<Form className="relative">
						<div className="grid">
							<div className="col-12 sm:col-6">
								<TextInput label="Business Name" name="createOrUpdateCorporateModel.businessName" value={values.createOrUpdateCorporateModel?.businessName} onChange={handleChange} placeholder="Enter business name" />
							</div>
							<div className="col-12 sm:col-6">
								<TextMaskInput label="FEIN Number" mask="99-9999999" name="createOrUpdateCorporateModel.feinNumber" unmask={true} value={values.createOrUpdateCorporateModel?.feinNumber || ''} onChange={handleChange} placeholder="Enter FEIN number" />
							</div>
							<div className="col-12 sm:col-6">
								<TextInput label="Merchant Name" name="userUpdateModel.name" value={values.userUpdateModel.name} onChange={handleChange} placeholder="Enter merchant name" />
							</div>
							<div className="col-12 sm:col-6">
								<TextInput label="Surname" name="userUpdateModel.surname" value={values.userUpdateModel.surname} onChange={handleChange} placeholder="Enter merchant surname" />
							</div>
							<div className="col-12 sm:col-6">
								<TextInput label="Email" name="userUpdateModel.email" value={values.userUpdateModel.email} onChange={handleChange} placeholder="Enter merchant email" />
							</div>
							<div className="col-12 sm:col-6">
								<div className="flex gap-2">
									<div className="w-3">
										<DropdownInput label="Code" name="userUpdateModel.phoneNumbers[0].code" options={prefixOptions} value={values.userUpdateModel.phoneNumbers[0]?.code} onChange={handleChange} placeholder="Code" />
									</div>
									<div className="w-9">
										<TextMaskInput label="Phone" name="userUpdateModel.phoneNumbers[0].number" unmask={true} mask="(999) 999-9999" value={values.userUpdateModel.phoneNumbers[0]?.number} placeholder="Enter merchant phone number" onChange={handleChange} />
									</div>
								</div>
							</div>
							<div className="col-12 sm:col-6">
								<TextMaskInput label="SSN Number" mask="999-99-9999" name="userUpdateModel.ssnNumber" unmask={true} value={values.userUpdateModel?.ssnNumber || ''} onChange={handleChange} placeholder="Enter ssn number" />
							</div>
							<div className="col-12 sm:col-6">
								<DropdownInput label="User Status" name="merchantUpdateModel.userStatuId" options={StatusOptions} value={values.merchantUpdateModel.userStatuId} onChange={handleChange} placeholder="Select a statu" />
							</div>
							<div className="col-12 mt-4">
								<div className="flex justify-content-between">
									<div>
										<Checkbox name="userUpdateModel.isForeignNation" checked={values.userUpdateModel.isForeignNation} onChange={handleChange} />
										<span className="ml-1">Foreign Nation</span>
									</div>

									<PrButton btnType="submit" text="Update Merchant" />
								</div>
							</div>
						</div>

						{loading ? (
							<div className="pr-loading-sticky">
								<div className="pr-spinner-wrapper">
									<ProgressSpinner className="p-progress-color" strokeWidth="4" />
								</div>
							</div>
						) : null}
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default MerchantUpdate;
