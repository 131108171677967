export class CreateUserSubscribtionRequest {
	userId: string;
	planId: string;
	startDate: Date;
	endDate: Date = new Date();
	isTrial: boolean;

	constructor(props?: Partial<CreateUserSubscribtionRequest>) {
		this.userId = props?.userId || '';
		this.planId = props?.planId || '';
		this.startDate = props?.startDate || new Date();
		this.isTrial = props?.isTrial || false;

		if (!!props?.endDate) this.endDate = props.endDate;
		else {
			this.endDate.setDate(this.endDate.getDate() + 1);
		}
	}

	get getConvertUriExtension() {
		return `create-subscription`;
	}
}
