export class CreateSetupIntentRequest {
	userId: string = '';

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `create-setup-intent`;
	}
}
