import { SendResetPasswordCode } from '../models/requests/userSecurity/send-reset-password-code-request';
import { DataResponse } from '../models/_commons/responses/data-response';
import { ResponseBase } from '../models/_commons/responses/response-base';
import HttpBaseService from './_common/http-base-service';
import { ResetPasswordRequest } from '../models/requests/userSecurity/reset-password-request';
import { CheckResetPasswordCode } from '../models/requests/userSecurity/check-reset-password-code-request';

class UserSecurityService extends HttpBaseService {
    constructor() {
        super('user-securities');
    }

    async sendResetPasswordCode(request: SendResetPasswordCode) {
        (await this.postData('send-reset-password-code', request)) as ResponseBase;
    }

    async checkResetPasswordCode(request: CheckResetPasswordCode) {
        return (await this.getData('check-reset-password-code', request)) as DataResponse<any>;
    }

    async resetPassword(request: ResetPasswordRequest) {
        return (await this.postData('reset-password', request)) as ResponseBase;
    }
}

export default new UserSecurityService();
