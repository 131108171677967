import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { useState } from 'react'
import * as Yup from 'yup';
import TextInput from '../../../../../helpers/widgets/Forms/TextInput';
import { CreateSizeRequest } from '../../../../../models/requests/sizes/create-size-request';
import sizeService from '../../../../../services/dimensions/size-service';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getSizes: Function;
};

const SizeCreate = ({ isVisible, setVisible, getSizes }: Props) => {
	const [loading, setLoading] = useState(false)

	const initialValues: CreateSizeRequest = { name: '', shortName: '', priority: 0 }

	const validationSchema = Yup.object().shape({
		name: Yup.string().required().min(2, 'Name must be at least 2 characters.').max(100, 'Name must be max 100 characters.'),
		shortName: Yup.string().required().min(1, 'Short name must be at least 1 characters.').max(50, 'Short name must be max 50 characters.'),
		priority: Yup.number().min(1, 'Priority number must be at least 1 characters.').max(255, 'Priority number must be max 255 characters.')
	});

	const onSave = (values: CreateSizeRequest) => {
		setLoading(true)

		sizeService.create(values).then(response => {
			if (!response.isSuccess) return;

			setVisible(false);
			getSizes();
		}).finally(() => setLoading(false))
	}

	return (
		<Dialog visible={isVisible} header="New Size" modal onHide={() => setVisible(false)}>
			<Formik initialValues={initialValues} onSubmit={onSave} validationSchema={validationSchema}>
				{({ values, handleChange }) => (
					<Form>
						<div className="grid">
							<div className="col-12">
								<TextInput name="name" value={values.name} onChange={handleChange} placeholder="Enter size name" label="Name" />
							</div>

							<div className="col-12">
								<TextInput name="shortName" value={values.shortName} onChange={handleChange} placeholder="Enter size short name" label="Short Name" />
							</div>

							<div className="col-12">
								<TextInput name="priority" onChange={handleChange} placeholder="0" label="Priority Number" keyfilter="num" />
							</div>

							<div className="col-12 flex justify-content-end mt-2">
								<Button type="submit" label="Create" loading={loading} className="h-full px-8" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default SizeCreate