import HttpBaseService from 'services/_common/http-base-service';
import { CreateReorderItemRequest } from './create-reorder-item/create-reorder-item.request';
import { CreateReorderItemResponse } from './create-reorder-item/create-reorder-item.response';

class ReorderItemService extends HttpBaseService {
	constructor() {
		super('reorder-items');
	}

	async createItems(request: CreateReorderItemRequest, uploadAction: (state: ProgressEvent<EventTarget>) => void): Promise<CreateReorderItemResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}
}

export const reorderItemService = new ReorderItemService();
