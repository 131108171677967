import { Card } from 'primereact/card';
import styles from '../OrderDetail.module.scss';
import { Timeline } from 'primereact/timeline';
import dateHelper from 'helpers/dateHelper';
import { useEffect, useState } from 'react';
import { OrderDto } from 'models/dtos/order/order';
import { OrderStatuHistoryDto } from 'models/dtos/order/order-statu.history';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { BsBrush } from 'react-icons/bs';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import toastHelper from 'helpers/toast.helper';
import UpdateShippingAddressModal from './modals/UpdateShippingAddressModal';

interface Detail {
	title: string;
	description: string | number;
	callback?: () => void;
	iconName?: string;
}

const OrderDetailList = (order: OrderDto) => {
	const [visible, setVisible] = useState<boolean>(false);

	const [details, setDetails] = useState<Detail[]>([
		{
			title: 'Order No:',
			description: order.code || '-'
		},
		// {
		// 	title: 'Order Receipt Id:',
		// 	description: order.store.etsyStore.receiptId || '-'
		// },
		{
			title: 'Customer Name:',
			description: order.customerFullName || '-'
		},
		{
			title: 'Pending Approval Date:',
			description: dateHelper.formatDate(order.statuHistories[0].createdDate) || '-'
		},
		{
			title: 'Order Note:',
			description: order.customerNote || '-'
		},
		{
			title: 'Shipping to Address:',
			description: order.orderAddress?.formattedAddress || '-',
			callback: () => {
				if (order.statu.type !== OrderStatuTypes.PendingApproval) {
					toastHelper.warning(`You can only update the shipping address while the order is in the '${OrderStatuTypes[OrderStatuTypes.PendingApproval]}' status.`);
					return;
				}

				setVisible(true);
			}
		},
		{
			title: 'Platform:',
			description: order.isManuelOrder ? 'Printram' : 'ETSY'
		},
		{
			title: 'Create Type:',
			description: order.isManuelOrder ? 'Manuel' : order.isExternalOrder ? 'External' : 'Automatic'
		},
		{
			title: 'Gift Information:',
			description: order.giftInformation?.message || '-'
		},
		{
			title: 'Order Status:',
			description: order.statu.readableName || '-'
		},
		{
			title: 'Delivery Type:',
			description: DeliveryTypes[order.deliveryType || 0]
		}
		// {
		// 	title: 'Private Note:',
		// 	description: order.store.etsyStore?.sellerPrivateNote || '-'
		// }
	]);

	// useEffect(() => {
	// 	if (!order.store?.etsyStore) return;

	// 	setDetails((current) => [...current, { title: 'Etsy Created Date:', description: dateHelper.formatDate(order.etsyOrder?.createdDateAtEtsy || '-') || '-' }]);
	// }, []);

	const customizedContent = (statuHistory: OrderStatuHistoryDto) => (
		<Card title={statuHistory.statu.readableName} subTitle={dateHelper.formatDate(statuHistory.createdDate)}>
			{!!statuHistory.reason && statuHistory.reason.length > 0 ? <p>{statuHistory.reason}</p> : null}
		</Card>
	);

	return (
		<div className="grid">
			<div className="col-12 lg:col-6">
				{details.length > 0
					? details.map(
							(detail: Detail, index: number) =>
								detail.description && (
									<div key={index} className={styles.detailMain}>
										<span dangerouslySetInnerHTML={{ __html: detail.title }} className={styles.title} />
										<span dangerouslySetInnerHTML={{ __html: detail.description.toString() }} className={styles.description} />

										{!!detail.callback ? <PrButton icon={<BsBrush />} type="icon" id="shippingAddressUpdate" tooltip="Update shipping address" className="ml-2 text-primary" onClick={detail.callback} /> : null}
									</div>
								)
					  )
					: null}
			</div>

			<div className={`col-12 lg:col-6 ${styles.hiddenOnPrint}`}>
				<h5 className="text-center">Order Status History</h5>

				<Timeline value={order.statuHistories} align="alternate" className="customized-timeline" content={customizedContent} />
			</div>

			<UpdateShippingAddressModal order={order} visible={visible} setVisible={setVisible} callback={() => {}} />
		</div>
	);
};

export default OrderDetailList;
