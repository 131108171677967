import { PdfRequestBase } from '../_bases/request.base';

export class GenerateOrderPdfsRequest implements PdfRequestBase {
	orderIdentities: string[] = [];

	constructor(orderIdentities?: string[]) {
		if (!!orderIdentities) this.orderIdentities = orderIdentities;
	}

	get getConvertUriExtension() {
		return `generate-order-pdfs`;
	}
}
