export class IsUserHaveAnAccountRequest {
	userId: string = '';

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `is-user-have-an-account?UserId=${this.userId}`;
	}
}