import React from 'react';

interface Props extends Omit<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'ref'> {
	label: string;
	description: string;
	required: boolean;
}

const PrTextareaInput = (props: Partial<Props>) => {
	return (
		<div className="pr-input-group w-full">
			<label htmlFor="title" className="pr-input-label">
				<h5 className="pr-input-label-title">
					{props.label || ''} {typeof props.required !== 'undefined' ? !props.required ? <span className="pr-input-label-optional">(Optional)</span> : <span className="pr-input-label-required">*</span> : null}
				</h5>

				{props.description ? <p className="pr-input-label-description">{props.description}</p> : null}
			</label>

			<textarea {...props} className="pr-input-textarea-control" />
		</div>
	);
};

export default PrTextareaInput;
