import CryptoJS from 'crypto-js';
import environment from './constants/environment';

const key = '(:printramSuperAbsoluteClassInterfaceAbstractReactReduxtToolkitCHastagOrCSharpSecretKeyWithCamelCase:)';

const encrypt = (value: string) => {
    if (environment.mode !== 'production') return value;

    try {
        return CryptoJS.AES.encrypt(value, key).toString();
    } catch (error) {
        console.error('ENCRYP ERROR =>', error);
        return null;
    }
};

const decrypt = (value: string | null) => {
    if (environment.mode !== 'production' || !value) return value;

    try {
        const bytes = CryptoJS.AES.decrypt(value, key);
        if (!bytes) throw bytes;

        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('DECRYP ERROR =>', error);
        return null;
    }
};

const cryptoHelper = { encrypt, decrypt };

export default cryptoHelper;
