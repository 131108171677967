import { ProductDetailAndMaterials } from "../../models/dtos/product/product-detail-and-materials";
import { CreateProductDetailAndMaterialsRequest } from "../../models/requests/products/create-product-detail-and-materials-request";
import { UpdateProductDetailAndMaterialsRequest } from "../../models/requests/products/update-product-detail-and-materials-request";
import { DataResponse } from "../../models/_commons/responses/data-response";
import HttpBaseService from "../_common/http-base-service";


class ProductDetailAndMaterialsService extends HttpBaseService {
	constructor() {
		super('product-detail-and-product-materials');
	}

	async get(productId: string): Promise<DataResponse<ProductDetailAndMaterials>> {
		return await this.getData('get-all-by-product?ProductId=' + productId);
	}

	async create(request: CreateProductDetailAndMaterialsRequest) {
		return await this.postData('create', request);
	}

	async update(request: UpdateProductDetailAndMaterialsRequest) {
		return await this.putData('update', request);
	}
}

export default new ProductDetailAndMaterialsService()