export class GetPaymentMethodsRequest {
	userId: string = "";

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `get-payment-methods?userId=${this.userId}`;
	}
}