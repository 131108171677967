export class CreateTicketRequest {
	title: string;
	description: string;
	ticketType: number;
	attachmentFiles?: File[];
	orderId?: string | null;

	constructor(title: string, description: string, ticketType: number, attachmentFiles: File[], orderId?: string) {
		this.title = title || '';
		this.description = description || '';
		this.ticketType = ticketType || 1;
		this.attachmentFiles = attachmentFiles || [];
		if (orderId) this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return ``;
	}
}