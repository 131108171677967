import { CreateMerchantRequest } from 'features/merchants/_common/create-merchant/create-merchant.request';
import { Form, Formik, FormikHelpers } from 'formik';
import TextInput from 'helpers/widgets/Forms/TextInput';
import TextMaskInput from 'helpers/widgets/Forms/TextMaskInput';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import * as Yup from 'yup';

type Props = {
	visible: boolean;
	setVisible: (state: boolean) => void;
	onSubmit: (values: CreateMerchantRequest, formikHelpers: FormikHelpers<CreateMerchantRequest>) => void;
};

const MerchantCreate = (props: Partial<Props>) => {
	return (
		<Dialog visible={props.visible} header="Create New Merchant" modal onHide={() => props.setVisible?.(false)}>
			<Formik
				initialValues={new CreateMerchantRequest({})}
				onSubmit={(values, formikHelpers) => {
					props.setVisible?.(false);
					props.onSubmit?.(values, formikHelpers);
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Name is Required').min(3, 'Name must be at least 3 characters.').max(50, 'Name must be a maximum of 50 characters.'),
					surname: Yup.string().required('Surname is Required').min(3, 'Surname must be at least 3 characters.').max(50, 'Surname must be a maximum of 50 characters.'),
					email: Yup.string().email('Email address must be valid.').required('Email is Required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
					businessName: Yup.string().required('Business Name is Required').min(3, 'Business Name must be at least 3 characters.'),
					feinNumber: Yup.string().required('Fein Number is Required'),
					password: Yup.string().required('Password is Required').min(8, 'Password must be at least 8 characters.'),
					isTermsConfirmed: Yup.bool().required('Terms is required').oneOf([true], 'Terms must be checked')
				})}>
				{({ values, handleChange }) => (
					<Form>
						<div className="grid relative">
							<div className="col-12 xl:col-6">
								<TextInput label="Business Name" name="businessName" value={values.businessName} onChange={handleChange} placeholder="Enter business name" />
							</div>

							<div className="col-12 xl:col-6">
								<TextMaskInput label="FEIN Number" mask="99-9999999" name="feinNumber" unmask={true} value={values.feinNumber} onChange={handleChange} placeholder="Enter fein number" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Enter name" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Surname" name="surname" value={values.surname} onChange={handleChange} placeholder="Enter surname" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Email" name="email" value={values.email} onChange={handleChange} type="email" placeholder="Enter email" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Password" name="password" value={values.password} onChange={handleChange} placeholder="Enter password" />
							</div>

							<div className="col-12 text-right mt-3">
								<Button type="submit" label="Create Merchant" className="h-full px-6" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default MerchantCreate;
