import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetTemplateProductsForListRequest {
	pagination: PaginationDto;

	constructor(props: Partial<GetTemplateProductsForListRequest>) {
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-template-products-for-list`;
	}
}
