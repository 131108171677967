import React from 'react';
import { OrderDto } from 'models/dtos/order/order';
import toastHelper from 'helpers/toast.helper';

export const OrderDetailTitle = (order: OrderDto) => {
	if (!!order?.etsyOrder?.receiptId) {
		return (
			<div>
				<h1
					className="container-header-title cursor-pointer"
					onClick={() => {
						if (!order) return;

						navigator.clipboard.writeText(order?.etsyOrder?.receiptId?.toString() || '');
						toastHelper.success(`Copied '${order?.etsyOrder?.receiptId}' to clipboard`);
					}}>
					#{order?.etsyOrder?.receiptId}
				</h1>
				<h6
					className="m-0 text-base font-medium cursor-pointer text-600"
					onClick={() => {
						if (!order) return;

						navigator.clipboard.writeText(order?.code);
						toastHelper.success(`Copied '${order?.code}' to clipboard`);
					}}>
					#{order?.code}
				</h6>
			</div>
		);
	}

	return (
		<h1
			className="container-header-title cursor-pointer"
			onClick={() => {
				if (!order) return;

				navigator.clipboard.writeText(order?.code);
				toastHelper.success(`Copied '${order?.code}' to clipboard`);
			}}>
			#{order?.code}
		</h1>
	);
};
