import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { PrimeIcons } from 'primereact/api';
import { CreateWizardContext, WizardContextType } from '../context/WizardContextProvider';
import { SurveyQuestionDto, SurveyQuestionOptionDto } from '../../../../../features/surveys/_common/survey-get/survey-get.response';
import { AnswerDto } from '../../../../../features/surveys/dtos/answer-dto';
import OptionButton from '../../Survey/components/OptionButton';

type Props = {
	question?: SurveyQuestionDto;
};

function ChannelsUsing({ question }: Props): React.ReactElement {
	const context: WizardContextType = React.useContext(CreateWizardContext) as WizardContextType;

	const [itemStates, setItemStates] = useState<{ [key: string]: boolean }>({});
	const [selectedOptions, setSelectedOptions] = useState<SurveyQuestionOptionDto[]>([]);
	const [otherSelected, setOtherSelected] = useState(false);
	const [otherInput, setOtherInput] = useState<string>('');
	const [textAddableOption, setTextAddableOption] = useState<string | null>(null);
	const [error, setError] = useState('');

	useEffect(() => {
		const initialStates = question?.surveyQuestionOptions.reduce(
			(acc: { [p: string]: boolean }, item) => {
				acc[item.id] = false;
				return acc;
			},
			{} as { [key: number]: boolean }
		);
		setItemStates(initialStates!);

		const filtered = question?.surveyQuestionOptions.filter((e) => e.textCanBeAdded);

		if (filtered!.length !== 0) setTextAddableOption(filtered![0].id);
	}, []);

	const onOptionClick = (option: SurveyQuestionOptionDto) => {
		setItemStates((prevState) => ({
			...prevState,
			[option.id]: !prevState[option.id]
		}));

		let newCurrent = [...selectedOptions];

		if (!itemStates[option.id]) {
			newCurrent.push(option);
		} else {
			newCurrent = newCurrent.filter((_nCurrent: SurveyQuestionOptionDto) => _nCurrent.id !== option.id);
		}
		setSelectedOptions(newCurrent);

		if (option.id === textAddableOption) setOtherSelected(!otherSelected);
	};

	const validate = () => {
		return Object.values(itemStates).some((isSelected) => isSelected) && (otherSelected ? otherInput !== '' : true);
	};

	const onSubmit = () => {
		if (!validate()) return setError('Please select at least one of the options');

		if (!context.user) return;

		let newAnswers: AnswerDto[] = context.answers;

		selectedOptions.forEach((option, index) => {
			newAnswers.push({
				surveyQuestionOptionId: option.id,
				text: option.id === textAddableOption ? otherInput : null,
				userId: context.user?.userId!,
				questionId: question?.id!
			});
		});

		context.setAnswers(newAnswers);

		context.setStep(3);
	};

	return (
		<div className="flex flex-column justify-content-center align-items-center w-full">
			<div className="text-center mb-1 px-2">
				<h2 className="font-semibold">{question?.question}</h2>
				<p>We will use this information to customize your experience.</p>

				<div className="h-1rem mb-2">{error !== '' && <p className="text-sm my-auto" style={{color: "red"}}>{error}</p>}</div>
			</div>

			<div className="grid w-full justify-content-center">
				{question?.surveyQuestionOptions.map((item, index: number) => {
					return <OptionButton key={index} name={item.text} disabled={context.loading} checked={itemStates[item.id] || false} onClick={() => onOptionClick(item)} />;
				})}
			</div>

			<div className={`${textAddableOption ? 'block' : 'hidden'} h-6rem w-full`}>
				{otherSelected ? (
					<div className="flex flex-column justify-content-center align-items-stretch w-full px-2">
						<p className="text-sm" style={{ color: '#555555' }}>
							You selected 'Other'. Please enter your answer below.
						</p>
						<InputText
							className={error !== '' ? 'border-round-lg border-red-500 transition-linear' : 'border-round-lg'}
							id="channel_other"
							onChange={(value) => {
								setError('');
								setOtherInput(value.target.value);
							}}
						/>
					</div>
				) : null}
			</div>

			<div className="flex flex-row justify-content-center my-1">
				<Button label="Back" onClick={() => context.back()} icon={PrimeIcons.ANGLE_LEFT} className="p-button-text mr-4 text-lg" style={{ color: '#101531' }} />

				<Button className="border-round-md border-none hover:bg-transparent text-lg px-4 py-2" style={{ backgroundColor: '#4541FF' }} label={!context.loading ? 'Next' : ''} onClick={() => onSubmit()}></Button>
			</div>
		</div>
	);
}

export default ChannelsUsing;