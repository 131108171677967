import HttpBaseService from "services/_common/http-base-service";
import { GetByIdRequest } from "./get-by-id/get-by-id.request";
import { GetByIdResponse } from "./get-by-id/get-by-id.response";
import { LoginWithAutomaticLoginRequest } from "./login-with-automatic-login/login-with-automatic-login.request";
import { DataResponse } from "models/_commons/responses/data-response";
import { Auth } from "models/dtos/auth/auth";
import localStorageHelper from "helpers/local-storage.helper";
import { ChangePasswordRequest } from "./change-password/change-password.request";
import { ChangePasswordResponse } from "./change-password/change-password.response";


class UserService extends HttpBaseService {
	constructor() {
		super('users');
	}

	async getById(request: GetByIdRequest): Promise<GetByIdResponse> {
		return await this.postData(request.getConvertUriExtension);
	}

	async autoLogin(request: LoginWithAutomaticLoginRequest) {
		const response = (await this.postData(request.getConvertUriExtension, request)) as DataResponse<Auth>;
		if (response.isSuccess) localStorageHelper.setStringfyForData('auth', response.data);

		return response;
	}

	async changePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new UserService();
