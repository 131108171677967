import HttpBaseService from 'services/_common/http-base-service';
import { GetMyAccountRequest } from './get-my-account/get-my-account.request';
import { GetMyAccountResponse } from './get-my-account/get-my-account.response';
import { UpdateMyAccountRequest } from './update-my-account/update-my-account.request';
import { UpdateMyAccountRespponse } from './update-my-account/update-my-account.response';
import { GetMySettingsRequest } from './get-my-settings/get-my-settings.request';
import { GetMySettingsResponse } from './get-my-settings/get-my-settings.response';
import { UpdateMySettingsRequest } from './update-my-settings/update-my-settings.request';
import { UpdateMySettingsResponse } from './update-my-settings/update-my-settings.response';

class UserProfileSercice extends HttpBaseService {
	constructor() {
		super('user-profiles');
	}

	async updateMyAccount(request: UpdateMyAccountRequest): Promise<UpdateMyAccountRespponse> {
		return this.putData(request.getConvertUriExtension, request);
	}

	async getMyAccount(request: GetMyAccountRequest): Promise<GetMyAccountResponse> {
		return this.getData(request.getConvertUriExtension);
	}

	async getMySettings(request: GetMySettingsRequest): Promise<GetMySettingsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async updateMySettings(request: UpdateMySettingsRequest): Promise<UpdateMySettingsResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}
}

export default new UserProfileSercice();
