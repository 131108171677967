import { Dialog } from 'primereact/dialog'
import React from 'react'
import styles from './CardSuccess.module.scss'
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { CiCircleCheck } from 'react-icons/ci';

type props = {
	isVisible: boolean;
	setVisible: Function;
	setCardFormVisible: Function;
	getAll: Function;
}
const CardSuccess = ({ isVisible, setVisible, setCardFormVisible, getAll }: props) => {

	const confirm = () => {
		getAll();
		setVisible(false);
		setCardFormVisible(false)
	}

  return (
		<Dialog visible={isVisible} modal onHide={() => setVisible(false)} closable={false} className={styles.dialog}>
			<div className={styles.img}>
				<CiCircleCheck size={120} className="text-green-300" />
			</div>
			<div className={styles.text}>
				<span>Card has been successfully submitted!</span>
			</div>

			<PrButton text="Ok, got it!" onClick={confirm} className="mx-auto mt-6" />
		</Dialog>
  );
}

export default CardSuccess