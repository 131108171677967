import { ProductVariantPriceDto } from './dtos/product-variant-price.dto';

export class SaveAsDraftRequest {
	productCopiedBySellerId: string = '';
	productName: string = '';
	productDescription: string = '';
	productVariantAndPrices: ProductVariantPriceDto[] = [];

	constructor(props: Partial<SaveAsDraftRequest>) {
		this.productCopiedBySellerId = props.productCopiedBySellerId || '';
		this.productName = props.productName || '';
		this.productDescription = props.productDescription || '';
		this.productVariantAndPrices = props.productVariantAndPrices || [];
	}

	get getConvertUriExtension() {
		return `save-as-draft`;
	}
}
