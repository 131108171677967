export class CancelDtfListRequest {
	dtfListIds: string[]

	constructor(dtfListIds: string[]) {
		this.dtfListIds = dtfListIds;
	}

	get getConvertUriExtension() {
		return "cancel-dtf-list";
	}
}