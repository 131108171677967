import { ReorderCalculateDto } from 'features/reorders/_common/calculate/re-order-calculate.response';
import currencyHelper from 'helpers/curreny.helper';

export const CostBreakdown = (props: ReorderCalculateDto) => {
	return (
		<div className="flex align-items-center justify-content-center">
			<div className="w-max">
				<h2 className="mb-3" style={{ fontSize: '1.5rem' }}>
					Order breakdown
				</h2>

				<div className="flex justify-content-between mb-2 font-bold">
					<span>Fulfillment</span>
					{props.discountedFulFillment ? (
						<div>
							<span>{currencyHelper.formatPrice(props.discountedFulFillment?.formattedPricePerUnit)}</span>
							<small className="font-semibold text-pink-600">
								<del>{currencyHelper.formatPrice(props.fulfillment.formattedPricePerUnit)}</del>
							</small>
						</div>
					) : (
						<span>{currencyHelper.formatPrice(props.fulfillment.formattedPricePerUnit)}</span>
					)}
				</div>

				<div className="flex justify-content-between font-bold">
					<span>Shipping</span>
					{props.discountedShipping ? (
						<div>
							<span>{currencyHelper.formatPrice(props.discountedShipping?.formattedPricePerUnit)}</span>
							<small className="font-semibold text-pink-600">
								<del>{currencyHelper.formatPrice(props.shipping.formattedPricePerUnit)}</del>
							</small>
						</div>
					) : (
						<span>{currencyHelper.formatPrice(props.shipping.formattedPricePerUnit)}</span>
					)}
				</div>

				<hr className="my-2" />

				<h2 className="flex justify-content-between m-0" style={{ fontSize: '1.25rem' }}>
					<span>Total</span>
					{props.discountedTotal ? (
						<div>
							<span>{currencyHelper.formatPrice(props.discountedTotal?.formattedPricePerUnit)}</span>
							<small className="font-semibold text-pink-600">
								<del>{currencyHelper.formatPrice(props.total.formattedPricePerUnit)}</del>
							</small>
						</div>
					) : (
						<span>{currencyHelper.formatPrice(props.total.formattedPricePerUnit)}</span>
					)}
				</h2>
			</div>
		</div>
	);
};
