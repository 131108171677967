import React, { createContext, ReactNode, useState } from 'react';
import { OrderDto } from '../../../../models/dtos/order/order';
import ShippingInfo from '../steps/ShippingInfo';
import UpdateAddress from '../steps/UpdateAddress';
import ShippingMethod from '../steps/ShippingMethod';
import Checkout from '../steps/Checkout';
import { UpdateTransportDeliveryAddress } from '../../../../features/orders/derived-features/order-transports/update-transport/update-transport.request';
import { PaymentTypes } from '../../../../features/orders/dtos/payment-type.dto';
import { UpgradeShippingRateDto } from '../../../../features/orders/derived-features/order-transports/dtos/upgrade-shipping-rate.dto';

export interface UpgradeShippingContextType {
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	order: OrderDto | null;
	setOrder: React.Dispatch<React.SetStateAction<OrderDto | null>>;
	deliveryAddress: UpdateTransportDeliveryAddress | null;
	setDeliveryAddress: React.Dispatch<React.SetStateAction<UpdateTransportDeliveryAddress | null>>;
	storeId: string | null;
	setStoreId: React.Dispatch<React.SetStateAction<string | null>>;
	selectedRate: UpgradeShippingRateDto | null;
	setSelectedRate: React.Dispatch<React.SetStateAction<UpgradeShippingRateDto | null>>;
	calculatedPrice: number;
	setCalculatedPrice: React.Dispatch<React.SetStateAction<number>>;
	paymentMethodType: PaymentTypes | null;
	setPaymentMethodType: React.Dispatch<React.SetStateAction<PaymentTypes | null>>;
	selectedPaymentCard: string | null;
	setSelectedPaymentCard: React.Dispatch<React.SetStateAction<string | null>>;
	step: UpgradeShippingStep;
	steps: UpgradeShippingStep[];
	nextStep: () => void;
	prevStep: () => void;
	goToStep: (index: number) => void;
}

const CreateUpgradeShippingContext = createContext<UpgradeShippingContextType | undefined>(undefined);

interface UpgradeShippingStep {
	index: number;
	component: ReactNode;
}

interface Props {
	children: ReactNode;
}

const UpgradeShippingContextProvider: React.FC<Props> = ({ children }) => {
	const steps = [
		{ index: 0, component: <ShippingInfo /> },
		{ index: 1, component: <UpdateAddress /> },
		{ index: 2, component: <ShippingMethod /> },
		{ index: 3, component: <Checkout /> }
	];

	const [loading, setLoading] = useState(false);
	const [order, setOrder] = useState<OrderDto | null>(null);
	const [deliveryAddress, setDeliveryAddress] = useState<UpdateTransportDeliveryAddress | null>(null);
	const [storeId, setStoreId] = useState<string | null>(null);
	const [selectedRate, setSelectedRate] = useState<UpgradeShippingRateDto | null>(null);
	const [calculatedPrice, setCalculatedPrice] = useState<number>(0);
	const [paymentMethodType, setPaymentMethodType] = React.useState<PaymentTypes | null>(null);
	const [selectedPaymentCard, setSelectedPaymentCard] = useState<string | null>(null);
	const [step, setStep] = useState<UpgradeShippingStep>(steps[0]);
	const nextStep = () => setStep(steps[step.index + 1]);
	const prevStep = () => setStep(steps[step.index - 1]);
	const goToStep = (index: number) => setStep(steps[index]);

	return (
		<CreateUpgradeShippingContext.Provider
			value={{
				loading,
				setLoading,
				order,
				setOrder,
				deliveryAddress,
				setDeliveryAddress,
				storeId,
				setStoreId,
				selectedRate,
				setSelectedRate,
				calculatedPrice,
				setCalculatedPrice,
				paymentMethodType,
				setPaymentMethodType,
				selectedPaymentCard,
				setSelectedPaymentCard,
				step,
				steps,
				nextStep,
				prevStep,
				goToStep
			}}>
			{children}
		</CreateUpgradeShippingContext.Provider>
	);
};

export { UpgradeShippingContextProvider, CreateUpgradeShippingContext };
