
import { CreateOrUpdateCorporateModelDto } from "./dtos/create-or-update-corporate-model.dto";
import { SellerUpdateModelDto } from "./dtos/seller-update-model.dto";
import { UserUpdateModelDto } from "./dtos/user-update-model.dto";

export class UpdateSellerWithUserRequest {
	sellerUpdateModel: SellerUpdateModelDto = { id: '', userStatuId: 0 };
	userUpdateModel: UserUpdateModelDto = { id: '', email: '', name: '', surname: '', phoneNumbers: [], isForeignNation: false };
	createOrUpdateCorporateModel?: CreateOrUpdateCorporateModelDto = { userId: '', businessName: '', feinNumber: null };

	get getConvertUriExtension() {
		return `update-seller-with-user`;
	}

	constructor(sellerUpdateModel: SellerUpdateModelDto, userUpdateModel: UserUpdateModelDto, createOrUpdateCorporateModel?: CreateOrUpdateCorporateModelDto) {
		this.sellerUpdateModel = sellerUpdateModel;
		this.userUpdateModel = userUpdateModel;
		if (createOrUpdateCorporateModel) this.createOrUpdateCorporateModel = createOrUpdateCorporateModel;
	}
}