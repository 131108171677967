import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetGroupProductVariantPricesRequest {
	groupId: string = '';
	productIdentities: string[];
	productVariantIdentities: string[] = [];
	pagination = new PaginationDto();

	constructor(groupId: string, productIdentities: string[], pagination: PaginationDto, productVariantIdentities?: string[]) {
		this.groupId = groupId;
		this.productIdentities = productIdentities;
		if (!!productVariantIdentities) this.productVariantIdentities = productVariantIdentities;
		if (!!pagination) this.pagination = pagination;
	}

	get getConvertUriExtension() {
		return `group/get-list`;
	}
}
