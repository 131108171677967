export class GetDtfListRequest {
	id: string;

	constructor(id: string) {
		this.id = id;
	}

	get getConvertUriExtension() {
		return `get-dtf-list-details?id=${this.id}`;
	}
}