import React from 'react';
import { CreateOrderContext, CreateOrderContextType } from '../../context/CreateOrderContextPrvider';
import { ShipForm } from './components/ship-form/ShipForm';
import { ShipSummary } from './components/ship-summary/ShipSummary';

export const StepShipping = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

	return !context.isShowShippingForm && !!context.calculatedPrice ? <ShipSummary /> : <ShipForm />;
};
