import { GetAccountCompletePercentageDto } from 'features/sellers/_common/get-account-complete-percentage/dtos/get-account-complete-percentage.dto';
import { GetAccountCompletePercentageRequest } from 'features/sellers/_common/get-account-complete-percentage/get-account-complete-percentage.request';
import sellerService from 'features/sellers/_common/seller.service';
import { GetOrCreateCustomerIdRequest } from 'features/stripe/_common/get-or-create-customer-id/get-or-create-customer-id.request';
import stripeService from 'features/stripe/_common/stripe.service';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const AccountCompleteInformationWidget = () => {
	const navigate = useNavigate();
	const user = useAppSelector((state) => state.auth.data?.user);
	const [loading, setLoading] = useState<boolean>(false);
	const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
	const [percentage, setPercentage] = useState<GetAccountCompletePercentageDto | undefined>(undefined);
	const [chartData, setChartData] = useState<object | undefined>(undefined);

	const fillChartData = (pData: GetAccountCompletePercentageDto) => {
		setChartData({
			labels: ['Uncompleted', 'Completed'],
			datasets: [
				{
					data: [100 - pData.percentage, pData.percentage],
					backgroundColor: ['#cbcbcb', '#66BB6A'],
					hoverBackgroundColor: ['#d7d5d5', '#81C784']
				}
			]
		});
	};

	const getSellerPercentage = async () => {
		try {
			setLoading(true);
			if (!user || !user.roles.isSeller) throw '';

			const request = new GetAccountCompletePercentageRequest(user.userId);
			const response = await sellerService.getAccountCompletePercentage(request);
			if (!response || !response.isSuccess || !response.data) throw '';

			const responseData = response.data.at(0);
			if (!responseData) throw '';

			setPercentage(responseData);
			fillChartData(responseData);
		} catch (error) {
			setPercentage(undefined);
			setChartData(undefined);
		} finally {
			setLoading(false);
		}
	};

	const onPaymentAdd = async () => {
		try {
			if (!user) throw '';

			setPaymentLoading(true);

			const request = new GetOrCreateCustomerIdRequest(user?.userId);

			const response = await stripeService.getOrCreateCustomerId(request);

			if (!response.isSuccess) throw '';

			getSellerPercentage();
		} finally {
			setPaymentLoading(false);
		}
	};

	useEffect(() => {
		getSellerPercentage();
	}, [user]);

	return (
		<div className="card">
			{loading ? (
				<Skeleton height="20rem" />
			) : (
				<React.Fragment>
					<h5>Account Complete Information</h5>
					<div className="grid">
						<div className="sm:col-12 md:col-6">{!!percentage && !!chartData ? <Chart type="pie" data={chartData} style={{ position: 'relative' }} className="w-full max-w-30rem" /> : null}</div>
						<div className="sm:col-12 md:col-6 mt-4">
							<div className="flex flex-column gap-3">
								<Button onClick={(_) => navigate('/account/details')} disabled={percentage?.isAddedAPhoneNumber} label="Add a phone number"></Button>
								<Button onClick={(_) => navigate('/account/details')} disabled={percentage?.isAddedAnAddress} label="Add an address"></Button>
								<Button onClick={(_) => navigate('/account/my-stores')} disabled={percentage?.isAddedAStore} label="Add a store"></Button>
								<Button onClick={(_) => navigate('/account/my-stores')} disabled={percentage?.isAddedAConnectionTheStore} label="Connect your store"></Button>
								<Button onClick={(_) => onPaymentAdd()} loading={paymentLoading} disabled={percentage?.isAddedAPaymentConnection} label="Complete Account"></Button>
							</div>
							<hr className="my-4" />
							{percentage?.percentage === 100 && (
								<div>
									<h5 className="text-indigo-600">Congratulations!</h5>
									<p>You have saved all of your account information. You are ready to design your products and sell them in your stores.</p>
									<div className="text-right">
										<Button onClick={() => navigate('/product/catalog')} label="Start Order" icon="pi pi-arrow-right" />
									</div>
								</div>
							)}
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default AccountCompleteInformationWidget;
