import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import currencyHelper from 'helpers/curreny.helper';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import { Tooltip } from 'primereact/tooltip';
import styles from './ReOrderProductsTable.module.scss'
import React, { useState } from 'react';

import { SellerReorderDetailContext, SellerReorderDetailContextType } from '../../../context/SellerReorderDetailContextProvider';
import { OrderItemDetail } from 'features/reorders/_common/get-reorder-detail/get-reorder-detail.response';

export const ReOrderProductsTable = () => {
	const context = React.useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	const calculateSidePrice = (item: OrderItemDetail) => {
		let price = item.newItem.price.pricePerUnit;

		for (const side of item.newItem.sides) price += side.printPrice.pricePerUnit;

		return price / 100;
	};

	return (
		<React.Fragment>
			<table className={styles.table}>
				<thead>
					<tr>
						<th>Product</th>
						<th>Print File</th>
						<th>QTY</th>
						<th>Retail Each</th>
						<th>Total Price</th>
					</tr>
				</thead>

				<tbody>
					{context?.reorder?.orderItemDetails.map((_item, _itemIndex) => (
						<React.Fragment key={_itemIndex}>
							{_item.extraProductCostAccepted ? (
								<tr className={styles.info}>
									<td colSpan={5}>
										<div className="text-green-600 font-bold flex gap-3 align-items-center">
											<span className={styles.new}>Approved</span> <span>&#x2022;</span> <span>Product cost accepted</span>
										</div>
									</td>
								</tr>
							) : null}

							<tr>
								<td>
									<div className={styles.productInfo}>
										<div className={styles.productImgContainer}>
											<img src={amazonS3Helper.showFile(_item.newItem.variantMedia, { resize: '100x100' })} alt={_item.newItem.coreProductName} width={80} />
										</div>

										<div className={styles.productInfoContainer}>
											<h6>{_item.newItem.coreProductName}</h6>

											<span>
												Model: {_item.newItem.brandName} {_item.newItem.modelName}
											</span>

											<span>Size: {_item.newItem.size.name}</span>

											<span className={styles.productInfoContainerColor}>
												<Tooltip target={`#color-${_itemIndex}-${_item.newItem.color.id}`} />
												Color:
												<span id={`color-${_itemIndex}-${_item.newItem.color.id}`} data-pr-tooltip={_item.newItem.color.hexCode} data-pr-position="top" style={{ backgroundColor: _item.newItem.color.hexCode }} />
												{_item.newItem.color.name}
											</span>
										</div>
									</div>
								</td>

								<td>
									<div className={styles.printFileWrapper}>
										{_item.newItem.sides.map((_side, sideIndex) => (
											<React.Fragment key={sideIndex}>
												<div className={styles.printFileItem}>
													<div className={styles.printFileItemImgContainer}>
														<img src={amazonS3Helper.showFile(_side.design, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Design`} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
													</div>

													<div className={styles.printFileItemInfo}>
														<span>{_side.printSideName} Side Print File</span>
													</div>
												</div>

												<div className={styles.printFileItem}>
													<div className={styles.printFileItemImgContainer}>
														<img src={amazonS3Helper.showFile(_side.templateMockup, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Mockup`} />
													</div>

													<div className={styles.printFileItemInfo}>
														<span>{_side.printSideName} Side Mockup</span>
													</div>
												</div>
											</React.Fragment>
										))}
									</div>
								</td>

								<td>
									<span style={{ lineHeight: '2.5rem', display: 'inline-block', marginRight: '2rem' }}>1</span>
								</td>

								<td>
									<PrPriceInput value={calculateSidePrice(_item)} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} />
									{/* <PrPriceInput value={context.calculatedPrice?.items.find((_itemPrice) => _itemPrice.uniqueIdentifier === _item.id)?.pricePerEach?.formattedPricePerUnit || 0} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} /> */}
								</td>

								<td>
									<span className="font-bold" style={{ lineHeight: '2.5rem', display: 'inline-block' }}>
										{currencyHelper.formatPrice(calculateSidePrice(_item) || 0)}
									</span>
								</td>
							</tr>
						</React.Fragment>
					))}
				</tbody>
			</table>
		</React.Fragment>
	);
};
