import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetSellerStoreListForAdminRequest {
	sellerId: string | null;
	contains: string | null;
	pagination: PaginationDto;

	get getConvertUriExtension() {
		return `get-seller-store-list-for-admin`;
	}

	constructor(sellerId: string | null, contains: string | null, pagination?: PaginationDto) {
		this.sellerId = sellerId;
		this.contains = contains;
		this.pagination = pagination || new PaginationDto();
	}
}