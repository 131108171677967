import { ErrorMessage, Form, Formik } from 'formik';
import { CreateColorRequest } from 'models/requests/colors/create-color-request';
import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import colorService from 'services/color-service';
import * as Yup from 'yup';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getColors: Function;
};

const ColorCreate = ({ isVisible, setVisible, getColors }: Props) => {
	const [selectedColor, setSelectedColor] = useState('');

	const initialValues: CreateColorRequest = { name: '', hexCode: '', imageUrl: '' };

	const validationScheme = Yup.object().shape({
		name: Yup.string().required('Color name is required').min(2, 'Name must be at least 2 characters.').max(100, 'Name must be a maximum of 100 characters.')
	});

	const submitForm = (values: CreateColorRequest) => {
		if (selectedColor === '') return;

		selectedColor.charAt(0) !== '#' ? (values.hexCode = '#' + selectedColor) : (values.hexCode = selectedColor);

		colorService.create(values).then((response) => {
			if (!response.isSuccess) return;

			setSelectedColor('');
			setVisible(false);
			getColors();
		});
	};

	return (
		<Dialog visible={isVisible} header="New Color" modal onHide={() => setVisible(false)}>
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div className="grid justify-content-center">
							<div className="col-12 lg:col-5">
								<ColorPicker value={selectedColor} onChange={(e: any) => setSelectedColor(e.value.toString())} inline className="flex align-items-center justify-content-center" />
							</div>

							<div className="col-12 lg:col-7 d-flex gap-4">
								<div className="col-12 mb-0 pb-0">
									<ErrorMessage name="hexCode" component="small" className="text-red font-medium" />
								</div>
								<div className="col-12">
									<InputText value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} placeholder="Enter hex code or select a color" className="w-full" />
								</div>

								<div className="col-12 mb-0 pb-0">
									<ErrorMessage name="name" component="small" className="text-red font-medium" />
								</div>
								<div className="col-12">
									<InputText name="name" value={values.name} onChange={handleChange} placeholder="Enter color name" className="w-full" />
								</div>

								<div className="col-12 mb-0 pb-0">
									<ErrorMessage name="imageUrl" component="small" className="text-red font-medium" />
								</div>
								<div className="col-12">
									<InputText name="imageUrl" value={values.imageUrl} onChange={handleChange} placeholder="Enter color image url" className="w-full" />
								</div>

								<div className="col-12">
									<Button type="submit" label="Create" className="w-full h-full" />
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ColorCreate;
