import { GetOrderForOperationRequest } from 'features/orders/_common/get-order-for-operation/get-order-for-operation.request';
import { OrderForPackagerDto } from 'features/orders/_common/get-order-for-operation/get-order-for-operation.response';
import orderService from 'features/orders/_common/order.service';
import { ReadyForShippingRequest } from 'features/orders/_common/ready-for-shipping/ready-for-shipping.request';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';

const ManuelBarcode = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [orders, setOrders] = useState<OrderForPackagerDto[]>([]);
	const [contains, setContains] = useState<string>('');

	const getAll = async () => {
		try {
			setLoading(true);

			if (!contains) {
				toastHelper.warning('Please enter order info and click to apply button or enter');
				throw '';
			}

			const response = await orderService.getOrderForOperation(new GetOrderForOperationRequest(contains));
			if (!response.isSuccess || !response.data) throw '';

			setOrders(response.data);
		} catch (error) {
			setOrders([]);
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="container-body static p-3">
			<SearchInput value={contains} onChange={(e) => setContains(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && getAll()} filterBtn disabled={loading} placeholder="Search orders" filterBtnOnClick={() => getAll()} />

			<DataTable
				dataKey="orderId"
				className="pr-table mt-3"
				selectionMode="single"
				showGridlines
				value={orders}
				disabled={loading}
				onSelectionChange={(event) => {
					const order: OrderForPackagerDto = event.value;

					confirmDialog({
						header: 'Confirm your selection',
						message: (
							<div className="flex flex-column gap-1">
								<div className="flex w-full gap-3">
									<div className="w-12rem">Code</div>
									<div
										className="w-full font-bold cursor-pointer"
										onClick={() => {
											if (!order) return;

											navigator.clipboard.writeText(order?.orderCode);
											toastHelper.success(`Copied '${order?.orderCode}' to clipboard`);
										}}>
										#{order.orderCode}
									</div>
								</div>

								<div className="flex w-full gap-3">
									<div className="w-12rem">Customer</div>
									<div className="w-full font-bold">{order.customerFullName}</div>
								</div>

								<div className="flex w-full gap-3">
									<div className="w-12rem">Store</div>
									<div className="w-full font-bold">{order.storeName}</div>
								</div>

								<div className="flex w-full gap-3">
									<div className="w-12rem">Track Number</div>
									<div className="w-full font-bold">{order.trackNumber}</div>
								</div>

								<div className="flex w-full gap-3">
									<div className="w-12rem">Status</div>
									<div className="w-full font-bold">{stringHelper.parseAtUpperCaseAndJoin(order.status.readableName)}</div>
								</div>

								<div className="mt-3">
									Are you sure you want to change the status of this order to <b>'Delivered To USPS'</b>?
								</div>
							</div>
						),
						icon: 'pi pi-info-circle',
						acceptLabel: 'Accept',
						rejectLabel: 'Cancel',
						acceptClassName: 'px-4',
						rejectClassName: 'p-button-text px-4',
						style: { maxWidth: '48rem' },
						accept: async () => {
							try {
								setLoading(true);

								const response = await orderService.setReadyForShipping(new ReadyForShippingRequest(order.orderCode, false));
								if (!response.isSuccess) throw '';

								await getAll();
							} catch (error) {
							} finally {
								setLoading(false);
							}
						}
					});
				}}>
				<Column header="Code" field="orderCode" />
				<Column header="Customer" field="customerFullName" />
				<Column header="Store" field="storeName" />
				<Column header="TrackNumber" field="trackNumber" />
				<Column header="Status" field="status.readableName" />
			</DataTable>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</section>
	);
};

export default ManuelBarcode;
