import { AssignOrderType } from "../enums/assign-order-type.enum";

export class DeleteAssignRequest {
	orderId: string;
	unAssignType: AssignOrderType | null;

	get getConvertUriExtension() {
		return `delete-assign`;
	}

	constructor(orderId: string, unAssignType: AssignOrderType | null) {
		this.orderId = orderId;
		this.unAssignType = unAssignType;
	}
}