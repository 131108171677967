import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import { useState } from 'react'
import OrderDetail from '../orders/OrderDetail';

const BarcodeScan = () => {
	const [barcode, setBarcode] = useState("")
	const [orderList, setOrderList] = useState(false)

	const onBarcodeChange = (value: string) => {
		setOrderList(false)
		setBarcode(value)
	}

	return (
		<div>
			<div className="card">
				<div className="col-12">
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<i className="pi pi-qrcode px-2"></i>
						</span>
						<InputText value={barcode} onChange={(e) => onBarcodeChange(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && (event.preventDefault(), setOrderList(true))} placeholder="Enter barcode number to get order detail" className="w-full text-lg" />
						<Button label="Get Order" onClick={() => setOrderList(true)} disabled={orderList} className="w-14rem h-4rem" />
					</div>
				</div>
			</div>

			{orderList && <OrderDetail barcode={barcode} setOrderList={setOrderList} />}
		</div>
	);
}

export default BarcodeScan