export class UpdateGroupRequest {
	id: string;
	name: string;
	description: string;
	isActive: boolean;

	constructor(props: Partial<UpdateGroupRequest>) {
		this.id = props.id || '';
		this.name = props.name || '';
		this.description = props.description || '';
		this.isActive = props.isActive || true;
	}

	get getConvertUriExtension() {
		return 'update-group';
	}
}
