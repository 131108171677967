export class DeleteStockLocationRequest {
	stockLocationId: string;

	get getConvertUriExtension() {
		return `delete-stock-location?Id=${this.stockLocationId}`;
	}

	constructor(id: string) {
		this.stockLocationId = id;
	}
}