import React from 'react';
import styles from './ProductList.module.scss';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { TbFilterOff } from 'react-icons/tb';
import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import { GetCoreProductForSynchronizeRequest } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.request';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';

type Props = {
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	brands: PrDropdownType[];
	setBrands: React.Dispatch<React.SetStateAction<PrDropdownType[]>>;
	selectedBrand: PrDropdownType | undefined;
	setSelectedBrand: React.Dispatch<React.SetStateAction<PrDropdownType | undefined>>;
	models: PrDropdownType[];
	setModels: React.Dispatch<React.SetStateAction<PrDropdownType[]>>;
	selectedModel: PrDropdownType | undefined;
	setSelectedModel: React.Dispatch<React.SetStateAction<PrDropdownType | undefined>>;
	selectedCategory: CategoryBaseDto | undefined;
	onSelectProduct: (product: CoreProductSynchronizeDto) => void;
	onGetProducts: (request: Partial<GetCoreProductForSynchronizeRequest>) => void;
	products: CoreProductSynchronizeDto[];
	search: string;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	filteredProducts: CoreProductSynchronizeDto[];
	setFilteredProducts: React.Dispatch<React.SetStateAction<CoreProductSynchronizeDto[]>>;
	clearFilter: Function;
};

const defaultPrDropdownValue: PrDropdownType = { label: 'All', value: 'All' };

export const ProductList = (props: Props) => {
	return (
		<React.Fragment>
			<div className="w-full">
				<SearchInput disabled={props.loading} value={props.search} onChange={e => props.setSearch(e.target.value)} />
			</div>

			<div className="flex gap-3 my-4 w-full">
				<PrDropdown
					value={props.selectedBrand}
					options={props.brands}
					filter
					disabled={props.loading}
					placeholder="Select Brand"
					className={styles.filterDropdown}
					onChange={(event) => {
						const value = event.value as string;

						if (!value) return;

						props.setSelectedBrand(event);

						props.onGetProducts({
							includeModels: value !== defaultPrDropdownValue.value,
							categoryId: props.selectedCategory?.id || null,
							brandId: value !== defaultPrDropdownValue.value ? value : null
						});

						props.setModels([]);
						props.setSelectedModel(undefined);
						props.setFilteredProducts([])
						props.setSearch("")
					}}
				/>

				<PrDropdown
					value={props.selectedModel}
					options={props.models}
					filter
					disabled={props.loading}
					placeholder="Select Model"
					className={styles.filterDropdown}
					onChange={(event) => {
						const value = event.value as string;

						if (!value) return;

						props.setSelectedModel(event);

						props.onGetProducts({
							brandId: props.selectedBrand?.value.toString() || null,
							categoryId: props.selectedCategory?.id || null,
							modelId: value !== defaultPrDropdownValue.value ? value : null
						});

						props.setFilteredProducts([]);
						props.setSearch('');
					}}
				/>

				<PrButton icon={<TbFilterOff size={18} />} onClick={() => props.clearFilter()} loading={props.loading} text="Clear" className={styles.clearFilterBtn} />
			</div>

			<div className={styles.productWrapper}>
				{props.loading ? (
					<h5 className="m-0 py-4 px-2 flex align-items-center font-semibold text-color-secondary justify-content-center surface-ground pointer-events-none">Loading...</h5>
				) : (
					(props.filteredProducts.length > 0 ? props.filteredProducts : props.products).map((_product, index) => (
						<div key={index} role="button" onClick={() => props.onSelectProduct(_product)} className={styles.productItem}>
							<div className={styles.imgDivider}>
								<img src={amazonS3Helper.showFile(_product.variants[0]?.medias[0], { resize: '80x80' })} loading="lazy" alt={_product.productName} height={80} />
							</div>

							<div className={styles.productItemInfo}>
								<span>{_product.categoryName}</span>

								<span className="mt-1">
									{_product.productName} - {_product.model.brand.name} {_product.model.name}
								</span>
							</div>
						</div>
					))
				)}
			</div>
		</React.Fragment>
	);
};
