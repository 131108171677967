import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import { PaymentTypes } from 'features/orders/dtos/payment-type.dto';
import { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';

export const prefixOptions: PrDropdownType[] = [
	{ label: '+1', value: '+1' },
	{ label: '+90', value: '+90' }
];

export interface AddressBaseDto {
	firstLine: string;
	secondLine: string | null;
	city: string;
	state: string;
	zip: string;
	countryIso2: string;
	formattedAddress: string;
}

export class CreateManuelOrderDeliveryAddressRequestDto {
	firstLine: string;
	secondLine: string | null;
	city: string;
	state: string;
	zip: string;
	countryIso: string;
	formattedAddress: string;
	receiverFullName: string;
	receiverEmail: string;
	receiverPhoneNumber: string | null;
	phonePrefix: PrDropdownType;
	vatNumber: string | null;

	constructor(props: Partial<CreateManuelOrderDeliveryAddressRequestDto>) {
		this.firstLine = props.firstLine || '';
		this.secondLine = props.secondLine || null;
		this.city = props.city || '';
		this.state = props.state || '';
		this.zip = props.zip || '';
		this.countryIso = props.countryIso || '';
		this.formattedAddress = props.formattedAddress || '';
		this.receiverFullName = props.receiverFullName || '';
		this.receiverEmail = props.receiverEmail || '';
		this.receiverPhoneNumber = props.receiverPhoneNumber || null;
		this.phonePrefix = props.phonePrefix || prefixOptions[0];
		this.vatNumber = props.vatNumber || null;
	}
}

export enum DeliveryTypes {
	Unknown = 0,
	Shipment = 1,
	PickUp = 2
}

export class CreateManuelOrderGiftInformationRequestDto {
	message: string;

	constructor(props: Partial<CreateManuelOrderGiftInformationRequestDto>) {
		this.message = props.message || '';
	}
}

export class CreateManuelOrderItemSideRequestDto {
	productPrintSideId: string | undefined;
	designFile: File | undefined;
	templateMockupFile: File | undefined;
	printWidth: number | undefined;
	printHeight: number | undefined;

	constructor(props: Partial<CreateManuelOrderItemSideRequestDto>) {
		this.productPrintSideId = props.productPrintSideId;
		this.designFile = props.designFile;
		this.templateMockupFile = props.templateMockupFile;
		this.printWidth = props.printWidth;
		this.printHeight = props.printHeight;
	}
}

export class CreateManuelOrderItemRequestDto {
	uniqueId: string;
	productId: string;
	productVariantId: string;
	quantity: number;
	sides: CreateManuelOrderItemSideRequestDto[];

	constructor(props: Partial<CreateManuelOrderItemRequestDto>) {
		this.uniqueId = props.uniqueId || '';
		this.productId = props.productId || '';
		this.productVariantId = props.productVariantId || '';
		this.quantity = props.quantity || 1;
		this.sides = props.sides || [];
	}
}

export class ShipmentType {
	shippoShipmentId: string;
	shippoRateId: string;

	constructor(props: Partial<ShipmentType>) {
		this.shippoShipmentId = props.shippoShipmentId || '';
		this.shippoRateId = props.shippoRateId || '';
	}
}

export class CreateManuelOrderRequest {
	userId: string;
	storeId: string;
	customerFullName: string;
	customerNote: string | null;
	sellerNote: string | null;
	deliveryType: DeliveryTypes;
	deliveryAddress: CreateManuelOrderDeliveryAddressRequestDto | null;
	giftInformation: CreateManuelOrderGiftInformationRequestDto | null;
	items: CreateManuelOrderItemRequestDto[];
	paymentMethodType: PaymentTypes;
	shippingRate: ShippingRateDto | null;
	attachmentFiles: File[] | null;

	constructor(props: Partial<CreateManuelOrderRequest>) {
		this.userId = props.userId || '';
		this.storeId = props.storeId || '';
		this.customerFullName = props.customerFullName || '';
		this.customerNote = props.customerNote || null;
		this.sellerNote = props.sellerNote || null;
		this.deliveryType = props.deliveryType || DeliveryTypes.Shipment;
		this.deliveryAddress = props.deliveryAddress || new CreateManuelOrderDeliveryAddressRequestDto({});
		this.giftInformation = props.giftInformation || null;
		this.items = props.items || [];
		this.paymentMethodType = props.paymentMethodType || 2;
		this.shippingRate = props.shippingRate || null;
		this.attachmentFiles = props.attachmentFiles || null;
	}

	get getConvertUriExtension() {
		return `create-manuel-order`;
	}
}
