import React from 'react';

const GildanCareInstructions = () => {
	return (
		<React.Fragment>
			<div className="flex flex-wrap">
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/machine-wash-cold.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/non-chlorine-bleach-as-needed.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/tumble-dry-low-heat.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/do-not-iron.png" />
				<img loading="lazy" alt="Care set icon" width="55" src="/pngs/care-instructions/do-not-dry-clean.png" />
			</div>

			<p className="text-lg">Machine wash: cold (max 30C or 90F); Non-chlorine: bleach as needed; Tumble dry: low heat; Do not iron; Do not dry clean.</p>
		</React.Fragment>
	);
};

export default GildanCareInstructions;
