import { DataResponse } from '../../../models/_commons/responses/data-response';
import HttpBaseService from '../../../services/_common/http-base-service';
import { CreateEtsySynchronizeProductsRequest } from './create-etsy-synchronize-products/create-etsy-sycnhronize-product.request';
import { GetEtsyInformationsForPushRequest } from './get-etsy-informations-for-push/get-etsy-informations-for-push.request';
import { GetEtsyInformationsForPushResponse } from './get-etsy-informations-for-push/get-etsy-informations-for-push.response';
import { GetEtsyShippingProfilesByShopIdRequest } from './get-etsy-shipping-profiles-by-shop-id/get-etsy-shipping-profiles-by-shop-id.request';
import { GetEtsyShippingProfilesByShopIdResponse } from './get-etsy-shipping-profiles-by-shop-id/get-etsy-shipping-profiles-by-shop-id.response';
import { GetEtsyShopSectionsByShopIdRequest } from './get-etsy-shop-sections-by-shop-id/get-etsy-shop-sections-by-shop-id.request';
import { GetEtsyShopSectionsByShopIdResponse } from './get-etsy-shop-sections-by-shop-id/get-etsy-shop-sections-by-shop-id.response';
import { PushEtsyListingRequest } from './push-etsy-listing/push-etsy-listing.request';
import { PushEtsyListingResponse } from './push-etsy-listing/push-etsy-listing.response';
import { SearchOrdersRequest } from './search-orders/search-orders.request';
import { CreateEtsySynchronizeProductsResponse } from './create-etsy-synchronize-products/create-etsy-synchronize-product.response';
import { SearchOrdersResponse } from './search-orders/search-orders.response';
import { GetEtsyTaxonomiesRequest } from './get-etsy-taxonomies/get-etsy-taxonomies.request';
import { GetEtsyTaxonomiesResponse } from './get-etsy-taxonomies/get-etsy-taxonomies.response';
import { GetActiveEtsyAuthenticationsRequest } from './get-active-etsy-authentications/get-active-etsy-authentications.request';
import { GetActiveEtsyAuthenticationsResponse } from './get-active-etsy-authentications/get-active-etsy-authentications.response';
import { DeleteAuthenticationRequest } from './delete-authentication/delete-authentication.request';
import { CreateEtsySynchronizeProductsWithSidesRequest } from './create-etsy-synchronize-products-with-sides/create-etsy-synchronize-products-with-sides.request';
import { CreateEtsySynchronizeProductsWithSidesResponse } from './create-etsy-synchronize-products-with-sides/create-etsy-synchronize-products-with-sides.response';
import { CheckEtsyAuthenticationRequest } from './check-etsy-authentication/check-etsy-authentication.request';
import { CheckEtsyAuthenticationResponse } from './check-etsy-authentication/check-etsy-authentication.response';

class IntegrationsService extends HttpBaseService {
	constructor() {
		super('integrations');
	}

	async getEtsyInformationsForPush(request: GetEtsyInformationsForPushRequest): Promise<DataResponse<GetEtsyInformationsForPushResponse>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async pushEtsyListing(request: PushEtsyListingRequest): Promise<PushEtsyListingResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getEtsyTaxonomies(request: GetEtsyTaxonomiesRequest): Promise<GetEtsyTaxonomiesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getEtsyShopSectionsByShopId(request: GetEtsyShopSectionsByShopIdRequest): Promise<DataResponse<GetEtsyShopSectionsByShopIdResponse[]>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getEtsyShippingProfilesByShopId(request: GetEtsyShippingProfilesByShopIdRequest): Promise<GetEtsyShippingProfilesByShopIdResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createSynchronizeProducts(request: CreateEtsySynchronizeProductsRequest, uploadAction: (state: ProgressEvent) => void): Promise<CreateEtsySynchronizeProductsResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}

	async createSynchronizeProductsWithSides(request: CreateEtsySynchronizeProductsWithSidesRequest, uploadAction: (state: ProgressEvent) => void): Promise<CreateEtsySynchronizeProductsWithSidesResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}

	async searchOrders(request: SearchOrdersRequest): Promise<SearchOrdersResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getActiveEtsyAuthentications(request: GetActiveEtsyAuthenticationsRequest): Promise<GetActiveEtsyAuthenticationsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async deleteAuthentication(request: DeleteAuthenticationRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension, request.userId);
	}

	async checkEtsyAuthentication(request: CheckEtsyAuthenticationRequest): Promise<CheckEtsyAuthenticationResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new IntegrationsService();
