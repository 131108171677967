import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { CreateOrUpdateProductVariantPricesForGroupRequest } from 'features/products/derived-features/product-variant-prices/create-or-update-product-variant-prices-for-group/create-or-update-product-variant-prices-for-group.request';
import { ProductVariantAndPrice } from 'features/products/derived-features/product-variant-prices/create-or-update-product-variant-prices/create-or-update-product-variant-prices.request';
import { DeleteGroupProductVariantPricesRequest } from 'features/products/derived-features/product-variant-prices/delete-group-product-variant-prices/delete-group-product-variant-prices.request';
import { GroupVariantPrice } from 'features/products/derived-features/product-variant-prices/get-group-product-variant-prices/dtos/group-variant-price.dto';
import { GetGroupProductVariantPricesRequest } from 'features/products/derived-features/product-variant-prices/get-group-product-variant-prices/get-group-product-variant-prices.request';
import productVariantPricesService from 'features/products/derived-features/product-variant-prices/product-variant-prices.service';
import { VariantSummary } from 'features/products/derived-features/product-variants/get-variant-summaries/dtos/variant-summary.dto';
import { GetVariantSummariesRequest } from 'features/products/derived-features/product-variants/get-variant-summaries/get-variant-summaries.request';
import productVariantService from 'features/products/derived-features/product-variants/product-variant.service';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Pagination } from 'models/_commons/responses/pagination';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styles from './GroupPrices.module.scss'
import currencyHelper from 'helpers/curreny.helper';
import { Paginator } from 'primereact/paginator';
import { SelectPrintramProductDialogNew } from 'components/_common/modals/select-printram-product-new/SelectPrintramProductDialogNew';
import { ProgressSpinner } from 'primereact/progressspinner';

const GroupPrices = () => {
	const { groupId } = useParams();
	const navigate = useNavigate();
	const priceOpRef = useRef<OverlayPanel>(null);

	const [variants, setVariants] = useState<VariantSummary[]>([]);
	const [viewVariants, setViewVariants] = useState<VariantSummary[]>([]);
	const [copiedVariants, setCopiedVariants] = useState<VariantSummary[]>([]);
	const [groupPrices, setGroupPrices] = useState<GroupVariantPrice[]>([]);
	const [sizes, setSizes] = useState<PrDropdownType[]>([]);
	const [selectedSize, setSelectedSize] = useState<PrDropdownType>();
	const [colors, setColors] = useState<PrDropdownType[]>([]);
	const [selectedColor, setSelectedColor] = useState<PrDropdownType>();
	const [filters, setFilters] = useState([
		{ name: 'size', value: '' },
		{ name: 'color', value: '' }
	]);
	const [selectedProduct, setSelectedProduct] = useState<PrDropdownType>();
	const [updatedPrices, setUpdatedPrices] = useState<ProductVariantAndPrice[]>([]);
	const [allPrice, setAllPrice] = useState<number>(0);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [pricesLoading, setPricesLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [paginator, setPaginator] = useState<PaginationDto>({ pageNumber: 1, itemCount: 40, orderBy: 2, first: 0 });
	const [visible, setVisible] = useState<boolean>(false);

	const getVariants = async (pagination: PaginationDto) => {
		try {
			if (!selectedProduct) throw '';

			setLoading(true);

			const request = new GetVariantSummariesRequest([selectedProduct.value.toString()], pagination);

			const response = await productVariantService.getVariantSummaries(request);

			if (!response.isSuccess) throw '';

			const formattedVariants = response.data?.variants?.data?.map((_vari: VariantSummary) => ({ ..._vari, size: _vari.size.name }));
			setVariants(formattedVariants || ([] as any));
			setCopiedVariants(formattedVariants || ([] as any));

			const first = paginator.first;
			const count = paginator.itemCount || 0;
			const firstCount = first + count;
			if (formattedVariants) setViewVariants(formattedVariants.filter((_vari, index) => index >= first && index < firstCount) as any);

			const sizeOptions = response.data?.sizes.map((size) => ({ label: size, value: size }));
			setSizes([{ label: 'All', value: '' }, ...(sizeOptions || [])]);

			const colorOptions = response.data?.colors.map((color) => ({ label: color, value: color }));
			setColors([{ label: 'All', value: '' }, ...(colorOptions || [])]);

			setPaginationResponse(response.data?.variants.pagination);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const getGroupPrices = async (pagination: PaginationDto) => {
		try {
			if (!selectedProduct || !groupId) throw '';

			setPricesLoading(true);

			const request = new GetGroupProductVariantPricesRequest(groupId, [selectedProduct.value.toString()], pagination);

			const response = await productVariantPricesService.getGroupProductVariantPrices(request);

			if (!response.isSuccess) throw '';

			setGroupPrices(response.data || []);
		} catch (error) {
		} finally {
			setPricesLoading(false);
		}
	};

	const changePrice = (variantId: string, value: number) => {
		const isExist = updatedPrices.find((_price) => _price.productVariantId === variantId);

		if (isExist) {
			setUpdatedPrices(
				updatedPrices.map((_price) => {
					if (_price.productVariantId === variantId) {
						return { ..._price, price: { pricePerUnit: Math.round(parseFloat(value.toString()) * 100) } };
					} else return _price;
				})
			);
		} else {
			setUpdatedPrices([...updatedPrices, { productVariantId: variantId, price: { pricePerUnit: Math.round(parseFloat(value.toString()) * 100) } }]);
		}
	};

	// Çoklu seçim state update için recursive func
	const changePrices = (newUpdatedPrices: ProductVariantAndPrice[], index: number, newSetUpdatePrices: ProductVariantAndPrice[]) => {
		if (index === newUpdatedPrices.length) {
			setUpdatedPrices(newSetUpdatePrices);
			return;
		}

		const variantId = newUpdatedPrices[index].productVariantId;

		const isExist = updatedPrices.find((_price) => _price.productVariantId === variantId);

		if (isExist) {
			newSetUpdatePrices = newUpdatedPrices.map((_price) => {
				if (_price.productVariantId === variantId) {
					return { ..._price, price: { pricePerUnit: Math.round(parseFloat(allPrice.toString()) * 100) } };
				} else return _price;
			});
		} else {
			newSetUpdatePrices = [...newSetUpdatePrices, { productVariantId: variantId, price: { pricePerUnit: Math.round(parseFloat(allPrice.toString()) * 100) } }];
		}

		changePrices(newUpdatedPrices, ++index, newSetUpdatePrices);
	};

	const applyAll = () => {
		const newUpdatedPrices: ProductVariantAndPrice[] = viewVariants.map((_vari) => ({ productVariantId: _vari.id, price: { pricePerUnit: Math.round(parseFloat(allPrice.toString()) * 100) } }));

		if (newUpdatedPrices.length === 0) return;

		changePrices(newUpdatedPrices, 0, updatedPrices);
	};

	const deleteAll = async () => {
		try {
			setLoading(true);

			const formattedGroupPrices = groupPrices.map((_price) => ({ ..._price, sizeName: variants.find((_vari) => _vari.id === _price.productVariantId)?.size, colorName: variants.find((_vari) => _vari.id === _price.productVariantId)?.color }));
			const sizeName = filters.find((filter) => filter.name === 'size')?.value || undefined;
			const colorName = filters.find((filter) => filter.name === 'color')?.value || undefined;

			const query = { sizeName: sizeName || undefined, colorName: colorName || undefined } as any;

			const trimQuery = Object.entries(query)
				.filter(([key, value]) => value !== undefined)
				.reduce((obj: any, [key, value]) => {
					obj[key] = value;
					return obj;
				}, {});

			const deletedPrices = formattedGroupPrices?.filter((item: any) => {
				for (let key in trimQuery) {
					if (item[key] === undefined || item[key] != trimQuery[key]) return false;
				}
				return true;
			});

			const delRequest = deletedPrices.map((_delete) => _delete.id);

			const request = new DeleteGroupProductVariantPricesRequest(delRequest);

			const response = await productVariantPricesService.deleteGroupProductVariantPrice(request);

			if (!response.isSuccess) throw '';

			setUpdatedPrices([]);
			setSelectedSize(undefined);
			setSelectedColor(undefined);
			getVariants({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
			getGroupPrices({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
		} catch (error) {
			setLoading(false);
		} finally {
		}
	};

	const savePrices = async () => {
		try {
			if (!groupId) throw '';

			setLoading(true);

			const request = new CreateOrUpdateProductVariantPricesForGroupRequest(groupId, updatedPrices);

			const response = await productVariantPricesService.createOrUpdateGroupProductVariantPrices(request);

			if (!response.isSuccess) throw '';

			setUpdatedPrices([]);
			setSelectedSize(undefined);
			setSelectedColor(undefined);
			getVariants({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
			getGroupPrices({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
		} catch (error) {
			setLoading(false);
		} finally {
		}
	};

			const groupPriceHandler = (rowData: VariantSummary) => {
				const groupPrice = groupPrices.find((_variant) => _variant.productVariantId === rowData.id)?.price.formattedPricePerUnit;
				const printramPrice = viewVariants.find((_variant) => _variant.id === rowData.id)?.printramPrice.formattedPricePerUnit;
				const updatePrice = updatedPrices.find((_price) => _price.productVariantId === rowData.id)?.price.pricePerUnit;
				const formattedUpdatedPrice = updatePrice ? updatePrice / 100 : 0;

				const inputStyle = { backgroundColor: 'white', backgroundImage: 'none', borderWidth: 1, borderColor: 'lightgray', borderRadius: '.5rem', height: '2.4rem', width: '8rem', color: formattedUpdatedPrice ? 'green' : groupPrice ? 'black' : 'gray', fontWeight: formattedUpdatedPrice || groupPrice ? 'bold' : 'normal' };
				return (
					<div>
						<InputNumber value={formattedUpdatedPrice || groupPrice || printramPrice} onChange={(e) => changePrice(rowData.id, e.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} inputStyle={inputStyle} />
					</div>
				);
			};


		const deleteConfirmTemplate = () => (
			<div>
				<div>
					<span>Selected Size: </span>
					<span className="font-bold">{selectedSize?.label || 'All Sizes'}</span>
				</div>

				<div>
					<span>Selected Color: </span>
					<span className="font-bold">{selectedColor?.label || 'All Colors'}</span>
				</div>

				<hr />
				<span>All group-specific prices on selected variants of this product will be deleted. Do you approve?</span>
			</div>
	);

		const showDeleteConfirm = () => {
			confirmDialog({
				header: 'Delete Prices Confirmation',
				icon: 'pi pi-info-circle',
				message: deleteConfirmTemplate(),
				acceptLabel: 'Confirm',
				acceptClassName: 'px-4 bg-blue-600',
				rejectClassName: 'hidden',
				className: 'max-w-30rem',
				accept: () => deleteAll()
			});
		};


		const groupPriceHeader = () => {
			const inputStyle = { backgroundColor: 'white', backgroundImage: 'none', borderWidth: 1, borderColor: 'lightgray', borderRadius: '.5rem', height: '2.4rem', width: '8rem', color: 'black' };
			return (
				<React.Fragment>
					<span>Group Price</span>
					<i className="pi pi-fw pi-cog text-blue-700 ml-1 cursor-pointer" onClick={(e) => priceOpRef.current?.toggle(e)} />
					<OverlayPanel ref={priceOpRef}>
						<div className={styles.groupPriceOverlay}>
							<InputNumber onChange={(e) => setAllPrice(e.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} inputStyle={inputStyle} />
							<PrButton
								text="Apply All"
								onClick={() => {
									applyAll();
									priceOpRef.current?.hide();
								}}
							/>
						</div>
						<hr />
						<PrButton text="Delete All Group Prices" onClick={showDeleteConfirm} type="secondary" icon={<span className="pi pi-fw pi-trash" />} disabled={groupPrices.length === 0} className="w-full mt-4" />
					</OverlayPanel>
				</React.Fragment>
			);
	};

		const handleSizeChange = (event: PrDropdownType) => {
			setSelectedSize(event);

			setFilters(
				filters.map((filter) => {
					if (filter.name === 'size') return { ...filter, value: event.value.toString() };
					else return filter;
				})
			);
		};

		const handleColorChange = (event: PrDropdownType) => {
			setSelectedColor(event);

			setFilters(
				filters.map((filter) => {
					if (filter.name === 'color') return { ...filter, value: event.value.toString() };
					else return filter;
				})
			);
	};

		useEffect(() => {
			if (!paginator || !variants) return;

			const first = paginator.first;
			const count = paginator.itemCount || 0;
			const firstCount = first + count;

			setViewVariants(variants.filter((_vari, index) => index >= first && index < firstCount));
		}, [paginator]);

		useEffect(() => {
			if (!copiedVariants) return;

			const oldVariants = copiedVariants;

			const sizeName = filters.find((filter) => filter.name === 'size')?.value || undefined;
			const colorName = filters.find((filter) => filter.name === 'color')?.value || undefined;

			const query = { size: sizeName || undefined, color: colorName || undefined } as any;

			const trimQuery = Object.entries(query)
				.filter(([key, value]) => value !== undefined)
				.reduce((obj: any, [key, value]) => {
					obj[key] = value;
					return obj;
				}, {});

			const newVariants = oldVariants?.filter((item: any) => {
				for (let key in trimQuery) {
					if (item[key] === undefined || item[key] != trimQuery[key]) return false;
				}
				return true;
			});

			setVariants(newVariants);

			const first = paginator.first;
			const count = paginator.itemCount || 0;
			const firstCount = first + count;

			setViewVariants(newVariants.filter((_vari, index) => index >= first && index < firstCount));
		}, [filters]);

		useEffect(() => {
			if (!selectedProduct) return;

			setSelectedColor(undefined);
			setSelectedSize(undefined);
			setSizes([]);
			setColors([]);
			getVariants({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
			getGroupPrices({ pageNumber: 1, itemCount: null, orderBy: 2, first: 0 });
		}, [selectedProduct]);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Group Prices</h1>

				<div className="container-header-tools">
					<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />
				</div>
			</div>

			<div className="container-body p-3 z-1">
				<div className="w-full">
					<PrButton text={selectedProduct?.label.toString() || 'Select Product'} type="secondary" onClick={() => setVisible(true)} />
				</div>

				<hr />
				<div className={styles.filters}>
					{sizes.length > 0 && (
						<div className={styles.filter}>
							<span>Select Size</span>
							<PrDropdown options={sizes} value={selectedSize} onChange={handleSizeChange} filter disabled={loading} placeholder="Select Size" />
						</div>
					)}

					{colors.length > 0 && (
						<div className={styles.filter}>
							<span>Select Color</span>
							<PrDropdown options={colors} value={selectedColor} onChange={handleColorChange} filter disabled={loading} placeholder="Select Color" />
						</div>
					)}

					{updatedPrices.length > 0 && (
						<div className={styles.savePrices}>
							<PrButton text="Save Prices" icon={<span className="pi pi-fw pi-save" />} onClick={savePrices} />
						</div>
					)}
				</div>
			</div>

			<div className="container-body p-0">
				<div className={styles.tableWrapper}>
					<div className="px-4">
						<div className={styles.tableHeader}>
							<div className={styles.tableHeaderTitles}>
								<span className={styles.tableHeaderTitlesName}>Variant Name</span>
								<span className={styles.tableHeaderTitlesPrintramPrice}>Printram Price</span>
								<div className={styles.tableHeaderTitlesGroupPrice}>{groupPriceHeader()}</div>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.tableBody}>
					{viewVariants.length > 0 ? (
						viewVariants.map((_vari, index) => (
							<div className={`${styles.tableBodyItemWrapper} ${updatedPrices.find((_upd) => _upd.productVariantId === _vari.id) && styles.selected}`} key={index}>
								<div className="px-4">
									<div className={styles.tableBodyWrapper}>
										<div className={styles.tableBodyItems}>
											<div className={styles.tableBodyItemName}>
												<span>{_vari.variant}</span>
											</div>
											<div className={styles.tableBodyItemPrintramPrice}>
												<span>{currencyHelper.formatPrice(_vari.printramPrice.formattedPricePerUnit)}</span>
											</div>
											<div className={styles.tableBodyItemSellerPrice}>
												<span>{groupPriceHandler(_vari)}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div className={`${styles.tableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any product variants yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && variants.length > 0 ? (
						<div className={`${styles.tableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={paginator?.first || 0}
								rows={paginator?.itemCount || 40}
								totalRecords={variants.length}
								rowsPerPageOptions={[20, 40, 60]}
								onPageChange={(event) => {
									setPaginator({ first: event.first, pageNumber: event.page, itemCount: event.rows, orderBy: 2 });
								}}
							/>
						</div>
					) : null}
				</div>
			</div>

			<SelectPrintramProductDialogNew
				visible={visible}
				setVisible={setVisible}
				handleProductClick={(_product) => {
					setSelectedProduct({ label: _product.productName, value: _product.id });
				}}
			/>

			{/* <SelectPrintramProductDialog
				visible={visible}
				setVisible={setVisible}
				productId={''}
				onlySelectProduct
				handleProductClick={(product) => {
					if (!product) return;

					setSelectedProduct({ label: product.productName, value: product.id })
				}}
			/> */}

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
}

export default GroupPrices