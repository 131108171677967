import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetApprovedCoreProductsForListRequest {
	pagination: PaginationDto = new PaginationDto();

	get getConvertUriExtension() {
		return `get-approved-core-products-for-list`;
	}

	constructor(pagination?: PaginationDto) {
		if (!!pagination) this.pagination = pagination;
	}
}