import { Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import * as Yup from 'yup';
import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadRemoveParams, FileUploadSelectParams } from 'primereact/fileupload';
import { CreateTicketRequest } from 'features/tickets/_common/create-ticket/create-ticket.request';
import toastHelper from 'helpers/toast.helper';
import ticketsService from 'features/tickets/_common/tickets.service';
import { CreateImageRequest } from 'features/editors/_common/create-image/create-image.request';
import editorService from 'features/editors/_common/editor.service';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ProgressSpinner } from 'primereact/progressspinner';

type props = {
	createVisible: boolean;
	setCreateVisible: Function;
	getTickets?: Function;
	orderId?: string;
}
const CreateTicket = ({ createVisible, setCreateVisible, getTickets, orderId }: props) => {
	const [loading, setLoading] = useState(false)
	const [imageloading, setImageLoading] = useState<boolean>(false)
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

	const initialValues: CreateTicketRequest = { title: "", description: "", ticketType: 1 } as any

	const validationSchema = Yup.object().shape({
		title: Yup.string().required("Title is required"),
		description: Yup.string().required("Description is required")
	})

	const onSave = async(values: CreateTicketRequest) => {
		try {
			if (imageloading) {
				toastHelper.warning('Plase wait for image upload');
				throw ''
			}

			setLoading(true);

			if (!!selectedFiles && selectedFiles.length > 0) values.attachmentFiles = selectedFiles;

			const request = new CreateTicketRequest(values.title, values.description, values.ticketType, values.attachmentFiles || [], orderId)

			const response = await ticketsService.createTicket(request)

			if (!response.isSuccess) throw ''

			getTickets && getTickets();
			setCreateVisible(false);
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	const uploadAdapter = (loader: any) => ({
		upload: () => new Promise((resolve, reject) => {
			setImageLoading(true);
			loader.file.then((file: File) => {
				const request = new CreateImageRequest(file)

				editorService.createImage(request)
					.then(response => resolve({ default: response.data?.[0] }))
					.catch(responseError => reject(responseError))
					.finally(() => setImageLoading(false));
			});
		})
	})

	function uploadPlugin(editor: any) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => uploadAdapter(loader)
	}

	const removeHandler = (event: FileUploadRemoveParams) => {
		const files = [...selectedFiles];

		const removedFiles = files.filter((file: any) => file.objectURL !== (event.file as any).objectURL);

		setSelectedFiles(removedFiles);
	}

	const handleSelect = (event: FileUploadSelectParams) => {
		const files: File[] = [...selectedFiles] || [];

		for (let index = 0; index < event.files.length; index++) files.push(event.files[index]);

		setSelectedFiles(files);
	};

	const chooseOptions = { icon: 'pi pi-fw pi-images', className: 'pr-btn pr-btn-primary hover:bg-blue-700' };

	const headerTemplate = (options: FileUploadHeaderTemplateOptions) => <div className={options.className}>
		<div className="flex align-items-center justify-content-between">
			{options.chooseButton}
		</div>
	</div>

	return (
		<Dialog header="Create Ticket" visible={createVisible} onHide={() => setCreateVisible(false)} className="relative">
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
				{({ handleChange, setFieldValue }) => (
					<Form>
						<div className="my-2">
							<PrTextInput name="title" label="Title" onChange={handleChange} placeholder="Enter ticket title" />
						</div>

						<div className="my-2">
							<CKEditor config={{ extraPlugins: [uploadPlugin] }} editor={ClassicEditor} onChange={(event, editor) => setFieldValue('description', editor.getData())} />
							{/* <TextareaInput name="description" label="Description" rows={5} onChange={handleChange} placeholder="Enter your ticket's description." /> */}
						</div>

						<div className="my-2">
							<FileUpload onRemove={removeHandler} headerTemplate={headerTemplate} chooseOptions={chooseOptions} onSelect={handleSelect} name="images[]" accept="image/*" chooseLabel="Select attachment images" multiple />
						</div>

						<div className="flex align-items-center justify-content-end my-2">
							<PrButton btnType="submit" text="Send" icon={<span className="pi pi-fw pi-send" />} disabled={imageloading} loading={loading} />
						</div>
					</Form>
				)}
			</Formik>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</Dialog>
	);
}

export default CreateTicket