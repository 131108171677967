import HttpBaseService from 'services/_common/http-base-service';
import { CreateProductPrintSideRequest } from './create-product-print-side/create-product-print-side.request';
import { CreateProductPrintSideResponse } from './create-product-print-side/create-product-print-side.response';
import { GetProductPrintSidesRequest } from './get-product-print-sides/get-product-print-sides.request';
import { GetProductPrintSidesResponse } from './get-product-print-sides/get-product-print-sides.response';
import { DeleteProductPrintSideRequest } from './delete-product-print-side/delete-product-print-side.request';

class ProductPrintSideService extends HttpBaseService {
	constructor() {
		super('product-print-sides');
	}

	async createOrUpdate(request: CreateProductPrintSideRequest, uploadAction?: (state: ProgressEvent) => void): Promise<CreateProductPrintSideResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}

	async deleteSide(request: DeleteProductPrintSideRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension)
	}

	async getSides(request: GetProductPrintSidesRequest): Promise<GetProductPrintSidesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export const productPrintSideService = new ProductPrintSideService();
