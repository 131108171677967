import { Resource } from '../../../../models/dtos/resource/resource';
import { UpdateResourceRequest } from '../../../../models/requests/resources/update-resource-request';
import { Dialog } from 'primereact/dialog';
import TextInput from '../../../../helpers/widgets/Forms/TextInput';
import * as Yup from 'yup';
import resourceService from '../../../../services/resource-service';
import { Button } from 'primereact/button';
import TextareaInput from '../../../../helpers/widgets/Forms/TextareaInput';
import { Chips, ChipsChangeParams } from 'primereact/chips';
import { useRef, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getResources: Function;
	editedResource: Resource | undefined;
	setEditedResource: Function;
};

const ResourceUpdate = ({ isVisible, setVisible, getResources, editedResource, setEditedResource }: Props) => {
	const initialValues: UpdateResourceRequest = { id: editedResource?.resourceId || '', title: editedResource?.title || '', description: editedResource?.description || '', identities:editedResource?.media?.videoMedias.map(x=>x.identity) || [], videoMediaBroadcasterType: 1 };
	const formikRef = useRef<FormikProps<UpdateResourceRequest>>(null);

	const validationScheme = Yup.object().shape({ title: Yup.string().required('Resource title is required.').min(1, 'Title must be at least 1 characters.').max(50, 'Title must be a maximum of 50 characters.'), description: Yup.string().required('Resource description is required.').min(1, 'Description must be at least 1 characters.').max(800, 'Description must be a maximum of 800 characters.') });

	const submitForm = (values: UpdateResourceRequest) => {
		resourceService.update(values).then((response) => {
			if (!response.isSuccess) return;

			setEditedResource(undefined);
			setVisible(false);
			getResources();
		});
	};

	const findVideoIdChange = (event: ChipsChangeParams) => {
		if (!formikRef.current) return;

		const newValues = event.value.map((text: string) => {
			try {
				const searchParams = new URL(text).searchParams;
				if (!searchParams) return text;
				return searchParams.get('v');
			} catch (error) {
				return text;
			}
		});
		formikRef.current.setFieldValue(event.target.name, newValues);
	};

	return (
		<Dialog
			visible={isVisible}
			header="Update Help Resource"
			modal
			onHide={() => {
				setEditedResource(undefined);
				setVisible(false);
			}}>
			<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div className="text-right">
							<div className="my-2">
								<TextInput label="Title" name="title" value={values.title} onChange={handleChange} placeholder="Enter resource title" />
							</div>
							<div className="my-2">
								<TextareaInput label="Description" name="description" value={values.description} onChange={handleChange} placeholder="Enter resource description" autoResize />
							</div>
							<div className="my-2">
								<Chips value={values.identities} max={1} name="identities" placeholder={values.identities.length > 0 ? '' : 'Enter youtube link or youtube video id'} onChange={findVideoIdChange} className="w-full" />
							</div>
							<div className="col-12 flex justify-content-end">
								<Button type="submit" label="Update" className="h-full px-8" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ResourceUpdate;
