import { Menu } from '@headlessui/react'
import { DtfListStatusType } from 'features/dtfs/enums/dtf-list-status-type.enum';
import React, { useState } from 'react'
import { AiFillCaretDown } from 'react-icons/ai';

type props = { disabled: boolean, dtfId: string; showCancel: Function; completeDTF: Function; dtfReady: Function; status: DtfListStatusType | number };

const DtfStatuUpdateDropdown = ({ dtfId, showCancel, completeDTF, dtfReady, disabled, status }: props) => {


	const dropdownMenuItems = [
		<Menu.Item as="button" key={0} onClick={() => dtfReady()} className={`pr-btn pr-dropdown-menu-item ${status !==4 && 'hidden'}`}>
			Ready To Print
		</Menu.Item>,
		<Menu.Item as="button" key={1} onClick={() => completeDTF()} className={`pr-btn pr-dropdown-menu-item ${status !==5 && 'hidden'}`}>
			Complete Dtf
		</Menu.Item>,
		<Menu.Item as="button" key={2} onClick={() => showCancel()} className="pr-btn pr-dropdown-menu-item">
			Cancel Dtf
		</Menu.Item>
	];
	return (
		<div>
			{!!dtfId ? (
				<Menu as="div" className="pr-dropdown" style={{ zIndex: '99' }}>
					<Menu.Button className="pr-btn pr-btn-secondary" disabled={disabled}>
						<span className="mr-3">Update Status</span>
						<AiFillCaretDown />
					</Menu.Button>

					<Menu.Items className="pr-dropdown-menu">{dropdownMenuItems}</Menu.Items>
				</Menu>
			) : null}
		</div>
  )
}

export default DtfStatuUpdateDropdown