export class DeleteGroupProductVariantPricesRequest {
	groupProductVariantPriceIdentities: string[];

	constructor(groupProductVariantPriceIdentities: string[]) {
		this.groupProductVariantPriceIdentities = groupProductVariantPriceIdentities;
	}

	get getConvertUriExtension() {
		return `group/delete`;
	}
}
