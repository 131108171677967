import { DeliveryTypes, prefixOptions } from "features/orders/_common/create-manuel-order/create-manuel-order.request";
import { PrDropdownType } from "helpers/widgets/Printram/Dropdown/PrDropdown";

export class SaveReorderAddressRequest {
	deliveryType: DeliveryTypes;
	address: {
		firstLine: string;
		secondLine: string | null;
		city: string;
		state: string;
		zip: string;
		countryIso: string;
		formattedAddress: string;
		receiverFullName: string;
		receiverEmail: string | null;
		phonePrefix: PrDropdownType | null;
		receiverPhoneNumber: string | null;
		vatNumber: string | null;
	} | null;
	reorderId: string;
	attachments: File[] | null;

	constructor(props: Partial<SaveReorderAddressRequest>) {
		this.reorderId = props.reorderId || '';
		this.deliveryType = props.deliveryType || DeliveryTypes.Shipment;
		this.address = props.address || null;
		this.attachments = props.attachments || null;
	}

	get getConvertUriExtension() {
		return `save-address-by-reorder/${this.reorderId}`;
	}
}