import { Button } from 'primereact/button';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import productCopyBySellerService from 'features/products/derived-features/product-copied-by-sellers/product-copy-by-seller.service';
import { DeleteMerchantProductRequest } from 'features/products/derived-features/merchant-products/delete-merchant-product/delete-merchant-product.request';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './ProductList.module.scss';
import mediaHelper from 'helpers/media.helper';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { Pagination } from 'models/_commons/responses/pagination';
import { setForSellerProductPaginationInfo } from 'redux/features/pagination/paginationSlice';
import minMaxPriceHandler from '../components/MinMaxPriceHandler';
import { GetMyProductsRequest } from 'features/products/derived-features/product-copied-by-sellers/get-my-products/get-my-products.request';
import { SellerProductDto } from 'features/products/derived-features/product-copied-by-sellers/get-my-products/dtos/seller-product.dto';

const ProductList = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forSellerProductPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.auth.data?.user);
	const navigate = useNavigate();
	const [products, setProducts] = useState<SellerProductDto[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getAll = useCallback(
		async (pagination?: PaginationDto) => {
			if (!user || !user.roles.isSeller) return;

			setLoading(true);

			try {
				if (!!pagination) dispatch(setForSellerProductPaginationInfo(JSON.parse(JSON.stringify(pagination))));

				const request = new GetMyProductsRequest({ sellerId: user.userId, pagination });

				const response = await productCopyBySellerService.getMyProducts(request);

				if (!response.isSuccess || !response.data) throw '';

				setPaginationResponse(response.pagination);
				setProducts(response.data);
			} catch {
				setProducts([]);
			} finally {
				setLoading(false);
			}
		},
		[user]
	);

	const handleDelete = async (productId: string) => {
		try {
			const request = new DeleteMerchantProductRequest();
			request.productId = productId;

			const response = await merchantProductsService.delete(request);

			if (!response) throw '';

			let newPagination = new PaginationDto();

			if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
			else {
				newPagination.itemCount = paginationResponse?.itemCountAtCurrentPage || 10;
				newPagination.pageNumber = paginationResponse?.currentPage || 1;
				newPagination.first = 0;
			}

			getAll(newPagination);
		} finally {
		}
	};

	useEffect(() => {
		if (!user || !user.roles.isSeller) return;

		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		getAll(newPagination);
	}, [user, getAll]);

	const renderListItems = (product: SellerProductDto) => {
		const categoryNames = product.categoryName;

		return (
			<div className="col-12">
				<div className={styles.productListItem}>
					<img src={mediaHelper.getImageOnMedia(product.media).url} alt={product.name} />

					<div className={styles.productListDetail}>
						<div className={styles.productName}>{product.name}</div>

						<span dangerouslySetInnerHTML={{ __html: product.description }} className={`${styles.productListDescription} mt-2`}></span>

						<div className="mt-3">
							<i className={`pi pi-tag ${styles.productCategoryIcon}`}></i>
							<span className={styles.productCategory}>{categoryNames}</span>
							{product.isPushed && <span className={styles.pushedProduct}>Pushed Product</span>}
						</div>
					</div>

					<div className={styles.productListAction}>
						<span className={styles.productPrice}>{minMaxPriceHandler(product.minPrice.formattedPricePerUnit, product.maxPrice.formattedPricePerUnit)}</span>

						<div className="flex flex-column align-items-end">
							<Button onClick={() => navigate('/product/seller/detail/' + product.id)} icon="pi pi-arrow-right" label="Go Detail" className="p-button-sm px-3 w-7rem" />
							<Button onClick={() => handleDelete(product.id)} icon="pi pi-trash" label="Delete" className="p-button-sm px-3 w-7rem p-button-danger mt-2" />
						</div>
					</div>
				</div>
			</div>
		);
	};

	const onPageChangeEvent = (event: DataViewPageParams) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		getAll(newPagination);
	};

	return (
		<div className="grid">
			<div className="col-12">
				<div className="card">
					<h5>My Products</h5>

					<DataView value={products} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading} layout={'list'} itemTemplate={renderListItems} paginator rows={fromStatePagination.itemCount || 5} />
				</div>
			</div>
		</div>
	);
};

export default ProductList;
