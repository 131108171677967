import { CategoryDetailDto } from '../create-category/dtos/category-detail.dto';
import { EtsyCategoryBuyerTaxonomy } from '../create-category/dtos/etsy-category-buyer-taxonmy.dto';

export class UpdateCategoryRequest {
	id: string;
	parentCategoryId: string | null;
	name: string;
	slug: string;
	canBeAddProduct: boolean;
	isTemplateCategory: boolean;
	imageFile: File | null;
	detail: CategoryDetailDto;
	etsyCategoryBuyerTaxonomy: EtsyCategoryBuyerTaxonomy;

	constructor(props: Partial<UpdateCategoryRequest>) {
		this.id = props.id || '';
		this.parentCategoryId = props.parentCategoryId || null;
		this.name = props.name || '';
		this.canBeAddProduct = props.canBeAddProduct || false;
		this.isTemplateCategory = props.isTemplateCategory || false;
		this.imageFile = props.imageFile || null;
		this.etsyCategoryBuyerTaxonomy = props.etsyCategoryBuyerTaxonomy || new EtsyCategoryBuyerTaxonomy({});
		this.detail = props.detail || new CategoryDetailDto({});
		this.slug = props.slug || '';
	}

	get getConvertUriExtension() {
		return `update`;
	}
}
