export class GetStatesRequest {
	private countryId: number;

	constructor(countryId: number) {
		this.countryId = countryId;
	}

	get getConvertUriExtension() {
		return `get-states?CountryId=${this.countryId}`;
	}
}
