export class SoftDeletePrintCenterOperatorRequest {
	id: number

	constructor(id: number) {
		this.id = id;
	}

	getConvertUriExtension () {
		return `soft-delete?id=${this.id}`;
	}
}