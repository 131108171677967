export class SetDefaultLocationRequest {
	stockLocationId: string;
	setDefault?: boolean = true;

	get getConvertUriExtension() {
		return 'set-default-location';
	}

	constructor(props: Partial<SetDefaultLocationRequest>) {
		this.stockLocationId = props.stockLocationId || ""
		if(this.setDefault) this.setDefault = props.setDefault || true;
	}
}