import { EnumToArrayModel } from '../models/_commons/dtos/enum-to-array-model';

const convertArray = (enumObject: any, ignoreFirstIndex: boolean = false) => {
	const enumArray: EnumToArrayModel[] = [];

	let i = 0;
	for (const key in enumObject) {
		try {
			if (i === 0 && ignoreFirstIndex) throw '';

			const canConvertNumber = Number(key);

			if (Number.isNaN(canConvertNumber)) throw '';

			enumArray.push({ label: enumObject[key] as string, value: +key });
		} catch (error) {
		} finally {
			i += 1;
		}
	}

	return enumArray;
};

const enumToArrayHelper = { convertArray };

export default enumToArrayHelper;
