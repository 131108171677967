export class CreateAutomatedDtfListWithOrdersRequest {
    name: string;
    orderIds: Array<string>;

    get getConvertUriExtension() {
        return 'create-automated-dtf-list-with-orders';
    }

    constructor(props: Partial<CreateAutomatedDtfListWithOrdersRequest>) {
        this.name = props.name || '';
        this.orderIds = props.orderIds || [];
    }
}