import { GetGroupsRequest } from 'features/groups/_common/get-groups/get-groups.request';
import groupsService from 'features/groups/_common/groups.service';
import { GroupForListDto } from 'features/groups/dtos/group-for-list.dto';
import dateHelper from 'helpers/dateHelper';
import DropdownButton from 'helpers/widgets/DropdownButton';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import UpdateSellerGroup from './components/UpdateSellerGroup';
import CreateSellerGroup from './components/CreateSellerGroup';
import { useNavigate } from 'react-router-dom';
import UpdateSellerGroupUsers from './components/UpdateSellerGroupUsers';
import { DeleteGroupRequest } from 'features/groups/_common/delete-group/delete-group.request';

const SellerGroups = () => {
	const navigate = useNavigate();
	const [groups, setGroups] = useState<GroupForListDto[]>([]);
	const [editedGroup, setEditedGroup] = useState<GroupForListDto>();
	const [editVisible, setEditVisible] = useState<boolean>(false);
	const [createVisible, setCreateVisible] = useState<boolean>(false);
	const [updatedUsersGroup, setUpdatedUsersGroup] = useState<GroupForListDto>();
	const [updateUsersVisible, setUpdateUsersVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const createdDateBodyTemplate = (group: GroupForListDto) => dateHelper.formatDate(group.startDate || '');

	const handleEditClick = (group: GroupForListDto) => {
		setEditedGroup(group);
		setEditVisible(true);
	};

	const handleUpdatedUserClick = (group: GroupForListDto) => {
		setUpdatedUsersGroup(group);
		setUpdateUsersVisible(true);
	};

	const getGroups = async () => {
		try {
			setLoading(true);

			const request = new GetGroupsRequest();

			const response = await groupsService.getGroups(request);

			if (!response.isSuccess) throw '';

			setGroups(response.data || []);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const deleteGroup = async (groupId: string) => {
		if (!groupId) return;

		try {
			setLoading(true)

			const request = new DeleteGroupRequest(groupId)

			const response = await groupsService.deleteGroup(request)

			if (!response) throw ''

			getGroups()
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const actionsTemplate = (group: GroupForListDto) => {
		const editGroupBtn = <button onClick={() => handleEditClick(group)}>Edit Group</button>;
		const editUsersBtn = <button onClick={() => handleUpdatedUserClick(group)}>Edit Users</button>;
		const editPricesBtn = <button onClick={() => navigate(`/group-prices/${group.id}`)}>Edit Prices</button>;
		const deleteBtn = <button onClick={() => deleteGroup(group.id)}>Delete Group</button>;

		const menuItems = [editGroupBtn, editUsersBtn, editPricesBtn, deleteBtn];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" className="bg-blue-600" />;
	};

	useEffect(() => {
		getGroups();
	}, []);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Seller Groups</h1>

				<div className="container-header-tools">
					<PrButton type="secondary" text="Create" disabled={loading} icon={<span className="pi pi-fw pi-plus" />} onClick={() => setCreateVisible(true)} />

					<PrButton type="secondary" text="Refresh" disabled={loading} icon={<span className="pi pi-fw pi-refresh" />} onClick={getGroups} />
				</div>
			</div>

			<div className="container-body p-0">
				<DataTable value={groups} showGridlines className="pr-table">
					<Column field="name" header="Group Name" />
					<Column field="description" header="Description" />
					<Column field="startDate" header="Start Date" body={createdDateBodyTemplate} />
					<Column field="actions" header="Actions" body={actionsTemplate} />
				</DataTable>
			</div>

			<CreateSellerGroup isVisible={createVisible} setVisible={setCreateVisible} getGroups={getGroups} />
			{editedGroup ? <UpdateSellerGroup isVisible={editVisible} setVisible={setEditVisible} group={editedGroup} setGroup={setEditedGroup} getGroups={getGroups} /> : null}
			{updatedUsersGroup ? <UpdateSellerGroupUsers isVisible={updateUsersVisible} setVisible={setUpdateUsersVisible} group={updatedUsersGroup} setGroup={setUpdatedUsersGroup} getGroups={getGroups} /> : null}

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default SellerGroups;
