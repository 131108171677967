import { OrderDto } from 'models/dtos/order/order';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import ProductTable from '../ProductTable';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ProductPrices } from '../products-prices/ProductPrices';

type Props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	order: OrderDto;
	onAccept: () => void;
};

const WantAcknowledgeModal = (props: Partial<Props>) => {
	return (
		<Dialog header="Update Status to 'Order Approved'" visible={props.visible} onHide={() => props.setVisible?.(false)} style={{ maxWidth: '60rem' }}>
			<p>The status of this order is 'Pending Approval'. By changing the status to 'Order Approved', you can obtain the ship label and the order will be ready for processing. Would you like to change the status of your order to 'Order Approved'?</p>

			<h5 className="m-0">Your current order items</h5>
			<p className="text-color-secondary">
				<b className="text-pink-600">Note:</b> You can delete or add items to the order. To perform these actions, you should close this modal and change the status to '<b>Order Approved</b>' after the updates are complete.
			</p>

			<ProductTable orderItems={props.order?.items} />

			{!!props.order ? <ProductPrices prices={props.order.prices} /> : null}

			<div className="flex justify-content-end gap-2 mt-3">
				<PrButton type="secondary" text="Cancel" onClick={() => props.setVisible?.(false)} />

				<PrButton
					text="Update Status"
					onClick={() => {
						props.onAccept?.();
						props.setVisible?.(false);
					}}
				/>
			</div>
		</Dialog>
	);
};

export default WantAcknowledgeModal;
