import React, { useContext } from 'react';
import styles from './Steps.module.scss';
import { SellerReorderDetailContext, SellerReorderDetailContextType, reorderSteps } from '../context/SellerReorderDetailContextProvider';

export const Steps = () => {
	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	return (
		<div className={styles.roStepWrapper}>
			{reorderSteps.map((_step, index) => (
				<div
					key={index}
					className={`${styles.roStepItem}${_step.value === context.reorderStep.value ? ` ${styles.active}` : ''}${context.reorderStep.value > _step.value ? ` ${styles.completed}` : ''}`}
					onClick={() => {
						if (!_step.isCompleted) return;

						context.setReorderStep(_step);
					}}>
					<span className={styles.roStepNumber}>{context.reorderStep.value > _step.value ? <span className="pi pi-check"></span> : _step.value}</span>
					<span className={styles.roStepName}>{_step.label}</span>
				</div>
			))}

			<div className={styles.roStepDivider} />
		</div>
	);
};
