import HttpBaseService from 'services/_common/http-base-service';
import { GetMerchantsForAdminRequest } from './get-merchants-for-admin/get-merchants-for-admin.request';
import { GetMerchantsForAdminResponse } from './get-merchants-for-admin/get-merchants-for-admin.response';
import { UpdateMerchantWithUserRequest } from './update-merchant-with-user/update-merchant-with-user.request';
import { UpdateMerchantWithUserResponse } from './update-merchant-with-user/update-merchant-with-user.response';
import { UpdateStatuRequest } from './update-statu/update-statu.request';
import { UpdateStatuResponse } from './update-statu/update-statu.response';
import { DataPaginationResponse } from 'models/_commons/responses/data-pagination-response';
import { GetMerchantListRequest } from './get-merchant-list/get-merchant-list.request';
import { GetMerchantListResponse } from './get-merchant-list/get-merchant-list.response';
import { CreateMerchantRequest } from './create-merchant/create-merchant.request';
import { CreateMerchantResponse } from './create-merchant/create-merchant.response';

class MerchantService extends HttpBaseService {
	constructor() {
		super('merchants');
	}

	async getMerchantsForAdmin(request: GetMerchantsForAdminRequest): Promise<DataPaginationResponse<GetMerchantsForAdminResponse[]>> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getMerchantList(request: GetMerchantListRequest): Promise<GetMerchantListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async update(request: UpdateMerchantWithUserRequest): Promise<UpdateMerchantWithUserResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async updateStatu(request: UpdateStatuRequest): Promise<UpdateStatuResponse> {
		return await this.patchData(request.getConvertUriExtension, request);
	}

	async create(request: CreateMerchantRequest): Promise<CreateMerchantResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export const merchantService = new MerchantService();

export default merchantService;
