import orderService from 'features/orders/_common/order.service';
import { Form, Formik } from 'formik';
import audioHelper from 'helpers/audio.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import { Dropdown } from 'primereact/dropdown';
import { OperatorDTO } from '../../../../../features/printCenterOperators/_common/dtos/operator_dto';
import printCenterOperatorService from '../../../../../features/printCenterOperators/_common/print-center-operator.service';
import { GetAllPrintCenterOperatorsRequest } from '../../../../../features/printCenterOperators/_common/get-all-print-center-operators/get-all-print-center-operators.request';
import { ReadyForShippingFromBarcodeWithOperatorRequest } from '../../../../../features/orders/_common/ready-for-shipping-from-barcode-with-operator/ready-for-shipping-from-barcode-with-operator.request';
import {
	ReadyForShippingFromBarcodeRequest
} from '../../../../../features/orders/_common/ready-for-shipping-from-barcode/ready-for-shipping-from-barcode.request';

const ReadBarcode = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [operators, setOperators] = useState<OperatorDTO[]>([]);
	const [selectedOperator, setSelectedOperator] = useState<OperatorDTO | undefined>(undefined);

	useEffect(() => {
		getOperators();
	}, []);

	const getOperators = async () => {
		try {
			setLoading(true);

			const response = await printCenterOperatorService.getAllPrintCenterOperators(new GetAllPrintCenterOperatorsRequest());

			if (!response.isSuccess) throw '';

			setOperators(response.data!);

			/*setSelectedOperator(response.data![0]);*/
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const focusInput = () => {
		const input = document.querySelector('input');
		input?.focus();
	};

	const selectedOperatorTemplate = (option: OperatorDTO) => {
		if (option) {
			return <span>{option.name}</span>;
		}

		return <span>Select an Operator</span>;
	};

	const operatorOptionTemplate = (option: OperatorDTO) => {
		return <span>{option.name}</span>;
	};

	const showErrorDialog = (error: any, barcode: string) => {
		confirmDialog({
			header: 'Status not updated!',
			message: error.response?.data?.message.toString() || '',
			icon: <IoWarningOutline size={25} />,
			rejectClassName: 'hidden',
			footer: (options) => {
				return (
					<div className="flex align-items-center justify-content-end gap-3">
						<span
							className="text-primary underline cursor-pointer font-bold"
							onClick={async () => {
								try {
									setLoading(true);
									options.reject();
									if (!selectedOperator) return;

									const response = await orderService.setReadyForShippingFromBarcode(new ReadyForShippingFromBarcodeWithOperatorRequest(barcode, true, selectedOperator.id));
									if (!response.isSuccess) throw '';
								} catch (error) {
									audioHelper.playAudio();
								} finally {
									setLoading(false);
								}
							}}>
							Get Ship Label
						</span>

						<PrButton text="Ok" btnType="button" onClick={() => options.accept()} />
					</div>
				);
			},
			className: 'max-w-30rem',
			accept: focusInput,
			reject: focusInput
		});
	};

	return (
		<Formik
			initialValues={{ barcode: '' }}
			onSubmit={async (values, formikHelpers) => {
				if (!values.barcode) return;

				const oldValue: { barcode: string } = JSON.parse(JSON.stringify(values));

				try {
					setLoading(true);

					const response = selectedOperator ?
						await orderService.setReadyForShippingFromBarcodeWithOperator(new ReadyForShippingFromBarcodeWithOperatorRequest(values.barcode, false, selectedOperator.id)) :
						await orderService.setReadyForShippingFromBarcode(new ReadyForShippingFromBarcodeRequest(values.barcode, false));
					if (!response.isSuccess) throw '';

					focusInput();
				} catch (error) {
					audioHelper.playAudio();
					showErrorDialog(error as any, oldValue.barcode);
				} finally {
					formikHelpers.resetForm();
					setLoading(false);
				}
			}}>
			{(form) => (
				<Form className="container-body static p-3" autoComplete="off">
					<div className=''>
						<PrTextInput className='my-2' label="Scan/Enter Barcode" readOnly={loading} name="barcode" minLength={2} autoFocus value={form.values.barcode} onChange={form.handleChange} required placeholder="Scan or Enter barcode to update order status" />
						<Dropdown showClear value={selectedOperator} onChange={(e) => setSelectedOperator(e.value)} options={operators} optionLabel="name" placeholder="Select an Operator" filter filterBy="name,code" valueTemplate={selectedOperatorTemplate} itemTemplate={operatorOptionTemplate} className="w-full border-round-lg bg-white-alpha-10 border-1 border-gray-400 my-2" />
					</div>

					<div className="flex justify-content-end mt-3">
						<PrButton btnType="submit" text="Update Status" />
					</div>

					{loading ? (
						<div className="pr-loading-sticky">
							<div className="pr-spinner-wrapper">
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						</div>
					) : null}
				</Form>
			)}
		</Formik>
	);
};

export default ReadBarcode;
