export class GetProductVariantsPriceOptionsRequest {
	id: string;

	get getConvertUriExtension() {
		return `get-product-variants-price?Id=${this.id}`;
	}

	constructor(id: string) {
		this.id = id
	}
}