export class GenerateOrderReceiptDtfRequest {
	dtfListId: string;
	isSaveToCdn: boolean;

	constructor(dtfListId: string, isSaveToCdn: boolean) {
		this.dtfListId = dtfListId;
		this.isSaveToCdn = isSaveToCdn || true;
	}

	get getConvertUriExtension() {
		return 'generate-order-receipt-dtf';
	}
}