import React, { useCallback, useEffect, useState } from 'react';
import { SelectedOrder } from 'components/pages/orderManagement/orders/models/selected-order';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { GetOrdersForPrintCenterRequest } from 'features/orders/_common/get-orders-for-print-center/get-orders-for-print-center.request';
import ordersService from 'features/orders/_common/order.service';
import { OrderForSellerListDto } from 'features/orders/dtos/order-for-seller-list.dto';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import { OrderAvailableStatuDto } from 'features/orders/dtos/order-statu.dto';
import currencyHelper from 'helpers/curreny.helper';
import dateHelper from 'helpers/dateHelper';
import { Pagination } from 'models/_commons/responses/pagination';
import { User } from 'models/dtos/auth/users/user';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableDataSelectableParams, DataTablePFSEvent } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SelectItem } from 'primereact/selectitem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setForSellerOrderListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import UpdateStatus from './components/UpdateStatus';
import UpdateWeightStatus from './components/UpdateWeightStatus';
import { CreateOrderStatuHistoryRequest } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';
import stringHelper from 'helpers/string.helper';
import OrderFilterTab from 'helpers/widgets/Order/OrderFilterTab/OrderFilterTab';
import PrintDropdown from 'helpers/widgets/Order/PrintDropdown/PrintDropdown';
import DropdownButton from 'helpers/widgets/DropdownButton';
import CancelAssign from './components/CancelAssign';
import { GetOrdersStoreDto } from 'features/orders/_common/get-orders-for-admin/get-orders-for-admin.response';

const Orders = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const searchStatus = searchParams.get('status');

	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminOrderListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [orders, setOrders] = useState<OrderForSellerListDto[] | null>([]);
	const [checkedOrders, setCheckedOrders] = useState<OrderForSellerListDto[] | null>([]);
	const [checkedOrderIds, setCheckedOrderIds] = useState<string[]>([]);
	const [filter, setFilter] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState<SelectedOrder>();
	const [cancelOrderId, setCancelOrderId] = useState<string>();
	const [statusVisible, setStatusVisible] = useState(false);
	const [weightStatusVisible, setWeightStatusVisible] = useState(false);
	const [cancelAssignVisible, setCancelAssignVisible] = useState(false);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [filterOrderStatuType, setFilterOrderStatuType] = useState<OrderStatuTypes | null>(!!searchStatus ? Number(searchStatus) || null : null);
	const [isGetStores, setIsGetStores] = useState<boolean>(true);
	const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(undefined);
	const [stores, setStores] = useState<GetOrdersStoreDto[]>([]);

	const getAll = async (pagination?: PaginationDto, filter?: string | null) => {
		try {
			if (!userId) throw '';

			setLoading(true);

			if (!!pagination) dispatch(setForSellerOrderListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			// const request = new GetOrdersForPrintCenterRequest(userId, pagination, filter || null, filterOrderStatuType);
			const request = new GetOrdersForPrintCenterRequest({
				printCenterId: userId,
				pagination,
				contains: filter || null,
				orderStatu: filterOrderStatuType,
				includeStores: isGetStores,
				storeId: selectedStoreId
			});

			const response = await ordersService.getOrdersForPrintCenter(request);

			if (!response.isSuccess || !response.data) throw '';

			if (response.data.stores.length > 0) setStores(response.data.stores);

			setPaginationResponse(response.data.orders.pagination);
			setOrders(response.data.orders.data);
		} catch (error) {
			setOrders([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
			setIsGetStores(false);
		}
	};

	useEffect(() => {
		if (isGetStores) return;

		getAll(fromStatePagination);
	}, [selectedStoreId]);

	const onPageChangeEvent = (event: DataTablePFSEvent) => {
		let newPaginagtion = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPaginagtion = fromStatePagination;
		} else {
			newPaginagtion.itemCount = event.rows;
			newPaginagtion.orderBy = 1;
			newPaginagtion.pageNumber = !!event.page ? event.page + 1 : 1;
			newPaginagtion.first = event.first;
		}

		getAll(newPaginagtion);
	};

	const isCellSelectable = (event: DataTableDataSelectableParams) => {
		if (event.data.field === '#' || event.data.field === 'status') return false;
		return true;
	};

	const handleOrderStatuOnChange = (orderId: string, orderStatuId: number, statuName: string) => {
		if (!orderId || !orderStatuId) return;

		setSelectedOrder({ orderId: orderId, orderStatuId: orderStatuId, statuName: statuName });
	};

	const statusBodyTemplate = (order: OrderForSellerListDto) => {
		const statuOptions = order?.availableOrderStatus?.map((statu: OrderAvailableStatuDto) => ({ label: statu.readableName, value: statu.id } as SelectItem)) || [];

		return <Dropdown options={statuOptions} placeholder={OrderStatuTypes[order.statu]} onChange={(e) => handleOrderStatuOnChange(order.id, e.value, statuOptions.find((stat) => stat.value === e.value)?.label || '')} disabled={statuOptions.length === 0} className="w-full" />;
	};

	const printramDateBodyTemplate = (rowData: OrderForSellerListDto) => dateHelper.formatDate(rowData.createdDate || '');

	const priceBodyTemplate = (rowData: OrderForSellerListDto) => {
		const price = currencyHelper.formatPrice(rowData.printramGrantTotal.formattedPricePerUnit ? rowData.printramGrantTotal.formattedPricePerUnit : rowData.externalGrantTotal.formattedPricePerUnit);
		return (
			<div>
				<span>{price}</span>

				{/* {!!rowData.estimatedTransportPrice ? (
				<React.Fragment>
					<span title="Estimated Ship Cost"> + ESC. </span>
					<span>{currencyHelper.formatPrice(rowData.estimatedTransportPrice.formattedPricePerUnit)}</span>
				</React.Fragment>
			) : null} */}
			</div>
		);
	};

	const handleFilter = async () => await getAll(new PaginationDto(1, 5), filter.trim());

	const setReadableName = (value: number) => {
		if (!value) return '';

		let newValue = stringHelper.parseAtUpperCaseAndJoin(OrderStatuTypes[value]);

		newValue = newValue.split('Order').join('');

		return newValue;
	};

	const headerTemplate = () => (
		<div className="flex align-items-end justify-content-between">
			<div className="flex align-items-end justify-content-between gap-5">
				<h5 className="m-0 white-space-nowrap">{!filterOrderStatuType ? 'All' : setReadableName(filterOrderStatuType)} Orders</h5>

				<div className="p-inputgroup">
					<InputText placeholder="Keyword..." value={filter} onKeyDown={(event) => event.key === 'Enter' && handleFilter()} onChange={(event) => setFilter(event.target.value)} className="p-inputtext-sm" />
					<Button onClick={handleFilter} icon="pi pi-fw pi-search" className="p-button-sm" />
				</div>

				<Dropdown options={stores} optionLabel="name" optionValue="id" value={selectedStoreId} onChange={(e) => setSelectedStoreId(e.value)} placeholder="Select store" showClear filter className="min-w-max" />
			</div>

			<div className="flex gap-2">
				<PrintDropdown loading={loading} setLoading={setLoading} orderIds={checkedOrderIds} />
				<Button label="Refresh" onClick={() => getAll()} loading={loading} icon="pi pi-refresh" />
			</div>
		</div>
	);

	const onOrdersSelect = (selectedOrders: OrderForSellerListDto[]) => setCheckedOrders(selectedOrders.filter((order) => !order.isExternalOrder));

	const actionsBodyTemplate = (order: OrderForSellerListDto) => {
		const menuItems = [
			<button disabled={order.isExternalOrder} onClick={() => navigate('../order/' + order.id)}>
				Order Detail
			</button>,
			<button disabled={order.isExternalOrder} onClick={() => setCancelOrderId(order.id)}>
				Cancel Assign
			</button>
		];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	const updateStatu = useCallback(async (orderId: string, orderStatuId: number) => {
		try {
			setLoading(true);

			const request = new CreateOrderStatuHistoryRequest({ orderId, orderStatuId });

			const response = await orderStatuHistoriesService.create(request);

			if (!response.isSuccess) throw '';

			setSelectedOrder(undefined);
			let newPaginagtion = new PaginationDto();

			if (!!fromStatePagination.itemCount) newPaginagtion = fromStatePagination;
			else {
				newPaginagtion.itemCount = paginationResponse?.itemCountAtCurrentPage || 5;
				newPaginagtion.orderBy = 2;
				newPaginagtion.pageNumber = paginationResponse?.currentPage || 1;
				newPaginagtion.first = 0;
			}

			await getAll(newPaginagtion);
			setStatusVisible(false);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (!cancelOrderId) return;

		setCancelAssignVisible(true);
	}, [cancelOrderId]);

	useEffect(() => {
		if (!selectedOrder) return;

		if (selectedOrder.orderStatuId === 5) setWeightStatusVisible(true);
		else if (selectedOrder.orderStatuId === 4 || selectedOrder.orderStatuId === 8) setStatusVisible(true);
		else updateStatu(selectedOrder.orderId, selectedOrder.orderStatuId);
	}, [selectedOrder]);

	useEffect(() => {
		let newPaginagtion = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPaginagtion = fromStatePagination;
		else {
			newPaginagtion.itemCount = paginationResponse?.itemCountAtCurrentPage || 5;
			newPaginagtion.orderBy = 1;
			newPaginagtion.pageNumber = paginationResponse?.currentPage || 1;
			newPaginagtion.first = 0;
		}

		getAll(newPaginagtion);
	}, []);

	useEffect(() => {
		if (!checkedOrders || checkedOrders?.length === 0) setCheckedOrderIds([]);
		else setCheckedOrderIds(checkedOrders.map((order: OrderForSellerListDto) => order.id));
	}, [checkedOrders]);

	return (
		<div className="datatable-style-demo">
			<div className="card">
				<OrderFilterTab filterOrderStatuType={filterOrderStatuType} setFilterOrderStatuType={setFilterOrderStatuType} page="seller" />

				<DataTable value={orders as any} lazy dataKey="id" selectionMode="checkbox" currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" header={headerTemplate} paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} rowsPerPageOptions={[5, 10, 15, 20]} first={fromStatePagination.first} totalRecords={paginationResponse?.totalItemCount} cellSelection isDataSelectable={isCellSelectable} selection={checkedOrders} onSelectionChange={(e) => onOrdersSelect(e.value)} loading={loading}>
					<Column field="#" selectionMode="multiple" className="order-column" />
					<Column field="code" header="Order Code" sortable filter className="order-column" />
					<Column field="sellerFullName" header="Seller" sortable filter className="order-column" />
					<Column field="storeName" header="Store" sortable filter className="order-column" />
					<Column field="customerFullName" header="Customer" sortable filter className="order-column" />
					<Column field="count" header="Count" sortable className="order-column" />
					<Column body={priceBodyTemplate} header="Total" sortable className="order-column" />
					<Column field="status" body={statusBodyTemplate} header="Status" className="order-column" />
					<Column body={printramDateBodyTemplate} header="Printram Created Date" sortable className="order-column" />
					<Column field="actions" body={actionsBodyTemplate} header="Actions" className="order-column" />
				</DataTable>
			</div>

			{selectedOrder && <UpdateStatus statusVisible={statusVisible} setStatusVisible={setStatusVisible} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} getOrders={getAll} />}
			{selectedOrder?.orderStatuId === 5 && <UpdateWeightStatus statusVisible={weightStatusVisible} setStatusVisible={setWeightStatusVisible} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} getOrders={getAll} />}
			{cancelOrderId && <CancelAssign assignVisible={cancelAssignVisible} setAssignVisible={setCancelAssignVisible} cancelOrderId={cancelOrderId} setCancelOrderId={setCancelOrderId} getOrders={getAll} />}
		</div>
	);
};

export default Orders;
