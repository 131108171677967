import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { TicketDto } from 'features/tickets/_common/dtos/ticket.dto';
import { GetTicketsRequest } from 'features/tickets/_common/get-tickets/get-tickets.request';
import ticketsService from 'features/tickets/_common/tickets.service';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import styles from './ReOrderTickets.module.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import dateHelper from 'helpers/dateHelper';
import { Pagination } from 'models/_commons/responses/pagination';
import { Paginator } from 'primereact/paginator';
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import { useAppSelector } from 'redux/hooks';
import { User } from 'models/dtos/auth/users/user';
import { TicketFilterTypes } from 'features/tickets/_common/enums/ticket-filter-types.enum';
import toastHelper from 'helpers/toast.helper';

const ReOrderTickets = () => {
	const { roles } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const [tickets, setTickets] = useState<TicketDto[]>([]);
	const [pagination, setPagination] = useState<PaginationDto>({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [filter, setFilter] = useState<TicketFilterTypes | undefined>(undefined);
	const [ticketStatus] = useState([
		{ label: 'All', value: 0 },
		{ label: 'Answered', value: 1 },
		{ label: 'Waiting', value: 2 },
		{ label: 'Closed', value: 3 }
	]);
	const [search, setSearch] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const statuIsActive = (statu: number) => {
		if (statu === filter) return true;
		else {
			if (statu === 0 && filter === undefined) return true;
			else return false;
		}
	};

	const statuLabelHandler = (ticket: TicketDto) => {
		if (ticket.isActive) {
			if (ticket.isAnswered) return 'Answered';
			else return 'Waiting';
		} else return 'Closed';
	};

	const statuStyleHandler = (ticket: TicketDto) => {
		if (ticket.isActive) {
			if (!ticket.isAnswered) return styles.isWaitingStatus;
		} else return styles.isClosedStatus;
	};

	const rowStyleHandler = (ticket: TicketDto) => {
		if (ticket.isActive) {
			if (!ticket.isAnswered) return styles.isWaitingTicket;
		} else return styles.isClosedTicket;
	};

	const copytoClipboard = (orderCode: string) => {
		navigator.clipboard.writeText(orderCode);
		toastHelper.info('Code copied: ' + orderCode);
	};

	const clearPagination = () => setPagination({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });

	const setPaginationOrderBy = () => setPagination({ pageNumber: 1, itemCount: 20, orderBy: pagination.orderBy === 2 ? 1 : 2, first: 0 });

	const getTickets = async (paginationReq: PaginationDto) => {
		try {
			if (!paginationReq) throw '';

			setLoading(true);

			const request = new GetTicketsRequest(paginationReq, search, filter, true);

			const response = await ticketsService.getTickets(request);

			if (!response.isSuccess) throw '';

			setTickets(response.data || []);
			setPaginationResponse(response.pagination);
		} catch (error) {
			setTickets([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!pagination) return;

		getTickets(pagination);
	}, [pagination]);

	useEffect(() => {
		if (filter === undefined) return;

		setPagination({ pageNumber: 1, itemCount: 20, orderBy: pagination.orderBy, first: 0 });
	}, [filter]);

	return (
		<main className="container relative">
			<section className="container-header">
				<h1 className="container-header-title">Re Order Tickets</h1>

				<div className="container-header-tools">
					<PrButton type="secondary" text="Refresh Tickets" disabled={loading} icon={<span className="pi pi-fw pi-refresh" />} onClick={() => getTickets(pagination)} />
				</div>
			</section>

			<section className="container-body p-3">
				<div className={styles.status}>
					<h5 className="font-bold">Ticket Status Filter</h5>
					<div className={styles.filters}>
						{ticketStatus.map((_status) => (
							<div key={_status.value} onClick={() => setFilter(_status.value)} className={`${styles.filter} ${statuIsActive(_status.value) ? styles.active : ''}`}>
								<span>{_status.label}</span>
							</div>
						))}
					</div>
				</div>
				<hr />
				<div className="mb-3">
					You can search among tickets by <span className="font-bold">ticket code, order code{roles.isAdmin && ', seller name'}</span> or <span className="font-bold">ticket title.</span>
				</div>
				<SearchInput onChange={(e) => setSearch(e.target.value)} value={search} onKeyDown={(event) => event.key === 'Enter' && clearPagination()} filterBtn disabled={loading} placeholder="Search tickets" filterBtnOnClick={() => clearPagination()} />
			</section>

			<section className="container-body p-0">
				<div className={styles.ticketsTableWrapper}>
					<div className={styles.ticketsTableHeader}>
						<div className={styles.ticketsTableHeaderTitles}>
							<span className={styles.ticketsTableHeaderTitlesCode}>Code</span>
							{roles.isAdmin && <span className={styles.ticketsTableHeaderTitlesFrom}>From</span>}
							<span className={styles.ticketsTableHeaderTitlesTitle}>Title</span>
							<span className={styles.ticketsTableHeaderTitlesStatus}>Status</span>
							<span className={styles.ticketsTableHeaderTitlesDate} onClick={() => setPaginationOrderBy()}>
								Created Date
								<i className="pi pi-fw pi-sort ml-1" />
							</span>
						</div>
					</div>
				</div>

				<div className={styles.ticketsTableBody}>
					{tickets.length > 0 ? (
						tickets.map((_ticket) => (
							<Link to={`/re-order-tickets/${_ticket.id}`} key={_ticket.id} className={`${styles.ticketsTableBodyItemWrapper} ${rowStyleHandler(_ticket)}`}>
								<div className="px-4">
									<div className={styles.ticketsTableBodyWrapper}>
										<div className={styles.ticketsTableBodyItems}>
											<div className={styles.ticketsTableBodyItemCode}>
												<span className={styles.ticketsTableBodyItemCodeTitle}>{_ticket.code}</span>
												<div className={styles.ticketsTableBodyItemCodeDesc}>
													{_ticket.order && (
														<span
															onClick={(e) => {
																e.preventDefault();
																copytoClipboard(_ticket.order?.orderCode || '');
															}}>
															{'Order: ' + _ticket.order?.orderCode}
														</span>
													)}
													{_ticket.order?.etsyReceiptId && <span>{'Etsy: ' + _ticket.order?.etsyReceiptId}</span>}
												</div>
											</div>

											{roles.isAdmin && (
												<div className={styles.ticketsTableBodyItemFrom}>
													<span>{_ticket.fromUserFullName}</span>
												</div>
											)}
											<div className={styles.ticketsTableBodyItemTitle}>
												<Tooltip target={`#${_ticket.code}`} />
												<h6 id={_ticket.code} data-pr-tooltip={_ticket.title} data-pr-position="top" className={!_ticket.isAnswered && _ticket.isActive ? styles.isWaiting : ''}>
													{_ticket.title}
												</h6>
											</div>
											<div className={`${styles.ticketsTableBodyItemStatus} ${statuStyleHandler(_ticket)}`}>
												<span>{statuLabelHandler(_ticket)}</span>
											</div>
											<div className={styles.ticketsTableBodyItemDate}>
												<span>Created at &#x2022; {dateHelper.formatDate(_ticket.createdDate)}</span>
											</div>
										</div>
									</div>
								</div>
							</Link>
						))
					) : (
						<div className={`${styles.ticketsTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any ticket yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && tickets.length > 0 ? (
						<div className={`${styles.ticketsTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={pagination.first}
								rows={pagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30]}
								onPageChange={(event) => {
									setPagination({
										first: event.first,
										itemCount: event.rows,
										pageNumber: event.page + 1,
										orderBy: PaginationOrderByEnum.Descending
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
	);
};

export default ReOrderTickets;
