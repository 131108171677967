import React from 'react';
import styles from './PrBtnGroup.module.scss';

type Props = {
	isActive: 'first' | 'second';
	firstOnClik: React.MouseEventHandler<HTMLButtonElement>;
	secondOnClik: React.MouseEventHandler<HTMLButtonElement>;
	firstText: string;
	secondText: string;
	className: string;
};

const PrBtnGroup = (props: Partial<Props>) => {
	return (
		<div className={`${styles.btnGroup}${props.className ?? ''}`}>
			<button onClick={props.firstOnClik} className={props.isActive === 'first' ? styles.active : ''}>
				{props.firstText}
			</button>

			<button onClick={props.secondOnClik} className={props.isActive === 'second' ? styles.active : ''}>
				{props.secondText}
			</button>
		</div>
	);
};

export default PrBtnGroup;
