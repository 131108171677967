import { MeasurementUnits } from "./constants/measurement-units"

export type measurementType = "Length" | "Height" | "Width" | "Weight" | "Capacity" | "oz"

const getMeasurementUnit = (unitNumber: number, measurementType: measurementType) => {
	if (unitNumber === 0) return ""

	if (unitNumber === 1) {
		if ((measurementType === 'Length') || (measurementType === 'Height') || (measurementType === 'Width')) return 'in';
		if (measurementType === 'Weight' || measurementType === 'oz') return 'oz';
		if (measurementType === "Capacity") return "gal"
	}

	if (unitNumber === 2) {
		if (measurementType === 'Length' || measurementType === 'Height' || measurementType === 'Width') return 'cm';
		if (measurementType === "Weight") return "g";
		if (measurementType === 'oz') return "oz";
		if (measurementType === 'Capacity') return 'lt';
	}
}

export const measurementHelper = { getMeasurementUnit }