import { Button } from 'primereact/button';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import categoryService from 'features/categories/_common/category.service';
import { GetCategoriesRequest } from 'features/categories/_common/get-categories/get-categories.request';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import TreeNode from 'primereact/treenode';
import { TreeSelect, TreeSelectSelectionKeyType, TreeSelectSelectionKeys } from 'primereact/treeselect';
import { GetCategoryTransportInformationByCategoryRequest } from 'features/categories/derived-features/category-transport-informations/get-category-transport-informations-by-category/get-category-transport-information-by-category.request';
import categoryTransportInformationsService from 'features/categories/derived-features/category-transport-informations/category-transport-informations-service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CategoryTransportInformationDto } from 'features/categories/derived-features/category-transport-informations/dtos/category-transport-information.dto';
import currencyHelper from 'helpers/curreny.helper';
import { Dropdown } from 'primereact/dropdown';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Skeleton } from 'primereact/skeleton';

const ShippingInformations = () => {
	const navigate = useNavigate();
	const opRef = useRef<OverlayPanel>(null);
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState<CategoryBaseDto[] | null>(null);
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<TreeSelectSelectionKeys>('');
	const [shippingInformations, setShippingInformations] = useState<CategoryTransportInformationDto[] | null>([]);

	const generateTreeNode = (category: CategoryBaseDto): TreeNode => {
		const treeNodeForCurrentSubCategory: TreeNode = { key: category.id, label: category.name, data: category };
		return treeNodeForCurrentSubCategory;
	};

	const mapCategoryToParent = useCallback((parentCategory: CategoryBaseDto, treeNode: TreeNode) => {
		if (parentCategory.subCategories === undefined) return;

		parentCategory?.subCategories?.forEach((_subCategory) => {
			if (treeNode.children === undefined) treeNode.children = [];

			const treeNodeForCurrentSubCategory = generateTreeNode(_subCategory);

			treeNode.children?.push(treeNodeForCurrentSubCategory);

			mapCategoryToParent(_subCategory, treeNodeForCurrentSubCategory);
		});
	}, []);

	const mapCategoriesForTree = useCallback(
		(categories: CategoryBaseDto[]) => {
			let treeNode: TreeNode[] = [];

			categories.forEach((_category) => {
				const treeNodeForCurrentCategory = generateTreeNode(_category);

				mapCategoryToParent(_category, treeNodeForCurrentCategory);

				treeNode.push(treeNodeForCurrentCategory);
			});

			setTreeCategories(treeNode);
		},
		[mapCategoryToParent]
	);

	const firstItemPriceBodyTemplate = (rowData: CategoryTransportInformationDto) => currencyHelper.formatPrice(rowData.firstItemPrice.formattedPricePerUnit);

	const additionalItemPriceBodyTemplate = (rowData: CategoryTransportInformationDto) => currencyHelper.formatPrice(rowData.additionalItemPrice.formattedPricePerUnit);

	const businessDayBodyTemplate = (rowData: CategoryTransportInformationDto) => rowData.minBusinessDay + ' - ' + rowData.maxBusinessDay + ' days';

	const sizeBodyTemplate = (rowData: CategoryTransportInformationDto) => rowData.size.name;

	const countriesBodyTemplate = (rowData: CategoryTransportInformationDto) => {
		const options = rowData.countries.map((country) => ({ label: country.country.name, value: country.countryId }));

		return (
			<>
				<span>{rowData.countries.length + ' countries'}</span>
				<i className="pi pi-fw ml-1 pi-eye text-indigo-500 cursor-pointer" onClick={(e) => opRef.current?.toggle(e)} />
				<OverlayPanel ref={opRef}>
					<ListBox options={options} filter listStyle={{ height: 200 }} />
				</OverlayPanel>
			</>
		);
	};

	const getCategories = useCallback(async () => {
		try {
			setLoading(true);

			const request = new GetCategoriesRequest();

			const response = await categoryService.getAll(request);

			if (!response.isSuccess) throw '';

			setCategories(response.data);
		} finally {
			setLoading(false);
		}
	}, []);

	const getTransportInformations = useCallback(async () => {
		try {
			setLoading(true);

			const request = new GetCategoryTransportInformationByCategoryRequest(selectedCategory?.toString() || '', { orderBy: 1, itemCount: null, pageNumber: 1, first: 0 });

			const response = await categoryTransportInformationsService.getTransportInformationsByCategory(request);

			if (!response.isSuccess) throw '';

			setShippingInformations(response.data);
		} catch {
			setShippingInformations([]);
		} finally {
			setLoading(false);
		}
	}, [selectedCategory]);

	useEffect(() => {
		if (!selectedCategory) return;

		getTransportInformations();
	}, [selectedCategory]);

	useEffect(() => {
		if (!categories || categories.length < 1) return;

		mapCategoriesForTree(categories);
	}, [categories, mapCategoriesForTree]);

	useEffect(() => {
		getCategories();
	}, [getCategories]);

	return (
		<div className="card">
			<div className="flex justify-content-between">
				<h5>Shipping Informations</h5>
				{!loading && <TreeSelect filter value={selectedCategory} options={treeCategories} onChange={(e) => setSelectedCategory(e.target.value)} placeholder="Select Category" />}
				<Button label="New Shipping Information" onClick={() => navigate('create')} icon="pi pi-plus" />
			</div>

			{!loading ? (
				<DataTable value={shippingInformations as any} paginator rows={10} dataKey="id" loading={loading} className="p-datatable-gridlines mt-4">
					<Column body={sizeBodyTemplate} header="Size" style={{ minWidth: '12rem' }} />
					<Column body={firstItemPriceBodyTemplate} header="First Item Price" style={{ minWidth: '12rem' }} />
					<Column body={additionalItemPriceBodyTemplate} header="Additional Item Price" style={{ minWidth: '12rem' }} />
					<Column body={businessDayBodyTemplate} header="Business Day" style={{ minWidth: '12rem' }} />
					<Column body={countriesBodyTemplate} header="Countries" style={{ minWidth: '12rem' }} />
				</DataTable>
			) : (
				<div className="grid mt-4">
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
				</div>
			)}
		</div>
	);
};

export default ShippingInformations;
