import HttpBaseService from "services/_common/http-base-service";
import { CreateOrderStatuHistoryRequest } from "./create-order-statu-history/create-order-statu-history.request";

class OrderStatuHistoriesService extends HttpBaseService {
	constructor() {
		super('order-statu-histories');
	}

	async create(request: CreateOrderStatuHistoryRequest) {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new OrderStatuHistoriesService();
