import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetProductsVariantsAndPriceRequest {
	pagination: PaginationDto = new PaginationDto();
	productId: string;
	colorId: string | null;
	sizeId: string | null;

	constructor(props: Partial<GetProductsVariantsAndPriceRequest>) {
		this.pagination = props.pagination || new PaginationDto();
		this.productId = props.productId || '';
		this.colorId = props.colorId || null;
		this.sizeId = props.sizeId || null;
	}

	get getConvertUriExtension() {
		return 'get-product-variants-and-price';
	}
}