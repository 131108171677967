import HttpBaseService from "services/_common/http-base-service";
import { GetModelsByBrandRequest } from "./get-models-by-brand/get-models-by-brand.request";
import { GetModelsByBrandResponse } from "./get-models-by-brand/get-models-by-brand.response";

class ModelService extends HttpBaseService {
	constructor() {
		super('models');
	}

	async getModelsByBrand(request: GetModelsByBrandRequest): Promise<GetModelsByBrandResponse> {
		return await this.getData(request.getConvertUriExtension)
	}
}

export default new ModelService();