import { Dialog } from 'primereact/dialog';
import { SelectedOrder } from '../models/selected-order';
import { GetMissingWeightVariantsByOrderRequest } from 'features/products/derived-features/product-variants/get-missing-weight-variants-by-order/get-missing-weight-variants-by-order.request';
import productVariantsService from 'features/products/derived-features/product-variants/product-variant.service';
import { useEffect, useState } from 'react';
import { CreateOrderStatuHistoryRequest } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';
import { InputText } from 'primereact/inputtext';
import { CopiedProductBase } from 'features/products/dtos/bases/copied-product.base.dto';
import { Button } from 'primereact/button';
import { CreateOrUpdateMissingWeightDimensionsRequest } from 'features/products/derived-features/product-variant-dimensions/create-or-update-missing-weight-dimensions/create-or-update-missing-weight-dimensions.request';
import productVariantDimensionsService from 'features/products/derived-features/product-variant-dimensions/product-variant-dimensions.service';
import { Skeleton } from 'primereact/skeleton';

type props = {
	statusVisible: boolean;
	setStatusVisible: Function;
	selectedOrder: SelectedOrder;
	setSelectedOrder: Function;
	getOrders: Function;
};

type TempWeight = { variantLabel: string; productVariantId: string; weight: string };

const UpdateWeightStatus = ({ statusVisible, setStatusVisible, selectedOrder, setSelectedOrder, getOrders }: props) => {
	const [loading, setLoading] = useState(false);
	const [variantWeights, setVariantWeights] = useState<TempWeight[]>([]);
	const [product, setProduct] = useState<CopiedProductBase>();

	const orderStatuUpdate = async () => {
		try {
			setLoading(true);

			const request = new CreateOrderStatuHistoryRequest({ orderId: selectedOrder.orderId, orderStatuId: selectedOrder.orderStatuId });

			const response = await orderStatuHistoriesService.create(request);

			if (!response.isSuccess) throw '';

			setSelectedOrder(undefined);
			getOrders();
			setStatusVisible(false);
		} finally {
			setLoading(false);
			setStatusVisible(false);
		}
	};

	const getMissingWeights = async () => {
		try {
			setLoading(true);

			const request = new GetMissingWeightVariantsByOrderRequest();

			request.orderId = selectedOrder.orderId;

			const response = await productVariantsService.getMissingWeightVariantsByOrder(request);

			if (!response.isSuccess) {
				setLoading(false);
				throw '';
			}

			if (!response.data) {
				orderStatuUpdate();
			} else {
				setProduct(response.data.copiedProduct);
				setVariantWeights(response.data.productVariants.map((vari) => ({ variantLabel: vari.displayText, productVariantId: vari.productVariantId, weight: response.data?.defaultValue.toString() || '0' })));
				setLoading(false);
			}
		} finally {
		}
	};

	const createMissingWeights = async () => {
		try {
			setLoading(true);

			const request = new CreateOrUpdateMissingWeightDimensionsRequest(variantWeights.map((vari) => ({ productVariantId: vari.productVariantId, weight: parseFloat(vari.weight) })));

			const response = await productVariantDimensionsService.createOrUpdateMissingWeightDimensions(request);

			if (!response.isSuccess) {
				setLoading(false);
				throw '';
			}

			orderStatuUpdate();
		} finally {
		}
	};

	const onWeightChange = (weight: string, variantId: string) => {
		setVariantWeights(
			variantWeights.map((vari) => {
				if (vari.productVariantId === variantId) return { ...vari, weight: weight };
				else return { ...vari };
			})
		);
	};

	useEffect(() => {
		if (!selectedOrder) return;

		getMissingWeights();
	}, [selectedOrder]);

	return (
		<Dialog
			header="Update Status"
			visible={statusVisible}
			onHide={() => {
				setSelectedOrder(undefined);
				setStatusVisible(false);
			}}>
			{!loading ? (
				<>
					{variantWeights.map((vari, index) => (
						<div key={index}>
							{index == 0 && (
								<>
									<span>Some weight data needs to be entered.</span>
									<hr />
								</>
							)}
							<span className="font-bold">{product?.name}</span>
							<div className="flex align-items-center justify-content-between">
								<span>{vari.variantLabel} :</span>
								<div className="flex flex-column">
									<span>Weight</span>
									<InputText value={variantWeights.find((weight) => weight.productVariantId === vari.productVariantId)?.weight} onChange={(e) => onWeightChange(e.target.value, vari.productVariantId)} keyfilter="num" />
								</div>
							</div>
							<hr />
						</div>
					))}
					<div className="w-full text-right">
						<Button label="Save & Confirm" onClick={() => createMissingWeights()} loading={loading} className="px-4" />
					</div>
				</>
			) : (
				<div className="grid">
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
					<div className="col-12">
						<Skeleton height="4rem" />
					</div>
				</div>
			)}
		</Dialog>
	);
};

export default UpdateWeightStatus;
