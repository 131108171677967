import HttpBaseService from 'services/_common/http-base-service';
import { GetAssignedProductVariantsRequest } from './get-assigned-product-variants/get-assigned-product-variants.request';
import { GetAssignedProductVariantsResponse } from './get-assigned-product-variants/get-assigned-product-variants.response';
import { AssignToPrintCentersRequest } from './assign-to-print-centers/assign-to-print-centers.request';
import { AssignToPrintCentersResponse } from './assign-to-print-centers/assign-to-print-centers.response';


class AssignedProductVariantToPrintCenterService extends HttpBaseService {
	constructor() {
		super('assigned-product-variant-to-print-centers');
	}

	async getAssignedProductVariants(request: GetAssignedProductVariantsRequest): Promise<GetAssignedProductVariantsResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async assignToPrintCenters(request: AssignToPrintCentersRequest): Promise<AssignToPrintCentersResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}
}

export default new AssignedProductVariantToPrintCenterService();
