import React from 'react';
import { Dialog } from 'primereact/dialog';
import { SellerStore } from 'features/seller-stores/dtos/seller-store.dto';
import { EtsyStore } from 'models/dtos/store/etsy-store';
import { Link } from 'react-router-dom';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { TfiNewWindow } from 'react-icons/tfi';
import { PiPlugsConnectedBold } from 'react-icons/pi';

type Props = {
	visible: boolean;
	store: SellerStore;
	etsyStore: EtsyStore;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setStore: React.Dispatch<React.SetStateAction<SellerStore | undefined>>;
	setEtsyStore: React.Dispatch<React.SetStateAction<EtsyStore | undefined>>;
	onSubmit: (connectData: { etsyStore: EtsyStore; sellerStore: SellerStore }) => void;
};

const ConnectStoreDialog = (props: Partial<Props>) => {
	return (
		<Dialog
			header="Connect your etsy store to printram store"
			visible={props.visible}
			onHide={() => {
				props.setVisible?.(false);
				props.setEtsyStore?.(undefined);
				props.setStore?.(undefined);
			}}>
			<div className="flex align-items-center gap-3">
				<img src="/logo/logo-renkli.svg" alt="Printram Logo Icon" loading="lazy" width={60} />
				<p>Let's connect your Etsy store to Printram store!</p>
			</div>

			<hr className="my-4" />

			<div className="flex align-items-center gap-3">
				<img src={props.etsyStore?.icon_url_fullxfull} alt={props.etsyStore?.shop_name} loading="lazy" width={60} />

				<div>
					<h5 className="m-0">{props.etsyStore?.shop_name}</h5>
					<p>{props.etsyStore?.title}</p>
				</div>

				<Link to={props.etsyStore?.url || ''} className="ml-auto">
					<PrButton icon={<TfiNewWindow />} text="Visit Etsy Store" type="secondary" />
				</Link>

				{!!props.etsyStore && !!props.store ? (
					<PrButton
						text="Complete Connection"
						icon={<PiPlugsConnectedBold size={20} />}
						onClick={() => {
							props.onSubmit?.({ etsyStore: props.etsyStore!, sellerStore: props.store! });
							props.setVisible?.(false);
							props.setEtsyStore?.(undefined);
							props.setStore?.(undefined);
						}}
					/>
				) : null}
			</div>
		</Dialog>
	);
};

export default ConnectStoreDialog;
