export class ReadyForShippingFromBarcodeWithOperatorRequest {
	barcode: string;
	onlyPrintModeActive: boolean;
	operatorId: number;

	constructor(barcode: string, onlyPrintModeActive: boolean, operatorId: number) {
		this.barcode = barcode;
		this.onlyPrintModeActive = onlyPrintModeActive;
		this.operatorId = operatorId;
	}

	get getConvertUriExtension() {
		return `ready-for-shipping-with-operator/${this.barcode && this.barcode + '/'}${this.onlyPrintModeActive}/${this.operatorId}`;
	}
}
