export class CreateSellerStoreRequest {
	sellerId: string;
	name: string;

	constructor(props: Partial<CreateSellerStoreRequest>) {
		this.sellerId = props.sellerId || '';
		this.name = props.name || '';
	}

	get getConvertUriExtension() {
		return `create`;
	}
}
