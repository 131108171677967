import HttpBaseService from 'services/_common/http-base-service';
import { CreateOrUpdateUserPrintSideDimensionRequest } from './create-or-update-user-print-side-dimension/create-or-update-user-print-side-dimension.request';
import { CreateOrUpdateUserPrintSideDimensionResponse } from './create-or-update-user-print-side-dimension/create-or-update-user-print-side-dimension.response';

class UserPrintSideDimensionService extends HttpBaseService {
	constructor() {
		super('user-print-side-print-dimensions');
	}

	createOrUpadte(request: CreateOrUpdateUserPrintSideDimensionRequest): Promise<CreateOrUpdateUserPrintSideDimensionResponse> {
		return this.postData(request.getConvertUriExtension, request);
	}
}

export const userPrintSideDimensionService = new UserPrintSideDimensionService();
