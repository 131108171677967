import { EntryDto } from 'features/stocks/_common/dtos/entry.dto';
import { OutDto } from 'features/stocks/_common/dtos/out.dto';
import React, { useRef, useState } from 'react';
import styles from './StockManagementTable.module.scss';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { PriceDto } from 'features/stocks/_common/dtos/price.dto';
import { OverlayPanel } from 'primereact/overlaypanel';
import { CoreProductForStockDto } from 'features/products/_common/dtos/core-product-for-stock.dto';
import { VariantForStockDto } from 'features/products/_common/dtos/variant-for-stock.dto';
import currencyHelper from 'helpers/curreny.helper';
import { ProfitError } from 'components/pages/stock/print-centers/StockManagement';

type props = {
	product?: CoreProductForStockDto | null;
	onSave: Function;
	saveLoading: boolean;
	entries: EntryDto[];
	setEntries: Function;
	outs: OutDto[];
	setOuts: Function;
	prices?: PriceDto[];
	setPrices?: Function;
	loading?: boolean;
	profitErrors?: ProfitError[];
};
const StockManagementTable = ({ product, onSave, saveLoading, entries, setEntries, outs, setOuts, prices, setPrices, loading, profitErrors }: props) => {
	const opRef = useRef<OverlayPanel>(null);
	const priceOpRef = useRef<OverlayPanel>(null);

	const [allStock, setAllStock] = useState<number>();

	const applyAllStock = () => {
		setEntries(product?.variants.map((vari) => ({ stockId: vari.stock.stockId, productVariantId: vari.productVariantId, quantity: allStock })));
		opRef.current?.hide();
	};

	const applyAllPrice = (allPrice: number) => {
		prices =
			prices?.map((_price) => {
				_price.price.formattedPricePerUnit = !!allPrice ? allPrice : 0;
				_price.price.pricePerUnit = !!allPrice ? allPrice * 100 : 0;
				return _price;
			}) || [];
	};

	const handleOnSave = () => {
		setAllStock(undefined);
		onSave();
	};

	const header = () => (
		<div className="w-full text-center">
			<span>{product?.name}</span>
		</div>
	);

	const priceHeader = () => (
		<React.Fragment>
			<span>Current Price</span>
			<i className="pi pi-fw ml-1 pi-cog text-indigo-500 cursor-pointer" onClick={(e) => priceOpRef.current?.toggle(e)} />
			<OverlayPanel ref={priceOpRef}>
				<div className="flex gap-2">
					<div className={`p-inputgroup w-8rem ${styles.entriesStockBtn}`}>
						<span className="p-inputgroup-addon">
							<i className="pi pi-dollar" />
						</span>

						<InputText type="number" onChange={(e) => applyAllPrice(+e.target.value)} />
					</div>

					<Button
						label="Apply All"
						onClick={() => {
							setEntries([...entries]);
							priceOpRef.current?.hide();
						}}
					/>
				</div>
			</OverlayPanel>
		</React.Fragment>
	);

	const increaseHeader = () => (
		<React.Fragment>
			<span>Increase</span>
			<i className="pi pi-fw ml-1 pi-cog text-indigo-500 cursor-pointer" onClick={(e) => opRef.current?.toggle(e)} />
			<OverlayPanel ref={opRef}>
				<div className="flex gap-2">
					<div className={`p-inputgroup w-8rem ${styles.entriesStockBtn}`}>
						<span className="p-inputgroup-addon">
							<i className="pi pi-plus" />
						</span>
						<InputText value={allStock} keyfilter="int" onChange={(e) => setAllStock(parseInt(e.target.value))} />
					</div>
					<Button label="Apply All" onClick={() => applyAllStock()} />
				</div>
			</OverlayPanel>
		</React.Fragment>
	);

	const skuBodyTemplate = (rowData: VariantForStockDto) => rowData.combinedSku;

	const onPriceChange = (productVariantId: string, value: number) => {
		prices &&
			setPrices &&
			setPrices(
				prices.map((price: PriceDto) => {
					if (price.productVariantId === productVariantId) {
						return { ...price, price: { formattedPricePerUnit: value, pricePerUnit: Math.round(value * 100) } };
					} else return price;
				})
			);
	};

	const printramPriceBodyTemplate = (rowData: VariantForStockDto) => <InputText value={currencyHelper.formatPrice(rowData.printramPrice?.formattedPricePerUnit || 0)} disabled className="w-8rem" />;

	const printCenterPriceBodyTemplate = (rowData: VariantForStockDto) => (
		<div className="p-inputgroup w-8rem">
			<span className="p-inputgroup-addon">
				<i className="pi pi-dollar" />
			</span>
			{prices && <InputText type="number" value={prices.find((price) => price.productVariantId === rowData.productVariantId)?.price?.formattedPricePerUnit || 0} onChange={(e: any) => onPriceChange(rowData.productVariantId, e.target.value)} />}
		</div>
	);

	const stockHeader = () => <div className="w-8rem text-center">Stock</div>;

	const stockBodyTemplate = (rowData: VariantForStockDto) => {
		const stock = rowData.stock?.quantity || 0;
		const entryQuantity = entries.find((entry) => entry.stockId === rowData.stock.stockId)?.quantity || 0;
		const outQuantity = outs.find((out) => out.stockId === rowData.stock.stockId)?.quantity || 0;
		const calculatedStock = stock + entryQuantity - outQuantity;

		return <span className="flex align-items-center justify-content-center surface-50 h-3rem border-round-lg text-400">{calculatedStock}</span>;
	};

	const onStockDecrease = (stockId: string, variantId: string, quantity: number) => {
		if (!variantId) return;

		if (outs.length > 0) {
			const index = outs.findIndex((stock) => stock.stockId === stockId);

			if (index === -1) {
				setOuts([...outs, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
				return;
			} else {
				setOuts(outs.map((stock, ind) => (ind === index ? { stockId: stockId, productVariantId: variantId, quantity: quantity } : stock)));
				return;
			}
		}

		setOuts([...outs, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
	};

	const decreaseBodyTemplate = (rowData: VariantForStockDto) => (
		<div className={`p-inputgroup w-8rem ${styles.outsStockBtn}`}>
			<span className="p-inputgroup-addon">
				<i className="pi pi-minus" />
			</span>
			<InputText value={outs.find((out) => out.stockId === rowData.stock.stockId)?.quantity || 0} keyfilter="int" onChange={(e) => onStockDecrease(rowData.stock.stockId,rowData.productVariantId, parseInt(e.target.value) || 0)} />
		</div>
	);

	const decreaseHeader = () => <div className="w-8rem text-center">Decrease</div>;

	const onStockIncrease = (stockId: string, variantId: string, quantity: number) => {
		if (!variantId) return;

		if (entries.length > 0) {
			const index = entries.findIndex((stock) => stock.stockId === stockId);

			if (index === -1) {
				setEntries([...entries, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
				return;
			} else {
				setEntries(entries.map((stock, ind) => (ind === index ? { stockId: stockId, productVariantId: variantId, quantity: quantity } : stock)));
				return;
			}
		}

		setEntries([...entries, { stockId: stockId, productVariantId: variantId, quantity: quantity }]);
	};
console.log("ENTRIES:", entries)
	const increaseBodyTemplate = (rowData: VariantForStockDto) => (
		<div className={`p-inputgroup w-8rem ${styles.entriesStockBtn}`}>
			<span className="p-inputgroup-addon">
				<i className="pi pi-plus" />
			</span>
			<InputText value={entries.find((entry) => entry.stockId === rowData.stock.stockId)?.quantity || 0} keyfilter="int" onChange={(e) => onStockIncrease(rowData.stock.stockId, rowData.productVariantId, parseInt(e.target.value) || 0)} />
		</div>
	);

	const handleFailedRowClass = (variant: VariantForStockDto) => {
		if (!profitErrors || profitErrors.length < 1) return {};

		const isErrorsExists = profitErrors.find((_profitError) => _profitError.productVariantId === variant.productVariantId);
		if (!isErrorsExists) return {};

		return { failed: true };
	};

	const displayTextBody = (variant: VariantForStockDto) => {
		if (!profitErrors || profitErrors.length < 1) return variant.displayText;

		const isErrorsExists = profitErrors.find((_profitError) => _profitError.productVariantId === variant.productVariantId);
		if (!isErrorsExists) return variant.displayText;

		return (
			<React.Fragment>
				<span className="mr-2">{variant.displayText}</span>
				<small className="font-bold">(Variant price should be max {currencyHelper.formatPrice(isErrorsExists.maxCanBeEnteredPrice.formattedPricePerUnit)})</small>
			</React.Fragment>
		);
	};

	return (
		<div className="data-table-stock">
			<DataTable header={header} value={product?.variants} loading={loading} paginator rows={10} rowClassName={handleFailedRowClass} currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 15, 20]} className="p-datatable-gridlines">
				<Column body={displayTextBody} field="displayText" header="Variant" />
				<Column body={skuBodyTemplate} header="Variant SKU" />
				<Column field="stock.stockLocationName" header="Location" />
				{product?.variants.find((vari) => vari.printramPrice) && <Column body={printramPriceBodyTemplate} header="Printram Price" className="w-8rem" />}
				{prices && <Column body={printCenterPriceBodyTemplate} header={priceHeader} className="w-8rem" />}
				<Column body={decreaseBodyTemplate} header={decreaseHeader} className="w-8rem text-center" />
				<Column body={stockBodyTemplate} header={stockHeader} className="w-8rem" />
				<Column body={increaseBodyTemplate} header={increaseHeader} className="w-8rem" />
			</DataTable>

			<div className="w-full text-right my-4">
				<Button type="button" label="Save Stocks" onClick={handleOnSave} loading={saveLoading} icon="pi pi-save" className="px-3" />
			</div>
		</div>
	);
};

export default StockManagementTable;
