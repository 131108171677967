import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { useState } from 'react';

type Props = {
	visible: boolean;
	onHide: () => void;
	onSubmit: (name: string) => void;
};

const CreateOperatorDialog = ({ visible, onHide, onSubmit }: Props) => {
	const [name, setName] = useState<string>('');
	const [error, setError] = useState<string>('');

	const submit = () => {
 		if (name === "") return setError("Please enter name");

		 onSubmit(name);
		 hide();
	}

	const hide = () => {
		dispose();
		onHide();
	}

	const dispose = () => {
		setName('');
		setError('');
	}

	return (
		<Dialog header="Create Operator" visible={visible} onHide={hide} style={{ maxWidth: '720px' }}>
			<div className="flex flex-column my-2">
				<label className="mb-2">Operator Name</label>
				<InputText name="operator-name" onChange={(event) => setName(event.target.value)} />
				{error !== '' && <p className="text-sm text-red mt-2">{error}</p>}
			</div>
			<PrButton onClick={() => submit()} text="Create Operator" className="ml-auto" />
		</Dialog>
	);
};

export default CreateOperatorDialog;
