import HttpBaseService from './_common/http-base-service';
import { LoginRequest } from '../models/requests/login-request';
import { DataResponse } from '../models/_commons/responses/data-response';
import { ResponseBase } from '../models/_commons/responses/response-base';
import localStorageHelper from '../helpers/local-storage.helper';
import { Auth } from '../models/dtos/auth/auth';
import { ChangePasswordRequest } from '../models/requests/users/change-password-request';
import { DeleteUserRequest } from '../models/requests/users/delete-user-request';
import { UpsertTermsRequest } from '../models/requests/users/terms/upsert-terms-request';

class UserService extends HttpBaseService {
    constructor() {
        super('users');
    }

    async login(request: LoginRequest) {
        const response = (await this.postData('login', request)) as DataResponse<Auth>;
        if (response.isSuccess) localStorageHelper.setStringfyForData('auth', response.data);

        return response;
    }

    async loginWithRefreshToken(request: { userId: string; refreshToken: string }) {
        const response = (await this.postData('login-with-refresh-token', request)) as DataResponse<Auth>;
        if (response.isSuccess) localStorageHelper.setStringfyForData('auth', response.data);

        return response;
    }

    async logout(): Promise<ResponseBase> {
        try {
            const response = (await this.getData('logout')) as ResponseBase;

            return response;
        } catch (error) {
            return { message: JSON.stringify(error) } as ResponseBase;
        } finally {
            localStorageHelper.remove('auth');
            localStorageHelper.remove('wizardAvaliable');
        }
    }

    async changePassword(request: ChangePasswordRequest): Promise<ResponseBase> {
        return await this.postData('change-password', request);
    }

    async upsertTerms(request: UpsertTermsRequest): Promise<ResponseBase> {
        return await this.postData('upsert-terms', request);
    }

    async delete(request: DeleteUserRequest): Promise<boolean> {
        return await this.deleteData('self-deleting', '', request);
    }
}

export default new UserService();
