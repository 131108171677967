import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductAttachmentCreateRequest, ProductAttachmentRequestDto } from 'features/products/derived-features/product-attachment/product-attachment-create/product-attachment-create.request';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { FileUpload } from 'primereact/fileupload';
import productAttachmentService from 'features/products/derived-features/product-attachment/product-attachment.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProductAttachmentGetRequest } from 'features/products/derived-features/product-attachment/product-attachment-get/product-attachment-get.request';
import { ProductAttachmentDto } from 'features/products/derived-features/product-attachment/product-attachment-get/product-attachment-get.response';
import mediaHelper from 'helpers/media.helper';
import axios from 'axios';

type Props = {
	setStep: React.Dispatch<React.SetStateAction<number>>;
};

const StepFive = (props: Props) => {
	const { productId } = useParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('Loading');
	const [attachments, setAttachments] = useState<ProductAttachmentDto[]>([]);
	const [attachmentRequests, setAttachmentRequests] = useState<ProductAttachmentRequestDto[]>([]);

	const getAttachments = async () => {
		try {
			setLoading(true);
			if (!productId) throw '';

			const response = await productAttachmentService.get(new ProductAttachmentGetRequest({ productId }));

			if (!response.isSuccess || !response.data) throw '';

			setAttachments(response.data);
		} catch (error) {
			setAttachments([]);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			if (!productId) throw '';

			const response = await productAttachmentService.create(new ProductAttachmentCreateRequest({ productId, attachments: attachmentRequests }), (event) => {
				const percentage = Math.round((event.loaded * 100) / event.total);
				if (percentage !== 100) setLoadingText((current) => `File Processing: ${percentage}%`);
				else setLoadingText('Processing');
			});

			if (!response.isSuccess) throw '';

			props.setStep((current) => current + 1);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!productId) return;

		getAttachments();
	}, [productId]);

	return (
		<React.Fragment>
			<div className="flex flex-wrap gap-3 mb-3">
				{attachments.map((_attachment) => (
					<div key={_attachment.id} className="flex gap-1">
						<button
							disabled={loading}
							onClick={async () => {
								try {
									const response = await axios.get(mediaHelper.getNewMediaImage(_attachment.media).url, { responseType: 'blob' });
									const href = URL.createObjectURL(response.data);

									const anchorElement = document.createElement('a');

									anchorElement.href = href;
									anchorElement.download = `${_attachment.header}-${productId}`;

									document.body.appendChild(anchorElement);

									anchorElement.click();

									document.body.removeChild(anchorElement);
									URL.revokeObjectURL(href);
								} catch (error) {}
							}}
							className="pr-btn pr-btn-secondary">
							<span className="pi pi-fw pi-download mr-2" />
							<span>{_attachment.header}</span>
						</button>

						<button
							disabled={loading}
							onClick={async () => {
								try {
									setLoading(true);
									if (!productId) throw '';

									const response = await productAttachmentService.softDelete(_attachment.id);
									if (!response) throw '';

									await getAttachments();
								} catch (error) {
								} finally {
									setLoading(false);
								}
							}}
							className="pr-btn pr-btn-danger">
							<span className="pi pi-fw pi-trash" />
						</button>
					</div>
				))}
			</div>

			<button
				onClick={() =>
					setAttachmentRequests((current) => {
						const newCurrent = structuredClone(current);

						newCurrent.push(new ProductAttachmentRequestDto({}));

						return newCurrent;
					})
				}
				className="pr-btn pr-btn-primary">
				<span className="pi pi-fw pi-plus-circle mr-2" />
				<span>Add</span>
			</button>

			<div className="grid my-3">
				<div className="col-12 xl:col-8 relative">
					<div className="flex flex-column gap-3">
						{attachmentRequests.map((_attachment) => (
							<React.Fragment key={_attachment.uniqueId}>
								<div className="flex gap-3">
									<PrTextInput
										label="Name"
										value={_attachment.header}
										onChange={(event) =>
											setAttachmentRequests((current) => {
												const newCurrent = structuredClone(current);

												for (const attachment of newCurrent) {
													if (attachment.uniqueId !== _attachment.uniqueId) continue;

													attachment.header = event.target.value;

													break;
												}

												return newCurrent;
											})
										}
										required
										placeholder="Enter attachment name"
									/>

									<button onClick={() => setAttachmentRequests((current) => structuredClone(current).filter((attachment: ProductAttachmentRequestDto) => attachment.uniqueId !== _attachment.uniqueId))} className="pr-btn pr-btn-danger mt-auto">
										<span className="pi pi-fw pi-trash mr-2" />
										<span>Delete</span>
									</button>
								</div>

								<FileUpload
									emptyTemplate={
										<div className="flex align-items-center flex-column">
											<span className="font-semibold text-xl">Drag and Drop Attachment File Here</span>
											<span className="mt-2 font-semibold text-color-secondary">Supported file extensions; Only SVG Files</span>
										</div>
									}
									headerTemplate={(options) => (
										<div className={options.className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
											{options.chooseButton}
										</div>
									)}
									accept=".svg"
									chooseLabel="Browse Attachment File"
									onSelect={(event) =>
										setAttachmentRequests((current) => {
											const newCurrent = structuredClone(current);

											for (const attachment of newCurrent) {
												if (attachment.uniqueId !== _attachment.uniqueId) continue;

												attachment.file = [...event.files][0];

												break;
											}

											return newCurrent;
										})
									}
									onRemove={() =>
										setAttachmentRequests((current) => {
											const newCurrent = structuredClone(current);

											for (const attachment of newCurrent) {
												if (attachment.uniqueId !== _attachment.uniqueId) continue;

												attachment.file = null;

												break;
											}

											return newCurrent;
										})
									}
								/>
							</React.Fragment>
						))}
					</div>

					{attachmentRequests.length > 0 ? (
						<div className="mt-4">
							<button onClick={handleSubmit} disabled={loading || attachmentRequests.length < 1 || attachmentRequests.findIndex((_attachment) => _attachment.header.length < 1 || !_attachment.file) > -1} className="pr-btn pr-btn-primary">
								<span className="pi pi-fw pi-arrow-right mr-2" />
								<span>Confirm & Next</span>
							</button>
						</div>
					) : null}

					{loading && attachmentRequests.length > 0 ? (
						<div className="custom-loading">
							<div className="flex flex-column text-0">
								<h4>{loadingText}</h4>
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		</React.Fragment>
	);
};

export default StepFive;
