import { CreateOrderStatuHistoryRequest, OrderStatuHistoryShipmentTypeRequestDto } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import { GetShippoShipmentsRequest } from 'features/orders/derived-features/order-transports/get-shippo-shipments/get-shippo-shipments.request';
import { ShippoShipmentDto, ShippoShipmentRateDto } from 'features/orders/derived-features/order-transports/get-shippo-shipments/get-shippo-shipments.response';
import { orderTransportService } from 'features/orders/derived-features/order-transports/order-transport.service';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import currencyHelper from 'helpers/curreny.helper';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import styles from './CreateOrderStatuHistoryAcknowledgeModal.module.scss';

type Props = {
	orderId: string;
	callback: (request: CreateOrderStatuHistoryRequest) => void;
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateOrderStatuHistoryAcknowledgeModal = (props: Partial<Props>) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [shippoShipment, setShippoShipment] = useState<ShippoShipmentDto | undefined>(undefined);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(2);

	const getShippoShipments = async () => {
		try {
			setLoading(true);

			if (!props.orderId) throw '';

			const response = await orderTransportService.getShippoShipments(new GetShippoShipmentsRequest(props.orderId));
			if (!response.isSuccess || !response.data) throw '';

			setShippoShipment(response.data);
		} catch (error) {
			setShippoShipment(undefined);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!props.visible) return;

		getShippoShipments();
	}, [props.visible]);

	return (
		<Dialog
			header='Select a shipping service and update status to "Order Approved"'
			visible={props.visible}
			onHide={() => {
				props.setVisible?.(false);
				setShippoShipment(undefined);
				setSelectedPaymentMethod(2);
			}}>
			<div className={styles.paymentMethodCard}>
				<p>Please select your payment method for this order.</p>
				<div className={styles.buttons}>
					<button className={`${styles.leftBtn} ${selectedPaymentMethod === 2 ? styles.activeBtn : styles.passiveBtn}`} onClick={() => setSelectedPaymentMethod(2)}>
						<i className="pi pi-fw pi-wallet mr-1" style={{ fontSize: '.9rem' }} />
						Printram Balance
					</button>
					<button className={`${styles.rightBtn} ${selectedPaymentMethod === 1 ? styles.activeBtn : styles.passiveBtn}`} onClick={() => setSelectedPaymentMethod(1)}>
						<i className="pi pi-fw pi-credit-card mr-1" style={{ fontSize: '.9rem' }} />
						Credit Card
					</button>
				</div>
			</div>
			<DataTable
				selectionMode="single"
				dataKey="object_id"
				showGridlines
				className="pr-table"
				loading={loading}
				value={shippoShipment?.rates}
				onSelectionChange={(e) => {
					const data: ShippoShipmentRateDto = e.value;

					const msg = (
						<div>
							<span>You selected - </span>
							<span className="font-bold">
								{data.provider} - {data.servicelevel.name}
							</span>
							<p>{data.duration_terms}</p>
							<span>Selected Payment Type: </span>
							<span className="font-bold">{selectedPaymentMethod === 2 ? 'Printram Balance' : 'Credit Card'}</span>
							<p className="m-0">Do you confirm?</p>
						</div>
					);

					confirmDialog({
						header: 'Confirm your selection',
						message: msg,
						icon: 'pi pi-info-circle',
						acceptLabel: 'Accept',
						rejectLabel: 'Cancel',
						acceptClassName: 'px-4',
						rejectClassName: 'p-button-text px-4',
						style: { maxWidth: '48rem' },
						accept: () => {
							props.callback?.(
								new CreateOrderStatuHistoryRequest({
									orderId: props.orderId,
									orderStatuId: OrderStatuTypes.OrderApproved,
									shipmentType: new OrderStatuHistoryShipmentTypeRequestDto({
										shippoShipmentId: shippoShipment?.shipmentId,
										shippoRateId: data.object_id
									}),
									paymentMethodType: selectedPaymentMethod
								})
							);

							props.setVisible?.(false);
							setShippoShipment(undefined);
						}
					});
				}}>
				<Column header="Provider" sortable field="provider" />
				<Column header="Service Level" field="servicelevel.name" />
				<Column header="Estimated Days" sortable field="estimated_days" body={(e: ShippoShipmentRateDto) => `${e.estimated_days} Days`} />
				<Column header="Duration Terms" field="duration_terms" />
				<Column header="Amount" sortable field="amount" body={(e: ShippoShipmentRateDto) => currencyHelper.formatPrice(+e.amount)} />
			</DataTable>
		</Dialog>
	);
};

export default CreateOrderStatuHistoryAcknowledgeModal;
