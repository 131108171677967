import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import localStorageHelper from '../../../helpers/local-storage.helper';
import { Auth } from '../../../models/dtos/auth/auth';
import { RegisterOrLoginWithGoogleRequest } from '../../../models/requests/google-login-request';
import { LoginRequest } from '../../../models/requests/login-request';
import { DataResponse } from '../../../models/_commons/responses/data-response';
import sellerService from '../../../services/seller-service';
import { LoginWithAutomaticLoginRequest } from 'features/users/_common/login-with-automatic-login/login-with-automatic-login.request';
import userService from '../../../services/user-service';
import userServiceNew from 'features/users/_common/user.service';
import { PhoneNumberDto } from 'features/_common/dtos/user/phone-number.dto';

const auth = localStorageHelper.getParsed('auth');

const initialState: DataResponse<Auth> = {
	data: auth
};

export const login = createAsyncThunk('auth/login', async (request: LoginRequest) => await userService.login(request));

export const autoLogin = createAsyncThunk('auth/autoLogin', async (request: LoginWithAutomaticLoginRequest) => await userServiceNew.autoLogin(request));

export const logout = createAsyncThunk('auth/logout', async () => await userService.logout());

export const googleLogin = createAsyncThunk('auth/googleLogin', async (request: RegisterOrLoginWithGoogleRequest) => await sellerService.registerOrLoginWithGoogle(request));

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setReNewAuth: (state, action: PayloadAction<Auth>) => {
			localStorageHelper.setStringfyForData('auth', action.payload);
			state.data = action.payload;
			return state;
		},
		resetAuth: (state) => {
			localStorageHelper.remove('auth');
			state.data = null;
			return state;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.fulfilled, (state, action: PayloadAction<DataResponse<Auth>>) => {
				state.data = action.payload.data;
			})
			.addCase(autoLogin.fulfilled, (state, action: PayloadAction<DataResponse<Auth>>) => {
				state.data = action.payload.data;
			})
			.addCase(logout.pending, (state) => {
				state.data = null;
			})
			.addCase(googleLogin.fulfilled, (state, action: PayloadAction<DataResponse<Auth>>) => {
				state.data = action.payload.data;
			})
	}
});

export const { resetAuth, setReNewAuth } = authSlice.actions;
export default authSlice.reducer;
