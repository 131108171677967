export class DeleteBulkVariantRequest {
	private identities: string[];

	constructor(identities: string[]) {
		this.identities = identities;
	}

	get getConvertUriExtension() {
		const query = this.identities.map((_id, index) => `${index === 0 ? '' : '&'}id=${_id}`);
		return `?${query.join('').replaceAll(',', '')}`;
	}
}
