import LocalStorageKey from './constants/local-storage-keys';
import cryptoHelper from './crypto.helper';

type LSKey = `${LocalStorageKey}`;

const clear = (): void => localStorage.clear();

const length = (): number => localStorage.length;

const get = (key: LSKey): string | null => cryptoHelper.decrypt(localStorage.getItem(key));

const getParsed = (key: LSKey): any | null => JSON.parse(get(key) || 'null');

const set = (key: LSKey, value: string): void => {
    if (!value) return;

    const encryptedValue = cryptoHelper.encrypt(value);
    if (!encryptedValue) return;

    localStorage.setItem(key, encryptedValue);
};

const setStringfyForData = (key: LSKey, value: any): void => set(key, JSON.stringify(value));

const remove = (key: LSKey): void => localStorage.removeItem(key);

const localStorageHelper = { clear, length, get, getParsed, set, setStringfyForData, remove };

export default localStorageHelper;
