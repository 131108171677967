import { CreateModelRequestDto } from "./dtos/create-model.dto";

export class CreateEtsySynchronizeProductsWithSidesRequest {
	createModel: CreateModelRequestDto;

	constructor(props: Partial<CreateEtsySynchronizeProductsWithSidesRequest>) {
		this.createModel = props.createModel || new CreateModelRequestDto({});
	}

	get getConvertUriExtension() {
		return 'create-synchronize-products';
	}
}
