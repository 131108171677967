export class IncreaseBalanceRequest {
	userId: string = '';
	amount: number = 0;
	description: string = "";

	constructor(userId: string, amount: number, description: string) {
		this.userId = userId;
		this.amount = amount;
		this.description = description;
	}

	get getConvertUriExtension() {
		return `${this.userId}/increase`;
	}
}