import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import PasswordInput from '../../helpers/widgets/Forms/PasswordInput';
import TextInput from '../../helpers/widgets/Forms/TextInput';
import { RegisterIndividualRequest } from '../../models/requests/register-individual-request';
import sellerService from '../../services/seller-service';
import LoginWithGoogle from './LoginWithGoogle';
import styles from './RegisterIndividual.module.scss';
import PrivacyPolicy from './TermsAndPolicy/PrivacyPolicy';
import TermOfService from './TermsAndPolicy/TermOfService';

type WindowWithDataLayer = Window & {
	gtag: Function;
};

declare const window: WindowWithDataLayer;
const RegisterIndividual = () => {
	const navigate = useNavigate();

	const formikRef = useRef<FormikProps<RegisterIndividualRequest>>(null);
	const [disable, setDisable] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [visibleTermsOfUse, setVisibleTermsOfUse] = useState(false);
	const [visiblePrivacyPolicy, setVisiblePrivacyPolicy] = useState(false);

	const initialValues: RegisterIndividualRequest = {
		name: '',
		surname: '',
		email: '',
		password: '',
		passwordConfirm: '',
		isTermsConfirmed: false
	};

	const validationScheme = Yup.object().shape({
		name: Yup.string().required('Name is Required').min(3, 'Name must be at least 3 characters.').max(50, 'Name must be a maximum of 50 characters.'),
		surname: Yup.string().required('Surname is Required').min(2, 'Surname must be at least 2 characters.').max(50, 'Surname must be a maximum of 50 characters.'),
		email: Yup.string().email('Email address must be valid.').required('Email is Required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
		password: Yup.string().required('Password is Required').min(5, 'Password must be at least 5 characters.'),
/*		passwordConfirm: Yup.string()
			.required('Password Confirm is Required')
			.oneOf([Yup.ref('password'), null], 'Password must be match'),*/
		isTermsConfirmed: Yup.bool().required('Terms is required').oneOf([true], 'Terms must be checked')
	});

	const redirectAutoLogin = (token: string) => navigate(`/login?autoLoginToken=${token}`);
	const redirectLogin = () => navigate('/login');
	const termOfService = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.preventDefault();
		setVisibleTermsOfUse(true);
	};
	const privacyPolicy = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.preventDefault();
		setVisiblePrivacyPolicy(true);
	};

	const submitForm = async (value: RegisterIndividualRequest) => {
		setDisable(true);
		setLoading(true);

		ReactPixel.track('CompleteRegistration');

		const registerResponse = await sellerService.register({
			name: value.name,
			surname: value.surname,
			email: value.email,
			password: value.password,
			passwordConfirm: value.password,
			isTermsConfirmed: true }).finally(() => setLoading(false));

		if (registerResponse) {
			window.gtag("event","sign_up",{send_to: "G-1J986ZCJJH",method:"Direct"});
			//	redirectLogin();
			redirectAutoLogin(registerResponse.data.automaticLogin.accessToken);
		}

		setDisable(false);
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement> | CheckboxChangeParams) => {
		if (!formikRef.current) return;

		formikRef.current.handleChange(event);

		if (!disable) return;

		setDisable(false);
	};

	useEffect(() => {
		ReactPixel.pageView();
	}, []);

	return (
		<div className={styles.registerWrapper}>
			<section className={styles.formSide}>
				<main className={styles.registerMain}>
					<div className="flex justify-content-center align-items-center mt-5">
						<img alt="printram logo" src="/logo/printram-main-logo-sm.svg" />
					</div>

					<div className={styles.description}>
						<h1 className={styles.title}>Sign up to Printram</h1>
						<p className={styles.text}>Sign up with existing accounts to make it faster</p>
					</div>

					<div className={styles.socialActionWrapper}>
						{/* <DownloadButton /> */}
						<LoginWithGoogle setDisable={setDisable} />

						<div className={styles.divider}>
							<span className={styles.line}></span>
							<span className={styles.text}>or</span>
							<span className={styles.line}></span>
						</div>
					</div>

					<Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationScheme} onSubmit={(values) => submitForm(values)}>
						{({ values }) => (
							<Form className={styles.registerForm}>
								<div className="flex flex-row">
									<div className={`${styles.formGroup} mr-4`}>
										<TextInput labelStyle={{ fontWeight: '600' }} style={{ borderColor: '#D1D3D4', borderRadius: '10px' }} label="Name" name="name" id="name" onChange={handleInputChange} keyfilter={/[A-Z a-z]/} />
									</div>

									<div className={`${styles.formGroup}`}>
										<TextInput labelStyle={{ fontWeight: '600' }} style={{ borderColor: '#D1D3D4', borderRadius: '10px' }} label="Surname" name="surname" id="surname" onChange={handleInputChange} keyfilter={/[A-Z a-z]/} />
									</div>
								</div>

								<div className={styles.formGroup}>
									<TextInput labelStyle={{ fontWeight: '600' }} style={{ borderColor: '#D1D3D4', borderRadius: '10px' }} label="Email" name="email" id="email" onChange={handleInputChange} keyfilter={'email'} />
								</div>

								<div className={styles.formGroup}>
									<PasswordInput
										labelStyle={{ fontWeight: '600' }}
										inputStyle={{
											borderColor: '#D1D3D4',
											borderRadius: '10px'
										}}
										type="password"
										label="Password"
										name="password"
										id="password"
										onChange={handleInputChange}
										toggleMask
										feedback={false}
									/>
								</div>

								{/*								<div className={styles.formGroup}>
									<PasswordInput
										labelStyle={{ fontWeight: '600' }}
										inputStyle={{
											borderColor: '#D1D3D4',
											borderRadius: '10px'
										}}
										type="password"
										label="Password Confirm"
										name="passwordConfirm"
										id="passwordConfirm"
										onChange={handleInputChange}
										toggleMask
										feedback={false}
									/>
								</div>*/}

								<div style={{ width: '100%' }}>
									<div style={{ marginBottom: '.5rem' }}>
										<ErrorMessage component="small" name="isTermsConfirmed" className="text-red" />
									</div>

									<Checkbox name="isTermsConfirmed" inputId="isTermsConfirmed" checked={values.isTermsConfirmed} onChange={handleInputChange} className={`${styles.checkbox} mr-1`} />

									<label htmlFor="isTermsConfirmed" className="p-checkbox-label ml-2 text-sm" style={{ color: '#252F4A' }}>
										I have read and agree to the{' '}
										<span className="cursor-pointer font-normal" onClick={(e) => termOfService(e)}>
											{' '}
											Terms of Service
										</span>{' '}
										and{' '}
										<span className="cursor-pointer" onClick={(e) => privacyPolicy(e)}>
											{' '}
											Privacy Policy
										</span>
									</label>
									<TermOfService visible={visibleTermsOfUse} setVisible={setVisibleTermsOfUse} />
									<PrivacyPolicy visible={visiblePrivacyPolicy} setVisible={setVisiblePrivacyPolicy} />
								</div>

								<Button type="submit" disabled={disable} className={`${styles.registerButton} mt-1 mr-auto`}>
									{loading ? <ProgressSpinner strokeWidth="4" animationDuration=".5s" style={{ width: '1.3rem', height: '1.3rem' }} /> : <span className="mx-auto">Create an Account</span>}
								</Button>
							</Form>
						)}
					</Formik>

					<div>
						Have an account?{' '}
						<Link to="/login" className="text-primary">
							Login
						</Link>
					</div>
				</main>
			</section>

			<section className={styles.imageSide}>
				<div className={styles.image} />
			</section>

			<section className={`${styles.mobileImageSide}`}>
				<div className={styles.image} />
			</section>
		</div>
	);
};

export default RegisterIndividual;
