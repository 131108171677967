import HttpBaseService from 'services/_common/http-base-service';
import { GetPrintramStockRequest } from './get-printram-stocks/get-printram-stock.request';
import { GetPrintramStockResponse } from './get-printram-stocks/get-printram-stock.response';
import { GetPrintCenterStockDto } from './get-print-center-stocks/dtos/get-print-center-stocks.dto';
import { GetPrintCenterStockRequest } from './get-print-center-stocks/get-print-center-stocks.request';
import { GetPrintCenterStockResponse } from './get-print-center-stocks/get-print-center-stocks.response';
import { CreateForAdminRequest } from "./product-variant-stocks/printram/create/create-for-admin.request";
import { CreateForAdminResponse } from "./product-variant-stocks/printram/create/create-for-admin.response";
import { CreateForPrintCenterRequest } from './product-variant-stocks/print-center/create/create-for-print-center.request';
import { CreateForPrintCenterResponse } from './product-variant-stocks/print-center/create/create-for-print-center.response';


class StockService extends HttpBaseService {
	constructor() {
		super('stocks');
	}

	async getPrintramStocks(request: GetPrintramStockRequest): Promise<GetPrintramStockResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getPrintCenterStocks(request: GetPrintCenterStockRequest): Promise<GetPrintCenterStockResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createForAdmin(request: CreateForAdminRequest): Promise<CreateForAdminResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createForPrintCenter(request: CreateForPrintCenterRequest): Promise<CreateForPrintCenterResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new StockService();
