import { ModelDto } from "../dtos/model.dto";

export class UpdateRequest {
	merchantId: string;
	productId: string;
	model: ModelDto;
	categoryIdentities: Array<string> | null;
	name: string;
	description: string;
	productStatuId: number;

	get getConvertUriExtension() {
		return `update`;
	}

	constructor(merchantId: string, productId: string, model: ModelDto, categoryIdentities: Array<string> | null, name: string, description: string, productStatuId: number) {
		this.merchantId = merchantId;
		this.productId = productId;
		this.model = model;
		this.categoryIdentities = categoryIdentities;
		this.name = name;
		this.description = description;
		this.productStatuId = productStatuId;
	}
}
