import 'core-js/stable';
import { injectStore } from 'interceptors/axios-interceptor';
import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'regenerator-runtime/runtime';
import AppWrapper from './AppWrapper';
import './assets/styles/config/variables.scss';
import { store } from './redux/store';

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// serviceWorkerDev();

ReactPixel.init('1543334096485482', undefined, { autoConfig: true, debug: true });

root.render(
	<HashRouter>
		<Provider store={store}>
			<AppWrapper />
			<ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover />
		</Provider>
	</HashRouter>
);
