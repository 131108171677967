export class ConvertImageToBase64Request {
    private urls: string[];

    constructor(urls: string[]) {
        this.urls = urls;
    }

    get getConvertUriExtension() {
        return `convert-image-to-base64`;
    }
}
