import { StockLocationDto } from "features/stockmanagement/dtos/stock-location.dto";

export class UpdateStockLocationRequest {
	stockLocationId: string;
	name: string;
	operationManager: string;
	pickUp: boolean;
	countryId: number;
	stateId: number;
	zipCode: number | string;
	city: string;
	addressLine1: string;
	addressLine2: string;

	get getConvertUriExtension() {
		return 'update-stock-location';
	}

	constructor(props: Partial<UpdateStockLocationRequest>) {
		this.stockLocationId = props.stockLocationId || '';
		this.name = props.name || '';
		this.operationManager = props.operationManager || '';
		this.pickUp = props.pickUp || false;
		this.countryId = props.countryId || 0;
		this.stateId = props.stateId || 0;
		this.zipCode = props.zipCode || 0;
		this.city = props.city || '';
		this.addressLine1 = props.addressLine1 || '';
		this.addressLine2 = props.addressLine2 || '';
	}

	initUpdateDataFromAccountData(stockLocationId: string, data: StockLocationDto) {
		this.stockLocationId = stockLocationId;
		this.name = data.name;
		this.operationManager = data.operationManager;
		this.pickUp = data.pickUp;
		this.countryId = data.address.countryId;
		this.stateId = data.address.stateId;
		this.zipCode = data.address.zipCode;
		this.city = data.address.city;
		this.addressLine1 = data.address.addressLine1;
		this.addressLine2 = data.address.addressLine2 || "";
	}
}