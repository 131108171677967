import { CreateEtsySynchronizeProductsRequest } from 'features/integrations/_common/create-etsy-synchronize-products/create-etsy-sycnhronize-product.request';
import { CreateModelRequestDto } from 'features/integrations/_common/create-etsy-synchronize-products/dtos/create-model.dto';
import { SyncEtsyProduct } from 'features/integrations/_common/create-etsy-synchronize-products/dtos/sync-etsy-product.dto';
import integrationsService from 'features/integrations/_common/integrations.service';
import { ExternalProductDto } from 'features/orders/_common/get-external-order-by-id/dtos/external-product.dto';
import { GetExternalOrderByIdRequest } from 'features/orders/_common/get-external-order-by-id/get-external-order-by-id.request';
import { ExternalOrderDto } from 'features/orders/_common/get-external-order-by-id/get-external-order-by-id.response';
import orderService from 'features/orders/_common/order.service';
import currencyHelper from 'helpers/curreny.helper';
import dateHelper from 'helpers/dateHelper';
import mediaHelper from 'helpers/media.helper';
import toastHelper from 'helpers/toast.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styles from './ExternalOrderDetail.module.scss';

const ExternalOrderDetail = () => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const { orderId } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('Loading');
	const [visible, setVisible] = useState<boolean>(false);
	const [externalOrder, setExternalOrder] = useState<ExternalOrderDto | undefined>(undefined);
	const [externalProducts, setExternalProducts] = useState<SyncEtsyProduct[]>([]);
	const [selectedExternalProduct, setSelectedExternalProduct] = useState<ExternalProductDto | undefined>(undefined);
	const [orderNote, setOrderNote] = useState<string | null>(null);

	const getExternalOrder = async () => {
		try {
			setLoading(true);
			if (!orderId) throw '';

			const response = await orderService.getExternalOrderById(new GetExternalOrderByIdRequest(orderId));
			if (!response || !response.isSuccess || !response.data) throw '';

			setExternalOrder(response.data);

			const newExternalProducts: SyncEtsyProduct[] = [];

			for (const externalProduct of response.data.externalProducts) {
				newExternalProducts.push({
					externalProductId: externalProduct.externalProductId,
					externalPrice: externalProduct.externalPrice,
					externalProductVariation: externalProduct.externalProductVariation,
					productId: '',
					productVariantId: '',
					designFile: null,
					productImageFile: null,
					transactionId: externalProduct.etsyTransaction.transaction_id,
					sellerNote: null
				});
			}

			setExternalProducts(newExternalProducts);

			console.clear();
		} catch (error) {
			setExternalOrder(undefined);
		} finally {
			setLoading(false);
		}
	};

	const getMockupImageByExternalProductId = (transactionId: number) => externalProducts.find((_product) => _product.transactionId === transactionId)?.productImageFile || null;
	const getPrintFileByExternalProductId = (transactionId: number) => externalProducts.find((_product) => _product.transactionId === transactionId)?.designFile || null;

	const getValue = (key: string, value: string | number | boolean) => {
		if (!value) return '-';

		switch (typeof value) {
			case 'string':
				return value;

			case 'boolean':
				return value ? 'True' : 'False';

			case 'number':
				return key.includes('timestamp') ? dateHelper.formatDate(new Date(value)) : value.toString();

			default:
				return '-';
		}
	};

	const setProductSellerNote = (event: any, editedProduct: ExternalProductDto) => {
		setExternalProducts(
			externalProducts.map((_product) => {
				if (_product.externalProductId === editedProduct.externalProductId) {
					return { ..._product, sellerNote: event.target.value };
				} else {
					return _product;
				}
			})
		);
	};

	const titleBodyTemplate = (row: ExternalProductDto) => (
		<div>
			<div>{row.title}</div>
			<span>Note</span>
			<input type="text" className="pr-input-control w-16rem ml-2 mt-2" placeholder="Product Note (Optional)" onChange={(event) => setProductSellerNote(event, row)} />
		</div>
	);

	const handleSubmit = async () => {
		try {
			setLoading(true);

			if (externalProducts.findIndex((_product) => !_product.productId || !_product.designFile || !_product.productImageFile) > -1) {
				toastHelper.warning('Invalid data. Please update your product');

				const variantTable = document.getElementById('externalVariantTable');
				variantTable?.scrollIntoView();

				throw '';
			}

			const response = await integrationsService.createSynchronizeProducts(
				new CreateEtsySynchronizeProductsRequest({
					createModel: new CreateModelRequestDto({
						orderId,
						sellerId: user?.userId,
						products: externalProducts,
						sellerNote: orderNote || null
					})
				}),
				(event) => {
					const percentage = Math.round((event.loaded * 100) / event.total);
					if (percentage !== 100) setLoadingText((current) => `File Processing: ${percentage}%`);
					else setLoadingText('Processing');
				}
			);

			if (!response.isSuccess) throw '';

			navigate(`/product/seller/order/${orderId}`);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!orderId) return;

		getExternalOrder();
	}, [orderId]);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Sync Your Order</h1>

				<div className="container-header-tools">
					<PrButton onClick={() => navigate(-1)} type="secondary" text="Go Back" icon={<span className="pi pi-fw pi-arrow-left" />} />
				</div>
			</div>

			<div id="externalVariantTable" className="container-body p-3" style={{ scrollMarginTop: '8rem' }}>
				<h4 className="m-0">Your Order</h4>
				<p className="text-color-secondary">You can use this screen to match your Etsy order with the Printram order.</p>

				<div>
					<div className="text-color-secondary">Add Seller Note (Optional)</div>
					<input type="text" className="pr-input-control w-28rem" placeholder="Seller Note" onChange={(event) => setOrderNote(event.target.value || null)} />
				</div>

				<DataTable value={externalOrder?.externalProducts} className="p-datatable-gridlines mt-3">
					<Column header="Title" body={titleBodyTemplate} />
					<Column header="Quantity" field="quantity" />
					<Column header="Price" body={(row: ExternalProductDto) => currencyHelper.formatPrice(row.externalPrice.formattedPricePerUnit)} />
					<Column
						header="Variations"
						body={(row: ExternalProductDto) => (
							<div className="flex flex-column gap-1">
								{row.externalProductVariation.variations.map((_variation, _vIndex) => (
									<div key={_vIndex}>
										<span className="font-bold" dangerouslySetInnerHTML={{ __html: `${_variation.key}:` }} />
										<span className="ml-2" dangerouslySetInnerHTML={{ __html: _variation.value }} />
									</div>
								))}
							</div>
						)}
					/>
					<Column
						header="Mockup Image"
						style={{ width: '1rem' }}
						body={(row: ExternalProductDto) => (
							<div className="flex flex-column gap-2 align-items-center justify-content-center">
								<input
									type="file"
									accept=".svg, .png, .jpeg, .jpg"
									id={`mockup${row.etsyTransaction.transaction_id}`}
									name="file"
									className="hidden"
									onChange={(e) => {
										const file = e.target.files?.[0];
										if (!file) return;

										setExternalProducts((_current) => {
											const newCurrent = structuredClone(_current);

											for (const product of newCurrent) {
												if (product.transactionId !== row.etsyTransaction.transaction_id) continue;

												product.productImageFile = file || null;

												break;
											}

											return newCurrent;
										});
									}}
								/>

								<PrButton
									type="secondary"
									text="Browse"
									icon={<span className="pi pi-fw pi-plus" />}
									onClick={() => {
										const fileInput = document.getElementById(`mockup${row.etsyTransaction.transaction_id}`) as HTMLInputElement | null;
										if (!fileInput) return;

										fileInput.click();
									}}
								/>

								{!!getMockupImageByExternalProductId(row.etsyTransaction.transaction_id) ? <img className={styles.img} src={URL.createObjectURL(getMockupImageByExternalProductId(row.etsyTransaction.transaction_id)!)} alt={getMockupImageByExternalProductId(row.etsyTransaction.transaction_id)?.name} height={80} /> : null}
							</div>
						)}
					/>
					<Column
						header="Print File"
						style={{ width: '1rem' }}
						body={(row: ExternalProductDto) => (
							<div className="flex flex-column gap-2 align-items-center justify-content-center">
								<input
									type="file"
									accept=".svg, .png, .jpeg, .jpg"
									id={`print${row.etsyTransaction.transaction_id}`}
									name="file"
									className="hidden"
									onChange={(e) => {
										const file = e.target.files?.[0];
										if (!file) return;

										setExternalProducts((_current) => {
											const newCurrent = structuredClone(_current);

											for (const product of newCurrent) {
												if (product.transactionId !== row.etsyTransaction.transaction_id) continue;

												product.designFile = file || null;

												break;
											}

											return newCurrent;
										});
									}}
								/>

								<PrButton
									type="secondary"
									text="Browse"
									icon={<span className="pi pi-fw pi-plus" />}
									onClick={() => {
										const fileInput = document.getElementById(`print${row.etsyTransaction.transaction_id}`) as HTMLInputElement | null;
										if (!fileInput) return;

										fileInput.click();
									}}
								/>

								{!!getPrintFileByExternalProductId(row.etsyTransaction.transaction_id) ? <img className={styles.img} src={URL.createObjectURL(getPrintFileByExternalProductId(row.etsyTransaction.transaction_id)!)} alt={getPrintFileByExternalProductId(row.etsyTransaction.transaction_id)?.name} height={80} /> : null}
							</div>
						)}
					/>
					<Column
						header="Printram Product"
						style={{ width: '1rem' }}
						body={(row: ExternalProductDto) => (
							<div className="flex flex-column gap-2 align-items-center justify-content-center text-center">
								<PrButton
									onClick={() => {
										setSelectedExternalProduct(row);
										setVisible(true);
									}}
									text="Sync Product"
								/>

								{!!row.printramProductObjectUrl ? <img src={row.printramProductObjectUrl!} alt={row.title} height={80} /> : null}

								{!!row.printramProductDetail ? <span className="w-max" dangerouslySetInnerHTML={{ __html: row.printramProductDetail }} /> : null}
							</div>
						)}
					/>
				</DataTable>
			</div>

			{!!externalOrder ? (
				<div className="container-body p-3">
					<h5 className="m-0">ETSY Information</h5>
					<p className="text-color-secondary">The information for the Etsy order is displayed below.</p>

					{(Object.keys(externalOrder.etsyOrder) as (keyof typeof externalOrder.etsyOrder)[]).map((key: any, index) =>
						typeof externalOrder.etsyOrder[key] !== 'object' && !(key as string).includes('timestamp') ? (
							<div key={index} className="grid">
								<div className="col-12 md:col-6 lg:col-4 xl:col-3 font-bold">
									{(key as string)
										.split('_')
										.map((_key) => _key.charAt(0).toUpperCase() + _key.substring(1) + ' ')
										.join(' ')
										.replaceAll('Timestamp', 'Date')
										.trimEnd()}
								</div>

								<div className="col-12 md:col-6 lg:col-8 xl:col-9" dangerouslySetInnerHTML={{ __html: getValue(key, externalOrder.etsyOrder[key]) }} />
							</div>
						) : null
					)}
				</div>
			) : null}

			<div className="container-body p-3 sticky bottom-0">
				<div className="flex justify-content-end align-items-center">
					<PrButton onClick={handleSubmit} btnType="submit" text="Save" loading={loading} icon={<span className="pi pi-fw pi-save" />} />
				</div>
			</div>

			{loading ? (
				<div className="pr-loading">
					<div className="flex flex-column text-0">
						<h3>{loadingText}</h3>
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}

			{/* <SyncProductDialog
				visible={visible}
				setVisible={setVisible}
				handleVariantClick={(variant, product) => {
					if (!selectedExternalProduct) {
						toastHelper.warning('Please select Etsy Product');
						return;
					}

					const newExternalOrder = structuredClone(externalOrder);

					if (!newExternalOrder?.externalProducts || newExternalOrder.externalProducts.length < 1) {
						toastHelper.warning('Etsy Product not found');
						return;
					}

					for (const externalProduct of newExternalOrder.externalProducts) {
						if (selectedExternalProduct.etsyTransaction.transaction_id !== externalProduct.etsyTransaction.transaction_id) continue;

						externalProduct.printramProductObjectUrl = mediaHelper.getNewMediaImage(variant.medias[0]).url;
						externalProduct.printramProductDetail = `${product.model.brand.name} ${product.model.name}\n${variant.color.name} - ${variant.size.name}`;
						break;
					}

					setExternalOrder(newExternalOrder);

					const newExternalProducts = structuredClone(externalProducts);

					if (!newExternalProducts || newExternalProducts.length < 1) {
						toastHelper.warning('External Product not found');
						return;
					}

					for (const externalProduct of newExternalProducts) {
						if (selectedExternalProduct.etsyTransaction.transaction_id !== externalProduct.transactionId) continue;

						externalProduct.productId = variant.productId;
						externalProduct.productVariantId = variant.id;

						break;
					}

					setExternalProducts(newExternalProducts);
					setSelectedExternalProduct(undefined);
				}}
				productId={undefined}
			/> */}
		</div>
	);
};

export default ExternalOrderDetail;
