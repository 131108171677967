import { Color, Size, Variant } from 'features/products/_common/get-core-product/get-core-product.response';

const getSizes = (variants: Variant[], options?: Partial<{ sort: 'ASC' | 'DESC' }>): Size[] => {
	const sizes: Size[] = [];

	for (const variant of variants) {
		if (sizes.findIndex((_size) => _size.id === variant.size.id) > -1) continue;

		sizes.push(variant.size);
	}

	return sizes.sort((a, b) => {
		if (a.priority > b.priority) return options?.sort === 'DESC' ? -1 : 1;
		else if (a.priority < b.priority) return options?.sort === 'DESC' ? 1 : -1;

		return 0;
	});
};

const getBrightness = (hexCode: string) => {
	const r = parseInt(hexCode.substring(1, 3), 16);
	const g = parseInt(hexCode.substring(3, 5), 16);
	const b = parseInt(hexCode.substring(5, 7), 16);

	return (r * 299 + g * 587 + b * 114) / 1000;
};

const getColors = (variants: Variant[], options?: Partial<{ sort: 'ASC' | 'DESC' }>): Color[] => {
	const colors: Color[] = [];

	for (const variant of variants) {
		if (colors.findIndex((_color) => _color.hexCode === variant.color.hexCode) > -1) continue;

		colors.push(variant.color);
	}

	return colors.sort((a, b) => {
		const brightnessA = getBrightness(a.hexCode.toLowerCase().replaceAll(' ', ''));
		const brightnessB = getBrightness(b.hexCode.toLowerCase().replaceAll(' ', ''));

		return options?.sort === 'ASC' ? brightnessA - brightnessB : brightnessB - brightnessA;
	});
};

const getVariantFromColorAndSize = (variants: Variant[], color: Color, size: Size) => {
	for (const variant of variants) {
		if (variant.color.hexCode !== color.hexCode || variant.size.id !== size.id) continue;

		return variant;
	}
};

export const variantHelper = { getSizes, getColors, getVariantFromColorAndSize };
