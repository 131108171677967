export class DeleteGroupRequest {
	id: string;

	constructor(id: string) {
		this.id = id;
	}

	get getConvertUriExtension() {
		return `delete-group/${this.id}`;
	}
}