import { ShippingRateDto } from "features/orders/derived-features/order-transports/dtos/shipping-rate.dto";

export class ReorderCalculateRequest {
	reOrderId: string;
	shippingRate: ShippingRateDto | null;

	constructor(props: Partial<ReorderCalculateRequest>) {
		this.reOrderId = props.reOrderId || '';
		this.shippingRate = props.shippingRate || null;
	}

	get getConvertUriExtension() {
		return 'calculate';
	}
}