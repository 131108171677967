export interface PriceAndAmounts {
	priceId: string;
	amount: number;
}

export class UpdateProductVariantPriceRequest {
	priceAndAmounts: PriceAndAmounts[];

	constructor(props: Partial<UpdateProductVariantPriceRequest>) {
		this.priceAndAmounts = props.priceAndAmounts || []
	}

	get getConvertUriExtension() {
		return 'update-product-variant-price'
	}
}