import { DataPaginationResponse } from 'models/_commons/responses/data-pagination-response';

export enum OperationSteps {
	Unknown = 0,
	PreparedForShipping = 1
}

export interface OperationProcessDto {
	id: string;
	orderId: string;
	orderCode: string | null;
	createdUserId: string;
	createdUserFullName: string;
	step: OperationSteps;
	barcode: string;
	createdDate: Date;
}

export interface GetOperationProcessStepsResponse extends DataPaginationResponse<OperationProcessDto[]> {}
