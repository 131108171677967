export class GetOrderDetailNewRequest {
	private orderId: string;

	constructor(orderId: string) {
		this.orderId = orderId;
	}

	get getConvertUriExtension() {
		return `get-order-detail/${this.orderId}`;
	}
}
