import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Balance from './components/Balance';
import Transactions from './components/Transactions';
import PaymentMethods from '../paymentMethods/PaymentMethods';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Payments.module.scss'
import Withdrawals from './components/Withdrawals';

const Payments = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<div className="container-header">
				<h1 className="container-header-title">Payments</h1>

				<div className={`container-header-tools ${styles.navigationBtns}`}>
					<PrButton type="secondary" icon={<span className="pi pi-arrow-left" />} onClick={() => navigate(-1)} text="Go Back" />
					<Link to="/account/security">
						<PrButton icon={<span className="pi pi-arrow-right" />} text="Go Security" />
					</Link>
				</div>
			</div>

			<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="mt-3">
				<TabPanel header="Payment Methods">
					<PaymentMethods />
				</TabPanel>
				<TabPanel header="Balance">
					<Balance setActiveIndex={setActiveIndex} />
				</TabPanel>
				<TabPanel header="Transactions">
					<Transactions />
				</TabPanel>
				<TabPanel header="Withdrawals">
					<Withdrawals />
				</TabPanel>
			</TabView>
		</React.Fragment>
	);
};

export default Payments;
