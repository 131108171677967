export class GetModelsByBrandRequest {
	brandId: string;

	get getConvertUriExtension() {
		return `get-models-by-brand?BrandId=${this.brandId}`;
	}

	constructor(brandId: string) {
		this.brandId = brandId
	}
}