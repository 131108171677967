import { useCallback, useEffect, useState, useRef } from 'react';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import CreatableSelect from 'react-select/creatable';
import { useAppSelector } from '../../../../../redux/hooks';
import * as Yup from 'yup';
import brandService from '../../../../../services/brand-service';
import categoryService from '../../../../../services/category-service';
import { useNavigate } from 'react-router-dom';
import { Category } from '../../../../../models/dtos/category/category';
import TreeNode from 'primereact/treenode';
import { TreeSelect } from 'primereact/treeselect';
import { User } from '../../../../../models/dtos/auth/users/user';
import { DropdownChangeParams } from 'primereact/dropdown';
import TextInput from '../../../../../helpers/widgets/Forms/TextInput';
import DropdownInput from '../../../../../helpers/widgets/Forms/DropdownInput';
import BrandCreate from '../../../productManagement/brands/components/BrandCreate';
import { CreateRequest } from 'features/products/derived-features/merchant-products/create/create.request';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import { GetModelsByBrandRequest } from 'features/models/_common/get-models-by-brand/get-models-by-brand.request';
import modelService from 'features/models/_common/model.service';

const StepOne = ({ setStep }: any) => {
	const navigate = useNavigate();
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const formikRef = useRef<FormikProps<CreateRequest>>(null);
	const creatableRef = useRef<any>(null);
	const merchantId = useAppSelector((state) => state.auth.data?.user.userId) || '';
	const [brandsOptions, setBrandsOptions] = useState<SelectItemOptionsType>();
	const [selectedBrand, setSelectedBrand] = useState<string>();
	const [modelsOptions, setModelsOptions] = useState<SelectItemOptionsType>();
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);
	const [visibleCreateBrand, setVisibleCreateBrand] = useState(false);
	const [loading, setLoading] = useState(false);

	const initialValues: CreateRequest = new CreateRequest(merchantId, {}, null, '', '');

	const validationScheme = Yup.object().shape({
		merchantId: Yup.string().required('Merchant not Found'),
		model: Yup.object().nullable().required('Please select a model'),
		categoryIdentities: Yup.object().nullable().required('Please select Category'),
		name: Yup.string().required('Name is Required').min(3, 'Name must be at least 3 characters.').max(100, 'Name must be a maximum of 100 characters.'),
		description: Yup.string().required('Description is Required').min(20, 'Description must be at least 20 characters.')
	});

	const modelChange = (selectedModel: any) => {
		if (!formikRef.current || !selectedModel) return;

		formikRef.current.setFieldValue('model', {});
		if (selectedModel.__isNew__) formikRef.current.setFieldValue('model.createModel', { name: selectedModel.value, brandId: selectedBrand });
		else formikRef.current.setFieldValue('model.modelId', selectedModel.value);
	};

	const getBrands = useCallback(() => {
		setLoading(true);
		brandService
			.getBrandsByMerchantId(userId)
			.then((response) => {
				if (!response.isSuccess) return;

				const options = response.data?.map((brand: any) => ({ label: (brand as any).statu === 1 ? (brand as any).name + ' (pending)' : brand.name, value: (brand as any).id, disabled: brand.statu !== 3 } as SelectItem));
				if (!options) return;

				setBrandsOptions(options);
			})
			.finally(() => setLoading(false));
	}, [userId]);

	const submitForm = async (values: CreateRequest) => {
		try {
			setLoading(true);

			const categoryIdentities = Object.keys(values.categoryIdentities as Object);

			const request = new CreateRequest(values.merchantId, values.model, categoryIdentities, values.name, values.description);

			const response = await merchantProductsService.create(request);

			if (!response.isSuccess) throw '';

			setStep((prev: number) => (prev += 1));
			navigate(response.data!);
		} finally {
			setLoading(false);
		}
	};

	const generateTreeNode = (category: Category): TreeNode => {
		const treeNodeForCurrentSubCategory: TreeNode = { key: category.id, label: category.name, selectable: category.canBeAddProduct };
		return treeNodeForCurrentSubCategory;
	};

	const mapCategoryToParent = useCallback((parentCategory: Category, treeNode: TreeNode) => {
		if (parentCategory.subCategories === undefined) return;

		parentCategory.subCategories.forEach((_subCategory) => {
			if (treeNode.children === undefined) treeNode.children = [];

			const treeNodeForCurrentSubCategory = generateTreeNode(_subCategory);

			treeNode.children?.push(treeNodeForCurrentSubCategory);

			mapCategoryToParent(_subCategory, treeNodeForCurrentSubCategory);
		});
	}, []);

	const mapCategoriesForTree = useCallback(
		(categories: Category[]) => {
			let treeNode: TreeNode[] = [];

			categories.forEach((_category) => {
				const treeNodeForCurrentCategory = generateTreeNode(_category);

				mapCategoryToParent(_category, treeNodeForCurrentCategory);

				treeNode.push(treeNodeForCurrentCategory);
			});

			setTreeCategories(treeNode);
		},
		[mapCategoryToParent]
	);

	const getModelsByBrand = async () => {
		try {
			if (!selectedBrand) throw '';

			setLoading(true);

			const request = new GetModelsByBrandRequest(selectedBrand);

			const response = await modelService.getModelsByBrand(request);

			if (!response.isSuccess) throw '';

			const options = response.data?.map((model) => ({ label: model.modelName, value: model.modelId, isDisabled: model.isHaveAnyProduct, __isNew__: false } as SelectItem));
			if (!options) return;

			setModelsOptions(options);
		} catch {
			setModelsOptions([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!selectedBrand) {
			setModelsOptions([]);
			return;
		}

		formikRef.current?.setFieldValue('model', {});

		getModelsByBrand();
	}, [selectedBrand, merchantId]);

	useEffect(() => {
		if (!creatableRef.current) return;

		creatableRef.current.commonProps.clearValue();
		formikRef.current?.setFieldValue('model', {});
	}, [modelsOptions]);

	useEffect(() => {
		if (!merchantId) return;

		getBrands();

		categoryService.getAll().then((response) => {
			if (!response.isSuccess) return;

			mapCategoriesForTree(response.data || []);
		});
	}, [merchantId, mapCategoriesForTree, userId, getBrands]);

	return (
		<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
			{({ values, handleChange, setFieldValue }) => (
				<Form>
					<div className="grid gap-2">
						<div className="col-12 pb-0">
							<div className="grid">
								<div className="col-8 sm:col-3">
									<DropdownInput label="Brand" name="model.createModel.brandId" options={brandsOptions} value={selectedBrand} disabled={loading} onChange={(event: DropdownChangeParams) => setSelectedBrand(event.value)} placeholder="Select a brand" />
								</div>
								<div className="col-4 sm:col-1 flex align-items-end">
									<Button
										type="button"
										onClick={() => {
											setVisibleCreateBrand(true);
										}}
										icon="pi pi-plus-circle"
										label="Brand"
										className="p-button-outlined p-button-md"
									/>
								</div>
								{!!modelsOptions && !!selectedBrand && (
									<div className="col-12 sm:col-4">
										<div className="mb-1 px-1 flex align-items-center justify-content-between">
											<label>Model</label>
											<ErrorMessage name="model.modelId" component="small" className="text-red font-medium" />
										</div>
										<CreatableSelect ref={creatableRef} options={modelsOptions} placeholder="Select or create a model" isClearable isLoading={loading} isDisabled={loading} onChange={(event) => modelChange(event)} />
									</div>
								)}
							</div>
						</div>

						<div className="col-12 sm:col-8">
							<div className="mb-1 px-1 flex align-items-center justify-content-between">
								<label>Category</label>
								<ErrorMessage name="categoryIdentities" component="small" className="text-red font-medium" />
							</div>

							<TreeSelect filter value={values.categoryIdentities as any} options={treeCategories} selectionMode="multiple" metaKeySelection={false} onChange={(event) => setFieldValue('categoryIdentities', event.value)} placeholder="Select Item" className="w-full" />
						</div>

						<div className="col-12 sm:col-8">
							<TextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Enter product name" />
						</div>

						<div className="col-12 sm:col-8">
							<div className="mb-1 px-1 flex align-items-center justify-content-between">
								<label>Description</label>
								<ErrorMessage name="description" component="small" className="text-red font-medium" />
							</div>
							{/* <Editor style={{ height: '320px' }} value={values.description} onTextChange={(e) => setFieldValue('description', e.htmlValue || '')} /> */}
							<InputTextarea rows={10} name="description" value={values.description} onChange={handleChange} placeholder="Enter description" className="w-full" />
						</div>

						<div className="col-12 sm:col-8">
							<Button type="submit" icon="pi pi-arrow-right" label="Confirm & Next" loading={loading} className="px-5" />
						</div>
					</div>
					<BrandCreate isVisible={visibleCreateBrand} setVisible={setVisibleCreateBrand} getBrands={getBrands} />
				</Form>
			)}
		</Formik>
	);
};

export default StepOne;
