import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { Skeleton } from 'primereact/skeleton';
import styles from './MyProducts.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { User } from 'models/dtos/auth/users/user';
import mediaHelper from 'helpers/media.helper';
import currencyHelper from 'helpers/curreny.helper';
import { GetMyProductsRequest } from 'features/products/derived-features/merchant-products/get-my-products/get-my-products.request';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import { MerchantProductDto } from 'features/products/derived-features/merchant-products/get-my-products/dtos/merchant-product.dto';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { Pagination } from 'models/_commons/responses/pagination';
import { setForMerchantProductPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { ProductStatu } from 'features/products/dtos/enums/product-statu.enum';
import ProductFilterTab from 'helpers/widgets/Order/OrderFilterTab/ProductFilterTab';
import { InputText } from 'primereact/inputtext';

const MyProducts = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forMerchantProductPaginationInfo as PaginationDto);
	const user = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();
	const searchStatus = searchParams.get('status');
	const [filterProductStatuType, setFilterProductStatuType] = useState<ProductStatu | null>(!!searchStatus ? Number(searchStatus) || null : null);

	const [filter, setFilter] = useState<string | undefined>(undefined);
	const [products, setProducts] = useState<MerchantProductDto[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getStatusColor = (statu: ProductStatu) => {
		switch (statu) {
			case ProductStatu.Unknown:
				return '#000';
			case ProductStatu.Pending:
				return '#add8e6';
			case ProductStatu.Approved:
				return '#2ca544';
			case ProductStatu.Rejected:
				return '#9f2929';
			default:
				return '#000';
		}
	};

	const getAllByMerchant = async (pagination?: PaginationDto) => {
		if (!user || !user.roles.isMerchant) return;

		setLoading(true);
		try {
			if (!!pagination) dispatch(setForMerchantProductPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetMyProductsRequest({ merchantId: user.userId, pagination, statu: filterProductStatuType, contains: !!filter && filter.length >= 3 ? filter : null });

			const response = await merchantProductsService.getMyProducts(request);

			if (!response.isSuccess || !response.data) throw '';

			setPaginationResponse(response.pagination);
			setProducts(response.data);
		} catch {
			setProducts([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else {
			newPagination.itemCount = paginationResponse?.itemCountAtCurrentPage || 10;
			newPagination.pageNumber = paginationResponse?.currentPage || 1;
		}

		getAllByMerchant(newPagination);
	}, [filterProductStatuType]);

	useEffect(() => {
		if (!user || !user.roles.isMerchant) return;

		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 10;

		getAllByMerchant(newPagination);
	}, [user]);

	const handleFilter = async () => {
		if (typeof filter === 'string' && filter.length === 0) {
			onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
			return;
		}

		if (!filter || filter.length < 3) return;

		onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
	};

	const onPageChangeEvent = (event: DataViewPageParams) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		getAllByMerchant(newPagination);
	};

	const renderListItems = (product: MerchantProductDto) => (
		<div className="col-12">
			<div className={styles.productListItem}>
				<img src={mediaHelper.getImageOnMedia(product.media).url} alt={product.name} />

				<div className={styles.productListDetail}>
					<div className={styles.productHeaderWrapper} style={{ '--statu-color': getStatusColor(product.productStatu) } as React.CSSProperties}>
						<span className={styles.productName}>{product.name}</span>
						<span className={styles.productStatus}>{ProductStatu[product.productStatu]}</span>
					</div>

					<span dangerouslySetInnerHTML={{ __html: product.description }} className={`${styles.productListDescription} mt-2`}></span>

					<div className="mt-3">
						<i className={`pi pi-tag ${styles.productCategoryIcon}`}></i>
						<span className={styles.productCategory}>{product.categoryName}</span>
					</div>
				</div>

				<div className={styles.productListAction}>
					<span className={styles.productPrice}>{currencyHelper.formatPrice(product.minPrice.formattedPricePerUnit)}</span>
					<Button onClick={() => navigate('/product/detail/' + product.id)} icon="pi pi-arrow-right" label="Go Detail" className="p-button-sm" />
					<Button onClick={() => navigate('/product/merchant/edit/' + product.id)} icon="pi pi-pencil" label="Edit" className="p-button-sm p-button-warning mt-2" />
				</div>
			</div>
		</div>
	);

	return (
		<div className="grid">
			<div className="col-12">
				<div className="card">
					<ProductFilterTab filterProductStatuType={filterProductStatuType} setFilterProductStatuType={setFilterProductStatuType} name="Products" />

					<div className="flex flex-wrap align-items-center gap-3 mt-1 mb-3">
						<h5 className="m-0">Product List</h5>

						<div>
							<div className="p-inputgroup">
								<InputText placeholder="Search..." value={filter} onKeyDown={(event) => event.key === 'Enter' && handleFilter()} onChange={(event) => setFilter(event.target.value)} className="p-inputtext-sm" />
								<Button onClick={handleFilter} icon="pi pi-fw pi-search" className="p-button-sm" />
							</div>
						</div>
					</div>

					<DataView value={products} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} onPage={onPageChangeEvent} first={fromStatePagination.first} loading={loading} layout={'list'} itemTemplate={renderListItems} paginator rows={fromStatePagination.itemCount || 5} />
				</div>
			</div>
		</div>
	);
};

export default MyProducts;
