import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import balanceService from 'features/balances/_common/balance.service';
import { GetBalanceHistoryByUserRequest } from 'features/balances/_common/get-balance-history-by-user/get-balance-history-by-user.request';
import { UserBalanceHistory } from 'features/balances/dtos/user-balance-history.dto';
import currencyHelper from 'helpers/curreny.helper';
import dateHelper from 'helpers/dateHelper';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { setForSellerBalanceHistoryPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './Transactions.module.scss';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { DateFilterDto } from 'features/_common/dtos/dateFilter/date-filter.dto';
import { Calendar, CalendarProps, CalendarValueType } from 'primereact/calendar';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { GetBalanceHistoryReportRequest } from 'features/balances/_common/get-balance-history-report/get-balance-history-report.request';
import { User } from 'models/dtos/auth/users/user';
import { useNavigate } from 'react-router-dom';

type props = { sellerId?: string };
const Transactions = ({ sellerId }: props) => {
	const { userId, roles } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const fromStatePagination = useAppSelector((state) => state.pagination.forSellerBalanceHistoryPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const calendarRef = useRef<Calendar>(null);
	const navigate = useNavigate();

	const [histories, setHistories] = useState<UserBalanceHistory[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [dateFilter, setDateFilter] = useState<DateFilterDto>({ startDate: null, endDate: null });
	const [dates, setDates] = useState<CalendarValueType>();
	const [loading, setLoading] = useState<boolean>(false);
	const [exportLoading, setExportLoading] = useState<boolean>(false)

	const getTransactions = async (pagination?: PaginationDto) => {
		try {
			if (!userId && !sellerId) throw '';

			setLoading(true);

			if (!!pagination) dispatch(setForSellerBalanceHistoryPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetBalanceHistoryByUserRequest(sellerId || userId, pagination || fromStatePagination, dateFilter);

			const response = await balanceService.getBalanceHistoryByUser(request);

			if (!response.isSuccess) throw '';

			setHistories(response.data || []);
			setPaginationResponse(response.pagination);
		} catch {
			setHistories([]);
		} finally {
			setLoading(false);
		}
	};

	const getTransactionsReport = async () => {
		try {
			if (!userId && !sellerId) throw '';

			setExportLoading(true);

			const request = new GetBalanceHistoryReportRequest(sellerId || userId);

			const response = await balanceService.getBalanceHistoryReport(request);

			if (!response.isSuccess) throw '';

			response.data?.url && window.open(response.data.url , '_self')
		} catch (error) {

		} finally {
			setExportLoading(false)
		}
	}

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;
		}

		await getTransactions(newPagination);
	};

	const applyFilter = () => {
		function addDays(date: Date, days: number) {
			var result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}

		const formattedDate = structuredClone(dates as Date[]);

		const startDate = formattedDate[0] || null;
		const endDate = formattedDate[1] ? addDays(formattedDate[1], 1) : null;

		setDateFilter({ startDate: startDate || null, endDate: endDate || ( startDate ? addDays(startDate, 1) : null )});

		calendarRef && calendarRef.current?.hide();
	};

	const dateFooterTemplate = () => {
		return <PrButton text="Apply Filter" onClick={() => applyFilter()} className="mx-auto mb-2" />;
	};

	const orderCodeTemplate = (rowData: UserBalanceHistory) => {
		const navigateUrl = roles.isSeller ? '/product/seller/order/' + rowData.orderId : '/order/' + rowData.orderId;
		return (
			<span onClick={() => navigate(navigateUrl)} className="cursor-pointer">
				{rowData.orderCode || ''}
			</span>
		);
	}

	const descriptionBodyTemplate = (rowData: UserBalanceHistory) => (
		<div className={styles.description}>
			<Tooltip target="#desc" />
			<p id="desc" data-pr-tooltip={rowData.description} data-pr-position="top">
				{rowData.description}
			</p>
		</div>
	);

	const dateBodyTemplate = (rowData: UserBalanceHistory) => dateHelper.formatDate(rowData.createdDate || '');

	const amountBodyTemplate = (rowData: UserBalanceHistory) => <span style={{ color: rowData.type === 2 ? 'red' : 'green' }}>{`${rowData.type === 2 ? '-' : ''} ${currencyHelper.formatPrice(rowData.price.formattedPricePerUnit)}`}</span>;

	const afterPriceBodyTemplate = (rowData: UserBalanceHistory) => currencyHelper.formatPrice(rowData.afterPrice.formattedPricePerUnit);

	const onClearDate = () => {
		setDates(null);
		setDateFilter({ startDate: null, endDate: null });
	};

	useEffect(() => {
		if (!dateFilter) return;

		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = fromStatePagination;
		else newPagination.itemCount = 20;

		getTransactions(newPagination);
	},[dateFilter])

	return (
		<React.Fragment>
			<div className="container-body p-3">
				<div className={styles.filters}>
					<PrButton text="Export Seller Transactions" onClick={getTransactionsReport} icon={<span className='pi pi-fw pi-download' />} loading={exportLoading} type="secondary" /> : <div />
					<Calendar ref={calendarRef} value={dates} onChange={(e) => setDates(e.value)} footerTemplate={dateFooterTemplate} onClearButtonClick={onClearDate} selectionMode="range" hideOnDateTimeSelect readOnlyInput showButtonBar placeholder="Select Date Range" clearButtonClassName="text-blue-500" todayButtonClassName="text-blue-500" inputClassName="cursor-pointer" />
				</div>
			</div>

			<div className="container-body p-3">
				<DataTable value={histories} currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} stripedRows paginator lazy rowsPerPageOptions={[5, 10, 15, 20]} rows={fromStatePagination.itemCount || 5} dataKey="id" first={fromStatePagination.first} onPage={onPageChangeEvent} className="pr-table">
					<Column body={orderCodeTemplate} header="Order Code" />
					<Column body={descriptionBodyTemplate} header="Description" style={{ width: '16rem' }} />
					<Column body={dateBodyTemplate} header="Date" />
					<Column body={amountBodyTemplate} header="Amount" />
					<Column body={afterPriceBodyTemplate} header="Balance After" />
					<Column field="processOwner" header="Process Owner" />
				</DataTable>

				{loading ? (
					<div className="pr-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default Transactions;
