import { Dialog } from 'primereact/dialog';
import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import printCenterOperatorService from '../../../../features/printCenterOperators/_common/print-center-operator.service';
import { GetPrintCenterOperatorByIdRequest } from '../../../../features/printCenterOperators/_common/get-print-center-operator-by-id/get-print-center-operator-by-id.request';
import { Form, Formik, FormikProps } from 'formik';
import TextInput from '../../../../helpers/widgets/Forms/TextInput';
import { OperatorDTO } from '../../../../features/printCenterOperators/_common/dtos/operator_dto';
import * as Yup from 'yup';

type Props = {
	visible: boolean;
	onHide: () => void;
	onSubmit: (operator: OperatorDTO) => void;
	operatorId: number | undefined;
};

const CreateOperatorDialog = ({ visible, onHide, onSubmit, operatorId }: Props) => {
	const formikRef = useRef<FormikProps<{ name: string; code: string }>>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (visible) {
			getOperator();
		}
	}, [visible, operatorId]);

	const getOperator = async () => {
		try {
			setLoading(true);

			if (!operatorId) return;

			const response = await printCenterOperatorService.getPrintCenterOperatorById(new GetPrintCenterOperatorByIdRequest(operatorId));

			if (!response.isSuccess || !response.data) throw '';
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is Required'),
		code: Yup.string().required('Code is Required').max(10),
	});

	const initialValues = { name: '', code: '' };

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (!formikRef.current) return;
		formikRef.current.handleChange(event);
	};

	const submit = (values: any) => {
		onSubmit({ id: operatorId!, name: values.name, code: values.code });
		hide();
	};

	const hide = () => {
		dispose();
		onHide();
	};

	const dispose = () => {
		// Add any cleanup logic here if needed
	};

	return (
		<Dialog header="Edit Operator" visible={visible} onHide={hide} style={{ maxWidth: '720px' }}>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => submit(values)}
			>
				{() => (
					<Form>
						<TextInput label="Operator Name" name="name" id="name" onChange={handleInputChange} disabled={loading} />
						<TextInput label="Operator Code" name="code" id="code" onChange={handleInputChange} disabled={loading} />
						<PrButton btnType="submit" text="Save" className="ml-auto mt-2" disabled={loading} />
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default CreateOperatorDialog;