import HttpBaseService from "services/_common/http-base-service";
import { CreateImageRequest } from "./create-image/create-image.request";
import { CreateImageResponse } from "./create-image/create-image.response";


class EditorService extends HttpBaseService {
	constructor() {
		super('editors');
	}

	async createImage(request: CreateImageRequest): Promise<CreateImageResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}
}

export default new EditorService();
