import { LoginWithAutomaticLoginRequest } from 'features/users/_common/login-with-automatic-login/login-with-automatic-login.request';
import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { resetRedirectUrl, setRedirectUrl } from 'redux/features/redirect/redirectSlice';
import * as Yup from 'yup';
import localStorageHelper from '../../helpers/local-storage.helper';
import PasswordInput from '../../helpers/widgets/Forms/PasswordInput';
import TextInput from '../../helpers/widgets/Forms/TextInput';
import { LoginRequest } from '../../models/requests/login-request';
import { autoLogin, login } from '../../redux/features/auth/authSlice';
import { resetStore } from '../../redux/features/printram-store/printramStoreSlice';
import { resetPrintOption } from '../../redux/features/user-print-option/userPrintOptionSlice';
import { useAppDispatch } from '../../redux/hooks';
import styles from './Login.module.scss';
import LoginWithGoogle from './LoginWithGoogle';
import surveyService from '../../features/surveys/_common/survey.service';
import { SurveyGetAvailableByUserRequest } from '../../features/surveys/_common/survey-get-available-by-user/survey-get-available-by-user.request';

const Login = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [searchParams, setSearchParams] = useSearchParams();
	const autoLoginToken = searchParams.get('autoLoginToken') || null;

	const formikRef = useRef<FormikProps<LoginRequest>>(null);
	const [disable, setDisable] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues: LoginRequest = {
		email: '',
		password: ''
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('Email address must be valid.').required('Email is Required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
		password: Yup.string().required('Password is Required').min(5, 'Password must be at least 5 characters.')
	});

	const submitForm = (values: LoginRequest) => {
		localStorageHelper.remove('auth');

		setLoading(true);
		setDisable(true);

		if (!!location.search && !!location.search.search('redirectUrl')) dispatch(setRedirectUrl(location.search));

		dispatch(login(values))
			.then(async (data: any | undefined | null) => {
				if (data.error) return;
				await getAvailableSurveys(data.payload.data.user.userId);
			})
			.finally(() => setLoading(false));
	};

	const getAvailableSurveys = async (userId: string) => {
		try {
			if (!userId) return;
			const response = await surveyService.getAvailableByUser(new SurveyGetAvailableByUserRequest(userId, 1));

			if (!response.isSuccess) throw '';

			if (!!response.data![0]) localStorageHelper.setStringfyForData('wizardAvaliable', true);
			navigate('/on-boarding');
		} catch (error) {
		} finally {
		}
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (!formikRef.current) return;

		formikRef.current.handleChange(event);

		if (!disable) return;

		setDisable(false);
	};

	const handleCheckAutoLogin = () => {
		if (!location.search || !location.search.includes('autoLoginToken')) return;

		//	const autoToken = location.search.replace('?autoLoginToken=', '');
		//	if (!autoToken || autoToken === 'null' || autoToken.length < 1) return;

		if (!autoLoginToken || autoLoginToken === 'null' || autoLoginToken.length < 1) return;

		setLoading(true);
		setDisable(true);

		dispatch(autoLogin(new LoginWithAutomaticLoginRequest(autoLoginToken)))
			.then(async (data: any | undefined | null) => {
				if (data.error) return;
				await getAvailableSurveys(data.payload.data.user.userId);
			})
			.finally(() => {
				setLoading(false);
				setDisable(false);
			});
	};

	useEffect(() => {
		handleCheckAutoLogin();
		dispatch(resetStore());
		dispatch(resetPrintOption());
		dispatch(resetRedirectUrl());
	}, []);

	return (
		<div className={styles.registerWrapper}>
			<section className={styles.formSide}>
				<main className={styles.registerMain}>
					<div className="flex justify-content-center align-items-center my-5">
						<img alt="printram logo" src="/logo/printram-main-logo-sm.svg" />
					</div>

					<div className={styles.description}>
						<h1 className={styles.title}>Log in to Printram</h1>
						<p className={styles.text}>Get back to your designs in a few clicks</p>
					</div>

					<div className={styles.socialActionWrapper}>
						{/* <DownloadButton /> */}
						<LoginWithGoogle setDisable={setDisable} />
						<div className={styles.divider}>
							<span className={styles.line}></span>
							<span className={styles.text}>or</span>
							<span className={styles.line}></span>
						</div>
					</div>

					<Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => submitForm(values)}>
						{() => (
							<Form className={styles.registerForm}>
								<div className={styles.formGroup}>
									<TextInput labelStyle={{ fontWeight: '600' }} style={{ borderColor: '#D1D3D4', borderRadius: '10px' }} label="Email" name="email" id="email" onChange={handleInputChange} />
								</div>

								<div className={styles.formGroup}>
									<PasswordInput labelStyle={{ fontWeight: '600' }} inputStyle={{ borderColor: '#D1D3D4', borderRadius: '10px' }} type="password" label="Password" name="password" id="password" onChange={handleInputChange} toggleMask feedback={false} />
								</div>

								<Link to="/send-reset-password-code">
									<p className="text-primary text-sm m-0 ml-1">Forgot Password?</p>
								</Link>

								<div className="">
									<Button type="submit" disabled={disable} className={`${styles.loginButton} mt-2 mr-auto`}>
										{loading ? <ProgressSpinner strokeWidth="4" animationDuration=".5s" style={{ width: '1.3rem', height: '1.3rem' }} /> : <span className="mx-auto">Login</span>}
									</Button>
								</div>
							</Form>
						)}
					</Formik>

					<div>
						Don’t have an account?{' '}
						<Link to="/register-individual" className="text-primary">
							Sign Up
						</Link>
					</div>
				</main>
			</section>

			<section className={styles.imageSide}>
				<div className={styles.image} />
			</section>

			<section className={`${styles.mobileImageSide}`}>
				<div className={styles.image} />
			</section>
		</div>
	);
};

export default Login;
