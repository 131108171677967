import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import { GetCoreProductForSynchronizeRequest } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.request';
import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import productService from 'features/products/_common/product.service';
import { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import styles from './DefaultPrintOptionsDialog.module.scss';
import { ProductList } from './components/produc-list/ProductList';
import { ProductUpdateArea } from './components/product-update-area/ProductUpdateArea';
import filterHelper from 'helpers/filter.helper';

type Props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	coreProductId: string;
};

const defaultPrDropdownValue: PrDropdownType = { label: 'All', value: 'All' };

export const DefaultPrintOptionsDialog = (props: Partial<Props>) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [products, setProducts] = useState<CoreProductSynchronizeDto[]>([]);
	const [filteredProducts, setFilteredProducts] = useState<CoreProductSynchronizeDto[]>([]);
	const [categories, setCategories] = useState<CategoryBaseDto[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<CategoryBaseDto | undefined>(undefined);
	const [brands, setBrands] = useState<PrDropdownType[]>([]);
	const [selectedBrand, setSelectedBrand] = useState<PrDropdownType | undefined>(undefined);
	const [models, setModels] = useState<PrDropdownType[]>([]);
	const [selectedModel, setSelectedModel] = useState<PrDropdownType | undefined>(undefined);
	const [selectedProduct, setSelectedProduct] = useState<CoreProductSynchronizeDto | null | undefined>(null);
	const [search, setSearch] = useState<string>('');

	const getProducts = async (request: Partial<GetCoreProductForSynchronizeRequest>) => {
		try {
			setLoading(true);

			const response = await productService.getCoreProductForSync(new GetCoreProductForSynchronizeRequest(request));
			if (!response.isSuccess || !response.data || !response.data.products.data) throw '';

			setProducts(response.data.products.data.filter((_product) => _product.variants.length > 0));

			if (request.includeCategories) setCategories(response.data.categories);

			if (request.includeBrands) {
				setSelectedBrand(defaultPrDropdownValue);
				setBrands([defaultPrDropdownValue, ...response.data.brands.map((_brand) => ({ label: _brand.name, value: _brand.id }))]);
			}

			if (request.includeModels) {
				setSelectedModel(defaultPrDropdownValue);
				setModels([defaultPrDropdownValue, ...response.data.models.map((_model) => ({ label: _model.name, value: _model.id }))]);
			}

			if (!!props.coreProductId) {
				const product = response.data.products.data.find((_product) => _product.id === props.coreProductId);

				if (!!product) setSelectedProduct(product);
			}
		} catch (error) {
			setProducts([]);
			setCategories([]);
			setBrands([]);
			setModels([]);

			setSelectedCategory(undefined);
			setSelectedBrand(undefined);
			setSelectedModel(undefined);
		} finally {
			setLoading(false);
		}
	};

	const handleClearFilter = (isGetProduct: boolean = true) => {
		setBrands([]);
		setModels([]);
		setSearch('');

		setSelectedCategory(undefined);
		setSelectedBrand(undefined);
		setSelectedModel(undefined);

		setFilteredProducts([]);
		setSelectedProduct(undefined);

		if (isGetProduct) getProducts({ includeCategories: true });
	};

	useEffect(() => {
		if (!search) return;

		setFilteredProducts(filterHelper.fromAllData(products, search) || []);
	}, [search]);

	useEffect(() => {
		if (props.visible) getProducts({ includeCategories: true });

		return () => {
			if (!props.visible) {
				setProducts([]);
				setSelectedProduct(undefined);
			}
		};
	}, [props.visible]);

	return (
		<Dialog
			header="Print area default settings"
			draggable={false}
			visible={props.visible}
			className={styles.areaDialog}
			onHide={() => {
				props.setVisible?.(false);
				handleClearFilter(true);
			}}>
			<div className={styles.wrapper}>
				<div className={styles.categories}>
					<Accordion>
						{categories.map((_category) => (
							<AccordionTab key={_category.id} disabled={loading} headerTemplate={() => <strong>{_category.name}</strong>} className="catalog-accordion-tab">
								<div className={styles.accordionSubCategoryWrapper}>
									{_category.subCategories.length > 0
										? _category.subCategories.map((_subCategory) => (
												<button
													disabled={loading}
													key={_subCategory.id}
													onClick={() => {
														setSelectedCategory(_subCategory);

														getProducts({
															includeBrands: true,
															categoryId: _subCategory.id
														});

														setBrands([]);
														setSelectedBrand(undefined);
														setModels([]);
														setSelectedModel(undefined);
														setSelectedProduct(undefined);
													}}
													className={`${styles.accordionSubCategoryItem}${selectedCategory?.id === _subCategory.id ? ` ${styles.active}` : ''}`}>
													{_subCategory.name}
												</button>
										  ))
										: null}
								</div>
							</AccordionTab>
						))}
					</Accordion>
				</div>

				<div className={styles.container}>
					{!!selectedProduct ? (
						<ProductUpdateArea setProduct={setSelectedProduct} product={selectedProduct} />
					) : (
						<ProductList
							loading={loading}
							brands={brands}
							models={models}
							products={products}
							selectedBrand={selectedBrand}
							selectedModel={selectedModel}
							setBrands={setBrands}
							setLoading={setLoading}
							setModels={setModels}
							setSelectedModel={setSelectedModel}
							setSelectedBrand={setSelectedBrand}
							selectedCategory={selectedCategory}
							onGetProducts={(event) => {
								getProducts(event);
							}}
							onSelectProduct={(event) => {
								setSelectedProduct(event);
							}}
							search={search}
							setSearch={setSearch}
							filteredProducts={filteredProducts}
							setFilteredProducts={setFilteredProducts}
							clearFilter={handleClearFilter}
						/>
					)}
				</div>
			</div>
		</Dialog>
	);
};
