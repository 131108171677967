import { ErrorMessage, Form, Formik } from 'formik';
import { ResetPasswordRequest } from 'models/requests/userSecurity/reset-password-request';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import userSecurityService from 'services/user-security-service';
import * as Yup from 'yup';
import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [emailAddress, setEmailAddress] = useState('');

	const resetPasswordCode = searchParams.get('code') || '';

	const navigateToLogin = useCallback(() => navigate('/login'), [navigate]);

	const initialValues: ResetPasswordRequest = new ResetPasswordRequest();

	const validationScheme = Yup.object().shape({
		password: Yup.string().required('Password is Required').min(5, 'Password must be at least 5 characters.'),
		passwordConfirm: Yup.string()
			.required('Password Confirm is Required')
			.oneOf([Yup.ref('password'), null], 'Password must be match')
	});

	function handleSubmit(data: ResetPasswordRequest) {
		setIsSubmitDisabled(true);

		data.resetPasswordCode = resetPasswordCode;

		userSecurityService
			.resetPassword(data)
			.then(() => setTimeout(() => navigateToLogin(), 3000))
			.catch(() => setIsSubmitDisabled(false));
	}

	useEffect(() => {
		if (resetPasswordCode === '') navigateToLogin();

		userSecurityService
			.checkResetPasswordCode({ resetPasswordCode: resetPasswordCode })
			.then((response) => {
				setEmailAddress(response.data.email);
				setIsSubmitDisabled(false);
			})
			.catch(() => navigateToLogin());
	}, [navigateToLogin, resetPasswordCode]);

	return (
		<div className={`${styles.pageWrapper} w-full`}>
			<Formik initialValues={initialValues} validationSchema={validationScheme} onSubmit={handleSubmit}>
				{({ values, handleChange }) => (
					<Form className={`${styles.resetPasswordWrapper} grid`}>
						<div className={`${styles.formColumn} col-12`}>
							<small className={styles.infoText}>
								Your Email Address: <b>{emailAddress}</b>
							</small>
						</div>
						<div className={`${styles.formColumn} col-12`}>
							<div className="mb-1 px-1 flex align-items-center justify-content-between">
								<label className="font-medium block">Password</label>
								<ErrorMessage name="password" component="small" className="text-red font-medium" />
							</div>
							<InputText id="password" onChange={handleChange} />
						</div>
						<div className={`${styles.formColumn} col-12`}>
							<div className="mb-1 px-1 flex align-items-center justify-content-between">
								<label className="font-medium block">Password Confirm</label>
								<ErrorMessage name="passwordConfirm" component="small" className="text-red font-medium" />
							</div>
							<InputText id="passwordConfirm" onChange={handleChange} />
						</div>

						<div className={`${styles.formColumn} col-12`}>
							<Button disabled={isSubmitDisabled} type="submit" label="Change Password" className="p-button-raised" />
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ResetPassword;
