import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import { SelectedOrder } from '../models/selected-order';
import { CreateOrderStatuHistoryRequest } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';

type props = {
	statusVisible: boolean;
	setStatusVisible: Function;
	selectedOrder: SelectedOrder;
	setSelectedOrder: Function;
	getOrders: Function;
};
const UpdateStatus = ({ statusVisible, setStatusVisible, selectedOrder, setSelectedOrder, getOrders }: props) => {
	const [reason, setReason] = useState('');
	const [loading, setLoading] = useState(false);

	const onSave = async () => {
		try {
			setLoading(true);

			const request = new CreateOrderStatuHistoryRequest({ orderId: selectedOrder.orderId, orderStatuId: selectedOrder.orderStatuId, reason });

			const response = await orderStatuHistoriesService.create(request);

			if (!response.isSuccess) throw '';

			setSelectedOrder(undefined);
			getOrders();
			setStatusVisible(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			header="Update Status"
			visible={statusVisible}
			onHide={() => {
				setSelectedOrder(undefined);
				setStatusVisible(false);
			}}>
			<span className="col-12">Status to update: {selectedOrder.statuName}</span>

			<InputText placeholder="Reason (optional)" value={reason} onChange={(e) => setReason(e.target.value)} className="col-12 my-4" />

			<div className="col-12 text-right">
				<Button type="button" label="Update Status" onClick={onSave} loading={loading} />
			</div>
		</Dialog>
	);
};

export default UpdateStatus;
