import { TicketDetailDto } from 'features/tickets/_common/dtos/ticket-detail.dto';
import { GetTicketDetailRequest } from 'features/tickets/_common/get-ticket-detail/get-ticket-detail.request';
import ticketsService from 'features/tickets/_common/tickets.service';
import dateHelper from 'helpers/dateHelper';
import mediaHelper from 'helpers/media.helper';
import { User } from 'models/dtos/auth/users/user';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styles from './TicketDetail.module.scss';
import CreateComment from './components/CreateComment';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { CloseTicketRequest } from 'features/tickets/_common/close-ticket/close-ticket.request';

const TicketDetail = () => {
	const { ticketId } = useParams();
	const { userId, roles } = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const [ticket, setTicket] = useState<TicketDetailDto | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const commentFromHandler = (ticketUserId?: string, ticketEmail?: string, commentUserId?: string, commentEmail?: string) => {
		if (ticketUserId && ticketEmail) {
			if (roles.isAdmin) {
				return ticketUserId === userId ? 'Admin' : ticketEmail;
			} else {
				return ticketUserId === userId ? 'You' : 'Admin';
			}
		} else {
			if (roles.isAdmin) {
				return commentUserId === userId ? 'Admin' : commentEmail;
			} else {
				return commentUserId === userId ? 'You' : 'Admin';
			}
		}
	};

	const getTicketDetail = async () => {
		try {
			if (!ticketId) throw '';

			setLoading(true);

			const request = new GetTicketDetailRequest(ticketId);

			const response = await ticketsService.getTicketDetail(request);

			if (!response.isSuccess) throw '';

			setTicket(response.data);
		} catch (error) {
			setTicket(null);
		} finally {
			setLoading(false);
		}
	};

	const closeTicket = async() => {
		try {
			if (!ticketId) throw '';

			setLoading(true);

			const request = new CloseTicketRequest(ticketId);

			const response = await ticketsService.closeTicket(request);

			if (!response.isSuccess) throw ''

			getTicketDetail()
		} catch (error) {
			setLoading(false)
		} finally {}
	}

	const reOrderDirectHandler = () => {
		if (roles.isSeller) {
			if (ticket?.reOrder.status === 3) return { isActive: true, path: `/seller/re-orders/${ticket.reOrder.id}/${ticket.reOrder.newOrderId}` }
			else if (ticket?.reOrder.newOrderId) return { isActive: true, path: `/product/seller/order/${ticket.reOrder.newOrderId}` };
			else return { isActive: true, path: `/re-order/${ticket?.reOrder.oldOrderId}` }
		} else {
			if (ticket?.reOrder.status !== 2 && ticket?.reOrder.status !== 4) return { isActive: false, path: '/' };
			else if(ticket?.reOrder.status === 4) return { isActive: true, path: `/order/${ticket.reOrder.newOrderId}` };
			else return { isActive: true, path: `/re-orders/${ticket.reOrder.id}` };
		}
	}

	useEffect(() => {
		if (!ticketId) return;

		getTicketDetail();
	}, [ticketId]);

	return (
		<div className="relative">
			<div className="container-header">
				<h1 className="container-header-title">{ticket?.code}</h1>
			</div>
			<div className={styles.ticketMain}>
				<div className="w-full">
					<div className="container-body w-full p-3">
						<span className="font-bold">Title: </span>
						<span>{ticket?.title}</span>

						<hr />

						<div dangerouslySetInnerHTML={{ __html: ticket?.description || '' }} className="editor-output" />
					</div>

					{ticket?.comments &&
						ticket.comments.map((_comment, index) => (
							<div key={index} className={` container-body w-full p-3${_comment.userId === userId ? ' bg-blue-50' : ' bg-yellow-50'} p-3 mt-2`}>
								<div className="flex justify-content-between">
									<span className="font-bold">{commentFromHandler(_comment.userId, _comment.user.fullName)}</span>
									<span className="font-medium text-sm">{dateHelper.formatDate(_comment.createdDate || '')}</span>
								</div>
								<hr className="mb-4" />

								<div dangerouslySetInnerHTML={{ __html: _comment.comment || '' }} className="editor-output" />
							</div>
						))}

					{ticket ? (
						ticket?.isActive ? (
							<div className="container-body w-full p-3">
								<CreateComment ticketId={ticketId || ''} getTicketDetail={getTicketDetail} />
							</div>
						) : (
							<div className="h-7rem flex flex-column align-items-center justify-content-center mt-3" style={{ backgroundColor: '#fafafa', border: '1px solid var(--gray-400)' }}>
								<h5 className="m-0">The ticket is closed.</h5>
								<h6 className="m-0 text-color-secondary">You cannot reply this ticket.</h6>
							</div>
						)
					) : null}
				</div>

				<div className="relative">
					<div className={styles.stickyCodes}>
						<div className={`container-body h-max p-3 ${styles.ticketCards}`}>
							<div className={styles.ticketCard}>
								<div className="font-bold">Ticket Code: </div>
								<span>{ticket?.code}</span>
							</div>
							{ticket?.order && (
								<div className={styles.ticketCard}>
									<div className="font-bold">Order Code: </div>
									<span>{ticket?.order.orderCode}</span>
									<Link to={roles.isSeller ? `/product/seller/order/${ticket.order.orderId}` : `/order/${ticket.order.orderId}`} target="_blank" className={styles.orderLink}>
										<i className="pi pi-external-link" />
									</Link>
								</div>
							)}
							{ticket?.reOrder?.newOrderCode && (
								<div className={styles.ticketCard}>
									<div className="font-bold">Reorder Code: </div>
									<span>{ticket?.reOrder?.newOrderCode}</span>
									{reOrderDirectHandler().isActive ? <Link to={reOrderDirectHandler().path} target="_blank" className={styles.orderLink}>
										<i className="pi pi-external-link" />
									</Link> : null}
								</div>
							)}
							{ticket?.order?.etsyReceiptId && (
								<div className={styles.ticketCard}>
									<div className="font-bold">Etsy Receipt Id: </div>
									<span>{ticket?.order?.etsyReceiptId}</span>
								</div>
							)}
							<div className={styles.ticketCard}>
								<div className="font-bold">Created Date: </div>
								<span>{dateHelper.formatDate(ticket?.createdDate || '')}</span>
							</div>
							{roles.isAdmin && (
								<div className={styles.ticketCard}>
									<div className="font-bold">From: </div>
									<span>{ticket?.from.fullName}</span>
								</div>
							)}
						</div>

						{!!ticket?.attachments && ticket?.attachments.length > 0 ? (
							<div className={`container-body h-max p-3 ${styles.ticketCards}`}>
								<h6 className="m-0">Attachment Files</h6>
								<small className="text-color-secondary">Click on the image you want to download</small>

								<div className="flex flex-wrap align-items-center gap-3 my-2">
									{ticket?.attachments.map((_attachmentMedia, index) => (
										<a href={amazonS3Helper.showFile(_attachmentMedia, { resize: '900x900' })} key={index} target="_blank" className="shadow-2">
											<img src={amazonS3Helper.showFile(_attachmentMedia, { resize: '220x220' })} style={{ height: '7rem', maxWidth: '18rem' }} />
										</a>
									))}
								</div>
							</div>
						) : null}

						{!!ticket?.isActive && (
							<div className={`container-body h-max p-3 ${styles.ticketCards}`}>
								<h6 className="m-0">Actions</h6>
								<small>Warning: Closed tickets cannot be answered.</small>
								<PrButton text="Close Ticket" onClick={closeTicket} className="mt-2" />
							</div>
						)}
					</div>
				</div>
			</div>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default TicketDetail;
