import { CreateModelRequestDto } from './dtos/create-model.dto';

export class CreateEtsySynchronizeProductsRequest {
	createModel: CreateModelRequestDto;

	constructor(props: Partial<CreateEtsySynchronizeProductsRequest>) {
		this.createModel = props.createModel || new CreateModelRequestDto({});
	}

	get getConvertUriExtension() {
		return 'create-synchronize-products';
	}
}
