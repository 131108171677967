import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { CreateWizardContext, WizardContextType } from '../context/WizardContextProvider';
import { PrimeIcons } from 'primereact/api';
import { SurveyQuestionDto, SurveyQuestionOptionDto } from '../../../../../features/surveys/_common/survey-get/survey-get.response';
import { AnswerDto } from '../../../../../features/surveys/dtos/answer-dto';
import OptionButton from '../../Survey/components/OptionButton';

type Props = {
	question?: SurveyQuestionDto;
};

function SalesFigures({ question }: Props) {
	const context: WizardContextType = React.useContext(CreateWizardContext) as WizardContextType;

	const [error, setError] = useState('');
	const [selectedFiguresOption, setSelectedFiguresOptions] = useState<SurveyQuestionOptionDto | null>(null);
	const [otherInputId, setOtherInputId] = useState<string | null>(null);
	const [otherSelected, setOtherSelected] = useState(false);
	const [otherInput, setOtherInput] = useState<string>('');

	useEffect(() => {
		const filtered = question?.surveyQuestionOptions.filter((e) => e.textCanBeAdded);
		if (filtered!.length !== 0) setOtherInputId(filtered![0].id);
	}, []);

	const onOptionClick = (option: SurveyQuestionOptionDto) => {
		setSelectedFiguresOptions(option);
		if (option.id === otherInputId) setOtherSelected(!otherSelected);
	};

	const onSubmit = () => {
		if (!validateSelection()) return setError('Please select one of the options');

		if (!context.termsAndConditionsChecked) return setError('You must agree with the Terms of Service and Privacy Policy');

		if (!context.user) return;

		let newAnswers: AnswerDto[] = context.answers;

		const answer: AnswerDto = {
			surveyQuestionOptionId: selectedFiguresOption!.id,
			text: selectedFiguresOption?.id === otherInputId ? otherInput : null,
			userId: context.user?.userId,
			questionId: question!.id
		};

		newAnswers.push(answer);

		context.submitWizard();
	};

	const validateSelection = (): boolean => {
		return !!selectedFiguresOption && (otherSelected ? otherInput !== '' : true);
	};

	return (
		<div className="flex flex-column justify-content-center align-items-center w-full">
			<div className="text-center mb-1">
				<h2 className="font-semibold">What are your average monthly sales figures?</h2>
				<p>We will use this information to customize your experience.</p>

				<div className="h-1rem mb-2 ">{error !== '' && <div style={{color: "red"}} className="text-sm my-auto">{error}</div>}</div>
			</div>

			<div className="grid w-full justify-content-center">
				{question?.surveyQuestionOptions.map((item, index: number) => {
					return <OptionButton key={index} name={item.text} disabled={context.loading} checked={selectedFiguresOption!?.id === item.id} onClick={() => onOptionClick(item)} />;
				})}
			</div>

			<div className={`${otherInputId ? 'block' : 'hidden'} h-6rem w-full`}>
				{(selectedFiguresOption ? (selectedFiguresOption.id === otherInputId) : false) ? (
					<div className="flex flex-column justify-content-center align-items-stretch w-full px-2">
						<p className="text-sm" style={{ color: '#555555' }}>
							You selected 'Other'. Please enter your answer below.
						</p>
						<InputText
							className={error !== '' ? 'border-round-lg border-red-500 transition-linear' : 'border-round-lg'}
							id="channel_other"
							onChange={(value) => {
								setError('');
								setOtherInput(value.target.value);
							}}
						/>
					</div>
				) : null}
			</div>

			<div className="flex flex-column align-items-start my-3 px-2">
				<div className="flex flex-row justify-content-center align-items-center my-2">
					<Checkbox inputId="terms_and_conditions" name="" value="" onChange={(e) => context.setTermsAndConditionsChecked(e.checked)} checked={context.termsAndConditionsChecked} />
					<label htmlFor="terms_and_conditions" className="ml-2 text-sm">
						I accept the{' '}
						<a className={'text-primary'} href="https://printram.com/contracts/terms-of-service" target="_blank" rel="noopener noreferrer">
							Terms of Service
						</a>{' '}
						and I have read the{' '}
						<a className={'text-primary'} href="https://printram.com/contracts/privacy-policy" target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>
					</label>
				</div>

				<div className="flex flex-row justify-content-center align-items-center my-2">
					<Checkbox inputId="recieve_news" name="" value="" onChange={(e) => context.setReceiveNewsChecked(e.checked)} checked={context.receiveNewsChecked} />
					<label htmlFor="recieve_news" className="ml-2 text-sm">
						I would like to receive news, insights, and best practices from Printram
					</label>
				</div>
			</div>

			<div className="flex flex-row justify-content-center">
				<Button label="Back" onClick={() => context.back()} icon={PrimeIcons.ANGLE_LEFT} className="p-button-text mr-4 text-lg" style={{ color: '#101531' }} />

				<Button className="border-round-md border-none hover:bg-transparent text-lg px-4 py-2" style={{ backgroundColor: '#4541FF' }} label={!context.loading ? 'Finish' : ''} onClick={() => onSubmit()} />
			</div>
		</div>
	);
}

export default SalesFigures;