export class ProductAttachmentGetRequest {
	productId: string;

	constructor(props: Partial<ProductAttachmentGetRequest>) {
		this.productId = props.productId || '';
	}

	get getConvertUriExtension() {
		return `${this.productId}`;
	}
}
