import { MediaBase } from 'features/medias/dtos/bases/media.base.dto';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';

type Parameters = {
	blur: number;
	format: 'gif' | 'heic' | 'heif' | 'jpeg' | 'png' | 'raw' | 'tiff' | 'webp';
	upscale: boolean;
	noUpscale: boolean;
	quality: number;
	resize: string;
};

const downloadFileUrl = 'https://d1kzgtpp1b2wdd.cloudfront.net';
const showFileUrl = 'https://d2nwybattd150x.cloudfront.net';

const amazonUrls = [downloadFileUrl, showFileUrl];

const getImageWithContainer = (pMedia?: MediaBase | MyProductMedia): { url: string; isAmazonUrl: boolean } => {
	if (!pMedia) return { url: 'https://via.placeholder.com/400x400', isAmazonUrl: false };

	if ('imageWithStorage' in pMedia) {
		if (pMedia.imageWithUrl !== null) return { url: `${pMedia.imageWithUrl.url.split('/printram/')[1] || pMedia.imageWithUrl.url}`, isAmazonUrl: amazonUrls.findIndex((_url) => pMedia.imageWithUrl?.url.includes(_url)) > -1 };

		return { url: `${pMedia.imageWithStorage?.url.split('/printram/')[1] || pMedia.imageWithStorage?.url}`, isAmazonUrl: amazonUrls.findIndex((_url) => pMedia.imageWithStorage?.url.includes(_url)) > -1 };
	}

	if (!!pMedia.imageMediasWithUrl && pMedia.imageMediasWithUrl.length > 0) {
		const media = pMedia.imageMediasWithUrl[0];
		return { url: `${media.url.split('/printram/')[1] || media.url}`, isAmazonUrl: amazonUrls.findIndex((_url) => media.url.includes(_url)) > -1 };
	}

	const media = pMedia.imageMediaWithStorages[0];
	return { url: `${media.url.split('/printram/')[1] || media.url}`, isAmazonUrl: amazonUrls.findIndex((_url) => media.url.includes(_url)) > -1 };
};

const buildParameters = (parameters?: Partial<Parameters>) => {
	let parameterString = '';

	if (typeof parameters?.blur !== 'undefined') parameterString += `/filters:blur(${parameters.blur})`;
	if (typeof parameters?.format !== 'undefined') parameterString += `/filters:format(${parameters.format})`;
	if (typeof parameters?.upscale !== 'undefined') parameterString += '/filters:upscale()';
	if (typeof parameters?.noUpscale !== 'undefined') parameterString += '/filters:no_upscale()';
	if (typeof parameters?.quality !== 'undefined') parameterString += `/filters:quality(${parameters.quality})`;
	if (typeof parameters?.resize !== 'undefined') parameterString += `/fit-in/${parameters.resize}`;

	return parameterString;
};

const downloadFile = (media: MediaBase | MyProductMedia, parameters?: Partial<Parameters>) => {
	const url = getImageWithContainer(media);

	if (!url.isAmazonUrl) return url.url;

	return `${downloadFileUrl}/filters:strip_icc()/filters:strip_exif()${buildParameters(parameters)}/printram/${url.url}`;
};

const showFile = (media?: MediaBase | MyProductMedia | null, parameters?: Partial<Parameters>): string => {
	const url = getImageWithContainer(media || undefined);

	if (!url.isAmazonUrl) return url.url;

	return `${showFileUrl}/filters:strip_icc()/filters:strip_exif()${buildParameters(parameters)}/printram/${url.url}`;
};

export const amazonS3Helper = { showFile, downloadFile, getImageWithContainer };
