import { OrderDto } from 'models/dtos/order/order';
import styles from './OrderDetailInfo.module.scss';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { BsBrush } from 'react-icons/bs';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import stringHelper from 'helpers/string.helper';

type Props = {
	order: OrderDto;
	onStatuChange: (event: PrDropdownType) => void;
	onUpdateAddress: () => void;
};

export const OrderDetailInfo = ({ order, onStatuChange, onUpdateAddress }: Partial<Props>) => {
	return (
		<section className="container-body p-3 z-5">
			<h4 className="m-0">Details</h4>
			<p className="text-sm text-600">The order details are provided below.</p>

			<div className={styles.detailWrapper}>
				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Customer Name</h6>
					</div>

					<p>{order?.customerFullName || '-'}</p>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Customer Note</h6>
					</div>

					<p>{order?.customerNote || '-'}</p>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Gift Note</h6>
					</div>

					<p>{order?.giftInformation?.message || '-'}</p>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Seller Note</h6>
					</div>

					<p>{order?.sellerNote || '-'}</p>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Delivery Type / Order Type</h6>
					</div>

					<span>{DeliveryTypes[order?.deliveryType || 0]} / </span>
					<span>{order !== null ? (order?.isManuelOrder ? 'Manuel' : order?.isExternalOrder ? 'External' : 'Automatic') : '-'}</span>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Platform</h6>
					</div>

					<p>{order !== null ? (order?.isManuelOrder ? 'Printram' : 'ETSY') : '-'}</p>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Shipping Address</h6>

						{order?.statu.type === OrderStatuTypes.PendingApproval ? (
							<PrButton
								icon={<BsBrush />}
								type="icon"
								id="shippingAddressUpdate"
								tooltip="Update shipping address"
								className="text-primary"
								onClick={() => {
									onUpdateAddress?.();
								}}
							/>
						) : null}
					</div>

					<address>{order?.orderAddress?.formattedAddress || '-'}</address>
				</div>

				<div className={styles.detailItem}>
					<div className={styles.detailItemHeader}>
						<h6>Status</h6>
					</div>

					<PrDropdown
						className="w-full"
						disabled={!order?.availableOrderStatus || order.availableOrderStatus.length < 1}
						value={{ value: '', label: stringHelper.parseAtUpperCaseAndJoin(order?.statu.readableName || '') }}
						options={order?.availableOrderStatus?.map((_status) => ({ label: stringHelper.parseAtUpperCaseAndJoin(_status.readableName), value: _status.id }))}
						onChange={(event) => {
							onStatuChange?.(event);
						}}
					/>
					{/* <Menu as="div" className="pr-dropdown w-full">
						<Menu.Button className={`pr-btn pr-btn-secondary w-full${!order || !order.availableOrderStatus || (!!order && order.availableOrderStatus && order.availableOrderStatus.length < 1) ? ' disabled' : ''}`}>
							<span className="mr-2">{stringHelper.parseAtUpperCaseAndJoin(order?.statu.readableName || '')}</span>
							<AiFillCaretDown className="ml-auto" />
						</Menu.Button>

						<Menu.Items className="pr-dropdown-menu right w-full">
							{!!order?.availableOrderStatus &&
								order.availableOrderStatus.map((_statu) => (
									<Menu.Item
										key={_statu.id}
										as="button"
										onClick={() => {
											if (_statu.type === OrderStatuTypes.OrderApproved && order.deliveryType === DeliveryTypes.PickUp) {
												updateOrderStatus({ orderId, orderStatuId: _statu.type, reason: '' });
												return;
											}

											setSelectedStatu(_statu.type);

											_statu.type === OrderStatuTypes.Canceled ? setStatuModalVisible(true) : setStatuAcknowledgeModalVisible(true);
										}}
										className="pr-btn pr-dropdown-menu-item">
										{_statu.readableName}
									</Menu.Item>
								))}
						</Menu.Items>
					</Menu> */}
				</div>
			</div>
		</section>
	);
};
