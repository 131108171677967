import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextareaInput from 'helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import { CreateOrderStatuHistoryRequest } from 'models/requests/orders/create-order-statu-history-request';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

export interface CreateOrderStatuHistoryModalData {
	orderId: string;
	statu: OrderStatuTypes;
}

type Props = {
	data: CreateOrderStatuHistoryModalData;
	callback: (request: CreateOrderStatuHistoryRequest) => void;
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateOrderStatuHistoryModal = (props: Partial<Props>) => {
	const [reason, setReason] = useState<string>('');

	return (
		<Dialog header={`Update statu to "${OrderStatuTypes[props.data?.statu || OrderStatuTypes.Unknown]}"`} style={{ maxWidth: '50rem' }} visible={props.visible} onHide={() => props.setVisible?.(false)}>
			<PrTextareaInput
				label="Reason"
				value={reason}
				onChange={(event) => setReason(event.target.value)}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						event.preventDefault();

						props.callback?.({
							reason,
							orderId: props.data?.orderId || '',
							orderStatuId: props.data?.statu || OrderStatuTypes.Unknown
						});

						props.setVisible?.(false);
						setReason('');
					}
				}}
				rows={3}
			/>

			<div className="flex justify-content-end mt-3">
				<PrButton
					text="Update Status"
					onClick={() => {
						props.callback?.({
							reason,
							orderId: props.data?.orderId || '',
							orderStatuId: props.data?.statu || OrderStatuTypes.Unknown
						});

						props.setVisible?.(false);
						setReason('');
					}}
				/>
			</div>
		</Dialog>
	);
};

export default CreateOrderStatuHistoryModal;
