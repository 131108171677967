import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {BiDotsHorizontal} from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

function BalanceDropdown() {
    const navigate = useNavigate();
    return (
        <div className="relative">
            <Menu as="div" className="inline-block text-left">
                <div>
                    <Menu.Button className="text-sm bg-transparent border-none">
                        <BiDotsHorizontal size='1rem' className="" aria-hidden="true" />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items style={{ border: '1px solid #D1D3D4FF' }} className="absolute border-round-md right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="p-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={'pr-btn pr-dropdown-menu-item font-normal text-sm'} onClick={() => navigate('/account/payments')}>
                                        Add to your balance
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button className={'pr-btn pr-dropdown-menu-item font-normal text-sm'} onClick={() => navigate('/account/payments')}>
                                        Payment
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

export default BalanceDropdown;
