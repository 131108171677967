export class ShipmentLabelValidatorRequest {
	file: File;

	constructor(file: File) {
		this.file = file;
	}

	get getConvertUriExtension() {
		return `shipment-label-validator`;
	}
}