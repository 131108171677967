import HttpBaseService from './_common/http-base-service';
import { PrintOption } from '../models/dtos/auth/users/print-option';
import { UserPrintOptionCreateOrUpdateRequest } from '../models/requests/users/userPrintOptions/user-print-option-create-or-update-request';
import { DataResponse } from '../models/_commons/responses/data-response';

class UserPrintOptionService extends HttpBaseService {
	constructor() {
		super('user-print-options');
	}

	async getByUserId(userId: string): Promise<DataResponse<PrintOption>> {
		return await this.getData('get-by-user?UserId=' + userId);
	}

	async createOrUpdateOption(request: UserPrintOptionCreateOrUpdateRequest): Promise<DataResponse<PrintOption>> {
		return await this.postData('create-or-update', request);
	}
}

export default new UserPrintOptionService();
