import { ProductForImport } from 'features/products/_common/dtos/product-for-import.dto';
import { ImportProductRequest } from 'features/products/_common/import-product/import-product.request';
import productService from 'features/products/_common/product.service';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import CategorySelect from './CategorySelect';

type props = { products: ProductForImport[]; loading: boolean };
const ProductsTable = ({ products, loading }: props) => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const [categoryId, setCategoryId] = useState<string>('');
	const [categoryVisible, setCategoryVisible] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductForImport | undefined>(undefined);

	const handleSelect = (product: ProductForImport) => {
		setSelectedProduct(product);
		setCategoryVisible(true);
	};

	const importProduct = async (product: ProductForImport) => {
		try {
			if (!user?.userId) throw '';

			setSubmitLoading(true);

			const request = new ImportProductRequest(user?.userId, categoryId, product);

			await productService.importProduct(request);
		} finally {
			setSubmitLoading(false);
		}
	};

	const descriptionBodyTemplate = (rowData: ProductForImport) => <div dangerouslySetInnerHTML={{ __html: rowData.description }} className="overflow-hidden text-overflow-ellipsis" style={{ height: 162 }} />;

	const imageBodyTemplate = (rowData: ProductForImport) => <img src={rowData.image} alt={rowData.image} className="w-10rem" />;

	const actionsBodyTemplate = (product: ProductForImport) => <Button label="Import Product" onClick={() => handleSelect(product)} loading={submitLoading} icon="pi pi-download" />;

	return (
		<React.Fragment>
			<DataTable value={products} dataKey="uniqueKey" rows={10} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={products.length} rowsPerPageOptions={[5, 10, 15, 20]} loading={loading} className="p-datatable-gridlines">
				<Column field="title" header="Title" filter />
				<Column field="brand" header="Brand" filter />
				<Column field="model" header="Model" filter />
				<Column body={descriptionBodyTemplate} header="Description" style={{ width: 400 }} />
				<Column body={imageBodyTemplate} header="Image" style={{ width: 160 }} />
				<Column body={actionsBodyTemplate} header="Actions" style={{ width: 160 }} />
			</DataTable>

			<CategorySelect visible={categoryVisible} setVisible={setCategoryVisible} categoryId={categoryId} setCategoryId={setCategoryId} product={selectedProduct} importProduct={importProduct} />
		</React.Fragment>
	);
};

export default ProductsTable;
