import { SyncOrderContext, SyncOrderContextType } from 'components/pages/orders/sync-order/context/SyncOrderContextProvider';
import React, { useEffect, useState } from 'react';
import styles from './ProductsTable.module.scss';
import currencyHelper from 'helpers/curreny.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import stringHelper from 'helpers/string.helper';
import { Tooltip } from 'primereact/tooltip';

type props = { syncAction: Function };
export const ProductsTable = ({ syncAction }: props) => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;
	const [showEtsyDetails, setShowEtsyDetails] = useState<string[]>([]);

	const openOrCloseDetail = (externalProductId: string) => {
		if (showEtsyDetails.includes(externalProductId)) {
			setShowEtsyDetails(showEtsyDetails.filter((_detail) => _detail !== externalProductId));
		} else {
			setShowEtsyDetails([...showEtsyDetails, externalProductId]);
		}
	};

	return (
		<table className={styles.table} style={{ '--item-count': context.request.createModel.products.length } as React.CSSProperties}>
			<thead>
				<tr>
					<th>Order Details</th>
					<th>Print File</th>
					<th>Quantity</th>
					{/*<th>Price </th>*/}
					{/*<th>Total</th>*/}
					<th></th>
				</tr>
			</thead>

			<tbody>
				{context.externalProducts.map((_product, index) => (
					<React.Fragment key={index}>
						<tr>
							<td className="w-5">
								{_product.product ? (
									<div className={styles.productInfo}>
										<h6>Choosed Order Details</h6>

										<div>
											<div>
												<span className="font-bold">Name : </span>
												<span>{_product.product?.productName}</span>
											</div>
											<div>
												<span className="font-bold">Model : </span>
												<span>{_product.product?.model.brand.name}</span>
											</div>
											<div className={styles.color}>
												<Tooltip target={`#color-${index}-${_product.selectedColor?.id}`} />
												<b>Color : </b>
												<span
													id={`color-${index}-${_product.selectedColor?.id}`}
													data-pr-tooltip={_product.selectedColor?.hexCode}
													data-pr-position="top"
													style={{
														backgroundColor: _product.selectedColor?.hexCode,
														backgroundImage: `url("${_product.selectedColor?.imageUrl}")`,
														backgroundRepeat: 'no-repeat',
														backgroundSize: 'cover'
													}}
												/>
												{_product.selectedColor?.name}
											</div>
											<div>
												<span className="font-bold">Size : </span>
												<span>{_product.selectedSize?.shortName}</span>
											</div>
										</div>
										<div>
											<div className={styles.etsyDetailButton} onClick={() => openOrCloseDetail(_product.externalProductId)}>
												<span>Etsy order details</span>
												{showEtsyDetails.includes(_product.externalProductId) ? <IoIosArrowUp size={20} style={{ marginLeft: 5 }} /> : <IoIosArrowDown size={20} style={{ marginLeft: 5 }} />}
											</div>
										</div>

										{_product.sides.length > 1 && showEtsyDetails.includes(_product.externalProductId) ? (
											<div className={styles.etsyDetail}>
												<div>
													<Tooltip target={`#prod-${_product.productId}`} />
													<span className="font-bold">Title : </span>
													<span id={`prod-${_product.productId}`} data-pr-tooltip={_product.title} data-pr-position="top" className={styles.value}>
														{stringHelper.formatStringLength(_product.title, 30)}
													</span>
												</div>

												{_product.variations.map((_vari, index) => (
													<div key={index}>
														<Tooltip target={`#vari-${index}`} />
														<span className="font-bold">{_vari.key} : </span>
														<span id={`vari-${index}`} data-pr-tooltip={_vari.value} data-pr-position="top" className={styles.value}>
															{stringHelper.formatStringLength(_vari.value, 30)}
														</span>
													</div>
												))}

												<div>
													<Tooltip target={`#sku-${_product.productId}`} />
													<span className="font-bold">SKU : </span>
													<span id={`sku-${_product.productId}`} data-pr-tooltip={_product.sku} data-pr-position="top" className={styles.value}>
														{stringHelper.formatStringLength(_product.sku, 30)}
													</span>
												</div>
											</div>
										) : null}
									</div>
								) : (
									<div className={styles.productInfo}>
										<div>
											<span className="font-bold">Title : </span>
											<span>{_product.title}</span>
										</div>

										{_product.variations.map((_vari, index) => (
											<div key={index}>
												<span className="font-bold">{_vari.key} : </span>
												<span>{_vari.value}</span>
											</div>
										))}

										<div>
											<span className="font-bold">SKU : </span>
											<span>{_product.sku}</span>
										</div>
									</div>
								)}
							</td>
							<td className="w-5">
								<div className={styles.printFileWrapper}>
									{_product.sides.length > 0 ? (
										_product.sides.map((_side, index) => (
											<React.Fragment key={index}>
												<div className={styles.printFileItem}>
													<div className={styles.printFileItemImgContainer}>
														<img src={URL.createObjectURL(_side.designFile!)} alt={_side.designFile?.name} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
													</div>

													<div className={styles.printFileItemInfo}>
														<span>{_side.name} Print File</span>
													</div>
												</div>

												<div className={styles.printFileItem}>
													<div className={styles.printFileItemImgContainer}>
														<img src={URL.createObjectURL(_side.templateMockupFile!)} alt={_side.templateMockupFile?.name} />
													</div>

													<div className={styles.printFileItemInfo}>
														<span>{_side.name} Mockup</span>
													</div>
												</div>
											</React.Fragment>
										))
									) : (
										<div className={styles.printFileEmptyContainer}>
											<div className={styles.printFileEmpty}>
												<span>After sync product, the print files will appear in this section.</span>
											</div>
											<div className={styles.printFileEmpty}>
												<span>After sync product, the mockup images will appear in this section.</span>
											</div>
										</div>
									)}
								</div>
							</td>
							<td className="w-1">{_product.quantity}</td>
							{/*<td className="w-1">{currencyHelper.formatPrice(context?.calculatedPrice?.items.find((_item) => _item.uniqueIdentifier === _product.externalProductId)?.pricePerEach.formattedPricePerUnit || _product.externalPrice.formattedPricePerUnit)}</td>*/}
							{/*<td className="w-1">{currencyHelper.formatPrice(context?.calculatedPrice?.items.find((_item) => _item.uniqueIdentifier === _product.externalProductId)?.totalPrice.formattedPricePerUnit || _product.externalPrice.formattedPricePerUnit * _product.quantity)}</td>*/}

							<td className="vertical-align-bottom w-2">
								<div className={styles.buttons}>
									<PrButton text={_product.sides.length > 0 ? 'Edit' : 'Sync Product'} onClick={() => syncAction(_product)} style={{ paddingLeft: _product.sides.length > 0 ? '3rem' : '1rem', paddingRight: _product.sides.length > 0 ? '3rem' : '1rem' }} />
								</div>
							</td>
						</tr>
						{_product.sides.length === 1 ? (
							<tr className={`${styles.etsyInfoWrapper}${showEtsyDetails.includes(_product.externalProductId) ? ` ${styles.active}` : ''}`}>
								<td colSpan={6}>
									<div className={`${styles.etsyDetailV2}`}>
										<div>
											<Tooltip target={`#prod-${_product.productId}`} />
											<span className="font-bold">Title : </span>
											<span id={`prod-${_product.productId}`} data-pr-tooltip={_product.title} data-pr-position="top" className={styles.value}>
												{stringHelper.formatStringLength(_product.title, 30)}
											</span>
										</div>

										{_product.variations.map((_vari, index) => (
											<div key={index}>
												<Tooltip target={`#vari-${index}`} />
												<span className="font-bold">{_vari.key} : </span>
												<span id={`vari-${index}`} data-pr-tooltip={_vari.value} data-pr-position="top" className={styles.value}>
													{stringHelper.formatStringLength(_vari.value, 30)}
												</span>
											</div>
										))}

										<div>
											<Tooltip target={`#sku-${_product.productId}`} />
											<span className="font-bold">SKU : </span>
											<span id={`sku-${_product.productId}`} data-pr-tooltip={_product.sku} data-pr-position="top" className={styles.value}>
												{stringHelper.formatStringLength(_product.sku, 30)}
											</span>
										</div>
									</div>
								</td>
							</tr>
						) : null}
					</React.Fragment>
				))}
			</tbody>
		</table>
	);
};
