import React from 'react';
import { SyncOrderContext, SyncOrderContextType } from '../../context/SyncOrderContextProvider';
import { ShipForm } from './components/ship-form/ShipForm';
import { ShipSummary } from './components/ship-summary/ShipSummary';

export const StepShipping = () => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	return !context.isShowShippingForm && !!context.calculatedPrice ? <ShipSummary /> : <ShipForm />;
};
