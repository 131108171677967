import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import styles from './ProductCatalogCategories.module.scss';
import mediaHelper from 'helpers/media.helper';
import { Link } from 'react-router-dom';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';

const ProductCatalogCategories = () => {
	const { categories, selectedCategory } = useOutletContext<{ categories: CategoryBaseDto[]; selectedCategory: CategoryBaseDto }>();

	return (
		<section className={`${styles.categoryWrapper} gap-3`}>
			{!!selectedCategory
				? selectedCategory.subCategories.map((_category) => (
						<Link key={_category.id} to={`/product/catalog/${_category.id}`} className={`container-body p-0 ${styles.categoryItem}`}>
							<img src={amazonS3Helper.showFile(_category.media, { resize: '250x250' })} loading="lazy" alt={_category.name} />

							<div className={styles.categoryItemTitle}>
								<h5 className="m-0">{_category.name}</h5>
							</div>
						</Link>
				  ))
				: categories.map((_category) => (
						<Link key={_category.id} to={`/product/catalog/${_category.id}`} className={`container-body p-0 ${styles.categoryItem}`}>
							<img src={amazonS3Helper.showFile(_category.media, { resize: '250x250' })} loading="lazy" alt={_category.name} />

							<div className={styles.categoryItemTitle}>
								<h5 className="m-0">{_category.name}</h5>
							</div>
						</Link>
				  ))}
		</section>
	);
};

export default ProductCatalogCategories;
