import { Menu } from '@headlessui/react';
import { AiFillCaretDown } from 'react-icons/ai';
import React from 'react'

type props = {
	orderIds: Array<string>;
	loading: boolean;
	setSendNewDtfVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setSendNewAutomatedDtfVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setSendExistingDtfVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const DtfDropdown = ({ orderIds, setSendNewDtfVisible, setSendNewAutomatedDtfVisible, setSendExistingDtfVisible, loading }: props) => {
	const dropdownMenuItems = [
		<Menu.Item as="button" key={1} onClick={() => setSendNewAutomatedDtfVisible(true)} className="pr-btn pr-dropdown-menu-item">
			Create a New Automated DTF
		</Menu.Item>,
		<Menu.Item as="button" key={2} onClick={() => setSendNewDtfVisible(true)} className="pr-btn pr-dropdown-menu-item">
			Create a New DTF
		</Menu.Item>,
		<Menu.Item as="button" key={3} onClick={() => setSendExistingDtfVisible(true)} className="pr-btn pr-dropdown-menu-item">
			Add an Existing DTF
		</Menu.Item>
	];

	return (
		<React.Fragment>
			{/* {!!orderIds && orderIds.length > 0 ? <DropdownButton menuItems={dropdownMenuItems.filter((data) => !skipTypes?.find((_type) => _type === data.key))} loading={loading} label="Prints" icon="pi pi-file-pdf" /> : null} */}

			{!!orderIds && orderIds.length > 0 ? (
				<Menu as="div" className="pr-dropdown" style={{ zIndex: '99' }}>
					<Menu.Button className="pr-btn pr-btn-secondary" disabled={loading}>
						<span className="mr-3">Send to DTF</span>
						<AiFillCaretDown />
					</Menu.Button>

					<Menu.Items className="pr-dropdown-menu">{dropdownMenuItems}</Menu.Items>
				</Menu>
			) : null}
		</React.Fragment>
	);
};

export default DtfDropdown