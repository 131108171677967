import countryService from 'features/countries/country.service';
import { GetCountriesRequest } from 'features/countries/get-countries/get-countries.request';
import { CountryBaseDto } from 'features/countries/get-countries/get-countries.response';
import { CreateManuelOrderDeliveryAddressRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import orderService from 'features/orders/_common/order.service';
import { UpdateOrderAddressRequest } from 'features/orders/_common/update-order-address/update-order-address.request';
import { Form, Formik } from 'formik';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import DropdownInput from 'helpers/widgets/Printram/Forms/Dropdown/DropdownInput';
import AutoCompleteInput from 'helpers/widgets/Printram/Forms/Input/AutoCompleteInput';
import PrPrefixDropdownInput from 'helpers/widgets/Printram/Forms/Input/PrPrefixDropdownInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { OrderDto } from 'models/dtos/order/order';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	order: OrderDto;
	callback: () => void;
};

const UpdateShippingAddressModal = (props: Props) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [countryLoading, setCountryLoading] = useState<boolean>(false);
	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [prefix, setPrefix] = useState<string>('1');

	const initialValues = new UpdateOrderAddressRequest({ ...props.order.orderAddress, receiverPhoneNumber: props.order.orderAddress?.receiverPhoneNumber?.slice(-10)  });

	const getCountries = async () => {
		try {
			setCountryLoading(true);
			const response = await countryService.getCountries(new GetCountriesRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCountries(response.data);
		} catch (error) {
			setCountries([]);
		} finally {
			setCountryLoading(false);
		}
	};

	useEffect(() => {
		if (!props.visible) return;

		getCountries();

		const receiverPhoneNumber = props.order.orderAddress?.receiverPhoneNumber;
		setPrefix(receiverPhoneNumber?.slice(0,receiverPhoneNumber.length - 10) || '1');
	}, [props.visible]);

	return (
		<Dialog header={`Update Order #${props.order.code} Shipping Address`} visible={props.visible} onHide={() => props.setVisible(false)} style={{ maxWidth: '50rem' }}>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values) => {
					try {
						setLoading(true);

						const request = new UpdateOrderAddressRequest({ ...structuredClone(values) });

						if (!!request.receiverPhoneNumber && request.receiverPhoneNumber.length > 0) request.receiverPhoneNumber = prefix + request.receiverPhoneNumber;
						else request.receiverPhoneNumber = null;

						request.receiverEmail = request.receiverEmail || null;

						const response = await orderService.updateOrderAddress(request);
						if (!response.isSuccess) throw '';

						props.setVisible(false);
						props.callback();
					} catch (error) {
					} finally {
						setLoading(false);
					}
				}}>
				{(form) => (
					<Form className="flex flex-column gap-4 p-4">
						<PrTextInput label="Receiver Full Name" name="receiverFullName" value={form.values.receiverFullName} required onChange={form.handleChange} />

						<PrTextInput label="Receiver Email" name="receiverEmail" type="email" required={false} value={form.values.receiverEmail || ''} onChange={form.handleChange} />

						{!countryLoading ? (
							<AutoCompleteInput
								label="Search Address"
								name="formattedAddress"
								disabled={countryLoading}
								value={form.values.formattedAddress}
								required
								onChange={form.handleChange}
								defaultValue={form.values.formattedAddress}
								onPlaceSelectedDto={(event) => {
									form.setFieldValue('formattedAddress', event.formattedAddress || '');
									form.setFieldValue('state', event.state || '');
									form.setFieldValue('city', event.city || '');
									form.setFieldValue('zip', event.zip || '');
									form.setFieldValue('firstLine', event.firstLine || '');
									form.setFieldValue('secondLine', event.secondLine || '');

									for (const country of countries) {
										if (country.iso2 === event.countryIso2) {
											form.setFieldValue('countryIso', country.iso3);
											break;
										}
									}
								}}
							/>
						) : null}

						<PrTextInput label="Address Line 1" name="firstLine" value={form.values.firstLine} required onChange={form.handleChange} />

						<PrTextInput label="Address Line 2" name="secondLine" value={form.values.secondLine || ''} onChange={form.handleChange} />

						<div className="flex gap-2">
							<DropdownInput label="Country" name="countryIso" placeholder="Select Country" required filter optionLabel="name" optionValue="iso3" options={countries} disabled={countryLoading} value={form.values.countryIso} onChange={form.handleChange} />

							<PrTextInput label="State" name="state" value={form.values.state || ''} required className="h-full" onChange={form.handleChange} />
						</div>

						<div className="flex gap-2">
							<PrTextInput label="City" name="city" value={form.values.city} required onChange={form.handleChange} />

							<PrTextInput label="Zip Code" name="zip" value={form.values.zip} required onChange={form.handleChange} />
						</div>

						<div className="flex gap-2">
							<div className="w-13rem">
								<PrPrefixDropdownInput label="Code" name="prefix" isOptional value={prefix} onChange={(e) => setPrefix(e.target.value)} />
							</div>

							<PrTextInput
								label="Phone Number"
								name="receiverPhoneNumber"
								type="tel"
								value={form.values.receiverPhoneNumber || ''}
								required={false}
								className="h-full"
								onChange={(e) => {
									if (e.target.value.length > 10) return;

									if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

									form.handleChange(e);
								}}
							/>
						</div>

						<PrButton btnType="submit" text="Update Shipping Address" loading={loading} icon={<span className="pi pi-fw pi-sync" />} className="ml-auto" />

						{loading ? (
							<div className="pr-loading">
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						) : null}
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default UpdateShippingAddressModal;
