import { PlanFeatureDto } from "../create-plan-with-other-domains/dtos/plan-feature.dto";
import { PlanFeatureForUpdateDto } from "../dtos/plan-feature-for-update.dto";

export class PlanUpdateRequest {
	id: string = ""
	basePlanId?: string | null = null;
	basePlanName: string = "";
	planType: number = 0;
	name: string = '';
	description: string = '';
	priceAsMonthly: number = 0;
	priceAsYearly: number = 0;
	isBuyable: boolean = true;
	planFeatures: PlanFeatureForUpdateDto[] = [];

	get getConvertUriExtension() {
		return `update`;
	}

	constructor(id: string, basePlanName: string, basePlanId: string | null, planType: number, name: string, description: string, priceAsMonthly: number, priceAsYearly: number, isBuyable: boolean, planFeatures: PlanFeatureForUpdateDto[]) {
		this.id = id;
		this.basePlanId = basePlanId;
		this.planType = planType;
		this.name = name;
		this.description = description;
		this.priceAsMonthly = priceAsMonthly;
		this.priceAsYearly = priceAsYearly;
		this.isBuyable = isBuyable;
		this.planFeatures = planFeatures;
	}
}