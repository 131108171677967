import HttpBaseService from "services/_common/http-base-service";
import { GetOrCreateCustomerIdRequest } from "./get-or-create-customer-id/get-or-create-customer-id.request";
import { GetOrCreateCustomerIdResponse } from "./get-or-create-customer-id/get-or-create-customer-id.response";
import { GetPaymentMethodsRequest } from "./get-payment-methods/get-payment-methods.request";
import { GetPaymentMethodsResponse } from "./get-payment-methods/get-payment-methods.response";
import { IsUserHaveAnAccountRequest } from "./is-user-have-an-account/is-user-have-an-account.request";
import { IsUserHaveAnAccountResponse } from "./is-user-have-an-account/is-user-have-an-account.response";
import { CreateStripeAccountRequest } from "./create-account/create-account.request";
import { CreateStripeAccountResponse } from "./create-account/create-account.response";
import { CreateSetupIntentRequest } from "./create-setup-intent/create-setup-intent.request";


class StripeService extends HttpBaseService {
	constructor() {
		super('stripe');
	}

	async getOrCreateCustomerId(request: GetOrCreateCustomerIdRequest): Promise<GetOrCreateCustomerIdResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async isUserHaveAnAccount(request: IsUserHaveAnAccountRequest): Promise<IsUserHaveAnAccountResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createAccount(request: CreateStripeAccountRequest): Promise<CreateStripeAccountResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getPaymentMethods(request: GetPaymentMethodsRequest): Promise<GetPaymentMethodsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createSetupIntent(request: CreateSetupIntentRequest) {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new StripeService();
