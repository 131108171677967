import axios from 'axios';
import { GetCoreProductRequest } from 'features/products/_common/get-core-product/get-core-product.request';
import { Color, CoreProductDto, Variant } from 'features/products/_common/get-core-product/get-core-product.response';
import productService from 'features/products/_common/product.service';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';
import imageHelper from 'helpers/image.helper';
import mediaHelper from 'helpers/media.helper';
import toastHelper from 'helpers/toast.helper';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import GalleriaV2 from 'helpers/widgets/Products/GalleriaV2/GalleriaV2';
import VariantsAccordionV2 from 'helpers/widgets/Products/VariantsAccordion/VariantsAccordionV2';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import minMaxPriceHandler from '../components/MinMaxPriceHandler';
import BellaCanvasCareInstructions from './care-instructions/BellaCanvasCareInstructions';
import GildanCareInstructions from './care-instructions/GildanCareInstructions';

const ProductDetailNew = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);
	const { productId } = useParams();
	const colorOverlayPanelRef = useRef<OverlayPanel>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('Loading');
	const [product, setProduct] = useState<CoreProductDto | undefined>(undefined);
	const [images, setImages] = useState<MyProductMedia[]>([]);
	const [colors, setColors] = useState<Color[]>([]);
	const [allColors, setAllColors] = useState<Color[]>([]);

	const buildColors = (variants: Variant[]) => {
		const newAllColors: Color[] = [];

		for (const variant of variants) {
			if (newAllColors.findIndex((_color) => _color.hexCode === variant.color.hexCode) > -1) continue;

			newAllColors.push(variant.color);
		}

		setAllColors(newAllColors);
		setColors(newAllColors.slice(0, 16));
	};

	const buildImages = (pProduct: CoreProductDto) => {
		if (!pProduct.images || pProduct.images.length < 1) {
			setImages(imageHelper.getImagesByVariants(pProduct.variants));

			return;
		}

		const images: MyProductMedia[] = [];

		for (const image of pProduct.images) {
			images.push(image);
		}

		setImages(images);
	};

	const getProduct = async () => {
		try {
			setLoadingText('Loading Product');
			setLoading(true);

			if (!productId) throw '';

			const response = await productService.getCoreProduct(new GetCoreProductRequest(productId));
			if (!response.isSuccess || !response.data) throw '';

			setProduct(response.data);
			buildColors(response.data.variants);
			buildImages(response.data);
		} catch (error) {
			setProduct(undefined);
			setColors([]);
			setAllColors([]);
		} finally {
			setLoading(false);
		}
	};

	const handleScrollDetail = () => {
		const productDetail = document.getElementById('productDetail');
		if (!productDetail) return;

		productDetail.scrollIntoView();
	};

	const copytoClipboard = (hexCode: string) => {
		navigator.clipboard.writeText(hexCode);
		toastHelper.info('Code copied: ' + hexCode);
	};

	useEffect(() => {
		if (!productId) return;

		getProduct();
	}, [productId]);

	return (
		<div className="container relative" style={{ minHeight: 'calc(100vh - 10rem)' }}>
			{!!product ? (
				<React.Fragment>
					<div className="flex gap-2 mb-3 font-bold">
						<span className="text-primary">{product.categories[0].name}</span>
						<span>/</span>
						<span className="text-700">{product.categories[0].subCategories[0].name}</span>
					</div>

					<div className="card">
						<div className="grid">
							<section className="col-12 lg:col-6">
								<GalleriaV2 medias={images} />
							</section>

							<section className="col-12 lg:col-6">
								<span className="text-500">{product.categories.map((_category) => _category.name).join(', ')}</span>

								<h1 className="mt-2 mb-3">{product.name}</h1>

								<div className="flex gap-2 font-medium text-700">
									<span>
										{product.model.brand.name} {product.model.name}
									</span>
									<span>|</span>
									<span role="button" onClick={handleScrollDetail} className="text-primary cursor-pointer select-none">
										Product Details
									</span>
								</div>

								<ul className="mb-4">
									{product.details.map((_detail) => (
										<li key={_detail.id} dangerouslySetInnerHTML={{ __html: _detail.detail }} />
									))}
								</ul>

								<section className="surface-100 py-3 mt-3 border-round border-300 border-solid border-1">
									<div className="px-5">
										<p className="m-0">The best available option based on price, quality, and speed.</p>
									</div>

									<div className="my-3" style={{ height: '1px', backgroundColor: 'var(--surface-300)' }} />

									<div className="px-5">
										<p className="font-normal text-700 text-lg mb-1">Price</p>

										<p className="text-lg m-0">
											From <span className="text-xl"> {minMaxPriceHandler(product.minSalePrice?.formattedPricePerUnit, product.maxSalePrice?.formattedPricePerUnit)}</span>
										</p>

										{user?.accountType === 'Enterprise' || product.minDiscountedSalePrice.price.formattedPricePerUnit === 0 || product.maxDiscountedSalePrice.price.formattedPricePerUnit === 0 ? null : (
											<p className="text-primary">
												From {minMaxPriceHandler(product.minDiscountedSalePrice?.price?.formattedPricePerUnit, product.maxDiscountedSalePrice?.price.formattedPricePerUnit)} with Printram {product.minDiscountedSalePrice.planName}
											</p>
										)}
									</div>

									<div className="my-3" style={{ height: '1px', backgroundColor: 'var(--surface-300)' }} />

									<div className="px-5 flex">
										<div className="flex-1">
											<p className="mb-2 text-600">Avg. production time</p>

											<p className="text-lg m-0">2 business day</p>
										</div>

										{!!colors && colors.length > 0 ? (
											<div className="flex-1">
												<p className="mb-2 text-600">Colors • {allColors.length}</p>

												<div className="flex flex-wrap gap-1">
													{colors.map((_color) => (
														<React.Fragment key={_color.hexCode}>
															<Tooltip target={`#color-${_color.id}`}>
																<div className="text-center p-2">
																	<div className="font-semibold">{_color.name + ', ' + _color.hexCode}</div>
																	<div>Click to copy the color</div>
																</div>
															</Tooltip>

															<div
																id={`color-${_color.id}`}
																data-pr-position="top"
																onClick={() => copytoClipboard(_color.hexCode)}
																className="border-circle border-1 border-300 cursor-pointer"
																style={{
																	backgroundImage: `url('${_color.imageUrl}')`,
																	backgroundColor: _color.hexCode,
																	backgroundPosition: 'center',
																	backgroundSize: 'cover',
																	backgroundRepeat: 'no-repeat',
																	width: '1.4rem',
																	height: '1.4rem'
																}}></div>
														</React.Fragment>
													))}

													{allColors.length > colors.length ? (
														<React.Fragment>
															<span onClick={(e) => colorOverlayPanelRef.current?.toggle(e)} className="text-primary align-self-end cursor-pointer">
																(+ {allColors.length - colors.length})
															</span>

															<OverlayPanel ref={colorOverlayPanelRef}>
																<span className="block mb-2">All Colors</span>

																<div className="flex flex-wrap gap-1 max-w-15rem">
																	{allColors.map((_color) => (
																		<React.Fragment key={`all-${_color.hexCode}`}>
																			<Tooltip target={`#color-${_color.id}`}>
																				<div className="text-center p-2">
																					<div className="font-semibold">{_color.name + ', ' + _color.hexCode}</div>
																					<div>Click to copy the color</div>
																				</div>
																			</Tooltip>

																			<div id={`color-${_color.id}`} data-pr-position="top" onClick={() => copytoClipboard(_color.hexCode)} className="border-circle border-1 border-300 cursor-pointer" style={{ backgroundColor: _color.hexCode, width: '1.4rem', height: '1.4rem' }}></div>
																		</React.Fragment>
																	))}
																</div>
															</OverlayPanel>
														</React.Fragment>
													) : null}
												</div>
											</div>
										) : null}
									</div>
								</section>

								{!!user && user.roles.isSeller ? (
									<Link to={'/create-order/' + product.id} className="mt-3 block">
										<Button label="Create an Order" className="p-button-outlined w-full" />
									</Link>
								) : null}
							</section>
						</div>

						<div id="productDetail" className="grid mt-5">
							<div className="col-12 lg:col-4">
								<h1 className="m-0 ml-5">About</h1>
							</div>

							<div className="col-12 lg:col-8">
								<p className="text-lg">{product.description}</p>
							</div>
						</div>

						{!!product.attachments && product.attachments.length > 0 ? (
							<React.Fragment>
								<div className="my-3" style={{ height: '1px', backgroundColor: 'var(--surface-300)' }} />

								<div className="grid mt-4">
									<div className="col-12 lg:col-4">
										<h1 className="m-0 ml-5">Attachments</h1>
									</div>

									<div className="col-12 lg:col-8">
										<div className="flex flex-wrap gap-3 mb-3">
											{product.attachments.map((_attachment) => (
												<button
													disabled={downloadLoading}
													key={_attachment.id}
													onClick={async () => {
														try {
															setDownloadLoading(true);

															const response = await axios.get(mediaHelper.getNewMediaImage(_attachment.media).url, { responseType: 'blob' });
															const href = URL.createObjectURL(response.data);

															const anchorElement = document.createElement('a');

															anchorElement.href = href;
															anchorElement.download = `${_attachment.header}-${productId}`;

															document.body.appendChild(anchorElement);

															anchorElement.click();

															document.body.removeChild(anchorElement);
															URL.revokeObjectURL(href);

															setDownloadLoading(false);
														} catch (error) {
															setDownloadLoading(false);
														}
													}}
													className="pr-btn pr-btn-secondary">
													{downloadLoading ? <span className="pi pi-spin pi-spinner mr-2" /> : <span className="pi pi-fw pi-download mr-2" />}
													<span>{_attachment.header}</span>
												</button>
											))}
										</div>
									</div>
								</div>
							</React.Fragment>
						) : null}

						{product.model.brand.name?.toLowerCase().includes('gildan') || product.model.brand.name?.toLowerCase().includes('bella') ? (
							<React.Fragment>
								<div className="my-3" style={{ height: '1px', backgroundColor: 'var(--surface-300)' }} />

								<div className="grid mt-4">
									<div className="col-12 lg:col-4">
										<h1 className="m-0 ml-5">Care Instructions</h1>
									</div>

									<div className="col-12 lg:col-8">
										{product.model.brand.name.toLowerCase().includes('gildan') ? <GildanCareInstructions /> : null}
										{product.model.brand.name.toLowerCase().includes('bella') ? <BellaCanvasCareInstructions /> : null}
									</div>
								</div>
							</React.Fragment>
						) : null}

						{product.materials.length > 0 ? (
							<React.Fragment>
								<div className="my-3" style={{ height: '1px', backgroundColor: 'var(--surface-300)' }} />

								<div id="productDetail" className="grid mt-5">
									<div className="col-12 lg:col-4">
										<h1 className="m-0 ml-5">Materials</h1>
									</div>

									<div className="col-12 lg:col-8">
										<p className="text-lg">{product.materials.map((_material) => _material.name).join('; ')}</p>
									</div>
								</div>
							</React.Fragment>
						) : null}

						{/*
						{product.sides.length > 0 ? (
							<React.Fragment>
								<div className="my-3" style={{ height: '1px', backgroundColor: 'var(--surface-300)' }} />

								<div id="productDetail" className="grid mt-5">
									<div className="col-12 lg:col-4">
										<h1 className="m-0 ml-5">Print Areas</h1>
									</div>

									<div className="col-12 lg:col-8">
										<SideList sides={product.sides} />
									</div>
								</div>
							</React.Fragment>
						) : null}
						*/}

						<div className="grid">
							<VariantsAccordionV2 product={product} />
						</div>
					</div>
				</React.Fragment>
			) : null}

			<PrLoading loading={loading} text={loadingText} />
		</div>
	);
};

export default ProductDetailNew;
