import React, { useEffect, useState } from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextType } from '../context/UpgradeShippingContextProvider';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { CountryBaseDto } from '../../../../features/countries/get-countries/get-countries.response';
import { prefixOptions } from '../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import { ValidateAddressDTO, ValidateAddressResponseDataDTO } from '../../../../features/orders/derived-features/order-transports/validate-address/validate-address-response';
import PrDropdown, { PrDropdownType } from '../../../../helpers/widgets/Printram/Dropdown/PrDropdown';
import countryService from '../../../../features/countries/country.service';
import { GetCountriesRequest } from '../../../../features/countries/get-countries/get-countries.request';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import PrTextInput from '../../../../helpers/widgets/Printram/Forms/Input/PrTextInput';
import stringHelper from '../../../../helpers/string.helper';
import PrTextareaInput from '../../../../helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import AddressAutofill from '../../../../helpers/widgets/Order/Address/AddressAutofill';
import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import FormatAddressDialog from '../../orders/create-order/steps/shipping/components/ship-form/dialogs/FormatAddressDialog';
import { getPrintramStores } from '../../../../redux/features/printram-store/printramStoreSlice';
import { ValidateAddressRequest, ValidationAddressDto } from '../../../../features/orders/derived-features/order-transports/validate-address/validate-address-request';
import { orderTransportService } from '../../../../features/orders/derived-features/order-transports/order-transport.service';
import camelCaseHelper from '../../../../helpers/camel-case.helper';
import { UpdateTransportDeliveryAddress } from '../../../../features/orders/derived-features/order-transports/update-transport/update-transport.request';
import FullScreenLoader from '../components/full-screen-loader/FullScreenLoader';

export const UpdateAddress = () => {
	const stores = useAppSelector((_state) => _state.printramStore.data || []);
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.auth.data?.user);
	const context = React.useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;

	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [formatAddressVisible, setFormatAddressVisible] = useState<boolean>(false);
	const [validateAddressResponse, setValidateAddressResponse] = useState<ValidateAddressResponseDataDTO | undefined>(undefined);
	const [stateOptions, setStateOptions] = useState<PrDropdownType[] | undefined>();
	const [selectedCountryIso, setSelectedCountryIso] = useState<string | undefined>();

	useEffect(() => {
		getCountries();

		dispatch(getPrintramStores(user!.userId));
	}, []);

	const getCountries = async () => {
		try {
			const response = await countryService.getCountries(new GetCountriesRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCountries(response.data);
		} catch (error) {
			setCountries([]);
		}
	};

	const validateAddress = async (values: FormikValues) => {
		try {
			context.setLoading(true);
			const validationAddress: ValidationAddressDto = structuredClone(values);

			let deliveryAddress: UpdateTransportDeliveryAddress = structuredClone(values);
			deliveryAddress.receiverPhoneNumber = deliveryAddress.receiverPhoneNumber ? values.phonePrefix.value + deliveryAddress.receiverPhoneNumber : null;
			context.setDeliveryAddress(deliveryAddress);

			const request: ValidateAddressRequest = new ValidateAddressRequest({
				shipTo: validationAddress
			});
			const validateResponse = await orderTransportService.validateAddress(request);

			if (!validateResponse.isSuccess) throw '';

			const data = camelCaseHelper.toCamelCase(validateResponse.data);

			if (!data.isValidationExist) {
				onSubmitForm(data.response.originalAddress!, deliveryAddress);
				return;
			}

			setValidateAddressResponse(data.response!);
			setFormatAddressVisible(true);
			return;
		} catch (error) {
		} finally {
			context.setLoading(false);
		}
	};

	const onSubmitForm = (address: ValidateAddressDTO, deliveryAddress: UpdateTransportDeliveryAddress) => {
		try {
			const _deliveryAddress = new UpdateTransportDeliveryAddress({
				receiverPhoneNumber: deliveryAddress.receiverPhoneNumber,
				receiverFullName: deliveryAddress.receiverFullName,
				receiverEmail: deliveryAddress.receiverEmail,
				firstLine: address.addressLine1,
				secondLine: deliveryAddress.secondLine,
				city: address.cityLocality,
				state: address.stateProvince,
				zip: address.postalCode,
				countryIso: address.countryCode,
				formattedAddress: address.completeAddress ? address.completeAddress : deliveryAddress.formattedAddress,
				vatNumber: deliveryAddress.vatNumber
			});

			context.setDeliveryAddress(_deliveryAddress);
			context.nextStep();
			return;
		} catch (e) {}
	};

	const initialValues = {
		firstLine: '',
		secondLine: '',
		city: '',
		state: '',
		zip: '',
		countryIso: '',
		receiverFullName: '',
		receiverEmail: '',
		formattedAddress: '',
		vatNumber: '',
		receiverPhoneNumber: '',
		phonePrefix: { label: '+1', value: '+1' },
		attachmentFiles: [],
		customerNote: '',
		storeId: ''
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={
					context.deliveryAddress
						? {
								...context.deliveryAddress,
								formattedAddress: context.deliveryAddress.formattedAddress,
								receiverFullName: context.deliveryAddress.receiverFullName,
								receiverEmail: context.deliveryAddress.receiverEmail,
								storeId: context.storeId,
								phonePrefix: { label: '+1', value: '1' },
								countryIso: context.deliveryAddress.countryIso.substring(0, 2),
								customerNote: ''
							}
						: initialValues
				}
				/*enableReinitialize*/
				validationSchema={Yup.object().shape({
					storeId: Yup.string().required('Store is Required'),
					receiverFullName: Yup.string()
						.required('Full Name is required')
						.test('is-full-name', 'Enter your Full Name', (value) => {
							if (!value) return false;

							return value.trim().split(' ').length > 1;
						}),
					receiverEmail: Yup.string().email('Email address must be valid.').required('Email is required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
					formattedAddress: Yup.string(),
					countryIso: Yup.string().required('Select a country'),
					state: Yup.string().required('State is required'),
					firstLine: Yup.string().required('Address Line 1 is required'),
					city: Yup.string().required('City is required'),
					zip: Yup.string().required('Zip Code is required')
				})}
				onSubmit={(values) => validateAddress(values)}>
				{(form) => (
					<Form>
						<div className="container-body p-0 flex mx-auto" style={{ width: '100%' }}>
							<div className="flex-1 p-5 tutorial-selector1">
								<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
									Recipient
								</h2>

								<div>
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										Printram Store *
									</label>
									<PrDropdown
										options={stores.map((_store) => ({
											label: _store.name,
											value: _store.storeId
										}))}
										value={stores
											.map((_store) => ({
												label: _store.name,
												value: _store.storeId
											}))
											.find((_store) => _store.value === form.values.storeId)}
										placeholder="Select store"
										className="w-full"
										onChange={(event) => {
											form.setFieldValue('storeId', event.value);
										}}
									/>
									<ErrorMessage name="storeId" component="small" className="text-red font-medium" />
								</div>

								<div className="mt-4">
									<PrTextInput
										type="text"
										label="Full Name *"
										style={{ height: '2.375rem' }}
										value={form.values.receiverFullName}
										onChange={(e) => {
											const formattedName = stringHelper.formatName(e.target.value);

											form.setFieldValue('receiverFullName', formattedName);
											form.setFieldValue('receiverFullName', formattedName);
										}}
									/>

									<ErrorMessage name="receiverFullName" component="small" className="text-red font-medium" />
								</div>

								<div className="mt-4">
									<PrTextInput
										type="email"
										label="Email *"
										value={form.values.receiverEmail}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('receiverEmail', e.target.value || '');
										}}
									/>

									<ErrorMessage name="receiverEmail" component="small" className="text-red font-medium" />
								</div>

								<React.Fragment>
									<label className="mt-4 block" style={{ fontSize: '19px', fontWeight: '600' }}>
										Phone <span className="pr-input-label-optional">(Optional)</span>
									</label>

									<div className="flex gap-2 align-items-end mt-1">
										<div>
											<PrDropdown
												options={prefixOptions}
												value={form.values.phonePrefix}
												className="w-7rem"
												onChange={(e) => {
													form.setFieldValue('phonePrefix', e);
												}}
											/>
										</div>

										<PrTextInput
											name="phone"
											type="tel"
											value={form.values.receiverPhoneNumber || ''}
											className="w-full"
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												if (e.target.value.length > 10) return;

												if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

												form.setFieldValue('receiverPhoneNumber', e.target.value);
											}}
										/>
									</div>
								</React.Fragment>

								<div className="mt-4">
									<PrTextareaInput
										label="Customer Note"
										value={form.values.customerNote || ''}
										rows={2}
										required={false}
										onChange={(e) => {
											form.setFieldValue('customerNote', e.target.value || null);
										}}
									/>
								</div>
							</div>

							<div className="flex-1 border-left-1 border-400 p-5 tutorial-selector3">
								<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
									Shipping address
								</h2>

								{countries.length > 0 ? (
									<AddressAutofill
										defaultAddress={form.values.formattedAddress}
										onAutofillConfirm={(e) => {
											if (!e) return;
											form.setFieldValue('formattedAddress', e.formatted_address || '');
											form.setFieldValue('state', e.state_province || '');
											form.setFieldValue('city', e.city_locality || '');
											form.setFieldValue('zip', e.postal_code || '');
											form.setFieldValue('firstLine', e.address_line_1 || '');
											form.setFieldValue('secondLine', e.address_line_2 || '');

											for (const country of countries) {
												if (country.iso3 !== e.country_code && country.iso2 !== e.country_code && country.name.toUpperCase() !== e.country_code) continue;
												form.setFieldValue('countryIso', country.iso2);
												setSelectedCountryIso(country.iso2);
												break;
											}
										}}
									/>
								) : null}

								<div className="mt-4 flex gap-4">
									<div className="w-full">
										<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
											Country *
										</label>

										<div>
											<PrDropdown
												value={countries
													.map((_country) => ({
														label: _country.name,
														value: _country.iso2
													}))
													.find((_country) => _country.value === form.values.countryIso)}
												options={countries.map((_country) => ({
													label: _country.name,
													value: _country.iso2
												}))}
												className="w-full"
												onChange={(e) => {
													form.setFieldValue('countryIso', e.value);
													setSelectedCountryIso(e.value.toString());
												}}
											/>

											<ErrorMessage name="countryIso" component="small" className="text-red font-medium" />
										</div>
									</div>

									<div className="w-full">
										<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
											State *
										</label>

										{stateOptions ? (
											<PrDropdown
												value={stateOptions.find((_states) => _states.value === form.values.state)}
												options={stateOptions}
												className="w-full"
												onChange={(e) => {
													form.setFieldValue('state', e.value);
												}}
											/>
										) : (
											<PrTextInput
												value={form.values.state || ''}
												className="w-full"
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													form.setFieldValue('state', e.target.value || '');
												}}
											/>
										)}

										<ErrorMessage name="state" component="small" className="text-red font-medium" />
									</div>
								</div>

								<div className="mt-4">
									<PrTextInput
										label="Address Line 1 *"
										value={form.values.firstLine || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('firstLine', e.target.value || '');
										}}
									/>

									<ErrorMessage name="firstLine" component="small" className="text-red font-medium" />
								</div>

								<div className="mt-4">
									<PrTextInput
										label="Address Line 2"
										required={false}
										value={form.values.secondLine || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('secondLine', e.target.value || null);
										}}
									/>
								</div>

								<div className="mt-4 flex gap-4">
									<div className="w-full">
										<PrTextInput
											label="City *"
											value={form.values.city || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('city', e.target.value || '');
											}}
										/>

										<ErrorMessage name="city" component="small" className="text-red font-medium" />
									</div>

									<div className="w-full">
										<PrTextInput
											label="Zip Code *"
											value={form.values.zip || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('zip', e.target.value || '');
											}}
										/>

										<ErrorMessage name="zip" component="small" className="text-red font-medium" />
									</div>
								</div>

								<div className="mt-4">
									<PrTextInput
										label="VAT number"
										required={false}
										value={form.values.vatNumber || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('vatNumber', e.target.value || '');
										}}
									/>
								</div>
							</div>
						</div>
						<div className="container-body p-3 h-6rem flex align-items-center justify-content-center">
							<PrButton text="Calculate Shipping" btnType="submit" />
						</div>
					</Form>
				)}
			</Formik>
			<FormatAddressDialog
				visible={formatAddressVisible}
				validateAddressResponse={validateAddressResponse}
				onSubmit={(address) => onSubmitForm(address, context.deliveryAddress!)}
				onHide={() => {
					context.setLoading(false);
					setFormatAddressVisible(false);
				}}
			/>
			{context.loading && <FullScreenLoader />}
		</React.Fragment>
	);
};

export default UpdateAddress;
