export class GetEtsyInformationsForPushRequest {
	userId: string;
	copiedProductId: string;

	constructor(props: Partial<GetEtsyInformationsForPushRequest>) {
		this.userId = props.userId || '';
		this.copiedProductId = props.copiedProductId || '';
	}

	get getConvertUriExtension() {
		return `get-etsy-informations-for-push?UserId=${this.userId}&CopiedProductId=${this.copiedProductId}`;
	}
}
