import {
    CreateManuelOrderDeliveryAddressRequestDto
} from "../../../_common/create-manuel-order/create-manuel-order.request";

export class ValidationAddressDto {
    receiverFullName: string;
    receiverPhoneNumber: string;
    receiverEmail: string;
    firstLine: string;
    secondLine: string;
    city: string;
    state: string;
    zip: string;
    countryIso: string;
    formattedAddress: string;
    vatNumber: string;

    constructor(props: Partial<ValidationAddressDto>) {
        this.receiverFullName = props.receiverFullName || "";
        this.receiverPhoneNumber = props.receiverPhoneNumber || "";
        this.receiverEmail = props.receiverEmail || "";
        this.firstLine = props.firstLine || "";
        this.secondLine = props.secondLine || "";
        this.city = props.city || "";
        this.state = props.state || "";
        this.zip = props.zip || "";
        this.countryIso = props.countryIso || "";
        this.formattedAddress = props.formattedAddress || "";
        this.vatNumber = props.vatNumber || "";
    }
}

export class ValidateAddressRequest {
    shipTo!: ValidationAddressDto;

    constructor(props: Partial<ValidateAddressRequest>) {
        this.shipTo = props.shipTo!;
    }

    get getConvertUriExtension() {
        return `validate-address`;
    }
}