import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useState } from 'react'
import { PrintOption } from '../../../models/dtos/auth/users/print-option'
import { User } from '../../../models/dtos/auth/users/user'
import { PrintOptionType } from '../../../models/enums/print-option-type.enum'
import { UserPrintOptionCreateOrUpdateRequest } from '../../../models/requests/users/userPrintOptions/user-print-option-create-or-update-request'
import { createOrUpdateUserPrintOption, getUserPrintOptionByUserId } from '../../../redux/features/user-print-option/userPrintOptionSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import stringHelper from '../../string.helper'
import PrButton from '../Printram/Forms/Buttons/PrButton'

type Prop = {
	type: 'print' | 'save',
	userId?: string
}

const PrintOptionsWidget = ({ type, userId }: Prop) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(state => state.auth.data?.user as User);
	const userPrintOption = useAppSelector(state => state.userPrintOption as PrintOption | null);

	const [loading, setLoading] = useState<boolean>(false);

	const initialValues: UserPrintOptionCreateOrUpdateRequest = {
		userId: userId || user.userId || "",
		optionAndValues: !!userPrintOption ? userPrintOption.userPrintOptions : [],
		saveSettingsForNextTime: false
	};

	const saveNewSettings = async (request: UserPrintOptionCreateOrUpdateRequest) => {
		await dispatch(createOrUpdateUserPrintOption(request))
		return await getPrintOption(true);
	}

	const submitForm = async (values: UserPrintOptionCreateOrUpdateRequest) => {
		if (type === 'save' || (type === 'print' && values.saveSettingsForNextTime)) {
			setLoading(true);
			await saveNewSettings(values);
			setLoading(false);
			return;
		}
	}

	const getPrintOption = async (refresh: boolean = false) => {
		if (!user.userId) return;

		if (userPrintOption && !refresh) return;

		setLoading(true)
		return await dispatch(getUserPrintOptionByUserId(userId || user.userId)).finally(() => setLoading(false));
	}

	useEffect(() => {
		getPrintOption();
	}, [])

	return (
		<React.Fragment>
			{!!userPrintOption ? (
				<Formik initialValues={initialValues} onSubmit={submitForm}>
					{({ values, setFieldValue }) => (
						<Form>
							{values.optionAndValues.map((optionAndValue, index) => (
								<React.Fragment key={index}>
									{optionAndValue.option === PrintOptionType.OrderReceiptBanner ? (
										<React.Fragment>
											<h6>Shop Info</h6>

											<div className="field-checkbox select-none">
												<Checkbox inputId={`optionAndValues[${index}].option`} name={`optionAndValues[${index}].isActive`} checked={optionAndValue.isActive} onChange={(event) => setFieldValue(`optionAndValues[${index}].isActive`, event.checked)} />
												<label htmlFor={`optionAndValues[${index}].option`} className="cursor-pointer">
													{stringHelper.parseAtUpperCaseAndJoin(PrintOptionType[optionAndValue.option])}
												</label>
											</div>
										</React.Fragment>
									) : null}
								</React.Fragment>
							))}

							<h6>Order Info</h6>
							<div className="grid">
								{values.optionAndValues.map((optionAndValue, index) => (
									<React.Fragment key={index}>
										{optionAndValue.option !== PrintOptionType.OrderReceiptBanner ? (
											<div className="lg:col-6">
												<div className="field-checkbox select-none">
													<Checkbox inputId={`optionAndValues[${index}].option`} name={`optionAndValues[${index}].isActive`} readOnly={optionAndValue.option === PrintOptionType.PrivateNotes} checked={optionAndValue.isActive} onChange={(event) => setFieldValue(`optionAndValues[${index}].isActive`, event.checked)} />
													<label htmlFor={`optionAndValues[${index}].option`} className="cursor-pointer">
														{stringHelper.parseAtUpperCaseAndJoin(PrintOptionType[optionAndValue.option])}
													</label>
												</div>
											</div>
										) : null}
									</React.Fragment>
								))}
							</div>

							{type === 'print' ? (
								<React.Fragment>
									<hr />

									<div className="field-checkbox select-none">
										<Checkbox inputId="saveSettingsForNextTime" name="saveSettingsForNextTime" checked={values.saveSettingsForNextTime} onChange={(event) => setFieldValue(`saveSettingsForNextTime`, event.checked)} />
										<label htmlFor="saveSettingsForNextTime" className="cursor-pointer">
											Save these settings for next time
										</label>
									</div>

									<div className="text-right">
										<Button type="submit" icon="pi pi-file-export" loading={loading} label={values.saveSettingsForNextTime ? 'Save & Print' : 'Print'} className="px-6" />
									</div>
								</React.Fragment>
							) : (
								<div className="flex justify-content-end">
									<PrButton btnType="submit" icon={<span className="pi pi-save" />} loading={loading} text="Save" className="px-6" />
								</div>
							)}
						</Form>
					)}
				</Formik>
			) : null}

			{loading ? (
				<div className="pr-loading-sticky" style={{ minHeight: '10rem' }}>
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
}

export default PrintOptionsWidget