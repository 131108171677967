import React, { useEffect, useRef, useState } from 'react'
import styles from './Locations.module.scss'
import { AddLocation } from './components/AddLocation';
import { TbBuilding, TbDots, TbEdit, TbSettings, TbTrash, TbSparkles, TbGitFork, TbCube } from 'react-icons/tb';
import DropdownButton from 'helpers/widgets/DropdownButton';
import { StockLocationDto } from 'features/stockmanagement/dtos/stock-location.dto';
import { GetAllStockLocationRequest } from 'features/stockmanagement/_common/get-all-stock-location/get-all-stock-location.request';
import stockmanagementService from 'features/stockmanagement/_common/stockmanagement.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SetDefaultLocationRequest } from 'features/stockmanagement/_common/set-default-location/set-default-location.request';
import { EditLocation } from './components/EditLocation';
import { DeleteStockLocationRequest } from 'features/stockmanagement/_common/delete-stock-location/delete-stock-location.request';



const Locations = () => {
	const [locs, setLocs] = useState<StockLocationDto[]>([])
	const [addLocVisible, setAddLocVisible] = useState<boolean>(false)
	const [editedLoc, setEditedLoc] = useState<string | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)

	const getLocations = async () => {
		try {
			setLoading(true)

			const request = new GetAllStockLocationRequest({ pagination: { pageNumber: 1, itemCount: 10, orderBy: 1, first: 0 } });

			const response = await stockmanagementService.getAllStockLocation(request)

			if (!response.isSuccess) throw ''

			setLocs(response.data || [])
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	const setLocationDefault = async (locId: string) => {
		if (!locId) return;

		try {
			setLoading(true)

			const request = new SetDefaultLocationRequest({ stockLocationId: locId })

			const response = await stockmanagementService.setDefaultLocation(request)

			if (!response.isSuccess) throw ''

			getLocations()
		} catch (error) {} finally {}
	}

	const deleteLocation = async (locId: string) => {
		if (!locId) return;

		try {
			const request = new DeleteStockLocationRequest(locId)

			const response = await stockmanagementService.deleteStockLocation(request)

			if (!response) throw ''

			getLocations()
		} catch (error) {

		} finally {}
	}

	const actionButtons = (locId: string) => {
		const menuItems = [
			<div onClick={() => setEditedLoc(locId)} className={styles.overlayBtns}>
				<TbEdit />
				Edit location details
			</div>,
			<div onClick={() => setLocationDefault(locId)} className={styles.overlayBtns}>
				<TbSparkles />
				Set location as default
			</div>,
			<div onClick={() => deleteLocation(locId)} className={`${styles.overlayBtns} text-red`}>
				<TbTrash />
				Delete location
			</div>
		];

		return <DropdownButton icon={<TbDots />} direction="left" menuItems={menuItems} className={styles.locCardNameActionsIcon} />;
	};

	useEffect(() => {
		getLocations()
	},[])

	return (
		<div>
			{!addLocVisible && !editedLoc ? (
				<main className="container relative">
					<section className="container-body p-3 z-1">
						<div className={styles.locTitle}>
							<div>
								<h6>Locations</h6>
								<span>Manage the places you stock inventory</span>
							</div>
							<div>
								<span className={styles.addBtn} onClick={() => setAddLocVisible(true)}>
									Add location
								</span>
							</div>
						</div>
					</section>

					{locs && locs.length > 0
						? locs.map((_loc, index) => (
								<section key={index} className={styles.locMainBody}>
									<div className={styles.locCardName}>
										<div className={styles.locCardNameIcon}>
											<TbBuilding size={24} color="#6B7280" style={{ backgroundColor: '#E5E7EB', padding: 4, borderRadius: 5 }} />
										</div>
										<div className={styles.locCardNameTitle}>
											<span>{_loc.name}</span>
											<div>-</div>
										</div>
										<div className={styles.locCardNameActions}>
											{_loc.setDefault && <span>Default</span>}

											{actionButtons(_loc.id)}
										</div>
									</div>
									{/* <div className={styles.locCardSales}>
										<div className={styles.locCardSalesTitle}>
											<span>Connected sales channels</span>
											<div className={styles.locCardSalesTitleIcons}>
												<div className={styles.locCardSalesIcon}>
													<TbGitFork size={24} color="#6B7280" style={{ backgroundColor: '#E5E7EB', padding: 4, borderRadius: 5 }} />
												</div>
												<div>
													<span>{_loc.connectedSales[0]} + {_loc.connectedSales.length} more</span>
												</div>
											</div>
										</div>
										<div className={styles.locCardSalesActions}>
											<span>Edit channels</span>
										</div>
									</div>
									<div className={styles.locCardItems}>
										<div className={styles.locCardItemsTitle}>
											<span>Items at this location</span>
											<div className={styles.locCardItemsTitleIcons}>
												<div className={styles.locCardItemsIcon}>
													<TbCube size={24} color="#6B7280" style={{ backgroundColor: '#E5E7EB', padding: 4, borderRadius: 5 }} />
												</div>
												<div>
													<span>{_loc.items[0]},{_loc.items[1]} + {_loc.items.length} more</span>
												</div>
											</div>
										</div>
										<div className={styles.locCardItemsActions}>
											<span>View Inventory</span>
										</div>
									</div> */}
								</section>
						  ))
						: null}

					{loading ? (
						<div className="pr-loading-sticky">
							<div className="pr-spinner-wrapper">
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						</div>
					) : null}
				</main>
			) : null}

			{addLocVisible && <AddLocation setVisible={setAddLocVisible} getLocations={getLocations} />}
			{editedLoc && <EditLocation setEditedLoc={setEditedLoc} getLocations={getLocations} locationId={editedLoc} />}
		</div>
	);
}

export default Locations