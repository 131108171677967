import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto'
import { GetInTouchFormDto } from 'features/forms/_common/get-in-touch-forms/dtos/get-in-touch-form.dto'
import { GetInTouchFormsRequest } from 'features/forms/_common/get-in-touch-forms/get-in-touch-forms.request'
import formsService from 'features/forms/forms.service'
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton'
import React, { useEffect, useState } from 'react'
import styles from './GetInTouchFormList.module.scss'
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput'
import { Pagination } from 'models/_commons/responses/pagination'
import { Tooltip } from 'primereact/tooltip'
import dateHelper from 'helpers/dateHelper'
import { Paginator } from 'primereact/paginator'
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface'
import { ProgressSpinner } from 'primereact/progressspinner'

const GetInTouchFormList = () => {
	const [forms, setForms] = useState<GetInTouchFormDto[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<PaginationDto>({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [search, setSearch] = useState<string>('');

	const getForms = async (paginationReq: PaginationDto) => {
		try {
			if (!paginationReq) throw '';

			setLoading(true);

			const request = new GetInTouchFormsRequest(paginationReq, search);

			const response = await formsService.getGetInTouchFormRequests(request);

			if (!response.isSuccess) throw '';

			setForms(response.data || []);
			setPaginationResponse(response.pagination);
		} catch (error) {
			setForms([]);
		} finally {
			setLoading(false);
		}
	};

	const setPaginationOrderBy = () => setPagination({ pageNumber: 1, itemCount: 20, orderBy: pagination.orderBy === 2 ? 1 : 2, first: 0 });

	const clearPagination = () => setPagination({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });

	const getClickableLink = (link: string) => {
		return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
	};

	useEffect(() => {
		if (!pagination) return;

		getForms(pagination);
	}, [pagination]);

  return (
		<main className="container relative">
			<section className="container-header">
				<h1 className="container-header-title">Get In Touch Forms</h1>

				<div className="container-header-tools">
					<PrButton type="secondary" text="Refresh Forms" disabled={loading} icon={<span className="pi pi-fw pi-refresh" />} onClick={() => getForms(pagination)} />
				</div>
			</section>

			<section className="container-body p-3">
				<div className="mb-3">
					You can search among forms by <span className="font-bold">company website, name, email</span> or <span className="font-bold">phone number.</span>
				</div>
				<SearchInput onChange={(e) => setSearch(e.target.value)} value={search} onKeyDown={(event) => event.key === 'Enter' && clearPagination()} filterBtn disabled={loading} placeholder="Search forms" filterBtnOnClick={() => clearPagination()} />
			</section>

			<section className="container-body p-0">
				<div className={styles.formsTableWrapper}>
					<div className={styles.formsTableHeader}>
						<div className={styles.formsTableHeaderTitles}>
							<span className={styles.formsTableHeaderTitlesWebsite}>Website</span>
							<span className={styles.formsTableHeaderTitlesEmailPhone}>Contact Info</span>
							<span className={styles.formsTableHeaderTitlesComments}>Additional Comments</span>
							<span className={styles.formsTableHeaderTitlesDate} onClick={() => setPaginationOrderBy()}>
								Created Date
								<i className="pi pi-fw pi-sort ml-1" />
							</span>
						</div>
					</div>
				</div>

				<div className={styles.formsTableBody}>
					{forms.length > 0 ? (
						forms.map((_form) => (
							<div key={_form.id} className={styles.formsTableBodyItemWrapper}>
								<div className="px-4">
									<div className={styles.formsTableBodyWrapper}>
										<div className={styles.formsTableBodyItems}>
											<div className={styles.formsTableBodyItemWebsite}>
												<div>
													<span>{_form.companyName}</span>
													<a href={getClickableLink(_form.companyWebSite)} target="_blank">
														{_form.companyWebSite}
													</a>
												</div>
											</div>

											<div className={styles.formsTableBodyItemEmailPhone}>
												<div>
													<span>{_form.fullName}</span>
													<span>{_form.email}</span>
													<span>+{_form.phoneNumber}</span>
												</div>
											</div>
											<div className={styles.formsTableBodyItemComments}>
												<Tooltip target={`#d${_form.id}`} />
												<h6 id={`d${_form.id}`} data-pr-tooltip={_form.additionalComments} data-pr-position="top">
													{_form.additionalComments}
												</h6>
											</div>

											<div className={styles.formsTableBodyItemDate}>
												<span>Created at &#x2022; {dateHelper.formatDate(_form.createdDate)}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div className={`${styles.formsTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any form yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && forms.length > 0 ? (
						<div className={`${styles.formsTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={pagination.first}
								rows={pagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30]}
								onPageChange={(event) => {
									setPagination({
										first: event.first,
										itemCount: event.rows,
										pageNumber: event.page + 1,
										orderBy: PaginationOrderByEnum.Descending
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
  );
}

export default GetInTouchFormList