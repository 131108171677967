export class GetOrderDtfFilesRequest {
	customDtfOrderId: string;

	constructor(customDtfOrderId: string) {
		this.customDtfOrderId = customDtfOrderId;
	}

	get getConvertUriExtension() {
		return `get-order-dtf-files`;
	}
}