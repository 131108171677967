import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';

type Props = {
    isVisible: boolean;
    setVisible: Function;
    deleteUser: Function;
    reason: string;
    setReason: Function;
};

const DeleteConfirm = ({ isVisible, setVisible, deleteUser, reason, setReason }: Props) => {
    return (
        <Dialog visible={isVisible} header="Delete Account" modal onHide={() => setVisible(false)}>
            <label>Please explain your reason for deleting the account.</label>

            <InputText name="reason" value={reason} onChange={(event) => setReason(event.target.value)} placeholder="Please enter" className="w-full my-2" />

            <div className="flex justify-content-end my-2">
                <PrButton text="Delete" type='secondary' onClick={() => deleteUser()} className="px-8" />
            </div>
        </Dialog>
    );
};

export default DeleteConfirm;
