import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { CreateCategoryTransportInformationCommand, CreateCategoryTransportInformationsRequest } from '../../../../../models/requests/transportInformations/create-category-transformations-request'

type props = { transportInformations: CreateCategoryTransportInformationsRequest | undefined, setTransfromInformations: Function ,categoryName: string }
const InformationsTable = ({ transportInformations, setTransfromInformations, categoryName }: props) => {

	const sortFunc = (a: CreateCategoryTransportInformationCommand, b: CreateCategoryTransportInformationCommand) => {
		if (!a.sizeName || !b.sizeName) return 0;

		return a.sizeName > b.sizeName ? -1 : b.sizeName > a.sizeName ? 1 : 0;
	};

	const deleteInfo = (rowData: CreateCategoryTransportInformationCommand) => {
		setTransfromInformations({ categoryId: transportInformations?.categoryId, createCategoryTransportInformationCommands: transportInformations?.createCategoryTransportInformationCommands.filter(info => info.sizeId !== rowData.sizeId)});
	}

	const sizeBodyTemplate = (rowData: CreateCategoryTransportInformationCommand) => rowData.sizeName

	const countriesBodyTemplate = (rowData: CreateCategoryTransportInformationCommand) => rowData?.countries?.map(country => country).toString().slice(0,24) + `... (${rowData.countries?.length} countries)`;

    const formatCurrency = (value: any) => value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

	const firstItemPriceBodyTemplate = (rowData: CreateCategoryTransportInformationCommand) => formatCurrency(rowData.firstItemPrice / 100);

	const additionalItemPriceBodyTemplate = (rowData: CreateCategoryTransportInformationCommand) => formatCurrency(rowData.additionalItemPrice / 100);

	const businessDayBodyTemplate = (rowData: CreateCategoryTransportInformationCommand) => rowData.minBusinessDay + ' - ' + rowData.maxBusinessDay + ' days'

	const header = () => (
		<div className="w-full text-center">
			<span>{categoryName + ' Transport Informations'}</span>
		</div>
	);

	const actionsTemplate = (rowData: CreateCategoryTransportInformationCommand) => (
		<div>
			<Button onClick={() => deleteInfo(rowData)} icon="pi pi-fw pi-trash" className="p-button-danger" />
		</div>
	);

	return (
		<div className="my-4">
			<DataTable value={transportInformations?.createCategoryTransportInformationCommands.sort(sortFunc) as any} header={header} dataKey="sizeName" className="p-datatable-gridlines">
				<Column body={sizeBodyTemplate} header="Size" />
				<Column body={countriesBodyTemplate} header="Countries" />
				<Column body={firstItemPriceBodyTemplate} header="First Item" />
				<Column body={additionalItemPriceBodyTemplate} header="Additional Items" />
				<Column body={businessDayBodyTemplate} header="Business Day" />
				<Column body={actionsTemplate} />
			</DataTable>
		</div>
	);
};

export default InformationsTable