const environment = {
	mode: process.env.REACT_APP_MODE as 'production' | 'development' | 'test',
	baseApiUrl: process.env.REACT_APP_BASE_API_URL,
	prefixPath: process.env.REACT_APP_PREFIX_PATH,
	apiVersion: process.env.REACT_APP_API_VERSION,
	apiUrl: process.env.REACT_APP_API_URL,
	storeUrl: process.env.REACT_APP_STORE_URL,
	projectName: process.env.REACT_APP_PROJECT_NAME,
	googleAppId: process.env.REACT_APP_GOOGLE_APP_ID,
	printcartToken: process.env.REACT_APP_PRINTCART_TOKEN,
	stripeKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
	stripePremiumMonthlyPriceId: process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID,
	stripePremiumYearlyPriceId: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
	metaContent: process.env.REACT_APP_META_CONTENT,
	googleMapKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
	s3AccessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
	s3SecretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
} as const;

export default environment;
