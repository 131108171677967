export class GetProductsRequest {
	categoryId: string | undefined;
	brandId: string | undefined;
	modelId: string | undefined;

	constructor(props: Partial<GetProductsRequest>) {
		this.categoryId = props.categoryId;
		this.brandId = props.brandId;
		this.modelId = props.modelId;
	}

	get getConvertUriExtension() {
		return `get-products`;
	}
}
