import React, { useState } from 'react';
import styles from './MyCards.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import NewCardForm from './NewCardForm';
import stripeService from 'services/stripe-service';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { RiVisaLine } from 'react-icons/ri';
import { FaCcAmex } from 'react-icons/fa';

const MyCards = ({ stripePromise, stripeOptions, setupIntent, paymentMethods, getAll, createSetupIntent, isDeletable, selectedCard, setSelectedCard }: any) => {
	const [newCardVisible, setNewCardVisible] = useState(false);

	const cardIcons = [
		{ name: 'visa', icon: <RiVisaLine size={'4rem'} className="text-primary" /> },
		{
			name: 'mastercard',
			icon: (
				<svg width="52px" height="32px" viewBox="0 0 52 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<title>MC-logo-52</title>
					<desc>Created with Sketch.</desc>
					<g id="Components---Sprint-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="assets-/-logo-/-mastercard-/-symbol">
							<polygon id="Fill-1" fill="#FF5F00" points="18.7752605 28.537934 32.6926792 28.537934 32.6926792 3.41596003 18.7752605 3.41596003"></polygon>
							<path d="M19.6590387,15.976947 C19.6590387,10.8803009 22.03472,6.34107274 25.7341024,3.41596003 C23.0283795,1.27638054 19.6148564,0 15.9044284,0 C7.12054904,0 0.000132546844,7.15323422 0.000132546844,15.976947 C0.000132546844,24.8006598 7.12054904,31.953894 15.9044284,31.953894 C19.6148564,31.953894 23.0283795,30.6775135 25.7341024,28.537934 C22.03472,25.6123775 19.6590387,21.0735931 19.6590387,15.976947" id="Fill-2" fill="#EB001B"></path>
							<path d="M50.9714634,25.8771954 L50.9714634,25.257201 L50.8101981,25.257201 L50.6250744,25.6836968 L50.4395088,25.257201 L50.2782434,25.257201 L50.2782434,25.8771954 L50.3917919,25.8771954 L50.3917919,25.4094258 L50.5658701,25.8128438 L50.6838368,25.8128438 L50.857915,25.4085382 L50.857915,25.8771954 L50.9714634,25.8771954 Z M49.9504109,25.8771954 L49.9504109,25.3628264 L50.157184,25.3628264 L50.157184,25.2580887 L49.6314148,25.2580887 L49.6314148,25.3628264 L49.8377461,25.3628264 L49.8377461,25.8771954 L49.9504109,25.8771954 Z M51.4680723,15.9768139 C51.4680723,24.8005266 44.347214,31.9537609 35.5637764,31.9537609 C31.8533484,31.9537609 28.4393835,30.6773803 25.7341024,28.5378008 C29.4334848,25.6126881 31.8091661,21.07346 31.8091661,15.9768139 C31.8091661,10.8806116 29.4334848,6.34138341 25.7341024,3.41582689 C28.4393835,1.2762474 31.8533484,-0.000133141225 35.5637764,-0.000133141225 C44.347214,-0.000133141225 51.4680723,7.15310107 51.4680723,15.9768139 L51.4680723,15.9768139 Z" id="Fill-4" fill="#F79E1B"></path>
						</g>
					</g>
				</svg>
			)
		},
		{ name: 'amex', icon: <FaCcAmex size={'4rem'} className="text-primary" /> }
	];

	const deleteCard = (paymentMethodId: string) => stripeService.deletePaymentMethod(paymentMethodId).then((response) => response && getAll());

	const cardSelect = (cardId: string) => {
		if (!setSelectedCard) return;

		setSelectedCard(cardId);
	};

	const isCardSelected = (cardId: string) => {
		if (!selectedCard || selectedCard !== cardId) return false;
		else return true;
	};

	return (
		<React.Fragment>
			{paymentMethods.length > 0 ? (
				<div>
					<div className={styles.cardsMain}>
						{paymentMethods.map((_card: any, index: number) => (
							<div key={index} className={styles.cardMain}>
								<div className={`${styles.card}`} /*${isCardSelected(_card.id) && styles.selectedCard}*/ /*onClick={() => cardSelect(_card.id)}*/>
									<div>
										<div className={styles.cardName}>{_card.billing_details?.name || '-'}</div>
										<div className={styles.cardBody}>
											<div className={styles.cardBodyIcon}>{cardIcons.find((icon) => icon.name === _card.card.brand)?.icon || <img src={'https://via.placeholder.com/64x48'} alt="" />}</div>
											<div className={styles.creditCard}>
												<div className={styles.creditCardNumber}>**** **** **** {_card.card?.last4}</div>
												<div className={styles.creditCardExpired}>
													card expires at {_card.card?.exp_month?.toString().padStart(2, '0')}/{_card.card?.exp_year?.toString().slice(2, 4)}
												</div>
											</div>
										</div>
									</div>
									{isDeletable && (
										<div className={styles.btns}>
											<PrButton text="Delete" type="icon" icon={<span className="pi pi-fw pi-trash" />} onClick={() => deleteCard(_card.id)} />
										</div>
									)}
								</div>
							</div>
						))}
						<div className={styles.addCardMain} onClick={() => setNewCardVisible(true)}>
							<div className={styles.addCard}>
								<div className={styles.addCardBodyNew}>
									<h5>+ Add New Card</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.noCardContainer}>
					<div className={styles.noCardMain}>
						<h3>New Card</h3>
						<span className={styles.addCard}>Add a credit/debit card, to allow production of orders</span>

						<PrButton text="Add New Card" icon={<span className="pi pi-fw pi-plus-circle" />} onClick={() => setNewCardVisible(true)} className="mt-4" />
					</div>
				</div>
			)}

			<Elements stripe={stripePromise} options={stripeOptions}>
				<NewCardForm setupIntent={setupIntent} isVisible={newCardVisible} setVisible={setNewCardVisible} getAll={getAll} createSetupIntent={createSetupIntent} />
			</Elements>
		</React.Fragment>
	);
};

export default MyCards;
