import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import SellerUpdate from './components/SellerUpdate';
import sellerService from 'features/sellers/_common/seller.service';
import { SoftDeleteSellerRequest } from 'features/sellers/_common/soft-delete/soft-delete.request';
import { UpdateStatuRequest } from 'features/sellers/_common/update-statu/update-statu.request';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { StatusOptions } from 'helpers/constants/user-status-options';
import DropdownButton from 'helpers/widgets/DropdownButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { Pagination } from 'models/_commons/responses/pagination';
import { setForAdminSellerListPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { SellerForListDto } from 'features/sellers/_common/dtos/seller-for-list.dto';
import { GetSellerListRequest } from 'features/sellers/_common/get-seller-list/get-seller-list.request';
import PlanUpgradeModal from './components/PlanUpgradeModal';
import dateHelper from 'helpers/dateHelper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SellerBalance from './components/SellerBalance';
import SellerFilterTab from 'helpers/widgets/Order/OrderFilterTab/SellerFilterTab';
import { SellerStatu } from 'features/sellers/_common/dtos/enums/seller-statu.enum';
import stringHelper from 'helpers/string.helper';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import { ProgressSpinner } from 'primereact/progressspinner';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';

const Sellers = () => {
	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminSellerListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [searchParams, _] = useSearchParams();
	const searchStatus = searchParams.get('status');

	const [filter, setFilter] = useState<string | undefined>(undefined);
	const [filterSellerStatuType, setFilterSellerStatuType] = useState<SellerStatu | null>(!!searchStatus ? Number(searchStatus) || null : null);
	const [loading, setLoading] = useState<boolean>(false);
	const [statuLoading, setStatuLoading] = useState<boolean>(false);
	const [sellers, setSellers] = useState<SellerForListDto[]>([]);
	const [visibleUpdateSeller, setVisibleUpdateSeller] = useState(false);
	const [selectedSeller, setSelectedSeller] = useState<SellerForListDto | undefined>(undefined);
	const [visiblePlan, setVisiblePlan] = useState<boolean>(false);
	const [editedSeller, setEditedSeller] = useState<SellerForListDto | undefined>(undefined);
	const [selectedSellers, setSelectedSellers] = useState<SellerForListDto[]>([]);
	const [balanceVisible, setBalanceVisible] = useState(false);
	const [receiverUserIdentities, setReceiverUserIdentities] = useState<Array<string> | null>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [lastRequest, setLastRequest] = useState<GetSellerListRequest>(new GetSellerListRequest({}));

	const getAll = async (pagination?: PaginationDto) => {
		try {
			setLoading(true);

			if (!!pagination) dispatch(setForAdminSellerListPaginationInfo(JSON.parse(JSON.stringify(pagination))));

			const request = new GetSellerListRequest({ pagination, contains: !!filter && filter.length >= 3 ? filter : null, statu: filterSellerStatuType });

			const response = await sellerService.getSellerList(request);

			if (!response.isSuccess) throw '';

			setSellers(response.data || []);
			setPaginationResponse(response.pagination);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const deleteSeller = async (id: string) => {
		try {
			const request = new SoftDeleteSellerRequest(id);

			const response = await sellerService.delete(request);

			if (!response) throw '';

			await getAll(fromStatePagination);
		} catch (error) {
		} finally {
		}
	};

	const updateStatu = async (req: UpdateStatuRequest) => {
		try {
			const request = new UpdateStatuRequest(req.sellerId, req.userStatuId);

			const response = await sellerService.updateStatu(request);

			if (!response) throw '';

			await getAll(fromStatePagination);
		} catch (error) {
		} finally {
		}
	};

	const handleEditBtnCliked = (seller: SellerForListDto) => {
		setEditedSeller(seller);
		setVisibleUpdateSeller(true);
	};

	const handleSellerStatuOnChange = async (event: DropdownChangeParams, sellerId: string) => {
		try {
			setStatuLoading(true);

			const request = new UpdateStatuRequest(sellerId, event.value);

			await updateStatu(request);
		} catch (error) {
		} finally {
			setStatuLoading(false);
		}
	};

	const statusTemplate = (seller: SellerForListDto) => <Dropdown options={StatusOptions} value={seller.statu} disabled={statuLoading} onChange={(event) => handleSellerStatuOnChange(event, seller.id)} className="w-full" />;

	const handleUpgradePlan = (seller: SellerForListDto) => {
		setSelectedSeller(seller)
		setVisiblePlan(true);
	};

	const handleBalance = (seller: SellerForListDto) => {
		setSelectedSeller(seller)
		setBalanceVisible(true)
	}

	const handlePrice = (sellerId: string) => {
		navigate('/seller-prices/' + sellerId);
	}

	const actionsTemplate = (seller: SellerForListDto) => {
		const editBtn = <button onClick={() => handleEditBtnCliked(seller)}>Edit</button>;
		const upgradePlanBtn = <button onClick={() => handleUpgradePlan(seller)}>Upgrade Plan</button>;
		const balanceBtn = <button onClick={() => handleBalance(seller)}>Balance</button>
		const priceBtn = <button onClick={() => handlePrice(seller.id)}>Prices</button>;
		const deleteBtn = <button onClick={() => deleteSeller(seller.id)}>Delete</button>;

		const menuItems = [editBtn, upgradePlanBtn, balanceBtn, priceBtn, deleteBtn];

		return <DropdownButton disabled={selectedSellers.length > 0} menuItems={menuItems} direction={'left'} icon="pi pi-cog" className="bg-blue-600" />;
	};

	useEffect(() => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount) newPagination = { ...fromStatePagination, pageNumber: 1, first: 0 };
		else {
			newPagination.first = 0;
			newPagination.itemCount = paginationResponse?.itemCountAtCurrentPage || 10;
			newPagination.pageNumber = 1;
		}

		const serializeData = JSON.stringify(newPagination);
		dispatch(setForAdminSellerListPaginationInfo(JSON.parse(serializeData)));
		getAll(newPagination);
	}, [filterSellerStatuType]);

	useEffect(() => {
		if (!selectedSellers) return;

		setReceiverUserIdentities(selectedSellers.map((seller) => seller.id));
	}, [selectedSellers]);

	const onPageChangeEvent = async (event: DataTablePFSEvent) => {
		let newPagination = new PaginationDto();

		if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
			newPagination = fromStatePagination;
		} else {
			newPagination.itemCount = event.rows;
			newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
			newPagination.first = event.first;

			const serializeData = JSON.stringify(newPagination);
			dispatch(setForAdminSellerListPaginationInfo(JSON.parse(serializeData)));
		}

		await getAll(newPagination);
	};

	const handleFilter = async () => {
		if (typeof filter === 'string' && filter.length === 0) {
			onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
			return;
		}

		if (!filter || filter.length < 3) return;

		onPageChangeEvent({ first: 0, page: 0, rows: fromStatePagination.itemCount || 10 } as any);
	};


	const setReadableName = (value: number) => {
		if (!value) return '';

		let newValue = stringHelper.parseAtUpperCaseAndJoin(SellerStatu[value]);

		newValue = newValue.split('Seller').join('');

		return newValue;
	};

	const createdDateBodyTemplate = (seller: SellerForListDto) => dateHelper.formatDate(seller.createdDate);
	const accountCompletePercentageBody = (seller: SellerForListDto) => <span title="Account Complete Percentage">{seller.accountCompletePercentage.percentage + '%'}</span>;
	const planTemplateBody = (seller: SellerForListDto) => seller.plan.name;

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">{!filterSellerStatuType ? 'All' : setReadableName(filterSellerStatuType)} Sellers</h1>

				<div className="container-header-tools">
					<PrButton
						type="secondary"
						text="Refresh Sellers"
						disabled={loading}
						icon={<span className="pi pi-fw pi-refresh" />}
						onClick={async () => {
							setFilter('');
							await getAll(fromStatePagination);
						}}
					/>
				</div>
			</div>

			<div className="container-body p-3 z-1">
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the Seller page from the options below.</p>

				<div className="flex">
					<div>
						<SellerFilterTab filterSellerStatuType={filterSellerStatuType} setFilterSellerStatuType={setFilterSellerStatuType} name="Seller" />
					</div>
				</div>

				<div className="mt-4">
					<SearchInput onChange={(e) => setFilter(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && handleFilter()} filterBtn disabled={loading} placeholder="Search sellers" filterBtnOnClick={handleFilter} />
				</div>
			</div>

			<div className="container-body p-0">
				<DataTable value={sellers} selectionMode="checkbox" currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" totalRecords={paginationResponse?.totalItemCount} lazy rowsPerPageOptions={[5, 10, 15, 20]} dataKey="id" paginator rows={fromStatePagination.itemCount || 5} onPage={onPageChangeEvent} first={fromStatePagination.first} selection={selectedSellers} onSelectionChange={(e) => setSelectedSellers(e.value)} showGridlines className="pr-table">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column field="fullName" header="Personal Name" />
					<Column field="email" header="Email" />
					<Column header="Percentage" body={accountCompletePercentageBody} />
					<Column header="Plan" body={planTemplateBody} />
					<Column field="corporateName" header="Corporate Name" />
					<Column field="createdDate" header="Created Date" body={createdDateBodyTemplate} />
					<Column field="userStatuId" header="Status" dataType="numeric" body={statusTemplate} />
					<Column field="actions" header="Actions" body={actionsTemplate} />
				</DataTable>
			</div>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}

			<SellerUpdate isVisible={visibleUpdateSeller} setVisible={setVisibleUpdateSeller} getSellers={getAll} editedSellerId={editedSeller?.id || ''} setEditedSeller={setEditedSeller} />
			{visiblePlan && !!selectedSeller ? <PlanUpgradeModal visible={visiblePlan} setVisible={setVisiblePlan} seller={selectedSeller} setSeller={setSelectedSeller} /> : null}
			{balanceVisible && !!selectedSeller ? <SellerBalance isVisible={balanceVisible} setVisible={setBalanceVisible} seller={selectedSeller} setSeller={setSelectedSeller} /> : null}
		</div>
	);
};

export default Sellers;
