import AccountCompleteInformationWidget from 'helpers/widgets/Dashboard/AccountCompleteInformation/AccountCompleteInformationWidget';
import AvailableSurveyList from 'helpers/widgets/Dashboard/Survey/AvailableSurveyList';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const Dashboard = () => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!location.search && !location.search.search('redirectUrl')) return;

		navigate(location.search.replace('?redirectUrl=', '/'));
	}, []);

	return (
		<div className="grid">
			{!!user?.roles.isSeller ? (
				<React.Fragment>
					<div className="col-12 xl:col-6">
						<AccountCompleteInformationWidget />
					</div>

					<div className="col-12 xl:col-6">
						<AvailableSurveyList userId={user.userId} />
					</div>
				</React.Fragment>
			) : (
				<div className="col-12">
					<div className="card">
						<h5>Dashboard</h5>
						<p>You can perform your operations from the left menu.</p>
					</div>
				</div>
			)}


		</div>
	);
};

export default Dashboard;
