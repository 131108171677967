export class DeleteAuthenticationRequest {
	userId: string;

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `soft-delete/${this.userId}`
	}
}