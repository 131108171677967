import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetAllStockLocationRequest {
	pagination: PaginationDto;

	get getConvertUriExtension() {
		return 'get-all-stock-location';
	}

	constructor(props: Partial<GetAllStockLocationRequest>) {
		this.pagination = props.pagination || new PaginationDto(1, 20);
	}
}