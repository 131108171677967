import dateHelper from 'helpers/dateHelper';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const NotRequireAuth = ({ children }: any) => {
	const redirectUrlFromState = useAppSelector((state) => state.redirect);
	const token = useAppSelector((state) => state.auth.data?.accessToken.token);
	const refreshTokenExpiryDate = useAppSelector((state) => state.auth.data?.refreshToken.expiryDate);
	const isRefreshTokenExpired = dateHelper.getMinutesDiffOfTwoDate(new Date(refreshTokenExpiryDate || new Date().setMinutes(-2))) < 1;

	if (!!token && !isRefreshTokenExpired) return <Navigate to={redirectUrlFromState} />;

	return children;
};

export default NotRequireAuth;
