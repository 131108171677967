import { UserStatuTypes } from 'features/_common/dtos/user/user-statu.enum';
import { UserBaseDto } from 'features/_common/dtos/user/user.base.dto';
import { CreateOrUpdateCorporateModelDto } from './dtos/corporate-create-or-update-model.dto';
import { MerchantUpdateModelDto } from './dtos/merchant-update-model.dto';
import { UserUpdateModelDto } from './dtos/user-update-model.dto';

export class UpdateMerchantWithUserRequest {
	merchantUpdateModel: MerchantUpdateModelDto;
	userUpdateModel: UserUpdateModelDto;
	createOrUpdateCorporateModel: CreateOrUpdateCorporateModelDto;

	constructor(props: Partial<UpdateMerchantWithUserRequest>) {
		this.merchantUpdateModel = props.merchantUpdateModel || ({} as MerchantUpdateModelDto);
		this.userUpdateModel = props.userUpdateModel || ({ phoneNumbers: [] } as any as UserUpdateModelDto);
		this.createOrUpdateCorporateModel = props.createOrUpdateCorporateModel || ({} as CreateOrUpdateCorporateModelDto);
	}

	get getConvertUriExtension() {
		return `update-merchant-with-user`;
	}

	initData(data: UserBaseDto) {
		this.merchantUpdateModel = {
			userId: data?.userId || '',
			userStatuId: data?.statu || UserStatuTypes.Pending
		};

		this.createOrUpdateCorporateModel = {
			userId: data?.userId || '',
			businessName: data?.corporateInfo.businessName || '',
			feinNumber: data?.corporateInfo.feinNumber || ''
		};

		this.userUpdateModel = {
			id: data?.userId || '',
			email: data?.email || '',
			ssnNumber: data?.ssnNumber || '',
			isForeignNation: data?.isForeignNation || false,
			name: data?.personInfo.name || '',
			surname: data?.personInfo.surname || '',
			phoneNumbers: !!data.phoneNumbers ? data?.phoneNumbers?.map((_phoneNumber) => ({ id: _phoneNumber.id, code: _phoneNumber.code, number: _phoneNumber.number, isDefault: true })) : []
		};
	}
}
