import { SelectButton, SelectButtonProps } from 'primereact/selectbutton';
import React from 'react';

interface Props extends SelectButtonProps {
	label: string;
	description: string;
	required: boolean;
}

const SelectButtonForm = (props: Partial<Props>) => {
	return (
		<div className="pr-input-group w-full">
			<label htmlFor="title" className="pr-input-label">
				<h5 className="pr-input-label-title">
					{props.label || ''} {typeof props.required === 'undefined' ? '' : !props.required ? <span className="pr-input-label-optional">(Optional)</span> : <span className="pr-input-label-required">*</span>}
				</h5>

				{props.description ? <p className="pr-input-label-description">{props.description}</p> : null}
			</label>

			<SelectButton {...props} className="w-full" />
		</div>
	);
};

export default SelectButtonForm;
