import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Integration } from '../../../../../models/dtos/store/integration';
import integrationsServiceOld from '../../../../../services/integrations/integrations-service';
import IntegrationItem from './components/integration-item/IntegrationItem';

const Integrations = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const storeId = searchParams.get('storeId') || '';

	const [loading, setLoading] = useState(false);
	const [integrations, setIntegrations] = useState<Integration[]>([]);

	const getIntegrations = async () => {
		try {
			setLoading(true);

			const response = await integrationsServiceOld.getActiveIntegrations({storeId: storeId});
			if (!response.isSuccess || !response.data) throw new Error('Failed to fetch integrations');

			setIntegrations(response.data);
		} catch (error) {
			setIntegrations([]);
		} finally {
			setLoading(false);
		}
	};

	const LoadingSkeleton = () => (
		<div style={{ height: '322px', width: '100%' }}>
			<Skeleton width="100%" height="100%" className="w-full h-full bg-gray-200" />
		</div>
	);

	useEffect(() => {
		getIntegrations(); //  Add shopId
	}, []);

	return (
		<div className="relative">
			<h4 className="font-400 my-3">Let's connect your store!</h4>
			{loading ? <LoadingSkeleton /> : integrations.map((integration) => <IntegrationItem integration={integration} />)}
		</div>
	);
};

export default Integrations;
