export class GetProductPrintSidesRequest {
	private productId: string;

	constructor(productId: string) {
		this.productId = productId;
	}

	get getConvertUriExtension() {
		return `${this.productId}/sides`;
	}
}
