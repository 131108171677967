export class CreateMerchantRequest {
	email: string;
	password: string;
	passwordConfirm: string;
	name: string;
	surname: string;
	isTermsConfirmed: boolean;
	businessName: string;
	feinNumber: string;

	constructor(props: Partial<CreateMerchantRequest>) {
		this.email = props.email || '';
		this.password = props.password || '';
		this.passwordConfirm = props.passwordConfirm || props.password || '';
		this.name = props.name || '';
		this.surname = props.surname || '';
		this.isTermsConfirmed = props.isTermsConfirmed || true;
		this.businessName = props.businessName || '';
		this.feinNumber = props.feinNumber || '';
	}

	get getConvertUriExtension() {
		return `create`;
	}
}
