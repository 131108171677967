import HttpBaseService from 'services/_common/http-base-service';
import { ConvertImageToBase64Request } from './convert-image-to-base64/convert-image-to-base64.request';
import { ConvertImageToBase64Response } from './convert-image-to-base64/convert-image-to-base64.response';

class MaterialService extends HttpBaseService {
	constructor() {
		super('materials');
	}

	async convertImageToBase64(request: ConvertImageToBase64Request): Promise<ConvertImageToBase64Response> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export const materialService = new MaterialService();
