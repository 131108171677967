import { MediaBase } from 'features/medias/dtos/bases/media.base.dto';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';
import { ImageMediaBase } from 'features/medias/dtos/bases/image-media.base.dto';

const getImageOnMedia = (media?: MediaBase | null, placeholderSize: number = 800) => {
	const defaultImage = { url: 'https://via.placeholder.com/' + placeholderSize } as ImageMediaBase;
	if (!media) return defaultImage as ImageMediaBase;

	if (!!media.imageMediaWithStorages && media.imageMediaWithStorages.length > 0) return media.imageMediaWithStorages[media.imageMediaWithStorages.length - 1] as ImageMediaBase;
	if (!!media.imageMediasWithUrl && media.imageMediasWithUrl.length > 0) return media.imageMediasWithUrl[media.imageMediasWithUrl.length - 1] as ImageMediaBase;

	return defaultImage as ImageMediaBase;
};

const getImage = (media: MyProductMedia, placeholderSize: number = 400) => {
	const defaultImage = { url: 'https://via.placeholder.com/' + placeholderSize } as ImageMediaBase;
	if (!media) return defaultImage.url;

	if (!!media.imageWithStorage) return media.imageWithStorage.url;
	if (!!media.imageWithUrl) return media.imageWithUrl.url;

	return defaultImage.url;
};

const getNewMediaImage = (media: MyProductMedia, placeholderSize: number = 400) => {
	const defaultImage = { url: 'https://via.placeholder.com/' + placeholderSize } as ImageMediaBase;
	if (!media) return defaultImage;

	if (!!media.imageWithStorage) return media.imageWithStorage as ImageMediaBase;
	if (!!media.imageWithUrl) return media.imageWithUrl as ImageMediaBase;

	return defaultImage;
};

const mediaHelper = { getImageOnMedia, getImage, getNewMediaImage };

export default mediaHelper;
