import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetSellerListForGroupRequest {
	contains: string | null;
	pagination: PaginationDto;

	constructor(props: Partial<GetSellerListForGroupRequest>) {
		this.contains = props.contains || "";
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return 'get-seller-list-for-group'
	}
}