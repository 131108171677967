export class ChangePasswordRequest {
	userId: string;
	currentPassword: string;
	password: string;
	passwordConfirm: string;

	constructor(userId: string, currentPassword: string, password: string, passwordConfirm: string) {
		this.userId = userId;
		this.currentPassword = currentPassword;
		this.password = password;
		this.passwordConfirm = passwordConfirm;
	}

	get getConvertUriExtension() {
		return `change-password`;
	}
}