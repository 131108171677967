import { ParsedAddressDto } from 'features/orders/derived-features/order-transports/dtos/parsed-address.dto';
import { orderTransportService } from 'features/orders/derived-features/order-transports/order-transport.service';
import { ParseAddressRequest } from 'features/orders/derived-features/order-transports/parse-address/parse-address.request';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextareaInput from 'helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import React, { useEffect, useState } from 'react';

type props = {
	defaultAddress?: string;
	onAutofillConfirm: (event: ParsedAddressDto | undefined) => void;
};

const AddressAutofill = ({ defaultAddress, onAutofillConfirm }: props) => {
	const [address, setAddress] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');

	const parseAddress = async () => {
		if (!address) return;

		try {
			setLoading(true);

			const addressReq = structuredClone(address);

			const request = new ParseAddressRequest({ address: addressReq.replace(/\n/g, ' ') });

			const response = await orderTransportService.parseAddress(request);

			if (!response.isSuccess || !response.data) throw '';

			const formattedData = structuredClone(response.data);

			formattedData.formatted_address = address;
			if (!formattedData.address_line_1) throw '';

			onAutofillConfirm(formattedData);
			setErrorMsg("")
		} catch (error) {
			onAutofillConfirm(undefined);
			setErrorMsg('We are unable to autofill that address. Please check the details and try again.');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!defaultAddress) return;

		setAddress(defaultAddress);
	}, [defaultAddress]);

	return (
		<div>
			<h5>Address Autofill</h5>
			<div>Easily autofill your customer’s address by pasting the details below.</div>

			<div className="text-sm mt-3">
				<span className="font-bold">Recommended format:</span> 13219 Stafford Road, Missouri City, TX, 77489, USA
			</div>

			<div className="my-2">
				<PrTextareaInput value={address} rows={3} placeholder="Paste your customer’s address here (apartment, street, city, state, ZIP code)" onChange={(e) => setAddress(e.target.value)} />
			</div>

			<div className="flex flex-row aling-items-center">
				<span className="pi pi-fw pi-info-circle text-xs" />
				<span className="ml-1 text-xs">Always make sure to doublecheck the address before continuing.</span>
			</div>

			{errorMsg ? (
				<div className="flex flex-row aling-items-center text-red">
					<span className="pi pi-fw pi-info-circle text-xs" />
					<span className="ml-1 text-xs">{errorMsg}</span>
				</div>
			) : null}

			<div className="flex justify-content-end mt-2">
				<PrButton text="Autofill" onClick={parseAddress} loading={loading} type="secondary" btnType="button" />
			</div>
		</div>
	);
};

export default AddressAutofill;
