export enum JwtKey {
    ReadableRoles = 'ReadableRoles',
    Audience = 'aud',
    ExpireDate = 'exp',
    Roles = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    Id = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    Issuer = 'iss',
    NotBefore = 'nbf'
}

export interface ParsedJwt {
    key: JwtKey;
    value: number | string | string[] | number[];
}
