export class GetReorderDetailRequest {
	reorderId: string;

	constructor(reorderId: string) {
		this.reorderId = reorderId;
	}

	get getConvertUriExtension() {
		return `get-reorder-detail/${this.reorderId}`;
	}
}
