import currencyHelper from 'helpers/curreny.helper';
import stringHelper from 'helpers/string.helper';
import { PriceTypes, PrintramOrderPrice, PrintramPrice, PrintramPriceDto } from 'models/dtos/order/order';
import styles from './ProductPrices.module.scss';
import { useEffect, useState } from 'react';

type Props = {
	prices: PrintramPrice;
};

type PrintramOrderPriceWithDiscount = PrintramOrderPrice & {
	discountedPrice?: PrintramPriceDto;
}

export const ProductPrices = (props: Props) => {
	const [formattedPrices, setFormattedPrices] = useState<PrintramOrderPriceWithDiscount[]>([]);
	const filteredValues = [ { isExist: 15, drop: 9 }, { isExist: 16, drop: 11 }, { isExist: 17, drop: 3 } ]

	useEffect(() => {
		if (!props) return;

		const priceHandle = structuredClone(props.prices.printramOrderPrices) as PrintramOrderPriceWithDiscount[];

		var prices = [3, 9, 11]

		const filteredPrices = priceHandle.filter((_price) => prices.includes(_price.price.type));

		var discountedPrices = [15, 16 , 17]

		const filteredDiscountedPrices = priceHandle.filter(_price => discountedPrices.includes(_price.price.type))

		const filteredPricesWithDiscount = filteredPrices.map(_price => {
			let filterValue = filteredValues.find(filterVal => filterVal.drop === _price.price.type) || null
			if (filterValue) {
				return { ..._price, discountedPrice: filteredDiscountedPrices.find(disFilter => disFilter.price.type === filterValue?.isExist)?.price}
			} else return _price
		})

		const sortedPrices = filteredPricesWithDiscount.sort(function (a, b) {
			var order = [15, 9, 16, 11, 17, 3];
			return order.indexOf(a.price.type) - order.indexOf(b.price.type);
		});

		setFormattedPrices(sortedPrices)
	}, [props])

	return (
		<section className="container-body p-3">
			<div className="flex align-items-center justify-content-end">
				<div className={styles.pricesWrapper}>
					{formattedPrices.map((_price, index) =>
						_price.price.type !== PriceTypes.TransportPriceForPrintram ? (
							_price.discountedPrice ? (
								<div key={index} className={styles.pricesItem}>
									<span className={styles.pricesTitle}>{stringHelper.parseAtUpperCaseAndJoin(PriceTypes[_price.price.type])}:</span>
									<div>
										<small className="font-semibold text-pink-600 mr-1">
											<del>{currencyHelper.formatPrice(_price.price.price.formattedPricePerUnit)}</del>
										</small>

										<span className={_price.discountedPrice.type === 17 ? 'font-bold' : ''}>{currencyHelper.formatPrice(_price.discountedPrice.price.formattedPricePerUnit)}</span>
									</div>
								</div>
							) : (
								<div key={index} className={styles.pricesItem}>
									<span className={styles.pricesTitle}>{stringHelper.parseAtUpperCaseAndJoin(PriceTypes[_price.price.type])}:</span>

									<span className={styles.pricesValue}>{currencyHelper.formatPrice(_price.price.price.formattedPricePerUnit)}</span>
								</div>
							)
						) : null
					)}
				</div>
			</div>
		</section>
	);
};
