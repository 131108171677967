import HttpBaseService from '../../../services/_common/http-base-service';
import { CreatePrintCenterOperatorRequest, CreatePrintCenterOperatorResponse } from './create-print-center-operator/create-print-center-operator';
import { GetAllPrintCenterOperatorsRequest, GetAllPrintCenterOperatorsResponse } from './get-all-print-center-operators/get-all-print-center-operators';
import { GetPrintCenterOperatorByIdRequest, GetPrintCenterOperatorByIdResponse } from './get-print-center-operator-by-id/get-print-center-operator-by-id';
import { UpdatePrintCenterOperatorRequest, UpdatePrintCenterOperatorResponse } from './update_print_center_operator/update-print-center-operator';
import { SoftDeletePrintCenterOperatorRequest, SoftDeletePrintCenterOperatorResponse } from './soft-delete-print-center-operator/soft-delete-print-center-operator';

class PrintCenterOperatorService extends HttpBaseService {
	constructor() {
		super('print-center-operator');
	}

	async createPrintCenterOperator(request: CreatePrintCenterOperatorRequest): Promise<CreatePrintCenterOperatorResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getAllPrintCenterOperators(request: GetAllPrintCenterOperatorsRequest): Promise<GetAllPrintCenterOperatorsResponse> {
		return await this.getData(request.getConvertUriExtension());
	}

	async getPrintCenterOperatorById(request: GetPrintCenterOperatorByIdRequest): Promise<GetPrintCenterOperatorByIdResponse> {
		return await this.getData(request.getConvertUriExtension());
	}

	async updatePrintCenterOperator(request: UpdatePrintCenterOperatorRequest): Promise<UpdatePrintCenterOperatorResponse> {
		return await this.postData(request.getConvertUriExtension(), request);
	}

	async softDeletePrintCenterOperator(request: SoftDeletePrintCenterOperatorRequest): Promise<SoftDeletePrintCenterOperatorResponse> {
		return await this.getData(request.getConvertUriExtension());
	}
}

export default new PrintCenterOperatorService();
