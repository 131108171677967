const turkishCharacters = {
	ı: 'i',
	ğ: 'g',
	ü: 'u',
	ş: 's',
	ö: 'o',
	ç: 'c',
	İ: 'i',
	Ğ: 'g',
	Ü: 'u',
	Ş: 's',
	Ö: 'o',
	Ç: 'c'
};

const parseAtUpperCaseAndJoin = (value: string) => value.split(/(?=[A-Z])/).join(' ');

const formatName = (value: string): string => value.replace(/[^A-Za-zöçİğüÖÇĞÜşŞ.-\s'`]*$/, '');

function slugify(value: string) {
	value = value.trim().toLowerCase();

	for (const [key, val] of Object.entries(turkishCharacters)) value = value.replace(new RegExp(key, 'g'), val);

	value = value
		.replace(/[^a-z0-9 -]/g, '')
		.replace(/\s+/g, '-')
		.replace(/-+/g, '-');

	return value;
}

const formatStringLength = (value: string, maxLength: number ) => {
	if (maxLength >= value.length) return value;

	return value.substring(0, maxLength).trim() + '...';
}

const stringHelper = { parseAtUpperCaseAndJoin, slugify, formatName, formatStringLength };

export default stringHelper;
