import React, { useState } from 'react';
import ordersService from 'features/orders/_common/order.service';
import { GetOrderDesignArchiveRequest } from 'features/orders/_common/get-order-design-archive/get-order-design-archive.request';
import { GenerateOrderDetailPdfsRequest } from 'features/orders/_common/pdfs/generate-order-detail-pdfs/generate-order-detail-pdfs.request';
import { GenerateOrderItemBarcodePdfsRequest } from 'features/orders/_common/pdfs/generate-order-item-barcode-pdfs/generate-order-item-barcode-pdfs.request';
import { GenerateOrderPdfsRequest } from 'features/orders/_common/pdfs/generate-order-pdfs/generate-order-pdfs.request';
import { GenerateOrderReceiptPdfsRequest } from 'features/orders/_common/pdfs/generate-order-receipt-pdfs/generate-order-receipt-pdfs.request';
import DropdownButton from 'helpers/widgets/DropdownButton';
import PrintPreviewModal, { DialogTypeString } from '../PrintPreview/PrintPreviewModal';
import { GenerateOrderTransportLabelsRequest } from 'features/orders/_common/pdfs/generate-order-transport-labels/generate-order-transport-labels.request';
import { GenerateOrderReceiptWithShipLabelPdfsRequest } from 'features/orders/_common/pdfs/generate-order-receipt-with-ship-label-pdfs/generate-order-receipt-with-ship-label-pdfs.request';
import { Menu } from '@headlessui/react';
import { AiFillCaretDown, AiOutlinePrinter } from 'react-icons/ai';
import { GenerateOrderReceiptV2Request } from 'features/orders/_common/pdfs/generate-order-receipt-v2/generate-order-receipt-v2.request';

enum PrintType {
	Receipts = 'Receipts',
	// Details = 'Details',
	Barcode = 'Barcode',
	Designs = 'Designs',
	TransportLabel = 'Transport Labels',
	// ReceiptWithShipLabel = 'Receipt With Ship Label',
	All = 'All'
}

export type SkipPrintTypes = `${PrintType}`;

type Props = {
	orderIds: string[] | string;
	loading: boolean;
	setLoading: (state: boolean) => void;
	skipTypes?: SkipPrintTypes[];
};

const PrintDropdown = ({ orderIds, loading, setLoading, skipTypes }: Props) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [pdfUrl, setPdfUrl] = useState<string>('');
	const [dialogType, setDialogType] = useState<DialogTypeString | undefined>(undefined);

	const handleDownloadReceiptsPdfs = async () => {
		if (orderIds.length === 0) return;

		try {
			setLoading(true);
			const request = new GenerateOrderReceiptV2Request(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
			const response = await ordersService.generateOrderReceiptV2(request);
			if (!response.isSuccess || !response.data) throw '';

			setVisible(true);
			setPdfUrl(response.data.cdnUrl);
			setDialogType('Receipts');
		} finally {
			setLoading(false);
		}
	};

	// const handleDownloadReceiptWithShipLabels = async () => {
	// 	if (orderIds.length === 0) return;

	// 	try {
	// 		setLoading(true);
	// 		const request = new GenerateOrderReceiptWithShipLabelPdfsRequest(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
	// 		const response = await ordersService.generateOrderReceiptWithShipLabelPdfs(request);
	// 		if (!response.isSuccess || !response.data) throw '';

	// 		setVisible(true);
	// 		setPdfUrl(response.data.cdnUrl);
	// 		setDialogType('Receipt With Ship Label');
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const handleDownloadTransportLabels = async () => {
		if (orderIds.length === 0) return;

		try {
			setLoading(true);
			const request = new GenerateOrderTransportLabelsRequest(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
			const response = await ordersService.generateOrderTransportLabels(request);
			if (!response.isSuccess || !response.data) throw '';
			const a = document.createElement('a');

			a.href = response.data.cdnUrl;
			a.download = response.data.fileName;
			a.click();
			a.remove();
		} finally {
			setLoading(false);
		}
	};

	// const handleDownnloadDetailsPdfs = async () => {
	// 	if (orderIds.length === 0) return;

	// 	try {
	// 		setLoading(true);
	// 		const request = new GenerateOrderDetailPdfsRequest(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
	// 		const response = await ordersService.generateOrderDetailPdfs(request);
	// 		if (!response.isSuccess || !response.data) throw '';

	// 		setVisible(true);
	// 		setPdfUrl(response.data.cdnUrl);
	// 		setDialogType('Details');
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const handleDownloadBarcodesPdfs = async () => {
		if (orderIds.length === 0) return;

		try {
			setLoading(true);
			const request = new GenerateOrderItemBarcodePdfsRequest(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
			const response = await ordersService.generateOrderItemBarcodePdfs(request);
			if (!response.isSuccess || !response.data) throw '';

			setVisible(true);
			setPdfUrl(response.data.cdnUrl);
			setDialogType('Barcode');
		} finally {
			setLoading(false);
		}
	};

	const handleDownloadOrderDesignsArchive = async () => {
		if (orderIds.length === 0) return;

		try {
			setLoading(true);
			const request = new GetOrderDesignArchiveRequest(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
			const response = await ordersService.getOrderDesignArchive(request);
			if (!response.isSuccess || !response.data) throw '';

			const a = document.createElement('a');

			a.href = response.data.cdnUrl;
			a.download = response.data.fileName;
			a.click();
			a.remove();
		} finally {
			setLoading(false);
		}
	};

	const handleDownloadAllPdfs = async () => {
		if (orderIds.length === 0) return;

		try {
			setLoading(true);
			const request = new GenerateOrderPdfsRequest(typeof orderIds === 'string' ? [orderIds] : [...orderIds]);
			const response = await ordersService.generateAllOrderDataPdfs(request);
			if (!response.isSuccess || !response.data) throw '';

			const a = document.createElement('a');

			a.href = response.data.archiveUrl;
			a.download = new Date().toISOString().slice(0, 19) + '-all-pdfs.zip';
			a.click();
			a.remove();
		} finally {
			setLoading(false);
		}
	};

	const dropdownMenuItems = [
		<Menu.Item as="button" key={`${PrintType.Receipts}`} onClick={handleDownloadReceiptsPdfs} className="pr-btn pr-dropdown-menu-item">
			Receipts
		</Menu.Item>,
		// <Menu.Item as="button" key={`${PrintType.Details}`} onClick={handleDownnloadDetailsPdfs} className="pr-btn pr-dropdown-menu-item">
		// 	Details
		// </Menu.Item>,
		<Menu.Item as="button" key={`${PrintType.Barcode}`} onClick={handleDownloadBarcodesPdfs} className="pr-btn pr-dropdown-menu-item">
			Barcodes
		</Menu.Item>,
		<Menu.Item as="button" key={`${PrintType.Designs}`} onClick={handleDownloadOrderDesignsArchive} className="pr-btn pr-dropdown-menu-item">
			Order Designs
		</Menu.Item>,
		<Menu.Item as="button" key={`${PrintType.TransportLabel}`} onClick={handleDownloadTransportLabels} className="pr-btn pr-dropdown-menu-item">
			Transport Label
		</Menu.Item>,
		// <Menu.Item as="button" key={`${PrintType.ReceiptWithShipLabel}`} onClick={handleDownloadReceiptWithShipLabels} className="pr-btn pr-dropdown-menu-item">
		// 	Receipt With Ship Label
		// </Menu.Item>,
		<Menu.Item as="button" key={`${PrintType.All}`} onClick={handleDownloadAllPdfs} className="pr-btn pr-dropdown-menu-item">
			Download All
		</Menu.Item>
	];

	return (
		<React.Fragment>
			{/* {!!orderIds && orderIds.length > 0 ? <DropdownButton menuItems={dropdownMenuItems.filter((data) => !skipTypes?.find((_type) => _type === data.key))} loading={loading} label="Prints" icon="pi pi-file-pdf" /> : null} */}

			{!!orderIds && orderIds.length > 0 ? (
				<Menu as="div" className="pr-dropdown" style={{ zIndex: '99' }}>
					<Menu.Button className="pr-btn pr-btn-secondary" disabled={loading}>
						<span className="mr-3">Print</span>
						<AiFillCaretDown />
					</Menu.Button>

					<Menu.Items className="pr-dropdown-menu">{dropdownMenuItems.filter((data) => !skipTypes?.find((_type) => _type === data.key)).map((_item) => _item)}</Menu.Items>
				</Menu>
			) : null}

			{!!dialogType && !!pdfUrl ? <PrintPreviewModal dialogType={dialogType} pdfUrl={pdfUrl} visible={visible} setVisible={setVisible} /> : null}
		</React.Fragment>
	);
};

export default PrintDropdown;
