import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { CreateWizardContext, WizardContextType } from '../context/WizardContextProvider';
import TextInput from '../../../../../helpers/widgets/Forms/TextInput';
import PrPrefixDropdownInput from '../../../../../helpers/widgets/Printram/Forms/Input/PrPrefixDropdownInputWithFlags';
import TextMaskInput from '../../../../../helpers/widgets/Forms/TextMaskInput';
import PrButton from '../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { UpdateAccountContactInfoPhoneNumberRequestDto, UpdateAccountContactInfoRequestDto, UpdateMyAccountRequest } from '../../../../../features/users/derived_features/user-profiles/update-my-account/update-my-account.request';
import userProfileService from '../../../../../features/users/derived_features/user-profiles/user-profile.service';
import { setReNewAuth } from '../../../../../redux/features/auth/authSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { GetMyAccountRequest } from '../../../../../features/users/derived_features/user-profiles/get-my-account/get-my-account.request';

const AccountDetails = () => {
	const context: WizardContextType = React.useContext(CreateWizardContext) as WizardContextType;
	const dispatch = useAppDispatch();

	const prefixCheck = (prefix: string): string => {
		if (prefix[0] === '+') return prefix
		return `+${prefix}`;
	}

	const initialPrefix = context.user && context.user.phoneNumbers ? prefixCheck(context.user.phoneNumbers[0].code) : '+1';

	const [prefix, setPrefix] = useState<string>(initialPrefix);
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues = {
		fullName: context.user?.personInfo.fullName,
		email: context.user?.email,
		prefix: context.user?.phoneNumbers ? context.user?.phoneNumbers![0].code : '+1',
		phone: context.user?.phoneNumbers ? context.user?.phoneNumbers![0].number : ''
	};

	const phoneRegExp = /^(\+?\d{1,4}[\s-]?)?\(?\d{1,4}?\)?[\s-]?\d{1,4}[\s-]?\d{1,9}$/;

	const ValidationSchema = Yup.object().shape({
		fullName: Yup.string().min(2, 'Full Name Too Short').max(50, 'Full Name Too Long').required('Full Name Required'),
		email: Yup.string().email('Enter a valid email').required('Full Name Required'),
		phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required')
	});

	function splitName(fullName: String) {
		const parts = fullName.split(' ');
		const surname = parts.pop();
		const restOfName = parts.join(' ');

		return { surname, restOfName };
	}

	const submitForm = async (values: any) => {
		try {
			setLoading(true);
			if (!context.user) return;

			if (values.fullName === initialValues.fullName && values.email === initialValues.email && values.phone === initialValues.phone && prefix === initialPrefix) return context.setStep(1);

			const { surname, restOfName } = splitName(values.fullName);

			const request = new UpdateMyAccountRequest({
				userId: context.user.userId,
				ssnNumber: context.user.ssnNumber,
				corporateInfo: context.user.corporateInfo,
				isForeignNation: true,
				contactInfo: new UpdateAccountContactInfoRequestDto({
					name: restOfName,
					surname: surname,
					email: values.email,
					phoneNumbers: [
						new UpdateAccountContactInfoPhoneNumberRequestDto({
							id: context.user.phoneNumbers ? context.user.phoneNumbers![0].id : null,
							code: prefix,
							number: values.phone
						})
					]
				})
			});

			const response = await userProfileService.updateMyAccount(request);

			if (!response.isSuccess) throw '';

			const accountResponse = await userProfileService.getMyAccount(new GetMyAccountRequest(context.user.userId));
			const data = accountResponse.data;

			if (!data) throw '';

			let newUser = structuredClone(context.user);
			newUser.personInfo.fullName = `${data.contactInfo.name} ${data.contactInfo.surname}`;
			newUser.email = data.contactInfo.email;
			newUser.phoneNumbers = data.contactInfo.phoneNumbers;

			dispatch(setReNewAuth({ ...context.auth, user: newUser } as any));

			context.setStep(1);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="AccountDetails flex flex-column justify-content-center align-items-center mx-3">
			<div className="text-center">
				<h2 className="font-semibold">What is Your Account Details</h2>
			</div>

			<Formik initialValues={initialValues} validationSchema={ValidationSchema} onSubmit={(values) => submitForm(values)}>
				{({ values, handleChange, setFieldValue, dirty }) => (
					<Form>
						<p>We will use this information to customize your experience.</p>
						<div className="flex flex-column justify-content-between px-2">
							<div className="mb-3">
								<TextInput style={{ borderRadius: '10px' }} label="Full Name*" name="fullName" value={values.fullName} onChange={handleChange} disabled={loading} />
							</div>

							<div className="mb-3">
								<TextInput style={{ borderRadius: '10px' }} label="Email*" name="email" value={values.email} onChange={handleChange} disabled={loading} />
							</div>

							<div className="flex flex-row mb-3">
								<div className="flex-grow-2 mr-3">
									<PrPrefixDropdownInput style={{ borderRadius: '10px' }} label="Prefix*" name="prefix" value={prefix} onChange={(e) => setPrefix(e.target.value)} disabled={loading} />
								</div>

								<div className="flex-grow-1">
									<TextMaskInput style={{ borderRadius: '10px' }} label="Phone*" mask="999-999-9999" name="phone" unmask={true} value={values.phone} onChange={handleChange} placeholder="" disabled={loading} />
								</div>
							</div>

							<div className="flex flex-row justify-content-center my-2">
								<PrButton className="border-round-md border-none hover:bg-transparent text-lg px-4 py-2" text="Next" style={{ height: '42px' }} btnType="submit" disabled={loading} />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AccountDetails;
