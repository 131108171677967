import { CalculatedDto } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.response';
import currencyHelper from 'helpers/curreny.helper';

export const CostBreakdown = (props: CalculatedDto) => {
	return (
		<div className="flex align-items-center justify-content-center">
			<div className="w-max">
				<h2 className="mb-3" style={{ fontSize: '1.5rem' }}>
					Order breakdown
				</h2>

				<div className="flex justify-content-between mb-2 font-bold">
					<span>Fulfillment</span>
					<span>{currencyHelper.formatPrice(props.fulfillment?.formattedPricePerUnit || props.fullfillment?.formattedPricePerUnit || 0)}</span>
				</div>

				<div className="flex justify-content-between font-bold">
					<span>Shipping</span>
					<span>{currencyHelper.formatPrice(props.shipping.formattedPricePerUnit)}</span>
				</div>

				<hr className="my-2" />

				<h2 className="flex justify-content-between m-0" style={{ fontSize: '1.5rem' }}>
					<span>Total</span>
					<span>{currencyHelper.formatPrice(props.total.formattedPricePerUnit)}</span>
				</h2>
			</div>
		</div>
	);
};
