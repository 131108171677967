import HttpBaseService from 'services/_common/http-base-service';
import { GetAssignedStoresToPrintCenterResponse } from './get-assigned-stores-to-print-center/get-assigned-stores-to-print-center.response';
import { GetAssignedStoresToPrintCenterRequest } from './get-assigned-stores-to-print-center/get-assigned-stores-to-print-center.request';

class AssignedStoreToPrintCenterService extends HttpBaseService {
	constructor() {
		super('assigned-store-to-print-centers');
	}

	async getAssignedStoresToPrintCenter(request: GetAssignedStoresToPrintCenterRequest): Promise<GetAssignedStoresToPrintCenterResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new AssignedStoreToPrintCenterService();
