import * as htmlToImage from 'html-to-image';

const toJpg = async (ref: HTMLElement) => {
	try {
		const data = await htmlToImage.toJpeg(ref);

		return data;
	} catch (error) {
		return error;
	}
};

const toPng = async (ref: HTMLElement) => {

	const data = await htmlToImage.toPng(ref);

		return data;
};

const toBlob = async (ref: HTMLElement) => {
	const data = await htmlToImage.toPixelData(ref, {
		fetchRequestInit: {
			"mode":"no-cors"
		}
	});

	return data;
};

const dataUrlToFile = (dataUrl: string, fileName: string) => {
	if (dataUrl.length === 0 || fileName.length === 0) return;

	let array = dataUrl.split(',');
	let mime = (array[0].match(/:(.*?);/) || ['', ''])[1];
	let bstr = atob(array[1]);
	let n = bstr.length;
	let u8array = new Uint8Array(n);

	while (n--) u8array[n] = bstr.charCodeAt(n);

	return new File([u8array], fileName, { type: mime });
};

const htmlToImageHelper = { toJpg, toBlob, dataUrlToFile, toPng };

export default htmlToImageHelper;
