import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetVariantSummariesRequest {
	productIdentities: string[];
	pagination = new PaginationDto();

	constructor(productIdentities: string[], pagination: PaginationDto) {
		if(!!pagination) this.pagination = pagination;
		this.productIdentities = productIdentities;
	}

	get getConvertUriExtension() {
		return `get-variant-summaries`;
	}
}