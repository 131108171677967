import { DataResponse } from '../models/_commons/responses/data-response';
import { ResponseBase } from '../models/_commons/responses/response-base';
import HttpBaseService from './_common/http-base-service';
import { PaginationRequest } from '../models/_commons/requests/pagination-request';
import { Model } from '../models/dtos/brand/model';
import { CreateModelRequest } from '../models/requests/models/create-model-request';
import { UpdateModelRequest } from '../models/requests/models/update-model-request';

class ModelService extends HttpBaseService {
    constructor() {
        super('models');
    }

    async getAll(request: PaginationRequest): Promise<DataResponse<Model[]>> {
        return await this.postData('get-models', { pagination: request });
    }

    async GetAllByBrandId(brandId: string): Promise<DataResponse<Model[]>> {
        return await this.getData('get-models-by-brand?brandId=' + brandId);
    }

    async GetAllByBrandAndMerchantId(brandId: string, merchantId: string): Promise<DataResponse<Model[]>> {
        return await this.getData('get-models-by-brand-and-merchant?brandId=' + brandId + '&merchantId=' + merchantId);
    }

    async create(request: CreateModelRequest): Promise<ResponseBase> {
        return await this.postData('create', request);
    }

    async update(request: UpdateModelRequest): Promise<ResponseBase> {
        return await this.putData('update', request);
    }

    async delete(id: string): Promise<boolean> {
        return await this.deleteData('soft-delete', id);
    }
}

export default new ModelService();
