import React, { useEffect, useRef, useState } from 'react';
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface';
import { DateFilterDto, GetOperationProcessStepsRequest } from 'features/orders/_common/get-operation-process-steps/get-operation-process-steps.request';
import { OperationProcessDto, OperationSteps } from 'features/orders/_common/get-operation-process-steps/get-operation-process-steps.response';
import orderService from 'features/orders/_common/order.service';
import dateHelper from 'helpers/dateHelper';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { setForOperationStepsPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useNavigate } from 'react-router-dom';
import { Calendar, CalendarValueType } from 'primereact/calendar';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ExportOperationProcessStepsRequest } from 'features/orders/_common/export-operation-process-steps/export-operation-process-steps.request';

const OperationStepList = () => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const fromStatePagination = useAppSelector((_state) => _state.pagination.forOperationStepsPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const calendarRef = useRef<Calendar>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [operationSteps, setOperationSteps] = useState<OperationProcessDto[]>([]);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const [dateFilter, setDateFilter] = useState<DateFilterDto>({ minDate: null, maxDate: null });
	const [dates, setDates] = useState<CalendarValueType>();
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [lastRequest, setLastRequest] = useState<GetOperationProcessStepsRequest>(new GetOperationProcessStepsRequest({}));

	const dateFooterTemplate = () => {
		return <PrButton text="Apply Filter" onClick={() => applyFilter()} className="mx-auto mb-2" />;
	};

	const applyFilter = () => {
		function addDays(date: Date, days: number) {
			var result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}

		const formattedDate = structuredClone(dates as Date[]);

		const minDate = formattedDate[0] || null;
		const maxDate = formattedDate[1] ? addDays(formattedDate[1], 1) : null;

		setDateFilter({ minDate: minDate || null, maxDate: maxDate || (minDate ? addDays(minDate, 1) : null) });

		calendarRef && calendarRef.current?.hide();
	};

	const onClearDate = () => {
		setDates(null);
		setDateFilter({ minDate: null, maxDate: null });
	};

	const getAll = async (request: Partial<GetOperationProcessStepsRequest>) => {
		request.contains = typeof request.contains !== 'undefined' ? request.contains : searchFilter || null;
		request.dateFilter = dateFilter;

		const clonedRequest = new GetOperationProcessStepsRequest({ ...structuredClone(request) });

		try {
			setLoading(true);

			const response = await orderService.getOperationProcessSteps(clonedRequest);
			if (!response.isSuccess || !response.data) throw '';

			console.clear();

			setOperationSteps(response.data);
			setPaginationResponse(response.pagination);

			const newPagination = JSON.parse(JSON.stringify(clonedRequest.pagination));

			dispatch(setForOperationStepsPaginationInfo(newPagination));
		} catch (error) {
			setOperationSteps([]);
			setPaginationResponse(undefined);
		} finally {
			setLoading(false);
			setLastRequest(clonedRequest);
		}
	};

	const exportOperationSteps = async() => {
		try {
			setLoading(true)

			const request = new ExportOperationProcessStepsRequest({ dateFilter: dateFilter, contains: searchFilter || null })

			const response = await orderService.exportOperationProcessSteps(request)

			if (!response.isSuccess || !response.data) throw ''

			window.open(response.data, '_self');
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!dateFilter) return;

		getAll({})
	},[dateFilter])

	return (
		<section className="container-body static p-3">
			<SearchInput value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && getAll({})} filterBtn disabled={loading} placeholder="Search" filterBtnOnClick={() => getAll({})} />

			<div className="mt-3 flex flex-row justify-content-between">
				<Calendar ref={calendarRef} value={dates} onChange={(e) => setDates(e.value)} footerTemplate={dateFooterTemplate} onClearButtonClick={onClearDate} selectionMode="range" hideOnDateTimeSelect readOnlyInput showButtonBar placeholder="Select Date Range" clearButtonClassName="text-blue-500" todayButtonClassName="text-blue-500" inputClassName="cursor-pointer" />
				<PrButton text="Export" onClick={exportOperationSteps} icon={<span className="pi pi-fw pi-download" />} type="secondary" />
			</div>

			<DataTable
				value={operationSteps}
				className="pr-table mt-3"
				showGridlines
				paginator
				lazy
				dataKey="id"
				currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				first={fromStatePagination.first}
				rows={fromStatePagination.itemCount || 20}
				totalRecords={paginationResponse?.totalItemCount}
				rowsPerPageOptions={[10, 20, 30]}
				onPage={(event) => {
					if (!!searchFilter) return;

					getAll({
						pagination: {
							first: event.first,
							itemCount: event.rows,
							pageNumber: event.page ? event.page + 1 : 1,
							orderBy: PaginationOrderByEnum.Descending
						}
					});
				}}>
				<Column
					header="Code"
					field="orderCode"
					body={(order: OperationProcessDto) => (
						<span
							className="cursor-pointer"
							onClick={() => {
								if (!order.orderCode || !order.orderId) return;

								if (!user?.roles.isPackager) navigate('/order/' + order.orderId);
								else {
									navigator.clipboard.writeText(order?.orderCode);
									toastHelper.success(`Copied '${order?.orderCode}' to clipboard`);
								}
							}}>
							{!!order.orderCode ? `#${order.orderCode}` : null}
						</span>
					)}
				/>

				<Column header="Step" field="step" body={(order: OperationProcessDto) => stringHelper.parseAtUpperCaseAndJoin(OperationSteps[order.step])} />

				<Column
					header="Barcode"
					field="barcode"
					body={(order: OperationProcessDto) => (
						<span
							className="cursor-pointer"
							onClick={() => {
								navigator.clipboard.writeText(order?.barcode);
								toastHelper.success(`Copied '${order?.barcode}' to clipboard`);
							}}>
							{order.barcode}
						</span>
					)}
				/>

				<Column header="Created By" field="createdUserFullName" />

				<Column header="Created Date" field="createdDate" body={(order: OperationProcessDto) => dateHelper.formatDate(order.createdDate)} />
			</DataTable>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</section>
	);
};

export default OperationStepList;
