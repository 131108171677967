import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { GetReorderListRequest } from 'features/reorders/_common/get-reorder-list/get-reorder-list.request';
import { ReorderListDto } from 'features/reorders/_common/get-reorder-list/get-reorder-list.response';
import { reorderService } from 'features/reorders/_common/reorder.service';
import { ReorderStatusType } from 'features/reorders/enums/reorder-status-type';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import stringHelper from 'helpers/string.helper';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import { Pagination } from 'models/_commons/responses/pagination';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { setForAdminReorderPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export const ReOrders = () => {
  	const fromStatePagination = useAppSelector((state) => state.pagination.forAdminReorderPaginationInfo as PaginationDto);
	const user = useAppSelector((_state) => _state.auth.data?.user);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [activeFilter, setActiveFilter] = useState<ReorderStatusType>(ReorderStatusType.Unknown);
	const [loading, setLoading] = useState<boolean>(false);
	const [reorders, setReorders] = useState<ReorderListDto[]>([]);
	const [contains, setContains] = useState<string>('');
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

		const getReOrders = async (pagination?: PaginationDto) => {
			const newPagination = pagination || new PaginationDto();

			try {
				setLoading(true);

				const request = new GetReorderListRequest({ status: activeFilter, pagination: pagination, contains: contains });

				const response = await reorderService.getReorderList(request);
				if (!response.data || !response.isSuccess) throw '';

				setReorders(response.data);
				setPaginationResponse(response.pagination);
			} catch (error) {
				setReorders([]);
			} finally {
				setLoading(false);

				dispatch(setForAdminReorderPaginationInfo(JSON.parse(JSON.stringify(newPagination))));
			}
		};

		const onPageChangeEvent = async (event: DataTablePFSEvent) => {
			let newPagination = new PaginationDto();

			if (!!fromStatePagination.itemCount && fromStatePagination.first === event.first && fromStatePagination.itemCount === event.rows) {
				newPagination = fromStatePagination;
			} else {
				newPagination.itemCount = event.rows;
				newPagination.pageNumber = !!event.page ? event.page + 1 : 1;
				newPagination.first = event.first;

				const serializeData = JSON.stringify(newPagination);
				dispatch(setForAdminReorderPaginationInfo(JSON.parse(serializeData)));
			}

			await getReOrders(newPagination);
		};

		useEffect(() => {
			getReOrders(new PaginationDto(1, 10));
		}, [activeFilter]);

	return (
		<main className="container relative">
			<section className="contianer-header">
				<h1 className="container-header-title">Re Orders</h1>
			</section>

			<div className="container-body p-3">
				<h5>Statu Filter</h5>

				<div className="flex flex-wrap gap-2">
					<div
						role="button"
						tabIndex={0}
						className={`text-sm px-3 py-2 border-1 border-round	cursor-pointer font-medium ${activeFilter === ReorderStatusType.Unknown ? 'surface-100' : 'border-400 text-color-secondary'}`}
						onClick={() => {
							if (activeFilter === ReorderStatusType.Unknown) return;

							setActiveFilter(ReorderStatusType.Unknown);
						}}>
						All
					</div>

					{enumToArrayHelper.convertArray(ReorderStatusType, true).map((_statu, index) => (
						<div
							key={index}
							role="button"
							tabIndex={0}
							className={`text-sm px-3 py-2 border-1 border-round	cursor-pointer font-medium ${activeFilter === _statu.value ? 'surface-100' : 'border-400 text-color-secondary'}`}
							onClick={() => {
								if (activeFilter === _statu.value) return;

								setActiveFilter(_statu.value as ReorderStatusType);
							}}>
							{stringHelper.parseAtUpperCaseAndJoin(_statu.label)}
						</div>
					))}
				</div>
				<div className="mt-4">
					<SearchInput value={contains} onChange={(e) => setContains(e.target.value)} onKeyDown={(event) => event.key === 'Enter' && getReOrders()} filterBtn disabled={loading} placeholder="Search reorders" filterBtnOnClick={() => getReOrders()} />
				</div>
			</div>

			<div className="container-body p-0">
				<DataTable
					value={reorders}
					lazy
					dataKey="reorderId"
					showGridlines
					selectionMode="single"
					className="pr-table"
					currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					totalRecords={paginationResponse?.totalItemCount}
					rowsPerPageOptions={[5, 10, 15, 20]}
					paginator
					rows={fromStatePagination.itemCount || 5}
					onPage={onPageChangeEvent}
					first={fromStatePagination.first}
					onSelectionChange={(event) => {
						const value: ReorderListDto = event.value;
						if ((value.status !== ReorderStatusType.WaitingForAdminReview) && !!value.oldOrderId) return;
						navigate(`/re-orders/${value.id}`, { state: { status: value.status } });
					}}>
					<Column field="code" header="Code" style={{ width: '12rem' }} />
					<Column field="sellerFullName" header="Seller" />
					<Column field="storeName" header="Store" />
					<Column field="customerFullName" header="Customer" />
					<Column field="count" header="Item" body={(row: ReorderListDto) => row.items.length + ' items'} />
					<Column field="statu.readableName" header="Status" body={(row: ReorderListDto) => stringHelper.parseAtUpperCaseAndJoin(ReorderStatusType[row.status])} />
				</DataTable>
			</div>

			<PrLoading loading={loading} />
		</main>
	);

}
