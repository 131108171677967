export class GetCoreProductsForStockPrintCenterRequest {
	productId: string;
	printCenterId: string;

	get getConvertUriExtension() {
		return `get-core-product-for-stock/print-center?ProductId=${this.productId}&PrintCenterId=${this.printCenterId}`;
	}

	constructor(productId: string, printCenterId: string) {
		this.productId = productId;
		this.printCenterId = printCenterId
	}
}