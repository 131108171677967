import { SyncEtsyProduct } from './sync-etsy-product.dto';

export class CreateModelRequestDto {
	sellerId: string;
	orderId: string;
	products: SyncEtsyProduct[];
	sellerNote: string | null;

	constructor(props: Partial<CreateModelRequestDto>) {
		this.sellerId = props.sellerId || '';
		this.orderId = props.orderId || '';
		this.products = props.products || [];
		this.sellerNote = props.sellerNote || null;
	}
}
