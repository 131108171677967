import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React from 'react';
import { SyncOrderContext, SyncOrderContextType, orderSteps } from './context/SyncOrderContextProvider';
import { Steps } from './steps/Steps';

export const SyncOrder = () => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	return (
		<div className="container relative">
			<div className="container-header">
				<div className="w-full">
					<h1 className="container-header-title">Your Order</h1>
					<div className="mt-1">You can use this screen to match your Etsy order with Printram order</div>
				</div>

				<div className="container-header-tools">
					{/* <GoBackButton /> */}

					{context.orderStep.value !== 1 ? <PrButton text="Go Back" icon={<span className="pi pi-fw pi-arrow-left" />} type="secondary" onClick={() => context.setOrderStep(orderSteps[context.orderStep.value - 2])} /> : null}
				</div>
			</div>

			<Steps />

			{context.orderStep.component}

			<PrLoading loading={context.loading} text={context.loadingText} />
		</div>
	);
};
