export class GetShippingRatesForReorderRequest {
	private newOrderId: string;

	constructor(newOrderId: string) {
		this.newOrderId = newOrderId;
	}

	get getConvertUriExtension() {
		return `get-shipping-rates/${this.newOrderId}`;
	}
}
