import { EntryDto } from 'features/stocks/_common/dtos/entry.dto';
import { OutDto } from 'features/stocks/_common/dtos/out.dto';
import { PriceDto } from 'features/stocks/_common/dtos/price.dto';

export class CreateForAdminRequest {
	entries: EntryDto[] = [];
	outs: OutDto[] = [];
	prices: PriceDto[] = [];

	constructor(entries: EntryDto[], outs: OutDto[], prices: PriceDto[]) {
		this.entries = entries;
		this.outs = outs;
		this.prices = prices;
	}

	get getConvertUriExtension() {
		return `product-variant-stocks/printram/create`;
	}
}
