import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetPrintCenterListForAdminRequest {
	pagination: PaginationDto;

	get getConvertUriExtension() {
		return `get-print-center-list-for-admin`;
	}

	constructor(pagination?: PaginationDto) {
		this.pagination = pagination || new PaginationDto();
	}
}
