import { ImageMediaBase } from 'features/medias/dtos/bases/image-media.base.dto';
import { ProductVariantPrice } from '../../../../models/requests/integrations/push-etsy-listing';
import { GetEtsyInformationsForPushResponse } from '../get-etsy-informations-for-push/get-etsy-informations-for-push.response';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';

export class PersonalizationDto {
	isPersonalizable: boolean;
	personalizationIsRequired: boolean;
	personalizationCharCountMax: number;
	personalizationInstructions: string | null;

	constructor(props: Partial<PersonalizationDto>) {
		this.isPersonalizable = props.isPersonalizable || false;
		this.personalizationIsRequired = props.personalizationIsRequired || false;
		this.personalizationCharCountMax = typeof props.personalizationCharCountMax === 'undefined' ? 256 : props.personalizationCharCountMax;
		this.personalizationInstructions = props.personalizationInstructions || null;
	}
}

export class PushEtsyListingRequest {
	shopId: number;
	productId: string;
	userId: string;
	mainMediaId: string;
	mediaIdentities: string[];
	title: string;
	description: string;
	productVariantPrices: ProductVariantPrice[];
	tags: string[] | null;
	occasionId: number | null;
	holidayId: number | null;
	shopSectionId: number | null;
	shippingProfileId: number | null;
	variationCombinationName: string | null;
	personalization: PersonalizationDto;

	constructor(props: Partial<PushEtsyListingRequest>) {
		this.shopId = props.shopId || 0;
		this.productId = props.productId || '';
		this.userId = props.userId || '';
		this.mainMediaId = props.mainMediaId || '';
		this.mediaIdentities = props.mediaIdentities || [];
		this.title = props.title || '';
		this.description = props.description || '';
		this.productVariantPrices = props.productVariantPrices || [];
		this.tags = props.tags || null;
		this.occasionId = props.occasionId || null;
		this.holidayId = props.holidayId || null;
		this.shopSectionId = props.shopSectionId || null;
		this.shippingProfileId = props.shippingProfileId || null;
		this.variationCombinationName = props.variationCombinationName || null;
		this.personalization = props.personalization || new PersonalizationDto({});
	}

	get getConvertUriExtension() {
		return 'push-listing-by-product-id';
	}

	initValues(response: GetEtsyInformationsForPushResponse, images: MyProductMedia[]) {
		this.productId = response.copiedProduct.id;
		this.shopId = response?.stores?.[0]?.etsyStore?.shopId || 0;
		this.title = response.copiedProduct.name;
		this.description = response.copiedProduct.description;
		this.variationCombinationName = response?.variantCombinations?.combinations?.[0]?.combinationName || '';

		if (images.length > 0)
			for (const image of images) {
				if (this.mediaIdentities.length > 8) break;

				if (!this.mainMediaId) this.mainMediaId = image.imageWithStorage?.id || image.imageWithUrl!.id;
				this.mediaIdentities.push(image.imageWithStorage?.id || image.imageWithUrl!.id);
			}

		// this.mainMediaId = images?.[0]?.id || '';
		// this.mediaIdentities = images.slice(0, 9).map((_image) => _image.id) || [];
	}
}
