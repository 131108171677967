import { useCallback, useEffect, useState, useRef } from 'react';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import TreeNode from 'primereact/treenode';
import { TreeSelect, TreeSelectSelectionKeys } from 'primereact/treeselect';
import { useAppSelector } from '../../../../../../redux/hooks';
import { User } from '../../../../../../models/dtos/auth/users/user';
import DropdownInput from '../../../../../../helpers/widgets/Forms/DropdownInput';
import TextInput from '../../../../../../helpers/widgets/Forms/TextInput';
import { Skeleton } from 'primereact/skeleton';
import BrandCreate from '../../../../productManagement/brands/components/BrandCreate';
import { UpdateRequest } from 'features/products/derived-features/merchant-products/update/update.request';
import { ProductDetailForUpdate, ProductForUpdateBaseDto } from 'features/products/dtos/bases/product-for-update.base.dto';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import { GetMerchantProductForUpdateByProductRequest } from 'features/products/derived-features/merchant-products/get-merchant-product-for-update-by-product/get-merchant-product-for-update-by-product.request';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import { GetModelsByBrandRequest } from 'features/models/_common/get-models-by-brand/get-models-by-brand.request';
import modelService from 'features/models/_common/model.service';

const StepOne = ({ setStep, step }: any) => {
	const { productId } = useParams();
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const formikRef = useRef<FormikProps<UpdateRequest>>(null);
	const creatableRef = useRef<any>(null);
	const [product, setProduct] = useState<ProductForUpdateBaseDto | null>();
	const [brandsOptions, setBrandsOptions] = useState<SelectItemOptionsType>();
	const [selectedBrand, setSelectedBrand] = useState<string>();
	const [modelsOptions, setModelsOptions] = useState<SelectItemOptionsType>();
	const [treeCategories, setTreeCategories] = useState<TreeNode[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<TreeSelectSelectionKeys>();
	const [visibleCreateBrand, setVisibleCreateBrand] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [modelLoading, setModelLoading] = useState(false);

	const initialValues: UpdateRequest = new UpdateRequest(userId, productId || '', { modelId: product?.product.modelId }, null, product?.product.name || '', product?.product.description || '', product?.product.productStatuId || 0);

	const validationScheme = Yup.object().shape({
		model: Yup.object().nullable().required('Please select a model'),
		name: Yup.string().required('Name is Required').min(3, 'Name must be at least 3 characters.').max(100, 'Name must be a maximum of 100 characters.'),
		description: Yup.string().required('Description is Required').min(20, 'Description must be at least 20 characters.')
	});

	const modelChange = (selectedModel: any) => {
		if (!formikRef.current || !selectedModel) return;

		formikRef.current.setFieldValue('model', {});
		if (selectedModel.__isNew__) formikRef.current.setFieldValue('model.createModel', { name: selectedModel.value, brandId: selectedBrand || product?.product.modelAndBrand.brandId });
		else formikRef.current.setFieldValue('model.modelId', selectedModel.value);
	};

	const submitForm = async (values: UpdateRequest) => {
		try {
			setSubmitLoading(true);

			let req = values;

			if (!values.model.modelId && !values.model.createModel) {
				req = new UpdateRequest(values.merchantId, values.productId, { modelId: product?.product.modelId }, values.categoryIdentities, values.name, values.description, values.productStatuId);
			}

			const response = await merchantProductsService.update(req);

			if (!response.isSuccess) throw '';

			getProductForUpdate();
		} finally {
			setSubmitLoading(false);
		}
	};

	const generateTreeNode = (category: CategoryBaseDto): TreeNode => {
		const treeNodeForCurrentSubCategory: TreeNode = { key: category.id, label: category.name, selectable: category.canBeAddProduct };
		return treeNodeForCurrentSubCategory;
	};

	const mapCategoryToParent = useCallback((parentCategory: CategoryBaseDto, treeNode: TreeNode) => {
		if (parentCategory.subCategories === undefined) return;

		parentCategory?.subCategories?.forEach((_subCategory) => {
			if (treeNode.children === undefined) treeNode.children = [];

			const treeNodeForCurrentSubCategory = generateTreeNode(_subCategory);

			treeNode.children?.push(treeNodeForCurrentSubCategory);

			mapCategoryToParent(_subCategory, treeNodeForCurrentSubCategory);
		});
	}, []);

	const mapCategoriesForTree = useCallback(
		(categories: CategoryBaseDto[]) => {
			let treeNode: TreeNode[] = [];

			categories.forEach((_category) => {
				const treeNodeForCurrentCategory = generateTreeNode(_category);

				mapCategoryToParent(_category, treeNodeForCurrentCategory);

				treeNode.push(treeNodeForCurrentCategory);
			});

			return treeNode;
		},
		[mapCategoryToParent]
	);

	const setFormikInitials = (formikProduct: ProductDetailForUpdate) => {
		if (!formikRef?.current) return;

		let merchantId = userId;
		let prodId = formikProduct.id;
		let model = { modelId: formikProduct.modelId };
		let name = formikProduct.name;
		let description = formikProduct.description;
		let productStatuId = formikProduct.productStatuId;
		let categoryIdentities = formikProduct.categoryIdentities;
		let formikValues = new UpdateRequest(merchantId, prodId, model, categoryIdentities, name, description, productStatuId);

		formikRef.current.setValues(formikValues);
	};

	const getModelsByBrand = async () => {
		try {
			if (!selectedBrand) throw '';

			setModelLoading(true);

			const request = new GetModelsByBrandRequest(selectedBrand);

			const response = await modelService.getModelsByBrand(request);

			if (!response.isSuccess) throw '';

			const options = response.data?.map((model) => ({ label: model.modelName, value: model.modelId, isDisabled: model.isHaveAnyProduct, __isNew__: false } as SelectItem));
			if (!options) return;

			setModelsOptions(options);
		} catch {
			setModelsOptions([]);
		} finally {
			setModelLoading(false);
		}
	};

	const getProductForUpdate = useCallback(async () => {
		if (!productId) return;

		try {
			setLoading(true);

			const request = new GetMerchantProductForUpdateByProductRequest();
			request.productId = productId;

			const response = await merchantProductsService.getMerchantProductForUpdate(request);

			if (!response.isSuccess) throw '';

			const options = response.data?.brands?.map((brand: any) => ({ label: brand.statu === 1 ? brand.name + ' (pending)' : brand.name, value: brand.id, disabled: brand.statu !== 3 } as SelectItem));
			if (!options) return;

			setBrandsOptions(options);
			setSelectedBrand(response.data?.product.modelAndBrand.brandId);
			setTreeCategories(mapCategoriesForTree(response.data?.categories || []));

			let cats = {};
			response.data?.product.categoryIdentities.forEach((cat) => {
				cats = { ...cats, [cat]: true };
			});
			setSelectedCategories(cats);
			setProduct(response.data);
		} finally {
			setLoading(false);
		}
	}, [productId]);

	useEffect(() => {
		getProductForUpdate();
	}, [getProductForUpdate]);

	useEffect(() => {
		if (!product) return;

		setFormikInitials(product.product);
	}, [product]);

	useEffect(() => {
		if (!creatableRef.current) return;

		creatableRef.current.commonProps.clearValue();
		formikRef.current?.setFieldValue('model', {});
	}, [modelsOptions]);

	useEffect(() => {
		if (!selectedCategories) return;

		formikRef.current?.setFieldValue('categoryIdentities', Object.keys(selectedCategories));
	}, [selectedCategories]);

	useEffect(() => {
		if (!selectedBrand) {
			setModelsOptions([]);
			return;
		}

		formikRef.current?.setFieldValue('model', {});

		getModelsByBrand();
	}, [selectedBrand, userId]);

	return (
		<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
			{({ values, handleChange }) => (
				<Form>
					<div className="grid">
						{!loading ? (
							<div className="col-12 lg:col-8">
								<div className="grid mb-2">
									<div className="col-8 lg:col-4">
										<DropdownInput label="Brand" name="model.createModel.brandId" options={brandsOptions} value={selectedBrand} disabled={loading} onChange={(e) => setSelectedBrand(e.value)} placeholder="Select a brand" />
									</div>

									<div className="col-4 lg:col-2 flex align-items-end">
										<Button type="button" onClick={(_) => setVisibleCreateBrand(true)} icon="pi pi-plus-circle" label="Brand" className="p-button-outlined p-button-md px-5" />
									</div>

									<div className="col-12 lg:col-6">
										<div className="mb-1 px-1 flex align-items-center justify-content-between">
											<label>Model</label>
											<ErrorMessage name="model.modelId" component="small" className="text-red font-medium" />
										</div>

										<CreatableSelect ref={creatableRef} options={modelsOptions} placeholder={product?.product.modelAndBrand.modelName} isClearable isLoading={modelLoading} isDisabled={modelLoading} onChange={(event) => modelChange(event)} />
									</div>
								</div>

								<div className="grid mb-2">
									<div className="col-12 lg:col-6">
										<div className="mb-1 px-1 flex align-items-center justify-content-between">
											<label>Category</label>
											<ErrorMessage name="categoryIdentities" component="small" className="text-red font-medium" />
										</div>

										<TreeSelect filter value={selectedCategories} options={treeCategories} selectionMode="multiple" metaKeySelection={false} onChange={(event) => setSelectedCategories(event.value)} placeholder="Select Item" className="w-full" />
									</div>

									<div className="col-12 lg:col-6">
										<TextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Enter product name" />
									</div>
								</div>

								<div className="grid mb-2">
									<div className="col-12">
										<div className="mb-1 px-1 flex align-items-center justify-content-between">
											<label>Description</label>
											<ErrorMessage name="description" component="small" className="text-red font-medium" />
										</div>

										<InputTextarea rows={10} name="description" value={values.description} onChange={handleChange} placeholder="Enter description" className="w-full" />
									</div>
								</div>

								<div className="grid">
									<div className="col-12 text-right">
										<Button type="submit" icon="pi pi-save" label="Save" loading={submitLoading} className="px-5" />
									</div>
								</div>
							</div>
						) : (
							<div className="col-12 lg:col-8">
								<div className="grid mb-2">
									<div className="col-12">
										<Skeleton height="5rem" />
									</div>
								</div>

								<div className="grid mb-2">
									<div className="col-12">
										<Skeleton height="5rem" />
									</div>
								</div>

								<div className="grid mb-2">
									<div className="col-12">
										<Skeleton height="6rem" />
									</div>
								</div>

								<div className="grid">
									<div className="col-12">
										<Skeleton height="6rem" />
									</div>
								</div>
							</div>
						)}
					</div>
					<BrandCreate isVisible={visibleCreateBrand} setVisible={setVisibleCreateBrand} getBrands={getProductForUpdate} />
				</Form>
			)}
		</Formik>
	);
};

export default StepOne;
