import HttpBaseService from "services/_common/http-base-service";
import { GetPlansForListResponse } from "./get-plans-for-list/get-plans-for-list.response";
import { GetPlansForListRequest } from "./get-plans-for-list/get-plans-for-list.request";
import { CreatePlanWithOrderDomainsRequest } from "./create-plan-with-other-domains/create-plan-with-other-domains.request";
import { CreatePlanWithOrderDomainsResponse } from "./create-plan-with-other-domains/create-plan-with-other-domains.response";
import { PlanDeleteRequest } from "./soft-delete/soft-delete.request";
import { PlanDeleteResponse } from "./soft-delete/soft-delete.response";
import { GetPlanByIdRequest } from "./get-by-id/get-by-id.request";
import { GetPlanByIdResponse } from "./get-by-id/get-by-id.response";
import { PlanUpdateRequest } from "./update/update.request";
import { PlanUpdateResponse } from "./update/update.response";
import { GetPlansRequest } from "./get-plans/get-plans.request";
import { GetPlansResponse } from "./get-plans/get-plans.response";

class PlansService extends HttpBaseService {
	constructor() {
		super('plans');
	}

	async getPlans(request: GetPlansRequest): Promise<GetPlansResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async getPlansForList(request: GetPlansForListRequest): Promise<GetPlansForListResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getPlanById(request: GetPlanByIdRequest): Promise<GetPlanByIdResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async create(request: CreatePlanWithOrderDomainsRequest): Promise<CreatePlanWithOrderDomainsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async update(request: PlanUpdateRequest): Promise<PlanUpdateResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async delete(request: PlanDeleteRequest) {
		return await this.deleteData(request.getConvertUriExtension, undefined, request);
	}
}

export default new PlansService();
