import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SurveyGetResultRequest } from 'features/surveys/_common/survey-get-result/survey-get-result.request';
import { SurveyResultUserDto } from 'features/surveys/_common/survey-get-result/survey-get-result.response';
import surveyService from 'features/surveys/_common/survey.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import styles from './SellersAnswers.module.scss';

const SellersAnswers = () => {
	const { surveyName, surveyId } = useParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [surveyResults, setSurveyResults] = useState<SurveyResultUserDto[]>([]);
	const [selectedUser, setSelectedUser] = useState<SurveyResultUserDto | undefined>(undefined);

	const getResults = async () => {
		try {
			setLoading(true);

			if (!surveyId) throw '';

			const response = await surveyService.getResults(new SurveyGetResultRequest(surveyId));
			if (!response.isSuccess || !response.data) throw '';

			setSurveyResults(response.data.users);
		} catch (error) {
			setSurveyResults([]);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectedUser = (user: SurveyResultUserDto) => {
		setSelectedUser((current) => {
			let newUser = !!current ? { ...current } : undefined;

			newUser = current?.userId === user.userId ? undefined : user;

			return newUser;
		});
	};

	useEffect(() => {
		if (!surveyId) return;

		getResults();

		return () => {
			setSelectedUser(undefined);
			setSurveyResults([]);
		};
	}, [surveyId]);

	return (
		<div className="grid">
			<div className="col-12">
				<h4>{surveyName}</h4>
			</div>

			<div className="col-12 lg:col-4 relative">
				<div className="card">
					<h5>Users</h5>

					<div className={styles.userWrapper}>
						{!!surveyResults && surveyResults.length > 0
							? surveyResults.map((_result) => (
									<div onClick={(e) => handleSelectedUser(_result)} role="button" tabIndex={0} key={_result.userId} className={styles.userItem}>
										<h6>{_result.fullName}</h6>

										<h6>{_result.email}</h6>
									</div>
							  ))
							: null}
					</div>
				</div>
			</div>

			{!!selectedUser ? (
				<div className="col-12 lg:col-8">
					<div className="card">
						<h5>{selectedUser.fullName}s' Answers</h5>

						<div className={styles.answerWrapper}>
							{selectedUser.questions.map((_question) => (
								<div key={_question.id} className={styles.answerItem}>
									<h6>
										Question: <span className="font-normal">{_question.question}</span>
									</h6>
									<h6>
										Answer: <span className="font-normal">{_question.answer.text || _question.answer.optionText}</span>
									</h6>
								</div>
							))}
						</div>
					</div>
				</div>
			) : null}

			{loading ? (
				<div className="custom-loading">
					<ProgressSpinner className="p-progress-color" strokeWidth="4" />
				</div>
			) : null}
		</div>
	);
};

export default SellersAnswers;
