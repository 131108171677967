import {useContext, useEffect, useRef, useState} from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate} from 'react-router-dom';
import { Button } from 'primereact/button';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import React from 'react';
import {Ripple} from "primereact/ripple";
import toastHelper from "../helpers/toast.helper";
import {Auth} from "../models/dtos/auth/auth";
import person from "../assets/icons/user.svg";
import security from "../assets/icons/shield-tick.svg";
import setting from "../assets/icons/setting.svg";
import {logout} from "../redux/features/auth/authSlice";
import login from "../assets/icons/login.svg";
import {Tooltip} from "primereact/tooltip";
import AvatarImage from '../helpers/widgets/AvatarImage';
import {CSSTransition} from "react-transition-group";
import {RTLContext} from "../App";

const AppTopbar = (props: any) => {
	const { user, accessToken } = useAppSelector((state) => state.auth.data || ({} as Auth));

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const menuKey = props.menuKey || 'inline-menu';
	const dangerColor = {color: "#EF4444"};
	const isRTL = useContext(RTLContext);

	const menuRef = useRef<HTMLUListElement>(null);
	const [menuStyle, setMenuStyle] = useState<React.CSSProperties>({});

	// Fixed for 6.1.0
	// eslint-disable-next-line
	//const searchPanel = useRef(null);

	useEffect(() => {
		// Fixed for 6.1.0
		/*if (props.searchActive) {
			searchPanel.current.element.focus();
		}*/
	}, [props.searchActive]);

	const isSlim = () => {
		return props.menuMode === 'slim';
	};

	const redirectHandler = () => {
		if(user?.roles.isSeller) {
			if (user?.phoneNumbers && user.phoneNumbers.length > 0) {
				navigate('/create-order');
			} else {
				toastHelper.warning('You must be able to register your phone number in order to create an order.');
				navigate('/account/details');
			}
		}
	};

	/*const menu = menuHelper.getMenuByRoles(user).map((item) => {
		item.items.map((child: any) => {
			child.command = () => navigate(child.to);
			return child;
		});
		return item;
	});

	const model = [
		{
			label: 'PAGES',
			items: [menu]
		}
	];*/

	return (
		<div className="layout-topbar">
			<div className="layout-topbar-left">
				<button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
					<img id="app-logo" src="assets/layout/images/logo-light.svg" alt="Printram Logo"  />
				</button>

				<button type="button" className="flex lg:hidden layout-topbar-mobile-button p-link">
					<i className="pi pi-align-justify fs-large" onClick={props.onMenuButtonClick}></i>
				</button>
			</div>

			<div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
				{/*<div className="layout-topbar-actions-left">
					<MegaMenu model={model} className="layout-megamenu" />
				</div>*/}
				<div className="layout-topbar-actions-right">
					<ul className="layout-topbar-items">
						{user?.roles.isSeller && (
							<li className="layout-topbar-item">
								<Button className="p-button layout-new-order-button" style={{color: 'white'}}
										onClick={redirectHandler}>
									<span className="p-button-label p-c">New Order</span>
									<Ripple/>
								</Button>
							</li>
						)}
						<li className="layout-topbar-item">
							<button
								className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link"
								onClick={(e) => props.onInlineMenuClick(e, menuKey)}>
								<AvatarImage/>
							</button>

							<CSSTransition classNames="slide-vertical" timeout={{enter: 300, exit: 200}}
										   in={props.inlineMenuActive[menuKey]} unmountOnExit>
								<ul className="layout-topbar-action-panel">
									{!user?.roles.isPackager && (
										<React.Fragment>
											<li onClick={() => navigate('/account/details')}
												className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<img src={person} alt=""/>
													<span>My Account</span>
												</button>
											</li>
											{user?.roles.isSeller && (
												<React.Fragment>
													{/* <li onClick={() => navigate('/account/connections')} className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-arrow-right-arrow-left', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Connections</span>
                                        </button>
                                    </li> */}

													{/*<li onClick={() => navigate('/account/my-stores')} className="layout-topbar-action-item">
														<button className="flex flex-row align-items-center p-link">
															<i className={classNames('pi pi-building', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
															<span>My Stores</span>
														</button>
													</li>*/}
													{/* <li onClick={() => navigate('/account/credit-cards')} className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-credit-card', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Payment Methods</span>
                                        </button>
                                    </li> */}

													{/* <li onClick={() => navigate('/account/plans')} className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-id-card', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Plans</span>
                                        </button>
                                    </li>
                                    <li onClick={() => navigate('/account/payments')} className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-wallet', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>Payments</span>
                                        </button>
                                    </li> */}
												</React.Fragment>
											)}
											<li onClick={() => navigate('/account/security')}
												className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<img src={security} alt=""/>
													<span>Security</span>
												</button>
											</li>
											<li onClick={() => navigate('/account/settings')}
												className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<img src={setting} alt=""/>
													<span>Settings</span>
												</button>
											</li>
										</React.Fragment>
									)}
									<li onClick={() => dispatch(logout())} className="layout-topbar-action-item">
										<button className="flex flex-row align-items-center p-link" style={dangerColor}>
											<img src={login} alt=""/>
											<span>Logout</span>
										</button>
									</li>
								</ul>
							</CSSTransition>
						</li>
					</ul>
				</div>

			</div>
		</div>
	);
};

export default AppTopbar;
