import { Button } from 'primereact/button'
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem'
import React, { useCallback, useEffect, useState } from 'react'

import PlanCreate from './components/PlanCreate'
import { GetPlansForListRequest } from 'features/plans/_common/get-plans-for-list/get-plans-for-list.request'
import plansService from 'features/plans/_common/plans.service'
import { DataTable } from 'primereact/datatable'
import { PlanDto } from 'features/plans/_common/dtos/plan.dto'
import { Column } from 'primereact/column'
import currencyHelper from 'helpers/curreny.helper'
import { Checkbox } from 'primereact/checkbox'
import DropdownButton from 'helpers/widgets/DropdownButton'
import { PlanDeleteRequest } from 'features/plans/_common/soft-delete/soft-delete.request'
import PlanEdit from './components/PlanEdit'

const Plans = () => {
	const [plans, setPlans] = useState<PlanDto[] | null>([])
	const [planOptions, setPlanOptions] = useState<SelectItemOptionsType | undefined>()
	const [editedPlan, setEditedPlan] = useState<string>()
	const [loading, setLoading] = useState(false)
	const [visibleCreatePlan, setVisibleCreatePlan] = useState(false)
	const [visibleEditPlan, setVisibleEditPlan] = useState(false);

	const priceBodyTemplate = (rowData: PlanDto) => {
		const monthlyPrice = currencyHelper.formatPrice(rowData.priceAsMonthly.formattedPricePerUnit) + '/Month';
		const yearlyPrice = currencyHelper.formatPrice(rowData.priceAsYearly.formattedPricePerUnit) + '/Year';

		return monthlyPrice + ' - ' + yearlyPrice
	}

	const descriptionBodyTemplate = (rowData: PlanDto) => rowData.description.slice(0, 50) + (rowData.description.length > 50 ? '...' : '')

	const buyableBodyTemplate = (rowData: PlanDto) => <Checkbox checked={rowData.isBuyable} disabled />;

	const deletePlan = async(planId: string) => {
		try {
			const request = new PlanDeleteRequest(planId)

			const response = await plansService.delete(request)

			if (!response) throw ''

			getPlans()
		} finally { setLoading(false) }
	}

	const actionsTemplate = (rowData: PlanDto) => {
		const menuItems = [<button onClick={() => setEditedPlan(rowData.id)}>Edit</button>, <button onClick={() => deletePlan(rowData.id)}>Delete</button>];

		return <DropdownButton menuItems={menuItems} direction={'left'} icon="pi pi-cog" />;
	};

	const getPlans = useCallback(async() => {
		try {
			setLoading(true)

			const request = new GetPlansForListRequest()

			const response = await plansService.getPlansForList(request)

			if (!response.isSuccess) throw ''

			setPlans(response.data)

			const options = response.data?.map((plan) => ({ label: plan.name, value: plan.id } as SelectItem));

			setPlanOptions(options)
		} finally { setLoading(false) }
	}, [])

	useEffect(() => {
		if (!editedPlan) return;

		setVisibleEditPlan(true)
	},[editedPlan])

	useEffect(() => {
		getPlans()
	}, [getPlans])

  return (
		<div className="card">
			<div className="flex justify-content-between mb-3">
				<h5>Plans</h5>
				<Button onClick={() => setVisibleCreatePlan(true)} label="New Plan" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>

			<DataTable value={plans as any} paginator rows={10} dataKey="id" loading={loading} className="p-datatable-gridlines">
				<Column field="name" header="Plan Name" style={{ minWidth: '12rem' }} />
				<Column body={descriptionBodyTemplate} header="Description" style={{ minWidth: '12rem' }} />
				<Column body={priceBodyTemplate} header="Price" style={{ minWidth: '12rem' }} />
				<Column body={buyableBodyTemplate} header="Buyable" style={{ minWidth: '12rem' }} />
				<Column field="actions" header="Actions" body={actionsTemplate} />
			</DataTable>
			<PlanCreate visible={visibleCreatePlan} setVisible={setVisibleCreatePlan} planOptions={planOptions} getPlans={getPlans} />
			<PlanEdit visible={visibleEditPlan} setVisible={setVisibleEditPlan} planOptions={planOptions} getPlans={getPlans} editedPlanId={editedPlan || ''} setEditedPlan={setEditedPlan} />
		</div>
  );
}

export default Plans