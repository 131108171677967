import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";
import { ReorderStatusType } from "features/reorders/enums/reorder-status-type"

export class GetReorderListRequest {
	contains: string | null;
	status: ReorderStatusType | null;
	pagination: PaginationDto;

	constructor(props: Partial<GetReorderListRequest>) {
		this.contains = props.contains || null;
		this.status = props.status || null;
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return 'get-reorder-list';
	}
}