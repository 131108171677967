import React, { useEffect, useState } from 'react';
import { SurveyDto } from 'features/surveys/_common/survey-get/survey-get.response';
import surveyService from 'features/surveys/_common/survey.service';
import { SurveyGetAvailableByUserRequest } from 'features/surveys/_common/survey-get-available-by-user/survey-get-available-by-user.request';
import AvailableSurveyItemDialog from './AvailableSurveyItemDialog';
import { Button } from 'primereact/button';

type Props = {
	userId: string;
};

const AvailableSurveyList = (props: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [surveys, setSurveys] = useState<SurveyDto[]>([]);
	const [selectedSurvey, setSelectedSurvey] = useState<SurveyDto | undefined>(undefined);

	const getAvailableSurveys = async () => {
		try {
			setLoading(true);

			const response = await surveyService.getAvailableByUser(new SurveyGetAvailableByUserRequest(props.userId));
			if (!response.isSuccess || !response.data) throw '';

			setSurveys(response.data);
			setSelectedSurvey(response.data[0]);
		} catch (error) {
			setSurveys([]);
		} finally {
			setLoading(false);
		}
	};

	const handleVisisbleSurvey = (survey: SurveyDto) => {
		setSelectedSurvey(survey);
	};

	useEffect(() => {
		// getAvailableSurveys();
	}, []);

	return (
		<React.Fragment>
			{surveys.length > 0 ? (
				<div className="card">
					<h5>Available Surveys</h5>

					{!!selectedSurvey ? (
						<div className="grid px-1 pt-3">
							<div className="col-12">
								<h5 className="m-0">{selectedSurvey.name}</h5>
							</div>

							<div className="col-12">
								<AvailableSurveyItemDialog userId={props.userId} survey={selectedSurvey} callback={getAvailableSurveys} />
							</div>

							<div className="col-12 flex justify-content-center gap-2">
								{surveys.map((_survey, index) => (
									<div key={_survey.id} onClick={() => setSelectedSurvey(_survey)} className={`w-2rem h-2rem font-bold border-circle flex align-items-center cursor-pointer justify-content-center ${selectedSurvey.id === _survey.id ? 'bg-primary' : 'surface-200'}`}>
										{index + 1}
									</div>
								))}
							</div>
						</div>
					) : null}
				</div>
			) : null}
		</React.Fragment>
	);
};

export default AvailableSurveyList;
