import HttpBaseService from 'services/_common/http-base-service';
import { GetCopiedProductsBySellerRequest } from './get-copied-products-by-seller/get-copied-products-by-seller.request';
import { GetCopiedProductsBySellerResponse } from './get-copied-products-by-seller/get-copied-products-by-seller.response';
import { SaveAsDraftRequest } from './save-as-draft/save-as-draft.request';
import { SaveAsDraftResponse } from './save-as-draft/save-as-draft.response';
import { GetMyProductsRequest } from './get-my-products/get-my-products.request';
import { GetMyProductsResponse } from './get-my-products/get-my-products.response';
import { GetMyProductsBySellerRequest } from './get-my-products-by-seller/get-my-products-by-seller.request';
import { GetMyProductsBySellerResponse } from './get-my-products-by-seller/get-my-products-by-seller.response';
import { SoftDeleteCopiedProductRequest } from './soft-delete-copied-product/soft-delete-copied-product.request';
import { GetCopiedProductDetailRequest } from './get-copied-product-detail/get-copied-product-detail.request';
import { GetCopiedProductDetailResponse } from './get-copied-product-detail/get-copied-product-detail.response';

class ProductCopyBySellerService extends HttpBaseService {
	constructor() {
		super('product-copied-by-sellers');
	}

	async getMyProducts(request: GetMyProductsRequest): Promise<GetMyProductsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getMyProductDetail(request: GetCopiedProductDetailRequest): Promise<GetCopiedProductDetailResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getMyProductsBySeller(request: GetMyProductsBySellerRequest): Promise<GetMyProductsBySellerResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getCopiedProductsBySellerId(request: GetCopiedProductsBySellerRequest): Promise<GetCopiedProductsBySellerResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async saveAsDraft(request: SaveAsDraftRequest): Promise<SaveAsDraftResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async softDelete(request: SoftDeleteCopiedProductRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension, undefined, request, 'product');
	}
}

export default new ProductCopyBySellerService();
