export class AddStockLocationRequest {
	name: string;
	operationManager: string;
	pickUp: boolean;
	setDefault: boolean;
	countryId: number;
	stateId: number;
	zipCode: number;
	city: string;
	addressLine1: string;
	addressLine2: string;

	get getConvertUriExtension() {
		return 'add-stock-location';
	}

	constructor(props: Partial<AddStockLocationRequest>) {
		this.name = props.name || "";
		this.operationManager = props.operationManager || '';
		this.pickUp = props.pickUp || false;
		this.setDefault = props.setDefault || false;
		this.countryId = props.countryId || 0;
		this.stateId = props.stateId || 0;
		this.zipCode = props.zipCode || 0;
		this.city = props.city || '';
		this.addressLine1 = props.addressLine1 || '';
		this.addressLine2 = props.addressLine2 || '';
	}
}