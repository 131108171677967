import HttpBaseService from "services/_common/http-base-service";
import { GetCategoryTransportInformationByCategoryRequest } from "./get-category-transport-informations-by-category/get-category-transport-information-by-category.request";
import { GetCategoryTransportInformationByCategoryResponse } from "./get-category-transport-informations-by-category/get-category-transport-information-by-category.response";
import { CreateCategoryRequest } from "features/categories/_common/create-category/create-category.request";
import { CreateCategoryTransportInformationsRequest } from "./create-category-transport-informations/create-category-transport-informations.request";
import { CreateCategoryTransportInformationsResponse } from "./create-category-transport-informations/create-category-transport-informations.response";


class CategoryTransportInformationsService extends HttpBaseService {
	constructor() {
		super('category-transport-informations');
	}

	async getTransportInformationsByCategory(request: GetCategoryTransportInformationByCategoryRequest): Promise<GetCategoryTransportInformationByCategoryResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async create(request: CreateCategoryTransportInformationsRequest): Promise<CreateCategoryTransportInformationsResponse> {
		return await this.postData('create-category-transport-informations', request);
	}
}

export default new CategoryTransportInformationsService();
