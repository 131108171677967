import { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { prefixOptions } from '../create-manuel-order/create-manuel-order.request';

export class UpdateOrderAddressRequest {
	id: string;
	firstLine: string;
	secondLine: string | null;
	city: string;
	state: string;
	zip: string;
	countryIso: string;
	formattedAddress: string;
	receiverFullName: string;
	receiverEmail: string | null;
	phonePrefix: PrDropdownType | null;
	receiverPhoneNumber: string | null;
	vatNumber: string | null;

	constructor(props: Partial<UpdateOrderAddressRequest>) {
		this.id = props.id || '';
		this.firstLine = props.firstLine || '';
		this.secondLine = props.secondLine || null;
		this.city = props.city || '';
		this.state = props.state || '';
		this.zip = props.zip || '';
		this.countryIso = props.countryIso || '';
		this.formattedAddress = props.formattedAddress || '.';
		this.receiverFullName = props.receiverFullName || '';
		this.receiverEmail = props.receiverEmail || null;
		this.phonePrefix = props.phonePrefix || prefixOptions[0];
		this.vatNumber = props.vatNumber || null;
		this.receiverPhoneNumber = props.receiverPhoneNumber || null;
	}

	get getConvertUriExtension() {
		return `addresses`;
	}
}
