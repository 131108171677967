import { OperatorDTO } from '../dtos/operator_dto';

export class UpdatePrintCenterOperatorRequest {
	id: number;
	name: string;
	code: string;

	constructor(props: OperatorDTO) {
		this.id = props.id;
		this.name = props.name;
		this.code = props.code;
	}

	getConvertUriExtension() {
		return `update`;
	}
}