import { Dropdown, DropdownProps } from 'primereact/dropdown';
import React from 'react';

interface Props extends DropdownProps {
	label: string;
	description: string;
	required: boolean;
}

const DropdownInput = (props: Partial<Props>) => {
	return (
		<div className={`pr-input-group w-full ${props.className}`}>
			<label htmlFor={props.inputId || ''} className="pr-input-label">
				<h5 className="pr-input-label-title">
					{props.label || ''} {typeof props.required !== 'undefined' ? !props.required ? <span className="pr-input-label-optional">(Optional)</span> : <span className="pr-input-label-required">*</span> : null}
				</h5>

				{props.description ? <p className="pr-input-label-description">{props.description}</p> : null}
			</label>

			<Dropdown {...props} className="w-full" />
		</div>
	);
};

export default DropdownInput;
