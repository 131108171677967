import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import { DeliveryTypes } from '../create-manuel-order/create-manuel-order.request';

export class GetOrdersDateFilterRequestDto {
	minDate: Date | null;
	maxDate: Date | null;

	constructor(props: Partial<GetOrdersDateFilterRequestDto>) {
		this.minDate = props.minDate || null;
		this.maxDate = props.maxDate || null;
	}
}

export class GetOrdersForAdminRequest {
	storeId: string | null;
	includeStores: boolean;
	contains: string | null;
	isListOnlyAssignedOrders: boolean | null;
	orderStatu: OrderStatuTypes | null;
	dateFilter: GetOrdersDateFilterRequestDto;
	pagination: PaginationDto;
	includeLabelServiceNames: boolean;
	labelServiceName: string | null;
	deliveryType: DeliveryTypes | null;

	constructor(props: Partial<GetOrdersForAdminRequest>) {
		this.storeId = props.storeId || null;
		this.includeStores = props.includeStores || false;
		this.contains = props.contains || null;
		this.isListOnlyAssignedOrders = props.isListOnlyAssignedOrders || null;
		this.orderStatu = props.orderStatu || null;
		this.dateFilter = props.dateFilter || new GetOrdersDateFilterRequestDto({});
		this.pagination = props.pagination || new PaginationDto(1, 20);
		this.includeLabelServiceNames = props.includeLabelServiceNames || false;
		this.labelServiceName = props.labelServiceName || null;
		this.deliveryType = props.deliveryType || null;
	}

	get getConvertUriExtension() {
		return `get-orders-for-admin`;
	}
}
