import React, { CSSProperties } from 'react';
import { useSearchParams } from 'react-router-dom';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import stringHelper from 'helpers/string.helper';
import { ProductStatu } from 'features/products/dtos/enums/product-statu.enum';
import styles from './OrderFilterTab.module.scss';

type Props = {
	filterProductStatuType: ProductStatu | null;
	setFilterProductStatuType: (state: ProductStatu | null) => void;
	name: string;
};

const ProductFilterTab = ({ filterProductStatuType, setFilterProductStatuType, name }: Props) => {
	const [_, setSearchParams] = useSearchParams();

	const statuColors = [
		{ productStatu: null, color: '#363062' }, // all
		{ productStatu: 0, color: '#363062' }, // all
		{ productStatu: 1, color: '#90cd93' }, // pending
		{ productStatu: 2, color: '#ff8980' }, // reject
		{ productStatu: 3, color: '#75bef8' } // approved
	];

	const handleClickStatuBtns = async (statu: ProductStatu | null = null) => {
		setFilterProductStatuType(statu);
		setSearchParams(`status=${statu}`);
	};

	return (
		<React.Fragment>
			<h5 className="m-0">Product Status</h5>
			<p className="text-sm text-color-secondary">You can filter your products by product status like pending, rejected, approved etc.</p>

			<div className="flex flex-wrap gap-3 mb-3">
				<button onClick={() => handleClickStatuBtns(null)} className={`${styles.btnStatus} shadow-1${!filterProductStatuType ? ' ' + styles.active : ''}`} style={{ '--border-color-hex': statuColors.find((statu) => !statu.productStatu)?.color } as CSSProperties} aria-label="All">
					<i className={`pi pi-circle${!filterProductStatuType ? '-fill' : ''} mr-2`} style={{ color: statuColors.find((statu) => !statu.productStatu)?.color }} />
					All
				</button>

				{enumToArrayHelper.convertArray(ProductStatu, true).map((data) => (
					<button onClick={() => handleClickStatuBtns(+data.value)} key={data.value} className={`${styles.btnStatus} shadow-1${!!filterProductStatuType && filterProductStatuType === data.value ? ' ' + styles.active : ''}`} style={{ '--border-color-hex': statuColors.find((statu) => statu.productStatu === data.value)?.color } as CSSProperties} aria-label={data.label}>
						<i className={`pi pi-circle${filterProductStatuType === data.value ? '-fill' : ''} mr-2`} style={{ color: statuColors.find((statu) => statu.productStatu === data.value)?.color }} />
						{stringHelper.parseAtUpperCaseAndJoin(data.label)}
					</button>
				))}
			</div>
		</React.Fragment>
	);
};

export default ProductFilterTab;
