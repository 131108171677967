import { DataResponse } from '../models/_commons/responses/data-response';
import HttpBaseService from './_common/http-base-service';
import { Country } from '../models/dtos/country/country';
import { State } from '../models/dtos/country/state';
import { Timezone } from '../models/dtos/country/timezone';

class CountryService extends HttpBaseService {
    constructor() {
        super('countries');
    }

    async getCountries(): Promise<DataResponse<Country[]>> {
        return await this.getData('get-countries');
    }

    async getStates(countryId: number): Promise<DataResponse<State[]>> {
        return await this.getData('get-states?CountryId=' + countryId);
    }

    async getTimezones(): Promise<DataResponse<Timezone[]>> {
        return await this.getData('get-timezones');
    }
}

export default new CountryService();
