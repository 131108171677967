import { ImageMediaBase } from 'features/medias/dtos/bases/image-media.base.dto';
import { Galleria } from 'primereact/galleria';
import React from 'react';

type Props = {
	images: ImageMediaBase[];
	activeIndex?: number;
	setActiveIndex?: Function;
};

const CustomGalleria = ({ images, activeIndex, setActiveIndex }: Props) => {
	const itemTemplate = (image: ImageMediaBase) => <img src={image.url} alt="Galleria Image" className="w-full" />;
	const thumbnailTemplate = (image: ImageMediaBase) => <img src={image.url} alt="Galleria Image" style={{ width: 75, height: 'auto', aspectRatio: 1 }} />;

	if (!activeIndex || activeIndex < 0 || !setActiveIndex) return <Galleria value={images} item={itemTemplate} thumbnail={thumbnailTemplate} />;

	return <Galleria value={images} activeIndex={activeIndex} onItemChange={(event) => setActiveIndex(event.index)} item={itemTemplate} thumbnail={thumbnailTemplate} />;
};

export default React.memo(CustomGalleria);
