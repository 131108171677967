export class GetStockLocationRequest {
	id: string;

	get getConvertUriExtension() {
		return `get-stock-location?Id=${this.id}`;
	}

	constructor(props: Partial<GetStockLocationRequest>) {
		this.id = props.id || "";
	}
}