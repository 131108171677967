import HttpBaseService from 'services/_common/http-base-service';
import { CreateUserSubscribtionResponse } from './create-subscription/create-user-subscription.response';
import { CreateUserSubscribtionRequest } from './create-subscription/create-user-subscription.request';
import { GetSubscriptionsRequest } from './get-subscriptions/get-subscriptions.request';
import { GetSubscriptionsResponse } from './get-subscriptions/get-subscriptions.response';

class UserSubscriptionService extends HttpBaseService {
	constructor() {
		super('user-subscriptions');
	}

	async getSubscriptions(request: GetSubscriptionsRequest): Promise<GetSubscriptionsResponse> {
		return await this.getData(request.getConvertUriExtension)
	}

	async createSubscription(request: CreateUserSubscribtionRequest): Promise<CreateUserSubscribtionResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new UserSubscriptionService();
