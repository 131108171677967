export class DownloadDtfImageRequest {
	id: string

	constructor(id: string) {
		this.id = id
	}

	get getConvertUriExtension() {
		return `download-dtf-image?Id=${this.id}`;
	}
}