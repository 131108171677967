import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './OrderDetail.module.scss';
import { Order } from 'features/orders/dtos/order.dto';
import OrderDetailList from 'components/pages/orders/components/OrderDetailList';
import ProductTable from 'components/pages/orders/components/ProductTable';
import ShippingInfo from 'helpers/widgets/Order/ShippingInfo';
import { AxiosError } from 'axios';
import PrintDropdown from 'helpers/widgets/Order/PrintDropdown/PrintDropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import GoBackButton from 'helpers/widgets/Buttons/GoBackButton';
import orderService from 'features/orders/_common/order.service';
import { GetOrderDetailNewRequest } from 'features/orders/_common/get-order-detail-new/get-order-detail-new.request';
import { OrderDto } from 'models/dtos/order/order';

type props = { barcode?: string; setOrderList?: Function };

const OrderDetail = ({ barcode, setOrderList }: props) => {
	const navigate = useNavigate();
	const { orderId } = useParams();
	const componentRef = useRef<HTMLDivElement>(null);

	const [order, setOrder] = useState<OrderDto | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

	const getOrderDetail = async () => {
		try {
			setLoading(true);
			if (!orderId) throw '';

			const response = await orderService.getOrderDetailNew(new GetOrderDetailNewRequest(orderId));
			if (!response.isSuccess || !response.data) throw '';

			setOrder(response.data);
		} catch (error) {
			setOrder(undefined);

			if (error instanceof AxiosError && error.response?.status === 500) navigate(-1);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!orderId) return;

		getOrderDetail();
	}, [orderId]);

	// const getOrderDetail = useCallback(async () => {
	// 	if (!orderId && !barcode) return;

	// 	try {
	// 		if (barcode) {
	// 			const request = new GetOrderDetailByBarcodeRequest();
	// 			request.barcode = barcode;

	// 			const response = await ordersService.getOrderDetailByBarcode(request);

	// 			if (!response.isSuccess) throw '';

	// 			setOrder(response.data);
	// 		} else {
	// 			const request = new GetOrderDetailRequest();
	// 			request.orderId = orderId || '';

	// 			const response = await ordersService.getOrderDetail(request);

	// 			if (!response.isSuccess) throw '';

	// 			setOrder(response.data);
	// 		}
	// 	} catch (error: AxiosError | any) {
	// 		if (setOrderList) setOrderList(false);

	// 		if (!error) return;

	// 		if ((error as AxiosError).response?.status === 500) navigate(-1);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// }, [orderId, barcode]);

	// useEffect(() => {
	// 	if (!orderId && !barcode) return;

	// 	getOrderDetail();
	// }, [orderId, getOrderDetail, barcode]);

	return (
		<React.Fragment>
			{!loading && order ? (
				<div className={styles.orderContainer}>
					<div className="card">
						<div ref={componentRef} className={styles.onPrint}>
							<div className={`${styles.header} align-items-end justify-content-between`}>
								<h3 className="m-0">Order Summary</h3>
								<img src="/assets/layout/images/logo-dark.svg" alt="Printram logo" width="300px" />
							</div>

							<hr className={styles.header} />

							<div className="flex justify-content-between">
								<div className={styles.hiddenOnPrint}>
									<h5>Order Details</h5>
								</div>
								<div className="flex gap-3">
									<PrintDropdown loading={downloadLoading} setLoading={setDownloadLoading} orderIds={orderId as string} />
									<GoBackButton />
								</div>
							</div>

							<hr className={styles.hiddenOnPrint} />

							{!!order ? (
								<React.Fragment>
									<OrderDetailList {...order} />

									<ProductTable orderItems={order.items} />
								</React.Fragment>
							) : null}
						</div>
					</div>

					{!!order.transport ? (
						<div className="grid">
							<div className="col-12 lg:col-6">
								<div className="card">
									<ShippingInfo {...order.transport} />
								</div>
							</div>
						</div>
					) : null}

					{downloadLoading ? (
						<div className={styles.loading}>
							<ProgressSpinner className={styles['p-progress-color']} strokeWidth="4" />
						</div>
					) : null}
				</div>
			) : (
				<div className="card">
					<div className="grid">
						<div className="col-12">
							<Skeleton height="2rem" />
						</div>
						<div className="col-12">
							<Skeleton height="2rem" />
						</div>
						<div className="col-12">
							<Skeleton height="2rem" />
						</div>
						<div className="col-12">
							<Skeleton height="2rem" />
						</div>
						<div className="col-12">
							<Skeleton height="2rem" />
						</div>
						<div className="col-12">
							<Skeleton height="12rem" />
						</div>
						<div className="col-12">
							<Skeleton height="12rem" />
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default OrderDetail;
