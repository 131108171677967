export class SurveyAnswerDtoRequest {
	userId: string;
	surveyQuestionOptionId: string;
	text?: string | null;

	constructor(props: Partial<SurveyAnswerDtoRequest>) {
		this.userId = props.userId || '';
		this.surveyQuestionOptionId = props.surveyQuestionOptionId || '';
		this.text = props.text;
	}
}

export class SurveyCreateAnswerRequest {
	answers: SurveyAnswerDtoRequest[];

	constructor(props: Partial<SurveyCreateAnswerRequest>) {
		this.answers = props.answers || [];
	}

	get getConvertUriExtension() {
		return `answers`;
	}
}
