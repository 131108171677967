import { DataResponse } from "../../models/_commons/responses/data-response";
import { ResponseBase } from "../../models/_commons/responses/response-base";
import HttpBaseService from "../../services/_common/http-base-service";
import { CreateSellerStoreRequest } from "./create-seller-store/create-seller-store.request";
import { DeleteSellerStoreRequest } from "./delete-seller-store/delete-seller-store.request";
import { GetSellerStoreListForAdminRequest } from "./get-seller-store-list-for-admin/get-seller-store-list-for-admin.request";
import { GetSellerStoreListForAdminResponse } from "./get-seller-store-list-for-admin/get-seller-store-list-for-admin.response";
import { GetSellerStoresBySellerIdRequest } from "./get-seller-stores-by-seller-id/get-seller-stores-by-seller-id.request";
import { GetSellerStoresBySellerIdResponse } from "./get-seller-stores-by-seller-id/get-seller-stores-by-seller-id.response";
import { UpdateSellerStoreRequest } from "./update-seller-store/update-seller-store.request";

class SellerStoresService extends HttpBaseService {
	constructor() {
		super('seller-stores');
	}

	async getSellerStoreListForAdmin(request: GetSellerStoreListForAdminRequest): Promise<GetSellerStoreListForAdminResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async getAllBySellerId(request: GetSellerStoresBySellerIdRequest): Promise<DataResponse<GetSellerStoresBySellerIdResponse[]>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async create(request: CreateSellerStoreRequest): Promise<ResponseBase> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async update(request: UpdateSellerStoreRequest): Promise<ResponseBase> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async delete(request: DeleteSellerStoreRequest): Promise<ResponseBase> {
		return await this.postData(request.getConvertUriExtension, request);
	}

//		async delete(request: DeleteSellerStoreRequest): Promise<boolean> {	//	remove
//			return await this.deleteData(request.getConvertUriExtension, undefined, request, "store");	//	remove
//		}	//	remove
}

export default new SellerStoresService();
