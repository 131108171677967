import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/auth/authSlice';
import printramStoreSlice from './features/printram-store/printramStoreSlice';
import userPrintOptionSlice from './features/user-print-option/userPrintOptionSlice';
import paginationSlice from './features/pagination/paginationSlice';
import redirectSlice from './features/redirect/redirectSlice';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		printramStore: printramStoreSlice,
		userPrintOption: userPrintOptionSlice,
		pagination: paginationSlice,
		redirect: redirectSlice
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
