import { loadStripe } from '@stripe/stripe-js';
import MyCards from 'components/pages/account/components/paymentMethods/components/MyCards';
import balanceService from 'features/balances/_common/balance.service';
import { GetBalanceByUserIdRequest } from 'features/balances/_common/get-balance-by-user-id/get-balance-by-user-id.request';
import { UserBalanceDto } from 'features/balances/dtos/user-balance.dto';
import { CreateSetupIntentRequest } from 'features/stripe/_common/create-setup-intent/create-setup-intent.request';
import { GetOrCreateCustomerIdRequest } from 'features/stripe/_common/get-or-create-customer-id/get-or-create-customer-id.request';
import { GetPaymentMethodsRequest } from 'features/stripe/_common/get-payment-methods/get-payment-methods.request';
import stripeService from 'features/stripe/_common/stripe.service';
import environment from 'helpers/constants/environment';
import currencyHelper from 'helpers/curreny.helper';
import { User } from 'models/dtos/auth/users/user';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styles from './Payments.module.scss';
import { SellerReorderDetailContext, SellerReorderDetailContextType } from '../../../context/SellerReorderDetailContextProvider';

export const Payments = () => {
	const [stripePromise] = useState(() => loadStripe(environment.stripeKey || ''));
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));
	const [userBalance, setUserBalance] = useState<UserBalanceDto>();
	const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
	const [stripeCustomerId, setStripeCustomerId] = useState('');
	const [setupIntent, setSetupIntent] = useState();
	const [stripeOptions, setStripeOptions] = useState({ client_secret: '' });
	const { selectedPaymentCard, setSelectedPaymentCard, calculatedPrice, reorderRequest, setReorderRequest, setLoading } = React.useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	const [index, setIndex] = useState(selectedPaymentCard ? 1 : 0);

	const changeCard = (cardId: string) => {
		setSelectedPaymentCard(cardId);
		setReorderRequest((_current) => {
			const newRequest = structuredClone(_current);

			newRequest.paymentMethodType = 1;

			return newRequest;
		});
	};

	const balanceColorHandler = () => {
		if (calculatedPrice && userBalance) {
			if (userBalance?.price.pricePerUnit >= calculatedPrice?.total.pricePerUnit) {
				return '#3B8329';
			} else return '#B95555';
		} else return '';
	};

	const getUserBalance = async () => {
		if (!userId) return;

		try {
			setLoading(true);

			const request = new GetBalanceByUserIdRequest(userId);

			const response = await balanceService.getBalanceByUserId(request);

			if (!response.isSuccess || !response.data) throw '';

			setUserBalance(response.data);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const getPaymentMethods = async () => {
		if (!userId) return;

		try {
			setLoading(true);

			const getOrCreateRequest = new GetOrCreateCustomerIdRequest(userId);
			const getOrCreateCustomerResponse = await stripeService.getOrCreateCustomerId(getOrCreateRequest);

			if (!getOrCreateCustomerResponse.isSuccess || !getOrCreateCustomerResponse.data) throw '';

			setStripeCustomerId(getOrCreateCustomerResponse.data);

			const getPaymentsRequest = new GetPaymentMethodsRequest(userId);
			const getPaymentMethodsResponse = await stripeService.getPaymentMethods(getPaymentsRequest);

			if (!getPaymentMethodsResponse.isSuccess || !getPaymentMethodsResponse.data) throw '';

			setPaymentMethods(getPaymentMethodsResponse.data.data);

			if (!selectedPaymentCard && getPaymentMethodsResponse.data.data.length > 0) {
				setSelectedPaymentCard(getPaymentMethodsResponse.data.data[0].id);
				setReorderRequest((_current) => {
					const newRequest = structuredClone(_current);

					newRequest.paymentMethodType = 1;

					return newRequest;
				});
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const createSetupIntent = async () => {
		try {
			if (!userId) throw '';

			const request = new CreateSetupIntentRequest(userId);

			const response = await stripeService.createSetupIntent(request);

			if (!response.isSuccess) throw '';

			setSetupIntent(response.data);
			setStripeOptions({ client_secret: response.data.client_secret });
		} catch (error) {
		} finally {
		}
	};

	useEffect(() => {
		if (!stripeCustomerId) return;

		createSetupIntent();
	}, [stripeCustomerId]);

	useEffect(() => {
		if (index === 0) {
			getUserBalance();
			setSelectedPaymentCard(undefined);
			setReorderRequest((_current) => {
				const newRequest = structuredClone(_current);

				newRequest.paymentMethodType = 2;

				return newRequest;
			});
		} else getPaymentMethods();
	}, [index]);

	return (
		<div>
			<span className={styles.paymentTitle}>Payment</span>

			<div className={styles.tabs}>
				<div className={`${styles.tab} ${index === 0 && styles.selected}`} onClick={() => setIndex(0)}>
					<i className="pi pi-fw pi-wallet" style={{ fontSize: '1.2rem' }} />
					<span>Printram balance</span>
				</div>
				<div className={`${styles.tab} ${index === 1 && styles.selected}`} onClick={() => setIndex(1)}>
					<i className="pi pi-fw pi-credit-card" style={{ fontSize: '1.2rem' }} />
					<span>Other method</span>
				</div>
			</div>

			{index === 0 ? (
				<div className={styles.balance}>
					<span>USD balance</span>
					<div className={styles.balancePrice}>
						<span>Balance:</span>
						<span className={styles.price} style={{ color: balanceColorHandler() }}>
							{currencyHelper.formatPrice(userBalance?.price.formattedPricePerUnit || 0)}
						</span>
					</div>
				</div>
			) : (
				<div className={styles.creditCards}>{!!stripePromise && (stripeOptions.client_secret ? <MyCards stripePromise={stripePromise} stripeOptions={stripeOptions} setupIntent={setupIntent} paymentMethods={paymentMethods} getAll={getPaymentMethods} createSetupIntent={createSetupIntent} selectedCard={selectedPaymentCard} setSelectedCard={changeCard} /> : <></>)}</div>
			)}
		</div>
	);
};
