export class ReadyToPrintDtfListRequest {
	dtfListIds: string[]

	constructor(dtfListIds: string[]) {
		this.dtfListIds = dtfListIds
	}

	get getConvertUriExtension() {
		return "ready-to-print-dtf-list";
	}
}