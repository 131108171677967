export class GetOrCreateCustomerIdRequest {
	userId: string;

	get getConvertUriExtension() {
		return `get-or-create-customer-id?userId=${this.userId}`;
	}

	constructor(userId: string) {
		this.userId = userId
	}
}