import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const Barcodes = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const location = useLocation();

	const basePathName = user?.roles.isPackager ? '/' : '/barcodes-beta/';

	return (
		<main className="container relative">
			<section className="container-header">
				<h1 className="container-header-title">Barcode Operations</h1>

				<div className="container-header-tools">
					{location.pathname === basePathName ? (
						<PrButton type="primary" disabled text="Scan Barcode" />
					) : (
						<Link to={basePathName}>
							<PrButton type="secondary" text="Scan Barcode" />
						</Link>
					)}

					{/* {location.pathname === `${basePathName}manuel` ? (
						<PrButton type="primary" disabled text="Update Manually" />
					) : (
						<Link to={`${basePathName}manuel`}>
							<PrButton type="secondary" text="Update Manually" />
						</Link>
					)} */}

					{/* {location.pathname === `${basePathName}get-ship-label` ? (
						<PrButton type="primary" disabled text="Get Ship Label" />
					) : (
						<Link to={`${basePathName}get-ship-label`}>
							<PrButton type="secondary" text="Get Ship Label" />
						</Link>
					)} */}

					{location.pathname === `${basePathName}operation-step-list` ? (
						<PrButton type="primary" disabled text="Operation Step List" />
					) : (
						<Link to={`${basePathName}operation-step-list`}>
							<PrButton type="secondary" text="Operation Step List" />
						</Link>
					)}
				</div>
			</section>

			<Outlet />
		</main>
	);
};

export default Barcodes;
