export interface ProductVariantAndPrice {
	productVariantId: string;
	price: { pricePerUnit: number }
}

export class CreateOrUpdateProductVariantPricesRequest {
	userId: string = '';
	productVariantAndPrices: ProductVariantAndPrice[] = [];

	constructor(userId: string, productVariantAndPrices: ProductVariantAndPrice[]) {
		this.userId = userId;
		this.productVariantAndPrices = productVariantAndPrices;
	}

	get getConvertUriExtension() {
		return `user/create-or-update`;
	}
}