import { Resource } from '../models/dtos/resource/resource';
import { CreateResourceRequest } from '../models/requests/resources/create-resource-request';
import { UpdateResourceRequest } from '../models/requests/resources/update-resource-request';
import { DataResponse } from '../models/_commons/responses/data-response';
import { ResponseBase } from '../models/_commons/responses/response-base';
import HttpBaseService from './_common/http-base-service';

class ResourceService extends HttpBaseService {
	constructor() {
		super('resources');
	}

	async getById(resourceId: string): Promise<DataResponse<Resource>> {
		return await this.getData(`get-by-id?ResourceId=${resourceId}`);
	}

	async getAll(): Promise<DataResponse<Resource[]>> {
		return await this.getData('get-resources');
	}

	async createWithVideoMedia(request: CreateResourceRequest): Promise<ResponseBase> {
		return await this.postData('create-with-video-media', request);
	}

	async update(request: UpdateResourceRequest): Promise<ResponseBase> {
		return await this.putData('update-with-video-media', request);
	}

	async delete(id: string): Promise<boolean> {
		return await this.deleteData('soft-delete', id);
	}
}

export default new ResourceService();
