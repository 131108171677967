import { ErrorMessage, Form, Formik } from 'formik';
import { SendResetPasswordCode } from 'models/requests/userSecurity/send-reset-password-code-request';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userSecurityService from 'services/user-security-service';
import * as Yup from 'yup';
import styles from './SendResetPasswordCode.module.scss';

const SendResetPassword = () => {
	const navigate = useNavigate();

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const initialValues: SendResetPasswordCode = new SendResetPasswordCode();

	const validationScheme = Yup.object().shape({
		email: Yup.string().email('Email address must be valid.').required('Email is Required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.')
	});

	function handleSubmit(data: SendResetPasswordCode) {
		setIsSubmitDisabled(true);

		userSecurityService.sendResetPasswordCode(data).catch(() => setIsSubmitDisabled(false));
	}

	return (
		<div className={`${styles.pageWrapper} w-full`}>
			<Formik initialValues={initialValues} validationSchema={validationScheme} onSubmit={handleSubmit}>
				{({ handleChange }) => (
					<Form className={`${styles.resetPasswordWrapper} grid`}>
						<div className="col-12 flex align-items-center cursor-pointer" onClick={() => navigate('login')}>
							<i className="pi pi-chevron-circle-left mr-2" />
							<span>Go to Sign In</span>
						</div>

						<div className={`${styles.formColumn} col-12`}>
							<small className={styles.infoText}>If you forgot your password, write your e-mail address in the box below. We will send a link to your e-mail address to reset your password.</small>
						</div>
						<div className={`${styles.formColumn} col-12`}>
							<div className="mb-1 px-1 flex align-items-center justify-content-between">
								<label className="font-medium block">Email</label>
								<ErrorMessage name="email" component="small" className="text-red font-medium" />
							</div>
							<InputText type="email" id="email" onChange={handleChange} />
						</div>

						<div className={`${styles.formColumn} col-12`}>
							<Button disabled={isSubmitDisabled} type="submit" label="Send Code" className="p-button-raised" />
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default SendResetPassword;
