export class GetByIdRequest {
	private id: string;

	constructor(id: string) {
		this.id = id;
	}

	get getConvertUriExtension() {
		return `get-by-id?Id=${this.id}`;
	}
}
