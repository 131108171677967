import { DataResponse } from "models/_commons/responses/data-response";
import HttpBaseService from "services/_common/http-base-service";
import { DataPaginationResponse } from "models/_commons/responses/data-pagination-response";
import { GetMerchantProductForSyncResponse } from "./get-merchant-product-for-sync/get-merchant-product-for-sync.response";
import { DeleteMerchantProductRequest } from "./delete-merchant-product/delete-merchant-product.request";
import { GetProductsForListRequest } from "./get-products-for-list/get-products-for-list.request";
import { GetProductsForListResponse } from "./get-products-for-list/get-products-for-list.response";
import { GetMerchantProductForSyncRequest } from "./get-merchant-product-for-sync/get-merchant-product-for-sync.request";
import { GetMyProductsRequest } from "./get-my-products/get-my-products.request";
import { GetMyProductsResponse } from "./get-my-products/get-my-products.response";
import { CreateRequest } from "./create/create.request";
import { CreateResponse } from "./create/create.response";
import { UpdateRequest } from "./update/update.request";
import { UpdateResponse } from "./update/update.response";
import { GetMerchantProductForUpdateByProductRequest } from "./get-merchant-product-for-update-by-product/get-merchant-product-for-update-by-product.request";
import { GetMerchantProductForUpdateByProductResponse } from "./get-merchant-product-for-update-by-product/get-merchant-product-for-update-by-product.response";

class MerchantProductService extends HttpBaseService {
	constructor() {
		super('merchant-products');
	}

	async getMyProducts(request: GetMyProductsRequest): Promise<GetMyProductsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getProductsForList(request: GetProductsForListRequest): Promise<DataPaginationResponse<GetProductsForListResponse[]>> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getProductForSync(request: GetMerchantProductForSyncRequest): Promise<DataResponse<GetMerchantProductForSyncResponse[]>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getMerchantProductForUpdate(request: GetMerchantProductForUpdateByProductRequest): Promise<GetMerchantProductForUpdateByProductResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async create(request: CreateRequest): Promise<CreateResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async update(request: UpdateRequest): Promise<UpdateResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async delete(request: DeleteMerchantProductRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension, undefined, request, 'product');
	}
}

export default new MerchantProductService();
