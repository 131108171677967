import { CategoryDetailDto } from './dtos/category-detail.dto';
import { EtsyCategoryBuyerTaxonomy } from './dtos/etsy-category-buyer-taxonmy.dto';

export class CreateCategoryRequest {
	parentCategoryId: string | null;
	name: string;
	slug: string;
	canBeAddProduct: boolean;
	isTemplateCategory: boolean;
	imageFile: File | null;
	detail: CategoryDetailDto;
	etsyCategoryBuyerTaxonomy: EtsyCategoryBuyerTaxonomy;

	constructor(props: Partial<CreateCategoryRequest>) {
		this.parentCategoryId = props.parentCategoryId || null;
		this.name = props.name || '';
		this.canBeAddProduct = props.canBeAddProduct || false;
		this.isTemplateCategory = props.isTemplateCategory || false;
		this.imageFile = props.imageFile || null;
		this.etsyCategoryBuyerTaxonomy = props.etsyCategoryBuyerTaxonomy || new EtsyCategoryBuyerTaxonomy({});
		this.detail = props.detail || new CategoryDetailDto({});
		this.slug = props.slug || '';
	}

	get getConvertUriExtension() {
		return `create`;
	}
}
