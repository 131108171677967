export class SoftDeleteSellerRequest {
	id: string = "";

	get getConvertUriExtension() {
		return `soft-delete`;
	}

	constructor(id: string) {
		this.id = id;
	}
}