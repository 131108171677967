export interface CompleteReorderByAdminItems {
	reOrderItemId: string;
	productCostAccepted: boolean;
}

export class SaveAdminReviewsToReorderRequest {
	reorderId: string;
	requestedShippingRateAccepted: boolean;
	oldOrderShippingCostAccepted: boolean;
	note: string | null;
	items: CompleteReorderByAdminItems[];

	constructor(props: Partial<SaveAdminReviewsToReorderRequest>) {
		this.reorderId = props.reorderId || '';
		this.requestedShippingRateAccepted = props.requestedShippingRateAccepted || false;
		this.oldOrderShippingCostAccepted = props.oldOrderShippingCostAccepted || false;
		this.note = props.note || null;
		this.items = props.items || [];
	}

	get getConvertUriExtension() {
		return `save-admin-reviews-to-reorder`;
	}
}
