import axios, { AxiosError, AxiosResponse } from 'axios';
import environment from '../helpers/constants/environment';
import dateHelper from '../helpers/dateHelper';
import handleMessageHelper from '../helpers/handle-message.helper';
import jwtParserHelper from '../helpers/jwt-parser.helper';
import localStorageHelper from '../helpers/local-storage.helper';
import { Auth } from '../models/dtos/auth/auth';
import { DataResponse } from '../models/_commons/responses/data-response';
import { resetAuth, setReNewAuth } from 'redux/features/auth/authSlice';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import he from 'he';
import { confirmDialog } from 'primereact/confirmdialog';

const axiosInstance = axios.create({
	baseURL: environment.apiUrl
});

let store: ToolkitStore = {} as ToolkitStore;

export const injectStore = (_store: ToolkitStore) => {
	store = _store;
};

const loginWithRefreshToken = async (request: { userId: string; refreshToken: string }) => {
	try {
		const response = (await axios.post(environment.apiUrl + 'users/login-with-refresh-token', request)) as AxiosResponse<DataResponse<Auth>>;
		if (!response?.data) return response;

		const lsAuth = (localStorageHelper.getParsed('auth') as Auth) || null;
		if (!lsAuth || !response?.data.data || !response.data.isSuccess) return response;

		const newAuth = { ...lsAuth };

		newAuth.accessToken = response.data.data.accessToken;
		newAuth.refreshToken = response.data.data.refreshToken;

		store.dispatch(setReNewAuth(newAuth));

		return response;
	} catch (error) {
		store.dispatch(resetAuth());
	}
};

axiosInstance.interceptors.request.use(async (request) => {
	const auth = (localStorageHelper.getParsed('auth') as Auth) || null;

	if (!request.headers || !auth) return request;

	request.headers.Authorization = 'Bearer ' + auth.accessToken.token;

	const expireDate = jwtParserHelper.expireDate(auth.accessToken.token);

	const isExpired = dateHelper.getMinutesDiffOfTwoDate(expireDate) < 1;

	if (!isExpired) return request;

	const response = await loginWithRefreshToken({ userId: auth.user.userId, refreshToken: auth.refreshToken.token });

	if (!response?.data.isSuccess || !response?.data.data) return request;

	request.headers.Authorization = 'Bearer ' + response.data?.data.accessToken.token;

	return request;
});

axiosInstance.interceptors.response.use(
	(responseConfig) => {
		if (!!responseConfig.data) {
			const response: DataResponse<any> = responseConfig.data;

			handleMessageHelper.handleMessage(response.messageType!, response.message!);
		}

		try {
			responseConfig.data = JSON.parse(he.decode(JSON.stringify(responseConfig.data)));
		} catch (error) {}

		return responseConfig;
	},
	(error: AxiosError) => {
		if (error.response?.status === 306)
			confirmDialog({
				header: 'Information',
				icon: 'pi pi-info-circle',
				message: (error.response.data as any)?.message || '',
				style: { maxWidth: '40rem' },
				rejectClassName: 'hidden',
				acceptLabel: 'Confirm'
			});
		else handleMessageHelper.handleResponseError(error);

		//console.log('REQUEST =>', JSON.parse(error.response?.config.data || 'null') || null, 'RESPONSE =>', error.response?.data || null);

		return Promise.reject(error);
	}
);

export default axiosInstance;
