export class CreateStripeAccountRequest {
	userId: string = '';

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `create-account`;
	}
}