import { ShippingRateDto } from "features/orders/derived-features/order-transports/dtos/shipping-rate.dto";

export class CreateReorderCompleteRequest {
	reorderId: string;
	isShippingServiceRequired: boolean;
	sellerNote: string | null;
	requestShippingRate: ShippingRateDto | null;
	reasonMedias: File[] | null;

	constructor(props: Partial<CreateReorderCompleteRequest>) {
		this.reorderId = props.reorderId || '';
		this.isShippingServiceRequired = props.isShippingServiceRequired || false;
		this.reasonMedias = props.reasonMedias || null;
		this.sellerNote = props.sellerNote || null;
		this.requestShippingRate = props.requestShippingRate || null;
	}

	get getConvertUriExtension() {
		return `save-seller-reviews-to-reorder`;
	}
}
