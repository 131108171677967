import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../../../../helpers/widgets/Forms/TextInput';
import { Size } from '../../../../../models/dtos/dimesions/size';
import sizeService from '../../../../../services/dimensions/size-service';
import { useState } from 'react';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getSizes: Function;
	editedSize: Size | undefined;
	setEditedSize: Function;
};

const SizeUpdate = ({ isVisible, setVisible, getSizes, editedSize, setEditedSize }: Props) => {
	const [loading, setLoading] = useState(false)

	const initialValues: Size = { id: editedSize?.id || '', name: editedSize?.name || '', shortName: editedSize?.shortName || '', priority: editedSize?.priority || 0 };

	const validationSchema = Yup.object().shape({
		name: Yup.string().required().min(2, 'Name must be at least 2 characters.').max(100, 'Name must be max 100 characters.'),
		shortName: Yup.string().required().min(1, 'Short name must be at least 1 characters.').max(50, 'Short name must be max 50 characters.'),
		priority: Yup.number().min(1, 'Priority number must be at least 1 characters.').max(255, 'Priority number must be max 255 characters.')
	});

	const submitForm = (values: Size) => {
		setLoading(true);

		sizeService.update(values).then((response) => {
			if (!response.isSuccess) return;

			setEditedSize(undefined);
			setVisible(false);
			getSizes();
		}).finally(() => setLoading(false));
	};

	return (
		<Dialog
			visible={isVisible}
			header="Update Size"
			modal
			onHide={() => {
				setEditedSize(undefined);
				setVisible(false);
			}}>
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationSchema}>
				{({ values, handleChange }) => (
					<Form>
						<div className="grid">
							<div className="col-12">
								<TextInput name="name" value={values.name} onChange={handleChange} placeholder="Enter size name" label="Name" />
							</div>

							<div className="col-12">
								<TextInput name="shortName" value={values.shortName} onChange={handleChange} placeholder="Enter size short name" label="Short Name" />
							</div>

							<div className="col-12">
								<TextInput name="priority" value={values.priority} onChange={handleChange} placeholder="0" label="Priority Number" keyfilter="num" />
							</div>

							<div className="col-12 flex justify-content-end mt-2">
								<Button type="submit" label="Update" loading={loading} className="h-full px-8" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default SizeUpdate;
