import { User } from '../../models/dtos/auth/users/user';
import { useAppSelector } from '../../redux/hooks';
import colorHelper from '../color.helper';

const AvatarImage = () => {
    const user = useAppSelector((state) => state.auth.data?.user || ({} as User));
    const colors = {} as { bgColor: string; textColor: string };

    colors.bgColor = user.avatarBackgroundColor || '#D2001A';
    colors.textColor = colorHelper.getContrast(colors.bgColor);

    return (
        <>
            {!!user.media ? (
                <img src={user.media?.imageMediasWithUrl[0]?.url || 'https://via.placeholder.com/200'} alt="avatar" referrerPolicy="no-referrer" width="32px" height="32px" style={{ borderRadius: '16px' }} />
            ) : (
                <div style={{ backgroundColor: colors.bgColor, color: colors.textColor }} className="default-avatar">
                    {user.corporateInfo?.businessName.charAt(0) || user.personInfo?.name.charAt(0) + user.personInfo.surname.charAt(0)}
                </div>
            )}
        </>
    );
};

export default AvatarImage;
