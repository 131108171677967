import ShippingInfoSection from '../components/shipping-info-section/ShippingInfoSection';
import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import React from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextType } from '../context/UpgradeShippingContextProvider';
import { DeliveryTypes } from '../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';

const ShippingInfo = () => {
	const context = React.useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;

	return (
		<React.Fragment>
			<ShippingInfoSection />

			<section className="container-body py-5 flex justify-content-center align-items-center">
				<PrButton disabled={context.loading} text={context.order?.deliveryType === DeliveryTypes.Shipment ? 'Calculate Shipping' : 'Enter Address'} onClick={() => context.order?.deliveryType === DeliveryTypes.Shipment ? context?.goToStep(2) : context?.goToStep(1) } className="border-round-md" />
			</section>
		</React.Fragment>
	)
}

export default ShippingInfo;