import countryService from 'features/countries/country.service';
import { GetCountriesRequest } from 'features/countries/get-countries/get-countries.request';
import { CountryBaseDto } from 'features/countries/get-countries/get-countries.response';
import { prefixOptions } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { ErrorMessage, Form, Formik } from 'formik';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import AutoCompleteInput from 'helpers/widgets/Printram/Forms/Input/AutoCompleteInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import * as Yup from 'yup';
import { SellerReorderDetailContext, SellerReorderDetailContextType } from '../../../context/SellerReorderDetailContextProvider';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { SaveReorderAddressRequest } from 'features/reorders/_common/save-address/save-address.request';
import { reorderService } from 'features/reorders/_common/reorder.service';
import { ReorderCalculateRequest } from 'features/reorders/_common/calculate/re-order-calculate.request';
import AddressAutofill from 'helpers/widgets/Order/Address/AddressAutofill';
import StatesAndCountries from '../../../../../../../../../assets/jsons/states-and-countries/states-and-countries.json';
import { State, StateAndCountry } from 'assets/jsons/states-and-countries/states.type';

export const ShipForm = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const context = useContext(SellerReorderDetailContext) as SellerReorderDetailContextType;

	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [stateOptions, setStateOptions] = useState<PrDropdownType[] | undefined>();
	const [selectedCountryIso, setSelectedCountryIso] = useState<string | undefined>();

	const prefixHandler = (phone: string) => (phone ? (phone.startsWith('+90') ? { label: '+90', value: '+90' } : { label: '+1', value: '+1' }) : { label: '+1', value: '+1' });

	const getCountries = async () => {
		try {
			const response = await countryService.getCountries(new GetCountriesRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCountries(response.data);
			const selectedIso = context.reorder?.orderAddress.countryIso.length === 2 ? context.reorder?.orderAddress.countryIso : response.data.find((_country) => _country.iso3 === context.reorder?.orderAddress.countryIso)?.iso2;
			setSelectedCountryIso(selectedIso);
		} catch (error) {
			setCountries([]);
		}
	};

	useEffect(() => {
		if (countries.length < 1) getCountries();
	}, []);

	useEffect(() => {
		const statesAndCountries = structuredClone(StatesAndCountries);

		setStateOptions(statesAndCountries.find((_states: StateAndCountry) => _states.country === selectedCountryIso)?.states.map((_statesOption: State) => ({ label: _statesOption.name, value: _statesOption.abbreviation })));
	}, [selectedCountryIso]);

	return (
		<Formik
			initialValues={
				new SaveReorderAddressRequest({
					address: {
						firstLine: context.reorder?.orderAddress?.firstLine || '',
						secondLine: context.reorder?.orderAddress?.secondLine || '',
						city: context.reorder?.orderAddress?.city || '',
						state: context.reorder?.orderAddress?.state || '',
						zip: context.reorder?.orderAddress?.zip || '',
						countryIso: context.reorder?.orderAddress?.countryIso || '',
						receiverFullName: context.reorder?.orderAddress?.receiverFullName || '',
						receiverEmail: context.reorder?.orderAddress?.receiverEmail || '',
						formattedAddress: context.reorder?.orderAddress?.formattedAddress || '',
						vatNumber: context.reorder?.orderAddress?.vatNumber || '',
						receiverPhoneNumber: context.reorder?.orderAddress?.receiverPhoneNumber || '',
						phonePrefix: context.reorder?.orderAddress?.phonePrefix || prefixHandler(context?.reorder?.orderAddress?.receiverPhoneNumber || '') || { label: '+1', value: '+1' }
					},
					reorderId: context.reorderIdFromRoute?.reorderId || '',
					deliveryType: context.reorder?.deliveryType || 1
				})
			}
			enableReinitialize
			validationSchema={
				context.reorder?.deliveryType !== 2
					? Yup.object().shape({
							address: Yup.object().shape({
								receiverFullName: Yup.string()
									.required('Full Name is required')
									.test('is-full-name', 'Enter your Full Name', (value) => {
										if (!value) return false;

										return value.trim().split(' ').length > 1;
									}),
								receiverEmail: Yup.string().email('Email address must be valid.').required('Email is required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
								countryIso: Yup.string().required('Select a country'),
								state: Yup.string().required('State is required'),
								firstLine: Yup.string().required('Address Line 1 is required'),
								city: Yup.string().required('City is required'),
								zip: Yup.string().required('Zip Code is required')
							})
					  })
					: null
			}
			onSubmit={async (values: SaveReorderAddressRequest) => {
				try {
					context.setLoading(true);
					const request: SaveReorderAddressRequest = new SaveReorderAddressRequest(JSON.parse(JSON.stringify(values)));

					if (!values?.address?.receiverPhoneNumber || values?.address?.receiverPhoneNumber.length < 1) request.address = { ...(values.address as any), receiverPhoneNumber: null };
					if (values.address?.receiverPhoneNumber) request.address = structuredClone({ ...values.address, receiverPhoneNumber: values.address.phonePrefix?.value + values.address.receiverPhoneNumber });

					if (request.deliveryType === 2) request.address = null;

					const response = await reorderService.saveReorderAddress(request);

					if (!response.isSuccess || !response.data) throw '';

					const minRate = Math.min(...response.data?.shippingRates?.shippingRates.map((_rate) => (_rate.discountedAmount ? _rate?.discountedAmount?.formattedPricePerUnit : _rate.shippingRate.amount)));

					const selectedRate = response.data.shippingRates?.shippingRates.find((_rate) => _rate?.discountedAmount?.formattedPricePerUnit === minRate || _rate.shippingRate.amount === minRate);

					const calcReq = new ReorderCalculateRequest({ reOrderId: context.reorderIdFromRoute?.reorderId, shippingRate: selectedRate?.shippingRate });

					const calcResponse = await reorderService.calculateReorder(calcReq);

					if (!calcResponse.isSuccess || !calcResponse.data) throw '';

					context.setSelectedRate(selectedRate);

					context.setShippingRates(response.data?.shippingRates?.shippingRates || []);

					context.setCalculatedPrice(calcResponse.data);

					context.setIsShowShippingForm(false);

					context.setIsNewShippingRatesCalculateRequired(false);
				} catch (error) {
					if (typeof error === 'string') toastHelper.warning(error);
				} finally {
					context.setLoading(false);
				}
			}}>
			{(form) => (
				<Form>
					<div className="container-body p-0 flex">
						<div className="flex-1 p-5">
							<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
								Recipient
							</h2>

							<div className="mt-4">
								<PrTextInput
									type="text"
									label="Full Name *"
									style={{ height: '2.375rem' }}
									value={form.values?.address?.receiverFullName || ''}
									onChange={(e) => {
										const formattedName = stringHelper.formatName(e.target.value);

										form.setFieldValue('receiverFullName', formattedName);
									}}
								/>

								<ErrorMessage name="receiverFullName" component="small" className="text-red font-medium" />
							</div>

							<div className="mt-4">
								<PrTextInput
									type="email"
									label="Email *"
									value={form.values?.address?.receiverEmail || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('receiverEmail', e.target.value || null);
									}}
								/>

								<ErrorMessage name="receiverEmail" component="small" className="text-red font-medium" />
							</div>

							<label className="mt-4 block" style={{ fontSize: '19px', fontWeight: '600' }}>
								Phone <span className="pr-input-label-optional">(Optional)</span>
							</label>

							<div className="flex gap-2 align-items-end mt-1">
								<div>
									<PrDropdown
										options={prefixOptions}
										value={form.values?.address?.phonePrefix || undefined}
										className="w-7rem"
										onChange={(e) => {
											form.setFieldValue('phonePrefix', e);
										}}
									/>
								</div>

								<PrTextInput
									name="phone"
									type="tel"
									value={form.values?.address?.receiverPhoneNumber || ''}
									className="w-full"
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										if (e.target.value.length > 10) return;

										if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

										form.setFieldValue('receiverPhoneNumber', e.target.value);
									}}
								/>
							</div>

							<div className="mt-4">
								<PrTextInput
									label="VAT number"
									required={false}
									value={form.values?.address?.vatNumber || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('vatNumber', e.target.value || '');
									}}
								/>
							</div>
						</div>

						<div className="flex-1 border-left-1 border-400 p-5">
							<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
								Shipping address
							</h2>

							{/* {countries.length > 0 ? (
								<div className="mt-4">
									<AutoCompleteInput
										label="Search Address *"
										value={form.values?.address?.formattedAddress}
										defaultValue={form.values.address ? (form.values?.address?.formattedAddress.length > 1 ? form.values?.address?.formattedAddress : '') : ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => form.setFieldValue('address.formattedAddress', e.target.value)}
										onPlaceSelectedDto={(e) => {
											form.setFieldValue('address.formattedAddress', e.formattedAddress);
											form.setFieldValue('address.state', e.state);
											form.setFieldValue('address.city', e.city);
											form.setFieldValue('address.zip', e.zip);
											form.setFieldValue('address.firstLine', e.firstLine);
											form.setFieldValue('address.secondLine', e.secondLine);

											for (const country of countries) {
												if (country.iso2 !== e.countryIso2) continue;

												form.setFieldValue('address.countryIso', country.iso3);
												break;
											}
										}}
									/>

									<ErrorMessage name="address.formattedAddress" component="small" className="text-red font-medium" />
								</div>
							) : null} */}

							{countries.length > 0 ? (
								<AddressAutofill
									defaultAddress={form.values.address?.formattedAddress || ''}
									onAutofillConfirm={(e) => {
										if (!e) return;
										form.setFieldValue('address.formattedAddress', e.formatted_address || '');
										form.setFieldValue('address.state', e.state_province || '');
										form.setFieldValue('address.city', e.city_locality || '');
										form.setFieldValue('address.zip', e.postal_code || '');
										form.setFieldValue('address.firstLine', e.address_line_1 || '');
										form.setFieldValue('address.secondLine', e.address_line_2 || '');

										for (const country of countries) {
											if (country.iso3 !== e.country_code && country.iso2 !== e.country_code && country.name.toUpperCase() !== e.country_code) continue;

											form.setFieldValue('address.countryIso', country.iso3);
											setSelectedCountryIso(country.iso2);
											break;
										}
									}}
								/>
							) : null}

							<div className="mt-4 flex gap-4">
								<div className="w-full">
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										Country *
									</label>

									<div>
										<PrDropdown
											value={countries.map((_country) => ({ label: _country.name, value: _country.iso3 })).find((_country) => _country.value === form.values?.address?.countryIso)}
											options={countries.map((_country) => ({ label: _country.name, value: _country.iso3 }))}
											className="w-full"
											onChange={(e) => {
												form.setFieldValue('address.countryIso', e.value);
												setSelectedCountryIso(countries.find((_country) => _country.iso3 === e.value)?.iso2);
											}}
										/>

										<ErrorMessage name="address.countryIso" component="small" className="text-red font-medium" />
									</div>
								</div>

								<div className="w-full">
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										State *
									</label>

									{stateOptions ? (
										<PrDropdown
											value={stateOptions.find((_states) => _states.value === form.values.address?.state)}
											options={stateOptions}
											className="w-full"
											onChange={(e) => {
												form.setFieldValue('address.state', e.value);
											}}
										/>
									) : (
										<PrTextInput
											value={form.values?.address?.state || ''}
											className="w-full"
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('address.state', e.target.value || '');
											}}
										/>
									)}

									<ErrorMessage name="address.state" component="small" className="text-red font-medium" />
								</div>
							</div>

							<div className="mt-4">
								<PrTextInput
									label="Address Line 1 *"
									value={form.values?.address?.firstLine || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('address.firstLine', e.target.value || '');
									}}
								/>

								<ErrorMessage name="address.firstLine" component="small" className="text-red font-medium" />
							</div>

							<div className="mt-4">
								<PrTextInput
									label="Address Line 2"
									required={false}
									value={form.values?.address?.secondLine || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('address.secondLine', e.target.value || null);
									}}
								/>
							</div>

							<div className="mt-4 flex gap-4">
								<div className="w-full">
									<PrTextInput
										label="City *"
										value={form.values?.address?.city || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('address.city', e.target.value || '');
										}}
									/>

									<ErrorMessage name="address.city" component="small" className="text-red font-medium" />
								</div>

								<div className="w-full">
									<PrTextInput
										label="Zip Code *"
										value={form.values?.address?.zip || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('address.zip', e.target.value || '');
										}}
									/>

									<ErrorMessage name="address.zip" component="small" className="text-red font-medium" />
								</div>
							</div>
						</div>
					</div>

					<div className="container-body p-3 h-6rem flex align-items-center justify-content-center">
						<PrButton btnType="submit" text="Update & Calculate Shipping" />
					</div>
				</Form>
			)}
		</Formik>
	);
};
