export class GenerateOrderReceiptV2Request {
	orderIdentities: string[] = [];
	isSaveToCdn: boolean = true;

	constructor(orderIdentities?: string[]) {
		if (!!orderIdentities) this.orderIdentities = orderIdentities;
	}

	get getConvertUriExtension() {
		return `generate-order-receipt-v2`;
	}
}