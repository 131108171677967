export class DeleteUserProductVariantPricesRequest {
	userProductVariantPriceIdentities: string[];

	constructor(userProductVariantPriceIdentities: string[]) {
		this.userProductVariantPriceIdentities = userProductVariantPriceIdentities;
	}

	get getConvertUriExtension() {
		return `user/delete`;
	}
}