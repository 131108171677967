import { PlanFeatureDto } from "./dtos/plan-feature.dto";

export class CreatePlanWithOrderDomainsRequest {
	basePlanId?: string | null = null;
	planType: number = 0;
	name: string = '';
	description: string = '';
	priceAsMonthly: number = 0;
	priceAsYearly: number = 0;
	isBuyable: boolean = true;
	createPlanFeatures: { featureAndValues: PlanFeatureDto[] } = { featureAndValues: [] };

	get getConvertUriExtension() {
		return `create-plan-with-other-domains`;
	}

	constructor(basePlanId: string | null, planType: number, name: string, description: string, priceAsMonthly: number, priceAsYearly: number, isBuyable: boolean, createPlanFeatures: { featureAndValues: PlanFeatureDto[] }) {
		this.basePlanId = basePlanId;
		this.planType = planType;
		this.name = name;
		this.description = description;
		this.priceAsMonthly = priceAsMonthly;
		this.priceAsYearly = priceAsYearly;
		this.isBuyable = isBuyable;
		this.createPlanFeatures = createPlanFeatures;
	}
}