import { createSlice } from '@reduxjs/toolkit';

const initialState: string = '/';

export const redirectSlice = createSlice({
	name: 'preview',
	initialState,
	reducers: {
		resetRedirectUrl: (state) => {
			state = '/';
			return state;
		},
		setRedirectUrl: (state, action) => {
			state = action.payload.replace('?redirectUrl=', '/');
			return state;
		}
	}
});

export const { resetRedirectUrl, setRedirectUrl } = redirectSlice.actions;
export default redirectSlice.reducer;
