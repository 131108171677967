import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Resource } from '../../../models/dtos/resource/resource';
import resourceService from '../../../services/resource-service';
import ResourceCreate from './components/ResourceCreate';
import ResourceUpdate from './components/ResourceUpdate';

const Resources = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [resources, setResources] = useState<Resource[]>([]);
	const [visibleCreateResource, setVisibleCreateResource] = useState(false);
	const [visibleUpdateResource, setVisibleUpdateResource] = useState(false);
	const [editedResource, setEditedResource] = useState<Resource | undefined>(undefined);

	const getAll = () => {
		setLoading(true);
		resourceService
			.getAll()
			.then((response) => response.isSuccess && setResources(response.data || []))
			.finally(() => setLoading(false));
	};

	const deleteResource = (id: string) => resourceService.delete(id).then((response) => response && getAll());

	const handleEditBtnCliked = (resource: Resource) => {
		setEditedResource(resource);
		setVisibleUpdateResource(true);
	};

	useEffect(() => {
		getAll();
	}, []);

	const actionsTemplate = (resource: Resource) => (
		<div className="flex gap-2 justify-content">
			<Button onClick={() => handleEditBtnCliked(resource)} label="Edit" icon="pi pi-fw pi-file-edit" className="p-button-info" />
			<Button onClick={() => deleteResource(resource.resourceId)} label="Delete" icon="pi pi-fw pi-trash" className="p-button-danger" />
			<Button onClick={() => navigate('/resources/detail/' + resource.resourceId)} icon="pi pi-arrow-right" label="Detail" />
		</div>
	);

	const videoTemplate = (resource: Resource) => <img src={resource.media?.videoMedias[0].thumbnailUrl} width="160" height="100" style={{ borderRadius: 10 }} />;

	const descriptionTemplate = (resource: Resource) => {
		const description = resource.description.substring(0, 75);
		return description.length === 75 ? description.trimEnd() + '...' : description;
	};

	return (
		<div className="card">
			<div className="flex justify-content-between mb-3">
				<h5>Help Resources</h5>
				<Button onClick={() => setVisibleCreateResource(true)} label="New Help Resource" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>
			<DataTable value={resources} paginator rows={10} dataKey="id" loading={loading} size="large" className="p-datatable-gridlines">
				<Column header="Resource Video" body={(event) => videoTemplate(event)} style={{ minWidth: '12rem' }} />
				<Column field="title" header="Title" filter filterPlaceholder="Search by title" style={{ minWidth: '12rem' }} />
				<Column field="description" header="Description" body={descriptionTemplate} filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
				<Column field="actions" header="Actions" body={actionsTemplate} />
			</DataTable>
			<ResourceCreate isVisible={visibleCreateResource} setVisible={setVisibleCreateResource} getResources={getAll} />
			<ResourceUpdate isVisible={visibleUpdateResource} setVisible={setVisibleUpdateResource} getResources={getAll} editedResource={editedResource} setEditedResource={setEditedResource} />
		</div>
	);
};
export default Resources;
