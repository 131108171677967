import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { GetCoreProductsForListRequest } from 'features/products/_common/get-core-products-for-list/get-core-products-for-list.request';
import productService from 'features/products/_common/product.service';
import { ProductVariantsPriceDto } from 'features/products/derived-features/product-variants/dtos/product-variants-price.dto';
import { GetProductVariantsPriceOptionsRequest } from 'features/products/derived-features/product-variants/get-product-variants-price-options/get-product-variants-price-options.request';
import { GetProductsVariantsAndPriceRequest } from 'features/products/derived-features/product-variants/get-products-variants-and-price/get-products-variants-and-price.request';
import productVariantService from 'features/products/derived-features/product-variants/product-variant.service';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import { Pagination } from 'models/_commons/responses/pagination';
import { useEffect, useRef, useState } from 'react';
import styles from './ProductPrices.module.scss';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { PriceAndAmounts, UpdateProductVariantPriceRequest } from 'features/products/derived-features/product-variants/update-product-variant-price/update-product-variant-price.request';
import currencyHelper from 'helpers/curreny.helper';
import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

const ProductPrices = () => {
	const priceOpRef = useRef<OverlayPanel>(null);
	const [loading, setLoading] = useState(false);
	const [productLoading, setProductLoading] = useState(false);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [variantPrices, setVariantPrices] = useState<ProductVariantsPriceDto[]>([]);
	const [priceAndAmounts, setPriceAndAmounts] = useState<PriceAndAmounts[]>([]);
	const [allPrice, setAllPrice] = useState(0)
	const [productOptions, setProductOptions] = useState<PrDropdownType[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<PrDropdownType>();
	const [sizeOptions, setSizeOptions] = useState<PrDropdownType[]>([]);
	const [colorOptions, setColorOptions] = useState<PrDropdownType[]>([]);
	const [selectedSize, setSelectedSize] = useState<PrDropdownType>();
	const [selectedColor, setSelectedColor] = useState<PrDropdownType>();
	const [pagination, setPagination] = useState<PaginationDto>({ pageNumber: 1, itemCount: 20, orderBy: 2, first: 0 });
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const inputStyle = { backgroundColor: 'white', borderWidth: 1, borderColor: '#bdbdbd', borderRadius: '0.6rem', background: 'none', height: 40, alignItems: 'center', width: '100%' };

	const getProducts = async () => {
		try {
			setProductLoading(true);

			const request = new GetCoreProductsForListRequest({});
			request.pagination = { pageNumber: 1, itemCount: null, orderBy: 2, first: 0 };

			const response = await productService.getCoreProductsForList(request);

			if (!response.isSuccess) throw '';

			const options = response.data && response?.data.map((prod) => ({ label: prod.productName + ' - ' + prod.brandName + ' - ' + prod.modelName, value: prod.id }));

			if (!options) return;

			setProductOptions(options);
		} finally {
			setProductLoading(false);
		}
	};

	const getProductOptions = async () => {
		if (!selectedProduct) return;

		try {
			setOptionsLoading(true);

			const request = new GetProductVariantsPriceOptionsRequest(selectedProduct.value.toString());

			const response = await productVariantService.getProductVariantPriceOptions(request);

			if (!response.isSuccess || !response.data) throw '';

			const optionsSize = response.data.productAndSizes.map((_size) => ({ label: _size.sizeName, value: _size.sizeId })) as PrDropdownType[];
			const optionsColor = response.data.productAndColors.map((_color) => ({ label: _color.colorName, value: _color.colorId })) as PrDropdownType[];

			setSizeOptions([{ label: 'All', value: '' }, ...optionsSize]);
			setColorOptions([{ label: 'All', value: '' }, ...optionsColor]);
		} catch (error) {
			setSizeOptions([]);
			setColorOptions([]);
		} finally {
			setOptionsLoading(false);
		}
	};

	const getVariantPrices = async (paginationReq: PaginationDto, useFilter: boolean) => {
		if (!selectedProduct) return;
		try {
			if (!paginationReq) throw '';

			setLoading(true);

			const request = new GetProductsVariantsAndPriceRequest({
				pagination: paginationReq,
				productId: selectedProduct?.value.toString(),
				sizeId: useFilter ? selectedSize?.value.toString() : null,
				colorId: useFilter ? selectedColor?.value.toString() : null
			});

			const response = await productVariantService.getProductVariantAndPrices(request);

			if (!response.isSuccess) throw '';

			setVariantPrices(response.data || []);
			setPaginationResponse(response.pagination);
		} catch (error) {
			setVariantPrices([]);
		} finally {
			setLoading(false);
		}
	};

	const onSave = async () => {
		try {
			setSaveLoading(true)

			const request = new UpdateProductVariantPriceRequest({ priceAndAmounts: priceAndAmounts })

			const response = await productVariantService.updateProductVariantPrice(request)

			if (!response.isSuccess) throw ''

			getVariantPrices(pagination, true)
			setPriceAndAmounts([])
		} catch (error) {
		} finally {
			setSaveLoading(false)
		}
	};

	const onPriceChange = (priceId: string, amount: number) => {
		if (amount === 0) {
			setPriceAndAmounts(priceAndAmounts.filter(_price => _price.priceId !== priceId))
			return;
		}
		if (priceAndAmounts.length === 0) {
			setPriceAndAmounts([{ priceId: priceId, amount: Math.round(amount * 100) }]);
		} else if (!priceAndAmounts.find(_price => _price.priceId === priceId )) {
			setPriceAndAmounts([...priceAndAmounts, { priceId: priceId, amount: Math.round(amount * 100) }]);
		}
		else {
			setPriceAndAmounts(
				priceAndAmounts.map((_price) => {
					if (_price.priceId === priceId) return { priceId: priceId, amount: Math.round(amount * 100) };
					else return {..._price};
				})
			);
		}
	};

	const applyAllPrice = () => {
		if (allPrice === 0) {
			setPriceAndAmounts([]);
			return;
		}

		const priceList: PriceAndAmounts[] = variantPrices.map((_vari) => ({ priceId: _vari.priceId, amount: Math.round(allPrice * 100)}));

		setPriceAndAmounts(priceList);
		priceOpRef.current?.hide();
	};

	const priceHeader = () => (
		<React.Fragment>
			<span>Price</span>
			<i className="pi pi-fw ml-1 pi-cog text-indigo-500 cursor-pointer" onClick={(e) => priceOpRef.current?.toggle(e)} />
			<OverlayPanel ref={priceOpRef}>
				<div className='flex gap-2'>
					<InputNumber onChange={(e) => setAllPrice(e.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} inputStyle={{ ...inputStyle, maxWidth: '8rem' }} />
					<PrButton text="Apply All" type='secondary' onClick={() => applyAllPrice()} />
				</div>
			</OverlayPanel>
		</React.Fragment>
	);

	const priceBodyTemplate = (rowData: ProductVariantsPriceDto) => {
		let priceHandler;

		if (priceAndAmounts && priceAndAmounts.length > 0) {
			let priceItem = priceAndAmounts.find(_price => _price.priceId === rowData.priceId)
			if (priceItem && priceItem?.amount > 0) {
				priceHandler = priceItem.amount / 100
			} else priceHandler = null
		}
		return (
			<div className="w-full">
				<InputNumber inputId="currency-us" placeholder={currencyHelper.formatPrice(rowData.price.formattedPricePerUnit)} value={priceHandler} onChange={(e) => onPriceChange(rowData.priceId, e.value || 0)} mode="currency" currency="USD" locale="en-US" min={0} inputStyle={{ ...inputStyle, color: priceHandler ? 'green' : 'lightgreen', fontWeight: priceHandler ? 500 : 400 }} />
			</div>
		);
	}

	const clearFilters = () => {
		setSelectedColor(undefined);
		setSelectedSize(undefined);
		getVariantPrices(pagination, false);
	};

	const applyFilters = () => {
		setPriceAndAmounts([]);
		getVariantPrices(pagination, true);
	}

	useEffect(() => {
		if (!selectedProduct || !pagination) return;

		setSelectedColor(undefined);
		setSelectedSize(undefined);
		setPriceAndAmounts([]);
		getVariantPrices(pagination, false);
		getProductOptions();
	}, [selectedProduct]);

	useEffect(() => {
		if (!pagination) return;

		getVariantPrices(pagination, true);
	}, [pagination]);

	useEffect(() => {
		getProducts();
	}, []);

	return (
		<main className="container relative">
			<section className="container-header">
				<h1 className="container-header-title">Product Variant Prices</h1>
			</section>

			<section className={styles.priceMainBody}>
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the variant price page from the options below.</p>

				<PrDropdown options={productOptions} value={selectedProduct} onChange={(e) => setSelectedProduct(e)} disabled={productLoading} filter placeholder="Select a product" />
			</section>

			<section className="container-body">
				<div className={styles.actions}>
					<div className={styles.filters}>
						<PrDropdown options={colorOptions} value={selectedColor} onChange={(e) => setSelectedColor(e)} disabled={optionsLoading} placeholder="Select a color" />
						<PrDropdown options={sizeOptions} value={selectedSize} onChange={(e) => setSelectedSize(e)} disabled={optionsLoading} placeholder="Select a size" />
						<PrButton text="Apply Filters" onClick={applyFilters} loading={optionsLoading} />
						<PrButton onClick={clearFilters} loading={optionsLoading} type="secondary" icon={<span className="pi pi-filter-slash" />} />
					</div>
					{priceAndAmounts.length > 0 ? <PrButton text="Save Prices"  onClick={onSave} loading={saveLoading} /> : null}
				</div>

				<div className={styles.pricesTableWrapper}>
					<div className={styles.pricesTableHeader}>
						<div className={styles.pricesTableHeaderTitles}>
							<span className={styles.pricesTableHeaderTitlesVariant}>Product Variant</span>
							<span className={styles.pricesTableHeaderTitlesSize}>Size</span>
							<span className={styles.pricesTableHeaderTitlesColor}>Color</span>
							<span className={styles.pricesTableHeaderTitlesPrice}>{priceHeader()}</span>
						</div>
					</div>
				</div>

				<div className={styles.pricesTableBody}>
					{variantPrices.length > 0 ? (
						variantPrices.map((_product) => (
							<div key={_product.priceId} className={styles.pricesTableBodyItemWrapper}>
								<div className="px-4">
									<div className={styles.pricesTableBodyWrapper}>
										<div className={styles.pricesTableBodyItems}>
											<div className={styles.pricesTableBodyItemVariant}>{_product.generalInfo}</div>
											<div className={styles.pricesTableBodyItemSize}>{_product.sizeName}</div>
											<div className={styles.pricesTableBodyItemColor}>{_product.colorName}</div>
											<div className={styles.pricesTableBodyItemPrice}>{priceBodyTemplate(_product)}</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div className={`${styles.pricesTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any product stock yet</p>
						</div>
					)}
				</div>

				{!!paginationResponse && paginationResponse.totalItemCount > 0 && variantPrices.length > 0 ? (
					<div className={`${styles.productStocksTableBodyItemWrapper} ${styles.loadMore}`}>
						<Paginator
							template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
							first={pagination.first}
							rows={pagination.itemCount || 20}
							totalRecords={paginationResponse.totalItemCount}
							rowsPerPageOptions={[20, 40, 60]}
							onPageChange={(event) => {
								setPagination({
									first: event.first,
									itemCount: event.rows,
									pageNumber: event.page + 1,
									orderBy: PaginationOrderByEnum.Descending
								});
							}}
						/>
					</div>
				) : null}
			</section>

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
	);
};

export default ProductPrices;
