import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import TextareaInput from '../../../../helpers/widgets/Forms/TextareaInput';
import TextInput from '../../../../helpers/widgets/Forms/TextInput';
import { CreateResourceRequest } from '../../../../models/requests/resources/create-resource-request';
import resourceService from '../../../../services/resource-service';
import { Chips, ChipsChangeParams } from 'primereact/chips';

type props = {
	isVisible: boolean;
	setVisible: Function;
	getResources: Function;
};

const ResourceCreate = ({ isVisible, setVisible, getResources }: props) => {
	const formikRef = useRef<FormikProps<CreateResourceRequest>>(null);
	const initialValues: CreateResourceRequest = { title: '', description: '', identities: [], videoMediaBroadcasterType: 1 };
	const [loading, setLoading] = useState(false);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required !'),
		description: Yup.string().required('Description is required !')
	});

	const onSave = (values: CreateResourceRequest) => {
		setLoading(true);

		resourceService
			.createWithVideoMedia(values)
			.then((response) => {
				if (!response) return;

				setVisible(false);
				getResources();
			})
			.finally(() => setLoading(false));
	};

	const findVideoIdChange = (event: ChipsChangeParams) => {
		if (!formikRef.current) return;

		const newValues = event.value.map((text: string) => {
			try {
				const searchParams = new URL(text).searchParams;
				if (!searchParams) return text;
				return searchParams.get('v');
			} catch (error) {
				return text;
			}
		});
		formikRef.current.setFieldValue(event.target.name, newValues);
	};

	return (
		<Dialog
			header="New Help Resource"
			modal
			visible={isVisible}
			onHide={() => {
				setVisible(false);
			}}>
			<Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
				{({ values, handleChange }) => (
					<Form className="text-right">
						<div className="my-2">
							<TextInput name="title" value={values.title} onChange={handleChange} placeholder="Enter resource title" label={''} />
						</div>
						<div className="my-2">
							<TextareaInput name="description" value={values.description} rows={5} onChange={handleChange} placeholder="Enter resource description." label={''} />
						</div>
						<div className="my-2">
							<Chips value={values.identities} max={1} name="identities" placeholder={values.identities.length > 0 ? '' : 'Enter youtube link or youtube video id'} onChange={findVideoIdChange} className="w-full" />
						</div>
						<div className="col-12 flex justify-content-end">
							<Button type="submit" label="Create" className="h-full px-8" />
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ResourceCreate;
