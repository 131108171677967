import { AreaSelector, IArea } from '@bmunozg/react-image-area';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toastHelper from '../../../../../../helpers/toast.helper';
import { ProductMockup, ProductMockupArea } from '../../../../../../models/dtos/product/product-mockup';
import { UpdateMockupArea, UpdateMockupRequest } from '../../../../../../models/requests/products/update-mockup-request';
import productMockupService from '../../../../../../services/products/product-mockup-service';
import { CreateMockupRequest } from 'models/requests/products/create-mockup-request';

const StepFour = ({ setStep, step }: any) => {
	const navigate = useNavigate();
	const { productId } = useParams();
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const fileInputRef = useRef<HTMLInputElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const previousImageRef = useRef<HTMLImageElement>(null);

	const [areas, setAreas] = useState<IArea[]>([]);
	const [previousAreas, setPreviousAreas] = useState<IArea[]>([]);

	const [file, setFile] = useState<File | undefined>(undefined);

	const [mockups, setMockups] = useState<ProductMockup[] | null>([]);

	const getMockups = useCallback(() => {
		if (!productId) return;

		setLoading(true);
		productMockupService
			.get(productId)
			.then((response) => {
				if (!response.isSuccess || !response.data) return;

				setMockups(response.data);
				setPreviousAreas(response.data[0].areas.map((area: ProductMockupArea) => ({ ...area, height: area.height, width: area.width, x: area.left, y: area.top, unit: 'px' })));
			})
			.finally(() => setLoading(false));
	}, [productId]);

	const onChangeHandler = (areas: IArea[]) => setAreas(areas);

	const onPreviousChangeHandler = (areas: IArea[]) => setPreviousAreas(areas.map((area: any) => ({ ...area, id: previousAreas.map((prevArea: any) => prevArea.id)[0], productMockupId: mockups ? mockups[0].id : '' })));

	const uploadImageHandler = () => fileInputRef.current?.click();

	const createNew = () => {
		if (areas.length < 1 || !file || !productId || !imageRef.current) return;

		const { width, height } = file && imageRef.current ? imageRef.current.getBoundingClientRect() : previousImageRef.current ? previousImageRef.current.getBoundingClientRect() : { width: 0, height: 0 };

		const request: CreateMockupRequest = {
			productId,
			imageFile: file,
			imageWidth: width.toFixed(2),
			imageHeight: height.toFixed(2),
			productMockupAreas: areas.map((area) => ({ height: area.height.toFixed(2), width: area.width.toFixed(2), left: area.x.toFixed(2), top: area.y.toFixed(2) }))
		};

		setSubmitLoading(true);
		productMockupService
			.create(request)
			.then((res) => res.isSuccess && navigate('/product/detail/' + productId))
			.finally(() => setSubmitLoading(false));
	};

	const update = () => {
		if (!productId || !mockups) return;

		const { width, height } = file && imageRef.current ? imageRef.current.getBoundingClientRect() : previousImageRef.current ? previousImageRef.current.getBoundingClientRect() : { width: 0, height: 0 };
		const mockupAreas = areas.length > 0 ? areas.map((area) => ({ id: previousAreas.map((prevArea: any) => prevArea.id)[0], productMockupId: mockups[0].id, height: area.height.toFixed(2), width: area.width.toFixed(2), left: area.x.toFixed(2), top: area.y.toFixed(2) } as UpdateMockupArea)) : previousAreas.length > 0 ? previousAreas.map((area: any) => ({ id: area.id, productMockupId: mockups[0].id, height: area.height.toFixed(2), width: area.width.toFixed(2), left: area.x.toFixed(2), top: area.y.toFixed(2) } as UpdateMockupArea)) : [];

		const request: UpdateMockupRequest = {
			id: mockups[0].id,
			productId,
			imageFile: file || null,
			imageWidth: width.toFixed(2),
			imageHeight: height.toFixed(2),
			updateMockupAreas: mockupAreas,
			isNewImage: !!file
		};

		setSubmitLoading(true);
		productMockupService
			.update(request)
			.then((res) => res.isSuccess && navigate('/product/detail/' + productId))
			.finally(() => setSubmitLoading(false));
	};

	const confirmAndNext = () => {
		if (!productId || !mockups) return;

		!!mockups[0]?.id && mockups[0].id.length > 0 ? update() : createNew();
	};

	const handleFileInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;

		const selectedFile = event.target.files[0];
		const fileReader = new FileReader();

		fileReader.onloadend = () => {
			const img = new Image();

			img.src = fileReader.result as string;

			img.onload = () => {
				const width = img.naturalWidth;
				const height = img.naturalHeight;

				if (width !== 800 || height !== 800) {
					toastHelper.warning('Image size must be a 800x800px, Please try another image');
					return;
				}

				setFile(selectedFile);
			};
		};

		fileReader.readAsDataURL(selectedFile);
	};

	useEffect(() => {
		getMockups();
	}, [getMockups]);

	useEffect(() => {
		if (!file || !imageRef.current) return;

		imageRef.current.src = URL.createObjectURL(file);
	}, [file]);

	return (
		<div className="grid">
			{!loading ? (
				<div className="col-12 lg:col-8">
					<input ref={fileInputRef} accept=".jpg, .jpeg, .png, .webp" onChange={handleFileInputOnChange} type="file" className="hidden" />

					{previousAreas && mockups && !file && (
						<div className="w-full">
							<AreaSelector areas={previousAreas} onChange={onPreviousChangeHandler} maxAreas={1} globalAreaStyle={{ backgroundColor: 'white', opacity: 0.5, border: '1.5px dashed' }} wrapperStyle={{ width: '100%' }}>
								<img ref={previousImageRef} src={mockups[0]?.image?.url || ''} alt="Selected Mockup Image" className="block" style={{ height: mockups[0]?.imageHeight || 0, width: mockups[0]?.imageWidth || 0 }} />
							</AreaSelector>
						</div>
					)}

					{file ? (
						<AreaSelector areas={areas} onChange={onChangeHandler} maxAreas={1} globalAreaStyle={{ backgroundColor: 'white', opacity: 0.5, border: '1.5px dashed' }}>
							<img ref={imageRef} alt="Selected Mockup Image" className="block w-full" />
						</AreaSelector>
					) : null}

					<div className="grid mt-3">
						<div className="col-12 text-right">
							<Button onClick={uploadImageHandler} loading={submitLoading} label="Change image" icon="pi pi-image" className="px-5 mr-3" />
							<Button onClick={confirmAndNext} loading={submitLoading} icon="pi pi-save" label="Save" className="px-5" />
						</div>
					</div>
				</div>
			) : (
				<div className="col-12 lg:col-8">
					<div className="grid mb-2">
						<div className="col-12">
							<Skeleton height="30rem" />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default StepFour;
