import { useState } from 'react';
import StepOne from '../components/StepOne';
import StepTwo from '../components/StepTwo';
import StepThree from '../components/StepThree';
import StepFour from '../components/StepFour';
import StepFive from '../components/StepFive';
import StepSix from '../components/StepSix';

const ProductCreate = () => {
	const [step, setStep] = useState<number>(0);

	return (
		<div className="card">
			{step === 0 ? <h5>Create Product</h5> : null}
			{step === 1 ? <h5>Add Product Images</h5> : null}
			{step === 2 ? <h5>Add Product Details</h5> : null}
			{step === 3 ? <h5>Add Variant</h5> : null}
			{step === 4 ? <h5>Add Attachments</h5> : null}
			{step === 5 ? <h5>Add Mockup</h5> : null}

			{step === 0 ? <StepOne setStep={setStep} /> : null}
			{step === 1 ? <StepTwo setStep={setStep} /> : null}
			{step === 2 ? <StepThree setStep={setStep} /> : null}
			{step === 3 ? <StepFour setStep={setStep} /> : null}
			{step === 4 ? <StepFive setStep={setStep} /> : null}
			{step === 5 ? <StepSix setStep={setStep} /> : null}
		</div>
	);
};

export default ProductCreate;
