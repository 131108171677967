import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { CreateBrandRequest } from '../../../../../models/requests/brands/create-brand-request';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import brandService from '../../../../../services/brand-service';
import { FileUpload, FileUploadHeaderTemplateOptions } from 'primereact/fileupload';
import TextInput from '../../../../../helpers/widgets/Forms/TextInput';

type Props = {
    isVisible: boolean;
    setVisible: Function;
    getBrands: Function;
};

const BrandCreate = ({ isVisible, setVisible, getBrands }: Props) => {
    const initialValues: CreateBrandRequest = { name: '' };

    const validationScheme = Yup.object().shape({ name: Yup.string().required('Brand name is required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

    const submitForm = (values: CreateBrandRequest) => {
        brandService.createWithImageMedia(values).then((response) => {
            if (!response.isSuccess) return;

            setVisible(false);
            getBrands();
        });
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };
    
    return (
        <Dialog visible={isVisible} header="New Brand" modal onHide={() => setVisible(false)}>
            <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
                {({ values, handleChange, setFieldValue }) => (
                    <Form>
                            <div className="grid">
                                <div className='col-12'>
                                    <TextInput name="name" value={values.name} onChange={handleChange} placeholder="Enter brand name" label={''} />
                                </div>

                                <div className="col-12">
                                    <FileUpload name="image" accept="image/*" chooseLabel="Select brand image" onSelect={(e) => setFieldValue('imageFiles', e.files[0])} headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} />
                                </div>

                                <div className="col-12 flex justify-content-end">
                                    <Button type="submit" label="Create" className='h-full px-8' />
                                </div>
                            </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default BrandCreate;
