import { AreaSelector, IArea } from '@bmunozg/react-image-area';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toastHelper from '../../../../../helpers/toast.helper';
import { CreateMockupRequest, ProductMockupArea } from '../../../../../models/requests/products/create-mockup-request';
import productMockupService from '../../../../../services/products/product-mockup-service';

type Props = {
    setStep: Function;
};

const StepSix = ({ setStep }: Props) => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [loading,setLoading] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const [areas, setAreas] = useState<IArea[]>([]);
    const [file, setFile] = useState<File | undefined>(undefined);

    const onChangeHandler = (areas: IArea[]) => setAreas(areas);

    const uploadImageHanlder = () => fileInputRef.current?.click();

    const confirmAndNext = () => {
        if (areas.length < 1 || !file || !productId || !imageRef.current) return;

        const { width, height } = imageRef.current.getBoundingClientRect();

        const request: CreateMockupRequest = {
            productId,
            imageFile: file,
            imageWidth: width.toFixed(2),
            imageHeight: height.toFixed(2),
			productMockupAreas: areas.map((area) => ({ height: area.height.toFixed(2), width: area.width.toFixed(2), left: area.x.toFixed(2), top: area.y.toFixed(2) } as ProductMockupArea))
		};

		setLoading(true)
		productMockupService.create(request).then(res => res.isSuccess && navigate('/product/detail/' + productId)).finally(() => setLoading(false));
    };

    const handleFileInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const selectedFile = event.target.files[0];
        const fileReader = new FileReader();

        fileReader.onloadend = () => {
            const img = new Image();

            img.src = fileReader.result as string;

            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;

                if (width !== 800 || height !== 800) {
                    toastHelper.warning('Image size must be a 800x800px, Please try another image');
                    return;
                }

                setFile(selectedFile);
            };
        };

        fileReader.readAsDataURL(selectedFile);
    };

    useEffect(() => {
        if (!file || !imageRef.current) return;

        imageRef.current.src = URL.createObjectURL(file);
    }, [file]);

    return (
        <div className="grid">
            <div className="col-12 lg:col-6">
                <input ref={fileInputRef} accept=".jpg, .jpeg, .png, .webp" onChange={handleFileInputOnChange} type="file" className="hidden" />

                {file ? (
					<AreaSelector areas={areas} onChange={onChangeHandler} maxAreas={1} globalAreaStyle={{ backgroundColor: 'white', opacity: 0.5, border: '1.5px dashed' }}>
                        <img ref={imageRef} alt="Selected Mockup Image" className="block w-full" />
                    </AreaSelector>
                ) : null}

                <div className={file ? 'mt-3' : ''}>
                    <Button onClick={uploadImageHanlder} label="Choose image" icon="pi pi-image" className="px-5 mr-3" />
                    <Button onClick={confirmAndNext} loading={loading} disabled={areas.length < 1 || !file} icon="pi pi-arrow-right" label="Confirm & Complete" className="px-5" />
                </div>
            </div>

            <div className="col-12 lg:col-6"></div>
        </div>
    );
};

export default StepSix;
