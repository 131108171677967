import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const FullScreenLoader: React.FC = () => {
	const loaderContainerStyle: React.CSSProperties = {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		zIndex: 9999
	};

	const spinnerStyle: React.CSSProperties = {
		width: '50px',
		height: '50px',
		border: '6px solid rgba(0, 0, 0, 0.1)',
		borderTop: '6px solid #3498db',
		borderRadius: '50%',
		animation: 'spin 1s linear infinite'
	};

	return (
		<div style={loaderContainerStyle}>
			<ProgressSpinner className="mr-auto ml-auto" style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
		</div>
	);
};

export default FullScreenLoader;
