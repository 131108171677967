import HttpBaseService from 'services/_common/http-base-service';
import { CreateTemplateCategoryRequest } from './create-template-category/create-template-category.request';
import { CreateTemplateCategoryResponse } from './create-template-category/create-template-category.response';
import { UpdateTemplateCategoryRequest } from './update-template-category/update-template-category.request';
import { UpdateTemplateCategoryResponse } from './update-template-category/update-template-category.response';
import { SoftDeleteTemplateCategoryRequest } from './soft-delete-template-category/soft-delete-template-category.request';
import { GetTemplateCategoryRequest } from './get-template-category/get-template-category.request';
import { GetTemplateCategoryResponse } from './get-template-category/get-template-category.response';

class TemplateCategoryService extends HttpBaseService {
	constructor() {
		super('template-categories');
	}

	async create(request: CreateTemplateCategoryRequest): Promise<CreateTemplateCategoryResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}

	async update(request: UpdateTemplateCategoryRequest): Promise<UpdateTemplateCategoryResponse> {
		return await this.putData(request.getConvertUriExtension, request, true);
	}

	async softDelete(request: SoftDeleteTemplateCategoryRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension);
	}

	async getAll(request: GetTemplateCategoryRequest): Promise<GetTemplateCategoryResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new TemplateCategoryService();
