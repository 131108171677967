import { CreateOrderContext, CreateOrderContextType, orderSteps } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import React from 'react';
import { UsaFlagIcon } from '../icon/UsaFlagIcon';
import currencyHelper from 'helpers/curreny.helper';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';

const ShippingInfo = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

	return (
		<div>
			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping
			</h2>

			<div className="flex gap-4">
				<div className="w-3">
					<h4 className="mb-4" style={{ fontSize: '1rem' }}>
						Shipping From
					</h4>

					<p>
						<UsaFlagIcon style={{ width: '1.5625rem', height: '0.8125rem' }} />
						<span className="ml-3">United States</span>
					</p>
				</div>

				<div className="w-3">
					<h4 className="mb-4" style={{ fontSize: '1rem' }}>
						Shipping to
						<span
							role="button"
							tabIndex={0}
							className="text-primary underline cursor-pointer ml-2 font-normal"
							onClick={() => {
								context.setIsShowShippingForm(true);
								context.setOrderStep(orderSteps[1]);
							}}>
							Edit
						</span>
					</h4>

					<p className="mb-0">
						{context.request.customerFullName}{' '}
						{context.request.deliveryType === DeliveryTypes.PickUp ? (
							<>
								- <b>Pick Up</b>
							</>
						) : null}
					</p>

					{context.request.deliveryType === DeliveryTypes.Shipment && !!context.request.deliveryAddress ? (
						<>
							<p className="mb-0">{context.request.deliveryAddress?.firstLine}</p>

							{!!context.request.deliveryAddress?.secondLine ? <p className="mb-0">{context.request.deliveryAddress?.secondLine}</p> : null}

							<p className="mb-0">
								{context.request.deliveryAddress?.city}, {context.request.deliveryAddress?.state} {context.request.deliveryAddress?.zip} {context.request.deliveryAddress?.countryIso}
							</p>

							{!!context.request.deliveryAddress.receiverPhoneNumber ? (
								<p className="mb-0">
									{context.request.deliveryAddress?.phonePrefix.label}
									{context.request.deliveryAddress?.receiverPhoneNumber}
								</p>
							) : null}
						</>
					) : null}
				</div>

				<div className="w-6">
					{!!context.selectedRate && orderSteps[1].isCompleted && context.orderStep.value !== orderSteps[1].value ? (
						<React.Fragment>
							<h4 className="mb-4" style={{ fontSize: '1rem' }}>
								Shipping method
								<span
									role="button"
									tabIndex={0}
									className="text-primary underline cursor-pointer ml-2 font-normal"
									onClick={() => {
										context.setIsShowShippingForm(false);
										context.setOrderStep(orderSteps[1]);
									}}>
									Edit
								</span>
							</h4>

							<p>
								{currencyHelper.formatPrice(+context.selectedRate.amount)} - {context.selectedRate.serviceName}
							</p>
						</React.Fragment>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ShippingInfo;
