import HttpBaseService from 'services/_common/http-base-service';
import { DataResponse } from 'models/_commons/responses/data-response';
import { GetPrintCenterListForAdminResponse } from './get-print-center-list-for-admin/get-print-center-list-for-admin.response';
import { GetPrintCenterListForAdminRequest } from './get-print-center-list-for-admin/get-print-center-list-for-admin.request';
import { GetPrintCenterForAdminRequest } from './get-print-center-for-admin/get-print-center-for-admin.request';
import { GetPrintCenterForAdminResponse } from './get-print-center-for-admin/get-print-center-for-admin.response';
import { UpdatePrintCenterRequest } from './update/update-print-center.request';
import { UpdatePrintCenterResponse } from './update/update-print-center.response';
import { UpdateStatuRequest } from './update-statu/update-statu.request';
import { UpdateStatuResponse } from './update-statu/update-statu.response';
import { DataPaginationResponse } from 'models/_commons/responses/data-pagination-response';
import { AvailablePrintCentersForTheOrderRequest } from './available-print-centers-for-the-order/available-print-centers-for-the-order.request';
import { AvailablePrintCentersForTheOrderResponse } from './available-print-centers-for-the-order/available-print-centers-for-the-order.response';
import { CreatePrintCenterRequest } from './create-print-center/create-print-center.request';
import { CreatePrintCenterResponse } from './create-print-center/create-print-center.response';

class PrintCenterService extends HttpBaseService {
	constructor() {
		super('print-centers');
	}

	async getPrintCenterListForAdmin(request: GetPrintCenterListForAdminRequest): Promise<DataPaginationResponse<GetPrintCenterListForAdminResponse>> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getPrintCenterForAdmin(request: GetPrintCenterForAdminRequest): Promise<DataResponse<GetPrintCenterForAdminResponse>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getAvailablePrintCentersForTheOrder(request: AvailablePrintCentersForTheOrderRequest): Promise<AvailablePrintCentersForTheOrderResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async updatePrintCenter(request: UpdatePrintCenterRequest): Promise<UpdatePrintCenterResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async updateStatu(request: UpdateStatuRequest): Promise<UpdateStatuResponse> {
		return await this.patchData(request.getConvertUriExtension, request);
	}

	async create(request: CreatePrintCenterRequest): Promise<CreatePrintCenterResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new PrintCenterService();
