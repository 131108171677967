import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import TextInput from 'helpers/widgets/Forms/TextInput';
import { Button } from 'primereact/button';
import TextMaskInput from 'helpers/widgets/Forms/TextMaskInput';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CreatePrintCenterRequest } from 'features/printCenters/_common/create-print-center/create-print-center.request';
import printCenterService from 'features/printCenters/_common/print-center.service';

type Props = {
	visible: boolean;
	setVisible: (state: boolean) => void;
	getMerchants: () => void;
};

const PrintCenterCreate = ({ visible, setVisible, getMerchants }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues = new CreatePrintCenterRequest({});
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is Required').min(3, 'Name must be at least 3 characters.').max(50, 'Name must be a maximum of 50 characters.'),
		surname: Yup.string().required('Surname is Required').min(3, 'Surname must be at least 3 characters.').max(50, 'Surname must be a maximum of 50 characters.'),
		email: Yup.string().email('Email address must be valid.').required('Email is Required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
		businessName: Yup.string().required('Business Name is Required').min(3, 'Business Name must be at least 3 characters.'),
		feinNumber: Yup.string().required('Fein Number is Required'),
		password: Yup.string().required('Password is Required').min(8, 'Password must be at least 8 characters.'),
		isTermsConfirmed: Yup.bool().required('Terms is required').oneOf([true], 'Terms must be checked')
	});

	const handleSubmit = async (values: CreatePrintCenterRequest) => {
		try {
			setLoading(true);

			values.passwordConfirm = values.password;

			const response = await printCenterService.create(values);
			if (!response.isSuccess) throw '';

			handleHide();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleHide = () => {
		setVisible(false);
		getMerchants();
	};

	return (
		<Dialog visible={visible} header="Create New Print Center" modal onHide={handleHide}>
			<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
				{({ values, handleChange }) => (
					<Form>
						<div className="grid relative">
							<div className="col-12 xl:col-6">
								<TextInput label="Business Name" name="businessName" value={values.businessName} onChange={handleChange} placeholder="Enter business name" />
							</div>

							<div className="col-12 xl:col-6">
								<TextMaskInput label="Fein Number" mask="99-999999" name="feinNumber" value={values.feinNumber} onChange={handleChange} placeholder="Enter fein number" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Enter name" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Surname" name="surname" value={values.surname} onChange={handleChange} placeholder="Enter surname" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Email" name="email" value={values.email} onChange={handleChange} type="email" placeholder="Enter email" />
							</div>

							<div className="col-12 xl:col-6">
								<TextInput label="Password" name="password" value={values.password} onChange={handleChange} placeholder="Enter password" />
							</div>

							<div className="col-12 text-right mt-3">
								<Button type="submit" label="Create Print Center" loading={loading} className="h-full px-6" />
							</div>

							{!!loading ? (
								<div className="custom-loading">
									<ProgressSpinner className="p-progress-color" strokeWidth="4" />
								</div>
							) : null}
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default PrintCenterCreate;
