import { GetReorderDetailRequest } from 'features/reorders/_common/get-reorder-detail/get-reorder-detail.request';
import { ReorderDetail, ReorderItem } from 'features/reorders/_common/get-reorder-detail/get-reorder-detail.response';
import { reorderService } from 'features/reorders/_common/reorder.service';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './ReOrderDetail.module.scss';
import dateHelper from 'helpers/dateHelper';
import GoBackButton from 'helpers/widgets/Buttons/GoBackButton';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import { Tooltip } from 'primereact/tooltip';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import currencyHelper from 'helpers/curreny.helper';
import { PrSwitchToggle } from 'helpers/widgets/Printram/Switch/PrSwitchToggle';
import PrTextareaInput from 'helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import { confirmDialog } from 'primereact/confirmdialog';
import { SaveAdminReviewsToReorderRequest } from 'features/reorders/_common/save-admin-reviews-to-reorder/save-admin-reviews-to-reorder.request';

export const ReOrderDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [reorderDetail, setReorderDetail] = useState<ReorderDetail | undefined>(undefined);
	const [request, setRequest] = useState<SaveAdminReviewsToReorderRequest | undefined>(undefined);

	const [acceptedCostProductIds, setAcceptedCostProductIds] = useState<string[]>([]);

	const getReorderDetail = async () => {
		try {
			setLoading(true);
			if (!id) throw '';

			const response = await reorderService.getReorderDetail(new GetReorderDetailRequest(id));
			if (!response.isSuccess || !response.data) throw '';

			setReorderDetail(response.data);
			setRequest(
				new SaveAdminReviewsToReorderRequest({
					reorderId: id,
					oldOrderShippingCostAccepted: response.data.deliveryType !== 1 ? true : false,
					items: response.data?.orderItemDetails.map((_item) => ({
						reOrderItemId: _item.newItem.id,
						productCostAccepted: _item.extraProductCostAccepted
					}))
				})
			);
		} catch (error) {
			setReorderDetail(undefined);
			setRequest(undefined);
		} finally {
			setLoading(false);
		}
	};

	const calculateSidePrice = (item: ReorderItem) => {
		let price = item.price.pricePerUnit;

		for (const side of item.sides) price += side.printPrice.pricePerUnit;

		return price / 100;
	};

	const ticketStatuHandler = () => (reorderDetail?.isTicketActive ? 'Waiting' : 'Closed');

	const statuStyleHandler = () => {
		if (!reorderDetail?.isTicketActive) return styles.isClosedStatus;
	};

	useEffect(() => {
		if (!reorderDetail) getReorderDetail();
	}, [id]);

	return (
		<main className="container relative mb-8 pb-5">
			<section className="container-header">
				<h1 className="container-header-title">#{reorderDetail?.newOrderCode}</h1>

				<div className="container-header-tools">
					<GoBackButton />
				</div>
			</section>

			{!!reorderDetail ? (
				<React.Fragment>
					{!!reorderDetail.ticketId ? <section className="container-body p-3">
						<div className="flex justify-content-between mb-3">
							<div>
								<h4 className="m-0">Ticket Information</h4>
								<p className="text-sm text-600">The ticket information details are provided below.</p>
							</div>

							<Link to={`/ticket/${reorderDetail?.ticketId}`} target="_blank">
								<PrButton text="Go Ticket Detail" type="secondary" icon={<span className="pi pi-external-link" />} />
							</Link>
						</div>

						<div className={styles.tickets}>
							<div className={styles.ticketsCard}>
								<h6>Ticket Code</h6>
								<div>{reorderDetail?.ticketCode}</div>
							</div>

							<div className={styles.ticketsCard}>
								<h6>Title</h6>
								<div>{reorderDetail?.ticketTitle}</div>
							</div>

							<div className={styles.ticketsCard}>
								<h6>Created Date</h6>
								<div>{dateHelper.formatDate(reorderDetail?.ticketCreatedDate)}</div>
							</div>

							<div className={styles.ticketsCard}>
								<h6>Status</h6>

								<div className={`${styles.ticketsStatus} ${statuStyleHandler()}`}>
									<span>{ticketStatuHandler()}</span>
								</div>
							</div>
						</div>

						<div className={`${styles.ticketsCard} mt-2`}>
							<h6>Last Comment</h6>
							<div>
								<div>
									<span className="font-bold">From: </span>
									{reorderDetail?.customerFulLName}
								</div>
								<div>
									<span className="font-bold">Date: </span>
									{dateHelper.formatDate(reorderDetail?.ticketCreatedDate)}
								</div>
							</div>

							<span className="mt-2">{reorderDetail?.ticketSellerNote}</span>
						</div>
					</section> : null }

					<section className="container-body p-3">
						<h4 className="m-0">Details</h4>
						<p className="text-sm text-600">The order details are provided below.</p>

						<div className={styles.detailWrapper}>
							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Customer Name</h6>
								</div>

								<p>{reorderDetail.customerFulLName}</p>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Customer Note</h6>
								</div>

								<p>{reorderDetail.customerNote || '-'}</p>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Gift Note</h6>
								</div>

								<p>{reorderDetail.giftNote || '-'}</p>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Seller Note</h6>
								</div>

								<p>{reorderDetail.ticketSellerNote || '-'}</p>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Delivery Type / Order Type</h6>
								</div>

								<span>{DeliveryTypes[reorderDetail.deliveryType || 0]} / </span>
								<span>Reorder</span>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Platform</h6>
								</div>

								<p>Printram</p>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Shipping Address</h6>
								</div>

								<address>{reorderDetail.orderAddress?.formattedAddress || '-'}</address>
							</div>

							<div className={styles.detailItem}>
								<div className={styles.detailItemHeader}>
									<h6>Status</h6>
								</div>

								<p>Admin Preview</p>
							</div>
						</div>
					</section>

					<section className="container-body p-3">
						<table className={styles.table}>
							<thead>
								<tr>
									<th>Product</th>
									<th>Print File</th>
									<th>QTY</th>
									<th>Retail Each</th>
									<th>Total Price</th>
								</tr>
							</thead>

							<tbody>
								{reorderDetail.orderItemDetails.map((_item, index) => (
									<React.Fragment key={index}>
										{!!_item.newItem ? (
											<tr className={styles.info}>
												<td colSpan={5}>
													<span className={styles.old}>Old</span>
												</td>
											</tr>
										) : null}

										<tr>
											<td>
												<div className={styles.productInfo}>
													<div className={styles.productImgContainer}>
														<img src={amazonS3Helper.showFile(_item.oldItem.variantMedia, { resize: '100x100' })} alt={_item.oldItem.coreProductName} width={80} />
													</div>

													<div className={styles.productInfoContainer}>
														<h6>{_item.oldItem.coreProductName}</h6>

														<span>
															Model: {_item.oldItem.brandName} {_item.oldItem.modelName}
														</span>

														<span>Size: {_item.oldItem.size.name}</span>

														<span className={styles.productInfoContainerColor}>
															<Tooltip target={`#color-${index}-${_item.oldItem.color.id}`} />
															Color:
															<span id={`color-${index}-${_item.oldItem.color.id}`} data-pr-tooltip={_item.oldItem.color.hexCode} data-pr-position="top" style={{ backgroundColor: _item.oldItem.color.hexCode }} />
															{_item.oldItem.color.name}
														</span>
													</div>
												</div>
											</td>

											<td>
												<div className={styles.printFileWrapper}>
													{_item.oldItem.sides.map((_side, sideIndex) => (
														<React.Fragment key={sideIndex}>
															<div className={styles.printFileItem}>
																<div className={styles.printFileItemImgContainer}>
																	<img src={amazonS3Helper.showFile(_side.design, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Design`} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
																</div>

																<div className={styles.printFileItemInfo}>
																	<span>{_side.printSideName} Side Print File</span>
																</div>
															</div>

															<div className={styles.printFileItem}>
																<div className={styles.printFileItemImgContainer}>
																	<img src={amazonS3Helper.showFile(_side.templateMockup, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Mockup`} />
																</div>

																<div className={styles.printFileItemInfo}>
																	<span>{_side.printSideName} Side Mockup</span>
																</div>
															</div>
														</React.Fragment>
													))}
												</div>
											</td>

											<td>
												<span style={{ lineHeight: '2.5rem', display: 'inline-block', marginRight: '2rem' }}>1</span>
											</td>

											<td>
												<PrPriceInput value={calculateSidePrice(_item.oldItem)} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} />
											</td>

											<td>
												<span className="font-bold" style={{ lineHeight: '2.5rem', display: 'inline-block' }}>
													{currencyHelper.formatPrice(calculateSidePrice(_item.oldItem))}
												</span>
											</td>
										</tr>

										{!!_item.newItem ? (
											<React.Fragment>
												<tr className={styles.info}>
													<td colSpan={5}>
														<span className={styles.new}>New</span>
													</td>
												</tr>

												<tr>
													<td>
														<div className={styles.productInfo}>
															<div className={styles.productImgContainer}>
																<img src={amazonS3Helper.showFile(_item.newItem.variantMedia, { resize: '100x100' })} alt={_item.newItem.coreProductName} width={80} />
															</div>

															<div className={styles.productInfoContainer}>
																<h6>{_item.newItem.coreProductName}</h6>

																<span>
																	Model: {_item.newItem.brandName} {_item.newItem.modelName}
																</span>

																<span>Size: {_item.newItem.size.name}</span>

																<span className={styles.productInfoContainerColor}>
																	<Tooltip target={`#color-${index}-${_item.newItem.color.id}`} />
																	Color:
																	<span id={`color-${index}-${_item.newItem.color.id}`} data-pr-tooltip={_item.newItem.color.hexCode} data-pr-position="top" style={{ backgroundColor: _item.newItem.color.hexCode }} />
																	{_item.newItem.color.name}
																</span>
															</div>
														</div>

														<div className={styles.reasonWrapper}>
															{_item.reasons.map((_reason) => (
																<div className={styles.reasonItem} key={_reason.id}>
																	<span className={styles.itemText}>{_reason.readableName}</span>
																</div>
															))}
														</div>

														<div className="flex gap-2 align-items-center mt-3">
															<PrSwitchToggle
																checked={acceptedCostProductIds.findIndex((_id) => _id === _item.newItem.id) > -1}
																onChange={(e) => {
																	setAcceptedCostProductIds((_currentIds) => (e ? _currentIds.concat(_item.newItem.id) : _currentIds.filter((_id) => _id !== _item.newItem.id)));
																}}
															/>

															<span
																role="button"
																tabIndex={0}
																className="cursor-pointer select-none"
																onClick={() => {
																	setAcceptedCostProductIds((_currentIds) => (_currentIds.findIndex((_id) => _id === _item.newItem.id) === -1 ? _currentIds.concat(_item.newItem.id) : _currentIds.filter((_id) => _id !== _item.newItem.id)));
																}}>
																Product cost
															</span>
														</div>
													</td>

													<td>
														<div className={styles.printFileWrapper}>
															{_item.newItem.sides.map((_side, sideIndex) => (
																<React.Fragment key={sideIndex}>
																	<div className={styles.printFileItem}>
																		<div className={styles.printFileItemImgContainer}>
																			<img src={amazonS3Helper.showFile(_side.design, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Design`} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
																		</div>

																		<div className={styles.printFileItemInfo}>
																			<span>{_side.printSideName} Side Print File</span>
																		</div>
																	</div>

																	<div className={styles.printFileItem}>
																		<div className={styles.printFileItemImgContainer}>
																			<img src={amazonS3Helper.showFile(_side.templateMockup, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Mockup`} />
																		</div>

																		<div className={styles.printFileItemInfo}>
																			<span>{_side.printSideName} Side Mockup</span>
																		</div>
																	</div>
																</React.Fragment>
															))}
														</div>
													</td>

													<td>
														<span style={{ lineHeight: '2.5rem', display: 'inline-block', marginRight: '2rem' }}>1</span>
													</td>

													<td>
														<PrPriceInput value={calculateSidePrice(_item.newItem)} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} />
													</td>

													<td>
														<span className="font-bold" style={{ lineHeight: '2.5rem', display: 'inline-block' }}>
															{currencyHelper.formatPrice(calculateSidePrice(_item.newItem))}
														</span>
													</td>
												</tr>
											</React.Fragment>
										) : null}
									</React.Fragment>
								))}
							</tbody>
						</table>
					</section>
				</React.Fragment>
			) : null}

			{reorderDetail?.deliveryType === 1 ? <section className="container-body p-3">
				<h4 className="m-0">Approval conditions</h4>

				<hr />

				<h5 className="m-0">Confirmation</h5>
				<p className="text-sm text-600">Select the shipping cost to be covered by printram's payment.</p>

				{reorderDetail?.oldOrderShippingService	?	<div className="flex gap-2 align-items-center mt-3">
					<PrSwitchToggle
						checked={request?.oldOrderShippingCostAccepted || false}
						onChange={(e) => {
							setRequest((_request) => new SaveAdminReviewsToReorderRequest({ ..._request, oldOrderShippingCostAccepted: e, requestedShippingRateAccepted: false }));
						}}
					/>

					<span
						role="button"
						className="cursor-pointer select-none"
						tabIndex={0}
						onClick={() => {
							setRequest((_request) => new SaveAdminReviewsToReorderRequest({ ..._request, oldOrderShippingCostAccepted: !_request?.oldOrderShippingCostAccepted, requestedShippingRateAccepted: false }));
						}}>
						{`Shipping cost (${reorderDetail?.oldOrderShippingService})`}
					</span>
				</div> : null}

				{!!reorderDetail?.requestedShipping ? (
					<div className="flex gap-2 align-items-center mt-3">
						<PrSwitchToggle
							checked={!!request?.requestedShippingRateAccepted}
							onChange={(e) => {
								setRequest((_request) => new SaveAdminReviewsToReorderRequest({ ..._request, requestedShippingRateAccepted: e, oldOrderShippingCostAccepted: false }));
							}}
						/>

						<span
							role="button"
							tabIndex={0}
							className="cursor-pointer select-none"
							onClick={() => {
								setRequest((_request) => new SaveAdminReviewsToReorderRequest({ ..._request, requestedShippingRateAccepted: !_request?.requestedShippingRateAccepted, oldOrderShippingCostAccepted: false }));
							}}>
							{reorderDetail?.requestedShipping.serviceName} - {currencyHelper.formatPrice(reorderDetail?.requestedShipping.amount)} cost
						</span>
					</div>
				) : null}

				<div className="mt-4" style={{ maxWidth: '40rem' }}>
					<PrTextareaInput
						label="Enter your note"
						placeholder="You can write your notes about shipping and products in this section."
						rows={5}
						onChange={(event) => {
							setRequest((_request) => new SaveAdminReviewsToReorderRequest({ ..._request, note: event.target.value || null }));
						}}
					/>
				</div>
			</section>: null}

			<footer className="container-body p-4">
				<div className="flex justify-content-end">
					<PrButton
						text="Send to customer"
						icon={<span className="pi pi-fw pi-angle-right" />}
						onClick={() => {
							confirmDialog({
								showHeader: false,
								message: (
									<React.Fragment>
										<b className="mb-3 block" style={{ color: '#101531' }}>
											Hey There,
										</b>
										<p>Are you sure?</p>
									</React.Fragment>
								),
								contentClassName: 'm-0 pl-2 pt-4 pb-1',
								contentStyle: { borderTopLeftRadius: 4, borderTopRightRadius: 4 },
								closeOnEscape: false,
								className: 'm-0 p-0',
								style: { maxWidth: '28.375rem' },
								footer: (options) => (
									<div className="mb-2 mt-3 flex gap-4 justify-content-center">
										<PrButton text="Close" type="secondary" btnType="button" onClick={() => options.reject()} />

										<PrButton text="Send to customer" btnType="button" onClick={() => options.accept()} />
									</div>
								),
								accept: async () => {
									try {
										setLoading(true);
										if (!request) throw '';

										const clonedRequest = structuredClone(request);

										for (const item of clonedRequest.items) {
											item.productCostAccepted = acceptedCostProductIds.findIndex((_id) => _id === item.reOrderItemId) > -1;
										}

										const response = await reorderService.saveAdminReviewstoReorder(new SaveAdminReviewsToReorderRequest(clonedRequest));
										if (!response.isSuccess) throw '';

										navigate('/re-orders');
									} catch (error) {
									} finally {
										setLoading(false);
									}
								}
							});
						}}
					/>
				</div>
			</footer>

			<PrLoading loading={loading} />
		</main>
	);
};
