import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetCoreProductsForListRequest {
	statu: number | null;
	contains: string | null;
	pagination: PaginationDto = new PaginationDto();

	get getConvertUriExtension() {
		return `get-core-products-for-list`;
	}

	constructor(props: Partial<GetCoreProductsForListRequest>) {
		this.statu = props.statu || null;
		this.contains = props.contains || null;
		this.pagination = props.pagination || new PaginationDto();
	}
}
