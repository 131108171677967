import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

type Props = {
	loading: boolean;
	isStatic?: boolean;
	text?: string;
};

export const PrLoading = ({ loading, isStatic, text }: Props) => {
	return (
		<React.Fragment>
			{loading ? (
				!isStatic ? (
					<div className="pr-loading-sticky">
						<div className="pr-spinner-wrapper">
							{!!text ? <h3>{text}</h3> : null}
							<ProgressSpinner className="p-progress-color" strokeWidth="4" />
						</div>
					</div>
				) : (
					<div className="pr-loading">
						<div className="flex flex-column text-0">
							{!!text ? <h3>{text}</h3> : null}
							<ProgressSpinner className="p-progress-color" strokeWidth="4" />
						</div>
					</div>
				)
			) : null}
		</React.Fragment>
	);
};
