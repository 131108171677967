import HttpBaseService from 'services/_common/http-base-service';
import { SurveyCreateRequest } from './survey-create/survey-create.request';
import { SurveyCreateResponse } from './survey-create/survey-create.response';
import { SurveyGetListRequest } from './survey-get-list/survey-get-list.request';
import { SurveyGetListResponse } from './survey-get-list/survey-get-list.response';
import { SurveySoftDeleteRequest } from './survey-soft-delete/survey-soft-delete.request';
import { SurveyGetRequest } from './survey-get/survey-get.request';
import { SurveyGetResponse } from './survey-get/survey-get.response';
import { SurveyUpdateRequest } from './survey-update/survey-update.request';
import { SurveyUpdateResponse } from './survey-update/survey-update.response';
import { SurveyGetAvailableByUserRequest } from './survey-get-available-by-user/survey-get-available-by-user.request';
import { SurveyGetAvailableByUserResponse } from './survey-get-available-by-user/survey-get-available-by-user.response';
import { SurveyCreateAnswerRequest } from './survey-create-answer/survey-create-answer.request';
import { SurveyCreateAnswerResponse } from './survey-create-answer/survey-create-answer.response';
import { SurveyGetResultRequest } from './survey-get-result/survey-get-result.request';
import { SurveyGetResultResponse } from './survey-get-result/survey-get-result.response';

class SurveyService extends HttpBaseService {
	constructor() {
		super('surveys');
	}

	create(request: SurveyCreateRequest): Promise<SurveyCreateResponse> {
		return this.postData(request.getConvertUriExtension, request);
	}

	update(request: SurveyUpdateRequest): Promise<SurveyUpdateResponse> {
		return this.putData(request.getConvertUriExtension, request);
	}

	createAnswer(request: SurveyCreateAnswerRequest): Promise<SurveyCreateAnswerResponse> {
		return this.postData(request.getConvertUriExtension, request);
	}

	softDelete(request: SurveySoftDeleteRequest): Promise<boolean> {
		return this.deleteData(request.getConvertUriExtension);
	}

	getAvailableByUser(request: SurveyGetAvailableByUserRequest): Promise<SurveyGetAvailableByUserResponse> {
		return this.getData(request.getConvertUriExtension);
	}

	getSurvey(request: SurveyGetRequest): Promise<SurveyGetResponse> {
		return this.getData(request.getConvertUriExtension);
	}

	getList(request: SurveyGetListRequest): Promise<SurveyGetListResponse> {
		return this.getData(request.getConvertUriExtension);
	}

	getResults(request: SurveyGetResultRequest): Promise<SurveyGetResultResponse> {
		return this.getData(request.getConvertUriExtension);
	}
}

export default new SurveyService();
