import categoryService from 'features/categories/_common/category.service';
import { CategoryDetailDto } from 'features/categories/_common/create-category/dtos/category-detail.dto';
import { EtsyCategoryBuyerTaxonomy } from 'features/categories/_common/create-category/dtos/etsy-category-buyer-taxonmy.dto';
import { UpdateCategoryRequest } from 'features/categories/_common/update-category/update-category.request';
import { CategoryBaseDto } from 'features/categories/dtos/bases/category.base.dto';
import { TaxonomyDto } from 'features/integrations/_common/get-etsy-taxonomies/dtos/taxonomy.dto';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import stringHelper from 'helpers/string.helper';
import treeHelper from 'helpers/tree.helper';
import TextInput from 'helpers/widgets/Forms/TextInput';
import TextareaInput from 'helpers/widgets/Forms/TextareaInput';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { FileUpload, FileUploadHeaderTemplateOptions } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import TreeNode from 'primereact/treenode';
import { TreeSelect } from 'primereact/treeselect';
import React, { useState } from 'react';

type Props = {
	visible: boolean;
	setVisible: (state: boolean) => void;
	getAll: () => void;
	categories: TreeNode[];
	updateCategory: CategoryBaseDto | undefined;
	taxonomies: TaxonomyDto[];
};

const UpdateCategoryModal = ({ visible, setVisible, updateCategory, categories, getAll, taxonomies }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues = new UpdateCategoryRequest({ ...updateCategory, imageFile: null, etsyCategoryBuyerTaxonomy: new EtsyCategoryBuyerTaxonomy({ buyerTaxonomyId: updateCategory?.etsyBuyerTaxonomyId || undefined }), detail: new CategoryDetailDto({ description: updateCategory?.description, bannerImageFile: null }) });

	const handleHide = (isSubmitted: boolean = false) => {
		setVisible(false);

		if (isSubmitted) getAll();
	};

	const submitForm = async (values: UpdateCategoryRequest, formikHelpers: FormikHelpers<UpdateCategoryRequest>) => {
		try {
			setLoading(true);

			const parentCategory = categories.find((_category) => _category.data.id === values.parentCategoryId);

			if (!!parentCategory) values.slug = stringHelper.slugify(`${values.name} ${parentCategory.data.name}`);
			else values.slug = stringHelper.slugify(values.name);

			const response = await categoryService.update(new UpdateCategoryRequest(values));
			if (!response.isSuccess) throw '';

			formikHelpers.resetForm();
			handleHide(true);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
		const { className, chooseButton } = options;
		return (
			<div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
				{chooseButton}
			</div>
		);
	};

	const emptyTemplate = () => {
		return (
			<div className="flex align-items-center flex-column">
				<span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
					Drag and Drop Image Here
				</span>
			</div>
		);
	};

	return (
		<Dialog modal visible={visible} header="Update an Product Category" onHide={handleHide}>
			<Formik initialValues={initialValues} onSubmit={submitForm}>
				{({ values, handleChange, setFieldValue }) => (
					<Form>
						<div className="grid relative">
							<div className="col-12 xl:col-4">
								<div className="flex align-items-center justify-content-between px-1 mb-1">
									<label>
										Parent Category <small>(Optional)</small>
									</label>
									<ErrorMessage name="parentCategoryId" component="small" className="text-red font-medium" />
								</div>

								<TreeSelect value={values.parentCategoryId} options={categories} metaKeySelection={false} onChange={(event) => setFieldValue('parentCategoryId', event.value)} placeholder="Select a parent category" className="w-full" filter />
							</div>

							<div className="col-12 xl:col-4">
								<div className="flex align-items-center justify-content-between px-1 mb-1">
									<label>Etsy Taxonomy</label>
									<ErrorMessage name="parentCategoryId" component="small" className="text-red font-medium" />
								</div>

								<TreeSelect value={values.etsyCategoryBuyerTaxonomy.buyerTaxonomyId.toString()} options={treeHelper.mapEtsyTaxonomiesForTree(taxonomies)} metaKeySelection={false} onChange={(event) => setFieldValue('etsyCategoryBuyerTaxonomy.buyerTaxonomyId', event.value)} placeholder="Select Etsy Taxonomy" className="w-full" filter />
							</div>

							<div className="col-12 xl:col-4">
								<TextInput label="Name" name="name" value={values.name} onChange={handleChange} placeholder="Enter category name" />
							</div>

							<div className="col-12">
								<TextareaInput label="Description" name="detail.description" value={values?.detail?.description || undefined} onChange={handleChange} placeholder="Enter category description" />
							</div>

							<div className="col-12 xl:col-6">
								<FileUpload name="imageFile" accept=".jpg, .jpeg, .png, .webp" chooseLabel="Select category image" onSelect={(e) => setFieldValue('imageFile', e.files[0])} onRemove={(e) => setFieldValue('imageFile', null)} headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} />
							</div>

							<div className="col-12 xl:col-6">
								<FileUpload name="detail.bannerImageFile" accept=".jpg, .jpeg, .png, .webp" chooseLabel="Select category banner image" onSelect={(e) => setFieldValue('detail.bannerImageFile', e.files[0] || null)} onRemove={(e) => setFieldValue('detail.bannerImageFile', null)} headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} />
							</div>

							<div className="col-6 my-auto">
								<div className="field-checkbox select-none mb-0">
									<Checkbox inputId="canBeAddProduct" name="canBeAddProduct" checked={values.canBeAddProduct} onChange={(event) => setFieldValue('canBeAddProduct', event.checked)} />

									<label htmlFor="canBeAddProduct" className="cursor-pointer font-bold">
										Product can be added
									</label>
								</div>
							</div>

							<div className="col-6 text-right mt-3">
								<Button type="submit" label="Update Product Category" loading={loading} className="h-full px-6" />
							</div>

							{!!loading ? (
								<div className="custom-loading">
									<ProgressSpinner className="p-progress-color" strokeWidth="4" />
								</div>
							) : null}
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default UpdateCategoryModal;
