import { loadStripe } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import environment from 'helpers/constants/environment';
import { User } from 'models/dtos/auth/users/user';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import stripeService from 'services/stripe-service';
import MyCards from './components/MyCards';
import { ProgressSpinner } from 'primereact/progressspinner';

const PaymentMethods = () => {
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const navigate = useNavigate();

	const [stripeCustomerId, setStripeCustomerId] = useState('');
	const [stripePromise] = useState(() => loadStripe(environment.stripeKey || ''));
	const [setupIntent, setSetupIntent] = useState();
	const [stripeOptions, setStripeOptions] = useState({ client_secret: '' });
	const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	const fetchAll = async () => {
		if (!userId) return;

		try {
			setLoading(true);

			const getOrCreateCustomerResponse = await stripeService.getCustomerId(userId);
			if (!getOrCreateCustomerResponse.isSuccess || !getOrCreateCustomerResponse.data) throw '';

			setStripeCustomerId(getOrCreateCustomerResponse.data);

			const getPaymentMethodsResponse = await stripeService.getPaymentMethods(userId);
			if (!getPaymentMethodsResponse.isSuccess || !getPaymentMethodsResponse.data) throw '';

			setPaymentMethods(getPaymentMethodsResponse.data.data);
		} catch (error: AxiosError | any) {
			if (error instanceof AxiosError) {
				if (!error.response?.config.url) return;

				if (error.response.config.url.includes('get-or-create-customer-id')) navigate('/account/details');
			}
		} finally {
			setLoading(false);
		}
	};

	const createSetupIntent = () =>
		stripeService.createSetupIntent({ userId: userId }).then((response) => {
			setSetupIntent(response.data);
			setStripeOptions({ client_secret: response.data.client_secret });
		});

	useEffect(() => {
		if (!stripeCustomerId) return;

		createSetupIntent();
	}, [stripeCustomerId]);

	useEffect(() => {
		fetchAll();
	}, []);

	return (
		<React.Fragment>
			<div className="container-body p-3" style={{ minHeight:'15rem' }}>
				{!!stripePromise && (stripeOptions.client_secret ? <MyCards stripePromise={stripePromise} stripeOptions={stripeOptions} setupIntent={setupIntent} paymentMethods={paymentMethods} getAll={fetchAll} createSetupIntent={createSetupIntent} isDeletable /> : <></>)}
				{loading ? (
					<div className="pr-loading">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default PaymentMethods;
