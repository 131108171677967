import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";

export class GetAssignedProductVariantsRequest {
	contains: string | null;
	pagination: PaginationDto;

	get getConvertUriExtension() {
		return `get-assigned-product-variants`;
	}

	constructor(contains: string | null, pagination?: PaginationDto) {
		this.contains = contains;
		this.pagination = pagination || new PaginationDto();
	}
}