export class IncreaseWithChargeRequest {
	paymentMethodId: string = '';
	amount: number = 0;
	userId: string = "";

	constructor(paymentMethodId: string, amount: number, userId: string) {
		this.paymentMethodId = paymentMethodId;
		this.amount = amount;
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `${this.userId}/increase-with-charge`;
	}
}