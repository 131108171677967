import { ProductForImport } from '../dtos/product-for-import.dto';

export class ImportProductRequest {
	merchantId: string;
	categoryId: string;
	productToImport: ProductForImport;

	constructor(merchantId: string, categoryId: string, productToImport: ProductForImport) {
		this.merchantId = merchantId;
		this.categoryId = categoryId;
		this.productToImport = productToImport;
	}

	get getConvertUriExtension() {
		return `import-product`;
	}
}
