import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Resource } from '../../../../models/dtos/resource/resource';
import resourceService from '../../../../services/resource-service';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { Skeleton } from 'primereact/skeleton';

const Resources = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [resources, setResources] = useState<Resource[]>([]);

	const getAll = () => {
		setLoading(true);
		resourceService
			.getAll()
			.then((response) => response.isSuccess && setResources(response.data || []))
			.finally(() => setLoading(false));
	};

	const renderDescription = (pDescription: string) => {
		const description = pDescription.substring(0, 75);
		return description.length === 75 ? description.trimEnd() + '...' : description;
	};

	useEffect(() => {
		getAll();
	}, []);

	const renderGridItems = (resource: Resource) => {
		return (
			<div className="px-3 py-3">
				<div className="card flex flex-column h-full md:w-25rem p-0">
					<div className="card-header mb-0">
						<img alt="Card" src={resource.media?.videoMedias[0].thumbnailUrl} className="w-full" />
					</div>
					<h5 className="px-3">{resource.title}</h5>
					<p className="m-0 px-3">{renderDescription(resource.description)}</p>
					<div className="text-right mt-auto pr-3 py-3">
						<Button onClick={() => navigate(resource.resourceId)} icon="pi pi-arrow-right" label="Detail" className="px-5" />
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="grid">
			<div className="col-12">
				<div className="card">
					<h5>Help Resource Video Tutorials</h5>

					{!loading ? (
						<DataView value={resources} layout={'grid'} itemTemplate={renderGridItems} paginator rows={12} />
					) : (
						<div className="grid">
							<div className="col-4">
								<Skeleton height="28rem" />
							</div>
							<div className="col-4">
								<Skeleton height="28rem" />
							</div>
							<div className="col-4">
								<Skeleton height="28rem" />
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Resources;
