export class CreateReorderRequest {
	orderId: string;
	selectedItemIds: string[];

	constructor(props: Partial<CreateReorderRequest>) {
		this.orderId = props.orderId || '';
		this.selectedItemIds = props.selectedItemIds || [];
	}

	get getConvertUriExtension() {
		return `create-reorder`;
	}
}
