import HttpBaseService from 'services/_common/http-base-service';
import { ProductAttachmentCreateRequest } from './product-attachment-create/product-attachment-create.request';
import { ProductAttachmentCreateResponse } from './product-attachment-create/product-attachment-create.response';
import { ProductAttachmentGetRequest } from './product-attachment-get/product-attachment-get.request';
import { ProductAttachmentGetResponse } from './product-attachment-get/product-attachment-get.response';

class ProductAttachmentService extends HttpBaseService {
	constructor() {
		super('product-attachments');
	}

	async create(request: ProductAttachmentCreateRequest, uploadAction: (state: ProgressEvent) => void): Promise<ProductAttachmentCreateResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}

	async softDelete(attachmentId: string): Promise<boolean> {
		return await this.deleteData(attachmentId);
	}

	async get(request: ProductAttachmentGetRequest): Promise<ProductAttachmentGetResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export default new ProductAttachmentService();
