import { AutoCompleteDto } from 'features/_common/dtos/autocomplete/autocomplete.dto';
import { AddressBaseDto, CreateManuelOrderDeliveryAddressRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import environment from 'helpers/constants/environment';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

interface Props extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onInput' | 'ref'> {
	label: string;
	description: string;
	required: boolean;
	onPlaceSelected: (place: AutoCompleteDto) => void;
	onPlaceSelectedDto: (response: Partial<AddressBaseDto>) => void;
	defaultValue: string;
}

const AutoCompleteInput = (props: Partial<Props>) => {
	const { ref } = usePlacesWidget<HTMLInputElement>({
		apiKey: environment.googleMapKey,
		onPlaceSelected: (place: AutoCompleteDto) => {
			const streetNumber = place.address_components.find((_address) => _address.types.includes('street_number'))?.long_name || '';
			const route = place.address_components.find((_address) => _address.types.includes('route'))?.long_name || '';

			const placeResponse: Partial<AddressBaseDto> = {
				formattedAddress: place.formatted_address,
				state: place.address_components.find((_address) => _address.types.includes('administrative_area_level_1'))?.long_name || '',
				city: place.address_components.find((_address) => _address.types.includes('locality'))?.long_name || '',
				zip: place.address_components.find((_address) => _address.types.includes('postal_code'))?.long_name || '',
				countryIso2: place.address_components.find((_address) => _address.types.includes('country'))?.short_name || '',
				firstLine: `${streetNumber} ${route}`,
				secondLine: place.address_components.find((_address) => _address.types.includes('subpremise'))?.long_name || null
			};

			props.onPlaceSelectedDto?.(placeResponse);
			return props.onPlaceSelected?.(place);
		},
		options: {
			types: ['address']
		},
		language: 'en-US'
	});

	return (
		<div className="pr-input-group w-full">
			<label htmlFor="title" className="pr-input-label">
				<h5 className="pr-input-label-title">
					{props.label || ''} {typeof props.required !== 'undefined' ? !props.required ? <span className="pr-input-label-optional">(Optional)</span> : <span className="pr-input-label-required">*</span> : null}
				</h5>

				{props.description ? <p className="pr-input-label-description">{props.description}</p> : null}
			</label>

			<input ref={ref} defaultValue={props.defaultValue} style={props.style} className={`${!!props.className ? props.className : ''} pr-input-control`} />
		</div>
	);
};

export default AutoCompleteInput;
