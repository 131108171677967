import React, { useEffect, useState } from 'react';
import { SizeChartRootDto } from 'features/products/_common/get-product-for-detail-by-id/dtos/sizes/size-chart-root.dto';
import styles from './SizeGuideChart.module.scss';
import { SizeChartDto } from 'features/products/_common/get-product-for-detail-by-id/dtos/sizes/size-chart.dto';
import { Button } from 'primereact/button';

const SizeGuideChart = ({ sizes, imperialİmperialSizeCharts, metricSizeCharts }: SizeChartRootDto) => {
	const [sizeCharts, setSizeCharts] = useState<SizeChartDto[]>(imperialİmperialSizeCharts || metricSizeCharts);
	const [lefItemMaxSize, setLefItemMaxSize] = useState<number>(0);

	useEffect(() => {
		const divs = document.getElementsByClassName(styles.sizeBodyLeftItem);

		const divArray = Array.prototype.slice.call(divs);
		const datas: number[] = divArray.map((_div) => _div.getBoundingClientRect().width);

		setLefItemMaxSize(Math.max(...datas));
	}, []);

	return (
		<React.Fragment>
			{!!sizes && sizes.length > 0 && !!sizeCharts && sizeCharts.length > 0 ? (
				<div className="grid w-full">
					<div className="col-4">
						<h3 className="ml-3">Size Guide Chart</h3>
					</div>

					<div className="col-8">
						{!!imperialİmperialSizeCharts && imperialİmperialSizeCharts.length > 0 && !!metricSizeCharts && metricSizeCharts.length > 0 ? (
							<div className="flex gap-3 justify-content-end mb-3">
								<Button onClick={() => setSizeCharts(imperialİmperialSizeCharts)} label="Imperial" className="p-button-sm p-button-outlined" />
								<Button onClick={() => setSizeCharts(metricSizeCharts)} label="Metric" className="p-button-sm p-button-outlined" />
							</div>
						) : null}

						<div className={styles.sizeWrapper}>
							<div className={styles.sizeHeader}>
								<span className={`${styles.sizeHeaderItem} ${styles.empty}`} style={{ '--i-width': `${lefItemMaxSize}px` } as React.CSSProperties}></span>

								{sizes.map((size, index) => (
									<span key={index} className={styles.sizeHeaderItem}>
										{size.shortName}
									</span>
								))}
							</div>

							<div className={styles.sizeBodyWrapper}>
								<div className={styles.sizeBodyLeft} style={{ width: `${lefItemMaxSize}px` }}>
									{sizeCharts.map((sizeChart, index) => (
										<span key={index} className={styles.sizeBodyLeftItem}>
											{sizeChart.measurementType.name}, {sizeChart.measurementUnit}
										</span>
									))}
								</div>

								<div className={styles.sizeBodyContainer} style={{ width: `calc(100% - ${lefItemMaxSize}px)` }}>
									{sizeCharts.map((sizeChart, index) => (
										<div key={index} className={styles.sizeBodyContent}>
											{sizeChart.sizeAndValues.map((sizeAndValue, _index) => (
												<span key={_index} className={styles.sizeBodyContentItem}>
													{sizeAndValue.value}
												</span>
											))}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="w-full text-center py-4">
					<h5 className="m-0">Size info not found</h5>
				</div>
			)}
		</React.Fragment>
	);
};

export default SizeGuideChart;
