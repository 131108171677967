export class UpdateStatuRequest {
	sellerId: string = '';
	userStatuId: number = 0;

	get getConvertUriExtension() {
		return `update-statu`;
	}

	constructor(sellerId: string, userStatuId: number) {
		this.sellerId = sellerId;
		this.userStatuId = userStatuId;
	}
}