export class CreatePrintCenterOperatorRequest {
	name: string;

	constructor(name: string) {
		this.name = name;
	}

	get getConvertUriExtension() {
		return `create?Name=${this.name}`;
	}
}
