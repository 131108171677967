import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {PaginationDto} from 'features/_common/dtos/paginations/pagination.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import CreateOrderStatuHistoryModal, {
    CreateOrderStatuHistoryModalData
} from '../orderManagement/orders/modals/CreateOrderStatuHistoryModal';
import {Pagination} from 'models/_commons/responses/pagination';
import {OrderStatuTypes} from 'features/orders/dtos/order-statu-types';
import orderService from 'features/orders/_common/order.service';
import {setForSellerOrderListPaginationInfo} from 'redux/features/pagination/paginationSlice';
import {
    CreateOrderStatuHistoryRequest
} from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService
    from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';
import stringHelper from 'helpers/string.helper';
import OrderFilterTab from 'helpers/widgets/Order/OrderFilterTab/OrderFilterTab';
import SearchInput from 'helpers/widgets/Inputs/SearchInput/SearchInput';
import styles from './Orders.module.scss';
import {Checkbox} from 'primereact/checkbox';
import PrintDropdown from 'helpers/widgets/Order/PrintDropdown/PrintDropdown';
import toastHelper from 'helpers/toast.helper';
import dateHelper from 'helpers/dateHelper';
import currencyHelper from 'helpers/curreny.helper';
import {Dropdown} from 'primereact/dropdown';
import {Paginator} from 'primereact/paginator';
import {PaginationOrderByEnum} from 'features/_common/dtos/paginations/pagination.interface';
import {Tooltip} from 'primereact/tooltip';
import {ProgressSpinner} from 'primereact/progressspinner';
import CreateOrderStatuHistoryAcknowledgeModal from './components/modals/CreateOrderStatuHistoryAcknowledgeModal';
import {DeliveryTypes} from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import {Message} from 'primereact/message';
import PrMultipleCalendar from 'helpers/widgets/Printram/Calendar/PrMultipleCalendar';
import PrDropdown from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import {OrderForListNewDto} from 'features/orders/dtos/order-for-list-new.dto';
import {
    GetOrdersForSellerNewRequest
} from 'features/orders/_common/get-orders-for-seller-new/get-orders-for-seller-new.request';
import {PassiveOrderRequest} from "../../../features/orders/_common/passive-order/passive-order-request";
import {confirmDialog} from "primereact/confirmdialog";
import { TiDelete } from "react-icons/ti";
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import StartTutorialTourButton from 'helpers/widgets/Tutorial/StartTutorialTourButton';
import Cookies from 'js-cookie';

const Orders = () => {
    const user = useAppSelector((_state) => _state.auth.data?.user);
    const fromStatePagination = useAppSelector((_state) => _state.pagination.forSellerOrderListPaginationInfo as PaginationDto);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchStatus = searchParams.get('status');

    const [loading, setLoading] = useState<boolean>(false);
    const [statuLoading, setStatuLoading] = useState<boolean>(false);
    const [statuModalVisible, setStatuModalVisible] = useState<boolean>(false);
    const [statuAcknowledgeModalVisible, setStatuAcknowledgeModalVisible] = useState<boolean>(false);
    const [statuModalData, setStatuModalData] = useState<CreateOrderStatuHistoryModalData | undefined>(undefined);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [orders, setOrders] = useState<OrderForListNewDto[]>([]);
    const [selectedOrders, setSelectedOrders] = useState<OrderForListNewDto[]>([]);
    const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
    const [filterOrderStatuType, setFilterOrderStatuType] = useState<OrderStatuTypes | null>(!!searchStatus ? Number(searchStatus) || null : null);
    const [lastRequest, setLastRequest] = useState<GetOrdersForSellerNewRequest>(new GetOrdersForSellerNewRequest({}));
    const [serviceLabelNames, setServiceLabelNames] = useState<string[]>([]);
    const [selectedServiceLabelName, setSelectedServiceLabelName] = useState<string | null>(null);
	const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryTypes | null>(null);
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false)
	const [filterTutorialStepsEnabled, setFilterTutorialStepsEnabled] = useState<boolean>(false)
	const steps = [
		{
			element: '.tutorial-selector1',
			intro: 'You can easily create orders manually. Enter product, quantity, and customer information to manage and track orders efficiently.',
			position: 'left'
		}
	];

    const getAll = async (request: Partial<GetOrdersForSellerNewRequest>) => {
        request.orderStatus = filterOrderStatuType || null;
        request.contains = typeof request.contains !== 'undefined' ? request.contains : searchFilter || null;
        request.labelServiceName = selectedServiceLabelName || null;
        request.deliveryType = selectedDeliveryType || null;
        request.includeLabelServiceNames = !request.labelServiceName ? true : false;

        const descStatus = [null, 1, 2, 6, 7, 8, 12];

        const clonedRequest = new GetOrdersForSellerNewRequest({...structuredClone(request), sellerId: user?.userId});

        clonedRequest.pagination.orderBy = descStatus.includes(filterOrderStatuType) ? PaginationOrderByEnum.Descending : PaginationOrderByEnum.Ascending;

        try {
            setLoading(true);

            const response = await orderService.getOrdersForSellerNew(clonedRequest);

            if (!response.isSuccess || !response.data?.orders) throw '';

            setOrders(response.data.orders);
            setPaginationResponse(response.data.pagination);

            if (!request.labelServiceName && response.data.serviceLabelNames.length > 0) {
                setServiceLabelNames(response.data.serviceLabelNames);
            }

            const newPagination = JSON.parse(JSON.stringify(clonedRequest.pagination));

            dispatch(setForSellerOrderListPaginationInfo(newPagination));

            console.clear();
        } catch (error) {
            setOrders([]);
            setPaginationResponse(undefined);
        } finally {
            setSelectedOrders([]);
            setLoading(false);
            setLastRequest(clonedRequest);
        }
    };

    const updateOrderStatus = async (request: Partial<CreateOrderStatuHistoryRequest>) => {
        try {
            setStatuLoading(true);

            const response = await orderStatuHistoriesService.create(new CreateOrderStatuHistoryRequest({...request}));

            if (!response.isSuccess) throw '';

            await getAll(lastRequest);
        } catch (error) {
        } finally {
            setStatuLoading(false);
        }
    };

    const setReadableName = (value: number) => {
        if (!value) return '';

        let newValue = stringHelper.parseAtUpperCaseAndJoin(OrderStatuTypes[value]);

        newValue = newValue.split('Order').join('');

        return newValue;
    };

    const redirectHandler = () => {
        if (user?.phoneNumbers && user.phoneNumbers.length > 0) {
            navigate('/create-order');
        } else {
            toastHelper.warning('You must be able to register your phone number in order to create an order.');
            navigate('/account/details');
        }
    };

    const onPassiveOrder = (order: OrderForListNewDto) => {
        if (order.currentOrderStatusType !== OrderStatuTypes.PendingApproval) return;

        confirmDialog({
            header: 'Delete Order',
            icon: 'pi pi-info-circle',
            message: "Are you sure you want to delete this order? Please note that deleted orders cannot be undone.",
            acceptLabel: 'Confirm',
            rejectLabel: 'No',
            acceptClassName: 'px-4',
            rejectClassName: 'p-button-text px-4',
            accept: () => passiveOrder(order)
        });
    };

    const passiveOrder = async (order: OrderForListNewDto) => {
        try {
            setLoading(true);

            const response = await orderService.passiveOrder(new PassiveOrderRequest(order.orderId));
            if (!response.isSuccess) throw '';

            await getAll(lastRequest);
        } catch (error) {
        } finally {
            setLoading(false);
        }
	}

	const tutorialFinish = () => {
		setTutorialStepsEnabled(false);
		setFilterTutorialStepsEnabled(true);
	}

	const startTutorial = () => {
		Cookies.set('orders-intro', 'false')
		Cookies.set('orders-intro-2', 'false');
		setTutorialStepsEnabled(true)
		setFilterTutorialStepsEnabled(false);
	}

    useEffect(() => {
        getAll({});

        const newLastRequest = structuredClone(lastRequest);

        newLastRequest.dateFilter.minDate = null;
        newLastRequest.dateFilter.maxDate = null;

        setLastRequest(newLastRequest);
	}, [searchStatus, selectedServiceLabelName, selectedDeliveryType]);

	useEffect(() => {
		setTutorialStepsEnabled(true)
	},[])

    return (
		<main className="container relative">
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => tutorialFinish()} onComplete={() => tutorialFinish()} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'orders-intro', disableInteraction: false }} /> */}

			<section className="container-header">
				<h1 className="container-header-title">{!filterOrderStatuType ? 'All' : setReadableName(filterOrderStatuType)} Orders</h1>

				<div className="container-header-tools">
					{/* <StartTutorialTourButton onClickEvent={startTutorial} /> */}

					<PrButton type="secondary" text="Create an Order" onClick={redirectHandler} icon={<span className="pi pi-fw pi-plus-circle" />} className="tutorial-selector1" />

					<PrButton
						type="secondary"
						text="Refresh Orders"
						disabled={loading}
						icon={<span className="pi pi-fw pi-refresh" />}
						onClick={async () => {
							setSearchFilter('');
							await getAll({ contains: null });
						}}
					/>
				</div>
			</section>

			<section className="container-body p-3 z-1">
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the Order page from the options below.</p>

				<OrderFilterTab filterOrderStatuType={filterOrderStatuType} setFilterOrderStatuType={setFilterOrderStatuType} page="seller" isTutorialStart={filterTutorialStepsEnabled} />

				<div className="flex mt-4">
					<div className="flex-1">
						<h5 className="mb-2">Service Label Filter</h5>
						<PrDropdown
							clearable
							filter
							placeholder="Select Service Label"
							onClear={() => {
								setSelectedServiceLabelName(null);
							}}
							onChange={(e) => {
								setSelectedServiceLabelName(e.value as string);
							}}
							value={
								selectedServiceLabelName
									? {
											label: selectedServiceLabelName,
											value: selectedServiceLabelName
									  }
									: undefined
							}
							options={serviceLabelNames.map((_serviceLabel) => ({
								label: _serviceLabel,
								value: _serviceLabel
							}))}
							className={styles.dropdownMinWidth}
						/>
					</div>

					<div className="flex-1">
						<h5 className="mb-2">Delivery Type Filter</h5>

						<PrDropdown
							clearable
							placeholder="Select Delivery Type"
							onClear={() => {
								setSelectedDeliveryType(null);
							}}
							onChange={(e) => {
								setSelectedDeliveryType(e.value as DeliveryTypes);
							}}
							value={
								selectedDeliveryType
									? {
											label: DeliveryTypes[selectedDeliveryType],
											value: selectedDeliveryType
									  }
									: undefined
							}
							options={enumToArrayHelper.convertArray(DeliveryTypes, true)}
							className={styles.dropdownMinWidth}
						/>
					</div>
				</div>

				<div className="mt-4">
					<SearchInput onChange={(e) => setSearchFilter(e.target.value)} value={searchFilter} onKeyDown={(event) => event.key === 'Enter' && getAll({})} filterBtn disabled={loading} placeholder="Search orders (order code, customer name, tracking number, etsy receipt no)" filterBtnOnClick={() => getAll({})} />
				</div>

				<h5 className="mb-2">Date Filter</h5>
				<PrMultipleCalendar dates={lastRequest.dateFilter} onChange={(event) => getAll({ dateFilter: event })} />

				{filterOrderStatuType === OrderStatuTypes.PendingApproval ? <Message severity="info" text="Orders are updated every 2 hours. If you can't view your order, please try again later." className="w-full justify-content-start gap-3 font-bold px-4 mt-3" /> : null}
			</section>

			<section className="container-body p-0 z-0 relative">
				<div className={`px-4 sticky bg-white ${styles.ordersTableWrapper}`} style={{ top: '4rem', width: '100%', height: '100%', left: 0, zIndex: 5 }}>
					<div className={`${styles.ordersTableHeader}${selectedOrders.length > 0 ? ` ${styles.haveSelected}` : ''}`}>
						<div className={styles.ordersTableHeaderSelectAll} style={{ visibility: orders.findIndex((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval) > -1 ? 'visible' : 'hidden' }}>
							<Checkbox inputId="selectAll" name="selectAll" checked={selectedOrders.length === orders.filter((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval).length && orders.filter((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval).length > 0} onChange={(event) => setSelectedOrders(event.checked ? orders.filter((_order) => _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval) : [])} />

							<label htmlFor="selectAll" className="cursor-pointer ml-2">
								Select All
							</label>
						</div>

						<div className={styles.ordersTableHeaderTitles}>
							<span className={styles.ordersTableHeaderTitlesProduct}>Store</span>
							<span className={styles.ordersTableHeaderTitlesInventory}>Delivery</span>
							<span className={styles.ordersTableHeaderTitlesDeliveryOptions}>Customer</span>
							<span className={styles.ordersTableHeaderTitlesStatus}>
								<div className="flex justify-content-between align-items-center gap-2">
									<span>Status</span>

									<PrintDropdown loading={loading} setLoading={setLoading} orderIds={selectedOrders.map((_order) => _order.orderId)} skipTypes={['Barcode']} />
								</div>
							</span>
						</div>
					</div>
				</div>

				<div className={styles.ordersTableBody}>
					{orders.length > 0 ? (
						orders.map((_order) => (
							<Link key={_order.orderId} to={`${_order.isExternalOrder ? '/sync-order/' : '/product/seller/order/'}${_order.orderId}`} className={`${styles.ordersTableBodyItemWrapper}${_order.isExternalOrder ? ` ${styles.external}` : ''}`}>
								<div className="px-4">
									<div className={styles.ordersTableBodyWrapper}>
										<div className={styles.ordersTableBodyItemCheck} onClick={(e) => e.preventDefault()}>
											<Checkbox
												inputId={`selectItem${_order.orderId}`}
												name={`selectItem${_order.orderId}`}
												checked={!!selectedOrders.find((_sOrder) => _sOrder.orderId === _order.orderId)}
												className={`${_order.currentOrderStatusType !== OrderStatuTypes.PendingApproval ? 'block' : 'hidden'}`}
												//	style={{visibility: _order.currentOrderStatusType !== OrderStatuTypes.PendingApproval ? 'visible' : 'hidden'}}
												onChange={(event) =>
													setSelectedOrders((_current) => {
														let newCurrent = structuredClone(_current);

														if (event.checked) newCurrent.push(_order);
														else newCurrent = newCurrent.filter((_nCurrent: OrderForListNewDto) => _nCurrent.orderId !== _order.orderId);

														return newCurrent;
													})
												}
											/>

											<div className={`styles.ordersTableBodyItemCheck ${searchStatus === '2' && _order.currentOrderStatusType === OrderStatuTypes.PendingApproval ? 'block' : 'hidden'}`}>
												<TiDelete role="button" color={'#ef3118'} size={'1.75rem'} onClick={() => onPassiveOrder(_order)} />
											</div>

											<label htmlFor={`selectItem${_order.orderId}`} onClick={(e) => e.preventDefault()}>
												{!!_order.receiptId ? (
													<span
														className="cursor-pointer font-bold"
														onClick={() => {
															navigator.clipboard.writeText(_order.receiptId || '');
															toastHelper.success(`Copied '${_order.receiptId}' to clipboard`);
														}}>
														{_order.receiptId}
													</span>
												) : null}

												<span
													className="cursor-pointer"
													onClick={() => {
														navigator.clipboard.writeText(_order.code);
														toastHelper.success(`Copied '${_order.code}' to clipboard`);
													}}>
													{_order.code}
												</span>

												<span>{`${_order.orderItemCount > 0 ? _order.orderItemCount + ' products' : ''}`}</span>
											</label>
										</div>

										<div className={styles.ordersTableBodyItems}>
											<div className={styles.ordersTableBodyItemProduct}>
												<Tooltip target={`#store${_order.code}`} />
												<h6 id={`store${_order.code}`} data-pr-tooltip={_order.storeName} data-pr-position="top">
													{_order.storeName}
												</h6>
												<span>
													{stringHelper.parseAtUpperCaseAndJoin(OrderStatuTypes[_order.currentOrderStatusType])} at &#x2022; {dateHelper.formatDate(_order.currentOrderStatusCreatedDate)}
												</span>
											</div>

											<div className={styles.ordersTableBodyItemInventory}>
												<Tooltip target={`#label${_order.orderId}`} />
												<span
													id={`label${_order.orderId}`}
													data-pr-tooltip={_order.labelServiceName}
													data-pr-position="top"
													className={`${styles.serviceLabelName}
													${_order.labelServiceName === 'Priority Mail Express' ? ` ${styles.express}` : ''}`}>
													{_order.labelServiceName}
												</span>
												<span className={styles.deliveryType}>{DeliveryTypes[_order.deliveryType || 0]}</span>
											</div>

											<div className={styles.ordersTableBodyItemDeliveryOptions}>
												<span>{_order.customerFullName || '-'}</span>
											</div>

											<div className={styles.ordersTableBodyItemStatus} onClick={(e) => e.preventDefault()}>
												<Dropdown
													options={_order.availableOrderStatus}
													optionValue="id"
													optionLabel="readableName"
													placeholder={OrderStatuTypes[_order.currentOrderStatusType]}
													onChange={(event) => {
														if (event.value === OrderStatuTypes.OrderApproved && _order.deliveryType === DeliveryTypes.PickUp) {
															updateOrderStatus({
																orderId: _order.orderId,
																orderStatuId: event.value,
																reason: ''
															});
															return;
														}

														setStatuModalData({
															orderId: _order.orderId,
															statu: event.value
														});

														event.value === OrderStatuTypes.Canceled ? setStatuModalVisible(true) : setStatuAcknowledgeModalVisible(true);
													}}
													value={_order.currentOrderStatusType}
													className="w-full"
												/>
											</div>
										</div>
									</div>
								</div>
							</Link>
						))
					) : (
						<div className={`${styles.ordersTableBodyItemWrapper} ${styles.loadMore}`}>
							<p>You dont have any order yet</p>
						</div>
					)}

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && orders.length > 0 ? (
						<div className={`${styles.ordersTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={fromStatePagination.first}
								rows={fromStatePagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30]}
								onPageChange={(event) => {
									if (fromStatePagination.pageNumber !== event.page + 1) setSelectedOrders([]);

									getAll({
										...lastRequest,
										pagination: {
											first: event.first,
											itemCount: event.rows,
											pageNumber: event.page + 1,
											orderBy: PaginationOrderByEnum.Descending
										}
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading || statuLoading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}

			<CreateOrderStatuHistoryModal data={statuModalData} visible={statuModalVisible} setVisible={setStatuModalVisible} callback={updateOrderStatus} />
			<CreateOrderStatuHistoryAcknowledgeModal orderId={statuModalData?.orderId} visible={statuAcknowledgeModalVisible} setVisible={setStatuAcknowledgeModalVisible} callback={updateOrderStatus} />
		</main>
	);
};

export default Orders;
