import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React from 'react';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { SyncOrderContext, SyncOrderContextType, orderSteps } from '../../context/SyncOrderContextProvider';
import { CostBreakdown } from '../shipping/components/breakdown/CostBreakdown';
import ShippingInfo from '../shipping/components/shippingInfo/ShippingInfo';
import styles from './StepReviewOrder.module.scss';
import { Payments } from './components/payments/Payments';
import { ProductsTable } from '../products/components/products-table/ProductsTable';
import { CreateEtsySynchronizeProductsWithSidesRequest } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/create-etsy-synchronize-products-with-sides.request';
import { CreateModelRequestDto } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/create-model.dto';
import integrationsService from 'features/integrations/_common/integrations.service';
import { useNavigate } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { BiError } from 'react-icons/bi';
import { SyncEtsyProduct } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/sync-etsy-product.dto';

export const StepReviewOrder = () => {
	const navigate = useNavigate();
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	const showFailDialog = (error: any) => {
		confirmDialog({
			header: 'Order can not be synced.',
			icon: <BiError color="red" size={50} />,
			message: error?.response?.data?.message || '',
			acceptLabel: 'Ok',
			acceptClassName: 'px-4 bg-blue-600',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: () => {}
		});
	};

	const syncOrder = async () => {
		try {
			context.setLoading(true);

			const createModel = structuredClone(context.request.createModel);

			const request = new CreateEtsySynchronizeProductsWithSidesRequest({
				createModel: new CreateModelRequestDto({
					orderId: createModel.orderId,
					sellerId: createModel.sellerId,
					products: createModel.products.filter((_prod: SyncEtsyProduct) => _prod.sides.length > 0),
					// sellerNote: createModel.sellerNote,
					sellerNote: null,
					customerNote: createModel.customerNote,
					giftMessage: createModel.giftMessage,
					paymentMethodType: createModel.paymentMethodType,
					shippingRate: createModel.shippingRate,
					shipTo: createModel.shipTo
				})
			});

			if (!!request.createModel.shipTo?.receiverPhoneNumber) request.createModel.shipTo.receiverPhoneNumber = request.createModel.shipTo?.phonePrefix.value + request.createModel.shipTo?.receiverPhoneNumber;
			if (request.createModel.shipTo?.receiverPhoneNumber === '') request.createModel.shipTo.receiverPhoneNumber = null;

			const response = await integrationsService.createSynchronizeProductsWithSides(request, (event) => {
				const percentage = Math.round((event.loaded * 100) / event.total);
				if (percentage !== 100) context.setLoadingText((_) => `File Processing: ${percentage}%`);
				else context.setLoadingText('');
			});

			if (!response.isSuccess) throw '';
			navigate('/product/seller/orders?status=3');
		} catch (error) {
			showFailDialog(error);
		} finally {
			context.setLoading(false);
		}
	};

	return (
		<div className="relative">
			<div className="container-body p-4" style={{ zIndex: 'auto' }}>
				<ProductsTable syncAction={() => context.setOrderStep(orderSteps[0])} />
			</div>

			<div className="container-body p-4 mt-5">
				<ShippingInfo />
			</div>

			<div className="container-body p-4 mt-5">
				<Payments />
			</div>

			{context.calculatedPrice && (
				<div className="container-body p-4 mt-5">
					<CostBreakdown {...context.calculatedPrice} />

					<div className={styles.payButton}>
						<PrButton text="Pay securely now" onClick={syncOrder} icon={<BsFillShieldLockFill />} btnType="button" />
						<div className={styles.payButtonDescription}>
							<div>By completing this order, </div>
							<div>I agree to Printram's Terms of Service.</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
