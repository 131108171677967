export class PassiveOrderRequest {
    orderId: string = "";

    constructor(orderId: string) {
        this.orderId = orderId;
    }

    get getConvertUriExtension() {
        return `passive-order?Id=${this.orderId}`;
    }
}