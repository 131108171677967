export class GetAccountCompletePercentageRequest {
	sellerId: string;

	constructor(sellerId: string) {
		this.sellerId = sellerId || '';
	}

	get getConvertUriExtension() {
		return `get-account-complete-percentage?sellerId=${this.sellerId}`;
	}
}
