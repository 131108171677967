import currencyHelper from "../../../../helpers/curreny.helper"

const minMaxPriceHandler = (minPrice: number, maxPrice: number) => {
	if (!minPrice || !maxPrice) return '';

	const minFormattedPrice = currencyHelper.formatPrice(minPrice)
	const maxFormattedPrice = currencyHelper.formatPrice(maxPrice)

	if (minFormattedPrice === maxFormattedPrice) return minFormattedPrice;
	else return minFormattedPrice + ' - ' + maxFormattedPrice;
}

export default minMaxPriceHandler