export class CreateCommentToTicketRequest {
	comment: string;
	ticketId: string;

	constructor(comment: string, ticketId: string) {
		this.comment = comment;
		this.ticketId = ticketId;
	}

	get getConvertUriExtension() {
		return `${this.ticketId}/comments`
	}
}