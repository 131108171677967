export class GetMyAccountRequest {
	private userId: string;

	constructor(userId: string) {
		this.userId = userId;
	}

	get getConvertUriExtension() {
		return `get-my-account?UserId=${this.userId}`;
	}
}
