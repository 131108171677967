import { PrintCenterDto } from 'features/printCenters/_common/dtos/print-center.dto';
import { GetPrintCenterListForAdminRequest } from 'features/printCenters/_common/get-print-center-list-for-admin/get-print-center-list-for-admin.request';
import printCenterService from 'features/printCenters/_common/print-center.service';
import { AssignToPrintCentersRequest } from 'features/printCenters/derived-features/assigned-product-variant-to-print-centers/assign-to-print-centers/assign-to-print-centers.request';
import { CreateModelDto } from 'features/printCenters/derived-features/assigned-product-variant-to-print-centers/assign-to-print-centers/dtos/create-model.dto';
import assignedProductVariantToPrintCenterService from 'features/printCenters/derived-features/assigned-product-variant-to-print-centers/assigned-product-variant-to-print-center.service';
import { GetMerchantProductForSyncRequest } from 'features/products/derived-features/merchant-products/get-merchant-product-for-sync/get-merchant-product-for-sync.request';
import { GetMerchantProductForSyncResponse } from 'features/products/derived-features/merchant-products/get-merchant-product-for-sync/get-merchant-product-for-sync.response';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import currencyHelper from 'helpers/curreny.helper';
import mediaHelper from 'helpers/media.helper';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import React, { useCallback, useEffect, useState } from 'react'

type props = {
	visible: boolean;
	setVisible: Function;
	getAssignedProducts: Function;
};
const NewAssign = ({ visible, setVisible, getAssignedProducts }: props) => {
	const [products, setProducts] = useState<GetMerchantProductForSyncResponse[]>([])
	const [selectedProduct, setSelectedProduct] = useState<string | undefined>();
	const [selectedVariants, setSelectedVariants] = useState<Array<string>>([]);
	const [selectedPrintCenter, setSelectedPrintCenter] = useState<string | undefined>();
	const [productOptions, setProductOptions] = useState<SelectItemOptionsType>();
	const [variantOptions, setVariantOptions] = useState<SelectItemOptionsType>();
	const [printCenterOptions, setPrintCenterOptions] = useState<SelectItemOptionsType>();
	const [loading, setLoading] = useState<boolean>(false)
	const [saveLoading, setSaveLoading] = useState<boolean>(false);

	const getMerchantProductsForSync = useCallback(async () => {
		try {
				const response = await merchantProductsService.getProductForSync(new GetMerchantProductForSyncRequest());

				if (!response.isSuccess) throw '';

				return await Promise.resolve(response.data || []);
			} finally { }
	}, []);

	const getPrintCenters = useCallback(async () => {
		try {
			const request = new GetPrintCenterListForAdminRequest({ orderBy: 1, itemCount: null, pageNumber: 1, first: 0 });

			const response = await printCenterService.getPrintCenterListForAdmin(request);

			if (!response.isSuccess) throw '';

			const options = response?.data?.map((printCenter: PrintCenterDto) => ({ label: printCenter.fullName + ' - ' + printCenter.email, value: printCenter.id } as SelectItem));

			if (!options) throw '';

			return await Promise.resolve(options);
		} finally { }
	}, []);

	const productOptionsTemplate = (option: any) => {
		return (
			<div className="w-max flex justify-content-center align-items-center gap-2">
				<img alt={option.label} src={mediaHelper.getImageOnMedia(option.media).url || 'https://via.placeholder.com/40'} height={40} />
				<span>{option.label}</span>
				{option.price && <span>- {currencyHelper.formatPrice(option.price)}</span>}
			</div>
		);
	};

	const onSave = async() => {
		try {
			if (!selectedPrintCenter || !selectedVariants) return;

			setSaveLoading(true)

			let createModels: CreateModelDto[] = selectedVariants.map((variant) => ({ printCenterId: selectedPrintCenter, productVariantId: variant }));

			const request = new AssignToPrintCentersRequest(createModels);

			const response = await assignedProductVariantToPrintCenterService.assignToPrintCenters(request)

			if (!response.isSuccess) throw ''

			setSelectedPrintCenter(undefined);
			setSelectedProduct(undefined);
			setSelectedVariants([]);
			getAssignedProducts();
			setVisible(false);
		} finally { setSaveLoading(false) }
	}

	useEffect(() => {
		if (!products) return;

		const options = products?.map((prod) => ({ label: prod.name, value: prod.productId, media: prod.media } as SelectItem));

		setProductOptions(options);
	}, [products]);

	useEffect(() => {
		if (!selectedProduct) return;

		setSelectedVariants([])

		const options = products.find((prod) => prod.productId === selectedProduct)?.productVariants.map((vari) => ({ label: vari.displayText, value: vari.productVariantId, media: vari.media, price: vari.price.formattedPricePerUnit }));

		setVariantOptions(options);
	}, [selectedProduct]);

	useEffect(() => {
		if (!visible) return;

		setLoading(true)
		Promise.all([getMerchantProductsForSync(), getPrintCenters()]).then((values:any) => {
			setProducts(values[0] || [])
			setPrintCenterOptions(values[1])
		}).finally(() => setLoading(false))
	}, [visible])

  return (
	  <Dialog visible={visible} header="New Assign" onHide={() => {
		setVisible(false)
		setSelectedPrintCenter(undefined)
		setSelectedProduct(undefined)
		setSelectedVariants([])
	  }}>
			{!loading ? (
				<>
					{' '}
					<div className="w-full">
						<span className="font-medium">Product</span>
						<Dropdown value={selectedProduct} options={productOptions} onChange={(e) => setSelectedProduct(e.target.value)} itemTemplate={productOptionsTemplate} filter filterBy="label" placeholder="Select a product" className="w-full" />
					</div>
					<div className="w-full mt-2">
						<span className="font-medium">Variants</span>
						<MultiSelect value={selectedVariants} multiple options={variantOptions} onChange={(e) => setSelectedVariants(e.value)} itemTemplate={productOptionsTemplate} filter filterBy="label" maxSelectedLabels={2} placeholder="Select product variants" className="w-full" />
					</div>
					<div className="w-full mt-2">
						<span className="font-medium">Print Center</span>
						<Dropdown value={selectedPrintCenter} options={printCenterOptions} onChange={(e) => setSelectedPrintCenter(e.target.value)} filter filterBy="label" placeholder="Select a print center to assign" className="w-full" />
					</div>
					<div className="w-full mt-4 text-right">
						<Button label="Save Assignment" onClick={() => onSave()} loading={saveLoading} icon="pi pi-save" />
					</div>
				</>
			) : (
				<div className="grid">
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12">
						<Skeleton height="3rem" />
					</div>
					<div className="col-12 mt-2">
						<Skeleton height="3rem" />
					</div>
				</div>
			)}
		</Dialog>
  );
}

export default NewAssign