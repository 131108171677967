import HttpBaseService from 'services/_common/http-base-service';
import { GetMissingWeightVariantsByOrderRequest } from './get-missing-weight-variants-by-order/get-missing-weight-variants-by-order.request';
import { GetMissingWeightVariantsByOrderResponse } from './get-missing-weight-variants-by-order/get-missing-weight-variants-by-order.response';
import { DataResponse } from 'models/_commons/responses/data-response';
import { GetCombinationsByProductRequest } from './get-combinations-by-product/get-combinations-by-product.request';
import { GetCombinationsByProductResponse } from './get-combinations-by-product/get-combinations-by-product.response';
import { DeleteBulkVariantRequest } from './delete-bulk-variant/delete-bulk-variant.request';
import { GetVariantSummariesRequest } from './get-variant-summaries/get-variant-summaries.request';
import { GetVariantSummariesResponse } from './get-variant-summaries/get-variant-summaries.response';
import { GetSizesByProductRequest } from './get-sizes-by-product/get-sizes-by-product.request';
import { GetSizesByProductResponse } from './get-sizes-by-product/get-sizes-by-product.response';
import { GetProductVariantsPriceOptionsResponse } from './get-product-variants-price-options/get-product-variants-price-options.response';
import { GetProductVariantsPriceOptionsRequest } from './get-product-variants-price-options/get-product-variants-price-options.request';
import { GetProductsVariantsAndPriceRequest } from './get-products-variants-and-price/get-products-variants-and-price.request';
import { GetProductsVariantsAndPriceResponse } from './get-products-variants-and-price/get-products-variants-and-price.response';
import { UpdateProductVariantPriceRequest } from './update-product-variant-price/update-product-variant-price.request';
import { UpdateProductVariantPriceResponse } from './update-product-variant-price/update-product-variant-price.response';

class ProductVariantService extends HttpBaseService {
	constructor() {
		super('product-variants');
	}

	async getMissingWeightVariantsByOrder(request: GetMissingWeightVariantsByOrderRequest): Promise<DataResponse<GetMissingWeightVariantsByOrderResponse>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getCombinationsByProduct(request: GetCombinationsByProductRequest): Promise<DataResponse<GetCombinationsByProductResponse>> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getVariantSummaries(request: GetVariantSummariesRequest): Promise<GetVariantSummariesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async deleteBulk(request: DeleteBulkVariantRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension);
	}

	async getSizesByProduct(request: GetSizesByProductRequest): Promise<GetSizesByProductResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getProductVariantAndPrices(request: GetProductsVariantsAndPriceRequest): Promise<GetProductsVariantsAndPriceResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	};

	async getProductVariantPriceOptions(request: GetProductVariantsPriceOptionsRequest): Promise<GetProductVariantsPriceOptionsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async updateProductVariantPrice(request: UpdateProductVariantPriceRequest): Promise<UpdateProductVariantPriceResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}
}

export default new ProductVariantService();
