import React from 'react';

const Spinner = () => {
	return (
		<div style={styles.spinnerOverlay}>
			<div style={styles.spinner}></div>
		</div>
	);
};

const styles = {
	spinnerOverlay: {
		position: 'absolute' as 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.3)', // semi-transparent background
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000,
	},
	spinner: {
		width: '50px',
		height: '50px',
		border: '6px solid rgba(255, 255, 255, 0.3)',
		borderTopColor: '#fff',
		borderRadius: '50%',
		animation: 'spin 1s ease-in-out infinite',
	},
};

export default Spinner;