import { StepWithComponent, StepWithComponentEnum } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SellerReOrderDetail } from '../SellerReOrderDetail';
import { StepProducts } from '../steps/products/StepProducts';
import { StepReviewOrder } from '../steps/review-order/StepReviewOrder';
import { StepShipping } from '../steps/shipping/StepShipping';
import { ReorderDetail } from 'features/reorders/_common/get-reorder-detail/get-reorder-detail.response';
import { ReorderChargeRequest } from 'features/reorders/_common/charge/charge.request';
import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import { ReorderShippingRate } from 'features/reorders/_common/get-shipping-rates/get-shipping-rates.response';
import { ReorderCalculateDto } from 'features/reorders/_common/calculate/re-order-calculate.response';

export const reorderSteps: StepWithComponent[] = [
	{ label: 'Products', value: StepWithComponentEnum.Products, isCompleted: false, isCanNext: false, component: <StepProducts /> },
	{ label: 'Shipping', value: StepWithComponentEnum.Shipping, isCompleted: false, isCanNext: false, component: <StepShipping /> },
	{ label: 'Review order', value: StepWithComponentEnum.ReviewOrder, isCompleted: false, isCanNext: false, component: <StepReviewOrder /> }
];


export type SellerReorderDetailContextType = {
	reorderIdFromRoute: { reorderId: string; newOrderId: string } | undefined;
	reorder: ReorderDetail | undefined;
	setReorder: React.Dispatch<React.SetStateAction<ReorderDetail | undefined>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	reorderRequest: ReorderChargeRequest;
	setReorderRequest: React.Dispatch<React.SetStateAction<ReorderChargeRequest>>;
	reorderStep: StepWithComponent;
	setReorderStep: React.Dispatch<React.SetStateAction<StepWithComponent>>;
	shippingRates: ReorderShippingRate[] | [];
	setShippingRates: React.Dispatch<React.SetStateAction<ReorderShippingRate[] | []>>;
	selectedPaymentCard: string | undefined;
	setSelectedPaymentCard: React.Dispatch<React.SetStateAction<string | undefined>>;
	selectedRate: ReorderShippingRate | undefined;
	setSelectedRate: React.Dispatch<React.SetStateAction<ReorderShippingRate | undefined>>;
	calculatedPrice: ReorderCalculateDto | undefined;
	setCalculatedPrice: React.Dispatch<React.SetStateAction<ReorderCalculateDto | undefined>>;
	isShowShippingForm: boolean;
	setIsShowShippingForm: React.Dispatch<React.SetStateAction<boolean>>;
	isNewShippingRatesCalculateRequired: boolean;
	setIsNewShippingRatesCalculateRequired: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SellerReorderDetailContext = React.createContext<SellerReorderDetailContextType | null>(null);

export const SellerReorderDetailContextProvider = () => {
	const { reorderId, newOrderId } = useParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [isShowShippingForm, setIsShowShippingForm] = useState<boolean>(false);
	const [reorderStep, setReorderStep] = useState<StepWithComponent>(reorderSteps[0]);
	const [reorderRequest, setReorderRequest] = useState<ReorderChargeRequest>(new ReorderChargeRequest({ reorderId: reorderId }));
	const [shippingRates, setShippingRates] = useState<ReorderShippingRate[] | []>([]);
	const [selectedPaymentCard, setSelectedPaymentCard] = useState<string | undefined>(undefined);
	const [selectedRate, setSelectedRate] = useState<ReorderShippingRate | undefined>(undefined);
	const [calculatedPrice, setCalculatedPrice] = useState<ReorderCalculateDto | undefined>(undefined);
	const [reorder, setReorder] = useState<ReorderDetail | undefined>(undefined);
	const [isNewShippingRatesCalculateRequired, setIsNewShippingRatesCalculateRequired] = useState<boolean>(false);

	return (
		<SellerReorderDetailContext.Provider
			value={{
				reorderIdFromRoute: { reorderId: reorderId!, newOrderId: newOrderId! },
				reorder,
				setReorder,
				loading,
				setLoading,
				reorderRequest,
				setReorderRequest,
				reorderStep,
				setReorderStep,
				shippingRates,
				setShippingRates,
				selectedPaymentCard,
				setSelectedPaymentCard,
				selectedRate,
				setSelectedRate,
				calculatedPrice,
				setCalculatedPrice,
				isShowShippingForm,
				setIsShowShippingForm,
				isNewShippingRatesCalculateRequired,
				setIsNewShippingRatesCalculateRequired
			}}>
			<SellerReOrderDetail />
		</SellerReorderDetailContext.Provider>
	);
};
