export class ProductImagesCreateRequest {
	productId: string;
	images: File[];

	constructor(props: Partial<ProductImagesCreateRequest>) {
		this.productId = props.productId || '';
		this.images = props.images || [];
	}

	get getConvertUriExtension() {
		return '';
	}
}
