import { Menu } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import PrButton from '../Forms/Buttons/PrButton';

export interface PrDropdownType {
	label: string | number;
	value: string | number;
}

type Props = {
	options: PrDropdownType[];
	value: PrDropdownType;
	placeholder: string;
	disabled: boolean;
	filter: boolean;
	clearable: boolean;
	className: string;
	optionIcon: JSX.Element;
	onChange: (event: PrDropdownType) => void;
	onClear: () => void;
};

const PrDropdown = (props: Partial<Props>) => {
	const [options, setOptions] = useState<PrDropdownType[]>([]);

	useEffect(() => {
		setOptions(props.options || []);
	}, [props.options]);

	return (
		<Menu as="div" className={`pr-dropdown${props.className ? ` ${props.className}` : ''}`}>
			<Menu.Button className="pr-btn pr-btn-secondary flex w-full justify-content-between" disabled={props.disabled}>
				<span className="mr-2">{!!props.value ? props.value.label : props.placeholder || 'Select'}</span>

				{props.clearable && !!props.value ? (
					<PrButton
						type="icon"
						icon={<MdClear />}
						onClick={(event) => {
							event.preventDefault();
							props.onClear?.();
						}}
						className='ml-auto mr-1'
					/>
				) : null}
				<AiFillCaretDown />
			</Menu.Button>

			<Menu.Items className="pr-dropdown-menu right">
				{props.filter ? (
					<React.Fragment>
						<Menu.Item
							as="input"
							type="text"
							placeholder="Search"
							className="pr-input-control"
							onClick={(event) => event.preventDefault()}
							onKeyDown={(event) => event.stopPropagation()}
							onChange={(event) => {
								if (!props.options) return;

								if (!event.target.value) {
									setOptions(props.options);
									return;
								}

								setOptions(options.filter((_option) => _option.label.toString().toLowerCase().includes(event.target.value.toLowerCase())));
							}}
						/>

						<Menu.Item as="hr" className="m-0 mt-2" />
					</React.Fragment>
				) : null}

				{options.length > 0 ? (
					options.map((_option, index) => (
						<Menu.Item key={index} onClick={() => props.onChange?.(_option)} as="button" className={`pr-btn pr-dropdown-menu-item${props.value?.value === _option.value ? ' active-item' : ''}`}>
							{props.optionIcon && <span className='mr-2'>{props.optionIcon}</span>}
							{_option.label}
						</Menu.Item>
					))
				) : (
					<Menu.Item as="div" className={`h-3rem flex align-items-center justify-content-center px-2${props.filter ? ' mt-2' : ''}`} style={{ backgroundColor: '#fafafa', border: '1px solid #eaeaea', borderRadius: '0.2rem' }}>
						No result found
					</Menu.Item>
				)}
			</Menu.Items>
		</Menu>
	);
};

export default PrDropdown;
