import { Menu } from '@headlessui/react';
import React, { useState } from 'react'
import { AiFillCaretDown } from 'react-icons/ai';

type props = { dtfId: string, downloadDTF: Function, downloadPDF: Function }

const DtfDownloadDropdown = ({ dtfId, downloadDTF, downloadPDF }: props) => {
	const [loading, setLoading] = useState<boolean>(false)


	const dropdownMenuItems = [
		<Menu.Item as="button" key={1} onClick={() => downloadDTF()} className="pr-btn pr-dropdown-menu-item">
			DTF File
		</Menu.Item>,
		<Menu.Item as="button" key={2} onClick={() => downloadPDF()} className="pr-btn pr-dropdown-menu-item">
			Orders Receipt PDF
		</Menu.Item>
	];
	return (
		<div>
			{!!dtfId ? (
				<Menu as="div" className="pr-dropdown" style={{ zIndex: '99' }}>
					<Menu.Button className="pr-btn pr-btn-secondary" disabled={loading}>
						<span className="mr-3">Downloads</span>
						<AiFillCaretDown />
					</Menu.Button>

					<Menu.Items className="pr-dropdown-menu">{dropdownMenuItems}</Menu.Items>
				</Menu>
			) : null}
		</div>
	);
}

export default DtfDownloadDropdown