import { SellerProduct } from '../../../models/dtos/product/seller-products';
import { CopyProductRequest } from '../../../models/requests/products/copy-product-request';
import { DataResponse } from '../../../models/_commons/responses/data-response';
import { ResponseBase } from '../../../models/_commons/responses/response-base';
import HttpBaseService from '../../_common/http-base-service';

class ProductCopyBySellerService extends HttpBaseService {
    constructor() {
        super('product-copied-by-sellers');
    }

    async copyProduct(request: CopyProductRequest): Promise<ResponseBase> {
        return await this.postData('copy-product', request);
    }

    async getCopiedProductsBySellerId(sellerId: string): Promise<DataResponse<SellerProduct[]>> {
        return await this.getData('get-copied-products-by-seller?SellerId=' + sellerId);
    }

    async getCopiedProductById(productId: string): Promise<DataResponse<SellerProduct>> {
        return await this.getData('get-copied-product-by-id?Id=' + productId);
    }
}

export default new ProductCopyBySellerService();
