import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetPrintramStockRequest {
	pagination: PaginationDto;

	constructor(pagination?: PaginationDto) {
		this.pagination = pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `product-variant-stocks/printram/get-stocks`;
	}
}
