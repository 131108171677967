export class GetGroupUsersRequest {
	groupId: string;

	constructor(groupId: string) {
		this.groupId = groupId;
	}

	get getConvertUriExtension() {
		return 'get-group-users'
	}
}