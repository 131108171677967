import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useCallback, useEffect, useState } from 'react'
import { Size } from '../../../../models/dtos/dimesions/size'
import sizeService from '../../../../services/dimensions/size-service'
import SizeCreate from './components/SizeCreate'
import SizeUpdate from './components/SizeUpdate'

const Sizes = () => {
	const [sizes, setSizes] = useState<Size[] | null>([])
	const [loading, setLoading] = useState(false)
	const [visibleCreateSize, setVisibleCreateSize] = useState(false);
	const [visibleUpdateSize, setVisibleUpdateSize] = useState(false);
	const [editedSize, setEditedSize] = useState<Size | undefined>(undefined);

    const handleEditBtnCliked = (size: Size) => {
		setEditedSize(size);
		setVisibleUpdateSize(true);
	};

	const getSizes = useCallback(() => {
		setLoading(true)
		sizeService.getSizes({ pagination: { orderBy: 1, pageNumber: 1 } }).then((response) => {
			if (!response.isSuccess) return;

			setSizes(response.data);
		}).finally(() => setLoading(false));
	}, []);

	const deleteSize = (id: string) => sizeService.delete(id).then((response) => response && getSizes());

	const nameBodyTemplate = (rowData: Size) => rowData.name + ' / ' + rowData.shortName

	const actionsTemplate = (size: Size) => (
		<div className="flex gap-2 justify-content">
			<Button onClick={() => handleEditBtnCliked(size)} label="Edit" icon="pi pi-fw pi-file-edit" className="p-button-info" />
			<Button onClick={() => deleteSize(size.id)} label="Delete" icon="pi pi-fw pi-trash" className="p-button-danger" />
		</div>
	);

	useEffect(() => {
		getSizes();
	},[getSizes])

  return (
		<div className="card">
			<div className="flex justify-content-between mb-3">
				<h5>Sizes</h5>
				<Button onClick={() => setVisibleCreateSize(true)} label="New Size" icon="pi pi-plus-circle" className="p-button-outlined" />
			</div>

			<DataTable value={sizes as any} paginator rows={10} dataKey="id" loading={loading} className="p-datatable-gridlines">
				<Column field="id" header="Size Id" filter filterPlaceholder="Search by id" style={{ minWidth: '12rem' }} />
				<Column header="Name" body={nameBodyTemplate} />
				<Column field="priority" header="Priority" filter filterPlaceholder="Search by priority number" />
				<Column field="actions" header="Actions" body={actionsTemplate} />
			</DataTable>

			<SizeCreate isVisible={visibleCreateSize} setVisible={setVisibleCreateSize} getSizes={getSizes} />
			<SizeUpdate isVisible={visibleUpdateSize} setVisible={setVisibleUpdateSize} getSizes={getSizes} editedSize={editedSize} setEditedSize={setEditedSize} />
		</div>
  );
}

export default Sizes