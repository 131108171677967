import productImageService from 'features/products/derived-features/product-images/product-image.service';
import { ProductImagesCreateRequest } from 'features/products/derived-features/product-images/product-images-create/product-images-create.request';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadRemoveParams, FileUploadSelectParams } from 'primereact/fileupload';
import React, { useState } from 'react';
import { useParams } from 'react-router';

type Props = {
	setStep: React.Dispatch<React.SetStateAction<number>>;
};

const StepTwo = (props: Props) => {
	const { productId } = useParams();

	const [files, setFiles] = useState<File[]>([]);
	const [loading, setLoading] = useState(false);

	const handleFilesSelect = (event: FileUploadSelectParams) => {
		const fileList = [...event.files];
		const newFiles = structuredClone(files);

		newFiles.push(...fileList);

		setFiles(newFiles);
	};

	const handleFileRemove = (event: FileUploadRemoveParams) => setFiles(structuredClone(files).filter((_file: File) => _file.name !== event.file.name));

	const handleSubmit = async () => {
		try {
			setLoading(true);

			if (!productId) throw '';

			const response = await productImageService.create(new ProductImagesCreateRequest({ productId, images: structuredClone(files) }));
			if (!response.isSuccess) throw '';

			props.setStep((current) => current + 1);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const headerTemplateOfFileUpload = (options: FileUploadHeaderTemplateOptions) => (
		<div className={options.className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
			{options.chooseButton}
		</div>
	);

	const emptyTemplateOfFileUpload = () => (
		<div className="flex align-items-center flex-column py-3">
			<span className="font-semibold text-xl">Drag and Drop Image Here</span>
			<span className="mt-2 font-semibold text-color-secondary">Supported file extensions; .jpg, .jpeg, .png</span>
		</div>
	);

	return (
		<div className="grid">
			<div className="col-12 xl:col-8 relative">
				<FileUpload accept=".jpg, .jpeg, .png" headerTemplate={headerTemplateOfFileUpload} emptyTemplate={emptyTemplateOfFileUpload} onSelect={handleFilesSelect} onRemove={handleFileRemove} multiple className="w-full" />
			</div>

			<div className="col-12 xl:col-8 relative">
				<Button onClick={handleSubmit} type="submit" icon="pi pi-arrow-right" label="Confirm & Next" loading={loading} />
			</div>
		</div>
	);
};

export default StepTwo;
