import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DropdownChangeParams } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { useCallback, useEffect, useState } from 'react';
import DropdownInput from '../../../../helpers/widgets/Forms/DropdownInput';
import TextareaInput from '../../../../helpers/widgets/Forms/TextareaInput';
import TextInput from '../../../../helpers/widgets/Forms/TextInput';

import plansService from 'features/plans/_common/plans.service';
import { CreatePlanWithOrderDomainsRequest } from 'features/plans/_common/create-plan-with-other-domains/create-plan-with-other-domains.request';
import planFeatureTypesService from 'features/plans/derived-features/plan-feature-types/plan-feature-types.service';
import { GetPlanFeatureTypesRequest } from 'features/plans/derived-features/plan-feature-types/get-plan-feature-types/get-plan-feature-types.request';
import { Skeleton } from 'primereact/skeleton';

type props = {
    visible: boolean;
	setVisible: Function;
	planOptions: SelectItemOptionsType | undefined;
	getPlans: Function;
};

type PlanFeatureOptions = { planFeatureOptions: number; label: string; value: string; isCustomizable: boolean };

const PlanCreate = ({ visible, setVisible, planOptions, getPlans }: props) => {
	const [planFeatureOptions, setPlanFeatureOptions] = useState<SelectItemOptionsType | undefined>();
	const [selectedFeatures, setSelectedFeatures] = useState<PlanFeatureOptions[]>([]);
    const [loading, setLoading] = useState(false);
	const initialValues: CreatePlanWithOrderDomainsRequest = new CreatePlanWithOrderDomainsRequest(null, 2, '', '', 0, 0, true, { featureAndValues: [] })

	const onFeaturesChange = (e: DropdownChangeParams) => {
		if (!planFeatureOptions || selectedFeatures.find((item) => item.planFeatureOptions === e.value)) return;

        const pushParam = { planFeatureOptions: e.value, label: planFeatureOptions.find(feature => feature.value === e.value)?.label, value: '', isCustomizable: planFeatureOptions.find((feature) => feature.value === e.value)?.isCustomizable || false };
        setSelectedFeatures([...selectedFeatures, pushParam]);
    };

    const onInputChange = (inputValue: string, planFeatureTypeId: number) => {
        setSelectedFeatures(
            selectedFeatures.map((item) => {
                if (item.planFeatureOptions === planFeatureTypeId) {
                    return { ...item, value: inputValue.toString() };
                } else {
                    return item;
                }
            })
        );
    };

    const submitForm = async(values: CreatePlanWithOrderDomainsRequest) => {
		try {

			setLoading(true)

			const request = new CreatePlanWithOrderDomainsRequest(values?.basePlanId ? values.basePlanId : null, values.planType, values.name, values.description, Math.round(parseFloat(values.priceAsMonthly.toString()) * 100), Math.round(parseFloat(values.priceAsYearly.toString()) * 100), values.isBuyable, { featureAndValues: selectedFeatures });

			const response = await plansService.create(request)

			if (!response.isSuccess) throw ''

			setSelectedFeatures([])
			setVisible(false)
			getPlans()

		}finally { setLoading(false) }
	};

	const getPlanFeatureTypes = useCallback(async() => {
		try {
			setLoading(true)

			const request = new GetPlanFeatureTypesRequest();

			const response = await planFeatureTypesService.getAll(request);

			if (!response.isSuccess) throw '';

			const options = response.data?.map((feature) => ({ label: feature.description.replace('{0}', '___'), value: feature.type, isCustomizable: feature.isCustomizable } as SelectItem));

			if (!options) return;

			setPlanFeatureOptions(options);
		} finally { setLoading(false) }
	}, [])

	const deleteSelectedFeature = (planFeatureOptions: number) => setSelectedFeatures(selectedFeatures.filter(selecteds => selecteds.planFeatureOptions !== planFeatureOptions))

	useEffect(() => {
		getPlanFeatureTypes();

	}, [getPlanFeatureTypes])

    return (
		<Dialog
			header="Create Plan"
			visible={visible}
			onHide={() => {
				setSelectedFeatures([]);
				setVisible(false);
			}}
			className="plan-dialog">
				<Formik initialValues={initialValues} onSubmit={submitForm}>
					{({ values, handleChange }) => (
						<Form>
							<div className="grid">
								<div className="col-12 md:col-6">
									<TextInput name="name" label="Plan Name" value={values.name} onChange={handleChange} placeholder="Plan name" />
								</div>

								<div className="col-12 md:col-6">
									<DropdownInput name="basePlanId" label="Base Plan" value={values.basePlanId} options={planOptions} onChange={handleChange} placeholder="Select for add new features" />
								</div>

								<div className="col-12">
									<TextareaInput name="description" label="Plan Description" value={values.description} onChange={handleChange} placeholder="Plan description" />
								</div>

								<div className="col-12 md:col-6">
									<TextInput name="priceAsMonthly" label="Price as monthly" value={values.priceAsMonthly} onChange={handleChange} placeholder="0" keyfilter="num" />
								</div>

								<div className="col-12 md:col-6">
									<TextInput name="priceAsYearly" label="Price as yearly" value={values.priceAsYearly} onChange={handleChange} placeholder="0" keyfilter="num" />
								</div>

								<div className="col-12">
									<DropdownInput name="createPlanFeatures.featureAndValues" label="Features" options={planFeatureOptions} onChange={(e) => onFeaturesChange(e)} placeholder="Select for add new features" />
								</div>
							</div>
							<div className="my-4">
								{selectedFeatures.length > 0 &&
									selectedFeatures.map((feature: PlanFeatureOptions, index: number) => (
										<div key={index} className="flex flex-row justify-content-between align-items-center my-1 px-4" style={{ height: 40, backgroundColor: '#EFEFEF', borderRadius: 10 }}>
											<span>{feature.label || ''}</span>
											<div className="flex align-items-center justify-content-center">
												{feature.isCustomizable ? (
													<InputText onChange={(e) => onInputChange(e.target.value, feature.planFeatureOptions)} style={{ width: 50, height: 30, borderRadius: 0, borderColor: 'lightgray' }} className="text-center" />
												) : (
													<div className="flex align-items-center justify-content-center" style={{ width: 50 }}>
														<i className="pi pi-check-circle" />
													</div>
												)}
												<div className="ml-4 cursor-pointer flex justify-content-center align-items-center border-1 border-round-2xl p-1 text-red" onClick={() => deleteSelectedFeature(feature.planFeatureOptions)}>
													<i className="pi pi-times text-xs" />
												</div>
											</div>
										</div>
									))}
							</div>

							<div className="col-12 flex justify-content-between align-items-end p-0">
								<div className="border-1 border-gray-500 border-round-sm py-3 px-3 flex justify-content-center align-items-center">
									<InputSwitch name="isBuyable" checked={values.isBuyable} onChange={handleChange} className="mr-3" />
									<span>Is Buyable?</span>
								</div>
								<Button type="submit" label="Create" loading={loading} className="h-full px-4 mr-0 md:px-8" />
							</div>
						</Form>
					)}
				</Formik>
		</Dialog>
	);
};

export default PlanCreate;
