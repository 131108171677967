import { Brand } from '../models/dtos/brand/brand';
import { PaginationRequest } from '../models/_commons/requests/pagination-request';
import { DataResponse } from '../models/_commons/responses/data-response';
import HttpBaseService from './_common/http-base-service';
import { CreateBrandRequest } from '../models/requests/brands/create-brand-request';
import { UpdateBrandRequest } from '../models/requests/brands/update-brand-request';
import { ResponseBase } from '../models/_commons/responses/response-base';
import { UpdateBrandStatuRequest } from '../models/requests/brands/update-brand-statu-request';

class BrandService extends HttpBaseService {
    constructor() {
        super('brands');
    }

    async getAll(request: PaginationRequest): Promise<DataResponse<Brand[]>> {
        return await this.postData('get-brands', { pagination: request });
    }

    async getBrandsByMerchantId(merchantId:string): Promise<DataResponse<Brand[]>> {
        return await this.getData('get-approved-and-vendorOwned-pending-brands?MerchantId='+merchantId)
    }

    async getBrandsForAdmin(request: PaginationRequest): Promise<DataResponse<Brand[]>> {
        return await this.postData('get-brands-for-admin', { pagination: request });
    }

    async create(request: CreateBrandRequest): Promise<ResponseBase>{
        return await this.postData('create', request);
    }

    async createWithImageMedia(request: CreateBrandRequest): Promise<ResponseBase>{
        return await this.postData('create-with-image-media', request, true);
    }

    async update(request: UpdateBrandRequest): Promise<ResponseBase>{
        return await this.putData('update', request);
    }

    async updateStatu(request: UpdateBrandStatuRequest) {
        return await this.patchData('update-statu',request)
    }

    async delete(id: string): Promise<boolean>{
        return await this.deleteData('soft-delete', id);
    }

}

export default new BrandService();
