import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { PlanDto } from 'features/plans/_common/dtos/plan.dto';
import plansService from 'features/plans/_common/plans.service';
import { GetPlansForListRequest } from 'features/plans/_common/get-plans-for-list/get-plans-for-list.request';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CreateUserSubscribtionRequest } from 'features/users/derived_features/user-subscriptions/create-subscription/create-user-subscription.request';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import userSubscriptionService from 'features/users/derived_features/user-subscriptions/user-subscription.service';
import { Checkbox } from 'primereact/checkbox';
import { SellerForListDto } from 'features/sellers/_common/dtos/seller-for-list.dto';

type Props = {
	visible: boolean;
	setVisible: (state: boolean) => void;
	seller: SellerForListDto;
	setSeller: (state: SellerForListDto | undefined) => void;
}

const PlanUpgradeModal = ({ visible, setVisible, seller, setSeller}: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [plans, setPlans] = useState<PlanDto[]>([]);
	const now = new Date();

	const initialValues = new CreateUserSubscribtionRequest({ userId: seller.id, planId: seller.plan.id });

	const validationSchema = Yup.object().shape({
		planId: Yup.string().required('Plan is required'),
		startDate: Yup.date().min(new Date(now.getFullYear(), now.getMonth(), now.getDate()), 'Start date should be greater then now'),
		endDate: Yup.date().min(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1), 'End date should be greater then tomorrow')
	});

	const handleSubmit = async (values: CreateUserSubscribtionRequest, formikHelpers: FormikHelpers<CreateUserSubscribtionRequest>) => {
		try {
			setLoading(true);

			const response = await userSubscriptionService.createSubscription(values);
			if (!response.isSuccess) throw '';

			formikHelpers.resetForm();
			handleHide();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleHide = () => {
		setSeller(undefined);
		setPlans([]);
		setLoading(false);
		setVisible(false);
	};

	const getPlans = async () => {
		try {
			setLoading(true);

			const response = await plansService.getPlansForList(new GetPlansForListRequest());
			if (!response.isSuccess || !response.data) throw '';

			setPlans(response.data);
		} catch (error) {
			setPlans([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPlans();

		return () => {
			handleHide();
		};
	}, []);

	return (
		<Dialog visible={visible} onHide={handleHide} header="Upgrade Seller Plan">
			<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
				{({ values, handleChange, setFieldValue }) => (
					<Form className="relative pt-3">
						<div className="grid">
							<div className="col-12">
								<div className="flex align-items-center justify-content-between px-1 mb-1">
									<label>Select a Plan*</label>
									<ErrorMessage name="planId" component="small" className="text-red font-medium" />
								</div>

								<Dropdown name="planId" options={plans} value={values.planId} optionLabel="name" optionValue="id" placeholder="Please select Plan" onChange={handleChange} className="w-full" />
							</div>
						</div>

						<div className="grid mt-2">
							<div className="col-6">
								<div className="flex align-items-center justify-content-between px-1 mb-1">
									<label>Select Start Date*</label>
									<ErrorMessage name="startDate" component="small" className="text-red font-medium" />
								</div>

								<Calendar id="startDate" name="startDate" value={values.startDate} onChange={handleChange} showTime showSeconds hourFormat="12" className="w-full" />
							</div>

							<div className="col-6">
								<div className="flex align-items-center justify-content-between px-1 mb-1">
									<label>Select End Date*</label>
									<ErrorMessage name="endDate" component="small" className="text-red font-medium" />
								</div>

								<Calendar id="endDate" name="endDate" value={values.endDate} onChange={handleChange} showTime showSeconds hourFormat="12" className="w-full" />
							</div>
						</div>

						<div className="grid mt-2">
							<div className="col-6">
								<div className="field-checkbox select-none">
									<Checkbox inputId="isTrial" name="isTrial" checked={values.isTrial} onChange={(event) => setFieldValue(`isTrial`, event.checked)} />

									<label htmlFor="isTrial" className="cursor-pointer">
										Is Trial
									</label>
								</div>
							</div>

							<div className="col-6 text-right">
								<Button type="submit" label="Save" icon="pi pi-save" className="px-5" />
							</div>
						</div>

						{!!loading ? (
							<div className="custom-loading">
								<ProgressSpinner className="p-progress-color" strokeWidth="4" />
							</div>
						) : null}
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default PlanUpgradeModal;
