import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { SellerStatu } from '../dtos/enums/seller-statu.enum';

export class GetSellerListRequest {
	contains: string | null;
	statu: SellerStatu | null = null;
	pagination: PaginationDto;

	constructor(props: Partial<GetSellerListRequest>) {
		this.contains = props.contains || '';
		this.statu = props.statu || null;
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-seller-list`;
	}
}
