import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';

export class GetProductsForListRequest {
	categoryId: string;
	pagination: PaginationDto;

	constructor(props: Partial<GetProductsForListRequest>) {
		this.categoryId = props.categoryId || '';
		this.pagination = props.pagination || new PaginationDto();
	}

	get getConvertUriExtension() {
		return `get-products-for-list`;
	}
}
