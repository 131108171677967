import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Resource } from '../../../../models/dtos/resource/resource';
import resourceService from '../../../../services/resource-service';

const ResourceDetail = () => {
	const { resourceId } = useParams();
	const [resource, setResource] = useState<Resource | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const getResource = () => {
		if (!resourceId) return;

		setLoading(true);

		resourceService
			.getById(resourceId)
			.then((response) => {
				if (!response.isSuccess) return;

				setResource(response.data);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (!resourceId) return;

		getResource();
	}, [resourceId]);

	return (
		<>
			<h5>Help Resource Detail</h5>
			<div className="card">
				<div className="grid">
					<div className="col-12 lg:col-8">
						<iframe src={resource?.media?.videoMedias[0].embeddedUrl} title={resource?.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen style={{ width: '100%', height: '60%', aspectRatio: '16/12', border: 0 }}></iframe>
						<h5 className="card-title">{resource?.title}</h5>
						<div className="card-text">{resource?.description}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResourceDetail;
