import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import stringHelper from 'helpers/string.helper';
import styles from './CustomDtfOrderFilterTab.module.scss';
import 'intro.js/introjs.css';
import { CustomDtfOrderStatu } from '../../../../../../../features/customDtf/get-custom-dtf-orders/get-custom-dtf-orders.request';

type Props = {
	filterOrderStatuType: CustomDtfOrderStatu | null;
	setFilterOrderStatuType: (state: CustomDtfOrderStatu | null) => void;
	page: 'admin' | 'seller' | 'printCenter';
	isTutorialStart?: boolean;
};

const CustomDtfOrderFilterTab = ({ filterOrderStatuType, setFilterOrderStatuType, page, isTutorialStart }: Props) => {
	const [_, setSearchParams] = useSearchParams();
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);

	/*
	* 	OrderApproved = 1,
	Printing = 2,
	Printed = 3,
	DeliveredToCarrier = 4,
	ReadyForPickup = 5,
	Cancelled = 6,
	*
	* */

	const statuColors = [
		{ orderStatu: null, color: '#363062' }, // all
		{ orderStatu: CustomDtfOrderStatu.OrderApproved, color: '#3f51b5' }, // acknowledge
		{ orderStatu: CustomDtfOrderStatu.Printing, color: '#6C5F5B' }, // delay order
		{ orderStatu: CustomDtfOrderStatu.Printed, color: '#75bef8' }, // printed order
		{ orderStatu: OrderStatuTypes.DeliveredToCarrier, color: '#4169e1' }, // Delivered To USPS
		{ orderStatu: CustomDtfOrderStatu.ReadyForPickup, color: '#ad8957' }, // preparing
		{ orderStatu: OrderStatuTypes.Canceled, color: '#ff8980' } // canceled
	];
	const handleClickStatuBtns = async (statu: CustomDtfOrderStatu | null = null) => {
		setFilterOrderStatuType(statu);
		setSearchParams(`status=${statu}`);
	};

	useEffect(() => {
		if (!isTutorialStart) return;

		setTutorialStepsEnabled(true);
	}, [isTutorialStart]);

	return (
		<React.Fragment>
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'orders-intro-2', disableInteraction: false, buttonClass: 'text-sm text-color-secondary bg-red', tooltipClass: "p-10rem" }} /> */}

			{page === 'admin' || page === 'seller' ? (
				<React.Fragment>
					<h5 className="m-0">Order Status</h5>
					<p className="text-sm text-color-secondary">You can filter your orders by order status like acknowledge, printed, canceled etc.</p>
				</React.Fragment>
			) : (
				<h6 className="mb-1">Filter by Order Status</h6>
			)}

			<div className={`flex flex-wrap gap-3${page === 'admin' || page === 'seller' ? '' : ' mb-3'}`}>
				<button onClick={() => handleClickStatuBtns(null)} className={`${styles.btnStatus} shadow-1${filterOrderStatuType === null ? ' ' + styles.active : ''}`} style={{ '--border-color-hex': statuColors.find((statu) => statu.orderStatu === null)?.color } as CSSProperties} aria-label="All">
					<i className={`pi pi-circle${filterOrderStatuType === null ? '-fill' : ''} mr-2`} style={{ color: statuColors.find((statu) => statu.orderStatu === null)?.color }} />
					All
				</button>

				{enumToArrayHelper.convertArray(CustomDtfOrderStatu, true).map((data) => (
					<button onClick={() => handleClickStatuBtns(+data.value)} key={data.value} className={`${styles.btnStatus} shadow-1${!!filterOrderStatuType && filterOrderStatuType === data.value ? ' ' + styles.active : ''} tutorial-selector${data.value}`} style={{ '--border-color-hex': statuColors.find((statu) => statu.orderStatu === data.value)?.color } as CSSProperties} aria-label={data.label}>
						<i className={`pi pi-circle${!!filterOrderStatuType && filterOrderStatuType === data.value ? '-fill' : ''} mr-2`} style={{ color: statuColors.find((statu) => statu.orderStatu === data.value)?.color }} />
						{stringHelper.parseAtUpperCaseAndJoin(data.label)}
					</button>
				))}
			</div>
		</React.Fragment>
	);
};

export default CustomDtfOrderFilterTab;
