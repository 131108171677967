import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../../Welcome.module.scss';
import { Navigation } from 'swiper/modules';
import { BestSellerProduct } from '../../../../../features/informations/get-dashboard-informations/get-dashboard-informations.response';
import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/hooks';

type Props = {
	items: BestSellerProduct[];
	loading: boolean;
};

enum SlideDirection {
	Next = 0,
	Prev = 1
}

type SlideBtnProps = {
	direction: SlideDirection;
	className?: string;
};

const SlideBtn = ({ direction, className }: SlideBtnProps) => {
	const swiper = useSwiper();

	return direction === SlideDirection.Next ? <MdKeyboardArrowRight className={`${className} cursor-pointer border-1 border-round-sm`} size={'2rem'} type="button" onClick={() => swiper.slideNext()} /> : <MdKeyboardArrowLeft className={`${className} cursor-pointer border-1 border-round-sm mr-3`} size={'2rem'} type="button" onClick={() => swiper.slidePrev()} />;
};

const ProductCarousel = ({ items, loading }: Props) => {
	const user = useAppSelector((state) => state.auth.data?.user);

	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const navigate = useNavigate();

	const skeleton = () => (
		<div className={styles.swiperItem}>
			<Skeleton />
			<div className={styles.content}>
				<Skeleton />
				<Skeleton />
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</div>
		</div>
	);

	return (
		<div className={styles.sliderWrapper}>
			<Swiper
				modules={[Navigation]}
				breakpoints={{
					325: { slidesPerView: 1.5, spaceBetween: 10 },
					500: { slidesPerView: 2 },
					750: { slidesPerView: 3 },
					1425: { slidesPerView: 4 }
				}}
				navigation={{
					prevEl: prevRef.current,
					nextEl: nextRef.current
				}}>
				<div slot={'container-start'} className={`flex flex-row justify-content-between align-items-center w-full`}>
					<p>Coming in hot—the latest additions to our product catalog you don’t want to miss</p>
					<div className={'hidden md:block'}>
						<SlideBtn direction={SlideDirection.Prev} />
						<SlideBtn direction={SlideDirection.Next} />
					</div>
				</div>
				{items.map((item, index) => (
					<SwiperSlide className={'swiper-wrapper'} key={index}>
						{!loading && !!items ? (
							<div className={styles.swiperItem} onClick={() => navigate(`/product/detail/${item.id}`)}>
								{item.media.imageMediasWithUrl[0] || item.media.imageMediaWithStorages[0] ? (
									<div className="overflow-hidden">
										<img src={item.media.imageMediasWithUrl[0]?.url || item.media.imageMediaWithStorages[0]?.url} alt={item.name} className="bg-contain" />
									</div>
								) : (
									<div className={`${styles.swiperItemErr} pi pi-image`} />
								)}
								<div className={styles.content}>
									<p className={`${styles.title} h-2rem`}>{item.name}</p>
									<p className={styles.subtitle}>
										{item.brand} + {item.model}
									</p>
									<p className={styles.subtitle}>
										{item.sizeCount} Sizes {item.colorCount} Colors
									</p>
									<p className={styles.price}>From ${item.minPrice.formattedPricePerUnit.toFixed(2)}</p>
									{user?.accountType === 'Enterprise' ? null :
										<p className={styles.platinium}>{`From $${item.minDiscountedPrice.price.formattedPricePerUnit.toFixed(2)} with Printram Platinum`}</p>}
								</div>
							</div>
						) : (
							skeleton()
						)}
					</SwiperSlide>
				))}
			</Swiper>
			<div ref={prevRef} className={`${styles.prevBtn} cursor-pointer border-1 border-round-sm`}>
				<MdKeyboardArrowLeft size={'2rem'} />
			</div>
			<div ref={nextRef} className={`${styles.nextBtn} cursor-pointer border-1 border-round-sm`}>
				<MdKeyboardArrowRight size={'2rem'} />
			</div>
		</div>
	);
};

export default ProductCarousel;
